var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("amadel-menu", { attrs: { page: _vm.currentPage } }),
      _vm._v(" "),
      _c(
        "panel",
        {
          attrs: { title: "Add new Event" },
          scopedSlots: _vm._u([
            {
              key: "actions",
              fn: function() {
                return [
                  _c(
                    "ch-button",
                    {
                      attrs: { color: "gray" },
                      on: {
                        click: function($event) {
                          return _vm.clear()
                        }
                      }
                    },
                    [_vm._v("Clear Event")]
                  ),
                  _vm._v(" "),
                  _c(
                    "ch-button",
                    {
                      on: {
                        click: function($event) {
                          return _vm.save()
                        }
                      }
                    },
                    [_vm._v("Save Event")]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _vm._v(" "),
          _c("create-event-form", {
            ref: "addEvent",
            attrs: { type: "AMADEL" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }