var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    { staticClass: "item" },
    [
      _vm.item.title && _vm.item.sub
        ? [
            _c(
              "div",
              {
                staticClass: "item-dropdown",
                on: {
                  click: function($event) {
                    _vm.menuOpen = !_vm.menuOpen
                  }
                }
              },
              [
                _c("span", [_vm._v(_vm._s(_vm.item.title))]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "arrow-pointer unstyled",
                    class: { open: _vm.menuOpen }
                  },
                  [
                    _c("img", {
                      attrs: { src: "/assets/img/arrowd1.svg", alt: "" }
                    })
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "ul",
              { staticClass: "submenu", class: { open: _vm.menuOpen } },
              _vm._l(_vm.item.sub, function(subitem, key) {
                return _c("nav-item", {
                  key: key,
                  attrs: { name: key, item: subitem }
                })
              }),
              1
            )
          ]
        : [
            _c(
              "a",
              {
                staticClass: "nav-link",
                class: { active: _vm.isActive },
                attrs: { href: "/" + _vm.item[1] },
                on: {
                  mouseenter: function($event) {
                    _vm.isHover = true
                  },
                  mouseleave: function($event) {
                    _vm.isHover = false
                  }
                }
              },
              [
                _vm.iconComponent
                  ? _c(
                      "div",
                      { staticClass: "icon" },
                      [
                        _c(_vm.iconComponent, {
                          tag: "component",
                          attrs: {
                            size: 18,
                            color:
                              _vm.isActive || _vm.isHover
                                ? "#51b13e"
                                : "#292929"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v("\n      " + _vm._s(_vm.item[0]) + "\n    ")
              ]
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }