var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form", { staticClass: "edit-system" }, [
    _c("div", { staticClass: "field" }, [
      _c("label", [_vm._v("PIN:")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.options.pin,
            expression: "options.pin"
          }
        ],
        attrs: { type: "text" },
        domProps: { value: _vm.options.pin },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.options, "pin", $event.target.value)
          }
        }
      })
    ]),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _c("div", { staticClass: "buttons" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-large btn-primary",
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.updateSettings.apply(null, arguments)
            }
          }
        },
        [_vm._v("Submit")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }