var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "view-profile",
    {
      attrs: { header: ["Merchant Detail"] },
      scopedSlots: _vm._u(
        [
          _vm.item
            ? {
                key: "tabsmain",
                fn: function() {
                  return [
                    _c(
                      "ul",
                      _vm._l(_vm.tabs, function(tabData, tabId) {
                        return !tabData.act || _vm.merchant
                          ? _c("li", { class: { active: _vm.tab == tabId } }, [
                              _c(
                                "a",
                                {
                                  attrs: { href: "" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      ;(_vm.tab = tabId), (_vm.editMode = false)
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(tabData.label))]
                              )
                            ])
                          : _vm._e()
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _vm.item && _vm.statusDescription
                      ? _c("div", { staticClass: "status" }, [
                          _c("label", [
                            _c("strong", [
                              _vm._v(
                                _vm._s(
                                  _vm.item.businessProfile.profileNumber ||
                                    _vm.item.id
                                )
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("label", [
                            _c("strong", [_vm._v(_vm._s(_vm.item.companyName))])
                          ]),
                          _vm._v(" "),
                          _c("label", [
                            _c("strong", [
                              _vm._v(
                                _vm._s(
                                  _vm.merchant
                                    ? _vm.merchant.status
                                    : _vm.item.status
                                ) +
                                  " - " +
                                  _vm._s(_vm.statusDescription)
                              )
                            ])
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.item
                      ? _c("div", { staticClass: "status2" }, [
                          _c("label", [
                            _vm._v("Account Reviews: "),
                            _c("strong", [
                              _vm._v(
                                _vm._s(_vm.accountReview) +
                                  " " +
                                  _vm._s(
                                    _vm.accountReview != 1 ? "Days" : "Day"
                                  )
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _vm.item.status == "A02" || _vm.item.status == "A03"
                            ? _c(
                                "label",
                                {
                                  class: _vm.agoClass(
                                    _vm.item.submitDate,
                                    _vm.now
                                  )
                                },
                                [
                                  _vm._v(
                                    "\n        Approval counter\n        "
                                  ),
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.agoTimer(
                                          _vm.item.submitDate,
                                          _vm.now
                                        )
                                      )
                                    )
                                  ])
                                ]
                              )
                            : _vm.item.status == "A04"
                            ? _c(
                                "label",
                                {
                                  class: _vm.agoClass(
                                    _vm.item.submitDate,
                                    _vm.item.approveDate
                                  )
                                },
                                [
                                  _vm._v(
                                    "\n        Approval counter\n        "
                                  ),
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.agoTimer(
                                          _vm.item.submitDate,
                                          _vm.item.approveDate
                                        )
                                      )
                                    )
                                  ])
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.item.status == "A04" &&
                          (!_vm.merchant || _vm.merchant.status == "M00")
                            ? _c(
                                "label",
                                {
                                  class: _vm.agoClass(
                                    _vm.item.approveDate || _vm.item.submitDate,
                                    _vm.now
                                  )
                                },
                                [
                                  _vm._v(
                                    "\n        Approval counter\n        "
                                  ),
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.agoTimer(
                                          _vm.item.approveDate ||
                                            _vm.item.submitDate,
                                          _vm.now
                                        )
                                      )
                                    )
                                  ])
                                ]
                              )
                            : _c("label", [
                                _vm._v("\n        Approval counter\n        "),
                                _c("strong", [_vm._v("00:00:00")])
                              ])
                        ])
                      : _vm._e()
                  ]
                },
                proxy: true
              }
            : null,
          _vm._l([_vm.sTabs[_vm.tab]], function(subTabs) {
            return {
              key: "tabssub",
              fn: function() {
                return [
                  _c(
                    "ul",
                    _vm._l(subTabs, function(tabData, tabId) {
                      return _c(
                        "li",
                        { class: { active: _vm.subTab == tabId } },
                        [
                          _c(
                            "a",
                            {
                              attrs: { href: "" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  ;(_vm.subTab = tabId), (_vm.editMode = false)
                                }
                              }
                            },
                            [_vm._v(_vm._s(tabData.label))]
                          )
                        ]
                      )
                    }),
                    0
                  )
                ]
              },
              proxy: true
            }
          }),
          {
            key: "actions",
            fn: function() {
              return [_c("PortalTarget", { attrs: { name: "actions" } })]
            },
            proxy: true
          }
        ],
        null,
        true
      )
    },
    [
      _vm._v(" "),
      _vm._v(" "),
      _vm._v(" "),
      _c("div", { staticClass: "merchant-panel" }, [
        _vm.item
          ? _c(
              "div",
              { staticClass: "data-content" },
              [
                _vm.formTabs[_vm.subTab] !== undefined
                  ? _c("formview-app-main", {
                      attrs: {
                        item: _vm.item,
                        merchant: _vm.merchant,
                        tab: _vm.formTabs[_vm.subTab],
                        notes: _vm.notes,
                        messagesApi: _vm.messagesApi,
                        editMode: _vm.editMode,
                        lastAgreement: _vm.lastAgreement,
                        edit: _vm.sTabs[_vm.tab][_vm.subTab].edit
                      },
                      on: {
                        "update:editMode": function($event) {
                          _vm.editMode = $event
                        },
                        "update:edit-mode": function($event) {
                          _vm.editMode = $event
                        }
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.subTab == "notes"
                  ? _c(
                      "form",
                      { staticClass: "preq-form" },
                      [
                        _c("notes", {
                          ref: "notes",
                          staticClass: "appl-notes",
                          attrs: {
                            notes: _vm.notes,
                            type: "Application",
                            id: _vm.item.id
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.subTab == "documents"
                  ? _c("merchant-documents", {
                      ref: "documents",
                      attrs: { item: _vm.item },
                      on: { reloadnotes: _vm.loadNotes }
                    })
                  : _vm.subTab == "emails"
                  ? _c("merchant-emails", {
                      ref: "emails",
                      attrs: { item: _vm.item }
                    })
                  : _vm.subTab == "accounts"
                  ? _c("merchant-accounts", {
                      ref: "accounts",
                      attrs: { item: _vm.item }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.subTab == "wscheck"
                  ? _c("merchant-chk-website", {
                      ref: "wscheck",
                      attrs: { item: _vm.item, merchant: _vm.merchant }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.subTab == "precheck"
                  ? _c("merchant-chk-pre", {
                      ref: "precheck",
                      attrs: { item: _vm.item, merchant: _vm.merchant }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.subTab == "g2check"
                  ? _c("merchant-chk-g2", {
                      ref: "g2check",
                      attrs: { item: _vm.item, merchant: _vm.merchant }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.subTab == "idmcheck"
                  ? _c("merchant-chk-acuant", {
                      ref: "idmcheck",
                      attrs: { item: _vm.item, merchant: _vm.merchant }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.subTab == "risk"
                  ? _c("view-app-risk", {
                      ref: "risk",
                      attrs: { item2: _vm.item }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.subTab == "merchantsubmission"
                  ? _c("view-app-intake", {
                      ref: "submission",
                      attrs: { item: _vm.item }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.subTab == "agents" || _vm.subTab == "activity"
                  ? _c("merchant-profile-activity", {
                      attrs: { item: _vm.item, editMode: _vm.editMode },
                      on: {
                        "update:editMode": function($event) {
                          _vm.editMode = $event
                        },
                        "update:edit-mode": function($event) {
                          _vm.editMode = $event
                        }
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.subTab == "users"
                  ? _c("merchant-profile-users", {
                      attrs: { item: _vm.item, editMode: _vm.editMode },
                      on: {
                        "update:editMode": function($event) {
                          _vm.editMode = $event
                        },
                        "update:edit-mode": function($event) {
                          _vm.editMode = $event
                        }
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.subTab == "underwriting"
                  ? _c("app-underwriting-popup", {
                      attrs: { inline: _vm.item }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.subTab == "boarding"
                  ? _c("merchant-boarding", {
                      attrs: { item: _vm.item, merchant: _vm.merchant }
                    })
                  : _vm._e()
              ],
              1
            )
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }