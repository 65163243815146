var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("input", {
    attrs: { type: "text" },
    domProps: { value: _vm.valueToDisplay },
    on: {
      input: _vm.updateValue,
      focus: function($event) {
        _vm.focused = true
      },
      blur: function($event) {
        _vm.focused = false
      },
      keypress: function($event) {
        return _vm.allowLetterNumber($event, {
          letters: false,
          numbers: true,
          allow: [46]
        })
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }