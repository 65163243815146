var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("table", { staticClass: "calendar" }, [
    _c(
      "thead",
      [
        _c(
          "tr",
          _vm._l(_vm.daysOfWeek, function(day) {
            return _c("th", { key: day }, [
              _vm._v("\n        " + _vm._s(day) + "\n      ")
            ])
          }),
          0
        ),
        _vm._v(" "),
        _vm._l(_vm.weeks, function(week, index) {
          return _c(
            "tr",
            { key: _vm.year + _vm.month + index },
            _vm._l(week, function(day, idx) {
              return _c(
                "td",
                {
                  key: idx,
                  class: _vm.getDayClasses(day),
                  on: {
                    click: function($event) {
                      return _vm.selectDate(day)
                    }
                  }
                },
                [
                  day
                    ? _c(
                        "div",
                        [
                          _c(
                            "span",
                            {
                              staticClass: "day",
                              class: { today: day.isToday }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(day.day) +
                                  "\n          "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _vm._l(day.events, function(event) {
                            return _c(
                              "span",
                              {
                                key: event.id,
                                staticClass: "event",
                                class: event.color
                                  ? "event-" + event.color.toLowerCase()
                                  : "event-blue"
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.formatEventText(event)) +
                                    "\n          "
                                )
                              ]
                            )
                          })
                        ],
                        2
                      )
                    : _vm._e()
                ]
              )
            }),
            0
          )
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }