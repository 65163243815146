var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content-width" }, [
    _c(
      "form",
      {
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.save()
          }
        }
      },
      [
        _c("div", { staticClass: "buttons" }, [
          _c(
            "button",
            { staticClass: "btn btn-xs", attrs: { type: "submit" } },
            [_vm._v("Submit")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-xs",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  return _vm.clear()
                }
              }
            },
            [_vm._v("Clear")]
          ),
          _vm._v(" "),
          _vm.loaded
            ? _c(
                "a",
                {
                  attrs: {
                    href:
                      "/compliance/recmatrix/recordkeeping-matrix/merchants_matrix/" +
                      _vm.loaded.id +
                      "/pdf",
                    target: "_blank"
                  }
                },
                [
                  _c(
                    "button",
                    { staticClass: "btn btn-xs", attrs: { type: "button" } },
                    [_vm._v("View")]
                  )
                ]
              )
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "matrix-table-wrapper" }, [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "table",
            _vm._l(_vm.list, function(category, idx) {
              return _c(
                "tbody",
                { key: idx },
                [
                  _c("tr", { staticClass: "header" }, [
                    _c("td", { attrs: { colspan: "2" } }, [
                      _vm._v(_vm._s(category.name) + ":")
                    ])
                  ]),
                  _vm._v(" "),
                  _vm._l(category.items, function(item, index) {
                    return _c("tr", { key: index }, [
                      _c("td", [_vm._v(_vm._s(item.name) + ":")]),
                      _vm._v(" "),
                      _c("td", { attrs: { width: "200" } }, [
                        _vm.getSelectOptions(item.key)
                          ? _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form[item.key],
                                    expression: "form[item.key]"
                                  }
                                ],
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.form,
                                      item.key,
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  }
                                }
                              },
                              _vm._l(_vm.getSelectOptions(item.key), function(
                                option
                              ) {
                                return _c("option", { key: option }, [
                                  _vm._v(_vm._s(option))
                                ])
                              }),
                              0
                            )
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.form[item.key],
                                  expression: "form[item.key]"
                                }
                              ],
                              staticClass: "checkbox",
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(_vm.form[item.key])
                                  ? _vm._i(_vm.form[item.key], null) > -1
                                  : _vm.form[item.key]
                              },
                              on: {
                                change: function($event) {
                                  var $$a = _vm.form[item.key],
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.form,
                                          item.key,
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.form,
                                          item.key,
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(_vm.form, item.key, $$c)
                                  }
                                }
                              }
                            })
                      ])
                    ])
                  })
                ],
                2
              )
            }),
            0
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "matrix-header" }, [
      _c("h2", [
        _vm._v("Merchants Matrix\n          "),
        _c("span", { staticClass: "float-r" }, [_vm._v("Required")])
      ]),
      _vm._v(" "),
      _c("h3", [_vm._v("Identity Information To Be Obtained By Company")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }