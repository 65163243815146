var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.label
        ? _c("label", [
            _vm._v(_vm._s(_vm.label) + " "),
            _vm.required ? _c("em", [_vm._v("*")]) : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._t("default"),
      _vm._v(" "),
      _vm.hasError
        ? _c(
            "div",
            { staticClass: "error-message" },
            _vm._l(_vm.errors, function(error) {
              return _c("span", { key: error }, [_vm._v(_vm._s(error))])
            }),
            0
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }