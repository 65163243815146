var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form", { staticClass: "form-step h3lg" }, [
    _c(
      "section",
      [
        _c("h2", [_vm._v("Contact Information")]),
        _vm._v(" "),
        _vm._l(_vm.typesWithContact, function(xtype) {
          return [
            _c(
              "h3",
              {
                key: "chead" + xtype,
                staticClass: "list-heading",
                class: { emph: xtype.emph }
              },
              [
                _c("em", { domProps: { innerHTML: _vm._s(xtype.title) } }),
                !xtype.emph
                  ? _c("label", [
                      _c("input", {
                        attrs: { type: "checkbox" },
                        on: {
                          change: function($event) {
                            return _vm.checkType(xtype.contact, $event)
                          }
                        }
                      }),
                      _vm._v("Same as General")
                    ])
                  : _vm._e()
              ]
            ),
            _vm._v(" "),
            _c(
              "ul",
              { key: "clist" + xtype, staticClass: "column-form type2" },
              [
                _c("li", [
                  _c("label", [_vm._v("First name")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "field" },
                    [
                      _c(
                        "validator",
                        {
                          attrs: {
                            value: xtype.contact.firstName,
                            reqs: "required city"
                          }
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: xtype.contact.firstName,
                                expression: "xtype.contact.firstName"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: { value: xtype.contact.firstName },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  xtype.contact,
                                  "firstName",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ]
                      )
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Last name")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "field" },
                    [
                      _c(
                        "validator",
                        {
                          attrs: {
                            value: xtype.contact.lastName,
                            reqs: "required city"
                          }
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: xtype.contact.lastName,
                                expression: "xtype.contact.lastName"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: { value: xtype.contact.lastName },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  xtype.contact,
                                  "lastName",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ]
                      )
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("E-mail address")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "field" },
                    [
                      _c(
                        "validator",
                        {
                          attrs: {
                            value: xtype.contact.email,
                            reqs: "required email"
                          }
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: xtype.contact.email,
                                expression: "xtype.contact.email"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: { value: xtype.contact.email },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  xtype.contact,
                                  "email",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ]
                      )
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._m(0, true),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "field" },
                    [
                      _c(
                        "validator",
                        {
                          attrs: {
                            value: xtype.contact.phone,
                            reqs: "required phone"
                          }
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: xtype.contact.phone,
                                expression: "xtype.contact.phone"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: { value: xtype.contact.phone },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  xtype.contact,
                                  "phone",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ]
                      )
                    ],
                    1
                  )
                ])
              ]
            )
          ]
        }),
        _vm._v(" "),
        _vm._m(1)
      ],
      2
    ),
    _vm._v(" "),
    _c("section", [
      _c("h2", { staticClass: "bline" }, [_vm._v("Additional Comments")]),
      _vm._v(" "),
      _c("ul", { staticClass: "column-form type2" }, [
        _c("li", { staticClass: "fwidth" }, [
          _c("label", [
            _vm._v(
              "Please let us know if you have any further comments or information:"
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field" },
            [
              _c(
                "validator",
                {
                  attrs: { value: _vm.app.additionalComments, reqs: "char1000" }
                },
                [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.app.additionalComments,
                        expression: "app.additionalComments"
                      }
                    ],
                    attrs: { type: "text" },
                    domProps: { value: _vm.app.additionalComments },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.app,
                          "additionalComments",
                          $event.target.value
                        )
                      }
                    }
                  })
                ]
              )
            ],
            1
          )
        ])
      ])
    ]),
    _vm._v(" "),
    _vm._m(2),
    _vm._v(" "),
    _c("div", { staticClass: "buttons" }, [
      _c(
        "a",
        {
          staticClass: "btn btn-large btn-plain btn-back",
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.$emit("prev")
            }
          }
        },
        [_vm._v("Back")]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "btn btn-large btn-warn",
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.$emit("save")
            }
          }
        },
        [_vm._v("Save")]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "btn btn-large btn-success btn-next",
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.nextStep.apply(null, arguments)
            }
          }
        },
        [_vm._v("Next Step")]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_vm._v("Telephone number "), _c("sup", [_vm._v("1")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "footnotes" }, [
      _c("li", [
        _c("sup", [_vm._v("1")]),
        _vm._v(
          " Including international country code (e.g. +1 for USA, +852 for Hong Kong, etc.)"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "consent" }, [
      _c("p", [
        _vm._v(
          "Please ensure that your application form and additional documentation is complete and correct. Application requirements can be found in our separate ‘Merchant Application Checklist’."
        )
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "By submitting this form you confirm that all information provided is accurate, complete and truthful and you consent to credit and information verification checks being performed."
        )
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "By completing this form you agree that your personal data may be shared with third parties for onboarding and Know-Your-Customer purposes."
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }