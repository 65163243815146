var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wcontent dashboard" }, [
    _c("div", { staticClass: "dash-row" }, [
      _c("div", { staticClass: "col" }),
      _vm._v(" "),
      _c("div", { staticClass: "col" }, [
        _c("table", { staticClass: "breakdown-table" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("tbody", [
            _c("tr", [
              _c("td", { attrs: { colspan: "2" } }, [
                _c("div", { staticClass: "font-16 mb-10" }, [
                  _vm._v("Todays Events:")
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "event-list" },
                  [
                    _vm.events
                      ? [
                          _vm.events.length
                            ? _c(
                                "ul",
                                { staticClass: "list" },
                                _vm._l(_vm.events, function(event) {
                                  return _c(
                                    "li",
                                    { key: event.id, staticClass: "list-item" },
                                    [
                                      _c("div", {
                                        staticClass: "circle",
                                        class: event.color
                                          ? "event-" + event.color.toLowerCase()
                                          : "event-blue"
                                      }),
                                      _vm._v(" "),
                                      _c("b", [
                                        _vm._v(
                                          _vm._s(_vm.formatEventTime(event))
                                        )
                                      ]),
                                      _vm._v(
                                        ": " +
                                          _vm._s(event.subject) +
                                          "\n                      "
                                      ),
                                      _c(
                                        "a",
                                        {
                                          attrs: { href: _vm.getUrl(event.id) }
                                        },
                                        [
                                          _c(
                                            "ch-button",
                                            {
                                              staticClass: "floatr",
                                              attrs: { size: "xs" }
                                            },
                                            [_vm._v("View")]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                }),
                                0
                              )
                            : _c("span", [_vm._v("No Events Today.")])
                        ]
                      : _c("div", [
                          _vm._v(
                            "\n                  Loading...\n                "
                          )
                        ])
                  ],
                  2
                )
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { colspan: "2" } }, [_vm._v("My Calendar")])
      ]),
      _vm._v(" "),
      _c("tr", [_c("th", { staticClass: "spacer", attrs: { colspan: "2" } })])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }