var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "setupcl" }, [
    _c("ul", [
      _c("li", [
        _c("label", [_vm._v("Run Pre-Check")]),
        _vm._v(" "),
        _vm.isPrecheckDone
          ? _c("div", { staticClass: "done" })
          : _c("div", { staticClass: "actions" }, [
              _c(
                "a",
                {
                  staticClass: "button",
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.runPrecheck()
                    }
                  }
                },
                [_vm._v("Run")]
              )
            ])
      ]),
      _vm._v(" "),
      _c("li", [
        _c("label", [_vm._v("Website Check")]),
        _vm._v(" "),
        _vm.isWebsiteCheckDone
          ? _c("div", { staticClass: "done" })
          : _c("div", { staticClass: "actions" }, [
              _c(
                "a",
                {
                  staticClass: "button",
                  class: { disabled: !_vm.isPrecheckDone },
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.isPrecheckDone && _vm.startWebsiteCheck()
                    }
                  }
                },
                [_vm._v("Run")]
              )
            ])
      ]),
      _vm._v(" "),
      _c("li", [
        _c("label", [_vm._v("Compliance Scans")]),
        _vm._v(" "),
        _vm.complianceDone2 || _vm.isApproved
          ? _c("div", { staticClass: "done" })
          : _vm.complianceDone
          ? _c("div", { staticClass: "actions" }, [
              _c(
                "a",
                {
                  staticClass: "button done",
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.isWebsiteCheckDone &&
                        !_vm.secondaryPrecheck &&
                        (_vm.complianceDone = false)
                    }
                  }
                },
                [_vm._v("Skipped")]
              )
            ])
          : _c("div", { staticClass: "actions" }, [
              _c(
                "a",
                {
                  staticClass: "button",
                  class: { disabled: !_vm.isWebsiteCheckDone },
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.isWebsiteCheckDone && (_vm.complianceDone = true)
                    }
                  }
                },
                [_vm._v("Skip")]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "button",
                  class: { disabled: !_vm.isWebsiteCheckDone },
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.isWebsiteCheckDone &&
                        (_vm.complianceDone = true) &&
                        (_vm.complianceDone2 = true) &&
                        (_vm.$parent.actionsTab = 3)
                    }
                  }
                },
                [_vm._v("Scan")]
              )
            ])
      ]),
      _vm._v(" "),
      _c("li", [
        _c("label", [_vm._v("Pre-Check")]),
        _vm._v(" "),
        _vm.secondaryPrecheck2 || _vm.isApproved
          ? _c("div", { staticClass: "done" })
          : _vm.secondaryPrecheck
          ? _c("div", { staticClass: "actions" }, [
              _c(
                "a",
                {
                  staticClass: "button done",
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.complianceDone && (_vm.secondaryPrecheck = false)
                    }
                  }
                },
                [_vm._v("Skipped")]
              )
            ])
          : _c("div", { staticClass: "actions" }, [
              _c(
                "a",
                {
                  staticClass: "button",
                  class: { disabled: !_vm.complianceDone },
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.complianceDone && (_vm.secondaryPrecheck = true)
                    }
                  }
                },
                [_vm._v("Skip")]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "button",
                  class: { disabled: !_vm.complianceDone },
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.complianceDone &&
                        (_vm.secondaryPrecheck = true) &&
                        (_vm.secondaryPrecheck2 = true) &&
                        _vm.runPrecheck()
                    }
                  }
                },
                [_vm._v("Run")]
              )
            ])
      ]),
      _vm._v(" "),
      _c("li", [
        _c("label", [_vm._v("Pre-Approval")]),
        _vm._v(" "),
        _vm.isApproved
          ? _c("div", { staticClass: "done" })
          : _c("div", { staticClass: "actions" }, [
              _c(
                "a",
                {
                  staticClass: "button",
                  class: { disabled: !_vm.secondaryPrecheck },
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.secondaryPrecheck && _vm.doFinalApproval()
                    }
                  }
                },
                [_vm._v("Run")]
              )
            ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }