var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("p", [_vm._v("Compliance")]),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _c("div", { staticClass: "content-table-container file-list-container" }, [
      _c("table", { staticClass: "file-list content-table" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "tbody",
          [
            _vm._l(this.item.complianceScans, function(complianceScan) {
              return _c("tr", [
                _c("td", [_vm._v(_vm._s(complianceScan.type))]),
                _vm._v(" "),
                _c("td", { staticClass: "partner-boarding-doc-actions" }, [
                  _c(
                    "button",
                    {
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.startStatus(complianceScan)
                        }
                      }
                    },
                    [_vm._v("Start")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.skipStatus(complianceScan)
                        }
                      }
                    },
                    [_vm._v("Skip")]
                  )
                ]),
                _vm._v(" "),
                complianceScan.type != "Scans"
                  ? _c("td", [
                      _vm._v(
                        "\n            " +
                          _vm._s(complianceScan.status) +
                          "\n          "
                      )
                    ])
                  : _c("td"),
                _vm._v(" "),
                _c("td")
              ])
            }),
            _vm._v(" "),
            _vm.item.accountType == "Personal"
              ? [
                  _c("tr", [
                    _c("td", [
                      _vm._v(
                        _vm._s(_vm.item.personal.firstName) +
                          " " +
                          _vm._s(_vm.item.personal.lastName)
                      )
                    ]),
                    _vm._v(" "),
                    _c("td"),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(_vm.scanStatus))]),
                    _vm._v(" "),
                    _c("td")
                  ])
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.item.accountType == "Business"
              ? [
                  _c("tr", [
                    _c("td", [_vm._v(_vm._s(_vm.item.business.companyName))]),
                    _vm._v(" "),
                    _c("td"),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(_vm.scanStatus))]),
                    _vm._v(" "),
                    _c("td")
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.item.business.contacts, function(ubo, ui) {
                    return _c("tr", [
                      _c("td", [
                        _vm._v(
                          _vm._s(ubo.firstName) + " " + _vm._s(ubo.lastName)
                        )
                      ]),
                      _vm._v(" "),
                      _c("td"),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(_vm.scanStatus))]),
                      _vm._v(" "),
                      _c("td")
                    ])
                  })
                ]
              : _vm._e()
          ],
          2
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "actions" }, [
      _c(
        "button",
        {
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.goBack.apply(null, arguments)
            }
          }
        },
        [_vm._v("Back")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          attrs: { disabled: !_vm.boardingResult },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.doNext.apply(null, arguments)
            }
          }
        },
        [_vm._v("Save & Continue")]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("td", [_vm._v("Type")]),
        _vm._v(" "),
        _c("td"),
        _vm._v(" "),
        _c("td", [_vm._v("Result")]),
        _vm._v(" "),
        _c("td", [_vm._v("Options")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }