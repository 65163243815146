var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "content-table-container resources" }, [
      _c("table", { staticClass: "content-table resources" }, [
        _c(
          "tbody",
          [
            _vm._m(0),
            _vm._v(" "),
            _vm._l(_vm.items, function(item, index) {
              return _c("tr", [
                _c(
                  "td",
                  { staticClass: "text-left", staticStyle: { width: "65%" } },
                  [
                    _vm._v(
                      "\n            " + _vm._s(item.name) + "\n          "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "text-left", staticStyle: { width: "15%" } },
                  [
                    _vm._v(
                      "\n            " + _vm._s(item.type) + "\n          "
                    )
                  ]
                ),
                _vm._v(" "),
                _c("td", { staticStyle: { width: "20%" } }, [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-default",
                      attrs: {
                        target: "_blank",
                        href: _vm.basepath + "/" + item.filename
                      }
                    },
                    [_vm._v("Download")]
                  )
                ])
              ])
            }),
            _vm._v(" "),
            _vm._m(1)
          ],
          2
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "inend" }, [
      _c("td", { attrs: { colspan: "3" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "inend" }, [
      _c("td", { attrs: { colspan: "3" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }