var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("amadel-menu", { attrs: { page: _vm.currentPage } }),
      _vm._v(" "),
      _c(
        "panel",
        {
          scopedSlots: _vm._u([
            {
              key: "actions",
              fn: function() {
                return [
                  _c(
                    "ch-button",
                    {
                      attrs: { color: "gray", disabled: !_vm.selected },
                      on: {
                        click: function($event) {
                          return _vm.viewSelectedDate()
                        }
                      }
                    },
                    [_vm._v("View selected day")]
                  ),
                  _vm._v(" "),
                  _c(
                    "ch-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.openAddEvent = true
                        }
                      }
                    },
                    [_vm._v("Add new Event")]
                  )
                ]
              },
              proxy: true
            },
            {
              key: "title",
              fn: function() {
                return [
                  _c("calendar-date-select", {
                    attrs: { month: _vm.month, year: _vm.year },
                    on: {
                      "update:month": function($event) {
                        _vm.month = $event
                      },
                      "update:year": function($event) {
                        _vm.year = $event
                      }
                    }
                  })
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _vm._v(" "),
          _vm._v(" "),
          _c("calendar", {
            ref: "calendar",
            attrs: { type: "AMADEL", month: _vm.month, year: _vm.year },
            on: {
              selected: function($event) {
                _vm.selected = $event
              }
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("manage-event-modal", {
        attrs: {
          type: "AMADEL",
          preselectedDate:
            _vm.selected && _vm.selected.isInFuture ? _vm.selected.date : null
        },
        on: {
          save: function($event) {
            return _vm.addedEvent($event)
          }
        },
        model: {
          value: _vm.openAddEvent,
          callback: function($$v) {
            _vm.openAddEvent = $$v
          },
          expression: "openAddEvent"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }