var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "popup",
    {
      attrs: { "auto-width": "" },
      on: {
        close: function($event) {
          return _vm.close()
        }
      },
      model: {
        value: _vm.open,
        callback: function($$v) {
          _vm.open = $$v
        },
        expression: "open"
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "text-center",
          staticStyle: { padding: "10px 50px 30px 50px" }
        },
        [
          _vm.agents.length && _vm.leads
            ? [
                _vm._v("\n      Assign Selected Lead(s) To:\n      "),
                _c(
                  "div",
                  { staticClass: "mt-20 mb-20" },
                  [
                    _c(
                      "validator",
                      {
                        attrs: { value: _vm.form.assignedTo, reqs: "required" }
                      },
                      [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.assignedTo,
                                expression: "form.assignedTo"
                              }
                            ],
                            attrs: { placeholder: "Select Agent" },
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.form,
                                  "assignedTo",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              }
                            }
                          },
                          _vm._l(_vm.agents, function(user) {
                            return _c(
                              "option",
                              { key: user.id, domProps: { value: user.id } },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(user.agentId) +
                                    " - " +
                                    _vm._s(_vm.item.agentDb.name) +
                                    "\n            "
                                )
                              ]
                            )
                          }),
                          0
                        )
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-small align-center",
                    on: {
                      click: function($event) {
                        return _vm.save()
                      }
                    }
                  },
                  [_vm._v("Assign")]
                )
              ]
            : _c("div", [
                _c("div", { staticClass: "mb-20" }, [
                  _vm._v(
                    "Lead can't be assigned because there are no available agents to assign it to."
                  )
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-small align-center",
                    on: {
                      click: function($event) {
                        return _vm.close()
                      }
                    }
                  },
                  [_vm._v("Okay")]
                )
              ])
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }