var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "validator",
      class: {
        error: (_vm.submitted || _vm.value) && !_vm.valid,
        focused: _vm.focused,
        valid: _vm.submitted && _vm.valid
      },
      on: {
        "!focus": function($event) {
          _vm.focused = true
        },
        "!blur": function($event) {
          _vm.focused = false
        }
      }
    },
    [
      _vm._t("default"),
      _vm._v(" "),
      (_vm.submitted || _vm.value) && !_vm.valid
        ? _c("span", {
            staticClass: "error",
            domProps: { innerHTML: _vm._s(_vm.validationError) }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }