var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ncontent nc-flat" },
    [
      _c(
        "tabs",
        { staticClass: "tabs" },
        [
          _c("tab", {
            attrs: { title: "MERCHANT CASH - ADVANCE", active: _vm.open === 0 },
            on: {
              click: function($event) {
                _vm.open = 0
              }
            }
          }),
          _vm._v(" "),
          _c("tab", {
            attrs: { title: "Language", active: _vm.open === 1 },
            on: {
              click: function($event) {
                _vm.open = 1
              }
            }
          }),
          _vm._v(" "),
          _c("tab", {
            attrs: { title: "Time Zone", active: _vm.open === 2 },
            on: {
              click: function($event) {
                _vm.open = 2
              }
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "ncontent opt-cont" }, [
        false
          ? _c("ul", { staticClass: "opt-setboxes" }, [
              _vm.open === 0
                ? _c("li", [_vm._m(0), _vm._v(" "), _vm._m(1)])
                : _vm._e(),
              _vm._v(" "),
              false
                ? _c("li", [
                    _c("div", { staticClass: "osb-head" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "osb-body" })
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.open === 1
                ? _c("li", [
                    _c("div", { staticClass: "osb-head" }, [
                      _vm._v("Language")
                    ]),
                    _vm._v(" "),
                    _vm._m(2)
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.open === 2
                ? _c("li", [
                    _c("div", { staticClass: "osb-head" }, [
                      _vm._v("Time Zone")
                    ]),
                    _vm._v(" "),
                    _vm._m(3)
                  ])
                : _vm._e()
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "account-settings" },
          [
            _c("h2", [_vm._v("Merchant Options")]),
            _vm._v(" "),
            _vm._m(4),
            _vm._v(" "),
            _c("h2", [_vm._v("Chammp Settings")]),
            _vm._v(" "),
            _vm._m(5),
            _vm._v(" "),
            _vm._m(6),
            _vm._v(" "),
            _c("h2", [_vm._v("Payment Types")]),
            _vm._v(" "),
            _c("div", { staticClass: "module" }, [
              _c("div", { staticClass: "mod-head" }, [_vm._v("CardBiller")]),
              _vm._v(" "),
              _c("div", { staticClass: "mod-body" }, [
                true
                  ? _c(
                      "label",
                      {
                        staticClass: "active-toggle",
                        class: { checked: _vm.user.configuration.cardBiller }
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.user.configuration.cardBiller,
                              expression: "user.configuration.cardBiller"
                            }
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(
                              _vm.user.configuration.cardBiller
                            )
                              ? _vm._i(
                                  _vm.user.configuration.cardBiller,
                                  null
                                ) > -1
                              : _vm.user.configuration.cardBiller
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.user.configuration.cardBiller,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.user.configuration,
                                      "cardBiller",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.user.configuration,
                                      "cardBiller",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.user.configuration,
                                  "cardBiller",
                                  $$c
                                )
                              }
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("span", [_vm._v("Active")])
                      ]
                    )
                  : _c("label", { staticClass: "enroll-button" }, [
                      _c(
                        "a",
                        { staticClass: "misc", attrs: { href: "/support" } },
                        [_vm._v("Enroll")]
                      )
                    ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "module" }, [
              _c("div", { staticClass: "mod-head" }, [
                _vm._v("CardBiller Plus")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "mod-body" }, [
                true
                  ? _c(
                      "label",
                      {
                        staticClass: "active-toggle",
                        class: {
                          checked: _vm.user.configuration.cardBillerPlus
                        }
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.user.configuration.cardBillerPlus,
                              expression: "user.configuration.cardBillerPlus"
                            }
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(
                              _vm.user.configuration.cardBillerPlus
                            )
                              ? _vm._i(
                                  _vm.user.configuration.cardBillerPlus,
                                  null
                                ) > -1
                              : _vm.user.configuration.cardBillerPlus
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.user.configuration.cardBillerPlus,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.user.configuration,
                                      "cardBillerPlus",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.user.configuration,
                                      "cardBillerPlus",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.user.configuration,
                                  "cardBillerPlus",
                                  $$c
                                )
                              }
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("span", [_vm._v("Active")])
                      ]
                    )
                  : _c("label", { staticClass: "enroll-button" }, [
                      _c(
                        "a",
                        { staticClass: "misc", attrs: { href: "/support" } },
                        [_vm._v("Enroll")]
                      )
                    ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "module" }, [
              _c("div", { staticClass: "mod-head" }, [_vm._v("BankBiller")]),
              _vm._v(" "),
              _c("div", { staticClass: "mod-body" }, [
                true
                  ? _c(
                      "label",
                      {
                        staticClass: "active-toggle",
                        class: { checked: _vm.user.configuration.bankBiller }
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.user.configuration.bankBiller,
                              expression: "user.configuration.bankBiller"
                            }
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(
                              _vm.user.configuration.bankBiller
                            )
                              ? _vm._i(
                                  _vm.user.configuration.bankBiller,
                                  null
                                ) > -1
                              : _vm.user.configuration.bankBiller
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.user.configuration.bankBiller,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.user.configuration,
                                      "bankBiller",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.user.configuration,
                                      "bankBiller",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.user.configuration,
                                  "bankBiller",
                                  $$c
                                )
                              }
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("span", [_vm._v("Active")])
                      ]
                    )
                  : _c("label", { staticClass: "enroll-button" }, [
                      _c(
                        "a",
                        { staticClass: "misc", attrs: { href: "/support" } },
                        [_vm._v("Enroll")]
                      )
                    ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "module" }, [
              _c("div", { staticClass: "mod-head" }, [_vm._v("CoinBiller")]),
              _vm._v(" "),
              _c("div", { staticClass: "mod-body" }, [
                true
                  ? _c(
                      "label",
                      {
                        staticClass: "active-toggle",
                        class: { checked: _vm.user.configuration.coinBiller }
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.user.configuration.coinBiller,
                              expression: "user.configuration.coinBiller"
                            }
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(
                              _vm.user.configuration.coinBiller
                            )
                              ? _vm._i(
                                  _vm.user.configuration.coinBiller,
                                  null
                                ) > -1
                              : _vm.user.configuration.coinBiller
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.user.configuration.coinBiller,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.user.configuration,
                                      "coinBiller",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.user.configuration,
                                      "coinBiller",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.user.configuration,
                                  "coinBiller",
                                  $$c
                                )
                              }
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("span", [_vm._v("Active")])
                      ]
                    )
                  : _c("label", { staticClass: "enroll-button" }, [
                      _c(
                        "a",
                        { staticClass: "misc", attrs: { href: "/support" } },
                        [_vm._v("Enroll")]
                      )
                    ])
              ])
            ]),
            _vm._v(" "),
            _c("h2", [_vm._v("Modules")]),
            _vm._v(" "),
            _vm._l(_vm.modules, function(module) {
              return _c("div", { key: module.id, staticClass: "module" }, [
                _c("div", { staticClass: "mod-head" }, [
                  _vm._v(_vm._s(module.name))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mod-body" }, [
                  _vm.moduleFound(module)
                    ? _c(
                        "label",
                        {
                          staticClass: "active-toggle",
                          class: {
                            checked: _vm.user.configuration["mod" + module.id]
                          }
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.user.configuration["mod" + module.id],
                                expression:
                                  "user.configuration['mod' + module.id]"
                              }
                            ],
                            attrs: { type: "checkbox" },
                            domProps: {
                              checked: Array.isArray(
                                _vm.user.configuration["mod" + module.id]
                              )
                                ? _vm._i(
                                    _vm.user.configuration["mod" + module.id],
                                    null
                                  ) > -1
                                : _vm.user.configuration["mod" + module.id]
                            },
                            on: {
                              change: function($event) {
                                var $$a =
                                    _vm.user.configuration["mod" + module.id],
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.user.configuration,
                                        "mod" + module.id,
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.user.configuration,
                                        "mod" + module.id,
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.user.configuration,
                                    "mod" + module.id,
                                    $$c
                                  )
                                }
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("span", [_vm._v("Active")])
                        ]
                      )
                    : _c("label", { staticClass: "enroll-button" }, [
                        _c(
                          "a",
                          { staticClass: "misc", attrs: { href: "/support" } },
                          [_vm._v("Enroll")]
                        )
                      ]),
                  _vm._v(" "),
                  _vm._m(7, true)
                ])
              ])
            }),
            _vm._v(" "),
            _c("h2", [_vm._v("Account options")]),
            _vm._v(" "),
            _vm._l(
              _vm.options.filter(function(option) {
                return !(option.parent * 1) && option.mode == "Toggle"
              }),
              function(option) {
                return _c("div", { key: option.id, staticClass: "module" }, [
                  _c("div", { staticClass: "mod-head" }, [
                    _vm._v(_vm._s(option.name))
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "mod-body" }, [
                    _vm.optionFound(option)
                      ? _c(
                          "label",
                          {
                            staticClass: "active-toggle",
                            class: {
                              checked: _vm.user.configuration["opt" + option.id]
                            }
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.user.configuration["opt" + option.id],
                                  expression:
                                    "user.configuration['opt' + option.id]"
                                }
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.user.configuration["opt" + option.id]
                                )
                                  ? _vm._i(
                                      _vm.user.configuration["opt" + option.id],
                                      null
                                    ) > -1
                                  : _vm.user.configuration["opt" + option.id]
                              },
                              on: {
                                change: function($event) {
                                  var $$a =
                                      _vm.user.configuration["opt" + option.id],
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.user.configuration,
                                          "opt" + option.id,
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.user.configuration,
                                          "opt" + option.id,
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.user.configuration,
                                      "opt" + option.id,
                                      $$c
                                    )
                                  }
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("span", [_vm._v("Active")])
                          ]
                        )
                      : _c("label", { staticClass: "enroll-button" }, [
                          _c(
                            "a",
                            {
                              staticClass: "misc",
                              attrs: { href: "/support" }
                            },
                            [_vm._v("Enroll")]
                          )
                        ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "addons" },
                      _vm._l(
                        _vm.options.filter(function(option) {
                          return option.id == _vm.subopt.parent
                        }),
                        function(subopt, index) {
                          return _c(
                            "a",
                            { key: index, staticClass: "button" },
                            [
                              _vm._v(
                                "Activate " +
                                  _vm._s(subopt.name.replace("CHAMMP", ""))
                              )
                            ]
                          )
                        }
                      ),
                      0
                    )
                  ])
                ])
              }
            ),
            _vm._v(" "),
            _c("h2", [_vm._v("Plug-Ins")]),
            _vm._v(" "),
            _c("div", { staticClass: "module" }, [
              _c("div", { staticClass: "mod-head" }, [_vm._v("Tax Cloud")]),
              _vm._v(" "),
              _c("div", { staticClass: "mod-body" }, [
                true
                  ? _c(
                      "label",
                      {
                        staticClass: "active-toggle",
                        class: { checked: _vm.user.configuration.taxCloud }
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.user.configuration.taxCloud,
                              expression: "user.configuration.taxCloud"
                            }
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(
                              _vm.user.configuration.taxCloud
                            )
                              ? _vm._i(_vm.user.configuration.taxCloud, null) >
                                -1
                              : _vm.user.configuration.taxCloud
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.user.configuration.taxCloud,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.user.configuration,
                                      "taxCloud",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.user.configuration,
                                      "taxCloud",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.user.configuration,
                                  "taxCloud",
                                  $$c
                                )
                              }
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("span", [_vm._v("Active")])
                      ]
                    )
                  : _c("label", { staticClass: "enroll-button" }, [
                      _c(
                        "a",
                        { staticClass: "misc", attrs: { href: "/support" } },
                        [_vm._v("Enroll")]
                      )
                    ])
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "buttons",
                staticStyle: { "text-align": "center", "margin-top": "30px" }
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-large btn-primary",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.updateSettings.apply(null, arguments)
                      }
                    }
                  },
                  [_vm._v("\n          Submit\n        ")]
                )
              ]
            )
          ],
          2
        )
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "osb-head" }, [
      _c("span", [_vm._v("MERCHANT CASH "), _c("br"), _vm._v("ADVANCE")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "osb-body" }, [
      _c("label", { staticClass: "current" }, [
        _c("strong", [_vm._v("You are eligible")]),
        _vm._v(" to "),
        _c("br"),
        _vm._v("receive up to")
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "value emph" }, [
        _c("strong", [_vm._v("$2373.95")])
      ]),
      _vm._v(" "),
      _c("label", { staticClass: "current" }, [
        _vm._v("in a "),
        _c("strong", [_vm._v("Merchant"), _c("br"), _vm._v("Cash Advance")])
      ]),
      _vm._v(" "),
      _c("form", { staticClass: "opts-f2" }, [
        _c("div", { staticClass: "osb-btns" }, [
          _c("a", { staticClass: "osb-btn", attrs: { href: "" } }, [
            _vm._v("Claim")
          ]),
          _vm._v(" "),
          _c("a", { staticClass: "osb-btn", attrs: { href: "" } }, [
            _vm._v("Free Eval")
          ])
        ]),
        _vm._v(" "),
        _c("label", [
          _vm._v("Last Eval: "),
          _c("strong", [_vm._v("September 1, 2019")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "osb-body" }, [
      _c("label", { staticClass: "current" }, [_vm._v("Current Language:")]),
      _vm._v(" "),
      _c("span", { staticClass: "value" }, [_c("strong", [_vm._v("English")])]),
      _vm._v(" "),
      _c("form", [
        _c("label", [_vm._v("Select New Language:")]),
        _vm._v(" "),
        _c("div", { staticClass: "select" }, [
          _c("span", [_vm._v(" ")]),
          _vm._v(" "),
          _c("select", [
            _c("option", [_vm._v("English")]),
            _vm._v(" "),
            _c("option", [_vm._v("Français")])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "osb-btns" }, [
          _c("button", [_vm._v("Update")]),
          _vm._v(" "),
          _c("a", { staticClass: "osb-btn", attrs: { href: "" } }, [
            _vm._v("Cancel")
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "osb-body" }, [
      _c("label", { staticClass: "current" }, [_vm._v("Current Time Zone:")]),
      _vm._v(" "),
      _c("span", { staticClass: "value" }, [
        _c("strong", [_vm._v("GMT -5 (EST)")])
      ]),
      _vm._v(" "),
      _c("form", [
        _c("label", [_vm._v("Select New Time Zone:")]),
        _vm._v(" "),
        _c("div", { staticClass: "select" }, [
          _c("span", [_vm._v(" ")]),
          _vm._v(" "),
          _c("select", [
            _c("option", [_vm._v("GMT -5")]),
            _vm._v(" "),
            _c("option", [_vm._v("GMT -6")])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "osb-btns" }, [
          _c("button", [_vm._v("Update")]),
          _vm._v(" "),
          _c("a", { staticClass: "osb-btn", attrs: { href: "" } }, [
            _vm._v("Cancel")
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "module osb-module" }, [
      _c("div", { staticClass: "mod-head" }, [
        _vm._v("MERCHANT CASH"),
        _c("br"),
        _vm._v("ADVANCE")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "mod-body osb-body cash-advance" }, [
        _c("label", { staticClass: "current" }, [
          _c("strong", [_vm._v("You are eligible")]),
          _vm._v(" to receive up to\n            "),
          _c("span", { staticClass: "emph" }, [
            _c("strong", [_vm._v("$2373.95")])
          ]),
          _c("br"),
          _vm._v("\n            in a "),
          _c("strong", [_vm._v("Merchant Cash Advance")])
        ]),
        _vm._v(" "),
        _c("form", { staticClass: "opts-f2" }, [
          _c("div", { staticClass: "osb-btns" }, [
            _c("a", { staticClass: "osb-btn", attrs: { href: "" } }, [
              _vm._v("Claim")
            ]),
            _vm._v(" "),
            _c("a", { staticClass: "osb-btn", attrs: { href: "" } }, [
              _vm._v("Free Eval")
            ])
          ]),
          _vm._v(" "),
          _c("label", [
            _vm._v("Last Eval: "),
            _c("strong", [_vm._v("September 1, 2019")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "module osb-module" }, [
      _c("div", { staticClass: "mod-head" }, [_vm._v("Language")]),
      _vm._v(" "),
      _c("div", { staticClass: "mod-body osb-body" }, [
        _c("div", [
          _c("label", { staticClass: "current" }, [
            _vm._v("Current Language:")
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "value" }, [
            _c("strong", [_vm._v("English")])
          ])
        ]),
        _vm._v(" "),
        _c("form", [
          _c("label", [_vm._v("Select New Language:")]),
          _vm._v(" "),
          _c("div", { staticClass: "select" }, [
            _c("span", [_vm._v(" ")]),
            _vm._v(" "),
            _c("select", [
              _c("option", [_vm._v("English")]),
              _vm._v(" "),
              _c("option", [_vm._v("Français")])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "osb-btns" }, [
            _c("button", [_vm._v("Update")]),
            _vm._v(" "),
            _c("a", { staticClass: "osb-btn", attrs: { href: "" } }, [
              _vm._v("Cancel")
            ])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "module osb-module" }, [
      _c("div", { staticClass: "mod-head" }, [_vm._v("Time zone")]),
      _vm._v(" "),
      _c("div", { staticClass: "mod-body osb-body" }, [
        _c("div", [
          _c("label", { staticClass: "current" }, [
            _vm._v("Current Time Zone:")
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "value" }, [
            _c("strong", [_vm._v("GMT -5 (EST)")])
          ])
        ]),
        _vm._v(" "),
        _c("form", [
          _c("label", [_vm._v("Select New Time Zone:")]),
          _vm._v(" "),
          _c("div", { staticClass: "select" }, [
            _c("span", [_vm._v(" ")]),
            _vm._v(" "),
            _c("select", [
              _c("option", [_vm._v("GMT -5")]),
              _vm._v(" "),
              _c("option", [_vm._v("GMT -6")])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "osb-btns" }, [
            _c("button", [_vm._v("Update")]),
            _vm._v(" "),
            _c("a", { staticClass: "osb-btn", attrs: { href: "" } }, [
              _vm._v("Cancel")
            ])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "addons" }, [
      _c("a", { staticClass: "button" }, [_vm._v("Activate Tax")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }