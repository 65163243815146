var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ncontent nc-flat" },
    [
      _c(
        "tabs",
        { staticClass: "tabs" },
        [
          _c("tab", {
            attrs: { title: "Language", active: _vm.open === 0 },
            on: {
              click: function($event) {
                _vm.open = 0
              }
            }
          }),
          _vm._v(" "),
          _c("tab", {
            attrs: { title: "Time Zone", active: _vm.open === 1 },
            on: {
              click: function($event) {
                _vm.open = 1
              }
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "ncontent opt-cont" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("ul", { staticClass: "opt-setboxes" }, [
          _vm.open === 0
            ? _c("li", [
                _c("div", { staticClass: "osb-head" }, [_vm._v("Language")]),
                _vm._v(" "),
                _vm._m(1)
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.open === 1
            ? _c("li", [
                _c("div", { staticClass: "osb-head" }, [_vm._v("Time Zone")]),
                _vm._v(" "),
                _vm._m(2)
              ])
            : _vm._e()
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header opt-head" }, [
      _c("h3", [_vm._v("Admin Options")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "osb-body" }, [
      _c("label", { staticClass: "current" }, [_vm._v("Current Language:")]),
      _vm._v(" "),
      _c("span", { staticClass: "value" }, [_c("strong", [_vm._v("English")])]),
      _vm._v(" "),
      _c("form", [
        _c("label", [_vm._v("Select New Language:")]),
        _vm._v(" "),
        _c("div", { staticClass: "select" }, [
          _c("span", [_vm._v("English")]),
          _vm._v(" "),
          _c("select", [_c("option", [_vm._v("English")])])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "osb-btns" }, [
          _c("button", [_vm._v("Update")]),
          _vm._v(" "),
          _c("a", { staticClass: "osb-btn", attrs: { href: "" } }, [
            _vm._v("Cancel")
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "osb-body" }, [
      _c("label", { staticClass: "current" }, [_vm._v("Current Time Zone:")]),
      _vm._v(" "),
      _c("span", { staticClass: "value" }, [
        _c("strong", [_vm._v("GMT -5 (EST)")])
      ]),
      _vm._v(" "),
      _c("form", [
        _c("label", [_vm._v("Select New Time Zone:")]),
        _vm._v(" "),
        _c("div", { staticClass: "select" }, [
          _c("span", [_vm._v("GMT -5")]),
          _vm._v(" "),
          _c("select", [_c("option", [_vm._v("GMT -5")])])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "osb-btns" }, [
          _c("button", [_vm._v("Update")]),
          _vm._v(" "),
          _c("a", { staticClass: "osb-btn", attrs: { href: "" } }, [
            _vm._v("Cancel")
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }