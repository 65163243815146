var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "merchant-panel" }, [
    _c("div", { staticClass: "data-content" }, [
      _c("ul", { staticClass: "tabs" }, [
        _c(
          "li",
          {
            class: { active: _vm.dataTab == 0 },
            on: {
              click: function($event) {
                _vm.dataTab = 0
              }
            }
          },
          [_vm._v("General")]
        ),
        _vm._v(" "),
        _c(
          "li",
          {
            class: { active: _vm.dataTab == 1 },
            on: {
              click: function($event) {
                _vm.dataTab = 1
              }
            }
          },
          [_vm._v("Company")]
        ),
        _vm._v(" "),
        _c(
          "li",
          {
            class: { active: _vm.dataTab == 2 },
            on: {
              click: function($event) {
                _vm.dataTab = 2
              }
            }
          },
          [_vm._v("Banking")]
        ),
        _vm._v(" "),
        _c(
          "li",
          {
            class: { active: _vm.dataTab == 5 },
            on: {
              click: function($event) {
                _vm.dataTab = 5
              }
            }
          },
          [_vm._v("Additional Notes")]
        )
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "tabs" }, [_vm._v("Partner Settings")]),
      _vm._v(" "),
      _c("ul", { staticClass: "tabs-smaller" }, [
        _c(
          "li",
          {
            class: { active: _vm.dataTab == 7 },
            on: {
              click: function($event) {
                _vm.dataTab = 7
              }
            }
          },
          [_vm._v("Profile")]
        ),
        _vm._v(" "),
        _c(
          "li",
          {
            class: { active: _vm.dataTab == 8 },
            on: {
              click: function($event) {
                _vm.dataTab = 8
              }
            }
          },
          [_vm._v("Schedule A")]
        ),
        _vm._v(" "),
        _c(
          "li",
          {
            class: { active: _vm.dataTab == 9 },
            on: {
              click: function($event) {
                _vm.dataTab = 9
              }
            }
          },
          [_vm._v("Access")]
        ),
        _vm._v(" "),
        _c(
          "li",
          {
            class: { active: _vm.dataTab == 10 },
            on: {
              click: function($event) {
                _vm.dataTab = 10
              }
            }
          },
          [_vm._v("Sub-Agents")]
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "main" },
        [
          _c("formview-agent", {
            attrs: {
              item: _vm.item,
              tab: _vm.dataTab,
              notes: _vm.notes,
              agents: _vm.agents,
              editMode: _vm.editMode,
              setup: _vm.lastSetup
            },
            on: {
              "update:editMode": function($event) {
                _vm.editMode = $event
              },
              "update:edit-mode": function($event) {
                _vm.editMode = $event
              }
            }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("aside", { staticClass: "actions" }, [
      _c("ul", { staticClass: "tabs" }, [
        _c(
          "li",
          {
            class: { active: _vm.actionsTab == 0 },
            on: {
              click: function($event) {
                _vm.showFinalApproval = false
                _vm.actionsTab = 0
              }
            }
          },
          [_vm._v("General")]
        ),
        _vm._v(" "),
        _c(
          "li",
          {
            class: { active: _vm.actionsTab == 2 },
            on: {
              click: function($event) {
                _vm.actionsTab = 2
              }
            }
          },
          [_vm._v("Application")]
        ),
        _vm._v(" "),
        _c(
          "li",
          {
            class: { active: _vm.actionsTab == 1 },
            on: {
              click: function($event) {
                _vm.actionsTab = 1
              }
            }
          },
          [_vm._v("Documents")]
        ),
        _vm._v(" "),
        _c(
          "li",
          {
            class: { active: _vm.actionsTab == 3 },
            on: {
              click: function($event) {
                _vm.actionsTab = 3
              }
            }
          },
          [_vm._v("Compliance")]
        ),
        _vm._v(" "),
        _c(
          "li",
          {
            class: { active: _vm.actionsTab == 7 },
            on: {
              click: function($event) {
                _vm.actionsTab = 7
              }
            }
          },
          [_vm._v("Agreements")]
        ),
        _vm._v(" "),
        _c(
          "li",
          {
            class: { active: _vm.actionsTab == 5 },
            on: {
              click: function($event) {
                _vm.actionsTab = 5
              }
            }
          },
          [_vm._v("Email")]
        ),
        _vm._v(" "),
        _c(
          "li",
          {
            class: { active: _vm.actionsTab == 4 },
            on: {
              click: function($event) {
                _vm.actionsTab = 4
              }
            }
          },
          [_vm._v("Agent setup")]
        ),
        _vm._v(" "),
        _c(
          "li",
          {
            class: { active: _vm.actionsTab == 6 },
            on: {
              click: function($event) {
                _vm.actionsTab = 6
              }
            }
          },
          [_vm._v("Accounting")]
        )
      ]),
      _vm._v(" "),
      _vm.actionsTab == 0
        ? _c(
            "div",
            { staticClass: "main" },
            [
              _c(
                "div",
                { staticClass: "action-buttons" },
                [
                  _c(
                    "a",
                    {
                      attrs: { href: "" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.listChecklist.apply(null, arguments)
                        }
                      }
                    },
                    [_vm._v("Setup Checklist")]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      class: { disabled: _vm.editMode },
                      attrs: { href: "" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          _vm.editMode = true
                        }
                      }
                    },
                    [_vm._v("Edit")]
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.allActions, function(optLabel, optMethod) {
                    return _c(
                      "a",
                      {
                        class: { disabled: _vm.isDisabledAction(optMethod) },
                        attrs: { href: "" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            !_vm.isDisabledAction(optMethod) &&
                              _vm.doAction(optMethod)
                          }
                        }
                      },
                      [_vm._v(_vm._s(optLabel))]
                    )
                  }),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      attrs: { href: "" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          _vm.listPreChecks()
                          _vm.showFinalApproval = false
                        }
                      }
                    },
                    [_vm._v("Pre-Checks")]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      attrs: { href: "" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          _vm.uwTab = 2
                          _vm.showFinalApproval = false
                        }
                      }
                    },
                    [_vm._v("Status Update")]
                  )
                ],
                2
              ),
              _vm._v(" "),
              _vm.showFinalApproval
                ? _c("view-agent-finalcheck", {
                    key: "finalcheck",
                    attrs: { uwList: _vm.approvalList }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.uwTab == 1
                ? _c("view-agent-precheck", {
                    key: "precheck",
                    attrs: { uwList: _vm.uwList }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.showFinalApproval && _vm.uwTab == 2
                ? _c("view-agent-status", {
                    key: "status",
                    attrs: { item: _vm.item, notes: _vm.notes }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.showFinalApproval && _vm.uwTab == 3
                ? _c("view-agent-setupcl", {
                    key: "setupcl",
                    attrs: {
                      item: _vm.item,
                      notes: _vm.notes,
                      prechecks: _vm.uwList
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm.actionsTab == 1
        ? _c("div", { staticClass: "main" }, [
            _c("div", { staticClass: "action-buttons" }, [
              _c(
                "a",
                {
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.newFile = true
                    }
                  }
                },
                [_vm._v("New File")]
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "content-table-container file-list-container" },
              [
                _c("table", { staticClass: "file-list content-table" }, [
                  _vm._m(0),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    [
                      _vm.newFile
                        ? _c("tr", [
                            _c("td", [
                              _c(
                                "a",
                                {
                                  staticClass: "close",
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      _vm.newFile = false
                                    }
                                  }
                                },
                                [_vm._v("×")]
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.newFileType,
                                    expression: "newFileType"
                                  }
                                ],
                                staticStyle: { width: "100%" },
                                domProps: { value: _vm.newFileType },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.newFileType = $event.target.value
                                  }
                                }
                              })
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _c("input", {
                                ref: "newFileFile",
                                staticStyle: { width: "100%" },
                                attrs: { type: "file" }
                              })
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _c(
                                "button",
                                {
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.uploadNew.apply(
                                        null,
                                        arguments
                                      )
                                    }
                                  }
                                },
                                [_vm._v("Upload")]
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._l(_vm.item.agentDb.documents, function(file) {
                        return file.type.indexOf("signedApplication") == -1
                          ? [
                              _c("tr", [
                                _c("td", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.selectedFile,
                                        expression: "selectedFile"
                                      }
                                    ],
                                    attrs: { type: "radio" },
                                    domProps: {
                                      value: "custom" + file.id,
                                      checked: _vm._q(
                                        _vm.selectedFile,
                                        "custom" + file.id
                                      )
                                    },
                                    on: {
                                      change: function($event) {
                                        _vm.selectedFile = "custom" + file.id
                                      }
                                    }
                                  })
                                ]),
                                _vm._v(" "),
                                _c("td", [_vm._v(_vm._s(file.type))]),
                                _vm._v(" "),
                                _c("td", [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: _vm.fileLink(
                                          file.filename,
                                          file.id
                                        ),
                                        target: "_blank"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(file.filename || "(no file)")
                                      )
                                    ]
                                  )
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatDate(file.created, "YYYY-MM-DD")
                                    )
                                  )
                                ])
                              ])
                            ]
                          : _vm._e()
                      }),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td"),
                        _vm._v(" "),
                        _c("td", [_vm._v("Completed Agent Application")]),
                        _vm._v(" "),
                        _c("td", [
                          _c(
                            "a",
                            {
                              attrs: { href: _vm.agentPdfUrl, target: "_blank" }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.item.agentId) +
                                  " - " +
                                  _vm._s(_vm.item.agentDb.name)
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm.formatDate(_vm.item.submitDate, "YYYY-MM-DD")
                            )
                          )
                        ])
                      ])
                    ],
                    2
                  )
                ])
              ]
            )
          ])
        : _vm.actionsTab == 2
        ? _c("div", { staticClass: "main" }, [
            _c("div", { staticClass: "action-buttons" }, [
              !_vm.viewApp
                ? _c(
                    "a",
                    {
                      attrs: { href: "" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          _vm.viewApp = true
                        }
                      }
                    },
                    [_vm._v("View")]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.viewApp
                ? _c(
                    "a",
                    {
                      attrs: { href: "" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.uploadFile(_vm.signedApplicationField)
                        }
                      }
                    },
                    [_vm._v("Upload signed")]
                  )
                : _c(
                    "a",
                    {
                      attrs: { href: "" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          _vm.viewApp = false
                        }
                      }
                    },
                    [_vm._v("Back")]
                  )
            ]),
            _vm._v(" "),
            !_vm.viewApp
              ? _c(
                  "div",
                  {
                    staticClass: "content-table-container file-list-container"
                  },
                  [
                    _c("table", { staticClass: "file-list content-table" }, [
                      _vm._m(1),
                      _vm._v(" "),
                      _c(
                        "tbody",
                        [
                          _c("tr", [
                            _vm._m(2),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(_vm.item.agentId))]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.formatDate(
                                    _vm.item.agentDb.createdAt,
                                    "YYYY-MM-DD"
                                  )
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(_vm.item.agentDb.status))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(_vm.item.agentDb.name))])
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("td", { attrs: { colspan: "3" } }, [
                              _c(
                                "a",
                                {
                                  staticStyle: { display: "block" },
                                  attrs: {
                                    target: "_blank",
                                    href: _vm.agentPdfUrl
                                  }
                                },
                                [_vm._v("Completed Agent Application")]
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", { attrs: { colspan: "3" } }, [
                              _c(
                                "a",
                                {
                                  staticStyle: { display: "block" },
                                  attrs: {
                                    target: "_blank",
                                    href: _vm.agentPdfUrl
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.item.applicationId) +
                                      " " +
                                      _vm._s(_vm.item.agentDb.name) +
                                      " " +
                                      _vm._s(
                                        _vm.formatDate(
                                          _vm.item.agentDb.createdAt,
                                          "YYYY-MM-DD hh:mm"
                                        )
                                      )
                                  )
                                ]
                              )
                            ])
                          ]),
                          _vm._v("f\n          "),
                          _vm._l(_vm.signedApplications, function(signedDoc) {
                            return _c("tr", [
                              _c("td", { attrs: { colspan: "3" } }, [
                                _c(
                                  "a",
                                  {
                                    staticStyle: { display: "block" },
                                    attrs: {
                                      target: "_blank",
                                      href: _vm.fileLink(
                                        signedDoc.filename,
                                        signedDoc.id
                                      )
                                    }
                                  },
                                  [_vm._v("Signed Agent Application")]
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", { attrs: { colspan: "3" } }, [
                                _c(
                                  "a",
                                  {
                                    staticStyle: { display: "block" },
                                    attrs: {
                                      target: "_blank",
                                      href: _vm.fileLink(
                                        signedDoc.filename,
                                        signedDoc.id
                                      )
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.item.applicationId) +
                                        " " +
                                        _vm._s(_vm.item.agentDb.name) +
                                        " " +
                                        _vm._s(
                                          _vm.formatDate(
                                            signedDoc.created,
                                            "YYYY-MM-DD hh:mm"
                                          )
                                        )
                                    )
                                  ]
                                )
                              ])
                            ])
                          })
                        ],
                        2
                      )
                    ])
                  ]
                )
              : _c("div", { staticClass: "app-details" }, [
                  _c("p", [
                    _c("label", [_vm._v("Agent ID:")]),
                    _vm._v(" " + _vm._s(_vm.item.agentId))
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _c("label", [_vm._v("Submitted Date:")]),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.formatDate(
                            _vm.item.agentDb.createdAt,
                            "YYYY-MM-DD"
                          )
                        )
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _c("label", [_vm._v("Status:")]),
                    _vm._v(" " + _vm._s(_vm.item.agentDb.status))
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _c("label", [_vm._v("Legal Name:")]),
                    _vm._v(" " + _vm._s(_vm.item.agentDb.name))
                  ])
                ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.actionsTab == 3
        ? _c(
            "div",
            { staticClass: "main" },
            [
              _c("div", { staticClass: "action-buttons" }, [
                _c(
                  "a",
                  {
                    attrs: { href: "" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.listWebsiteChecks.apply(null, arguments)
                      }
                    }
                  },
                  [_vm._v("Website Check")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "disabled",
                    attrs: { href: "" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                      }
                    }
                  },
                  [_vm._v("IDM")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "disabled",
                    attrs: { href: "" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                      }
                    }
                  },
                  [_vm._v("G2")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "disabled",
                    attrs: { href: "" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                      }
                    }
                  },
                  [_vm._v("KYC Site Scan")]
                )
              ]),
              _vm._v(" "),
              _vm.uwTab == 2
                ? _c("view-app-websitecheck", {
                    key: "webcheck",
                    attrs: { agent: true, uwList: _vm.webList }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.actionsTab == 4
        ? _c("div", { staticClass: "main" }, [
            _c("div", { staticClass: "action-buttons" }, [
              false
                ? _c(
                    "a",
                    {
                      class: { disabled: _vm.item.agentDb.status != "A04" },
                      attrs: { href: "" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          _vm.item.agentDb.status == "A04" && _vm.startSetup()
                        }
                      }
                    },
                    [_vm._v("Start New Setup")]
                  )
                : _vm._e(),
              _vm._v(" "),
              false && _vm.currentSetup && _vm.currentSetup.status == null
                ? _c(
                    "a",
                    {
                      attrs: { href: "" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.startSetup(_vm.currentSetup)
                        }
                      }
                    },
                    [_vm._v("Continue Setup")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.currentSetup
                ? _c(
                    "a",
                    {
                      attrs: { href: "" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.viewSetup(_vm.currentSetup)
                        }
                      }
                    },
                    [_vm._v("View Setup")]
                  )
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("table", { staticClass: "content-table file-list" }, [
              _vm._m(3),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.setups, function(setup, ai) {
                  return _c("tr", [
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.currentSetupId,
                            expression: "currentSetupId"
                          }
                        ],
                        attrs: { type: "radio", checked: "" },
                        domProps: {
                          value: setup.id,
                          checked: _vm._q(_vm.currentSetupId, setup.id)
                        },
                        on: {
                          change: function($event) {
                            _vm.currentSetupId = setup.id
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          _vm.formatDate(setup.created, "YYYY-MM-DD hh:mm")
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(setup.id))]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(setup.id))]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(setup.status))])
                  ])
                }),
                0
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.actionsTab == 5
        ? _c("div", { staticClass: "main" }, [
            _c("div", { staticClass: "action-buttons" }, [
              _c(
                "a",
                {
                  class: { disabled: _vm.emailDisabled.Agreement },
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      !_vm.emailDisabled.Agreement &&
                        _vm.sendEmailUser("Agreement")
                    }
                  }
                },
                [_vm._v("AG Agreement")]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  class: { disabled: _vm.emailDisabled.Declined },
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      !_vm.emailDisabled.Declined &&
                        _vm.sendEmailUser("Declined")
                    }
                  }
                },
                [_vm._v("AG Declined")]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  class: { disabled: _vm.emailDisabled.Closed },
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      !_vm.emailDisabled.Closed && _vm.sendEmailUser("Closed")
                    }
                  }
                },
                [_vm._v("AG Closed")]
              )
            ]),
            _vm._v(" "),
            _c("table", { staticClass: "file-list content-table" }, [
              _vm._m(4),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.emails, function(email) {
                  return _c("tr", [
                    _c("td", [_vm._v(_vm._s(email.emailType))]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          _vm.formatDate(email.created, "YYYY-MM-DD HH:mm:ss")
                        )
                      )
                    ])
                  ])
                }),
                0
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.actionsTab == 6 ? _c("div", { staticClass: "main" }) : _vm._e(),
      _vm._v(" "),
      _vm.actionsTab == 7
        ? _c("div", { staticClass: "main" }, [
            _c("div", { staticClass: "action-buttons" }, [
              _c(
                "a",
                {
                  class: { disabled: _vm.item.agentDb.status != "A02" },
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.item.agentDb.status == "A02" && _vm.startAgreement()
                    }
                  }
                },
                [_vm._v("Start Agreement")]
              )
            ]),
            _vm._v(" "),
            _c("table", { staticClass: "content-table file-list" }, [
              _vm._m(5),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.agreements, function(agreement, ai) {
                  return _c("tr", [
                    _c("td", [_vm._v(_vm._s(ai + 1))]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          _vm.formatDate(agreement.created, "YYYY-MM-DD hh:mm")
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _c(
                        "a",
                        {
                          attrs: {
                            target: "_blank",
                            href: _vm.agreementPath(agreement)
                          }
                        },
                        [_vm._v(_vm._s(agreement.name))]
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      agreement.filename
                        ? _c(
                            "a",
                            {
                              attrs: {
                                target: "_blank",
                                href: _vm.fileLink(agreement.filename)
                              }
                            },
                            [_vm._v(_vm._s(agreement.name + " signed"))]
                          )
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          _vm.formatDate(agreement.signed, "YYYY-MM-DD hh:mm")
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _c(
                        "a",
                        {
                          staticClass: "button",
                          attrs: { href: "" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.uploadSigned(agreement, ai)
                            }
                          }
                        },
                        [_vm._v("Upload")]
                      )
                    ])
                  ])
                }),
                0
              )
            ])
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th"),
        _vm._v(" "),
        _c("th", [_vm._v("Type")]),
        _vm._v(" "),
        _c("th", [_vm._v("Filename")]),
        _vm._v(" "),
        _c("th", [_vm._v("Uploaded")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th"),
        _vm._v(" "),
        _c("th", [_vm._v("AgentID")]),
        _vm._v(" "),
        _c("th", [_vm._v("Submitted")]),
        _vm._v(" "),
        _c("th", [_vm._v("Status")]),
        _vm._v(" "),
        _c("th", [_vm._v("Legal Name")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("input", { attrs: { type: "radio", checked: "" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticStyle: { width: "1%" } }, [_vm._v("#")]),
        _vm._v(" "),
        _c("th", [_vm._v("Build Date")]),
        _vm._v(" "),
        _c("th", [_vm._v("Build Id")]),
        _vm._v(" "),
        _c("th", [_vm._v("Build By")]),
        _vm._v(" "),
        _c("th", [_vm._v("Status")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Type")]),
        _vm._v(" "),
        _c("th", [_vm._v("Created")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticStyle: { width: "1%" } }, [_vm._v("#")]),
        _vm._v(" "),
        _c("th", [_vm._v("Date Created")]),
        _vm._v(" "),
        _c("th", [_vm._v("Agreement")]),
        _vm._v(" "),
        _c("th", [_vm._v("Date Signed")]),
        _vm._v(" "),
        _c("th", [_vm._v("Signed Agreement")]),
        _vm._v(" "),
        _c("th", [_vm._v("Action")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }