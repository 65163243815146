var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "mnb-input",
    _vm._b(
      {
        on: { change: _vm.uploadFile },
        model: {
          value: _vm.file,
          callback: function($$v) {
            _vm.file = $$v
          },
          expression: "file"
        }
      },
      "mnb-input",
      _vm.options,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }