var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.uwShown
      ? _c("div", { staticClass: "action-sub-buttons" }, [
          _c("a", { staticClass: "btn", on: { click: _vm.newDocCheck } }, [
            _vm._v("Run new")
          ]),
          _vm._v(" "),
          _vm.uwSelected
            ? _c(
                "a",
                {
                  staticClass: "btn",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.doShowItems(_vm.uwSelected)
                    }
                  }
                },
                [_vm._v("View")]
              )
            : _vm._e()
        ])
      : _c("div", { staticClass: "action-sub-buttons" }, [
          _c(
            "a",
            {
              staticClass: "btn",
              on: {
                click: function($event) {
                  _vm.uwShown = null
                }
              }
            },
            [_vm._v("Back")]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "btn",
              attrs: { href: _vm.printUrl, target: "_blank" }
            },
            [_vm._v("Print")]
          )
        ]),
    _vm._v(" "),
    !_vm.uwShown
      ? _c("table", { staticClass: "file-list content-table" }, [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "tbody",
            _vm._l(_vm.uwList, function(uwItem, uwI) {
              return _c("tr", [
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.uwSelected,
                        expression: "uwSelected"
                      }
                    ],
                    attrs: { type: "radio" },
                    domProps: {
                      value: uwItem.id,
                      checked: _vm._q(_vm.uwSelected, uwItem.id)
                    },
                    on: {
                      change: function($event) {
                        _vm.uwSelected = uwItem.id
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(_vm.uwList.length - uwI))]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    _vm._s(
                      _vm.formatDate(uwItem.createdDate, "YYYY-MM-DD HH:mm")
                    )
                  )
                ])
              ])
            }),
            0
          )
        ])
      : _vm.uwShown
      ? _c("div", { staticClass: "pre-check-details" }, [
          _c("div", { staticClass: "t2col toAnimate" }, [
            _c(
              "div",
              { staticStyle: { width: "100%" } },
              _vm._l(_vm.fieldIds, function(field, index) {
                return _c(
                  "div",
                  {
                    staticClass: "field",
                    class: { visible: _vm.shownItem > index }
                  },
                  [
                    _c("label", [_vm._v(_vm._s(_vm.fields[field]) + ":")]),
                    _vm._v(" "),
                    _vm.uwShownItem.documents[field] && field == "agreement"
                      ? _c("span", [
                          _c(
                            "a",
                            {
                              attrs: {
                                target: "_blank",
                                href: _vm.$parent.agreementPath(
                                  _vm.uwShownItem.documents[field]
                                )
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.uwShownItem.documents[field].filename
                                )
                              )
                            ]
                          )
                        ])
                      : _vm.uwShownItem.documents[field]
                      ? _c("span", [
                          _c(
                            "a",
                            {
                              attrs: {
                                target: "_blank",
                                href: _vm.$parent.fileLink(
                                  _vm.uwShownItem.documents[field].filename
                                )
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.uwShownItem.documents[field].filename
                                )
                              )
                            ]
                          )
                        ])
                      : _c("span", { staticClass: "empty" }, [_vm._v("NO")])
                  ]
                )
              }),
              0
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticStyle: { width: "1%" } }),
        _vm._v(" "),
        _c("th", [_vm._v("ID")]),
        _vm._v(" "),
        _c("th", [_vm._v("Date")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }