var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      [
        _c("div", { staticClass: "provider-form pad20" }, [
          _c(
            "div",
            { staticClass: "input-group" },
            [
              _c("label", [_vm._v("Tier Name")]),
              _vm._v(" "),
              _c(
                "validator",
                { attrs: { value: _vm.form.partnerTier, reqs: "char10" } },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.partnerTier,
                        expression: "form.partnerTier"
                      }
                    ],
                    domProps: { value: _vm.form.partnerTier },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.form, "partnerTier", $event.target.value)
                      }
                    }
                  })
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "input-group" },
            [
              _c("label", [_vm._v("Description")]),
              _vm._v(" "),
              _c(
                "validator",
                { attrs: { value: _vm.form.description, reqs: "char100" } },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.description,
                        expression: "form.description"
                      }
                    ],
                    domProps: { value: _vm.form.description },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.form, "description", $event.target.value)
                      }
                    }
                  })
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "input-group" },
            [
              _c("label", [_vm._v("Review Days")]),
              _vm._v(" "),
              _c("dyn-select", {
                attrs: { options: _vm.reviewDays },
                model: {
                  value: _vm.form.reviewDays,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "reviewDays", $$v)
                  },
                  expression: "form.reviewDays"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-small btn-primary mb-20",
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.saveNewItem()
              }
            }
          },
          [_vm._v("Add")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-small btn-primary mb-20",
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.resetItem()
              }
            }
          },
          [_vm._v("Reset")]
        )
      ],
      _vm._v(" "),
      _c("h4", { staticClass: "services-text mb-20" }),
      _vm._v(" "),
      _c("ul", { staticClass: "acc-results" }, [
        _c("li", {}, [
          _c(
            "div",
            { staticClass: "acc-list", staticStyle: { display: "block" } },
            [
              _c("table", [
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(_vm.partners, function(item, index) {
                    return _c("tr", [
                      _c("td", { staticStyle: { "text-align": "left" } }, [
                        _c("span", [_vm._v(_vm._s(item.partnerTier))])
                      ]),
                      _vm._v(" "),
                      _c("td", { staticStyle: { "text-align": "left" } }, [
                        _c("span", [_vm._v(_vm._s(item.description))])
                      ]),
                      _vm._v(" "),
                      _c("td", { staticStyle: { "text-align": "left" } }, [
                        _c("span", [_vm._v(_vm._s(item.reviewDays))])
                      ]),
                      _vm._v(" "),
                      _c("td")
                    ])
                  }),
                  0
                )
              ])
            ]
          )
        ])
      ])
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticStyle: { "text-align": "left" } }, [
          _vm._v("Partner Tier")
        ]),
        _vm._v(" "),
        _c("th", { staticStyle: { "text-align": "left" } }, [
          _vm._v("Description")
        ]),
        _vm._v(" "),
        _c("th", { staticStyle: { "text-align": "left" } }, [
          _vm._v("Review Days")
        ]),
        _vm._v(" "),
        _c("th", [_vm._v("Options")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }