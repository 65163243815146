var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.companyId
      ? _c(
          "div",
          {
            staticClass: "content-table-container",
            staticStyle: { overflow: "auto" }
          },
          [
            _c(
              "table",
              {
                staticClass: "content-table",
                staticStyle: { width: "1000px" }
              },
              [
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "tbody",
                  [
                    _vm._l(_vm.items, function(item, index) {
                      return _c("tr", [
                        _c("td", { staticClass: "text-left main-col" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: item.brandId,
                                expression: "item.brandId"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: { value: item.brandId },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(item, "brandId", $event.target.value)
                              }
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-left" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: item.dba,
                                expression: "item.dba"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: { value: item.dba },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(item, "dba", $event.target.value)
                              }
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-left" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: item.website,
                                expression: "item.website"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: { value: item.website },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(item, "website", $event.target.value)
                              }
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-left" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: item.phone,
                                expression: "item.phone"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: { value: item.phone },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(item, "phone", $event.target.value)
                              }
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-left" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: item.email,
                                expression: "item.email"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: { value: item.email },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(item, "email", $event.target.value)
                              }
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-left" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: item.address,
                                expression: "item.address"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: { value: item.address },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(item, "address", $event.target.value)
                              }
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-left" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: item.city,
                                expression: "item.city"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: { value: item.city },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(item, "city", $event.target.value)
                              }
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-left" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: item.state,
                                expression: "item.state"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: { value: item.state },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(item, "state", $event.target.value)
                              }
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-left" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: item.zip,
                                expression: "item.zip"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: { value: item.zip },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(item, "zip", $event.target.value)
                              }
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-left" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: item.country,
                                expression: "item.country"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: { value: item.country },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(item, "country", $event.target.value)
                              }
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c(
                            "a",
                            {
                              staticClass: "remove",
                              attrs: { href: "" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.deleteItem(index)
                                }
                              }
                            },
                            [_vm._v("×")]
                          )
                        ])
                      ])
                    }),
                    _vm._v(" "),
                    _c("tr", { staticClass: "new-row" }, [
                      _c("td", { attrs: { colspan: "11" } }, [
                        _c(
                          "a",
                          {
                            staticClass: "add",
                            attrs: { href: "" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.newItem.apply(null, arguments)
                              }
                            }
                          },
                          [_vm._v("+")]
                        )
                      ])
                    ])
                  ],
                  2
                )
              ]
            )
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticStyle: { width: "8%" } }, [_vm._v("Brand ID")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "8%" } }, [_vm._v("DBA")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "8%" } }, [_vm._v("Website")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "8%" } }, [_vm._v("Phone")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "8%" } }, [_vm._v("Email")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "8%" } }, [_vm._v("Address")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "8%" } }, [_vm._v("City")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "8%" } }, [_vm._v("State")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "8%" } }, [_vm._v("Zip")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "8%" } }, [_vm._v("Country")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "1%" } }, [_vm._v(" ")])
      ]),
      _vm._v(" "),
      _c("tr", [_c("th", { staticClass: "spacer", attrs: { colspan: "4" } })])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }