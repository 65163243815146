var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "element-checkbox-line" }, [
    _c("label", { class: { checked: _vm.value !== null } }, [_c("span")]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "title",
        on: {
          click: function($event) {
            $event.preventDefault()
            _vm.more = !_vm.more
          }
        }
      },
      [
        _vm._t("default"),
        _vm._v(" "),
        _c("formel-toggle", {
          attrs: { yes: true, no: false },
          on: { input: _vm.update },
          nativeOn: {
            click: function($event) {
              $event.stopPropagation()
            }
          },
          model: {
            value: _vm.actualValue,
            callback: function($$v) {
              _vm.actualValue = $$v
            },
            expression: "actualValue"
          }
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.more,
                expression: "more"
              }
            ],
            staticClass: "more"
          },
          [_vm._t("more")],
          2
        )
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "toggleMore",
        class: { opened: _vm.more },
        on: {
          click: function($event) {
            $event.preventDefault()
            _vm.more = !_vm.more
          }
        }
      },
      [_c("a", { attrs: { href: "" } })]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }