var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "notes-list" },
    [
      _vm.hasFilter
        ? _c("div", { staticClass: "toolbar" }, [
            _c(
              "a",
              {
                staticClass: "btn",
                class: { current: _vm.filter == null },
                attrs: { href: "" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    _vm.filter = null
                  }
                }
              },
              [_vm._v("All")]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "btn",
                class: { current: _vm.filter == 1 },
                attrs: { href: "" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    _vm.filter = 1
                  }
                }
              },
              [_vm._v("System")]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "btn",
                class: { current: _vm.filter == 0 },
                attrs: { href: "" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    _vm.filter = 0
                  }
                }
              },
              [_vm._v("User")]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("h4", [_vm._v("Notes")]),
      _vm._v(" "),
      _c(
        "ul",
        _vm._l(_vm.notesList, function(note, ni) {
          return _vm.filter == null || note.system == _vm.filter
            ? _c("li", [
                note.system == 0
                  ? _c(
                      "a",
                      {
                        staticClass: "remove",
                        attrs: { href: "" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.removeNote(ni)
                          }
                        }
                      },
                      [_vm._v("×")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("strong", [
                  _vm._v(
                    _vm._s(
                      note.created === "CURRENT_TIMESTAMP"
                        ? _vm.now
                        : note.created
                    ) + "\n        "
                  ),
                  _c("em", { staticClass: "link" }, [
                    _vm._v(
                      _vm._s(
                        (note.author && note.author.username) ||
                          _vm.currentUser.username
                      )
                    )
                  ]),
                  _vm._v(":\n      ")
                ]),
                _vm._v("\n      " + _vm._s(note.message) + "\n    ")
              ])
            : _vm._e()
        }),
        0
      ),
      _vm._v(" "),
      _c("div", { staticClass: "new-note" }, [
        _c("h4", [_vm._v("Additional notes")]),
        _vm._v(" "),
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.newNote,
              expression: "newNote"
            }
          ],
          domProps: { value: _vm.newNote },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.newNote = $event.target.value
            }
          }
        }),
        _vm._v(" "),
        _c(
          "button",
          {
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.createNote.apply(null, arguments)
              }
            }
          },
          [_vm._v("Create")]
        )
      ]),
      _vm._v(" "),
      _c("portal", { attrs: { to: "actions" } }, [
        _c("ul", [
          _c("li", [
            _c(
              "a",
              {
                attrs: { href: "" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.createNote.apply(null, arguments)
                  }
                }
              },
              [_vm._v("Create")]
            )
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }