var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login-content" }, [
    _c("img", { staticClass: "logo", attrs: { src: _vm.logo, alt: "" } }),
    _vm._v(" "),
    !_vm.submitted
      ? _c("p", [
          _vm._v(
            "Please enter your Account ID and username below to reset your password"
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.submitted
      ? _c(
          "form",
          {
            staticClass: "form-login",
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.doSubmit.apply(null, arguments)
              }
            }
          },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.accountId,
                  expression: "accountId"
                }
              ],
              attrs: {
                type: "text",
                name: "agentId",
                placeholder: "Account ID#",
                required: ""
              },
              domProps: { value: _vm.accountId },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.accountId = $event.target.value
                }
              }
            }),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.username,
                  expression: "username"
                }
              ],
              attrs: {
                type: "text",
                name: "username",
                placeholder: "Username",
                required: ""
              },
              domProps: { value: _vm.username },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.username = $event.target.value
                }
              }
            }),
            _vm._v(" "),
            _c("button", [_vm._v("Submit")])
          ]
        )
      : !_vm.success
      ? _c("div", { staticClass: "login-msg" }, [
          _c("h1", [_vm._v("Username Not Found!")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "Your username was not found. Please check your information and try again. If the problem persists or for immediate assistance, contact support."
            )
          ]),
          _vm._v(" "),
          _c("form", [
            _c(
              "button",
              {
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    _vm.submitted = false
                  }
                }
              },
              [_vm._v("Back")]
            )
          ])
        ])
      : _c("div", { staticClass: "login-msg" }, [
          _c("h1", [_vm._v("Reset Instructions Sent!")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "Please follow the instructions found in the email to reset your password. If you do not receive the email or for immediate assistance, contact support."
            )
          ])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }