var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form", { staticClass: "form-step" }, [
    _c("section", [
      _c("h2", [_vm._v("Business Profile")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pgf-main-cb type2" },
        [
          _c("p", { staticClass: "intro" }, [
            _vm._v("Does this business have a website that accepts payments?")
          ]),
          _vm._v(" "),
          _c(
            "validator",
            { attrs: { value: _vm.app.hasWebsite, reqs: "required" } },
            [
              _c("ul", { staticClass: "toggle-main" }, [
                _c("li", { staticClass: "disabled" }, [
                  _c(
                    "label",
                    { class: { checked: _vm.app.hasWebsite === "1" } },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.app.hasWebsite,
                            expression: "app.hasWebsite"
                          }
                        ],
                        attrs: {
                          type: "radio",
                          name: "hasParent",
                          value: "1",
                          disabled: ""
                        },
                        domProps: { checked: _vm._q(_vm.app.hasWebsite, "1") },
                        on: {
                          change: function($event) {
                            return _vm.$set(_vm.app, "hasWebsite", "1")
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("i"),
                      _vm._v(" "),
                      _c("strong", [_vm._v("Yes")])
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "disabled" }, [
                  _c(
                    "label",
                    { class: { checked: _vm.app.hasWebsite === "0" } },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.app.hasWebsite,
                            expression: "app.hasWebsite"
                          }
                        ],
                        attrs: {
                          type: "radio",
                          name: "hasParent",
                          value: "0",
                          disabled: ""
                        },
                        domProps: { checked: _vm._q(_vm.app.hasWebsite, "0") },
                        on: {
                          change: function($event) {
                            return _vm.$set(_vm.app, "hasWebsite", "0")
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("i"),
                      _vm._v(" "),
                      _c("strong", [_vm._v("No")])
                    ]
                  )
                ])
              ])
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.app.hasWebsite * 1
        ? _c("ul", { staticClass: "column-form type2 grayed" }, [
            _c("li", { staticClass: "large-title" }, [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field ve" },
                [
                  _c(
                    "validator",
                    { attrs: { value: _vm.app.websiteLive, reqs: "required" } },
                    [
                      _c(
                        "formel-toggle-slot",
                        {
                          model: {
                            value: _vm.app.websiteLive,
                            callback: function($$v) {
                              _vm.$set(_vm.app, "websiteLive", $$v)
                            },
                            expression: "app.websiteLive"
                          }
                        },
                        [
                          _c(
                            "validator",
                            {
                              attrs: {
                                value: _vm.app.websiteDate,
                                reqs:
                                  _vm.app.websiteLive === "0" ? "required" : ""
                              }
                            },
                            [
                              _c("datepicker", {
                                attrs: {
                                  disabled: _vm.app.websiteLive !== "0",
                                  placeholder: "If not, when?"
                                },
                                model: {
                                  value: _vm.app.websiteDate,
                                  callback: function($$v) {
                                    _vm.$set(_vm.app, "websiteDate", $$v)
                                  },
                                  expression: "app.websiteDate"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("li", [
              _c("label", [_vm._v("Website(s)")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                [
                  _c(
                    "validator",
                    {
                      attrs: {
                        value: _vm.app.website,
                        reqs: "required char1000"
                      }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.app.website,
                            expression: "app.website"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.app.website },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.app, "website", $event.target.value)
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("span", { staticClass: "pfx-info" }, [
                _vm._v("(please separate with commas)")
              ])
            ]),
            _vm._v(" "),
            _c("li", [
              _c("label", [_vm._v("Login details:")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                [
                  _c(
                    "validator",
                    {
                      attrs: {
                        value: _vm.app.websiteUsername,
                        reqs: "char1000"
                      }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.app.websiteUsername,
                            expression: "app.websiteUsername"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.app.websiteUsername },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.app,
                              "websiteUsername",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("span", { staticClass: "pfx-info" }, [_vm._v("Username")])
            ]),
            _vm._v(" "),
            _c("li", [
              _c("label", [_vm._v("  ")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                [
                  _c(
                    "validator",
                    {
                      attrs: {
                        value: _vm.app.websitePassword,
                        reqs: "char1000"
                      }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.app.websitePassword,
                            expression: "app.websitePassword"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.app.websitePassword },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.app,
                              "websitePassword",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("span", { staticClass: "pfx-info" }, [_vm._v("Password")])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("ul", { staticClass: "column-form type2" }, [
        _c("li", { staticClass: "fwidth" }, [
          _vm.app.industry != "Facilitator"
            ? _c("label", [
                _vm._v("Detailed description of products / services sold "),
                _c("span", [_vm._v("(explain business model)")])
              ])
            : _c("label", [
                _vm._v("Detailed description of products / services"),
                _c("br"),
                _vm._v("offered to Sub-Merchants "),
                _c("span", [_vm._v("(explain business model)")])
              ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field" },
            [
              _c(
                "validator",
                {
                  attrs: {
                    value: _vm.app.businessDescription,
                    reqs: "required char1000"
                  }
                },
                [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.app.businessDescription,
                        expression: "app.businessDescription"
                      }
                    ],
                    attrs: { type: "text" },
                    domProps: { value: _vm.app.businessDescription },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.app,
                          "businessDescription",
                          $event.target.value
                        )
                      }
                    }
                  })
                ]
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._m(1),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field" },
            [
              _c(
                "validator",
                { attrs: { value: _vm.app.businessLength, reqs: "required" } },
                [
                  _c("field-businesslength", {
                    model: {
                      value: _vm.app.businessLength,
                      callback: function($$v) {
                        _vm.$set(_vm.app, "businessLength", $$v)
                      },
                      expression: "app.businessLength"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _vm.app.industry != "Facilitator"
            ? _c("label", [_vm._v("Descriptor")])
            : _c("label", [_vm._v("Desired Descriptor Prefix")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field" },
            [
              _c(
                "validator",
                {
                  attrs: {
                    value: _vm.app.descriptor1,
                    reqs: "required descriptor"
                  }
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.app.descriptor1,
                        expression: "app.descriptor1"
                      }
                    ],
                    attrs: { type: "text" },
                    domProps: { value: _vm.app.descriptor1 },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.app, "descriptor1", $event.target.value)
                      }
                    }
                  })
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.app.industry != "Facilitator"
            ? _c("span", { staticClass: "pfx-info" }, [
                _vm._v(
                  "(to appear on customers' credit card statement; max 22 characters, for example: website or company name)"
                )
              ])
            : _c("span", { staticClass: "pfx-info" }, [
                _vm._v(
                  "(to appear on customers' statement before sub-merchant descriptor. max 3 characters)"
                )
              ])
        ]),
        _vm._v(" "),
        false
          ? _c("li", [
              _vm._m(2),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                [
                  _c(
                    "validator",
                    {
                      attrs: {
                        value: _vm.app.descriptor2,
                        reqs: "required descriptor"
                      }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.app.descriptor2,
                            expression: "app.descriptor2"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.app.descriptor2 },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.app,
                              "descriptor2",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        false
          ? _c("li", [
              _vm._m(3),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field ve" },
                [
                  _c(
                    "validator",
                    {
                      attrs: {
                        value: _vm.app.dynamicDescriptors,
                        reqs: "required"
                      }
                    },
                    [
                      _c("formel-toggle", {
                        model: {
                          value: _vm.app.dynamicDescriptors,
                          callback: function($$v) {
                            _vm.$set(_vm.app, "dynamicDescriptors", $$v)
                          },
                          expression: "app.dynamicDescriptors"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("li", [
          _vm._m(4),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field ve" },
            [
              _c(
                "validator",
                { attrs: { value: _vm.app.bankruptcy, reqs: "required" } },
                [
                  _c(
                    "formel-toggle-slot",
                    {
                      model: {
                        value: _vm.app.bankruptcy,
                        callback: function($$v) {
                          _vm.$set(_vm.app, "bankruptcy", $$v)
                        },
                        expression: "app.bankruptcy"
                      }
                    },
                    [
                      _c("validator", {
                        attrs: {
                          value: _vm.app.bankruptcyDate,
                          reqs: _vm.app.bankruptcy == 1 ? "required" : ""
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "fwidth" }, [
          _c(
            "label",
            [
              _vm._v(
                _vm._s(
                  _vm.app.industry != "Facilitator"
                    ? "Have you ever flagged or been in violation of any card scheme program?"
                    : "Have you ever experienced a breach, been flagged or in any violation of any card scheme program?"
                ) + "\n          "
              ),
              _c(
                "validator",
                { attrs: { value: _vm.app.violation, reqs: "required" } },
                [
                  _c("formel-toggle", {
                    model: {
                      value: _vm.app.violation,
                      callback: function($$v) {
                        _vm.$set(_vm.app, "violation", $$v)
                      },
                      expression: "app.violation"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("span", { staticClass: "pfx-info" }, [
                _vm._v(
                  "(e.g. Excessive Chargebacks, BRAM violation, Account Data Compromise)"
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field ve" },
            [
              _c(
                "validator",
                {
                  attrs: {
                    value: _vm.app.violationDetails,
                    reqs: _vm.app.violation == 1 ? "required char250" : ""
                  }
                },
                [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.app.violationDetails,
                        expression: "app.violationDetails"
                      }
                    ],
                    attrs: { placeholder: "Description goes here" },
                    domProps: { value: _vm.app.violationDetails },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.app,
                          "violationDetails",
                          $event.target.value
                        )
                      }
                    }
                  })
                ]
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("li", { staticStyle: { width: "calc(50% - 15px)" } }, [
          _vm._m(5),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field ve" },
            [
              _c(
                "validator",
                { attrs: { value: _vm.app.lastProvider, reqs: "" } },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.app.lastProvider,
                        expression: "app.lastProvider"
                      }
                    ],
                    attrs: { type: "text" },
                    domProps: { value: _vm.app.lastProvider },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.app, "lastProvider", $event.target.value)
                      }
                    }
                  })
                ]
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("li", { staticStyle: { width: "calc(50% - 15px)" } }, [
          _c(
            "div",
            { staticClass: "field ve" },
            [
              _c(
                "validator",
                { attrs: { value: _vm.app.lastReason, reqs: "" } },
                [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.app.lastReason,
                        expression: "app.lastReason"
                      }
                    ],
                    attrs: {
                      type: "text",
                      placeholder: "Reason for leaving current Provider"
                    },
                    domProps: { value: _vm.app.lastReason },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.app, "lastReason", $event.target.value)
                      }
                    }
                  })
                ]
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._m(6),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field ve" },
            [
              _c(
                "validator",
                { attrs: { value: _vm.app.acceptedCards, reqs: "required" } },
                [
                  _c("formel-toggle-slot", {
                    model: {
                      value: _vm.app.acceptedCards,
                      callback: function($$v) {
                        _vm.$set(_vm.app, "acceptedCards", $$v)
                      },
                      expression: "app.acceptedCards"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _c("label", { staticClass: "dh" }, [
            _vm._v("Estimated monthly sales volume")
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field money" },
            [
              _c(
                "validator",
                {
                  attrs: {
                    value: _vm.app.volume + " " + _vm.app.volumeCurrency,
                    reqs: "required money numval10"
                  }
                },
                [
                  _c("money", {
                    attrs: {
                      amount: _vm.app.volume,
                      currency: _vm.app.volumeCurrency
                    },
                    on: {
                      "update:amount": function($event) {
                        return _vm.$set(_vm.app, "volume", $event)
                      },
                      "update:currency": function($event) {
                        return _vm.$set(_vm.app, "volumeCurrency", $event)
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _c("label", { staticClass: "dh" }, [
            _vm._v("Average transaction value")
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field" },
            [
              _c(
                "validator",
                {
                  attrs: {
                    value:
                      _vm.app.avgTransaction +
                      " " +
                      _vm.app.avgTransactionCurrency,
                    reqs: "required money numval10"
                  }
                },
                [
                  _c("money", {
                    attrs: {
                      amount: _vm.app.avgTransaction,
                      currency: _vm.app.avgTransactionCurrency
                    },
                    on: {
                      "update:amount": function($event) {
                        return _vm.$set(_vm.app, "avgTransaction", $event)
                      },
                      "update:currency": function($event) {
                        return _vm.$set(
                          _vm.app,
                          "avgTransactionCurrency",
                          $event
                        )
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _c("label", { staticClass: "dh" }, [
            _vm._v("Highest transaction value")
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field" },
            [
              _c(
                "validator",
                {
                  attrs: {
                    value:
                      _vm.app.maxTransaction +
                      " " +
                      _vm.app.maxTransactionCurrency,
                    reqs: "required money numval10"
                  }
                },
                [
                  _c("money", {
                    attrs: {
                      amount: _vm.app.maxTransaction,
                      currency: _vm.app.maxTransactionCurrency
                    },
                    on: {
                      "update:amount": function($event) {
                        return _vm.$set(_vm.app, "maxTransaction", $event)
                      },
                      "update:currency": function($event) {
                        return _vm.$set(
                          _vm.app,
                          "maxTransactionCurrency",
                          $event
                        )
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ])
      ])
    ]),
    _vm._v(" "),
    _c("section", [
      _vm.app.acceptedCards == "1"
        ? _c("div", { staticClass: "ext-h4" }, [
            _c(
              "div",
              { staticClass: "toolbar currency-select" },
              [
                _c(
                  "validator",
                  {
                    attrs: { value: _vm.app.historyCurrency, reqs: "required" }
                  },
                  [
                    _c("dyn-select", {
                      attrs: { options: _vm.currencies },
                      model: {
                        value: _vm.app.historyCurrency,
                        callback: function($$v) {
                          _vm.$set(_vm.app, "historyCurrency", $$v)
                        },
                        expression: "app.historyCurrency"
                      }
                    }),
                    _vm._v(" "),
                    _c("label", [
                      _vm._v(
                        "Applicable currency for the\nvolumes specified below"
                      )
                    ])
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _vm.app.acceptedCards == "1"
              ? _c("h4", [
                  _vm._v(
                    "Please provide 6 months of most recent processing history below"
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.app.acceptedCards == "1"
              ? _c("div", { staticClass: "subtitle" }, [
                  _vm._v(
                    "(Clearly showing transactions, chargebacks and refunds per month)"
                  )
                ])
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("ul", { staticClass: "column-form type3" }, [
        _vm.app.acceptedCards == "1"
          ? _c("li", { staticClass: "head" }, [
              _c("label", [_vm._v("Credit Card Processing History")]),
              _vm._v(" "),
              _c(
                "div",
                _vm._l([1, 2, 3, 4, 5, 6], function(ago) {
                  return _c("div", [
                    _vm._v(
                      _vm._s(ago == 1 ? "Last Month" : ago + " Months Ago")
                    )
                  ])
                }),
                0
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.app.acceptedCards == "1"
          ? _c("li", [
              _c("label", [_vm._v("Number of transactions")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                _vm._l(_vm.app.history, function(ago, i) {
                  return _c(
                    "validator",
                    {
                      key: "ccph1x" + i,
                      attrs: { value: ago.tsCount, reqs: "number" }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: ago.tsCount,
                            expression: "ago.tsCount"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: ago.tsCount },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(ago, "tsCount", $event.target.value)
                          }
                        }
                      })
                    ]
                  )
                }),
                1
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.app.acceptedCards == "1"
          ? _c("li", [
              _c("label", [_vm._v("Transaction volume")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                _vm._l(_vm.app.history, function(ago, i) {
                  return _c(
                    "validator",
                    {
                      key: "ccph2x" + i,
                      attrs: { value: ago.tsVolume, reqs: "number" }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: ago.tsVolume,
                            expression: "ago.tsVolume"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: ago.tsVolume },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(ago, "tsVolume", $event.target.value)
                          }
                        }
                      })
                    ]
                  )
                }),
                1
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.app.acceptedCards == "1"
          ? _c("li", [
              _c("label", [_vm._v("Number of chargebacks")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                _vm._l(_vm.app.history, function(ago, i) {
                  return _c(
                    "validator",
                    {
                      key: "ccph3x" + i,
                      attrs: { value: ago.cbCount, reqs: "number" }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: ago.cbCount,
                            expression: "ago.cbCount"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: ago.cbCount },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(ago, "cbCount", $event.target.value)
                          }
                        }
                      })
                    ]
                  )
                }),
                1
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.app.acceptedCards == "1"
          ? _c("li", [
              _c("label", [_vm._v("Chargeback volume")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                _vm._l(_vm.app.history, function(ago, i) {
                  return _c(
                    "validator",
                    {
                      key: "ccph4x" + i,
                      attrs: { value: ago.cbVolume, reqs: "number" }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: ago.cbVolume,
                            expression: "ago.cbVolume"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: ago.cbVolume },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(ago, "cbVolume", $event.target.value)
                          }
                        }
                      })
                    ]
                  )
                }),
                1
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.app.acceptedCards == "1"
          ? _c("li", [
              _c("label", [_vm._v("Number of refunds")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                _vm._l(_vm.app.history, function(ago, i) {
                  return _c(
                    "validator",
                    {
                      key: "ccph5x" + i,
                      attrs: { value: ago.refundCount, reqs: "number" }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: ago.refundCount,
                            expression: "ago.refundCount"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: ago.refundCount },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(ago, "refundCount", $event.target.value)
                          }
                        }
                      })
                    ]
                  )
                }),
                1
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.app.acceptedCards == "1"
          ? _c("li", [
              _c("label", [_vm._v("Refund volume")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                _vm._l(_vm.app.history, function(ago, i) {
                  return _c(
                    "validator",
                    {
                      key: "ccph6x" + i,
                      attrs: { value: ago.refundVolume, reqs: "number" }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: ago.refundVolume,
                            expression: "ago.refundVolume"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: ago.refundVolume },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(ago, "refundVolume", $event.target.value)
                          }
                        }
                      })
                    ]
                  )
                }),
                1
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("ul", { staticClass: "column-form type2" }, [
        _c("li", { staticClass: "qw" }, [
          _vm.app.industry != "Facilitator"
            ? _c("label", [_vm._v("Transaction currencies applying for")])
            : _c("label", [
                _vm._v("Sub-Merchants"),
                _c("br"),
                _vm._v("Transaction currencies")
              ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field" },
            [
              _c(
                "validator",
                {
                  attrs: {
                    value: _vm.app.transactionCurrencies,
                    reqs: "required letters"
                  }
                },
                [
                  _c("field-currencies", {
                    model: {
                      value: _vm.app.transactionCurrencies,
                      callback: function($$v) {
                        _vm.$set(_vm.app, "transactionCurrencies", $$v)
                      },
                      expression: "app.transactionCurrencies"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "halfw split-tri" }, [
          _vm.app.industry != "Facilitator"
            ? _c("label", [_vm._v("Payment types applying for:")])
            : _c("label", [_vm._v("Payment types for Sub-Merchants:")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field" },
            [
              _c(
                "validator",
                {
                  attrs: {
                    value:
                      _vm.app.cardTypes && _vm.app.cardTypes.length
                        ? _vm.app.cardTypes
                        : _vm.app.cardTypesOther,
                    reqs: "required"
                  }
                },
                [
                  _vm.app.mainType == "cp"
                    ? _c("multiselect", {
                        attrs: {
                          other: _vm.app.cardTypesOther,
                          hasOther: "1",
                          options: [
                            "Visa",
                            "MasterCard",
                            "Discover",
                            "AMEX",
                            "China UnionPay",
                            "Apple Pay",
                            "Google Pay",
                            "Interac Online!disabled",
                            "Interac Debit",
                            "ACH/EFT"
                          ]
                        },
                        on: {
                          "update:other": function($event) {
                            return _vm.$set(_vm.app, "cardTypesOther", $event)
                          }
                        },
                        model: {
                          value: _vm.app.cardTypes,
                          callback: function($$v) {
                            _vm.$set(_vm.app, "cardTypes", $$v)
                          },
                          expression: "app.cardTypes"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.app.mainType == "cnp"
                    ? _c("multiselect", {
                        attrs: {
                          other: _vm.app.cardTypesOther,
                          hasOther: "1",
                          options: [
                            "Visa",
                            "MasterCard",
                            "Discover",
                            "AMEX",
                            "China UnionPay",
                            "Apple Pay",
                            "Google Pay",
                            "Interac Online",
                            "Interac Debit!disabled",
                            "ACH/EFT"
                          ]
                        },
                        on: {
                          "update:other": function($event) {
                            return _vm.$set(_vm.app, "cardTypesOther", $event)
                          }
                        },
                        model: {
                          value: _vm.app.cardTypes,
                          callback: function($$v) {
                            _vm.$set(_vm.app, "cardTypes", $$v)
                          },
                          expression: "app.cardTypes"
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "qw half-split" }, [
          _c("label", [_vm._v("When does payment take place?")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field" },
            [
              _c(
                "validator",
                {
                  attrs: {
                    value:
                      _vm.app.paymentMoment && _vm.app.paymentMoment.length
                        ? _vm.app.paymentMoment
                        : _vm.app.paymentMomentOther,
                    reqs: "required"
                  }
                },
                [
                  _c("multiselect", {
                    attrs: {
                      other: _vm.app.paymentMomentOther,
                      hasOther: "1",
                      options: ["Upon purchase", "With download", "On delivery"]
                    },
                    on: {
                      "update:other": function($event) {
                        return _vm.$set(_vm.app, "paymentMomentOther", $event)
                      }
                    },
                    model: {
                      value: _vm.app.paymentMoment,
                      callback: function($$v) {
                        _vm.$set(_vm.app, "paymentMoment", $$v)
                      },
                      expression: "app.paymentMoment"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "fwidth fwx" }, [
          _c("label", [
            _c("em", [
              _vm._v(
                _vm._s(
                  (_vm.app.originDomestic * 1 || 0) +
                    (_vm.app.originEurope * 1 || 0) +
                    (_vm.app.originUSA * 1 || 0) +
                    (_vm.app.originAsia * 1 || 0) +
                    (_vm.app.originOther * 1 || 0)
                ) + "%"
              )
            ]),
            _vm._v(" Origin of transactions "),
            _c("span", [_vm._v("(total should equal 100%)")])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field" },
            [
              _c(
                "validator",
                {
                  staticClass: "multipercent",
                  attrs: {
                    value: [
                      _vm.app.originDomestic,
                      _vm.app.originEurope,
                      _vm.app.originUSA,
                      _vm.app.originAsia,
                      _vm.app.originOther
                    ],
                    reqs: "sum100"
                  }
                },
                [
                  _c("div", [
                    _c("label", [_vm._v("Domestic")]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.app.originDomestic,
                          expression: "app.originDomestic"
                        }
                      ],
                      attrs: { type: "text" },
                      domProps: { value: _vm.app.originDomestic },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.app,
                            "originDomestic",
                            $event.target.value
                          )
                        }
                      }
                    }),
                    _c("span", [_vm._v("%")])
                  ]),
                  _vm._v(" "),
                  _c("div", { class: { disabled: _vm.app.mainType == "cp" } }, [
                    _c("label", [_vm._v("USA")]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.app.originUSA,
                          expression: "app.originUSA"
                        }
                      ],
                      attrs: {
                        type: "text",
                        disabled: _vm.app.mainType == "cp"
                      },
                      domProps: { value: _vm.app.originUSA },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.app, "originUSA", $event.target.value)
                        }
                      }
                    }),
                    _c("span", [_vm._v("%")])
                  ]),
                  _vm._v(" "),
                  _c("div", { class: { disabled: _vm.app.mainType == "cp" } }, [
                    _c("label", [_vm._v("Europe")]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.app.originEurope,
                          expression: "app.originEurope"
                        }
                      ],
                      attrs: {
                        type: "text",
                        disabled: _vm.app.mainType == "cp"
                      },
                      domProps: { value: _vm.app.originEurope },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.app, "originEurope", $event.target.value)
                        }
                      }
                    }),
                    _c("span", [_vm._v("%")])
                  ]),
                  _vm._v(" "),
                  _c("div", { class: { disabled: _vm.app.mainType == "cp" } }, [
                    _c("label", [_vm._v("Asia")]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.app.originAsia,
                          expression: "app.originAsia"
                        }
                      ],
                      attrs: {
                        type: "text",
                        disabled: _vm.app.mainType == "cp"
                      },
                      domProps: { value: _vm.app.originAsia },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.app, "originAsia", $event.target.value)
                        }
                      }
                    }),
                    _c("span", [_vm._v("%")])
                  ]),
                  _vm._v(" "),
                  _c("div", { class: { disabled: _vm.app.mainType == "cp" } }, [
                    _c("label", [_vm._v("Rest")]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.app.originOther,
                          expression: "app.originOther"
                        }
                      ],
                      attrs: {
                        type: "text",
                        disabled: _vm.app.mainType == "cp"
                      },
                      domProps: { value: _vm.app.originOther },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.app, "originOther", $event.target.value)
                        }
                      }
                    }),
                    _c("span", [_vm._v("%")]),
                    _vm._v(" "),
                    _c("span", { staticClass: "pfx-info" }, [
                      _vm._v("(Rest of the world)")
                    ])
                  ])
                ]
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "fwidth fwx" }, [
          _c("label", [
            _c("em", [
              _vm._v(
                _vm._s(
                  (_vm.app.methodECommerce * 1 || 0) +
                    (_vm.app.methodPOS * 1 || 0) +
                    (_vm.app.methodMPOS * 1 || 0) +
                    (_vm.app.methodMCommerce * 1 || 0) +
                    (_vm.app.methodMOTO * 1 || 0) +
                    (_vm.app.methodApp * 1 || 0)
                ) + "%"
              )
            ]),
            _vm._v(" Method of acceptance "),
            _c("span", [_vm._v("(total should equal 100%)")])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field" },
            [
              _c(
                "validator",
                {
                  staticClass: "multipercent",
                  attrs: {
                    value: [
                      _vm.app.methodECommerce,
                      _vm.app.methodPOS,
                      _vm.app.methodMPOS,
                      _vm.app.methodMCommerce,
                      _vm.app.methodMOTO,
                      _vm.app.methodApp
                    ],
                    reqs: "sum100"
                  }
                },
                [
                  _c("div", { class: { disabled: _vm.app.mainType == "cp" } }, [
                    _c("label", [_vm._v("E-Commerce")]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.app.methodECommerce,
                          expression: "app.methodECommerce"
                        }
                      ],
                      attrs: {
                        type: "text",
                        disabled: _vm.app.mainType == "cp"
                      },
                      domProps: { value: _vm.app.methodECommerce },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.app,
                            "methodECommerce",
                            $event.target.value
                          )
                        }
                      }
                    }),
                    _c("span", [_vm._v("%")])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { class: { disabled: _vm.app.mainType == "cnp" } },
                    [
                      _c("label", [_vm._v("Card-Present")]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.app.methodPOS,
                            expression: "app.methodPOS"
                          }
                        ],
                        attrs: {
                          type: "text",
                          disabled: _vm.app.mainType == "cnp"
                        },
                        domProps: { value: _vm.app.methodPOS },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.app, "methodPOS", $event.target.value)
                          }
                        }
                      }),
                      _c("span", [_vm._v("%")]),
                      _vm._v(" "),
                      _c("span", { staticClass: "pfx-info" }, [
                        _vm._v("(Point of sale)")
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { class: { disabled: _vm.app.mainType == "cnp" } },
                    [
                      _c("label", [_vm._v("Mobile POS")]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.app.methodMPOS,
                            expression: "app.methodMPOS"
                          }
                        ],
                        attrs: {
                          type: "text",
                          disabled: _vm.app.mainType == "cnp"
                        },
                        domProps: { value: _vm.app.methodMPOS },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.app, "methodMPOS", $event.target.value)
                          }
                        }
                      }),
                      _c("span", [_vm._v("%")]),
                      _vm._v(" "),
                      _c("span", { staticClass: "pfx-info" }, [
                        _vm._v("(Mobile POS)")
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  false
                    ? _c("div", [
                        _c("label", [_vm._v("M-Commerce")]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.app.methodMCommerce,
                              expression: "app.methodMCommerce"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: _vm.app.methodMCommerce },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.app,
                                "methodMCommerce",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _c("span", [_vm._v("%")]),
                        _vm._v(" "),
                        _c("span", { staticClass: "pfx-info" }, [
                          _vm._v("(Mobile payments)")
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { class: { disabled: _vm.app.mainType == "cp" } }, [
                    _c("label", [_vm._v("MOTO")]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.app.methodMOTO,
                          expression: "app.methodMOTO"
                        }
                      ],
                      attrs: {
                        type: "text",
                        disabled: _vm.app.mainType == "cp"
                      },
                      domProps: { value: _vm.app.methodMOTO },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.app, "methodMOTO", $event.target.value)
                        }
                      }
                    }),
                    _c("span", [_vm._v("%")]),
                    _vm._v(" "),
                    _c("span", { staticClass: "pfx-info" }, [
                      _vm._v("(Mail-order / Telephone-order)")
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { class: { disabled: _vm.app.mainType == "cp" } }, [
                    _c("label", [_vm._v("In-App")]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.app.methodApp,
                          expression: "app.methodApp"
                        }
                      ],
                      attrs: {
                        type: "text",
                        disabled: _vm.app.mainType == "cp"
                      },
                      domProps: { value: _vm.app.methodApp },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.app, "methodApp", $event.target.value)
                        }
                      }
                    }),
                    _c("span", [_vm._v("%")]),
                    _vm._v(" "),
                    _c("span", { staticClass: "pfx-info" }, [
                      _vm._v("(In-App Commerce)")
                    ])
                  ])
                ]
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _c("label", [_vm._v("Payment frequency")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field multirow" },
            [
              _c(
                "validator",
                { attrs: { value: _vm.app.frequency, reqs: "required" } },
                [
                  _c("multiselect", {
                    attrs: {
                      hasOther: false,
                      options: [
                        "One-time payment",
                        "Recurring payment <span class='pfx-info'>(subscription)</span>"
                      ]
                    },
                    model: {
                      value: _vm.app.frequency,
                      callback: function($$v) {
                        _vm.$set(_vm.app, "frequency", $$v)
                      },
                      expression: "app.frequency"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "tqw singlerow" }, [
          _c("label", [
            _vm._v(" Delivery time for goods/services "),
            _c("span", [_vm._v("(total should equal 100%)")]),
            _c("em", [
              _vm._v(
                _vm._s(
                  (_vm.app.deliveryTime1 * 1 || 0) +
                    (_vm.app.deliveryTime2 * 1 || 0) +
                    (_vm.app.deliveryTime3 * 1 || 0) +
                    (_vm.app.deliveryTime4 * 1 || 0)
                ) + "%"
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field" },
            [
              _c(
                "validator",
                {
                  staticClass: "multipercent",
                  attrs: {
                    value: [
                      _vm.app.deliveryTime1,
                      _vm.app.deliveryTime2,
                      _vm.app.deliveryTime3,
                      _vm.app.deliveryTime4
                    ],
                    reqs: "sum100"
                  }
                },
                [
                  _c("div", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.app.deliveryTime1,
                          expression: "app.deliveryTime1"
                        }
                      ],
                      attrs: { type: "text" },
                      domProps: { value: _vm.app.deliveryTime1 },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.app,
                            "deliveryTime1",
                            $event.target.value
                          )
                        }
                      }
                    }),
                    _c("span", [_vm._v("%")]),
                    _vm._v(" "),
                    _c("label", [_vm._v("Immediately")])
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.app.deliveryTime2,
                          expression: "app.deliveryTime2"
                        }
                      ],
                      attrs: { type: "text" },
                      domProps: { value: _vm.app.deliveryTime2 },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.app,
                            "deliveryTime2",
                            $event.target.value
                          )
                        }
                      }
                    }),
                    _c("span", [_vm._v("%")]),
                    _vm._v(" "),
                    _c("label", [_vm._v("Within 4 weeks")])
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.app.deliveryTime3,
                          expression: "app.deliveryTime3"
                        }
                      ],
                      attrs: { type: "text" },
                      domProps: { value: _vm.app.deliveryTime3 },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.app,
                            "deliveryTime3",
                            $event.target.value
                          )
                        }
                      }
                    }),
                    _c("span", [_vm._v("%")]),
                    _vm._v(" "),
                    _c("label", [_vm._v("Within 5-14 weeks")])
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.app.deliveryTime4,
                          expression: "app.deliveryTime4"
                        }
                      ],
                      attrs: { type: "text" },
                      domProps: { value: _vm.app.deliveryTime4 },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.app,
                            "deliveryTime4",
                            $event.target.value
                          )
                        }
                      }
                    }),
                    _c("span", [_vm._v("%")]),
                    _vm._v(" "),
                    _c("label", [_vm._v("More than 14 weeks")])
                  ])
                ]
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "fwidth" }, [
          _c(
            "div",
            { staticClass: "complex-label" },
            [
              _vm.app.industry != "Facilitator"
                ? _c("label", [
                    _vm._v("Do you offer / make use of affiliate programs?")
                  ])
                : _c("label", [
                    _vm._v(
                      "Do your sub-merchants offer / make use of affiliate programs?"
                    )
                  ]),
              _vm._v(" "),
              _c(
                "validator",
                { attrs: { value: _vm.app.affiliate, reqs: "required" } },
                [
                  _c("formel-toggle", {
                    class: { disabled: _vm.app.mainType == "cp" },
                    style:
                      _vm.app.mainType == "cp" ? "pointer-events: none" : "",
                    model: {
                      value: _vm.app.affiliate,
                      callback: function($$v) {
                        _vm.$set(_vm.app, "affiliate", $$v)
                      },
                      expression: "app.affiliate"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field ve ve2" },
            [
              _c(
                "validator",
                {
                  attrs: {
                    value: _vm.app.affiliateDetails,
                    reqs: _vm.app.affiliate == 1 ? "required" : ""
                  }
                },
                [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.app.affiliateDetails,
                        expression: "app.affiliateDetails"
                      }
                    ],
                    attrs: { disabled: _vm.app.affiliate != 1 },
                    domProps: { value: _vm.app.affiliateDetails },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.app,
                          "affiliateDetails",
                          $event.target.value
                        )
                      }
                    }
                  })
                ]
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("li", { class: { split5: _vm.app.industry == "Facilitator" } }, [
          _vm.app.industry != "Facilitator"
            ? _c("label", [_vm._v("Customer service email")])
            : _c("label", [_vm._v("Merchant service email")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field" },
            [
              _c(
                "validator",
                {
                  attrs: { value: _vm.app.emailService, reqs: "required email" }
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.app.emailService,
                        expression: "app.emailService"
                      }
                    ],
                    attrs: { type: "text" },
                    domProps: { value: _vm.app.emailService },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.app, "emailService", $event.target.value)
                      }
                    }
                  })
                ]
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("li", { class: { split5: _vm.app.industry == "Facilitator" } }, [
          _vm.app.industry != "Facilitator"
            ? _c("label", [_vm._v("Customer service telephone")])
            : _c("label", [_vm._v("Merchant service telephone")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field" },
            [
              _c(
                "validator",
                {
                  attrs: { value: _vm.app.phoneService, reqs: "required phone" }
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.app.phoneService,
                        expression: "app.phoneService"
                      }
                    ],
                    attrs: { type: "text" },
                    domProps: { value: _vm.app.phoneService },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.app, "phoneService", $event.target.value)
                      }
                    }
                  })
                ]
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("li", { class: { split5: _vm.app.industry == "Facilitator" } }, [
          _c("label", [_vm._v("Chargeback notification email")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field" },
            [
              _c(
                "validator",
                {
                  attrs: {
                    value: _vm.app.emailChargeback,
                    reqs: "required email"
                  }
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.app.emailChargeback,
                        expression: "app.emailChargeback"
                      }
                    ],
                    attrs: { type: "text" },
                    domProps: { value: _vm.app.emailChargeback },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.app,
                          "emailChargeback",
                          $event.target.value
                        )
                      }
                    }
                  })
                ]
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _vm.app.industry != "Facilitator" ? _c("li", [_vm._v(" ")]) : _vm._e(),
        _vm._v(" "),
        _vm.app.industry == "Facilitator"
          ? _c("li", { staticClass: "split5" }, [
              _c("label", [_vm._v("Support email")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                [
                  _c(
                    "validator",
                    {
                      attrs: {
                        value: _vm.app.supportEmail,
                        reqs: "required email"
                      }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.app.supportEmail,
                            expression: "app.supportEmail"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.app.supportEmail },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.app,
                              "supportEmail",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.app.industry == "Facilitator"
          ? _c("li", { staticClass: "split5" }, [
              _c("label", [_vm._v("Support phone")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                [
                  _c(
                    "validator",
                    {
                      attrs: {
                        value: _vm.app.supportPhone,
                        reqs: "required phone"
                      }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.app.supportPhone,
                            expression: "app.supportPhone"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.app.supportPhone },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.app,
                              "supportPhone",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              )
            ])
          : _vm._e()
      ])
    ]),
    _vm._v(" "),
    _c("section", [
      _c("h2", { staticClass: "bline" }, [_vm._v("Security measures")]),
      _vm._v(" "),
      _c("ul", { staticClass: "column-form type2 cf-3sp" }, [
        _c("li", { staticClass: "fwidth" }, [
          _vm._m(7),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field" },
            [
              _c(
                "validator",
                {
                  attrs: {
                    value: _vm.app.securityMeasures,
                    reqs: "required char500"
                  }
                },
                [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.app.securityMeasures,
                        expression: "app.securityMeasures"
                      }
                    ],
                    domProps: { value: _vm.app.securityMeasures },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.app,
                          "securityMeasures",
                          $event.target.value
                        )
                      }
                    }
                  })
                ]
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _c("label", [_vm._v("Would you like to apply for 3D Secure?")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field ve" },
            [
              _c(
                "validator",
                { attrs: { value: _vm.app.use3dSecure, reqs: "required" } },
                [
                  _c(
                    "formel-toggle-slot",
                    {
                      class: { disabled: _vm.app.mainType == "cp" },
                      style:
                        _vm.app.mainType == "cp" ? "pointer-events: none" : "",
                      model: {
                        value: _vm.app.use3dSecure,
                        callback: function($$v) {
                          _vm.$set(_vm.app, "use3dSecure", $$v)
                        },
                        expression: "app.use3dSecure"
                      }
                    },
                    [
                      _c("validator", {
                        attrs: { value: _vm.app.use3dSecureMPI, reqs: "" }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("span", { staticClass: "pfx-info" }, [
            _vm._v("(MasterCard SecureCode, VerifiedByVisa)")
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _vm.app.industry != "Facilitator"
            ? _c("label", [_vm._v("Will you use CVC")])
            : _c("label", [_vm._v("Will you require CVC")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field ve" },
            [
              _c(
                "validator",
                {
                  staticStyle: { overflow: "hidden" },
                  attrs: { value: _vm.app.useCVC, reqs: "required" }
                },
                [
                  _c("formel-toggle", {
                    class: { disabled: _vm.app.mainType == "cp" },
                    style:
                      _vm.app.mainType == "cp" ? "pointer-events: none" : "",
                    model: {
                      value: _vm.app.useCVC,
                      callback: function($$v) {
                        _vm.$set(_vm.app, "useCVC", $$v)
                      },
                      expression: "app.useCVC"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("span", { staticClass: "pfx-info" }, [
            _vm._v("(Card Verification Code printed on credit card)")
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _vm.app.industry != "Facilitator"
            ? _c("label", [_vm._v("Would you like to apply for AVS")])
            : _c("label", [_vm._v("Will you require AVS")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field ve" },
            [
              _c(
                "validator",
                {
                  staticStyle: { overflow: "hidden" },
                  attrs: { value: _vm.app.useAVS, reqs: "required" }
                },
                [
                  _c("formel-toggle", {
                    class: { disabled: _vm.app.mainType == "cp" },
                    style:
                      _vm.app.mainType == "cp" ? "pointer-events: none" : "",
                    model: {
                      value: _vm.app.useAVS,
                      callback: function($$v) {
                        _vm.$set(_vm.app, "useAVS", $$v)
                      },
                      expression: "app.useAVS"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("span", { staticClass: "pfx-info" }, [
            _vm._v("(Address Verification)")
          ])
        ]),
        _vm._v(" "),
        _c("li", [_vm._v(" ")])
      ]),
      _vm._v(" "),
      _vm._m(8)
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "buttons" }, [
      _c(
        "a",
        {
          staticClass: "btn btn-large btn-plain btn-back",
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.$emit("prev")
            }
          }
        },
        [_vm._v("Back")]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "btn btn-large btn-warn",
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.$emit("save")
            }
          }
        },
        [_vm._v("Save")]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "btn btn-large btn-success btn-next",
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.nextStep.apply(null, arguments)
            }
          }
        },
        [_vm._v("Next Step")]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_vm._v("Is website live?"), _c("span")])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_vm._v("Length of time in business "), _c("span")])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [
      _vm._v("DBA/City Field "),
      _c("span", [
        _vm._v(
          "(second line of descriptor, max 12 characters, for example: city of customer service phone number)"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [
      _vm._v('Will you use "Dynamic Descriptors"? '),
      _c("span")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [
      _vm._v("Have you ever filed for bankruptcy? "),
      _c("span")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [
      _vm._v("Current/previous Payment Provider"),
      _c("span")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [
      _vm._v("Do you currently or have you previously accepted credit cards? "),
      _c("span")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [
      _vm._v("Describe ALL security measures AND fraud/chargeback handling"),
      _c("span", { staticClass: "pfx-info" }, [_vm._v("(in detail)")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "footnotes" }, [
      _c("li", [
        _c("sup", [_vm._v("1")]),
        _vm._v(
          " MPI = Merchant Plug In (sofware required for processing 3D Secure transactions)."
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }