var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.document
    ? _c(
        "div",
        [
          _c(
            "popup",
            {
              on: {
                close: function($event) {
                  return _vm.$emit("close")
                }
              },
              model: {
                value: _vm.showPreview,
                callback: function($$v) {
                  _vm.showPreview = $$v
                },
                expression: "showPreview"
              }
            },
            [
              _c("div", { staticClass: "doc-title" }, [
                _vm._v(_vm._s(_vm.title))
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "title" },
                [
                  _vm._v("\n      ID: " + _vm._s(_vm.documentId)),
                  _c("br"),
                  _vm._v(" "),
                  _c("small", [
                    _vm._v(
                      "Submitted by: " +
                        _vm._s(_vm.user.fullName) +
                        ", " +
                        _vm._s(_vm.user.email) +
                        ", " +
                        _vm._s(_vm.userGroup(_vm.user.groups)) +
                        ", " +
                        _vm._s(_vm.formatDate(_vm.document.created, "LLLL"))
                    )
                  ]),
                  _c("br"),
                  _c("br"),
                  _vm._v(" "),
                  _c("a", { attrs: { href: _vm.UrlPDF, target: "_blank" } }, [
                    _c("button", { staticClass: "btn btn-xs" }, [
                      _vm._v("View")
                    ])
                  ]),
                  _vm._v(" "),
                  _vm.document.signed && _vm.document.documents.length
                    ? _vm._l(_vm.document.documents, function(file, index) {
                        return _c(
                          "a",
                          {
                            key: file.id,
                            attrs: {
                              href: _vm.formSignedDocUrl(file.filename),
                              target: "_blank"
                            }
                          },
                          [
                            _c("button", { staticClass: "btn btn-xs" }, [
                              _vm._v("View Signed "),
                              index > 0
                                ? _c("span", [_vm._v(_vm._s(index + 1))])
                                : _vm._e()
                            ])
                          ]
                        )
                      })
                    : _vm._e()
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "tables table-wrapper" },
                [
                  _vm._v(
                    "\n      Date Received: " +
                      _vm._s(_vm.formatDate(_vm.dateReceived, "MM/DD/YYYY")) +
                      "\n      "
                  ),
                  _vm.document.type === "IUAR"
                    ? _c("table", { staticClass: "mt-20" }, [
                        _c("thead", [
                          _c("tr", [
                            _c("th", [_vm._v("Employee FIrst Name")]),
                            _vm._v(" "),
                            _c("th", [_vm._v("Employee Last Name")]),
                            _vm._v(" "),
                            _c("th", [_vm._v("Email")]),
                            _vm._v(" "),
                            _c("th", [_vm._v("Department")])
                          ])
                        ]),
                        _vm._v(" "),
                        _c("tbody", [
                          _c("tr", [
                            _c("td", [_vm._v("First Name")]),
                            _vm._v(" "),
                            _c("td", [_vm._v("Last Name")]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(_vm._s(_vm.document.userId.email))
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.userGroup(_vm.document.userId.groups)
                                )
                              )
                            ])
                          ])
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(_vm.tables[_vm.document.type], function(table, idx) {
                    return _c("table", { key: idx, staticClass: "mt-20" }, [
                      _c("thead", [
                        _c(
                          "tr",
                          _vm._l(table, function(item) {
                            return _c("th", { key: item.id }, [
                              _vm._v(_vm._s(_vm.trans[_vm.document.type][item]))
                            ])
                          }),
                          0
                        )
                      ]),
                      _vm._v(" "),
                      _c("tbody", [
                        _c(
                          "tr",
                          _vm._l(table, function(item) {
                            return _c("td", { key: item.id }, [
                              _vm._v(_vm._s(_vm.displayValue(item)))
                            ])
                          }),
                          0
                        )
                      ])
                    ])
                  }),
                  _vm._v(" "),
                  !["EXPIRED", "CXL", "RESOLVED"].includes(_vm.document.status)
                    ? _c("div", { staticClass: "table-actions" }, [
                        _c("span", [_vm._v("Options:")]),
                        _vm._v(" "),
                        _vm.document.status !== "COMPLETE"
                          ? _c(
                              "a",
                              {
                                staticClass: "ta-btn",
                                on: {
                                  click: function($event) {
                                    return _vm.$emit("cxl", _vm.document.id)
                                  }
                                }
                              },
                              [_vm._v("CXL")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        ["COMPLETE", "PENDING"].includes(_vm.document.status)
                          ? _c(
                              "a",
                              {
                                staticClass: "ta-btn",
                                on: {
                                  click: function($event) {
                                    return _vm.$emit("upl", _vm.document.id)
                                  }
                                }
                              },
                              [_vm._v("UPL")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        ["COMPLETE"].includes(_vm.document.status)
                          ? _c(
                              "a",
                              {
                                staticClass: "ta-btn",
                                on: {
                                  click: function($event) {
                                    return _vm.$emit("res", _vm.document.id)
                                  }
                                }
                              },
                              [_vm._v("RES")]
                            )
                          : _vm._e()
                      ])
                    : _vm._e()
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "form",
                { staticClass: "notes preq-form" },
                [
                  _c("notes", {
                    staticClass: "appl-notes",
                    attrs: {
                      notes: _vm.notes,
                      type: "ComplianceDocument",
                      id: _vm.document.id
                    }
                  })
                ],
                1
              )
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }