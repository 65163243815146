var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", [
        false ? _c("div", { staticClass: "action-sub-buttons" }) : _vm._e(),
        _vm._v(" "),
        _vm.itemList && _vm.itemList.length
          ? _c("table", { staticClass: "file-list content-table" }, [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "tbody",
                [
                  _vm.item.accountType == "Personal"
                    ? _c("tr", [
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm.item.personal
                                ? _vm.item.personal.firstName +
                                    " " +
                                    _vm.item.personal.lastName
                                : _vm.item.business.companyName +
                                    " " +
                                    _vm.item.business.companyType
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                (_vm.itemList[0].response &&
                                  _vm.itemList[0].response.res) ||
                                  "ERROR"
                              ) +
                              "\n                "
                          ),
                          _c(
                            "a",
                            {
                              staticClass: "tb-btn",
                              attrs: { href: "" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.sendIDVPersonal(
                                    _vm.item.personal.id
                                  )
                                }
                              }
                            },
                            [_vm._v("IDV")]
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c(
                            "a",
                            {
                              staticClass: "tb-btn",
                              attrs: { href: "" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.viewResult(_vm.itemList[0])
                                }
                              }
                            },
                            [_vm._v("View")]
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.item.accountType == "Business"
                    ? _c("tr", [
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm.item.personal
                                ? _vm.item.personal.firstName +
                                    " " +
                                    _vm.item.personal.lastName
                                : _vm.item.business.companyName +
                                    " " +
                                    _vm.item.business.companyType
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              (_vm.itemList[0].response &&
                                _vm.itemList[0].response.res) ||
                                "ERROR"
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c(
                            "a",
                            {
                              staticClass: "tb-btn",
                              attrs: { href: "" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.viewResult(_vm.itemList[0])
                                }
                              }
                            },
                            [_vm._v("View")]
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(_vm.itemList[0].response.people, function(person) {
                    return _c("tr", [
                      _c("td", [_vm._v(_vm._s(_vm.dbPerson(person.personId)))]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          "\n                " +
                            _vm._s(person.res || "ERROR") +
                            "\n                "
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "tb-btn",
                            attrs: { href: "" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.sendIDVBusiness(person.personId)
                              }
                            }
                          },
                          [_vm._v("IDV")]
                        )
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _c(
                          "a",
                          {
                            staticClass: "tb-btn",
                            attrs: { href: "" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.viewResult(_vm.itemList[0])
                              }
                            }
                          },
                          [_vm._v("View")]
                        )
                      ])
                    ])
                  })
                ],
                2
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("table", { staticClass: "file-list content-table" }, [
          _vm._m(1),
          _vm._v(" "),
          _c(
            "tbody",
            _vm._l(_vm.itemList, function(uwItem, uwI) {
              return _c("tr", [
                _c("td", [_vm._v(_vm._s(_vm.itemList.length - uwI))]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    _vm._s(
                      _vm.formatDate(uwItem.createdDate, "YYYY-MM-DD HH:mm")
                    )
                  )
                ]),
                _vm._v(" "),
                _c("td", [_vm._v("Acuant")]),
                _vm._v(" "),
                uwItem.type == "g2"
                  ? _c("td", [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: _vm.printUrlForItem(uwItem),
                            target: "_blank"
                          }
                        },
                        [_vm._v(_vm._s(_vm.docNameForItem(uwItem)))]
                      )
                    ])
                  : _c("td"),
                _vm._v(" "),
                uwItem.type == "g2"
                  ? _c("td", [
                      _vm._v(
                        _vm._s(
                          uwItem.response.complete ? "Completed" : "Pending"
                        )
                      )
                    ])
                  : _c(
                      "td",
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm.item.personal
                                ? _vm.item.personal.firstName +
                                    " " +
                                    _vm.item.personal.lastName
                                : _vm.item.business.companyName +
                                    " " +
                                    _vm.item.business.companyType
                            ) +
                            " |\n                " +
                            _vm._s(
                              (uwItem.response && uwItem.response.res) ||
                                "ERROR"
                            ) +
                            "\n                "
                        ),
                        _vm._l(uwItem.response.people || [], function(person) {
                          return [
                            _c("br"),
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.dbPerson(person.personId)) +
                                " |\n                  " +
                                _vm._s(person.res || "ERROR") +
                                "\n                "
                            )
                          ]
                        })
                      ],
                      2
                    )
              ])
            }),
            0
          )
        ])
      ]),
      _vm._v(" "),
      _vm.selectedResponse
        ? _c("div", { staticClass: "popup shown api-log-pop" }, [
            _c("div", [
              _c("div", { staticClass: "pop-inner" }, [
                _c("a", {
                  staticClass: "close",
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.selectedResponse = null
                    }
                  }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "log-body" }, [
                  _c(
                    "div",
                    { staticClass: "response-data" },
                    [
                      _c("label", [_vm._v("Response:")]),
                      _vm._v(" "),
                      _c("json-viewer", {
                        attrs: { value: _vm.selectedResponse }
                      })
                    ],
                    1
                  )
                ])
              ])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      true
        ? _c("portal", { attrs: { to: "actions" } }, [
            _c("ul", [
              _c("li", [
                _c(
                  "a",
                  {
                    attrs: { href: "" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.newCheck.apply(null, arguments)
                      }
                    }
                  },
                  [_vm._v("Run New")]
                )
              ]),
              _vm._v(" "),
              false && _vm.itemList && _vm.itemList.length
                ? _c("li", [
                    _c(
                      "a",
                      {
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.refresh(_vm.itemList[0])
                          }
                        }
                      },
                      [_vm._v("Refresh")]
                    )
                  ])
                : _vm._e()
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Entity")]),
        _vm._v(" "),
        _c("th", [_vm._v("Status")]),
        _vm._v(" "),
        _c("th", [_vm._v("Options")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("ID")]),
        _vm._v(" "),
        _c("th", [_vm._v("Date")]),
        _vm._v(" "),
        _c("th", [_vm._v("Type")]),
        _vm._v(" "),
        _c("th", [_vm._v("Filename")]),
        _vm._v(" "),
        _c("th", [_vm._v("Status")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }