var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "search-form",
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.save()
        }
      }
    },
    [
      _c("h2", { staticClass: "doc-title" }, [
        _vm._v("INTERNAL UNUSUAL ACTIVITY REPORT (IUAR)")
      ]),
      _vm._v(" "),
      _c("p", [_vm._v("How to use this report form:")]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "The Internal Unusual Activity Report (IUAR) is used to document the information and facts regarding a potential unusual and/or suspicious behaviour, activity and/or transaction. An IUAR must be completed for all suspicious activity in conformity with the Company’s AML, ATF, Fraud and Risk Policy. This form must be submitted to the Compliance Officer promptly."
        )
      ]),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c("p", [_c("b", [_vm._v("From: " + _vm._s(_vm.user.fullName))])]),
      _vm._v(" "),
      _c("p", [
        _c("b", [_vm._v("Company Employee ID")]),
        _vm._v(" (if applicable): "),
        _c("b", [_vm._v(_vm._s(_vm.user.username))]),
        _vm._v(" "),
        _c("span", { staticClass: "ml-20" }, [
          _vm._v("Dept. Name: "),
          _c("b", [_vm._v(_vm._s(_vm.usersGroup))])
        ])
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "I, the undersigned  as " +
            _vm._s(_vm.user.fullName) +
            " part of Company’s compliance procedures, have identified the following transaction, and/or Merchant Customer Activity or known person affiliated with a specific Merchant Customer\n  "
        ),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.form.name,
              expression: "form.name"
            }
          ],
          attrs: { type: "text", placeholder: "Merchant Name" },
          domProps: { value: _vm.form.name },
          on: {
            keypress: function($event) {
              return _vm.allowLetterNumber($event, { numbers: false })
            },
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.form, "name", $event.target.value)
            }
          }
        }),
        _vm._v(
          "\n  , as possibly being involved in money laundering, terrorist financing and/or fraud offences or otherwise in breach of the Company’s policies or applicable terms and conditions."
        )
      ]),
      _vm._v(" "),
      _c("b", [
        _vm._v("Details of the transaction and/or transactions to be reported")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "preform" }, [
        _c(
          "ul",
          { staticClass: "inline-block" },
          _vm._l(_vm.transactions, function(transaction, index) {
            return _c("li", { key: index, staticClass: "mr-5 inline-block" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: transaction.value,
                    expression: "transaction.value"
                  }
                ],
                attrs: {
                  type: "text",
                  placeholder: "Transaction ID",
                  maxlength: "20"
                },
                domProps: { value: transaction.value },
                on: {
                  keypress: function($event) {
                    return _vm.allowLetterNumber($event, { numbers: true })
                  },
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(transaction, "value", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _vm.transactions.length >= 2
                ? _c(
                    "span",
                    {
                      staticClass: "small-remove-item-button",
                      on: {
                        click: function($event) {
                          return _vm.removeTransactionID(index)
                        }
                      }
                    },
                    [_vm._v("×")]
                  )
                : _vm._e()
            ])
          }),
          0
        ),
        _vm._v(" "),
        _c("div", { staticClass: "preq-form inline-block" }, [
          _c("div", { staticClass: "new-ubo" }, [
            _c(
              "a",
              {
                on: {
                  click: function($event) {
                    return _vm.addTransactionID()
                  }
                }
              },
              [_vm._v("Add Additional Transaction ID")]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.form.transaction_details,
            expression: "form.transaction_details"
          }
        ],
        staticClass: "w100",
        attrs: { rows: "3" },
        domProps: { value: _vm.form.transaction_details },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.form, "transaction_details", $event.target.value)
          }
        }
      }),
      _vm._v(" "),
      _vm._m(2),
      _vm._v(" "),
      _c("div", { staticClass: "custom-doc-field" }, [
        _c("div", { staticClass: "label" }, [
          _vm._v("Average monthly trading volume (if applicable):")
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "field-wrapper" },
          [
            _c("money", {
              attrs: {
                amount: _vm.form.avg_monthly_trading_volume.value,
                currency: _vm.form.avg_monthly_trading_volume.currency
              },
              on: {
                "update:amount": function($event) {
                  return _vm.$set(
                    _vm.form.avg_monthly_trading_volume,
                    "value",
                    $event
                  )
                },
                "update:currency": function($event) {
                  return _vm.$set(
                    _vm.form.avg_monthly_trading_volume,
                    "currency",
                    $event
                  )
                }
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "custom-doc-field" }, [
        _c("div", { staticClass: "label" }, [
          _vm._v("Average monthly volume of Transactions (if applicable):")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "field-wrapper" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.number",
                value: _vm.form.avg_monthly_volume_transactions,
                expression: "form.avg_monthly_volume_transactions",
                modifiers: { number: true }
              }
            ],
            staticClass: "w100",
            attrs: { type: "text" },
            domProps: { value: _vm.form.avg_monthly_volume_transactions },
            on: {
              keypress: function($event) {
                return _vm.allowLetterNumber($event, {
                  letters: false,
                  numbers: true
                })
              },
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(
                  _vm.form,
                  "avg_monthly_volume_transactions",
                  _vm._n($event.target.value)
                )
              },
              blur: function($event) {
                return _vm.$forceUpdate()
              }
            }
          })
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "custom-doc-field" }, [
        _c("div", { staticClass: "label" }, [
          _vm._v("Transaction activity in relation to any Company set limits:")
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "field-wrapper" },
          [
            _c("money", {
              attrs: {
                amount: _vm.form.transaction_activity_compnay_set_limits.value,
                currency:
                  _vm.form.transaction_activity_compnay_set_limits.currency
              },
              on: {
                "update:amount": function($event) {
                  return _vm.$set(
                    _vm.form.transaction_activity_compnay_set_limits,
                    "value",
                    $event
                  )
                },
                "update:currency": function($event) {
                  return _vm.$set(
                    _vm.form.transaction_activity_compnay_set_limits,
                    "currency",
                    $event
                  )
                }
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Detail the circumstances giving rise to this suspicious activity. If you believe a series of transactions, or linked transactions, are involved, ensure to describe the entire series or linked transactions, and indicate why you believe they comprise a series or are linked. Please include the date and time stamp of the transaction(s) in question."
        )
      ]),
      _vm._v(" "),
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.form.suspicious_activity_details,
            expression: "form.suspicious_activity_details"
          }
        ],
        staticClass: "w100",
        attrs: { rows: "5" },
        domProps: { value: _vm.form.suspicious_activity_details },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(
              _vm.form,
              "suspicious_activity_details",
              $event.target.value
            )
          }
        }
      }),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "What indicators raised your suspicions about possible money laundering, terrorist financing, fraud or other suspicious activity or behaviour by this individual?"
        )
      ]),
      _vm._v(" "),
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.form.suspicious_activity_details_fraud,
            expression: "form.suspicious_activity_details_fraud"
          }
        ],
        staticClass: "w100",
        attrs: { rows: "5" },
        domProps: { value: _vm.form.suspicious_activity_details_fraud },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(
              _vm.form,
              "suspicious_activity_details_fraud",
              $event.target.value
            )
          }
        }
      }),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Please provide any other information that is relevant to this present report:"
        )
      ]),
      _vm._v(" "),
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.form.relavant_report_info,
            expression: "form.relavant_report_info"
          }
        ],
        staticClass: "w100",
        attrs: { rows: "3" },
        domProps: { value: _vm.form.relavant_report_info },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.form, "relavant_report_info", $event.target.value)
          }
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "field" },
        [
          _c("label", [_vm._v("DATE IUAR WAS COMPLETED BY EMPLOYEE:")]),
          _vm._v(" "),
          _c("datepicker", {
            attrs: { future: false, minDate: new Date(), disabled: "" },
            model: {
              value: _vm.form.completed_date,
              callback: function($$v) {
                _vm.$set(_vm.form, "completed_date", $$v)
              },
              expression: "form.completed_date"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("p", [
        _vm._v("EMPLOYEE NAME: "),
        _c("b", [_vm._v(_vm._s(_vm.user.fullName))])
      ]),
      _vm._v(" "),
      _vm._m(3),
      _vm._v(" "),
      _vm._m(4),
      _vm._v(" "),
      _vm._m(5),
      _vm._v(" "),
      _c("div", { staticClass: "buttons" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-small",
            attrs: { type: "submit", disabled: !_vm.isValidForm }
          },
          [_vm._v("Submit")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-small",
            on: {
              click: function($event) {
                return _vm.clear()
              }
            }
          },
          [_vm._v("Clear")]
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("An IUAR must be submitted to the "),
      _c("b", [_vm._v("COMPLIANCE OFFICER")]),
      _vm._v(".")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("b", [_vm._v("To: Compliance Officer")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("b", [_vm._v("What is the:")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "signature-placeholder" }, [
      _c("span", [_vm._v("EMPLOYEE’S SIGNATURE:")]),
      _c("span", { staticClass: "signature-line" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "signature-placeholder" }, [
      _c("span", [_vm._v("DATE SUBMITTED TO THE COMPLIANCE OFFICER:")]),
      _c("span", { staticClass: "signature-line" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "signature-placeholder" }, [
      _c("span", [_vm._v("SIGNATURE OF COMPLIANCE OFFICER:")]),
      _c("span", { staticClass: "signature-line" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }