var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    { staticClass: "form" },
    [
      _c("div", { staticClass: "inline-form-group" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("label", [_vm._v("Date:")]),
            _vm._v(" "),
            _c(
              "validator",
              { attrs: { value: _vm.form.date, reqs: "required" } },
              [
                _c("datepicker", {
                  attrs: { type: "text", future: true },
                  model: {
                    value: _vm.form.date,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "date", $$v)
                    },
                    expression: "form.date"
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("label", [_vm._v("Time:")]),
            _vm._v(" "),
            _c(
              "validator",
              {
                staticClass: "inline-block",
                attrs: { value: _vm.form.hours, reqs: "required" }
              },
              [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.hours,
                        expression: "form.hours"
                      }
                    ],
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.form,
                          "hours",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      }
                    }
                  },
                  _vm._l(12, function(i) {
                    return _c("option", { key: i }, [_vm._v(_vm._s(i))])
                  }),
                  0
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "validator",
              {
                staticClass: "inline-block",
                attrs: { value: _vm.form.minutes, reqs: "required" }
              },
              [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.minutes,
                        expression: "form.minutes"
                      }
                    ],
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.form,
                          "minutes",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      }
                    }
                  },
                  _vm._l(_vm.timeOptions, function(t) {
                    return _c("option", { key: t, domProps: { value: t } }, [
                      _vm._v(_vm._s(t))
                    ])
                  }),
                  0
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "validator",
              {
                staticClass: "inline-block",
                attrs: { value: _vm.form.am_pm, reqs: "required" }
              },
              [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.am_pm,
                        expression: "form.am_pm"
                      }
                    ],
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.form,
                          "am_pm",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      }
                    }
                  },
                  _vm._l(_vm.amPm, function(type) {
                    return _c(
                      "option",
                      { key: type, domProps: { value: type } },
                      [_vm._v(_vm._s(type))]
                    )
                  }),
                  0
                )
              ]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _vm.isScheduler
        ? _c("tag-employees", {
            ref: "tagEmployees",
            model: {
              value: _vm.selectedEmployees,
              callback: function($$v) {
                _vm.selectedEmployees = $$v
              },
              expression: "selectedEmployees"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("label", [_vm._v("Task:")]),
          _vm._v(" "),
          _c(
            "validator",
            { attrs: { value: _vm.form.text, reqs: "required" } },
            [
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.text,
                    expression: "form.text"
                  }
                ],
                staticClass: "w100",
                attrs: { maxlength: "300" },
                domProps: { value: _vm.form.text },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.form, "text", $event.target.value)
                  }
                }
              })
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }