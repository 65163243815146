var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.shown,
          expression: "shown"
        }
      ],
      staticClass: "form-popup"
    },
    [
      _c("div", [
        _c(
          "div",
          { staticClass: "ncontent appf appnat" },
          [
            _c(
              "a",
              {
                staticClass: "close",
                attrs: { href: "" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.dismiss.apply(null, arguments)
                  }
                }
              },
              [_vm._v("×")]
            ),
            _vm._v(" "),
            _c("form-progress", {
              attrs: {
                step: _vm.step,
                steps: [
                  "General",
                  "Company",
                  "Business",
                  "Banking",
                  "Schedule A"
                ]
              }
            }),
            _vm._v(" "),
            _c("h3", [_vm._v("New Agreement")]),
            _vm._v(" "),
            _vm.step == 0
              ? _c("form", { key: "step1", staticClass: "form-step" }, [
                  _c("section", [
                    _c("h2", [_vm._v("General")]),
                    _vm._v(" "),
                    _c("ul", { staticClass: "column-form" }, [
                      _c("li", [
                        _c("label", [_vm._v("Signup Form ID")]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "field" },
                          [
                            _c("validator", { attrs: { value: 0, reqs: "" } }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.appl.id,
                                    expression: "appl.id"
                                  }
                                ],
                                attrs: { type: "text", readonly: "" },
                                domProps: { value: _vm.appl.id },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.appl,
                                      "id",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ])
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("label", [_vm._v("Partner ID")]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "field" },
                          [
                            _c("validator", { attrs: { value: 0, reqs: "" } }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.appl.agentId,
                                    expression: "appl.agentId"
                                  }
                                ],
                                attrs: { type: "text", readonly: "" },
                                domProps: { value: _vm.appl.agentId },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.appl,
                                      "agentId",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ])
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("label", [_vm._v("Partner Type")]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "field" },
                          [
                            _c(
                              "validator",
                              { attrs: { value: 0, reqs: "" } },
                              [
                                _c("dyn-select", {
                                  attrs: { options: _vm.groups },
                                  model: {
                                    value: _vm.appl.groups,
                                    callback: function($$v) {
                                      _vm.$set(_vm.appl, "groups", $$v)
                                    },
                                    expression: "appl.groups"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      false &&
                      (_vm.appl.groups == "agent-inside" ||
                        _vm.appl.groups == "agent-outside")
                        ? _c("li", [
                            _c("label", [_vm._v("Sales Brand")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field" },
                              [
                                _c(
                                  "validator",
                                  {
                                    attrs: {
                                      value: _vm.item.salesBrand,
                                      reqs: "required"
                                    }
                                  },
                                  [
                                    _c("dyn-select", {
                                      attrs: { options: _vm.salesBrands },
                                      model: {
                                        value: _vm.item.salesBrand,
                                        callback: function($$v) {
                                          _vm.$set(_vm.item, "salesBrand", $$v)
                                        },
                                        expression: "item.salesBrand"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("li", [
                        _c("label", [_vm._v("Partner Solution")]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "field" },
                          [
                            _c(
                              "validator",
                              {
                                attrs: {
                                  value: _vm.item.solutions,
                                  reqs: "required"
                                }
                              },
                              _vm._l(_vm.solutions, function(solution) {
                                return _c(
                                  "label",
                                  {
                                    class: {
                                      checked:
                                        _vm.item.solutions == solution.value
                                    }
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.item.solutions,
                                          expression: "item.solutions"
                                        }
                                      ],
                                      attrs: { type: "checkbox", name: "plan" },
                                      domProps: {
                                        value: solution.value,
                                        checked: Array.isArray(
                                          _vm.item.solutions
                                        )
                                          ? _vm._i(
                                              _vm.item.solutions,
                                              solution.value
                                            ) > -1
                                          : _vm.item.solutions
                                      },
                                      on: {
                                        change: function($event) {
                                          var $$a = _vm.item.solutions,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = solution.value,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  _vm.item,
                                                  "solutions",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  _vm.item,
                                                  "solutions",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(_vm.item, "solutions", $$c)
                                          }
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("span"),
                                    _vm._v(
                                      _vm._s(solution.label) + "\n            "
                                    )
                                  ]
                                )
                              }),
                              0
                            )
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      false
                        ? _c("li", [
                            _c("label", [_vm._v("Regions")]),
                            _vm._v(" "),
                            false
                              ? _c(
                                  "div",
                                  { staticClass: "field regions-list" },
                                  [
                                    _c(
                                      "validator",
                                      {
                                        attrs: {
                                          value: _vm.item.regions,
                                          reqs: "required"
                                        }
                                      },
                                      [
                                        _c("label", [_vm._v("CA - ")]),
                                        _vm._v(" "),
                                        _c(
                                          "label",
                                          {
                                            class: {
                                              checked:
                                                _vm.item.regions.indexOf(
                                                  "0101"
                                                ) != -1
                                            }
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.item.regions,
                                                  expression: "item.regions"
                                                }
                                              ],
                                              attrs: {
                                                type: "checkbox",
                                                name: "plan",
                                                value: "0101"
                                              },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.item.regions
                                                )
                                                  ? _vm._i(
                                                      _vm.item.regions,
                                                      "0101"
                                                    ) > -1
                                                  : _vm.item.regions
                                              },
                                              on: {
                                                change: function($event) {
                                                  var $$a = _vm.item.regions,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = "0101",
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          _vm.item,
                                                          "regions",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          _vm.item,
                                                          "regions",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      _vm.item,
                                                      "regions",
                                                      $$c
                                                    )
                                                  }
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("span"),
                                            _vm._v("01\n            ")
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "label",
                                          {
                                            class: {
                                              checked:
                                                _vm.item.regions.indexOf(
                                                  "0102"
                                                ) != -1
                                            }
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.item.regions,
                                                  expression: "item.regions"
                                                }
                                              ],
                                              attrs: {
                                                type: "checkbox",
                                                name: "plan",
                                                value: "0102"
                                              },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.item.regions
                                                )
                                                  ? _vm._i(
                                                      _vm.item.regions,
                                                      "0102"
                                                    ) > -1
                                                  : _vm.item.regions
                                              },
                                              on: {
                                                change: function($event) {
                                                  var $$a = _vm.item.regions,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = "0102",
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          _vm.item,
                                                          "regions",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          _vm.item,
                                                          "regions",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      _vm.item,
                                                      "regions",
                                                      $$c
                                                    )
                                                  }
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("span"),
                                            _vm._v("02\n            ")
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "label",
                                          {
                                            class: {
                                              checked:
                                                _vm.item.regions.indexOf(
                                                  "0103"
                                                ) != -1
                                            }
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.item.regions,
                                                  expression: "item.regions"
                                                }
                                              ],
                                              attrs: {
                                                type: "checkbox",
                                                name: "plan",
                                                value: "0103"
                                              },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.item.regions
                                                )
                                                  ? _vm._i(
                                                      _vm.item.regions,
                                                      "0103"
                                                    ) > -1
                                                  : _vm.item.regions
                                              },
                                              on: {
                                                change: function($event) {
                                                  var $$a = _vm.item.regions,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = "0103",
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          _vm.item,
                                                          "regions",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          _vm.item,
                                                          "regions",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      _vm.item,
                                                      "regions",
                                                      $$c
                                                    )
                                                  }
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("span"),
                                            _vm._v("03\n            ")
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "label",
                                          {
                                            class: {
                                              checked:
                                                _vm.item.regions.indexOf(
                                                  "0104"
                                                ) != -1
                                            }
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.item.regions,
                                                  expression: "item.regions"
                                                }
                                              ],
                                              attrs: {
                                                type: "checkbox",
                                                name: "plan",
                                                value: "0104"
                                              },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.item.regions
                                                )
                                                  ? _vm._i(
                                                      _vm.item.regions,
                                                      "0104"
                                                    ) > -1
                                                  : _vm.item.regions
                                              },
                                              on: {
                                                change: function($event) {
                                                  var $$a = _vm.item.regions,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = "0104",
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          _vm.item,
                                                          "regions",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          _vm.item,
                                                          "regions",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      _vm.item,
                                                      "regions",
                                                      $$c
                                                    )
                                                  }
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("span"),
                                            _vm._v("04\n            ")
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "label",
                                          {
                                            class: {
                                              checked:
                                                _vm.item.regions.indexOf(
                                                  "0105"
                                                ) != -1
                                            }
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.item.regions,
                                                  expression: "item.regions"
                                                }
                                              ],
                                              attrs: {
                                                type: "checkbox",
                                                name: "plan",
                                                value: "0105"
                                              },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.item.regions
                                                )
                                                  ? _vm._i(
                                                      _vm.item.regions,
                                                      "0105"
                                                    ) > -1
                                                  : _vm.item.regions
                                              },
                                              on: {
                                                change: function($event) {
                                                  var $$a = _vm.item.regions,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = "0105",
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          _vm.item,
                                                          "regions",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          _vm.item,
                                                          "regions",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      _vm.item,
                                                      "regions",
                                                      $$c
                                                    )
                                                  }
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("span"),
                                            _vm._v("05\n            ")
                                          ]
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _c(
                                  "div",
                                  { staticClass: "field" },
                                  [
                                    _c(
                                      "validator",
                                      {
                                        attrs: {
                                          value: _vm.item.regions,
                                          reqs: ""
                                        }
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.item.regions,
                                              expression: "item.regions"
                                            }
                                          ],
                                          attrs: { type: "text" },
                                          domProps: { value: _vm.item.regions },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.item,
                                                "regions",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                      ]
                                    )
                                  ],
                                  1
                                )
                          ])
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _vm.internalNotes.agentAgrGeneral
                      ? _c("div", { staticClass: "notes important" }, [
                          _vm._v(
                            "Note: " +
                              _vm._s(_vm.internalNotes.agentAgrGeneral) +
                              " "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "buttons" }, [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-large btn-plain btn-back",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                          }
                        }
                      },
                      [_vm._v("Back")]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-large btn-success btn-next",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.nextStep.apply(null, arguments)
                          }
                        }
                      },
                      [_vm._v("Next")]
                    )
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.step == 1
              ? _c("form", { key: "step2", staticClass: "form-step" }, [
                  _c("section", [
                    _c("h2", [_vm._v("Company Info")]),
                    _vm._v(" "),
                    _c(
                      "ul",
                      { staticClass: "column-form" },
                      [
                        _vm._l(_vm.appl.contacts, function(ubo, ui) {
                          return [
                            _c("li", [
                              _c("label", [
                                _c("strong", [
                                  _vm._v("Contact " + _vm._s(ui + 1))
                                ]),
                                _vm._v(" First Name")
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "field" }, [
                                _c("div", { staticClass: "validator" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: ubo.firstName,
                                        expression: "ubo.firstName"
                                      }
                                    ],
                                    attrs: { type: "text", readonly: "" },
                                    domProps: { value: ubo.firstName },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          ubo,
                                          "firstName",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ])
                              ])
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _c("label", [
                                _c("strong", [
                                  _vm._v("Contact " + _vm._s(ui + 1))
                                ]),
                                _vm._v(" Last Name")
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "field" }, [
                                _c("div", { staticClass: "validator" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: ubo.lastName,
                                        expression: "ubo.lastName"
                                      }
                                    ],
                                    attrs: { type: "text", readonly: "" },
                                    domProps: { value: ubo.lastName },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          ubo,
                                          "lastName",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ])
                              ])
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _c("label", [
                                _c("strong", [
                                  _vm._v("Contact " + _vm._s(ui + 1))
                                ]),
                                _vm._v(" E-mail")
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "field" }, [
                                _c("div", { staticClass: "validator" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: ubo.email,
                                        expression: "ubo.email"
                                      }
                                    ],
                                    attrs: { type: "text", readonly: "" },
                                    domProps: { value: ubo.email },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          ubo,
                                          "email",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ])
                              ])
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _c("label", [
                                _c("strong", [
                                  _vm._v("Contact " + _vm._s(ui + 1))
                                ]),
                                _vm._v(" Phone")
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "field" }, [
                                _c("div", { staticClass: "validator" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: ubo.phone,
                                        expression: "ubo.phone"
                                      }
                                    ],
                                    attrs: { type: "text", readonly: "" },
                                    domProps: { value: ubo.phone },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          ubo,
                                          "phone",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ])
                              ])
                            ])
                          ]
                        }),
                        _vm._v(" "),
                        ["agent-iso", "agent-isa", "agent-ref"].indexOf(
                          _vm.appl.groups
                        ) != -1
                          ? _c("li", [
                              _c("label", [_vm._v("Company Name")]),
                              _vm._v(" "),
                              _c("div", { staticClass: "field" }, [
                                _c("div", { staticClass: "validator" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.appl.agentDb.name,
                                        expression: "appl.agentDb.name"
                                      }
                                    ],
                                    attrs: { type: "text", readonly: "" },
                                    domProps: { value: _vm.appl.agentDb.name },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.appl.agentDb,
                                          "name",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ])
                              ])
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        [
                          "agent-iso",
                          "agent-isa",
                          "agent-auth",
                          "agent-ref"
                        ].indexOf(_vm.appl.groups) != -1
                          ? [
                              _c("li", [
                                _c("label", [_vm._v("Company Type")]),
                                _vm._v(" "),
                                _c("div", { staticClass: "field" }, [
                                  _c("div", { staticClass: "validator" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.appl.agentDb.companyType,
                                          expression: "appl.agentDb.companyType"
                                        }
                                      ],
                                      attrs: { type: "text", readonly: "" },
                                      domProps: {
                                        value: _vm.appl.agentDb.companyType
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.appl.agentDb,
                                            "companyType",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ])
                                ])
                              ]),
                              _vm._v(" "),
                              _c("li", [
                                _c("label", [_vm._v("Address")]),
                                _vm._v(" "),
                                _c("div", { staticClass: "field" }, [
                                  _c("div", { staticClass: "validator" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.appl.agentDb.address,
                                          expression: "appl.agentDb.address"
                                        }
                                      ],
                                      attrs: { type: "text", readonly: "" },
                                      domProps: {
                                        value: _vm.appl.agentDb.address
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.appl.agentDb,
                                            "address",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ])
                                ])
                              ]),
                              _vm._v(" "),
                              _c("li", [
                                _c("label", [_vm._v("City")]),
                                _vm._v(" "),
                                _c("div", { staticClass: "field" }, [
                                  _c("div", { staticClass: "validator" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.appl.agentDb.city,
                                          expression: "appl.agentDb.city"
                                        }
                                      ],
                                      attrs: { type: "text", readonly: "" },
                                      domProps: {
                                        value: _vm.appl.agentDb.city
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.appl.agentDb,
                                            "city",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ])
                                ])
                              ]),
                              _vm._v(" "),
                              _c("li", [
                                _c("label", [_vm._v("Zip/Postal Code")]),
                                _vm._v(" "),
                                _c("div", { staticClass: "field" }, [
                                  _c("div", { staticClass: "validator" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.appl.agentDb.zip,
                                          expression: "appl.agentDb.zip"
                                        }
                                      ],
                                      attrs: { type: "text", readonly: "" },
                                      domProps: { value: _vm.appl.agentDb.zip },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.appl.agentDb,
                                            "zip",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ])
                                ])
                              ]),
                              _vm._v(" "),
                              _c("li", [
                                _c("label", [_vm._v("State/Province")]),
                                _vm._v(" "),
                                _c("div", { staticClass: "field" }, [
                                  _c("div", { staticClass: "validator" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.appl.agentDb.state,
                                          expression: "appl.agentDb.state"
                                        }
                                      ],
                                      attrs: { type: "text", readonly: "" },
                                      domProps: {
                                        value: _vm.appl.agentDb.state
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.appl.agentDb,
                                            "state",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ])
                                ])
                              ]),
                              _vm._v(" "),
                              _c("li", [
                                _c("label", [_vm._v("Country")]),
                                _vm._v(" "),
                                _c("div", { staticClass: "field" }, [
                                  _c("div", { staticClass: "validator" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.appl.agentDb.country,
                                          expression: "appl.agentDb.country"
                                        }
                                      ],
                                      attrs: { type: "text", readonly: "" },
                                      domProps: {
                                        value: _vm.appl.agentDb.country
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.appl.agentDb,
                                            "country",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ])
                                ])
                              ]),
                              _vm._v(" "),
                              _c("li", [
                                _c("label", [_vm._v("Phone Number")]),
                                _vm._v(" "),
                                _c("div", { staticClass: "field" }, [
                                  _c("div", { staticClass: "validator" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.appl.agentDb.phone,
                                          expression: "appl.agentDb.phone"
                                        }
                                      ],
                                      attrs: { type: "text", readonly: "" },
                                      domProps: {
                                        value: _vm.appl.agentDb.phone
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.appl.agentDb,
                                            "phone",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ])
                                ])
                              ]),
                              _vm._v(" "),
                              _c("li", [
                                _c("label", [_vm._v("Company Email")]),
                                _vm._v(" "),
                                _c("div", { staticClass: "field" }, [
                                  _c("div", { staticClass: "validator" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.appl.agentDb.email,
                                          expression: "appl.agentDb.email"
                                        }
                                      ],
                                      attrs: { type: "text", readonly: "" },
                                      domProps: {
                                        value: _vm.appl.agentDb.email
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.appl.agentDb,
                                            "email",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ])
                                ])
                              ])
                            ]
                          : _vm._e()
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _vm.internalNotes.agentAgrCompany
                      ? _c("div", { staticClass: "notes important" }, [
                          _vm._v(
                            "Note: " +
                              _vm._s(_vm.internalNotes.agentAgrCompany) +
                              " "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "buttons" }, [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-large btn-plain btn-back",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            _vm.step--
                          }
                        }
                      },
                      [_vm._v("Back")]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-large btn-success btn-next",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.nextStep.apply(null, arguments)
                          }
                        }
                      },
                      [_vm._v("Next")]
                    )
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.step == 2
              ? _c("form", { key: "step3", staticClass: "form-step" }, [
                  _c("section", [
                    _c("h2", [_vm._v("Business Info")]),
                    _vm._v(" "),
                    _c("ul", { staticClass: "column-form" }, [
                      _c("li", [
                        _c("label", [_vm._v("Business Website")]),
                        _vm._v(" "),
                        _c("div", { staticClass: "field" }, [
                          _c("div", { staticClass: "validator" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.appl.agentDb.website,
                                  expression: "appl.agentDb.website"
                                }
                              ],
                              attrs: { type: "text", readonly: "" },
                              domProps: { value: _vm.appl.agentDb.website },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.appl.agentDb,
                                    "website",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("label", [_vm._v("Sevices")]),
                        _vm._v(" "),
                        _c("div", { staticClass: "field" }, [
                          _c(
                            "div",
                            { staticClass: "validator business-section" },
                            [
                              _c("div", { staticClass: "payment-checkbox" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.appl.agentDb.payments,
                                      expression: "appl.agentDb.payments"
                                    }
                                  ],
                                  attrs: {
                                    id: "payment_checkbox",
                                    type: "checkbox"
                                  },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.appl.agentDb.payments
                                    )
                                      ? _vm._i(
                                          _vm.appl.agentDb.payments,
                                          null
                                        ) > -1
                                      : _vm.appl.agentDb.payments
                                  },
                                  on: {
                                    change: function($event) {
                                      var $$a = _vm.appl.agentDb.payments,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.appl.agentDb,
                                              "payments",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.appl.agentDb,
                                              "payments",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.appl.agentDb,
                                          "payments",
                                          $$c
                                        )
                                      }
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  { attrs: { for: "payment_checkbox" } },
                                  [_vm._v("PAYMENTS")]
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "payment-checkbox" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.appl.agentDb.treasury,
                                      expression: "appl.agentDb.treasury"
                                    }
                                  ],
                                  attrs: {
                                    id: "treasury_checkbox",
                                    type: "checkbox"
                                  },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.appl.agentDb.treasury
                                    )
                                      ? _vm._i(
                                          _vm.appl.agentDb.treasury,
                                          null
                                        ) > -1
                                      : _vm.appl.agentDb.treasury
                                  },
                                  on: {
                                    change: function($event) {
                                      var $$a = _vm.appl.agentDb.treasury,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.appl.agentDb,
                                              "treasury",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.appl.agentDb,
                                              "treasury",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.appl.agentDb,
                                          "treasury",
                                          $$c
                                        )
                                      }
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  { attrs: { for: "treasury_checkbox" } },
                                  [_vm._v("TREASURY")]
                                )
                              ])
                            ]
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("label", [_vm._v("Types")]),
                        _vm._v(" "),
                        _c("div", { staticClass: "field" }, [
                          _c(
                            "div",
                            { staticClass: "validator business-section" },
                            [
                              _c(
                                "div",
                                { staticClass: "payment-checkbox" },
                                [
                                  _c("multiselect", {
                                    class: _vm.payment - _vm.multi,
                                    attrs: {
                                      readonly: !_vm.appl.agentDb.payments,
                                      hasOther: 0,
                                      options: [
                                        "POS",
                                        "E-Commerce",
                                        "Unattended",
                                        "EFT / ACH",
                                        "Debit Only"
                                      ]
                                    },
                                    model: {
                                      value: _vm.appl.agentDb.merchantTypes,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.appl.agentDb,
                                          "merchantTypes",
                                          $$v
                                        )
                                      },
                                      expression: "appl.agentDb.merchantTypes"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "payment-checkbox" },
                                [
                                  _c("multiselect", {
                                    attrs: {
                                      readonly: !_vm.appl.agentDb.treasury,
                                      hasOther: 0,
                                      options: [
                                        "Personal Accounts",
                                        "Business Accounts",
                                        "Debit Cards",
                                        "Digital Assets",
                                        "Assets Pay"
                                      ]
                                    },
                                    model: {
                                      value: _vm.appl.agentDb.treasuryTypes,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.appl.agentDb,
                                          "treasuryTypes",
                                          $$v
                                        )
                                      },
                                      expression: "appl.agentDb.treasuryTypes"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("label", [_vm._v("Estimated Applications Monthly")]),
                        _vm._v(" "),
                        _c("div", { staticClass: "field" }, [
                          _c(
                            "div",
                            { staticClass: "validator business-section" },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.appl.agentDb.appMonthly,
                                    expression: "appl.agentDb.appMonthly"
                                  }
                                ],
                                attrs: { type: "text", readonly: "" },
                                domProps: {
                                  value: _vm.appl.agentDb.appMonthly
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.appl.agentDb,
                                      "appMonthly",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.appl.agentDb.treasuryMonthly,
                                    expression: "appl.agentDb.treasuryMonthly"
                                  }
                                ],
                                attrs: { type: "text", readonly: "" },
                                domProps: {
                                  value: _vm.appl.agentDb.treasuryMonthly
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.appl.agentDb,
                                      "treasuryMonthly",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("label", [_vm._v("Estimated Monthly Volume")]),
                        _vm._v(" "),
                        _c("div", { staticClass: "field" }, [
                          _c(
                            "div",
                            { staticClass: "validator business-section" },
                            [
                              _c("formel-money-amount", {
                                attrs: { readonly: "" },
                                model: {
                                  value: _vm.appl.agentDb.monthlyVolume,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.appl.agentDb,
                                      "monthlyVolume",
                                      $$v
                                    )
                                  },
                                  expression: "appl.agentDb.monthlyVolume"
                                }
                              }),
                              _vm._v(" "),
                              _c("formel-money-amount", {
                                attrs: { readonly: "" },
                                model: {
                                  value: _vm.appl.agentDb.treasuryVolume,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.appl.agentDb,
                                      "treasuryVolume",
                                      $$v
                                    )
                                  },
                                  expression: "appl.agentDb.treasuryVolume"
                                }
                              })
                            ],
                            1
                          )
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _vm.internalNotes.agentAgrBusiness
                      ? _c("div", { staticClass: "notes important" }, [
                          _vm._v(
                            "Note: " +
                              _vm._s(_vm.internalNotes.agentAgrBusiness) +
                              " "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "buttons" }, [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-large btn-plain btn-back",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            _vm.step--
                          }
                        }
                      },
                      [_vm._v("Back")]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-large btn-success btn-next",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.nextStep.apply(null, arguments)
                          }
                        }
                      },
                      [_vm._v("Next")]
                    )
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.step == 3
              ? _c("form", { key: "step4", staticClass: "form-step" }, [
                  _c("section", [
                    _c("h2", [_vm._v("Banking")]),
                    _vm._v(" "),
                    _vm.appl.agentDb.accounts.length
                      ? _c("ul", { staticClass: "column-form" }, [
                          _c("li", [
                            _c("label", [_vm._v("Bank Country")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field" },
                              [
                                _c(
                                  "validator",
                                  {
                                    attrs: {
                                      value:
                                        _vm.appl.agentDb.accounts[0]
                                          .bankAddressCountry,
                                      reqs: "required"
                                    }
                                  },
                                  [
                                    _c("dyn-select", {
                                      attrs: {
                                        options: [
                                          {
                                            value: "United States",
                                            label: "United States"
                                          },
                                          { value: "Canada", label: "Canada" }
                                        ]
                                      },
                                      model: {
                                        value:
                                          _vm.appl.agentDb.accounts[0]
                                            .bankAddressCountry,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.appl.agentDb.accounts[0],
                                            "bankAddressCountry",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "appl.agentDb.accounts[0].bankAddressCountry"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v("Bank State")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field" },
                              [
                                _c(
                                  "validator",
                                  {
                                    attrs: {
                                      value:
                                        _vm.appl.agentDb.accounts[0]
                                          .bankAddressState,
                                      reqs: "required"
                                    }
                                  },
                                  [
                                    _vm.states.length
                                      ? _c("dyn-select", {
                                          attrs: { options: _vm.states },
                                          model: {
                                            value:
                                              _vm.appl.agentDb.accounts[0]
                                                .bankAddressState,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.appl.agentDb.accounts[0],
                                                "bankAddressState",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "appl.agentDb.accounts[0].bankAddressState"
                                          }
                                        })
                                      : _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.appl.agentDb.accounts[0]
                                                  .bankAddressState,
                                              expression:
                                                "appl.agentDb.accounts[0].bankAddressState"
                                            }
                                          ],
                                          attrs: {
                                            type: "text",
                                            options: _vm.states
                                          },
                                          domProps: {
                                            value:
                                              _vm.appl.agentDb.accounts[0]
                                                .bankAddressState
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.appl.agentDb.accounts[0],
                                                "bankAddressState",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v("Bank Name")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field" },
                              [
                                _c(
                                  "validator",
                                  {
                                    attrs: {
                                      value:
                                        _vm.appl.agentDb.accounts[0].bankName,
                                      reqs: "required"
                                    }
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.appl.agentDb.accounts[0]
                                              .bankName,
                                          expression:
                                            "appl.agentDb.accounts[0].bankName"
                                        }
                                      ],
                                      attrs: { type: "text" },
                                      domProps: {
                                        value:
                                          _vm.appl.agentDb.accounts[0].bankName
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.appl.agentDb.accounts[0],
                                            "bankName",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v("Currency")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field" },
                              [
                                _c(
                                  "validator",
                                  {
                                    attrs: {
                                      value:
                                        _vm.appl.agentDb.accounts[0]
                                          .accountCurrency,
                                      reqs: "required"
                                    }
                                  },
                                  [
                                    _c("dyn-select", {
                                      attrs: { options: _vm.currencies2 },
                                      model: {
                                        value:
                                          _vm.appl.agentDb.accounts[0]
                                            .accountCurrency,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.appl.agentDb.accounts[0],
                                            "accountCurrency",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "appl.agentDb.accounts[0].accountCurrency"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v("Account Name")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field" },
                              [
                                _c(
                                  "validator",
                                  {
                                    attrs: {
                                      value:
                                        _vm.appl.agentDb.accounts[0]
                                          .accountHolder,
                                      reqs: "required"
                                    }
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.appl.agentDb.accounts[0]
                                              .accountHolder,
                                          expression:
                                            "appl.agentDb.accounts[0].accountHolder"
                                        }
                                      ],
                                      attrs: { type: "text" },
                                      domProps: {
                                        value:
                                          _vm.appl.agentDb.accounts[0]
                                            .accountHolder
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.appl.agentDb.accounts[0],
                                            "accountHolder",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v("Account Number")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field" },
                              [
                                _c(
                                  "validator",
                                  {
                                    attrs: {
                                      value:
                                        _vm.appl.agentDb.accounts[0]
                                          .accountNumber,
                                      reqs: "required"
                                    }
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.appl.agentDb.accounts[0]
                                              .accountNumber,
                                          expression:
                                            "appl.agentDb.accounts[0].accountNumber"
                                        }
                                      ],
                                      attrs: { type: "text" },
                                      domProps: {
                                        value:
                                          _vm.appl.agentDb.accounts[0]
                                            .accountNumber
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.appl.agentDb.accounts[0],
                                            "accountNumber",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v("Institution Number")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field" },
                              [
                                _c(
                                  "validator",
                                  {
                                    attrs: {
                                      value:
                                        _vm.appl.agentDb.accounts[0]
                                          .accountSwift,
                                      reqs: "required"
                                    }
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.appl.agentDb.accounts[0]
                                              .accountSwift,
                                          expression:
                                            "appl.agentDb.accounts[0].accountSwift"
                                        }
                                      ],
                                      attrs: { type: "text" },
                                      domProps: {
                                        value:
                                          _vm.appl.agentDb.accounts[0]
                                            .accountSwift
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.appl.agentDb.accounts[0],
                                            "accountSwift",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v("Transit/Branch Number")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field" },
                              [
                                _c(
                                  "validator",
                                  {
                                    attrs: {
                                      value:
                                        _vm.appl.agentDb.accounts[0]
                                          .accountIBAN,
                                      reqs: "required"
                                    }
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.appl.agentDb.accounts[0]
                                              .accountIBAN,
                                          expression:
                                            "appl.agentDb.accounts[0].accountIBAN"
                                        }
                                      ],
                                      attrs: { type: "text" },
                                      domProps: {
                                        value:
                                          _vm.appl.agentDb.accounts[0]
                                            .accountIBAN
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.appl.agentDb.accounts[0],
                                            "accountIBAN",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v("ABA / routing number")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field" },
                              [
                                _c(
                                  "validator",
                                  {
                                    attrs: {
                                      value:
                                        _vm.appl.agentDb.accounts[0]
                                          .accountRouting,
                                      reqs: "required"
                                    }
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.appl.agentDb.accounts[0]
                                              .accountRouting,
                                          expression:
                                            "appl.agentDb.accounts[0].accountRouting"
                                        }
                                      ],
                                      attrs: { type: "text" },
                                      domProps: {
                                        value:
                                          _vm.appl.agentDb.accounts[0]
                                            .accountRouting
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.appl.agentDb.accounts[0],
                                            "accountRouting",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.internalNotes.agentAgrBanking
                      ? _c("div", { staticClass: "notes important" }, [
                          _vm._v(
                            "Note: " +
                              _vm._s(_vm.internalNotes.agentAgrBanking) +
                              " "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "buttons" }, [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-large btn-plain btn-back",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            _vm.step--
                          }
                        }
                      },
                      [_vm._v("Back")]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-large btn-success btn-next",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.nextStep.apply(null, arguments)
                          }
                        }
                      },
                      [_vm._v("Next")]
                    )
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.step == 4
              ? _c("form", { key: "step5", staticClass: "form-step" }, [
                  _c("section", [
                    _c("h2", [_vm._v("Schedule A")]),
                    _vm._v(" "),
                    _c("ul", { staticClass: "column-form" }, [
                      false
                        ? _c("li", [
                            _c("label", [_vm._v("Sales Brand")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field" },
                              [
                                _c(
                                  "validator",
                                  {
                                    attrs: {
                                      value: _vm.item.salesBrand,
                                      reqs: "required"
                                    }
                                  },
                                  [
                                    _c("dyn-select", {
                                      attrs: { options: _vm.salesBrands },
                                      model: {
                                        value: _vm.item.salesBrand,
                                        callback: function($$v) {
                                          _vm.$set(_vm.item, "salesBrand", $$v)
                                        },
                                        expression: "item.salesBrand"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("li", [
                        _c("label", [_vm._v("Partner Tier")]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "field" },
                          [
                            _c(
                              "validator",
                              {
                                attrs: {
                                  value: _vm.item.accClass,
                                  reqs: "required"
                                }
                              },
                              [
                                _c("dyn-select", {
                                  attrs: { options: _vm.accountClassesList },
                                  model: {
                                    value: _vm.item.accClass,
                                    callback: function($$v) {
                                      _vm.$set(_vm.item, "accClass", $$v)
                                    },
                                    expression: "item.accClass"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("label", [_vm._v("Compliance review days")]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "field" },
                          [
                            _c(
                              "validator",
                              {
                                attrs: {
                                  value: _vm.item.reviewDays,
                                  reqs: "required"
                                }
                              },
                              [
                                _c("dyn-select", {
                                  attrs: { options: _vm.reviewDaysOpts },
                                  model: {
                                    value: _vm.item.reviewDays,
                                    callback: function($$v) {
                                      _vm.$set(_vm.item, "reviewDays", $$v)
                                    },
                                    expression: "item.reviewDays"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("label", [_vm._v("BIN/ICA Rental Fee:")]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "field" },
                          [
                            _c(
                              "validator",
                              {
                                attrs: {
                                  value: _vm.item.bicRentalFee,
                                  reqs: "required"
                                }
                              },
                              [
                                _c("formel-money-amount4", {
                                  attrs: { suffix: "%" },
                                  model: {
                                    value: _vm.item.bicRentalFee,
                                    callback: function($$v) {
                                      _vm.$set(_vm.item, "bicRentalFee", $$v)
                                    },
                                    expression: "item.bicRentalFee"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("label", [_vm._v("Partner Setup Fee:")]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "field" },
                          [
                            _c(
                              "validator",
                              {
                                attrs: {
                                  value: _vm.item.setupFee,
                                  reqs: "required"
                                }
                              },
                              [
                                _c("formel-money-amount4", {
                                  attrs: { prefix: "$" },
                                  model: {
                                    value: _vm.item.setupFee,
                                    callback: function($$v) {
                                      _vm.$set(_vm.item, "setupFee", $$v)
                                    },
                                    expression: "item.setupFee"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("label", [_vm._v("Monthly Minimum:")]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "field" },
                          [
                            _c(
                              "validator",
                              {
                                attrs: {
                                  value: _vm.item.monthlyMinimum,
                                  reqs: "required"
                                }
                              },
                              [
                                _c("formel-money-amount4", {
                                  attrs: { prefix: "$" },
                                  model: {
                                    value: _vm.item.monthlyMinimum,
                                    callback: function($$v) {
                                      _vm.$set(_vm.item, "monthlyMinimum", $$v)
                                    },
                                    expression: "item.monthlyMinimum"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _vm.internalNotes.agentAgrBanking
                      ? _c("div", { staticClass: "notes important" }, [
                          _vm._v(
                            "Note: " +
                              _vm._s(_vm.internalNotes.agentAgrBanking) +
                              " "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "buttons" }, [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-large btn-plain btn-back",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            _vm.step--
                          }
                        }
                      },
                      [_vm._v("Back")]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-large btn-success",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.save.apply(null, arguments)
                          }
                        }
                      },
                      [_vm._v("Create Agreement")]
                    )
                  ])
                ])
              : _vm._e()
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }