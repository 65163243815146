var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ncontent" }, [
    _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "toolbar" }),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("div", [
        _c("ul", { staticClass: "acc-results" }, [
          _c("li", [
            _c(
              "div",
              { staticClass: "acc-list", staticStyle: { display: "block" } },
              [
                _c("table", [
                  _vm._m(1),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.apilog, function(item) {
                      return _c(
                        "tr",
                        {
                          staticStyle: { cursor: "pointer" },
                          on: {
                            click: function($event) {
                              _vm.selectedItem = item
                            }
                          }
                        },
                        [
                          _c("td", { staticStyle: { "text-align": "left" } }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.formatDate(
                                    item.created,
                                    "MMM Do YYYY, h:mm:ssa"
                                  )
                                )
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("td", { staticStyle: { "text-align": "left" } }, [
                            _c("span", [_vm._v(_vm._s(item.id))])
                          ]),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              staticStyle: { "text-align": "left" },
                              attrs: { width: "50%" }
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(item.method) + " " + _vm._s(item.url)
                                )
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c("td", { staticStyle: { "text-align": "left" } }, [
                            _c("span", [_vm._v(_vm._s(item.provider))])
                          ]),
                          _vm._v(" "),
                          _c("td", { staticStyle: { "text-align": "left" } }, [
                            _c("span", [_vm._v(_vm._s(item.version))])
                          ]),
                          _vm._v(" "),
                          _c("td", { staticStyle: { "text-align": "left" } }, [
                            _c("span", [_vm._v(_vm._s(item.status))])
                          ])
                        ]
                      )
                    }),
                    0
                  )
                ])
              ]
            )
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _vm.selectedItem
      ? _c("div", { staticClass: "popup shown api-log-pop" }, [
          _c("div", [
            _c("div", { staticClass: "pop-inner" }, [
              _c("a", {
                staticClass: "close",
                attrs: { href: "" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    _vm.selectedItem = null
                  }
                }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "log-body" }, [
                _c("div", { staticClass: "head-details" }, [
                  _c("div", [
                    _c("label", [_vm._v("URL:")]),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.selectedItem.url))])
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("label", [_vm._v("Response Code:")]),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.selectedItem.status))])
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("label", [_vm._v("Created date:")]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.formatDate(
                            _vm.selectedItem.created,
                            "MMM Do YYYY, h:mm:ssa"
                          )
                        )
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "request-data" },
                  [
                    _c("label", [_vm._v("Request:")]),
                    _vm._v(" "),
                    _c("json-viewer", {
                      attrs: { value: _vm.selectedItem.request }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "response-data" },
                  [
                    _c("label", [_vm._v("Response:")]),
                    _vm._v(" "),
                    _c("json-viewer", {
                      attrs: { value: _vm.selectedItem.response }
                    })
                  ],
                  1
                )
              ])
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "breadcrumbs" }, [
      _c("li", [
        _c("a", { attrs: { href: "/manage" } }, [_vm._v("System management")])
      ]),
      _vm._v(" "),
      _c("li", [_c("a", { attrs: { href: "" } }, [_vm._v("API Log")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticStyle: { "text-align": "left" } }, [
          _vm._v("Date/Time")
        ]),
        _vm._v(" "),
        _c("th", { staticStyle: { "text-align": "left" } }, [_vm._v("API id")]),
        _vm._v(" "),
        _c("th", { staticStyle: { "text-align": "left" } }, [
          _vm._v("Method / URL")
        ]),
        _vm._v(" "),
        _c("th", { staticStyle: { "text-align": "left" } }, [
          _vm._v("Provider")
        ]),
        _vm._v(" "),
        _c("th", { staticStyle: { "text-align": "left" } }, [
          _vm._v("Version")
        ]),
        _vm._v(" "),
        _c("th", { staticStyle: { "text-align": "left" } }, [_vm._v("Status")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }