var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "static-page privacy-popup" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _c("div", { staticClass: "content-form" }, [
      _c(
        "div",
        { staticClass: "form-row form-split" },
        [
          _c("form-field-select", {
            attrs: {
              label: "Document Category",
              required: "",
              options: { Business: "Business", Contact: "Contact" }
            },
            model: {
              value: _vm.category,
              callback: function($$v) {
                _vm.category = $$v
              },
              expression: "category"
            }
          }),
          _vm._v(" "),
          _c("form-field-select", {
            attrs: {
              validator: _vm.$v.name,
              disabled: !_vm.category,
              label: "Name",
              required: "",
              options: _vm.nameOptions
            },
            model: {
              value: _vm.name,
              callback: function($$v) {
                _vm.name = $$v
              },
              expression: "name"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-row form-split" },
        [
          _c("form-field-select", {
            attrs: {
              validator: _vm.$v.type,
              disabled: !_vm.category,
              label: "File type",
              required: "",
              options: _vm.typeOptions
            },
            model: {
              value: _vm.type,
              callback: function($$v) {
                _vm.type = $$v
              },
              expression: "type"
            }
          }),
          _vm._v(" "),
          _vm.type == "Other"
            ? _c("form-field-text", {
                attrs: {
                  disabled: !_vm.category,
                  label: "Other",
                  maxlength: 25,
                  required: ""
                },
                model: {
                  value: _vm.otherType,
                  callback: function($$v) {
                    _vm.otherType = $$v
                  },
                  expression: "otherType"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-row form-fullw" },
        [
          _c("form-field-text", {
            attrs: {
              disabled: !_vm.category,
              label: "Document Number",
              maxlength: 15,
              required: ""
            },
            model: {
              value: _vm.number,
              callback: function($$v) {
                _vm.number = $$v
              },
              expression: "number"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-row form-fullw" },
        [
          _c("form-field-upload", {
            attrs: {
              validator: _vm.$v.file,
              disabled: !_vm.category,
              label: "File Upload",
              required: ""
            },
            model: {
              value: _vm.file,
              callback: function($$v) {
                _vm.file = $$v
              },
              expression: "file"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "form-row form-center" }, [
        _c("div", {}, [
          _c(
            "button",
            {
              attrs: { disabled: !_vm.category },
              on: {
                click: function($event) {
                  return _vm.submit()
                }
              }
            },
            [_vm._v("Submit")]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", [_c("strong", [_vm._v("Add Document")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }