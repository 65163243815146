var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-width" },
    [
      _c(
        "form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c("div", { staticClass: "buttons" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-xs",
                on: {
                  click: function($event) {
                    return _vm.save()
                  }
                }
              },
              [_vm._v("Submit")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-xs",
                on: {
                  click: function($event) {
                    return _vm.openSaveAsTemplate()
                  }
                }
              },
              [_vm._v("Save as template")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-xs",
                on: {
                  click: function($event) {
                    return _vm.clear()
                  }
                }
              },
              [_vm._v("Clear")]
            ),
            _vm._v(" "),
            _vm.loaded
              ? _c(
                  "a",
                  {
                    attrs: {
                      href:
                        "/compliance/merchants-checklist/" +
                        _vm.loaded.id +
                        "/pdf",
                      target: "_blank"
                    }
                  },
                  [
                    _c(
                      "button",
                      { staticClass: "btn btn-xs", attrs: { type: "button" } },
                      [_vm._v("View")]
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "right" }, [
              _c("span", { staticClass: "select-template" }, [
                _vm._v("Select template:")
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.loadedTemplate,
                      expression: "loadedTemplate"
                    }
                  ],
                  attrs: { placeholder: "Select template to load" },
                  on: {
                    change: [
                      function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.loadedTemplate = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                      function($event) {
                        return _vm.loadTemplate()
                      }
                    ]
                  }
                },
                _vm._l(_vm.templates, function(template) {
                  return _c(
                    "option",
                    { key: template.id, domProps: { value: template.id } },
                    [_vm._v(_vm._s(template.docIndex))]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _vm.loadedTemplate
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-xs btn-danger delete",
                      on: {
                        click: function($event) {
                          return _vm.deleteTemplate()
                        }
                      }
                    },
                    [_vm._v("Delete")]
                  )
                : _vm._e()
            ])
          ]),
          _vm._v(" "),
          _vm.loadedTemplate
            ? _c("div", { staticClass: "solution-detail" }, [
                _vm._v("\n      Solution: \n      "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.solution,
                        expression: "solution"
                      }
                    ],
                    attrs: { placeholder: "solution" },
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.solution = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      }
                    }
                  },
                  _vm._l(_vm.solutions, function(sol) {
                    return _c("option", { domProps: { value: sol.id } }, [
                      _vm._v(_vm._s(sol.nameSolution))
                    ])
                  }),
                  0
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-xs",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.setSolution.apply(null, arguments)
                      }
                    }
                  },
                  [_vm._v("Set")]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "matrix-table-wrapper" }, [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "table",
              { staticClass: "bordered small-padding" },
              [
                _c("tbody", [
                  _vm._m(1),
                  _vm._v(" "),
                  _vm._m(2),
                  _vm._v(" "),
                  _vm._m(3),
                  _vm._v(" "),
                  _c(
                    "tr",
                    [
                      _c("td", [_vm._v("Select One")]),
                      _vm._v(" "),
                      _vm._l(8, function(i) {
                        return _c(
                          "td",
                          { key: i, staticClass: "pen-checkbox" },
                          [_c("i")]
                        )
                      })
                    ],
                    2
                  )
                ]),
                _vm._v(" "),
                _vm._l(_vm.list, function(entry, idx) {
                  return _c(
                    "tbody",
                    { key: entry.name },
                    [
                      _c("tr", { staticClass: "header text-left" }, [
                        _c("td", { attrs: { colspan: "9" } }, [
                          _vm._v(_vm._s(entry.name))
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "tr",
                        { staticClass: "header text-center" },
                        _vm._l(_vm.columns, function(column, index) {
                          return _c("td", { key: column + idx + index + 1 }, [
                            _vm._v(_vm._s(column)),
                            _c("br"),
                            _vm._v(_vm._s(_vm.columns2[index]))
                          ])
                        }),
                        0
                      ),
                      _vm._v(" "),
                      _vm._l(entry.items, function(item) {
                        return _c(
                          "tr",
                          { key: item, staticClass: "text-center" },
                          [
                            _c("td", [_vm._v(_vm._s(item))]),
                            _vm._v(" "),
                            _vm._l(_vm.columns.length - 1, function(column) {
                              return _c("td", { key: column }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.form[
                                          _vm.formatName(
                                            entry.name,
                                            item,
                                            column
                                          )
                                        ],
                                      expression:
                                        "form[formatName(entry.name, item, column)]"
                                    }
                                  ],
                                  staticClass: "checkbox",
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.form[
                                        _vm.formatName(entry.name, item, column)
                                      ]
                                    )
                                      ? _vm._i(
                                          _vm.form[
                                            _vm.formatName(
                                              entry.name,
                                              item,
                                              column
                                            )
                                          ],
                                          null
                                        ) > -1
                                      : _vm.form[
                                          _vm.formatName(
                                            entry.name,
                                            item,
                                            column
                                          )
                                        ]
                                  },
                                  on: {
                                    change: function($event) {
                                      var $$a =
                                          _vm.form[
                                            _vm.formatName(
                                              entry.name,
                                              item,
                                              column
                                            )
                                          ],
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.form,
                                              _vm.formatName(
                                                entry.name,
                                                item,
                                                column
                                              ),
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.form,
                                              _vm.formatName(
                                                entry.name,
                                                item,
                                                column
                                              ),
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.form,
                                          _vm.formatName(
                                            entry.name,
                                            item,
                                            column
                                          ),
                                          $$c
                                        )
                                      }
                                    }
                                  }
                                })
                              ])
                            })
                          ],
                          2
                        )
                      })
                    ],
                    2
                  )
                }),
                _vm._v(" "),
                _c("tbody", [
                  _vm._m(4),
                  _vm._v(" "),
                  _c(
                    "tr",
                    [
                      _c("td", [_vm._v("Cristine Score")]),
                      _vm._v(" "),
                      _vm._l(_vm.columns.length - 1, function(column) {
                        return _c("td", { key: column })
                      })
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _vm._m(5),
                  _vm._v(" "),
                  _vm._m(6),
                  _vm._v(" "),
                  _vm._m(7),
                  _vm._v(" "),
                  _vm._m(8)
                ])
              ],
              2
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "popup",
        {
          attrs: { "auto-width": "" },
          on: {
            close: function($event) {
              return _vm.closeSaveTemplate()
            }
          },
          model: {
            value: _vm.saveAsTemplate,
            callback: function($$v) {
              _vm.saveAsTemplate = $$v
            },
            expression: "saveAsTemplate"
          }
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                padding: "10px 30px 30px 30px",
                "box-sizing": "border-box"
              }
            },
            [
              _c(
                "label",
                { staticStyle: { display: "block", "margin-bottom": "10px" } },
                [_vm._v("Choose template name")]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.template_name,
                    expression: "template_name"
                  }
                ],
                attrs: { type: "text" },
                domProps: { value: _vm.template_name },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.template_name = $event.target.value
                  }
                }
              }),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.solution,
                      expression: "solution"
                    }
                  ],
                  attrs: { placeholder: "solution" },
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.solution = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    }
                  }
                },
                _vm._l(_vm.solutions, function(sol) {
                  return _c("option", { domProps: { value: sol.id } }, [
                    _vm._v(_vm._s(sol.nameSolution))
                  ])
                }),
                0
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "buttons",
                  staticStyle: { "margin-top": "10px" }
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "ch-button btn-gray btn-sm no-margin",
                      on: {
                        click: function($event) {
                          return _vm.closeSaveTemplate()
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "ch-button btn-primary btn-sm no-margin",
                      on: {
                        click: function($event) {
                          return _vm.saveTemplate()
                        }
                      }
                    },
                    [_vm._v("Save template")]
                  )
                ]
              )
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "matrix-header" }, [
      _c("h2", [_vm._v("CardBiller Merchant Underwriting Checklist")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "header" }, [
      _c("td", { attrs: { colspan: "9" } }, [_vm._v("MERCHANT CHECKLIST")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "header text-center" }, [
      _c("td", [_vm._v("Class Note"), _c("br"), _vm._v("Class")]),
      _vm._v(" "),
      _c("td", [_vm._v("LR-AA"), _c("br"), _vm._v("00")]),
      _vm._v(" "),
      _c("td", [_vm._v("LR-CP"), _c("br"), _vm._v("10")]),
      _vm._v(" "),
      _c("td", [_vm._v("LR-CP"), _c("br"), _vm._v("20")]),
      _vm._v(" "),
      _c("td", [_vm._v("MR-MIX"), _c("br"), _vm._v("30")]),
      _vm._v(" "),
      _c("td", [_vm._v("HR-CNP"), _c("br"), _vm._v("40")]),
      _vm._v(" "),
      _c("td", [_vm._v("HR-CNP"), _c("br"), _vm._v("50")]),
      _vm._v(" "),
      _c("td", [_vm._v("HRS-CP"), _c("br"), _vm._v("98")]),
      _vm._v(" "),
      _c("td", [_vm._v("HRS-CNP"), _c("br"), _vm._v("99")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "ver-allign text-center" }, [
      _c("td", [_vm._v("Criteria")]),
      _vm._v(" "),
      _c("td", [
        _vm._v("Credit 500 +"),
        _c("br"),
        _vm._v("> 70% CP "),
        _c("br"),
        _vm._v("Gets Pin or"),
        _c("br"),
        _vm._v("Signature"),
        _c("br"),
        _vm._v("Avg Ticket"),
        _c("br"),
        _vm._v(" < $1,000"),
        _c("br"),
        _vm._v("< 1.2M Yr Vol"),
        _c("br"),
        _c("br"),
        _vm._v("NOT MCC’s:"),
        _c("br"),
        _vm._v("1520; 4816;"),
        _c("br"),
        _vm._v("4829; 5734;"),
        _c("br"),
        _vm._v("5999; 6051;"),
        _c("br"),
        _vm._v("7321; 7375")
      ]),
      _vm._v(" "),
      _c("td", [
        _vm._v("Credit 400 +"),
        _c("br"),
        _vm._v("> 70% CP"),
        _c("br"),
        _vm._v(" Gets Pin or"),
        _c("br"),
        _vm._v("Signature"),
        _c("br"),
        _vm._v(" Avg Ticket"),
        _c("br"),
        _vm._v("< $1,000"),
        _c("br"),
        _vm._v(" < 6M Yr Vol")
      ]),
      _vm._v(" "),
      _c("td", [
        _vm._v("Credit 400 +"),
        _c("br"),
        _vm._v("> 70% CP"),
        _c("br"),
        _vm._v(" Gets Pin or"),
        _c("br"),
        _vm._v("Signature"),
        _c("br"),
        _vm._v(" Avg Ticket"),
        _c("br"),
        _vm._v("< $1,000 "),
        _c("br"),
        _vm._v("> 6M Yr Vol")
      ]),
      _vm._v(" "),
      _c("td", [
        _vm._v("Credit 400 +"),
        _c("br"),
        _vm._v("CP & CNP"),
        _c("br"),
        _vm._v(" Gets Pin or"),
        _c("br"),
        _vm._v("Signature"),
        _c("br"),
        _vm._v(" Avg Ticket"),
        _c("br"),
        _vm._v("< $1,000"),
        _c("br"),
        _vm._v("< 10M Yr Vol")
      ]),
      _vm._v(" "),
      _c("td", [
        _vm._v("Credit 400 +"),
        _c("br"),
        _vm._v("> 30% CNP "),
        _c("br"),
        _vm._v("Avg Ticket"),
        _c("br"),
        _vm._v("< $1,000"),
        _c("br"),
        _vm._v("< 3M Yr Vol")
      ]),
      _vm._v(" "),
      _c("td", [
        _vm._v("Credit 400 +"),
        _c("br"),
        _vm._v("> 30% CNP"),
        _c("br"),
        _vm._v(" Avg Ticket"),
        _c("br"),
        _vm._v("< $1,000"),
        _c("br"),
        _vm._v(" > 3M Yr Vol")
      ]),
      _vm._v(" "),
      _c("td", [
        _vm._v("Credit 400 +"),
        _c("br"),
        _vm._v("> 70% CP"),
        _c("br"),
        _vm._v(" Gets Pin or"),
        _c("br"),
        _vm._v("Signature"),
        _c("br"),
        _vm._v("Avg Ticket"),
        _c("br"),
        _vm._v("> $1,000"),
        _c("br"),
        _vm._v("> 10M Yr Vol")
      ]),
      _vm._v(" "),
      _c("td", [
        _vm._v("Credit 400 +"),
        _c("br"),
        _vm._v("> 30% CNP"),
        _c("br"),
        _vm._v(" Avg Ticket"),
        _c("br"),
        _vm._v("> $1,000"),
        _c("br"),
        _vm._v(" > 10M Yr Vol")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "header text-left" }, [
      _c("td", { attrs: { colspan: "9" } }, [_vm._v("APPROVAL SCANS")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", [_vm._v("Risk Calculator")]),
      _vm._v(" "),
      _c("td", [_vm._v("Credit-Chargeback Exposure")]),
      _vm._v(" "),
      _c("td"),
      _vm._v(" "),
      _c("td", [_vm._v("Delayed Delivery Exposure")]),
      _vm._v(" "),
      _c("td"),
      _vm._v(" "),
      _c("td", [_vm._v("Discount & Fee Exposure")]),
      _vm._v(" "),
      _c("td"),
      _vm._v(" "),
      _c("td", [_vm._v("Total Exposure")]),
      _vm._v(" "),
      _c("td")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", { attrs: { colspan: "2" } }, [
        _vm._v("Estimated Monthly Volume")
      ]),
      _vm._v(" "),
      _c("td", { attrs: { colspan: "2" } }),
      _vm._v(" "),
      _c("td", { attrs: { colspan: "2" } }, [
        _vm._v("Estimated Annual Volume")
      ]),
      _vm._v(" "),
      _c("td", { attrs: { colspan: "2" } }),
      _vm._v(" "),
      _c("td")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", [_vm._v("Approval Scan")]),
      _vm._v(" "),
      _c("td", { staticClass: "pen-checkbox" }, [
        _vm._v("YES "),
        _c("i"),
        _vm._v(" NO "),
        _c("i")
      ]),
      _vm._v(" "),
      _c("td", [_vm._v("By")]),
      _vm._v(" "),
      _c("td"),
      _vm._v(" "),
      _c("td", [_vm._v("Date/Time")]),
      _vm._v(" "),
      _c("td"),
      _vm._v(" "),
      _c("td", [_vm._v("Result")]),
      _vm._v(" "),
      _c("td"),
      _vm._v(" "),
      _c("td")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", [_vm._v("Final Approval Scan")]),
      _vm._v(" "),
      _c("td", { staticClass: "pen-checkbox" }, [
        _vm._v("YES "),
        _c("i"),
        _vm._v(" NO "),
        _c("i")
      ]),
      _vm._v(" "),
      _c("td", [_vm._v("By")]),
      _vm._v(" "),
      _c("td"),
      _vm._v(" "),
      _c("td", [_vm._v("Date/Time")]),
      _vm._v(" "),
      _c("td"),
      _vm._v(" "),
      _c("td", [_vm._v("Result")]),
      _vm._v(" "),
      _c("td"),
      _vm._v(" "),
      _c("td")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }