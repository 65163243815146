var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "form",
      {
        ref: "form",
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.sendSignupEmail.apply(null, arguments)
          }
        }
      },
      [
        _c("dyn-select", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: false,
              expression: "false"
            }
          ],
          attrs: { required: "", options: _vm.solutions },
          model: {
            value: _vm.solution,
            callback: function($$v) {
              _vm.solution = $$v
            },
            expression: "solution"
          }
        }),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.email,
              expression: "email"
            }
          ],
          attrs: {
            disabled: !this.solution,
            required: "",
            type: "email",
            placeholder: "email"
          },
          domProps: { value: _vm.email },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.email = $event.target.value
            }
          }
        }),
        _vm._v(" "),
        _c("button", { attrs: { disabled: !this.solution } }, [_vm._v("Send")])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }