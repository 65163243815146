var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ncontent specpad custom-width" },
    [
      _c("div", { staticClass: "tabs" }, [
        _c(
          "a",
          {
            staticClass: "btn",
            on: {
              click: function($event) {
                $event.preventDefault()
                _vm.currentTab = "reports"
              }
            }
          },
          [_vm._v("KYC Reports")]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "btn",
            on: {
              click: function($event) {
                $event.preventDefault()
                _vm.currentTab = "item2"
              }
            }
          },
          [_vm._v("G2 Monitoring")]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "btn",
            on: {
              click: function($event) {
                $event.preventDefault()
                _vm.currentTab = "item3"
              }
            }
          },
          [_vm._v("G2 Monitoring List")]
        )
      ]),
      _vm._v(" "),
      _vm.currentTab == "reports"
        ? _c("scan-list", { attrs: { type: "g2" } })
        : _vm._e(),
      _vm._v(" "),
      _vm.currentTab == "item2"
        ? _c("scan-g2mon", { attrs: { type: "g2" } })
        : _vm._e(),
      _vm._v(" "),
      _vm.currentTab == "item3"
        ? _c("scan-g2mon-server", { attrs: { type: "g2" } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }