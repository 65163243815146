var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { grbackground: _vm.tab > 0 } },
    [
      _vm.tab == 0
        ? _c("div", { staticClass: "status" }, [
            _c("em", [
              _c("i", [_vm._v("Account Reviews")]),
              _vm._v(
                _vm._s(_vm.accountReview) +
                  " " +
                  _vm._s(_vm.accountReview != 1 ? "Days" : "Day")
              )
            ]),
            _vm._v(" "),
            _c("label", [
              _vm._v("Current Status: "),
              _c("strong", [
                _vm._v(
                  _vm._s(_vm.item.agentDb.status) +
                    " (" +
                    _vm._s(_vm.statusDesc(_vm.item.agentDb.status)) +
                    ")"
                )
              ])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.tab == 0
        ? _c("form", { staticClass: "preq-form preq-view" }, [
            _c("section", [
              _c("ul", { staticClass: "appview-list" }, [
                _c("li", [
                  _c("label", [_vm._v("Submit Date")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.formatDate(
                            _vm.item.agentDb.createdAt,
                            "YYYY-MM-DD"
                          )
                        )
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Approval Date")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.formatDate(
                            _vm.item.agentDb.approvalDate,
                            "YYYY-MM-DD"
                          )
                        )
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Partner Solution")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.solutionForId(_vm.item.agentDb.solutionId))
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                false
                  ? _c("li", [
                      _c("label", [_vm._v("Partner Solution")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "validator" },
                        [
                          _vm.editMode
                            ? _c("dyn-select", {
                                attrs: { options: _vm.solutions },
                                model: {
                                  value: _vm.item.agentDb.solutions,
                                  callback: function($$v) {
                                    _vm.$set(_vm.item.agentDb, "solutions", $$v)
                                  },
                                  expression: "item.agentDb.solutions"
                                }
                              })
                            : _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.solution(_vm.item.agentDb.solutions)
                                  )
                                )
                              ])
                        ],
                        1
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Type")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    _c("span", [_vm._v(_vm._s(_vm.groupsObj[_vm.item.groups]))])
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Partner ID")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    _c("span", [_vm._v(_vm._s(_vm.item.agentId))])
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Tier")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    _c("span", [_vm._v(_vm._s(_vm.item.agentDb.agentClass))])
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Relationship Manager")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "validator" },
                    [
                      _vm.editMode
                        ? _c("dyn-select", {
                            attrs: { options: _vm.sourcesToShow },
                            model: {
                              value: _vm.item.manager,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "manager", $$v)
                              },
                              expression: "item.manager"
                            }
                          })
                        : _c("span", [
                            _vm._v(_vm._s(_vm.managerLabel(_vm.item.manager)))
                          ])
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Referrer")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "validator" },
                    [
                      _vm.editMode
                        ? _c("dyn-select", {
                            attrs: { options: _vm.usersToShow },
                            model: {
                              value: _vm.item.agentDb.referrer,
                              callback: function($$v) {
                                _vm.$set(_vm.item.agentDb, "referrer", $$v)
                              },
                              expression: "item.agentDb.referrer"
                            }
                          })
                        : _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.userById(_vm.item.agentDb.referrer).username
                              )
                            )
                          ])
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Language")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "validator" },
                    [
                      _vm.editMode
                        ? _c("dyn-select", {
                            attrs: { options: _vm.languages },
                            model: {
                              value: _vm.item.agentDb.language,
                              callback: function($$v) {
                                _vm.$set(_vm.item.agentDb, "language", $$v)
                              },
                              expression: "item.agentDb.language"
                            }
                          })
                        : _c("span", [
                            _vm._v(_vm._s(_vm.item.agentDb.language))
                          ])
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                false
                  ? _c("li", [
                      _c("label", [_vm._v("Processors")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "validator" }, [
                        _vm.editMode
                          ? _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.item.agentDb.processors,
                                  expression: "item.agentDb.processors"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: _vm.item.agentDb.processors },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.item.agentDb,
                                    "processors",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          : _c("span", [
                              _vm._v(_vm._s(_vm.item.agentDb.processors))
                            ])
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                false
                  ? _c("li", [
                      _c("label", [_vm._v("Regions")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "validator" }, [
                        _vm.editMode
                          ? _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.item.agentDb.regions,
                                  expression: "item.agentDb.regions"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: _vm.item.agentDb.regions },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.item.agentDb,
                                    "regions",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          : _c("span", [
                              _vm._v(_vm._s(_vm.item.agentDb.regions))
                            ])
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                false
                  ? _c("li", [
                      _c("label", [_vm._v("Sales Brand")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "validator" },
                        [
                          _vm.editMode
                            ? _c("dyn-select", {
                                attrs: { options: _vm.salesBrands },
                                model: {
                                  value: _vm.item.agentDb.salesBrand,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.item.agentDb,
                                      "salesBrand",
                                      $$v
                                    )
                                  },
                                  expression: "item.agentDb.salesBrand"
                                }
                              })
                            : _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.salesBrandForId(
                                      _vm.item.agentDb.salesBrand
                                    ).dba
                                  )
                                )
                              ])
                        ],
                        1
                      )
                    ])
                  : _vm._e()
              ])
            ]),
            _vm._v(" "),
            _vm.editMode
              ? _c("div", { staticClass: "form-actions" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-large",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.saveItem.apply(null, arguments)
                        }
                      }
                    },
                    [_vm._v("Save")]
                  )
                ])
              : _vm._e()
          ])
        : _vm.tab == 1
        ? _c("form", { staticClass: "preq-form preq-view" }, [
            _c(
              "section",
              [
                _vm._l(_vm.item.contacts, function(ubo, ui) {
                  return _c("ul", { staticClass: "appview-list" }, [
                    ui * 1 && _vm.editMode
                      ? _c("li", { staticClass: "remove" }, [
                          _c(
                            "a",
                            {
                              attrs: { href: "" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.item.contacts.splice(ui, 1)
                                }
                              }
                            },
                            [_vm._v("×")]
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [
                        _c("strong", [_vm._v("Contact " + _vm._s(ui + 1))]),
                        _vm._v(" First Name")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "validator" }, [
                        !_vm.editMode
                          ? _c("span", [_vm._v(_vm._s(ubo.firstName))])
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: ubo.firstName,
                                  expression: "ubo.firstName"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: ubo.firstName },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    ubo,
                                    "firstName",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [
                        _c("strong", [_vm._v("Contact " + _vm._s(ui + 1))]),
                        _vm._v(" Last Name")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "validator" }, [
                        !_vm.editMode
                          ? _c("span", [_vm._v(_vm._s(ubo.lastName))])
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: ubo.lastName,
                                  expression: "ubo.lastName"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: ubo.lastName },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(ubo, "lastName", $event.target.value)
                                }
                              }
                            })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [
                        _c("strong", [_vm._v("Contact " + _vm._s(ui + 1))]),
                        _vm._v(" E-mail")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "validator" }, [
                        !_vm.editMode
                          ? _c("span", [_vm._v(_vm._s(ubo.email))])
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: ubo.email,
                                  expression: "ubo.email"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: ubo.email },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(ubo, "email", $event.target.value)
                                }
                              }
                            })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [
                        _c("strong", [_vm._v("Contact " + _vm._s(ui + 1))]),
                        _vm._v(" Phone")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "validator" }, [
                        !_vm.editMode
                          ? _c("span", [_vm._v(_vm._s(ubo.phone))])
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: ubo.phone,
                                  expression: "ubo.phone"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: ubo.phone },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(ubo, "phone", $event.target.value)
                                }
                              }
                            })
                      ])
                    ])
                  ])
                }),
                _vm._v(" "),
                _vm.editMode
                  ? _c("div", { staticClass: "new" }, [
                      _c(
                        "a",
                        {
                          attrs: { href: "" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.item.contacts.push({})
                            }
                          }
                        },
                        [_vm._v("+")]
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c("ul", { staticClass: "appview-list" }, [
                  _c("li", [
                    _c("label", [_vm._v("Company Name")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "validator" }, [
                      !_vm.editMode
                        ? _c("span", [_vm._v(_vm._s(_vm.item.agentDb.name))])
                        : _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.item.agentDb.name,
                                expression: "item.agentDb.name"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: { value: _vm.item.agentDb.name },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.item.agentDb,
                                  "name",
                                  $event.target.value
                                )
                              }
                            }
                          })
                    ])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("label", [_vm._v("Address")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "validator" }, [
                      !_vm.editMode
                        ? _c("span", [_vm._v(_vm._s(_vm.item.agentDb.address))])
                        : _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.item.agentDb.address,
                                expression: "item.agentDb.address"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: { value: _vm.item.agentDb.address },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.item.agentDb,
                                  "address",
                                  $event.target.value
                                )
                              }
                            }
                          })
                    ])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("label", [_vm._v("City")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "validator" }, [
                      !_vm.editMode
                        ? _c("span", [_vm._v(_vm._s(_vm.item.agentDb.city))])
                        : _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.item.agentDb.city,
                                expression: "item.agentDb.city"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: { value: _vm.item.agentDb.city },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.item.agentDb,
                                  "city",
                                  $event.target.value
                                )
                              }
                            }
                          })
                    ])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("label", [_vm._v("Zip/Postal Code")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "validator" }, [
                      !_vm.editMode
                        ? _c("span", [_vm._v(_vm._s(_vm.item.agentDb.zip))])
                        : _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.item.agentDb.zip,
                                expression: "item.agentDb.zip"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: { value: _vm.item.agentDb.zip },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.item.agentDb,
                                  "zip",
                                  $event.target.value
                                )
                              }
                            }
                          })
                    ])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("label", [_vm._v("State/Province")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "validator" }, [
                      !_vm.editMode
                        ? _c("span", [_vm._v(_vm._s(_vm.item.agentDb.state))])
                        : _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.item.agentDb.state,
                                expression: "item.agentDb.state"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: { value: _vm.item.agentDb.state },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.item.agentDb,
                                  "state",
                                  $event.target.value
                                )
                              }
                            }
                          })
                    ])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("label", [_vm._v("Country")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "validator" }, [
                      !_vm.editMode
                        ? _c("span", [_vm._v(_vm._s(_vm.item.agentDb.country))])
                        : _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.item.agentDb.country,
                                expression: "item.agentDb.country"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: { value: _vm.item.agentDb.country },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.item.agentDb,
                                  "country",
                                  $event.target.value
                                )
                              }
                            }
                          })
                    ])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("label", [_vm._v("Phone Number")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "validator" }, [
                      !_vm.editMode
                        ? _c("span", [_vm._v(_vm._s(_vm.item.agentDb.phone))])
                        : _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.item.agentDb.phone,
                                expression: "item.agentDb.phone"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: { value: _vm.item.agentDb.phone },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.item.agentDb,
                                  "phone",
                                  $event.target.value
                                )
                              }
                            }
                          })
                    ])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("label", [_vm._v("Company Email")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "validator" }, [
                      !_vm.editMode
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.item.agentDb.email ||
                                  (_vm.item.contacts[0] &&
                                    _vm.item.contacts[0].email)
                              )
                            )
                          ])
                        : _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.item.agentDb.email,
                                expression: "item.agentDb.email"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: { value: _vm.item.agentDb.email },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.item.agentDb,
                                  "email",
                                  $event.target.value
                                )
                              }
                            }
                          })
                    ])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("label", [_vm._v("Company Type")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "validator" },
                      [
                        !_vm.editMode
                          ? _c("span", [
                              _vm._v(_vm._s(_vm.item.agentDb.companyType))
                            ])
                          : _c("combo-select-ext", {
                              attrs: { options: _vm.tobOptions, type: "text" },
                              model: {
                                value: _vm.item.agentDb.companyType,
                                callback: function($$v) {
                                  _vm.$set(_vm.item.agentDb, "companyType", $$v)
                                },
                                expression: "item.agentDb.companyType"
                              }
                            })
                      ],
                      1
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c("ul", { staticClass: "appview-list" }, [
                  _c("li", [
                    _c("label", [_vm._v("Business Website")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "validator" }, [
                      !_vm.editMode
                        ? _c("span", [_vm._v(_vm._s(_vm.item.agentDb.website))])
                        : _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.item.agentDb.website,
                                expression: "item.agentDb.website"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: { value: _vm.item.agentDb.website },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.item.agentDb,
                                  "website",
                                  $event.target.value
                                )
                              }
                            }
                          })
                    ])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("label", [
                      _vm._v("Estimated merchant applications monthly")
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "validator" }, [
                      !_vm.editMode
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.item.agentDb.appMonthly))
                          ])
                        : _c(
                            "div",
                            { staticClass: "combo-select" },
                            [
                              _c("dyn-select", {
                                attrs: { options: _vm.validMapps },
                                model: {
                                  value: _vm.item.agentDb.appMonthly,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.item.agentDb,
                                      "appMonthly",
                                      $$v
                                    )
                                  },
                                  expression: "item.agentDb.appMonthly"
                                }
                              })
                            ],
                            1
                          )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("label", [_vm._v("Merchant Type")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "validator" },
                      [
                        !_vm.editMode
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.item.agentDb.merchantTypes &&
                                    _vm.item.agentDb.merchantTypes.join(", ")
                                )
                              )
                            ])
                          : _c("multiselect", {
                              attrs: {
                                hasOther: 0,
                                labels: [
                                  "POS",
                                  "E-Commerce",
                                  "Mobile",
                                  "In-App"
                                ],
                                options: [
                                  "POS/Card-Present",
                                  "E-Commerce/Card-Not-Present",
                                  "Mobile",
                                  "In-App"
                                ]
                              },
                              model: {
                                value: _vm.item.agentDb.merchantTypes,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.item.agentDb,
                                    "merchantTypes",
                                    $$v
                                  )
                                },
                                expression: "item.agentDb.merchantTypes"
                              }
                            })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("label", [_vm._v("Estimated Monthly Merchant Volume")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "validator" },
                      [
                        !_vm.editMode
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.moneyAmount(
                                    _vm.item.agentDb.monthlyVolume
                                  )
                                )
                              )
                            ])
                          : _c("formel-money-amount", {
                              model: {
                                value: _vm.item.agentDb.monthlyVolume,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.item.agentDb,
                                    "monthlyVolume",
                                    $$v
                                  )
                                },
                                expression: "item.agentDb.monthlyVolume"
                              }
                            })
                      ],
                      1
                    )
                  ])
                ])
              ],
              2
            ),
            _vm._v(" "),
            _vm.editMode
              ? _c("div", { staticClass: "form-actions" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-large",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.saveItem.apply(null, arguments)
                        }
                      }
                    },
                    [_vm._v("Save")]
                  )
                ])
              : _vm._e()
          ])
        : _vm.tab == 100
        ? _c("form", { staticClass: "preq-form preq-view" }, [
            _c("section", [
              _c("ul", { staticClass: "appview-list" }, [
                _c("li", [
                  _c("label", [_vm._v("Company Name")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [_vm._v(_vm._s(_vm.item.agentDb.name))])
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.agentDb.name,
                              expression: "item.agentDb.name"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: _vm.item.agentDb.name },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.item.agentDb,
                                "name",
                                $event.target.value
                              )
                            }
                          }
                        })
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Address")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [_vm._v(_vm._s(_vm.item.agentDb.address))])
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.agentDb.address,
                              expression: "item.agentDb.address"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: _vm.item.agentDb.address },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.item.agentDb,
                                "address",
                                $event.target.value
                              )
                            }
                          }
                        })
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("City")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [_vm._v(_vm._s(_vm.item.agentDb.city))])
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.agentDb.city,
                              expression: "item.agentDb.city"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: _vm.item.agentDb.city },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.item.agentDb,
                                "city",
                                $event.target.value
                              )
                            }
                          }
                        })
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Zip/Postal Code")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [_vm._v(_vm._s(_vm.item.agentDb.zip))])
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.agentDb.zip,
                              expression: "item.agentDb.zip"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: _vm.item.agentDb.zip },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.item.agentDb,
                                "zip",
                                $event.target.value
                              )
                            }
                          }
                        })
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("State/Province")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [_vm._v(_vm._s(_vm.item.agentDb.state))])
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.agentDb.state,
                              expression: "item.agentDb.state"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: _vm.item.agentDb.state },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.item.agentDb,
                                "state",
                                $event.target.value
                              )
                            }
                          }
                        })
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Country")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [_vm._v(_vm._s(_vm.item.agentDb.country))])
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.agentDb.country,
                              expression: "item.agentDb.country"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: _vm.item.agentDb.country },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.item.agentDb,
                                "country",
                                $event.target.value
                              )
                            }
                          }
                        })
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Phone Number")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [_vm._v(_vm._s(_vm.item.agentDb.phone))])
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.agentDb.phone,
                              expression: "item.agentDb.phone"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: _vm.item.agentDb.phone },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.item.agentDb,
                                "phone",
                                $event.target.value
                              )
                            }
                          }
                        })
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Company Email")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.item.agentDb.email ||
                                (_vm.item.contacts[0] &&
                                  _vm.item.contacts[0].email)
                            )
                          )
                        ])
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.agentDb.email,
                              expression: "item.agentDb.email"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: _vm.item.agentDb.email },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.item.agentDb,
                                "email",
                                $event.target.value
                              )
                            }
                          }
                        })
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Company Type")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "validator" },
                    [
                      !_vm.editMode
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.item.agentDb.companyType))
                          ])
                        : _c("combo-select-ext", {
                            attrs: { options: _vm.tobOptions, type: "text" },
                            model: {
                              value: _vm.item.agentDb.companyType,
                              callback: function($$v) {
                                _vm.$set(_vm.item.agentDb, "companyType", $$v)
                              },
                              expression: "item.agentDb.companyType"
                            }
                          })
                    ],
                    1
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            _vm.editMode
              ? _c("div", { staticClass: "form-actions" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-large",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.saveItem.apply(null, arguments)
                        }
                      }
                    },
                    [_vm._v("Save")]
                  )
                ])
              : _vm._e()
          ])
        : _vm.tab == 101
        ? _c("form", { staticClass: "preq-form preq-view" }, [
            _c(
              "section",
              [
                _vm._l(_vm.item.contacts, function(ubo, ui) {
                  return _c("ul", { staticClass: "appview-list" }, [
                    ui * 1 && _vm.editMode
                      ? _c("li", { staticClass: "remove" }, [
                          _c(
                            "a",
                            {
                              attrs: { href: "" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.item.contacts.splice(ui, 1)
                                }
                              }
                            },
                            [_vm._v("×")]
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [
                        _c("strong", [_vm._v("Contact " + _vm._s(ui + 1))]),
                        _vm._v(" First Name")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "validator" }, [
                        !_vm.editMode
                          ? _c("span", [_vm._v(_vm._s(ubo.firstName))])
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: ubo.firstName,
                                  expression: "ubo.firstName"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: ubo.firstName },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    ubo,
                                    "firstName",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [
                        _c("strong", [_vm._v("Contact " + _vm._s(ui + 1))]),
                        _vm._v(" Last Name")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "validator" }, [
                        !_vm.editMode
                          ? _c("span", [_vm._v(_vm._s(ubo.lastName))])
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: ubo.lastName,
                                  expression: "ubo.lastName"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: ubo.lastName },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(ubo, "lastName", $event.target.value)
                                }
                              }
                            })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [
                        _c("strong", [_vm._v("Contact " + _vm._s(ui + 1))]),
                        _vm._v(" E-mail")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "validator" }, [
                        !_vm.editMode
                          ? _c("span", [_vm._v(_vm._s(ubo.email))])
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: ubo.email,
                                  expression: "ubo.email"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: ubo.email },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(ubo, "email", $event.target.value)
                                }
                              }
                            })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [
                        _c("strong", [_vm._v("Contact " + _vm._s(ui + 1))]),
                        _vm._v(" Phone")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "validator" }, [
                        !_vm.editMode
                          ? _c("span", [_vm._v(_vm._s(ubo.phone))])
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: ubo.phone,
                                  expression: "ubo.phone"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: ubo.phone },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(ubo, "phone", $event.target.value)
                                }
                              }
                            })
                      ])
                    ])
                  ])
                }),
                _vm._v(" "),
                _vm.editMode
                  ? _c("div", { staticClass: "new" }, [
                      _c(
                        "a",
                        {
                          attrs: { href: "" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.item.contacts.push({})
                            }
                          }
                        },
                        [_vm._v("+")]
                      )
                    ])
                  : _vm._e()
              ],
              2
            ),
            _vm._v(" "),
            _vm.editMode
              ? _c("div", { staticClass: "form-actions" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-large",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.saveItem.apply(null, arguments)
                        }
                      }
                    },
                    [_vm._v("Save")]
                  )
                ])
              : _vm._e()
          ])
        : _vm.tab == 102
        ? _c("form", { staticClass: "preq-form preq-view" }, [
            _c("section", [
              _c("ul", { staticClass: "appview-list" }, [
                _c("li", [
                  _c("label", [_vm._v("Business Website")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [_vm._v(_vm._s(_vm.item.agentDb.website))])
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.agentDb.website,
                              expression: "item.agentDb.website"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: _vm.item.agentDb.website },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.item.agentDb,
                                "website",
                                $event.target.value
                              )
                            }
                          }
                        })
                  ])
                ]),
                _vm._v(" "),
                _c("li", { staticStyle: { width: "80%" } }, [
                  _c("label", [_vm._v("Business Description")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.item.agentDb.description,
                          expression: "item.agentDb.description"
                        }
                      ],
                      staticClass: "more-details",
                      staticStyle: { resize: "none", heigth: "300px" },
                      attrs: { disabled: !_vm.editMode },
                      domProps: { value: _vm.item.agentDb.description },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.item.agentDb,
                            "description",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("ul", { staticClass: "appview-list" }, [
                _c(
                  "li",
                  { staticStyle: { width: "50%" } },
                  [
                    _c("label", [_vm._v("Services Requested")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "payment-checkbox" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.item.agentDb.payments,
                            expression: "item.agentDb.payments"
                          }
                        ],
                        attrs: {
                          disabled: !_vm.editMode,
                          id: "payment_checkbox",
                          type: "checkbox"
                        },
                        domProps: {
                          checked: Array.isArray(_vm.item.agentDb.payments)
                            ? _vm._i(_vm.item.agentDb.payments, null) > -1
                            : _vm.item.agentDb.payments
                        },
                        on: {
                          change: function($event) {
                            var $$a = _vm.item.agentDb.payments,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.item.agentDb,
                                    "payments",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.item.agentDb,
                                    "payments",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.item.agentDb, "payments", $$c)
                            }
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "payment_checkbox" } }, [
                        _vm._v("  PAYMENTS")
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "validator",
                      {
                        staticClass: "payment-multi",
                        staticStyle: { width: "23%" },
                        attrs: {
                          value: "item.agentDb.merchantTypes",
                          reqs: "required"
                        }
                      },
                      [
                        _c("multiselect", {
                          class: _vm.payment - _vm.multi,
                          attrs: {
                            readonly: !_vm.editMode,
                            hasOther: 0,
                            options: [
                              "POS",
                              "E-Commerce",
                              "Unattended",
                              "EFT / ACH",
                              "Debit Only"
                            ]
                          },
                          model: {
                            value: _vm.item.agentDb.merchantTypes,
                            callback: function($$v) {
                              _vm.$set(_vm.item.agentDb, "merchantTypes", $$v)
                            },
                            expression: "item.agentDb.merchantTypes"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("label", [
                      _vm._v("Estimated Merchant Activations Monthly?")
                    ]),
                    _vm._v(" "),
                    !_vm.editMode
                      ? _c("div", [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.item.agentDb.appMonthly) +
                              "\n          "
                          )
                        ])
                      : _c(
                          "validator",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              value: _vm.item.agentDb.appMonthly,
                              reqs: "required"
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "combo-select" },
                              [
                                _c("dyn-select", {
                                  attrs: { options: _vm.validMapps },
                                  model: {
                                    value: _vm.item.agentDb.appMonthly,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.item.agentDb,
                                        "appMonthly",
                                        $$v
                                      )
                                    },
                                    expression: "item.agentDb.appMonthly"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        ),
                    _vm._v(" "),
                    _c("label", [_vm._v("Estimated Monthly Merchant Volume")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "validator" },
                      [
                        !_vm.editMode
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.moneyAmount(
                                    _vm.item.agentDb.monthlyVolume
                                  )
                                )
                              )
                            ])
                          : _c("formel-money-amount", {
                              model: {
                                value: _vm.item.agentDb.monthlyVolume,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.item.agentDb,
                                    "monthlyVolume",
                                    $$v
                                  )
                                },
                                expression: "item.agentDb.monthlyVolume"
                              }
                            })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  { staticStyle: { width: "50%" } },
                  [
                    _c("label", [_vm._v(" ")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "payment-checkbox" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.item.agentDb.treasury,
                            expression: "item.agentDb.treasury"
                          }
                        ],
                        attrs: {
                          disabled: !_vm.editMode,
                          id: "treasury_checkbox",
                          type: "checkbox"
                        },
                        domProps: {
                          checked: Array.isArray(_vm.item.agentDb.treasury)
                            ? _vm._i(_vm.item.agentDb.treasury, null) > -1
                            : _vm.item.agentDb.treasury
                        },
                        on: {
                          change: function($event) {
                            var $$a = _vm.item.agentDb.treasury,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.item.agentDb,
                                    "treasury",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.item.agentDb,
                                    "treasury",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.item.agentDb, "treasury", $$c)
                            }
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "treasury_checkbox" } }, [
                        _vm._v("  TREASURY")
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "validator",
                      {
                        staticClass: "payment-multi",
                        staticStyle: { width: "30%" },
                        attrs: {
                          value: "item.agentDb.treasuryTypes",
                          reqs: "required"
                        }
                      },
                      [
                        _c("multiselect", {
                          attrs: {
                            readonly: !_vm.editMode,
                            hasOther: 0,
                            options: [
                              "Personal Accounts",
                              "Business Accounts",
                              "Debit Cards",
                              "Digital Assets",
                              "Assets Pay"
                            ]
                          },
                          model: {
                            value: _vm.item.agentDb.treasuryTypes,
                            callback: function($$v) {
                              _vm.$set(_vm.item.agentDb, "treasuryTypes", $$v)
                            },
                            expression: "item.agentDb.treasuryTypes"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("label", [_vm._v("Estimated Accounts Monthly?")]),
                    _vm._v(" "),
                    !_vm.editMode
                      ? _c("div", [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.item.agentDb.treasuryMonthly) +
                              "\n          "
                          )
                        ])
                      : _c(
                          "validator",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              value: _vm.item.agentDb.treasuryMonthly,
                              reqs: "required"
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "combo-select" },
                              [
                                _c("dyn-select", {
                                  attrs: { options: _vm.validMapps },
                                  model: {
                                    value: _vm.item.agentDb.treasuryMonthly,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.item.agentDb,
                                        "treasuryMonthly",
                                        $$v
                                      )
                                    },
                                    expression: "item.agentDb.treasuryMonthly"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        ),
                    _vm._v(" "),
                    _c("label", [_vm._v("Estimated Account Volume")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "validator" },
                      [
                        !_vm.editMode
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.moneyAmount(
                                    _vm.item.agentDb.treasuryVolume
                                  )
                                )
                              )
                            ])
                          : _c("formel-money-amount", {
                              model: {
                                value: _vm.item.agentDb.treasuryVolume,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.item.agentDb,
                                    "treasuryVolume",
                                    $$v
                                  )
                                },
                                expression: "item.agentDb.treasuryVolume"
                              }
                            })
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ]),
            _vm._v(" "),
            _vm.editMode
              ? _c("div", { staticClass: "form-actions" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-large",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.saveItem.apply(null, arguments)
                        }
                      }
                    },
                    [_vm._v("Save")]
                  )
                ])
              : _vm._e()
          ])
        : _vm.tab == 2
        ? _c("form", { staticClass: "preq-form" }, [
            _c(
              "section",
              [
                _vm._l(_vm.item.agentDb.accounts, function(account, i) {
                  return _c("div", [
                    _c("ul", { staticClass: "column-form appview-list" }, [
                      _c("li", { staticClass: "title" }, [
                        _vm._v("Account #" + _vm._s(i + 1) + " "),
                        _vm.editMode
                          ? _c(
                              "a",
                              {
                                staticClass: "remove",
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.item.agentDb.accounts.splice(
                                      i,
                                      1
                                    )
                                  }
                                }
                              },
                              [_vm._v("×")]
                            )
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c(
                        "li",
                        [
                          _c("label", [_vm._v("currency")]),
                          _vm._v(" "),
                          _c(
                            "validator",
                            {
                              attrs: {
                                value: account.accountCurrency,
                                reqs: "required"
                              }
                            },
                            [
                              !_vm.editMode
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.currency(account.accountCurrency)
                                      )
                                    )
                                  ])
                                : _c("dyn-select", {
                                    attrs: { options: _vm.currencies },
                                    model: {
                                      value: account.accountCurrency,
                                      callback: function($$v) {
                                        _vm.$set(
                                          account,
                                          "accountCurrency",
                                          $$v
                                        )
                                      },
                                      expression: "account.accountCurrency"
                                    }
                                  })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        [
                          _c("label", [_vm._v("Account Name")]),
                          _vm._v(" "),
                          _c(
                            "validator",
                            {
                              attrs: {
                                value: account.accountHolder,
                                reqs: "required legalName"
                              }
                            },
                            [
                              !_vm.editMode
                                ? _c("span", [
                                    _vm._v(_vm._s(account.accountHolder))
                                  ])
                                : _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: account.accountHolder,
                                        expression: "account.accountHolder"
                                      }
                                    ],
                                    attrs: { type: "text" },
                                    domProps: { value: account.accountHolder },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          account,
                                          "accountHolder",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        [
                          _c("label", [_vm._v("Account Number")]),
                          _vm._v(" "),
                          _c(
                            "validator",
                            {
                              attrs: {
                                value: account.accountNumber,
                                reqs: "required num20"
                              }
                            },
                            [
                              !_vm.editMode
                                ? _c("span", [
                                    _vm._v(_vm._s(account.accountNumber))
                                  ])
                                : _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: account.accountNumber,
                                        expression: "account.accountNumber"
                                      }
                                    ],
                                    attrs: { type: "text" },
                                    domProps: { value: account.accountNumber },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          account,
                                          "accountNumber",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        [
                          _c("label", [_vm._v("Institution Number")]),
                          _vm._v(" "),
                          _c(
                            "validator",
                            {
                              attrs: {
                                value: account.accountSwift,
                                reqs: "required num5"
                              }
                            },
                            [
                              !_vm.editMode
                                ? _c("span", [
                                    _vm._v(_vm._s(account.accountSwift))
                                  ])
                                : _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: account.accountSwift,
                                        expression: "account.accountSwift"
                                      }
                                    ],
                                    attrs: { type: "text" },
                                    domProps: { value: account.accountSwift },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          account,
                                          "accountSwift",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        [
                          _c("label", [_vm._v("Branch/Transit Number")]),
                          _vm._v(" "),
                          _c(
                            "validator",
                            {
                              attrs: {
                                value: account.accountIBAN,
                                reqs: "required num6"
                              }
                            },
                            [
                              !_vm.editMode
                                ? _c("span", [
                                    _vm._v(_vm._s(account.accountIBAN))
                                  ])
                                : _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: account.accountIBAN,
                                        expression: "account.accountIBAN"
                                      }
                                    ],
                                    attrs: { type: "text" },
                                    domProps: { value: account.accountIBAN },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          account,
                                          "accountIBAN",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        [
                          _c("label", [_vm._v("Routing Number")]),
                          _vm._v(" "),
                          _c(
                            "validator",
                            {
                              attrs: {
                                value: account.accountRouting,
                                reqs: "num9e"
                              }
                            },
                            [
                              !_vm.editMode
                                ? _c("span", [
                                    _vm._v(_vm._s(account.accountRouting))
                                  ])
                                : _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: account.accountRouting,
                                        expression: "account.accountRouting"
                                      }
                                    ],
                                    attrs: { type: "text" },
                                    domProps: { value: account.accountRouting },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          account,
                                          "accountRouting",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        [
                          _c("label", [_vm._v("Bank name")]),
                          _vm._v(" "),
                          _c(
                            "validator",
                            {
                              attrs: {
                                value: account.bankName,
                                reqs: "required alnum30"
                              }
                            },
                            [
                              !_vm.editMode
                                ? _c("span", [_vm._v(_vm._s(account.bankName))])
                                : _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: account.bankName,
                                        expression: "account.bankName"
                                      }
                                    ],
                                    attrs: { type: "text" },
                                    domProps: { value: account.bankName },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          account,
                                          "bankName",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        [
                          _c("label", [_vm._v("Bank address")]),
                          _vm._v(" "),
                          _c(
                            "validator",
                            {
                              attrs: {
                                value: account.bankAddress,
                                reqs: "required address"
                              }
                            },
                            [
                              !_vm.editMode
                                ? _c("span", [
                                    _vm._v(_vm._s(account.bankAddress))
                                  ])
                                : _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: account.bankAddress,
                                        expression: "account.bankAddress"
                                      }
                                    ],
                                    attrs: { type: "text" },
                                    domProps: { value: account.bankAddress },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          account,
                                          "bankAddress",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        [
                          _c("label", [_vm._v("Postal Code/Zip")]),
                          _vm._v(" "),
                          _c(
                            "validator",
                            {
                              attrs: {
                                value: account.bankAddressPostCode,
                                reqs: "required postcode"
                              }
                            },
                            [
                              !_vm.editMode
                                ? _c("span", [
                                    _vm._v(_vm._s(account.bankAddressPostCode))
                                  ])
                                : _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: account.bankAddressPostCode,
                                        expression:
                                          "account.bankAddressPostCode"
                                      }
                                    ],
                                    attrs: { type: "text" },
                                    domProps: {
                                      value: account.bankAddressPostCode
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          account,
                                          "bankAddressPostCode",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        [
                          _c("label", [_vm._v("City")]),
                          _vm._v(" "),
                          _c(
                            "validator",
                            {
                              attrs: {
                                value: account.bankAddressCity,
                                reqs: "required city"
                              }
                            },
                            [
                              !_vm.editMode
                                ? _c("span", [
                                    _vm._v(_vm._s(account.bankAddressCity))
                                  ])
                                : _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: account.bankAddressCity,
                                        expression: "account.bankAddressCity"
                                      }
                                    ],
                                    attrs: { type: "text" },
                                    domProps: {
                                      value: account.bankAddressCity
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          account,
                                          "bankAddressCity",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        [
                          _c("label", [_vm._v("Province/State")]),
                          _vm._v(" "),
                          _c(
                            "validator",
                            {
                              attrs: {
                                value: account.bankAddressState,
                                reqs: "required"
                              }
                            },
                            [
                              !_vm.editMode
                                ? _c("span", [
                                    _vm._v(_vm._s(account.bankAddressState))
                                  ])
                                : _c("province-select", {
                                    attrs: {
                                      country: account.bankAddressCountry
                                    },
                                    model: {
                                      value: account.bankAddressState,
                                      callback: function($$v) {
                                        _vm.$set(
                                          account,
                                          "bankAddressState",
                                          $$v
                                        )
                                      },
                                      expression: "account.bankAddressState"
                                    }
                                  })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        [
                          _c("label", [_vm._v("Country")]),
                          _vm._v(" "),
                          _c(
                            "validator",
                            {
                              attrs: {
                                value: account.bankAddressCountry,
                                reqs: "required country"
                              }
                            },
                            [
                              !_vm.editMode
                                ? _c("span", [
                                    _vm._v(_vm._s(account.bankAddressCountry))
                                  ])
                                : _c(
                                    "div",
                                    { staticClass: "combo-select" },
                                    [
                                      _c("dyn-select", {
                                        attrs: { options: _vm.validCountries },
                                        model: {
                                          value: account.bankAddressCountry,
                                          callback: function($$v) {
                                            _vm.$set(
                                              account,
                                              "bankAddressCountry",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "account.bankAddressCountry"
                                        }
                                      })
                                    ],
                                    1
                                  )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        [
                          _c("label", [_vm._v("Bank telephone number")]),
                          _vm._v(" "),
                          _c(
                            "validator",
                            {
                              attrs: {
                                value: account.bankPhone,
                                reqs: "required phone"
                              }
                            },
                            [
                              !_vm.editMode
                                ? _c("span", [
                                    _vm._v(_vm._s(account.bankPhone))
                                  ])
                                : _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: account.bankPhone,
                                        expression: "account.bankPhone"
                                      }
                                    ],
                                    attrs: { type: "text" },
                                    domProps: { value: account.bankPhone },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          account,
                                          "bankPhone",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                            ]
                          )
                        ],
                        1
                      )
                    ])
                  ])
                }),
                _vm._v(" "),
                _vm.editMode && _vm.item.agentDb.accounts.length < 5
                  ? _c(
                      "a",
                      {
                        staticClass: "new",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            _vm.item.agentDb.accounts.length < 5 &&
                              _vm.item.agentDb.accounts.push({})
                          }
                        }
                      },
                      [_vm._v("+")]
                    )
                  : _vm._e()
              ],
              2
            ),
            _vm._v(" "),
            _vm.editMode
              ? _c("div", { staticClass: "form-actions" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-large",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.saveItem.apply(null, arguments)
                        }
                      }
                    },
                    [_vm._v("Save")]
                  )
                ])
              : _vm._e()
          ])
        : _vm.tab == 3
        ? _c("form", { staticClass: "preq-form" }, [
            _c("section"),
            _vm._v(" "),
            _vm.editMode
              ? _c("div", { staticClass: "form-actions" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-large",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.saveItem.apply(null, arguments)
                        }
                      }
                    },
                    [_vm._v("Save")]
                  )
                ])
              : _vm._e()
          ])
        : _vm.tab == 4
        ? _c("form", { staticClass: "preq-form" }, [
            _c("section"),
            _vm._v(" "),
            _vm.editMode
              ? _c("div", { staticClass: "form-actions" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-large",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.saveItem.apply(null, arguments)
                        }
                      }
                    },
                    [_vm._v("Save")]
                  )
                ])
              : _vm._e()
          ])
        : _vm.tab == 5
        ? _c(
            "form",
            { staticClass: "preq-form" },
            [
              _c("notes", {
                staticClass: "appl-notes",
                attrs: { notes: _vm.notes, type: "User", id: _vm.item.id }
              })
            ],
            1
          )
        : _vm.tab == 7
        ? _c("form", { staticClass: "preq-form" }, [
            _vm.setup || true
              ? _c("ul", { staticClass: "appview-list" }, [
                  _c("li", { staticStyle: { width: "33%" } }, [
                    _c("label", [_vm._v("Partner ID")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "validator" }, [
                      !_vm.editMode
                        ? _c("span", [_vm._v(_vm._s(_vm.item.agentId))])
                        : _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.item.agentId,
                                expression: "item.agentId"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: { value: _vm.item.agentId },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.item,
                                  "agentId",
                                  $event.target.value
                                )
                              }
                            }
                          })
                    ]),
                    _vm._v(" "),
                    _c("label", [_vm._v("Relationship Manager")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "validator" },
                      [
                        _vm.editMode
                          ? _c("dyn-select", {
                              attrs: { options: _vm.sourcesToShow },
                              model: {
                                value: _vm.item.manager,
                                callback: function($$v) {
                                  _vm.$set(_vm.item, "manager", $$v)
                                },
                                expression: "item.manager"
                              }
                            })
                          : _c("span", [
                              _vm._v(_vm._s(_vm.managerLabel(_vm.item.manager)))
                            ])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("label", [_vm._v("Company Name")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "validator" }, [
                      !_vm.editMode
                        ? _c("span", [_vm._v(_vm._s(_vm.item.agentDb.name))])
                        : _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.item.agentDb.name,
                                expression: "item.agentDb.name"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: { value: _vm.item.agentDb.name },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.item.agentDb,
                                  "name",
                                  $event.target.value
                                )
                              }
                            }
                          })
                    ]),
                    _vm._v(" "),
                    false ? _c("label", [_vm._v("Sales Brand")]) : _vm._e(),
                    _vm._v(" "),
                    false
                      ? _c(
                          "div",
                          { staticClass: "validator" },
                          [
                            _vm.editMode
                              ? _c("dyn-select", {
                                  attrs: { options: _vm.salesBrands },
                                  model: {
                                    value: _vm.item.agentDb.salesBrand,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.item.agentDb,
                                        "salesBrand",
                                        $$v
                                      )
                                    },
                                    expression: "item.agentDb.salesBrand"
                                  }
                                })
                              : _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.salesBrandForId(
                                        _vm.item.agentDb.salesBrand
                                      ).dba
                                    )
                                  )
                                ])
                          ],
                          1
                        )
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("li", { staticStyle: { width: "33%" } }, [
                    _c("label", [_vm._v("Partner Tier")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "validator" },
                      [
                        _vm.editMode
                          ? _c("dyn-select", {
                              attrs: { options: _vm.accountClassesList },
                              model: {
                                value: _vm.item.agentDb.agentClass,
                                callback: function($$v) {
                                  _vm.$set(_vm.item.agentDb, "agentClass", $$v)
                                },
                                expression: "item.agentDb.agentClass"
                              }
                            })
                          : _c("span", [
                              _vm._v(_vm._s(_vm.item.agentDb.agentClass))
                            ])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("label", [_vm._v("Partner Type")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "validator" }, [
                      !_vm.editMode
                        ? _c("span", [_vm._v(_vm._s(_vm.item.groups))])
                        : _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.item.groups,
                                expression: "item.groups"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: { value: _vm.item.groups },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.item,
                                  "groups",
                                  $event.target.value
                                )
                              }
                            }
                          })
                    ]),
                    _vm._v(" "),
                    _c("label", [_vm._v("Partner Solution")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "validator" }, [
                      !_vm.editMode
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.viewSolutions(_vm.item.agentDb.solutions)
                              )
                            )
                          ])
                        : _c("input", {
                            attrs: { type: "text", value: "TODO" }
                          })
                    ])
                  ]),
                  _vm._v(" "),
                  _c("li", { staticStyle: { width: "33%" } }, [
                    _c("label", [_vm._v("Language")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "validator" }, [
                      _vm.editMode
                        ? _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.item.agentDb.language,
                                expression: "item.agentDb.language"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: { value: _vm.item.agentDb.language },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.item.agentDb,
                                  "language",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        : _c("span", [
                            _vm._v(_vm._s(_vm.item.agentDb.language))
                          ])
                    ]),
                    _vm._v(" "),
                    _vm.setup ? _c("label", [_vm._v("PIN")]) : _vm._e(),
                    _vm._v(" "),
                    _vm.setup
                      ? _c("div", { staticClass: "validator" }, [
                          !_vm.editMode
                            ? _c("span", [_vm._v(_vm._s(_vm.setup.pin))])
                            : _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.setup.pin,
                                    expression: "setup.pin"
                                  }
                                ],
                                attrs: { type: "text" },
                                domProps: { value: _vm.setup.pin },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.setup,
                                      "pin",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("label", [_vm._v("Merchant Solutions")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "validator" }, [
                      !_vm.editMode
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.viewMSolutions(_vm.item.agentDb.msolutions)
                              )
                            )
                          ])
                        : _c("input", {
                            attrs: { type: "text", value: "TODO" }
                          })
                    ])
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("section"),
            _vm._v(" "),
            _vm.editMode
              ? _c("div", { staticClass: "form-actions" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-large",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.saveItem.apply(null, arguments)
                        }
                      }
                    },
                    [_vm._v("Save")]
                  )
                ])
              : _vm._e()
          ])
        : _vm.tab == 110
        ? _c("form", { staticClass: "preq-form" }, [
            _c("ul", { staticClass: "appview-list" }, [
              _c("li", { staticStyle: { width: "33%" } }, [
                _c("label", [_vm._v("Partner ID")]),
                _vm._v(" "),
                _c("div", { staticClass: "validator" }, [
                  _c("span", [_vm._v(_vm._s(_vm.item.agentId))])
                ]),
                _vm._v(" "),
                _c("label", [_vm._v("Relationship Manager")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "validator" },
                  [
                    _vm.editMode
                      ? _c("dyn-select", {
                          attrs: { options: _vm.sourcesToShow },
                          model: {
                            value: _vm.item.manager,
                            callback: function($$v) {
                              _vm.$set(_vm.item, "manager", $$v)
                            },
                            expression: "item.manager"
                          }
                        })
                      : _c("span", [
                          _vm._v(_vm._s(_vm.managerLabel(_vm.item.manager)))
                        ])
                  ],
                  1
                ),
                _vm._v(" "),
                false ? _c("label", [_vm._v("Sales Brand")]) : _vm._e(),
                _vm._v(" "),
                false
                  ? _c(
                      "div",
                      { staticClass: "validator" },
                      [
                        _vm.editMode
                          ? _c("dyn-select", {
                              attrs: { options: _vm.salesBrands },
                              model: {
                                value: _vm.item.agentDb.salesBrand,
                                callback: function($$v) {
                                  _vm.$set(_vm.item.agentDb, "salesBrand", $$v)
                                },
                                expression: "item.agentDb.salesBrand"
                              }
                            })
                          : _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.salesBrandForId(
                                    _vm.item.agentDb.salesBrand
                                  ).dba
                                )
                              )
                            ])
                      ],
                      1
                    )
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("li", { staticStyle: { width: "33%" } }, [
                _c("label", [_vm._v("Partner Tier")]),
                _vm._v(" "),
                _c("div", { staticClass: "validator" }, [
                  _c("span", [_vm._v(_vm._s(_vm.item.agentDb.agentClass))])
                ]),
                _vm._v(" "),
                _c("label", [_vm._v("Partner Type")]),
                _vm._v(" "),
                _c("div", { staticClass: "validator" }, [
                  _c("span", [_vm._v(_vm._s(_vm.groupsObj[_vm.item.groups]))])
                ]),
                _vm._v(" "),
                _c("label", [_vm._v("Partner Solution")]),
                _vm._v(" "),
                _c("div", { staticClass: "validator" }, [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.viewSolutions(_vm.item.agentDb.solutions))
                    )
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("li", { staticStyle: { width: "33%" } }, [
                _c("label", [_vm._v("Language")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "validator" },
                  [
                    _vm.editMode
                      ? _c("dyn-select", {
                          attrs: { options: _vm.languages },
                          model: {
                            value: _vm.item.agentDb.language,
                            callback: function($$v) {
                              _vm.$set(_vm.item.agentDb, "language", $$v)
                            },
                            expression: "item.agentDb.language"
                          }
                        })
                      : _c("span", [_vm._v(_vm._s(_vm.item.agentDb.language))])
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.setup ? _c("label", [_vm._v("PIN")]) : _vm._e(),
                _vm._v(" "),
                _vm.setup
                  ? _c("div", { staticClass: "validator" }, [
                      !_vm.editMode
                        ? _c("span", [_vm._v(_vm._s(_vm.setup.pin))])
                        : _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.setup.pin,
                                expression: "setup.pin"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: { value: _vm.setup.pin },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(_vm.setup, "pin", $event.target.value)
                              }
                            }
                          })
                    ])
                  : _vm._e()
              ])
            ]),
            _vm._v(" "),
            _c("section"),
            _vm._v(" "),
            _vm.editMode
              ? _c("div", { staticClass: "form-actions" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-large",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.saveItem.apply(null, arguments)
                        }
                      }
                    },
                    [_vm._v("Save")]
                  )
                ])
              : _vm._e()
          ])
        : _vm.tab == 111
        ? _c("form", { staticClass: "preq-form" }, [
            _c("h3", [_vm._v("Solutions")]),
            _vm._v(" "),
            _c("ul", { staticClass: "appview-list" }, [
              _c(
                "li",
                { staticStyle: { width: "33%" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "validator",
                      staticStyle: { display: "flex" }
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "solution-header",
                          staticStyle: { width: "30%" }
                        },
                        [_vm._v("Payments")]
                      ),
                      _vm._v(" "),
                      _c("mnb-switch", {
                        attrs: {
                          disabled: !_vm.editMode || !_vm.modules.rPayments
                        },
                        model: {
                          value: _vm.item.agentDb.solutionsPayments,
                          callback: function($$v) {
                            _vm.$set(_vm.item.agentDb, "solutionsPayments", $$v)
                          },
                          expression: "item.agentDb.solutionsPayments"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.msolutions, function(msolution) {
                    return _c(
                      "div",
                      {
                        staticClass: "validator solution-list",
                        staticStyle: { display: "flex" }
                      },
                      [
                        _c("span", { staticStyle: { width: "30%" } }, [
                          _vm._v(_vm._s(msolution.label))
                        ]),
                        _vm._v(" "),
                        _vm.item.agentDb.solutionsPayments
                          ? _c("mnb-switch", {
                              attrs: {
                                disabled:
                                  !_vm.editMode ||
                                  !_vm.item.agentDb.solutionsPayments
                              },
                              model: {
                                value: _vm.msolutionsSwitches[msolution.value],
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.msolutionsSwitches,
                                    msolution.value,
                                    $$v
                                  )
                                },
                                expression:
                                  "msolutionsSwitches[msolution.value]"
                              }
                            })
                          : _c("mnb-switch", {
                              attrs: {
                                disabled:
                                  !_vm.editMode ||
                                  !_vm.item.agentDb.solutionsPayments,
                                set: (_vm.msolutionsSwitches[
                                  msolution.value
                                ] = false)
                              }
                            })
                      ],
                      1
                    )
                  })
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "li",
                { staticStyle: { width: "33%" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "validator",
                      staticStyle: { display: "flex" }
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "solution-header",
                          staticStyle: { width: "30%" }
                        },
                        [_vm._v("Treasury")]
                      ),
                      _vm._v(" "),
                      _c("mnb-switch", {
                        attrs: {
                          disabled: !_vm.editMode || !_vm.modules.rBanking
                        },
                        model: {
                          value: _vm.item.agentDb.solutionsTreasury,
                          callback: function($$v) {
                            _vm.$set(_vm.item.agentDb, "solutionsTreasury", $$v)
                          },
                          expression: "item.agentDb.solutionsTreasury"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.tsolutions, function(tsolution) {
                    return _c(
                      "div",
                      {
                        staticClass: "validator solution-list",
                        staticStyle: { display: "flex" }
                      },
                      [
                        _c("span", { staticStyle: { width: "30%" } }, [
                          _vm._v(_vm._s(tsolution.label))
                        ]),
                        _vm._v(" "),
                        _vm.item.agentDb.solutionsTreasury
                          ? _c("mnb-switch", {
                              attrs: {
                                disabled:
                                  !_vm.editMode ||
                                  !_vm.item.agentDb.solutionsTreasury
                              },
                              model: {
                                value: _vm.tsolutionsSwitches[tsolution.value],
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.tsolutionsSwitches,
                                    tsolution.value,
                                    $$v
                                  )
                                },
                                expression:
                                  "tsolutionsSwitches[tsolution.value]"
                              }
                            })
                          : _c("mnb-switch", {
                              attrs: {
                                disabled:
                                  !_vm.editMode ||
                                  !_vm.item.agentDb.solutionsTreasury,
                                set: (_vm.tsolutionsSwitches[
                                  tsolution.value
                                ] = false)
                              }
                            })
                      ],
                      1
                    )
                  })
                ],
                2
              )
            ]),
            _vm._v(" "),
            _c("h3", [_vm._v("Modules")]),
            _vm._v(" "),
            _c("ul", { staticClass: "appview-list" }, [
              _c("li", { staticStyle: { width: "33%" } }, [
                _c(
                  "div",
                  {
                    staticClass: "validator",
                    staticStyle: { display: "flex" }
                  },
                  [
                    _c("span", { staticStyle: { width: "30%" } }, [
                      _vm._v("Amadel")
                    ]),
                    _vm._v(" "),
                    _vm.settings && _vm.settings.partners
                      ? [
                          !_vm.settings.partners.amadel
                            ? _c("mnb-switch", {
                                attrs: { disabled: true, value: false }
                              })
                            : [
                                _c("mnb-switch", {
                                  attrs: { disabled: !_vm.editMode },
                                  model: {
                                    value: _vm.item.agentDb.amadel,
                                    callback: function($$v) {
                                      _vm.$set(_vm.item.agentDb, "amadel", $$v)
                                    },
                                    expression: "item.agentDb.amadel"
                                  }
                                })
                              ]
                        ]
                      : [
                          _c("mnb-switch", {
                            attrs: { disabled: true, value: false }
                          })
                        ]
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "validator",
                    staticStyle: { display: "flex" }
                  },
                  [
                    _c("span", { staticStyle: { width: "30%" } }, [
                      _vm._v("Scheduler")
                    ]),
                    _vm._v(" "),
                    _vm.settings && _vm.settings.partners
                      ? [
                          !_vm.settings.partners.scheduler
                            ? _c("mnb-switch", {
                                attrs: { disabled: true, value: false }
                              })
                            : [
                                _c("mnb-switch", {
                                  attrs: { disabled: !_vm.editMode },
                                  model: {
                                    value: _vm.item.agentDb.scheduler,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.item.agentDb,
                                        "scheduler",
                                        $$v
                                      )
                                    },
                                    expression: "item.agentDb.scheduler"
                                  }
                                })
                              ]
                        ]
                      : [
                          _c("mnb-switch", {
                            attrs: { disabled: true, value: false }
                          })
                        ]
                  ],
                  2
                )
              ])
            ]),
            _vm._v(" "),
            _c("section"),
            _vm._v(" "),
            _vm.editMode
              ? _c("div", { staticClass: "form-actions" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-large",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.saveItem.apply(null, arguments)
                        }
                      }
                    },
                    [_vm._v("Save")]
                  )
                ])
              : _vm._e()
          ])
        : _vm.tab == 8
        ? _c("form", { staticClass: "preq-form" }, [
            _c("ul", { staticClass: "appview-list" }, [
              _c("li", { staticStyle: { width: "33%" } }, [
                false ? _c("label", [_vm._v("Sales Brand")]) : _vm._e(),
                _vm._v(" "),
                false
                  ? _c(
                      "div",
                      { staticClass: "validator" },
                      [
                        _vm.editMode
                          ? _c("dyn-select", {
                              attrs: { options: _vm.salesBrands },
                              model: {
                                value: _vm.item.agentDb.salesBrand,
                                callback: function($$v) {
                                  _vm.$set(_vm.item.agentDb, "salesBrand", $$v)
                                },
                                expression: "item.agentDb.salesBrand"
                              }
                            })
                          : _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.salesBrandForId(
                                    _vm.item.agentDb.salesBrand
                                  ).dba
                                )
                              )
                            ])
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("label", [_vm._v("Partner Tier")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "validator" },
                  [
                    _vm.editMode
                      ? _c("dyn-select", {
                          attrs: { options: _vm.accountClassesList },
                          model: {
                            value: _vm.item.agentDb.agentClass,
                            callback: function($$v) {
                              _vm.$set(_vm.item.agentDb, "agentClass", $$v)
                            },
                            expression: "item.agentDb.agentClass"
                          }
                        })
                      : _c("span", [
                          _vm._v(_vm._s(_vm.item.agentDb.agentClass))
                        ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c("label", [_vm._v("Partner Type")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "validator" },
                  [
                    !_vm.editMode
                      ? _c("span", [
                          _vm._v(_vm._s(_vm.groupsObj[_vm.item.groups]))
                        ])
                      : _c("dyn-select", {
                          attrs: { options: _vm.groups },
                          model: {
                            value: _vm.item.groups,
                            callback: function($$v) {
                              _vm.$set(_vm.item, "groups", $$v)
                            },
                            expression: "item.groups"
                          }
                        })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("li", { staticStyle: { width: "33%" } }, [
                _c("label", [_vm._v("BIN/ICA Rental Fee")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "validator" },
                  [
                    !_vm.editMode
                      ? _c("span", [
                          _vm._v(_vm._s(_vm.item.agentDb.bicRentalFee) + "%")
                        ])
                      : _c("formel-money-amount4", {
                          attrs: { suffix: "%" },
                          model: {
                            value: _vm.item.agentDb.bicRentalFee,
                            callback: function($$v) {
                              _vm.$set(_vm.item.agentDb, "bicRentalFee", $$v)
                            },
                            expression: "item.agentDb.bicRentalFee"
                          }
                        })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("label", [_vm._v("Partner Monthly Fee")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "validator" },
                  [
                    !_vm.editMode
                      ? _c("span", [
                          _vm._v("$" + _vm._s(_vm.item.agentDb.setupFee))
                        ])
                      : _c("formel-money-amount4", {
                          attrs: { prefix: "$" },
                          model: {
                            value: _vm.item.agentDb.setupFee,
                            callback: function($$v) {
                              _vm.$set(_vm.item.agentDb, "setupFee", $$v)
                            },
                            expression: "item.agentDb.setupFee"
                          }
                        })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("label", [_vm._v("Monthly Minimum")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "validator" },
                  [
                    !_vm.editMode
                      ? _c("span", [
                          _vm._v("$" + _vm._s(_vm.item.agentDb.monthlyMinimum))
                        ])
                      : _c("formel-money-amount4", {
                          attrs: { type: "text", prefix: "$" },
                          model: {
                            value: _vm.item.agentDb.monthlyMinimum,
                            callback: function($$v) {
                              _vm.$set(_vm.item.agentDb, "monthlyMinimum", $$v)
                            },
                            expression: "item.agentDb.monthlyMinimum"
                          }
                        })
                  ],
                  1
                )
              ])
            ]),
            _vm._v(" "),
            _c("section"),
            _vm._v(" "),
            _vm.editMode
              ? _c("div", { staticClass: "form-actions" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-large",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.saveItem.apply(null, arguments)
                        }
                      }
                    },
                    [_vm._v("Save")]
                  )
                ])
              : _vm._e()
          ])
        : _vm.tab == 9
        ? _c("form", { staticClass: "preq-form" }, [
            _vm.setup
              ? _c("ul", { staticClass: "appview-list" }, [
                  _c("li", [
                    _c("label", [_vm._v("Platform")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "validator" }, [
                      _c("span", [_vm._v(_vm._s(""))])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("label", [_vm._v("Platform ID")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "validator" }, [
                      _c("span", [_vm._v(_vm._s(""))])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("label", [_vm._v("Portal Name")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "validator" }, [
                      _c("span", [_vm._v(_vm._s("Trak"))])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("label", [_vm._v("Username")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "validator" }, [
                      !_vm.editMode
                        ? _c("span", [_vm._v(_vm._s(_vm.item.username))])
                        : _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.item.username,
                                expression: "item.username"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: { value: _vm.item.username },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.item,
                                  "username",
                                  $event.target.value
                                )
                              }
                            }
                          })
                    ])
                  ]),
                  _vm._v(" "),
                  _c("li"),
                  _vm._v(" "),
                  _c("li", [
                    _c("label", [_vm._v("Reset Password")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "validator" }, [
                      _c(
                        "button",
                        {
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.resetPassword.apply(null, arguments)
                            }
                          }
                        },
                        [_vm._v("Reset password")]
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("label", [_vm._v("Welcome Email")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "validator" }, [
                      _c(
                        "button",
                        {
                          staticClass: "button",
                          attrs: {
                            disabled:
                              _vm.item.agentDb.status != "A02" &&
                              _vm.item.agentDb.status != "A04" &&
                              _vm.item.agentDb.status != "A07"
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.$parent.sendEmail("Welcome", true)
                            }
                          }
                        },
                        [_vm._v("Send")]
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("label", [_vm._v("Block")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "validator" }, [
                      _c(
                        "button",
                        {
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.blockUser.apply(null, arguments)
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.item.active == "Blocked" ? "Unblock" : "Block"
                            )
                          )
                        ]
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("label", [_vm._v("Amadel")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "validator" }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.item.agentDb.amadel * 1 ? "Enabled" : "Disabled"
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.toggleAmadel.apply(null, arguments)
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.item.agentDb.amadel * 1 ? "Disable" : "Enable"
                            )
                          )
                        ]
                      )
                    ])
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("section"),
            _vm._v(" "),
            _vm.editMode
              ? _c("div", { staticClass: "form-actions" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-large",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.saveItem.apply(null, arguments)
                        }
                      }
                    },
                    [_vm._v("Save")]
                  )
                ])
              : _vm._e()
          ])
        : _vm.tab == 10
        ? _c("form", { staticClass: "preq-form" }, [
            _c("table", { staticClass: "content-table file-list" }, [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.agents, function(item, index) {
                  return _c("tr", [
                    _c("td", { staticClass: "text-left" }, [
                      _c("a", { attrs: { href: "/profile/sub/" + item.id } }, [
                        _vm._v(_vm._s(item.subAgentID))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-left" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(item.subAgentStatus) +
                          "\n        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-left" }, [
                      _vm._v(
                        "\n          " + _vm._s(item.firstName) + "\n        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-left" }, [
                      _vm._v(
                        "\n          " + _vm._s(item.lastName) + "\n        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-left" }, [
                      _vm._v("\n          " + _vm._s(item.email) + "\n        ")
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-right" })
                  ])
                }),
                0
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("portal", { attrs: { to: "actions" } }, [
        _c(
          "ul",
          [
            _vm.edit
              ? _c("li", [
                  _c(
                    "a",
                    {
                      attrs: { href: "" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.$emit("update:editMode", true)
                        }
                      }
                    },
                    [_vm._v("Edit")]
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.tab == 0
              ? _vm._l(_vm.allActions, function(optLabel, optMethod) {
                  return _vm.tab == 0
                    ? _c(
                        "li",
                        {
                          class: {
                            disabled: _vm.$parent.$parent.isDisabledAction(
                              optMethod
                            )
                          }
                        },
                        [
                          _c(
                            "a",
                            {
                              attrs: { href: "" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  !_vm.$parent.$parent.isDisabledAction(
                                    optMethod
                                  ) && _vm.$parent.$parent.doAction(optMethod)
                                }
                              }
                            },
                            [_vm._v(_vm._s(optLabel))]
                          )
                        ]
                      )
                    : _vm._e()
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.tab == 999
              ? _vm._l(_vm.statusActions, function(optLabel, optMethod) {
                  return _c(
                    "li",
                    {
                      class: {
                        disabled: _vm.activeStatuses.includes(
                          _vm.item.agentDb.status
                        )
                      }
                    },
                    [
                      _c(
                        "a",
                        {
                          attrs: { href: "", title: optLabel },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.$parent.$parent.doAction(optMethod)
                            }
                          }
                        },
                        [_vm._v(_vm._s(optMethod))]
                      )
                    ]
                  )
                })
              : _vm._e()
          ],
          2
        )
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Agent ID")]),
        _vm._v(" "),
        _c("th", [_vm._v("Status")]),
        _vm._v(" "),
        _c("th", [_vm._v("First Name")]),
        _vm._v(" "),
        _c("th", [_vm._v("Last Name")]),
        _vm._v(" "),
        _c("th", [_vm._v("Email")]),
        _vm._v(" "),
        _c("th", [_vm._v("Options")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }