var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "solution-select" },
    [
      !_vm.partner
        ? _c("label", [_vm._v("Solution:")])
        : _c("label", [_vm._v("Solution:")]),
      _vm._v(" "),
      _c("dyn-select", {
        attrs: { required: "", options: _vm.solutions },
        on: { input: _vm.handleChange },
        model: {
          value: _vm.solution,
          callback: function($$v) {
            _vm.solution = $$v
          },
          expression: "solution"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }