var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sup-ticket" }, [
    _c(
      "form",
      {
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.createTicket.apply(null, arguments)
          }
        }
      },
      [
        _c("h3", [_vm._v("Create Support Ticket")]),
        _vm._v(" "),
        _c("div", { staticClass: "sf-row2" }, [
          _c("div", { staticClass: "sf-row" }, [
            _c("label", [_vm._v("Account #")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.user.agentId,
                  expression: "user.agentId"
                }
              ],
              attrs: { type: "text", readonly: "" },
              domProps: { value: _vm.user.agentId },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.user, "agentId", $event.target.value)
                }
              }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "sf-row" }, [
            _c("label", [_vm._v("Your Name")]),
            _vm._v(" "),
            _c("input", {
              attrs: { type: "text", readonly: "" },
              domProps: {
                value:
                  _vm.user.companyName ||
                  _vm.user.fullName ||
                  (_vm.user.contacts &&
                    _vm.user.contacts[0] &&
                    _vm.user.contacts[0].firstName +
                      " " +
                      _vm.user.contacts[0].lastName)
              }
            })
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "sf-row" }, [
          _c("label", [_vm._v("Your E-Mail")]),
          _vm._v(" "),
          _c("input", {
            attrs: { type: "email", readonly: "" },
            domProps: {
              value:
                _vm.user.companyEmail ||
                _vm.user.email ||
                (_vm.user.agentDb && _vm.user.agentDb.email)
            }
          })
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "sf-row" },
          [
            _c("label", [_vm._v("Subject")]),
            _vm._v(" "),
            _c("dyn-select", {
              attrs: { options: _vm.subjOptions },
              model: {
                value: _vm.subject,
                callback: function($$v) {
                  _vm.subject = $$v
                },
                expression: "subject"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "sf-row" }, [
          _c("label", [_vm._v("Description of Issue")]),
          _vm._v(" "),
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.newMessage,
                expression: "newMessage"
              }
            ],
            domProps: { value: _vm.newMessage },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.newMessage = $event.target.value
              }
            }
          })
        ]),
        _vm._v(" "),
        _vm._m(0)
      ]
    ),
    _vm._v(" "),
    _vm._m(1)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "sf-row" }, [
      _c("button", [_vm._v("Submit Ticket")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "faq-box" }, [
      _c("div", [
        _c("div", { staticClass: "fq-title" }, [
          _vm._v("Frequently Asked Questions")
        ]),
        _vm._v(" "),
        _c("a", { staticClass: "fq-btn", attrs: { href: "" } }, [
          _vm._v("View FAQ")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }