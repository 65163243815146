var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.shown,
          expression: "shown"
        }
      ],
      staticClass: "form-popup"
    },
    [
      _c("div", [
        _c("div", { staticClass: "ncontent appf appnat" }, [
          _c(
            "a",
            {
              staticClass: "close",
              attrs: { href: "" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.dismiss.apply(null, arguments)
                }
              }
            },
            [_vm._v("×")]
          ),
          _vm._v(" "),
          _c("h3", [_vm._v("Final Approval")]),
          _vm._v(" "),
          _vm.step == 0
            ? _c("form", { staticClass: "form-step" }, [
                _c("section", [
                  _c("h2", [_vm._v("Implementation Settings")]),
                  _vm._v(" "),
                  _c("ul", { staticClass: "column-form column-form-compact" }, [
                    _c("li", [
                      _c("label", [_vm._v("Compliance Review Days")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "field" },
                        [
                          _c(
                            "validator",
                            {
                              attrs: {
                                value: _vm.form.reviewDays,
                                reqs: "required number"
                              }
                            },
                            [
                              _c("dyn-select", {
                                attrs: { options: _vm.reviewDaysOpts },
                                model: {
                                  value: _vm.form.reviewDays,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "reviewDays", $$v)
                                  },
                                  expression: "form.reviewDays"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Settlement Delay (Days)")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "field" },
                        [
                          _c(
                            "validator",
                            {
                              attrs: {
                                value: _vm.form.settlementDays,
                                reqs: "required number"
                              }
                            },
                            [
                              _c("dyn-select", {
                                attrs: { options: _vm.settlementDaysOpts },
                                model: {
                                  value: _vm.form.settlementDays,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "settlementDays", $$v)
                                  },
                                  expression: "form.settlementDays"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Monthly Volume Limit")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "field" },
                        [
                          _c(
                            "validator",
                            {
                              attrs: {
                                value: _vm.form.monthlyLimit,
                                reqs: "required number"
                              }
                            },
                            [
                              _c("formel-money-amount", {
                                attrs: { prefix: "$" },
                                model: {
                                  value: _vm.form.monthlyLimit,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "monthlyLimit", $$v)
                                  },
                                  expression: "form.monthlyLimit"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Annual Volume Limit")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "field" },
                        [
                          _c(
                            "validator",
                            {
                              attrs: {
                                value: _vm.form.annualLimit,
                                reqs: "required number"
                              }
                            },
                            [
                              _c("formel-money-amount", {
                                attrs: { prefix: "$" },
                                model: {
                                  value: _vm.form.annualLimit,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "annualLimit", $$v)
                                  },
                                  expression: "form.annualLimit"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Account Reserve")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "field" },
                        [
                          _c(
                            "validator",
                            {
                              attrs: {
                                value: _vm.form.reserve,
                                reqs: "required number"
                              }
                            },
                            [
                              _c("formel-money-amount", {
                                attrs: { suffix: "%" },
                                model: {
                                  value: _vm.form.reserve,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "reserve", $$v)
                                  },
                                  expression: "form.reserve"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Account Reserve Hold (Days)")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "field" },
                        [
                          _c(
                            "validator",
                            {
                              attrs: {
                                value: _vm.form.reserveHold,
                                reqs: "required number"
                              }
                            },
                            [
                              _c("dyn-select", {
                                attrs: { options: _vm.reserveHoldOpts },
                                model: {
                                  value: _vm.form.reserveHold,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "reserveHold", $$v)
                                  },
                                  expression: "form.reserveHold"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "buttons" }, [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-large btn-success",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.nextStep.apply(null, arguments)
                        }
                      }
                    },
                    [_vm._v("Next")]
                  )
                ])
              ])
            : _vm.step == 1
            ? _c("form", { staticClass: "form-step" }, [
                _c("section", [
                  _c("h2", [_vm._v("Implementation Message")]),
                  _vm._v(" "),
                  _c("ul", { staticClass: "column-form column-form-compact" }, [
                    _c("li", [
                      _c("label", [_vm._v("Implementation Message")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "field" },
                        [
                          _c(
                            "validator",
                            {
                              attrs: {
                                value: _vm.form.implMessage,
                                reqs: "char250"
                              }
                            },
                            [
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form.implMessage,
                                    expression: "form.implMessage"
                                  }
                                ],
                                attrs: { type: "text" },
                                domProps: { value: _vm.form.implMessage },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.form,
                                      "implMessage",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          )
                        ],
                        1
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "buttons" }, [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-large btn-success",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.save.apply(null, arguments)
                        }
                      }
                    },
                    [_vm._v("Final Approval")]
                  )
                ])
              ])
            : _vm._e()
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }