var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "element-toggle" }, [
    _c(
      "label",
      { class: { checked: _vm.actualValue == _vm.customVal } },
      [
        _c("span"),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.actualValue,
              expression: "actualValue"
            }
          ],
          attrs: { type: "radio" },
          domProps: {
            value: _vm.customVal,
            checked: _vm._q(_vm.actualValue, _vm.customVal)
          },
          on: {
            change: [
              function($event) {
                _vm.actualValue = _vm.customVal
              },
              _vm.updateVal
            ]
          }
        }),
        _vm._v("\n    " + _vm._s(_vm.customLabel || "No") + "\n    "),
        _c("datepicker", {
          attrs: {
            disabled: _vm.actualValue == _vm.defaultVal,
            future: _vm.future,
            placeholder: "Live Date"
          },
          on: { input: _vm.updateVal },
          model: {
            value: _vm.customValue,
            callback: function($$v) {
              _vm.customValue = $$v
            },
            expression: "customValue"
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c("label", { class: { checked: _vm.actualValue == _vm.defaultVal } }, [
      _c("span"),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.actualValue,
            expression: "actualValue"
          }
        ],
        attrs: { type: "radio" },
        domProps: {
          value: _vm.defaultVal,
          checked: _vm._q(_vm.actualValue, _vm.defaultVal)
        },
        on: {
          change: [
            function($event) {
              _vm.actualValue = _vm.defaultVal
            },
            _vm.updateVal
          ]
        }
      }),
      _vm._v("\n    " + _vm._s(_vm.defaultLabel || "Yes") + "\n  ")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }