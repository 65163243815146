var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("view-agent-precheck", { attrs: { uwList: _vm.precheckList } }),
      _vm._v(" "),
      _c("div", { staticClass: "actions" }, [
        _c(
          "button",
          {
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.goBack.apply(null, arguments)
              }
            }
          },
          [_vm._v("Back")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.doNext.apply(null, arguments)
              }
            }
          },
          [_vm._v("Next")]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }