var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ncontent nc-flat" },
    [
      _c(
        "tabs",
        { staticClass: "tabs" },
        [
          _c("tab", {
            attrs: { title: "New Enrollment" },
            on: {
              click: function($event) {
                return _vm.openLink("/applications/new")
              }
            }
          }),
          _vm._v(" "),
          _c("tab", { attrs: { title: "Merchants Overview", active: "" } })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "ncontent marginless" }, [
        _c("a", { staticClass: "back-button", attrs: { href: "/" } }, [
          _vm._v("Dashboard")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "header split" }, [
          _c(
            "div",
            [
              _vm._m(0),
              _vm._v(" "),
              _c("div", { staticClass: "toolbar" }),
              _vm._v(" "),
              _c("app-search-form", {
                attrs: { statuses: _vm.statuses, saved: _vm.parsedSaved }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "aside",
            [
              _c("h3", [_vm._v("Breakdown")]),
              _vm._v(" "),
              _c("breakdown-table", { attrs: { statuses: _vm.statuses } })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "hr" }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "content" },
          [_c("accordeon-results-application")],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "breadcrumbs" }, [
      _c("li", [_c("a", [_vm._v("Application Overview")])])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }