var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.uwShown
        ? _c("div", { staticClass: "action-sub-buttons" }, [
            _c("a", { staticClass: "btn", on: { click: _vm.newPreCheck } }, [
              _vm._v("Run new")
            ]),
            _vm._v(" "),
            _vm.uwSelected
              ? _c(
                  "a",
                  {
                    staticClass: "btn",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.doShowItems(_vm.uwSelected)
                      }
                    }
                  },
                  [_vm._v("View")]
                )
              : _vm._e()
          ])
        : _c("div", { staticClass: "action-sub-buttons" }, [
            _c(
              "a",
              {
                staticClass: "btn",
                on: {
                  click: function($event) {
                    _vm.uwShown = null
                  }
                }
              },
              [_vm._v("Back")]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "btn",
                attrs: { href: _vm.printUrl, target: "_blank" }
              },
              [_vm._v("Print")]
            )
          ]),
      _vm._v(" "),
      !_vm.uwShown
        ? _c("table", { staticClass: "file-list content-table" }, [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "tbody",
              _vm._l(_vm.uwList, function(uwItem, uwI) {
                return _c("tr", [
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.uwSelected,
                          expression: "uwSelected"
                        }
                      ],
                      attrs: { type: "radio" },
                      domProps: {
                        value: uwItem.id,
                        checked: _vm._q(_vm.uwSelected, uwItem.id)
                      },
                      on: {
                        change: function($event) {
                          _vm.uwSelected = uwItem.id
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(_vm.uwList.length - uwI))]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(uwItem.companyName))]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        _vm.formatDate(uwItem.createdDate, "YYYY-MM-DD HH:mm")
                      )
                    )
                  ])
                ])
              }),
              0
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.uwShown
        ? _c("div", { ref: "detailsBox", staticClass: "pre-check-details" }, [
            _c("div", { staticClass: "t2precheck" }, [
              _c("div", { staticClass: "t2sect" }, [
                _c("div", { staticClass: "t2box" }, [
                  _c("h3", [_vm._v("General Information")]),
                  _vm._v(" "),
                  _c("ul", [
                    _c("li", [
                      _c(
                        "div",
                        {
                          staticClass: "field",
                          class: { visible: _vm.shownItem > 4 }
                        },
                        [
                          _c("label", [_vm._v("Solution:")]),
                          _vm._v(" "),
                          _vm.uwShownItem.solutions
                            ? _c("span", [_vm._v(_vm._s(_vm.solutionName))])
                            : _c("span", { staticClass: "empty" }, [
                                _vm._v("NO")
                              ])
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Status:")]),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(_vm.uwShownItem.status))])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("App ID:")]),
                      _vm._v(" "),
                      _vm.uwShownItem.agentIdNum
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.uwShownItem.agentIdNum))
                          ])
                        : _c("span", { staticClass: "empty" }, [_vm._v("NO")])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Agent Type:")]),
                      _vm._v(" "),
                      _vm.uwShownItem.groups
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.groups[_vm.uwShownItem.groups]))
                          ])
                        : _c("span", { staticClass: "empty" }, [_vm._v("NO")])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Agent Tier:")]),
                      _vm._v(" "),
                      _vm.uwShownItem.agentClass
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.uwShownItem.agentClass))
                          ])
                        : _c("span", { staticClass: "empty" }, [_vm._v("NO")])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Relationship Manager:")]),
                      _vm._v(" "),
                      _vm.uwShownItem.manager
                        ? _c("span", [_vm._v(_vm._s(_vm.uwManager))])
                        : _c("span", { staticClass: "empty" }, [_vm._v("NO")])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Referral Source:")]),
                      _vm._v(" "),
                      _vm.uwShownItem.referrerSite
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.uwShownItem.referrerSite))
                          ])
                        : _c("span", { staticClass: "empty" }, [_vm._v("NO")])
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "t2sect" }, [
                _c("div", { staticClass: "t2box" }, [
                  _c("h3", [_vm._v("Company Information")]),
                  _vm._v(" "),
                  _c("ul", [
                    _c("li", [
                      _c("label", [_vm._v("Business Legal Name:")]),
                      _vm._v(" "),
                      _vm.uwShownItem.companyName
                        ? _c(
                            "span",
                            {
                              class: {
                                duplicate: _vm.uwShownItem.dupCompanyName
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.uwShownItem.companyName) +
                                  " " +
                                  _vm._s(_vm.uwShownItem.companyType)
                              )
                            ]
                          )
                        : _c("span", { staticClass: "empty" }, [_vm._v("NO")])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Business DBA:")]),
                      _vm._v(" "),
                      _vm.uwShownItem.companyNameTrade
                        ? _c(
                            "span",
                            {
                              class: {
                                duplicate: _vm.uwShownItem.dupCompanyNameTrade
                              }
                            },
                            [_vm._v(_vm._s(_vm.uwShownItem.companyNameTrade))]
                          )
                        : _c("span", { staticClass: "empty" }, [_vm._v("NO")])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Business Address:")]),
                      _vm._v(" "),
                      _vm.uwShownItem.companyAddress
                        ? _c(
                            "span",
                            {
                              class: { duplicate: _vm.uwShownItem.dupAddress }
                            },
                            [_vm._v(_vm._s(_vm.uwShownItem.companyAddress))]
                          )
                        : _c("span", { staticClass: "empty" }, [_vm._v("NO")])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Business Country:")]),
                      _vm._v(" "),
                      _vm.uwShownItem.companyCountry
                        ? _c(
                            "span",
                            {
                              class: { duplicate: _vm.uwShownItem.dupAddress }
                            },
                            [_vm._v(_vm._s(_vm.uwShownItem.companyCountry))]
                          )
                        : _c("span", { staticClass: "empty" }, [_vm._v("NO")])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Business Email:")]),
                      _vm._v(" "),
                      _vm.uwShownItem.companyEmail
                        ? _c(
                            "span",
                            {
                              class: {
                                duplicate: _vm.uwShownItem.dupCompanyEmail
                              }
                            },
                            [_vm._v(_vm._s(_vm.uwShownItem.companyEmail))]
                          )
                        : _c("span", { staticClass: "empty" }, [_vm._v("NO")])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Business Phone:")]),
                      _vm._v(" "),
                      _vm.uwShownItem.companyPhone
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.uwShownItem.companyPhone))
                          ])
                        : _c("span", { staticClass: "empty" }, [_vm._v("NO")])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Business Website:")]),
                      _vm._v(" "),
                      _vm.uwShownItem.website
                        ? _c(
                            "span",
                            {
                              class: { duplicate: _vm.uwShownItem.dupWebsite }
                            },
                            [_vm._v(_vm._s(_vm.uwShownItem.website))]
                          )
                        : _c("span", { staticClass: "empty" }, [_vm._v("NO")])
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "t2sect" }, [
                _c("div", { staticClass: "t2box" }, [
                  _c("h3", [_vm._v("Principals Information")]),
                  _vm._v(" "),
                  _c(
                    "ul",
                    [
                      _c("li", [
                        _c("label", [_vm._v("Number of contacts:")]),
                        _vm._v(" "),
                        _vm.uwShownItem.contacts
                          ? _c(
                              "span",
                              {
                                class: {
                                  duplicate: _vm.uwShownItem.dupContacts
                                }
                              },
                              [_vm._v(_vm._s(_vm.uwShownItem.contacts.length))]
                            )
                          : _c("span", { staticClass: "empty" }, [_vm._v("NO")])
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.uwShownItem.contacts, function(contact, ci) {
                        return [
                          _c("li", [
                            _c("label", [
                              _vm._v(
                                "Contact " + _vm._s(ci + 1) + " First Name"
                              )
                            ]),
                            _vm._v(" "),
                            contact.firstName
                              ? _c(
                                  "span",
                                  {
                                    class: {
                                      duplicate: _vm.uwShownItem.dupContacts
                                    }
                                  },
                                  [_vm._v(_vm._s(contact.firstName))]
                                )
                              : _c("span", { staticClass: "empty" }, [
                                  _vm._v("NO")
                                ])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [
                              _vm._v("Contact " + _vm._s(ci + 1) + " Last Name")
                            ]),
                            _vm._v(" "),
                            contact.lastName
                              ? _c(
                                  "span",
                                  {
                                    class: {
                                      duplicate: _vm.uwShownItem.dupContacts
                                    }
                                  },
                                  [_vm._v(_vm._s(contact.lastName))]
                                )
                              : _c("span", { staticClass: "empty" }, [
                                  _vm._v("NO")
                                ])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [
                              _vm._v("Contact " + _vm._s(ci + 1) + " Email")
                            ]),
                            _vm._v(" "),
                            contact.email
                              ? _c(
                                  "span",
                                  {
                                    class: {
                                      duplicate: _vm.uwShownItem.dupContacts
                                    }
                                  },
                                  [_vm._v(_vm._s(contact.email))]
                                )
                              : _c("span", { staticClass: "empty" }, [
                                  _vm._v("NO")
                                ])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [
                              _vm._v("Contact " + _vm._s(ci + 1) + " Phone")
                            ]),
                            _vm._v(" "),
                            contact.phone
                              ? _c(
                                  "span",
                                  {
                                    class: {
                                      duplicate: _vm.uwShownItem.dupContacts
                                    }
                                  },
                                  [_vm._v(_vm._s(contact.phone))]
                                )
                              : _c("span", { staticClass: "empty" }, [
                                  _vm._v("NO")
                                ])
                          ])
                        ]
                      })
                    ],
                    2
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "t2sect" }, [
                _c("div", { staticClass: "t2box" }, [
                  _c("h3", [_vm._v("Business Information")]),
                  _vm._v(" "),
                  _c("ul", [
                    _c("li", [
                      _c("label", [_vm._v("Merchant Types:")]),
                      _vm._v(" "),
                      _vm.uwShownItem.merchantTypes
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.uwShownItem.merchantTypes))
                          ])
                        : _c("span", { staticClass: "empty" }, [_vm._v("NO")])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Monthly Applications:")]),
                      _vm._v(" "),
                      _vm.uwShownItem.monthlyApplications
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.uwShownItem.monthlyApplications))
                          ])
                        : _c("span", { staticClass: "empty" }, [_vm._v("NO")])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Monthly Volume:")]),
                      _vm._v(" "),
                      _vm.uwShownItem.monthlyVolume
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.uwShownItem.monthlyVolume +
                                  " " +
                                  (_vm.currencies[
                                    _vm.uwShownItem.monthlyVolumeCurrency
                                  ] || "")
                              )
                            )
                          ])
                        : _c("span", { staticClass: "empty" }, [_vm._v("NO")])
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "t2sect" }, [
                _c("div", { staticClass: "t2box" }, [
                  _c("h3", [_vm._v("Banking Information")]),
                  _vm._v(" "),
                  _c(
                    "ul",
                    [
                      _vm._l(_vm.uwShownItem.accounts, function(subi, si) {
                        return [
                          _c("li", [
                            _c("label", [_vm._v("Account Name:")]),
                            _vm._v(" "),
                            subi.accountHolder
                              ? _c(
                                  "span",
                                  {
                                    class: {
                                      duplicate: _vm.uwShownItem.dupAccounts
                                    }
                                  },
                                  [_vm._v(_vm._s(subi.accountHolder))]
                                )
                              : _c("span", { staticClass: "empty" }, [
                                  _vm._v("NO")
                                ])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v("Account Nbr:")]),
                            _vm._v(" "),
                            subi.accountNumber
                              ? _c("span", [_vm._v(_vm._s(subi.accountNumber))])
                              : _c("span", { staticClass: "empty" }, [
                                  _vm._v("NO")
                                ])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v("Bank Name:")]),
                            _vm._v(" "),
                            subi.bankName
                              ? _c("span", [_vm._v(_vm._s(subi.bankName))])
                              : _c("span", { staticClass: "empty" }, [
                                  _vm._v("NO")
                                ])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v("Institution Nbr:")]),
                            _vm._v(" "),
                            subi.accountSwift
                              ? _c("span", [_vm._v(_vm._s(subi.accountSwift))])
                              : _c("span", { staticClass: "empty" }, [
                                  _vm._v("NO")
                                ])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v("Transit/Branch Nbr:")]),
                            _vm._v(" "),
                            subi.accountIBAN
                              ? _c("span", [_vm._v(_vm._s(subi.accountIBAN))])
                              : _c("span", { staticClass: "empty" }, [
                                  _vm._v("NO")
                                ])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v("ABA/Routing Nbr:")]),
                            _vm._v(" "),
                            subi.accountRouting
                              ? _c("span", [
                                  _vm._v(_vm._s(subi.accountRouting))
                                ])
                              : _c("span", { staticClass: "empty" }, [
                                  _vm._v("NO")
                                ])
                          ])
                        ]
                      })
                    ],
                    2
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "t2sect" }, [
                _c("div", { staticClass: "t2box" }, [
                  _c("h3", [_vm._v("Documents Information")]),
                  _vm._v(" "),
                  _c("ul", [
                    _vm._m(1),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Completed Sign-up Form:")]),
                      _vm._v(" "),
                      _vm.uwShownItem.completedForm
                        ? _c("span", [_vm._v("YES")])
                        : _c("span", { staticClass: "empty" }, [_vm._v("NO")])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Signed Sign-up Form:")]),
                      _vm._v(" "),
                      _vm.uwShownItem.signedFrom
                        ? _c("span", [_vm._v("YES")])
                        : _c("span", { staticClass: "empty" }, [_vm._v("NO")])
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "t2sect" }, [
                _c("div", { staticClass: "t2box" }, [
                  _c("h3", [_vm._v("System")]),
                  _vm._v(" "),
                  _c("ul", [
                    _vm._m(2),
                    _vm._v(" "),
                    _vm._m(3),
                    _vm._v(" "),
                    _vm._m(4),
                    _vm._v(" "),
                    _vm._m(5),
                    _vm._v(" "),
                    _vm.uwShownItem.dupCompanyName
                      ? _c(
                          "li",
                          [
                            _c("label", [_vm._v("Company Name: ")]),
                            _vm._v(" "),
                            _vm._l(
                              _vm.uwShownItem.dupCompanyName.split(","),
                              function(dupId) {
                                return [
                                  dupId[0] == "a"
                                    ? _c(
                                        "a",
                                        {
                                          attrs: {
                                            _target: "blank",
                                            href: "/applications/" + dupId
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.appIds[dupId.substring(1)] ||
                                                dupId.substring(1)
                                            )
                                          )
                                        ]
                                      )
                                    : _c(
                                        "a",
                                        {
                                          attrs: {
                                            _target: "blank",
                                            href:
                                              "/agents/" + dupId.substring(1)
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.userIds[dupId.substring(1)] ||
                                                dupId.substring(1)
                                            )
                                          )
                                        ]
                                      ),
                                  _vm._v(" \n              ")
                                ]
                              }
                            )
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.uwShownItem.dupCompanyNameTrade
                      ? _c(
                          "li",
                          [
                            _c("label", [_vm._v("Company Trade Name: ")]),
                            _vm._v(" "),
                            _vm._l(
                              _vm.uwShownItem.dupCompanyNameTrade.split(","),
                              function(dupId) {
                                return [
                                  dupId[0] == "a"
                                    ? _c(
                                        "a",
                                        {
                                          attrs: {
                                            _target: "blank",
                                            href:
                                              "/applications/" +
                                              dupId.substring(1)
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.appIds[dupId.substring(1)] ||
                                                dupId.substring(1)
                                            )
                                          )
                                        ]
                                      )
                                    : _c(
                                        "a",
                                        {
                                          attrs: {
                                            _target: "blank",
                                            href:
                                              "/agents/" + dupId.substring(1)
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.userIds[dupId.substring(1)] ||
                                                dupId.substring(1)
                                            )
                                          )
                                        ]
                                      ),
                                  _vm._v(" \n              ")
                                ]
                              }
                            )
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.uwShownItem.dupCompanyEmail
                      ? _c(
                          "li",
                          [
                            _c("label", [_vm._v("Company Email: ")]),
                            _vm._v(" "),
                            _vm._l(
                              _vm.uwShownItem.dupCompanyEmail.split(","),
                              function(dupId) {
                                return [
                                  dupId[0] == "a"
                                    ? _c(
                                        "a",
                                        {
                                          attrs: {
                                            _target: "blank",
                                            href: "/applications/" + dupId
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.appIds[dupId.substring(1)] ||
                                                dupId.substring(1)
                                            )
                                          )
                                        ]
                                      )
                                    : _c(
                                        "a",
                                        {
                                          attrs: {
                                            _target: "blank",
                                            href:
                                              "/agents/" + dupId.substring(1)
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.userIds[dupId.substring(1)] ||
                                                dupId.substring(1)
                                            )
                                          )
                                        ]
                                      ),
                                  _vm._v(" \n              ")
                                ]
                              }
                            )
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.uwShownItem.dupWebsite
                      ? _c(
                          "li",
                          [
                            _c("label", [_vm._v("Website: ")]),
                            _vm._v(" "),
                            _vm._l(
                              _vm.uwShownItem.dupWebsite.split(","),
                              function(dupId) {
                                return [
                                  dupId[0] == "a"
                                    ? _c(
                                        "a",
                                        {
                                          attrs: {
                                            _target: "blank",
                                            href:
                                              "/applications/" +
                                              dupId.substring(1)
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.appIds[dupId.substring(1)] ||
                                                dupId.substring(1)
                                            )
                                          )
                                        ]
                                      )
                                    : _c(
                                        "a",
                                        {
                                          attrs: {
                                            _target: "blank",
                                            href:
                                              "/agents/" + dupId.substring(1)
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.userIds[dupId.substring(1)] ||
                                                dupId.substring(1)
                                            )
                                          )
                                        ]
                                      ),
                                  _vm._v(" \n              ")
                                ]
                              }
                            )
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.uwShownItem.dupAddress
                      ? _c(
                          "li",
                          [
                            _c("label", [_vm._v("Address: ")]),
                            _vm._v(" "),
                            _vm._l(
                              _vm.uwShownItem.dupAddress.split(","),
                              function(dupId) {
                                return [
                                  dupId[0] == "a"
                                    ? _c(
                                        "a",
                                        {
                                          attrs: {
                                            _target: "blank",
                                            href:
                                              "/applications/" +
                                              dupId.substring(1)
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.appIds[dupId.substring(1)] ||
                                                dupId.substring(1)
                                            )
                                          )
                                        ]
                                      )
                                    : _c(
                                        "a",
                                        {
                                          attrs: {
                                            _target: "blank",
                                            href:
                                              "/agents/" + dupId.substring(1)
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.userIds[dupId.substring(1)] ||
                                                dupId.substring(1)
                                            )
                                          )
                                        ]
                                      ),
                                  _vm._v(" \n              ")
                                ]
                              }
                            )
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.uwShownItem.dupAccounts
                      ? _c(
                          "li",
                          [
                            _c("label", [_vm._v("Bank Account:")]),
                            _vm._v(" "),
                            _vm._l(_vm.uwShownItem.dupAccounts, function(
                              dupId
                            ) {
                              return [
                                dupId[2] == "a"
                                  ? _c(
                                      "a",
                                      {
                                        attrs: {
                                          _target: "blank",
                                          href: "/applications/" + dupId[1]
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.appIds[dupId[1]] || dupId[1]
                                          )
                                        )
                                      ]
                                    )
                                  : _c(
                                      "a",
                                      {
                                        attrs: {
                                          _target: "blank",
                                          href: "/agents/" + dupId[1]
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.appIds[dupId[1]] || dupId[1]
                                          )
                                        )
                                      ]
                                    ),
                                _vm._v(" \n              ")
                              ]
                            })
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.uwShownItem.dupContacts
                      ? _c(
                          "li",
                          [
                            _vm._v("\n              Contacts:\n              "),
                            _vm._l(_vm.uwShownItem.dupContacts, function(
                              dupId
                            ) {
                              return [
                                dupId[2] == "a"
                                  ? _c(
                                      "a",
                                      {
                                        attrs: {
                                          _target: "blank",
                                          href: "/applications/" + dupId[1]
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.appIds[dupId[1]] || dupId[1]
                                          )
                                        )
                                      ]
                                    )
                                  : _c(
                                      "a",
                                      {
                                        attrs: {
                                          _target: "blank",
                                          href: "/agents/" + dupId[1]
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.appIds[dupId[1]] || dupId[1]
                                          )
                                        )
                                      ]
                                    ),
                                _vm._v(" \n              ")
                              ]
                            })
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("li"),
                    _vm._v(" "),
                    _c("li")
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "t2sect" }, [
                _c("div", { staticClass: "t2box" }, [
                  _c("h3", [_vm._v("Pre-Check Score Report")]),
                  _vm._v(" "),
                  _c("ul", [
                    _c("li", [
                      _c("label", [_vm._v("=")]),
                      _vm._v(
                        " " + _vm._s(_vm.validItems) + " / 35\n            "
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Negative List Report:")]),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(0))])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Duplicate List Report:")]),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(_vm.dupCount))])
                    ])
                  ])
                ])
              ])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("portal", { attrs: { to: "actions" } }, [
        _c("ul", [
          _c("li", [
            !_vm.uwShown
              ? _c(
                  "a",
                  {
                    attrs: { href: "" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.newPreCheck()
                      }
                    }
                  },
                  [_vm._v("New")]
                )
              : _c(
                  "a",
                  {
                    attrs: { href: "" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        _vm.uwShown = null
                      }
                    }
                  },
                  [_vm._v("Back")]
                )
          ]),
          _vm._v(" "),
          !_vm.uwShown && _vm.uwSelected
            ? _c("li", [
                _c(
                  "a",
                  {
                    attrs: { href: "" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.doShowItems(_vm.uwSelected)
                      }
                    }
                  },
                  [_vm._v("View")]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.uwShown
            ? _c("li", [
                _c("a", { attrs: { href: _vm.printUrl, target: "_blank" } }, [
                  _vm._v("Print")
                ])
              ])
            : _vm._e()
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticStyle: { width: "1%" } }),
        _vm._v(" "),
        _c("th", [_vm._v("ID")]),
        _vm._v(" "),
        _c("th", [_vm._v("Legal Name")]),
        _vm._v(" "),
        _c("th", [_vm._v("Date")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("label", [_vm._v("Signed NDA:")]),
      _vm._v(" "),
      _c("span", [_vm._v("NO")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("label", [_vm._v("CRISTINE Score:")]),
      _vm._v(" "),
      _c("span", [_vm._v("YES")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("label", [_vm._v("Website Check Complete:")]),
      _vm._v(" "),
      _c("span", [_vm._v("NO")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("label", [_vm._v("Compliance Review Days:")]),
      _vm._v(" "),
      _c("span", [_vm._v("365")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [_c("label", [_vm._v("Duplicates:")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }