var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "panel",
        {
          attrs: { title: "Task List" },
          scopedSlots: _vm._u([
            {
              key: "actions",
              fn: function() {
                return [
                  !_vm.showAddNewTask
                    ? _c(
                        "ch-button",
                        {
                          on: {
                            click: function($event) {
                              _vm.showAddNewTask = true
                            }
                          }
                        },
                        [_vm._v("Add New Task")]
                      )
                    : [
                        _c(
                          "ch-button",
                          {
                            attrs: { color: "gray" },
                            on: {
                              click: function($event) {
                                _vm.showAddNewTask = false
                              }
                            }
                          },
                          [_vm._v("Cancel")]
                        ),
                        _vm._v(" "),
                        _c(
                          "ch-button",
                          {
                            on: {
                              click: function($event) {
                                return _vm.$refs.addTask.save()
                              }
                            }
                          },
                          [_vm._v("Save New Task")]
                        )
                      ]
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { name: "slide" } },
            [
              _c("add-task", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showAddNewTask,
                    expression: "showAddNewTask"
                  }
                ],
                ref: "addTask",
                attrs: { type: _vm.type },
                on: {
                  save: function($event) {
                    return _vm.onSave($event)
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          !_vm.tasks
            ? _c("span", [_vm._v("Loading...")])
            : _vm.tasks && _vm.tasks.length
            ? _c(
                "ul",
                { staticClass: "list" },
                _vm._l(_vm.tasks, function(task, index) {
                  return _c("li", { staticClass: "list-item" }, [
                    _c(
                      "div",
                      { staticClass: "display-inline mr-10" },
                      [
                        task.done == 0
                          ? _c(
                              "ch-button",
                              {
                                attrs: { size: "sm", color: "success" },
                                on: {
                                  click: function($event) {
                                    return _vm.markAsDone(task.id, index)
                                  }
                                }
                              },
                              [_vm._v("✓")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !(_vm.isScheduler && task.createdBy.id !== _vm.userId)
                          ? _c(
                              "ch-button",
                              {
                                attrs: { size: "sm", color: "danger" },
                                on: {
                                  click: function($event) {
                                    return _vm.remove(task, index)
                                  }
                                }
                              },
                              [_vm._v("✖")]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      { class: { "striked-text": task.done == 1 } },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.formatDate(task.date)) +
                            "\n          "
                        ),
                        task.employees.length
                          ? _c("a", { staticClass: "link" }, [
                              _vm._v(
                                " - created by " +
                                  _vm._s(task.createdBy.username)
                              )
                            ])
                          : _vm._e(),
                        _vm._v(":\n          "),
                        _c("b", [_vm._v(_vm._s(task.text))]),
                        _c("br"),
                        _vm._v(" "),
                        task.employees.length
                          ? [
                              _vm._v(
                                "\n            Associated Employees:\n            "
                              ),
                              _c(
                                "ul",
                                { staticClass: "tags small" },
                                _vm._l(task.employees, function(item) {
                                  return _c("li", [
                                    _vm._v(
                                      _vm._s(item.employee_id.first_name) +
                                        " " +
                                        _vm._s(item.employee_id.last_name) +
                                        "\n                "
                                    ),
                                    task.createdBy.id === _vm.userId ||
                                    item.employee_id.userId.id === _vm.userId
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "close",
                                            on: {
                                              click: function($event) {
                                                return _vm.removeEmployee(
                                                  task,
                                                  item
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("x")]
                                        )
                                      : _vm._e()
                                  ])
                                }),
                                0
                              )
                            ]
                          : _vm._e()
                      ],
                      2
                    )
                  ])
                }),
                0
              )
            : _c("span", [_vm._v("There are no tasks.")])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }