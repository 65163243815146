var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", [
        false ? _c("div", { staticClass: "action-sub-buttons" }) : _vm._e(),
        _vm._v(" "),
        _c("table", { staticClass: "file-list content-table" }, [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "tbody",
            [
              _c("tr", [
                _c("td", [_vm._v(_vm._s(_vm.item.agentDb.name))]),
                _vm._v(" "),
                _c("td", [_vm._v("ERROR")])
              ]),
              _vm._v(" "),
              _vm._l(_vm.item.contacts, function(ubo, ui) {
                return _c("tr", [
                  _c("td", [
                    _vm._v(_vm._s(ubo.firstName) + " " + _vm._s(ubo.lastName))
                  ]),
                  _vm._v(" "),
                  _c("td", [_vm._v("ERROR")])
                ])
              })
            ],
            2
          )
        ]),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("table", { staticClass: "file-list content-table" }, [
          _vm._m(1),
          _vm._v(" "),
          _c(
            "tbody",
            _vm._l(_vm.itemList, function(uwItem, uwI) {
              return _c("tr", [
                _c("td", [_vm._v(_vm._s(_vm.itemList.length - uwI))]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    _vm._s(
                      _vm.formatDate(uwItem.createdDate, "YYYY-MM-DD HH:mm")
                    )
                  )
                ]),
                _vm._v(" "),
                _c("td", [_vm._v("Acuant")]),
                _vm._v(" "),
                uwItem.type == "g2"
                  ? _c("td", [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: _vm.printUrlForItem(uwItem),
                            target: "_blank"
                          }
                        },
                        [_vm._v(_vm._s(_vm.docNameForItem(uwItem)))]
                      )
                    ])
                  : _c("td"),
                _vm._v(" "),
                uwItem.type == "g2"
                  ? _c("td", [
                      _vm._v(
                        _vm._s(
                          uwItem.response.complete ? "Completed" : "Pending"
                        )
                      )
                    ])
                  : _c(
                      "td",
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm.item.companyName + " " + _vm.item.companyType
                            ) +
                            " |\n                " +
                            _vm._s(
                              (uwItem.response && uwItem.response.res) ||
                                "ERROR"
                            ) +
                            "\n                "
                        ),
                        _vm._l(uwItem.response.people || [], function(person) {
                          return [
                            _c("br"),
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.dbPerson(person.personId)) +
                                " |\n                  " +
                                _vm._s(person.res || "ERROR") +
                                "\n                "
                            )
                          ]
                        })
                      ],
                      2
                    )
              ])
            }),
            0
          )
        ])
      ]),
      _vm._v(" "),
      true
        ? _c("portal", { attrs: { to: "actions" } }, [
            _c("ul", [
              _c("li", [
                _c(
                  "a",
                  {
                    attrs: { href: "" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.newCheck.apply(null, arguments)
                      }
                    }
                  },
                  [_vm._v("Run New")]
                )
              ]),
              _vm._v(" "),
              false && _vm.itemList && _vm.itemList.length
                ? _c("li", [
                    _c(
                      "a",
                      {
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.refresh(_vm.itemList[0])
                          }
                        }
                      },
                      [_vm._v("Refresh")]
                    )
                  ])
                : _vm._e()
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Entity")]),
        _vm._v(" "),
        _c("th", [_vm._v("Status")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("ID")]),
        _vm._v(" "),
        _c("th", [_vm._v("Date")]),
        _vm._v(" "),
        _c("th", [_vm._v("Type")]),
        _vm._v(" "),
        _c("th", [_vm._v("Filename")]),
        _vm._v(" "),
        _c("th", [_vm._v("Status")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }