var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.uwShown
      ? _c("div", { staticClass: "action-sub-buttons" }, [
          _c(
            "a",
            {
              staticClass: "btn",
              attrs: { disabled: _vm.item.agentDb.status != "A04" },
              on: { click: _vm.newFinalCheck }
            },
            [_vm._v("Start")]
          ),
          _vm._v(" "),
          _vm.uwSelected
            ? _c(
                "a",
                {
                  staticClass: "btn",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.doShowItems(_vm.uwSelected)
                    }
                  }
                },
                [_vm._v("View")]
              )
            : _vm._e()
        ])
      : _c("div", { staticClass: "action-sub-buttons" }),
    _vm._v(" "),
    false && !_vm.uwShown
      ? _c("table", { staticClass: "file-list content-table" }, [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "tbody",
            _vm._l(_vm.uwList, function(uwItem, uwI) {
              return _c("tr", [
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.uwSelected,
                        expression: "uwSelected"
                      }
                    ],
                    attrs: { type: "radio" },
                    domProps: {
                      value: uwItem.id,
                      checked: _vm._q(_vm.uwSelected, uwItem.id)
                    },
                    on: {
                      change: function($event) {
                        _vm.uwSelected = uwItem.id
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(_vm.uwList.length - uwI))]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    _vm._s(_vm.formatDate(uwItem.created, "YYYY-MM-DD HH:mm"))
                  )
                ])
              ])
            }),
            0
          )
        ])
      : _vm.uwShown
      ? _c(
          "div",
          { ref: "detailsBox", staticClass: "pre-check-details" },
          [
            _c("div", { staticClass: "t2precheck" }, [
              _c("div", { staticClass: "t2sect" }, [
                _c("div", { staticClass: "t2box" }, [
                  _c("h3", [_vm._v("General Information")]),
                  _vm._v(" "),
                  _c("ul", [
                    _c("li", [
                      _c(
                        "div",
                        {
                          staticClass: "field",
                          class: { visible: _vm.shownItem > 4 }
                        },
                        [
                          _c("label", [_vm._v("Solution:")]),
                          _vm._v(" "),
                          _vm.item.agentDb.solutions
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.findSolution(_vm.item.agentDb.solutions)
                                  )
                                )
                              ])
                            : _c("span", { staticClass: "empty" }, [
                                _vm._v("NO")
                              ])
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Status:")]),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(_vm.item.agentDb.status))])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Type:")]),
                      _vm._v(" "),
                      _vm.item.groups
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.groups[_vm.item.groups]))
                          ])
                        : _c("span", { staticClass: "empty" }, [_vm._v("NO")])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Tier:")]),
                      _vm._v(" "),
                      _vm.item.agentDb.agentClass
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.item.agentDb.agentClass))
                          ])
                        : _c("span", { staticClass: "empty" }, [_vm._v("NO")])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Relationship Manager:")]),
                      _vm._v(" "),
                      _vm.uwShownItem.manager
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.uwShownItem.manager.fullName))
                          ])
                        : _c("span", { staticClass: "empty" }, [_vm._v("NO")])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Referral Source:")]),
                      _vm._v(" "),
                      _vm.item.agentDb.referrerSite
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.item.agentDb.referrerSite))
                          ])
                        : _c("span", { staticClass: "empty" }, [_vm._v("NO")])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Langauges:")]),
                      _vm._v(" "),
                      _vm.uwShownItem.language
                        ? _c("span", [_vm._v(_vm._s(_vm.uwShownItem.language))])
                        : _c("span", { staticClass: "empty" }, [_vm._v("NO")])
                    ]),
                    _vm._v(" "),
                    false
                      ? _c("li", [
                          _c("label", [_vm._v("Sales Brand:")]),
                          _vm._v(" "),
                          _vm.item.agentDb.salesBrand
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.salesBrandForId(
                                      _vm.item.agentDb.salesBrand
                                    ).dba
                                  )
                                )
                              ])
                            : _c("span", { staticClass: "empty" }, [
                                _vm._v("NO")
                              ])
                        ])
                      : _vm._e()
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "t2sect" }, [
                _c("div", { staticClass: "t2box" }, [
                  _c("h3", [_vm._v("Company Information")]),
                  _vm._v(" "),
                  _c("ul", [
                    _c("li", [
                      _c("label", [_vm._v("Legal Name:")]),
                      _vm._v(" "),
                      _vm.item.agentDb.name
                        ? _c("span", [
                            _vm._v(
                              _vm._s(_vm.item.agentDb.name) +
                                " " +
                                _vm._s(_vm.item.agentDb.companyType)
                            )
                          ])
                        : _c("span", { staticClass: "empty" }, [_vm._v("NO")])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("DBA:")]),
                      _vm._v(" "),
                      _vm.item.agentDb.nameTrade
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.item.agentDb.nameTrade))
                          ])
                        : _c("span", { staticClass: "empty" }, [_vm._v("NO")])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Address:")]),
                      _vm._v(" "),
                      _vm.item.agentDb.address
                        ? _c("span", [_vm._v(_vm._s(_vm.item.agentDb.address))])
                        : _c("span", { staticClass: "empty" }, [_vm._v("NO")])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Country:")]),
                      _vm._v(" "),
                      _vm.item.agentDb.country
                        ? _c("span", [_vm._v(_vm._s(_vm.item.agentDb.country))])
                        : _c("span", { staticClass: "empty" }, [_vm._v("NO")])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Email:")]),
                      _vm._v(" "),
                      _vm.item.agentDb.email
                        ? _c("span", [_vm._v(_vm._s(_vm.item.agentDb.email))])
                        : _c("span", { staticClass: "empty" }, [_vm._v("NO")])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Phone:")]),
                      _vm._v(" "),
                      _vm.item.agentDb.phone
                        ? _c("span", [_vm._v(_vm._s(_vm.item.agentDb.phone))])
                        : _c("span", { staticClass: "empty" }, [_vm._v("NO")])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Website:")]),
                      _vm._v(" "),
                      _vm.item.agentDb.website
                        ? _c("span", [_vm._v(_vm._s(_vm.item.agentDb.website))])
                        : _c("span", { staticClass: "empty" }, [_vm._v("NO")])
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "t2sect" }, [
                _c("div", { staticClass: "t2box" }, [
                  _c("h3", [_vm._v("Principals Information")]),
                  _vm._v(" "),
                  _c(
                    "ul",
                    [
                      _c("li", [
                        _c("label", [_vm._v("Number of contacts:")]),
                        _vm._v(" "),
                        _vm.item.contacts
                          ? _c("span", [
                              _vm._v(_vm._s(_vm.item.contacts.length))
                            ])
                          : _c("span", { staticClass: "empty" }, [_vm._v("NO")])
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.item.contacts, function(contact, ci) {
                        return [
                          _c("li", [
                            _c("label", [
                              _vm._v(
                                "Contact " + _vm._s(ci + 1) + " First Name"
                              )
                            ]),
                            _vm._v(" "),
                            contact.firstName
                              ? _c("span", [_vm._v(_vm._s(contact.firstName))])
                              : _c("span", { staticClass: "empty" }, [
                                  _vm._v("NO")
                                ])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [
                              _vm._v("Contact " + _vm._s(ci + 1) + " Last Name")
                            ]),
                            _vm._v(" "),
                            contact.lastName
                              ? _c("span", [_vm._v(_vm._s(contact.lastName))])
                              : _c("span", { staticClass: "empty" }, [
                                  _vm._v("NO")
                                ])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [
                              _vm._v("Contact " + _vm._s(ci + 1) + " Email")
                            ]),
                            _vm._v(" "),
                            contact.email
                              ? _c("span", [_vm._v(_vm._s(contact.email))])
                              : _c("span", { staticClass: "empty" }, [
                                  _vm._v("NO")
                                ])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [
                              _vm._v("Contact " + _vm._s(ci + 1) + " Phone")
                            ]),
                            _vm._v(" "),
                            contact.phone
                              ? _c("span", [_vm._v(_vm._s(contact.phone))])
                              : _c("span", { staticClass: "empty" }, [
                                  _vm._v("NO")
                                ])
                          ])
                        ]
                      })
                    ],
                    2
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "t2sect" }, [
                _c("div", { staticClass: "t2box" }, [
                  _c("h3", [_vm._v("Documents Information")]),
                  _vm._v(" "),
                  _c("ul", [
                    _c("li", [
                      _c("label", [_vm._v("Completed Agreement:")]),
                      _vm._v(" "),
                      _vm.uwShownItem.agreement
                        ? _c("span", [_vm._v("YES")])
                        : _c("span", { staticClass: "empty" }, [_vm._v("NO")])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Signed Agreement:")]),
                      _vm._v(" "),
                      _vm.uwShownItem.signedAgr
                        ? _c("span", [_vm._v("YES")])
                        : _c("span", { staticClass: "empty" }, [_vm._v("NO")])
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "t2sect" }, [
                _c("div", { staticClass: "t2box" }, [
                  _c("h3", [_vm._v("Banking Information")]),
                  _vm._v(" "),
                  _c(
                    "ul",
                    [
                      _vm._l(_vm.item.agentDb.accounts, function(subi, si) {
                        return [
                          _c("li", [
                            _c("label", [_vm._v("Account Name:")]),
                            _vm._v(" "),
                            subi.accountHolder
                              ? _c("span", [_vm._v(_vm._s(subi.accountHolder))])
                              : _c("span", { staticClass: "empty" }, [
                                  _vm._v("NO")
                                ])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v("Account Nbr:")]),
                            _vm._v(" "),
                            subi.accountNumber
                              ? _c("span", [_vm._v(_vm._s(subi.accountNumber))])
                              : _c("span", { staticClass: "empty" }, [
                                  _vm._v("NO")
                                ])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v("Bank Name:")]),
                            _vm._v(" "),
                            subi.bankName
                              ? _c("span", [_vm._v(_vm._s(subi.bankName))])
                              : _c("span", { staticClass: "empty" }, [
                                  _vm._v("NO")
                                ])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v("Institution Nbr:")]),
                            _vm._v(" "),
                            subi.accountSwift
                              ? _c("span", [_vm._v(_vm._s(subi.accountSwift))])
                              : _c("span", { staticClass: "empty" }, [
                                  _vm._v("NO")
                                ])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v("Transit/Branch Nbr:")]),
                            _vm._v(" "),
                            subi.accountIBAN
                              ? _c("span", [_vm._v(_vm._s(subi.accountIBAN))])
                              : _c("span", { staticClass: "empty" }, [
                                  _vm._v("NO")
                                ])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v("ABA/Routing Nbr:")]),
                            _vm._v(" "),
                            subi.accountRouting
                              ? _c("span", [
                                  _vm._v(_vm._s(subi.accountRouting))
                                ])
                              : _c("span", { staticClass: "empty" }, [
                                  _vm._v("NO")
                                ])
                          ])
                        ]
                      })
                    ],
                    2
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "t2sect" }, [
                _c("div", { staticClass: "t2box" }, [
                  _c("h3", [_vm._v("System")]),
                  _vm._v(" "),
                  _c("ul", [
                    _vm._m(1),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Website Check Complete:")]),
                      _vm._v(" "),
                      _vm.uwShownItem.webCheck == 1
                        ? _c("span", [_vm._v("YES")])
                        : _c("span", {}, [_vm._v("NO")])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Compliance Review Days:")]),
                      _vm._v(" "),
                      _vm.uwShownItem.reviewDays
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.uwShownItem.reviewDays))
                          ])
                        : _c("span", { staticClass: "empty" }, [_vm._v("NO")])
                    ]),
                    _vm._v(" "),
                    _vm._m(2),
                    _vm._v(" "),
                    _c("li"),
                    _vm._v(" "),
                    _c("li")
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _vm.animFinished
              ? [
                  _vm.passed
                    ? _c("div", { staticClass: "status-success" }, [
                        _vm._v(
                          "\n            Approved! Pending final aproval.\n          "
                        )
                      ])
                    : _c("div", { staticClass: "status-error" }, [
                        _vm._v("\n            Not approved\n          ")
                      ])
                ]
              : _vm._e()
          ],
          2
        )
      : _vm.uwShown
      ? _c(
          "div",
          { staticClass: "pre-check-details" },
          [
            _c("div", { staticClass: "t2col toAnimate" }, [
              _c(
                "div",
                { staticStyle: { width: "100%" } },
                _vm._l(_vm.fieldIds, function(field, index) {
                  return _c(
                    "div",
                    {
                      staticClass: "field",
                      class: { visible: _vm.shownItem > index }
                    },
                    [
                      _c("label", [_vm._v(_vm._s(_vm.fields[field][0]) + ":")]),
                      _vm._v(" "),
                      _vm.uwShownItem[field] || _vm.fields[field][1] == "app"
                        ? _c("span", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.showField(_vm.uwShownItem, field)
                              )
                            }
                          })
                        : _c("span", { staticClass: "empty" }, [_vm._v("NO")])
                    ]
                  )
                }),
                0
              )
            ]),
            _vm._v(" "),
            _vm.animFinished
              ? [
                  _vm.passed
                    ? _c("div", { staticClass: "status-success" }, [
                        _vm._v(
                          "\n            Approved! Pending final aproval.\n          "
                        )
                      ])
                    : _c("div", { staticClass: "status-error" }, [
                        _vm._v("\n            Not approved\n          ")
                      ])
                ]
              : _vm._e()
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "popup", class: { shown: _vm.finalPopup } }, [
      _c("div", { staticClass: "pop-med" }, [
        _c(
          "div",
          {
            staticClass: "pop-inner",
            staticStyle: {
              "max-width": "500px",
              "margin-left": "auto",
              "margin-right": "auto"
            }
          },
          [
            _c("h2", { staticStyle: { "text-align": "center" } }, [
              _vm._v("Implementation Settings")
            ]),
            _vm._v(" "),
            _c(
              "ul",
              {
                staticClass: "column-form",
                staticStyle: { display: "flex", "flex-wrap": "wrap" }
              },
              [
                _c(
                  "li",
                  { staticStyle: { width: "50%", "margin-bottom": "10px" } },
                  [
                    _c("label", [_vm._v("Compliance review days")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "field" },
                      [
                        _c(
                          "validator",
                          { attrs: { value: 0, reqs: "" } },
                          [
                            _c("dyn-select", {
                              staticStyle: { width: "75%" },
                              attrs: { options: _vm.reviewDaysOpts },
                              model: {
                                value: _vm.item.agentDb.reviewDays,
                                callback: function($$v) {
                                  _vm.$set(_vm.item.agentDb, "reviewDays", $$v)
                                },
                                expression: "item.agentDb.reviewDays"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "li",
                  { staticStyle: { width: "50%", "margin-bottom": "10px" } },
                  [
                    _c("label", [_vm._v("BIN/ICA Rental Fee:")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "field" },
                      [
                        _c(
                          "validator",
                          { attrs: { value: 0, reqs: "" } },
                          [
                            _c("formel-money-amount4", {
                              staticStyle: { width: "75%" },
                              attrs: { suffix: "%" },
                              model: {
                                value: _vm.item.agentDb.bicRentalFee,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.item.agentDb,
                                    "bicRentalFee",
                                    $$v
                                  )
                                },
                                expression: "item.agentDb.bicRentalFee"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "li",
                  { staticStyle: { width: "50%", "margin-bottom": "10px" } },
                  [
                    _c("label", [_vm._v("Partner Setup Fee:")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "field" },
                      [
                        _c(
                          "validator",
                          { attrs: { value: 0, reqs: "" } },
                          [
                            _c("formel-money-amount4", {
                              staticStyle: { width: "75%" },
                              attrs: { prefix: "$" },
                              model: {
                                value: _vm.item.agentDb.setupFee,
                                callback: function($$v) {
                                  _vm.$set(_vm.item.agentDb, "setupFee", $$v)
                                },
                                expression: "item.agentDb.setupFee"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "li",
                  { staticStyle: { width: "50%", "margin-bottom": "10px" } },
                  [
                    _c("label", [_vm._v("Monthly Minimum:")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "field" },
                      [
                        _c(
                          "validator",
                          { attrs: { value: 0, reqs: "" } },
                          [
                            _c("formel-money-amount4", {
                              staticStyle: { width: "75%" },
                              attrs: { prefix: "$" },
                              model: {
                                value: _vm.item.agentDb.monthlyMinimum,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.item.agentDb,
                                    "monthlyMinimum",
                                    $$v
                                  )
                                },
                                expression: "item.agentDb.monthlyMinimum"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c("h2", { staticStyle: { "text-align": "center" } }, [
              _vm._v("Implementation Message")
            ]),
            _vm._v(" "),
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.implementationMessage,
                  expression: "implementationMessage"
                }
              ],
              staticStyle: {
                border: "1px solid #ccc",
                "border-radius": "4px",
                width: "100%",
                "box-sizing": "border-box",
                height: "100px",
                padding: "6px",
                "margin-bottom": "20px",
                "font-size": "14px"
              },
              domProps: { value: _vm.implementationMessage },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.implementationMessage = $event.target.value
                }
              }
            }),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-large",
                staticStyle: {
                  "margin-left": "auto",
                  "margin-right": "auto",
                  display: "block",
                  width: "330px"
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.doFinal2.apply(null, arguments)
                  }
                }
              },
              [_vm._v("Submit")]
            )
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticStyle: { width: "1%" } }),
        _vm._v(" "),
        _c("th", [_vm._v("ID")]),
        _vm._v(" "),
        _c("th", [_vm._v("Date")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("label", [_vm._v("CRISTINE Score:")]),
      _vm._v(" "),
      _c("span", [_vm._v("YES")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [_c("label", [_vm._v("Duplicates:")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }