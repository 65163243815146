var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.loading
      ? _c("ul", { staticClass: "appview-list" }, [
          !_vm.uwList.length
            ? _c("li", [_vm._v("\n      Pending\n    ")])
            : _c("li", [_vm._v("\n      Profile set\n    ")])
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.loading && !_vm.uwList.length
      ? _c("div", { staticClass: "actions" }, [
          _c(
            "button",
            {
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.doSet.apply(null, arguments)
                }
              }
            },
            [_vm._v("Set")]
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.uwList.length
      ? _c("div", { staticClass: "actions" }, [
          _c(
            "button",
            {
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.doView.apply(null, arguments)
                }
              }
            },
            [_vm._v("View")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.doSet.apply(null, arguments)
                }
              }
            },
            [_vm._v("Reset")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.doNext.apply(null, arguments)
                }
              }
            },
            [_vm._v("Next")]
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }