var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.shown,
          expression: "shown"
        }
      ],
      staticClass: "form-popup"
    },
    [
      _c("div", [
        _c(
          "div",
          { staticClass: "ncontent appf appnat" },
          [
            _c(
              "a",
              {
                staticClass: "close",
                attrs: { href: "" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.dismiss.apply(null, arguments)
                  }
                }
              },
              [_vm._v("×")]
            ),
            _vm._v(" "),
            _c("form-progress", {
              attrs: {
                step: _vm.step,
                steps: [
                  "General",
                  "Company Info",
                  "Banking",
                  "Pricing Schedule A",
                  "Equipment"
                ]
              }
            }),
            _vm._v(" "),
            _c("h3", [_vm._v("New Agreement")]),
            _vm._v(" "),
            _vm.step == 0
              ? _c("form", { key: "step1", staticClass: "form-step" }, [
                  _c("section", [
                    _c("h2", [_vm._v("General")]),
                    _vm._v(" "),
                    _c("ul", { staticClass: "column-form" }, [
                      _c("li", [
                        _c("label", [_vm._v("Solution")]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "field" },
                          [
                            _c("validator", { attrs: { value: 0, reqs: "" } }, [
                              _c("input", {
                                attrs: { type: "text", readonly: "" },
                                domProps: {
                                  value: _vm.solution(_vm.appl.solution)
                                }
                              })
                            ])
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("label", [_vm._v("Application ID")]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "field" },
                          [
                            _c("validator", { attrs: { value: 0, reqs: "" } }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.appl.applicationId,
                                    expression: "appl.applicationId"
                                  }
                                ],
                                attrs: { type: "text", readonly: "" },
                                domProps: { value: _vm.appl.applicationId },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.appl,
                                      "applicationId",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ])
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("label", [_vm._v("Account ID")]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "field" },
                          [
                            _c("validator", { attrs: { value: 0, reqs: "" } }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.appl.accountId,
                                    expression: "appl.accountId"
                                  }
                                ],
                                attrs: { type: "text", readonly: "" },
                                domProps: { value: _vm.appl.accountId },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.appl,
                                      "accountId",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ])
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("label", [_vm._v("Account Class")]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "field" },
                          [
                            _c(
                              "validator",
                              {
                                attrs: {
                                  value: _vm.item.accClass,
                                  reqs: "required"
                                }
                              },
                              [
                                _c("dyn-select", {
                                  attrs: { options: _vm.accountClassesList },
                                  model: {
                                    value: _vm.item.accClass,
                                    callback: function($$v) {
                                      _vm.$set(_vm.item, "accClass", $$v)
                                    },
                                    expression: "item.accClass"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("label", [_vm._v("Account Manager")]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "field" },
                          [
                            _c(
                              "validator",
                              {
                                attrs: { value: _vm.item.managerId, reqs: "" }
                              },
                              [
                                _c("dyn-select", {
                                  attrs: { options: _vm.managers },
                                  model: {
                                    value: _vm.item.managerId,
                                    callback: function($$v) {
                                      _vm.$set(_vm.item, "managerId", $$v)
                                    },
                                    expression: "item.managerId"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("label", [_vm._v("Partner")]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "field" },
                          [
                            _c("validator", { attrs: { value: 0, reqs: "" } }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.appl.source &&
                                      _vm.appl.source.agentDb &&
                                      _vm.appl.source.agentDb.name,
                                    expression:
                                      "appl.source && appl.source.agentDb && appl.source.agentDb.name"
                                  }
                                ],
                                attrs: { type: "text", readonly: "" },
                                domProps: {
                                  value:
                                    _vm.appl.source &&
                                    _vm.appl.source.agentDb &&
                                    _vm.appl.source.agentDb.name
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.appl.source &&
                                        _vm.appl.source.agentDb &&
                                        _vm.appl.source.agentDb,
                                      "name",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ])
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("label", [_vm._v("Industry")]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "validator" },
                          [
                            _c(
                              "validator",
                              {
                                attrs: {
                                  value: _vm.item.industry,
                                  reqs: "required"
                                }
                              },
                              [
                                _c("dyn-select", {
                                  attrs: { options: _vm.industries },
                                  model: {
                                    value: _vm.item.industry,
                                    callback: function($$v) {
                                      _vm.$set(_vm.item, "industry", $$v)
                                    },
                                    expression: "item.industry"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("label", [_vm._v("MCC")]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "field" },
                          [
                            _c(
                              "validator",
                              {
                                attrs: { value: _vm.item.mcc, reqs: "required" }
                              },
                              [
                                _c("mcc-edit", {
                                  model: {
                                    value: _vm.item.mcc,
                                    callback: function($$v) {
                                      _vm.$set(_vm.item, "mcc", $$v)
                                    },
                                    expression: "item.mcc"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      false
                        ? _c("li", [
                            _c("label", [_vm._v("Plan")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field" },
                              [
                                _c(
                                  "validator",
                                  {
                                    attrs: {
                                      value: _vm.item.plan,
                                      reqs: "required"
                                    }
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        class: {
                                          checked: _vm.item.plan == "Lite"
                                        }
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.item.plan,
                                              expression: "item.plan"
                                            }
                                          ],
                                          attrs: {
                                            type: "radio",
                                            name: "plan",
                                            value: "Lite"
                                          },
                                          domProps: {
                                            checked: _vm._q(
                                              _vm.item.plan,
                                              "Lite"
                                            )
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.$set(
                                                _vm.item,
                                                "plan",
                                                "Lite"
                                              )
                                            }
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("span"),
                                        _vm._v("Lite\n            ")
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      {
                                        class: {
                                          checked: _vm.item.plan == "Full"
                                        }
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.item.plan,
                                              expression: "item.plan"
                                            }
                                          ],
                                          attrs: {
                                            type: "radio",
                                            name: "plan",
                                            value: "Full"
                                          },
                                          domProps: {
                                            checked: _vm._q(
                                              _vm.item.plan,
                                              "Full"
                                            )
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.$set(
                                                _vm.item,
                                                "plan",
                                                "Full"
                                              )
                                            }
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("span"),
                                        _vm._v("Full\n            ")
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ])
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _vm.internalNotes && _vm.internalNotes.agrGeneral
                      ? _c("div", { staticClass: "notes important" }, [
                          _vm._v(
                            "Note: " +
                              _vm._s(_vm.internalNotes.agrGeneral) +
                              " "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "buttons" }, [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-large btn-plain btn-back",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                          }
                        }
                      },
                      [_vm._v("Back")]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-large btn-success btn-next",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.nextStep.apply(null, arguments)
                          }
                        }
                      },
                      [_vm._v("Next")]
                    )
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.step == 1
              ? _c("form", { key: "step2", staticClass: "form-step" }, [
                  _c("section", [
                    _c("h2", [_vm._v("Company Info")]),
                    _vm._v(" "),
                    _c(
                      "ul",
                      { staticClass: "column-form" },
                      [
                        _c("li", [
                          _c("label", [_vm._v("Legal Business Name")]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "field" },
                            [
                              _c(
                                "validator",
                                { attrs: { value: 0, reqs: "" } },
                                [
                                  _c("input", {
                                    attrs: { type: "text", readonly: "" },
                                    domProps: { value: _vm.appl.companyName }
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c("label", [_vm._v("Trade Name")]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "field" },
                            [
                              _c(
                                "validator",
                                { attrs: { value: 0, reqs: "" } },
                                [
                                  _c("input", {
                                    attrs: { type: "text", readonly: "" },
                                    domProps: {
                                      value: _vm.appl.companyNameTrade
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c("label", [_vm._v("Company Address")]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "field" },
                            [
                              _c(
                                "validator",
                                {
                                  staticStyle: {
                                    "text-align": "left",
                                    "padding-left": "12px"
                                  },
                                  attrs: { value: 0, reqs: "" }
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.appl.companyAddress) +
                                      "\n          "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c("label", [_vm._v("Postcode")]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "field" },
                            [
                              _c(
                                "validator",
                                {
                                  staticStyle: {
                                    "text-align": "left",
                                    "padding-left": "12px"
                                  },
                                  attrs: { value: 0, reqs: "" }
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.appl.companyAddressPostcode) +
                                      "\n          "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c("label", [_vm._v("City")]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "field" },
                            [
                              _c(
                                "validator",
                                {
                                  staticStyle: {
                                    "text-align": "left",
                                    "padding-left": "12px"
                                  },
                                  attrs: { value: 0, reqs: "" }
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.appl.companyAddressCity) +
                                      "\n          "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c("label", [_vm._v("Province / State")]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "field" },
                            [
                              _c(
                                "validator",
                                {
                                  staticStyle: {
                                    "text-align": "left",
                                    "padding-left": "12px"
                                  },
                                  attrs: { value: 0, reqs: "" }
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.appl.companyAddressState) +
                                      "\n          "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c("label", [_vm._v("Country")]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "field" },
                            [
                              _c(
                                "validator",
                                {
                                  staticStyle: {
                                    "text-align": "left",
                                    "padding-left": "12px"
                                  },
                                  attrs: { value: 0, reqs: "" }
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.appl.companyAddressCountry) +
                                      "\n          "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c("label", [_vm._v("Reg #")]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "field" },
                            [
                              _c(
                                "validator",
                                { attrs: { value: 0, reqs: "" } },
                                [
                                  _c("input", {
                                    attrs: { type: "text", readonly: "" },
                                    domProps: { value: _vm.appl.companyRegnum }
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c("label", [_vm._v("Tax ID")]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "field" },
                            [
                              _c(
                                "validator",
                                { attrs: { value: 0, reqs: "" } },
                                [
                                  _c("input", {
                                    attrs: { type: "text", readonly: "" },
                                    domProps: { value: _vm.appl.companyTaxId }
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.appl.directors, function(director, di) {
                          return _c("li", [
                            _c("label", [_vm._v("Director " + _vm._s(di + 1))]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field" },
                              [
                                _c(
                                  "validator",
                                  {
                                    staticStyle: {
                                      "text-align": "left",
                                      "padding-left": "12px"
                                    },
                                    attrs: { value: 0, reqs: "" }
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          director.firstName +
                                            " " +
                                            director.lastName +
                                            " " +
                                            director.dob +
                                            ", " +
                                            director.address +
                                            ", " +
                                            director.addressPostcode +
                                            ", " +
                                            director.addressCity +
                                            ", " +
                                            director.addressNumber +
                                            ", " +
                                            director.addressCountry +
                                            ", " +
                                            director.phone +
                                            ", " +
                                            director.passportNum +
                                            ", " +
                                            director.ssn
                                        ) +
                                        "\n          "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ])
                        }),
                        _vm._v(" "),
                        _vm._l(_vm.appl.ubos, function(director, di) {
                          return _c("li", [
                            _c("label", [_vm._v("UBO " + _vm._s(di + 1))]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field" },
                              [
                                _c(
                                  "validator",
                                  {
                                    staticStyle: {
                                      "text-align": "left",
                                      "padding-left": "12px"
                                    },
                                    attrs: { value: 0, reqs: "" }
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          director.firstName +
                                            " " +
                                            director.lastName +
                                            " " +
                                            director.dob +
                                            ", " +
                                            director.address +
                                            ", " +
                                            director.addressPostcode +
                                            ", " +
                                            director.addressCity +
                                            ", " +
                                            director.addressNumber +
                                            ", " +
                                            director.addressCountry +
                                            ", " +
                                            director.phone +
                                            ", " +
                                            director.passportNum +
                                            ", " +
                                            director.ssn +
                                            ", " +
                                            director.ownership +
                                            "%"
                                        ) +
                                        "\n          "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ])
                        }),
                        _vm._v(" "),
                        _c("li", [
                          _c("label", [_vm._v("Website")]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "field" },
                            [
                              _c(
                                "validator",
                                { attrs: { value: 0, reqs: "" } },
                                [
                                  _c("input", {
                                    attrs: { type: "text", readonly: "" },
                                    domProps: { value: _vm.appl.website }
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c("label", [_vm._v("Website Beta")]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "field" },
                            [
                              _c(
                                "validator",
                                { attrs: { value: 0, reqs: "" } },
                                [
                                  _c("input", {
                                    attrs: { type: "text", readonly: "" },
                                    domProps: { value: _vm.appl.websiteBeta }
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        ])
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _vm.internalNotes && _vm.internalNotes.argCompany
                      ? _c("div", { staticClass: "notes important" }, [
                          _vm._v(
                            "Note: " +
                              _vm._s(_vm.internalNotes.argCompany) +
                              " "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "buttons" }, [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-large btn-plain btn-back",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            _vm.step--
                          }
                        }
                      },
                      [_vm._v("Back")]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-large btn-success btn-next",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.nextStep.apply(null, arguments)
                          }
                        }
                      },
                      [_vm._v("Next")]
                    )
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.step == 2
              ? _c("form", { key: "step3", staticClass: "form-step" }, [
                  _c("section", [
                    _c("h2", [_vm._v("Banking")]),
                    _vm._v(" "),
                    _c("ul", { staticClass: "column-form" }, [
                      _c("li", [
                        _c("label", [_vm._v("Settlement Currency")]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "field" },
                          [
                            _c("validator", { attrs: { value: 0, reqs: "" } }, [
                              _c("input", {
                                attrs: { type: "text", readonly: "" },
                                domProps: {
                                  value:
                                    _vm.currencies[
                                      _vm.appl.accounts[0].accountCurrency
                                    ]
                                }
                              })
                            ])
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("label", [_vm._v("Bank account holder")]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "field" },
                          [
                            _c("validator", { attrs: { value: 0, reqs: "" } }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.appl.accounts[0].accountHolder,
                                    expression: "appl.accounts[0].accountHolder"
                                  }
                                ],
                                attrs: { type: "text", readonly: "" },
                                domProps: {
                                  value: _vm.appl.accounts[0].accountHolder
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.appl.accounts[0],
                                      "accountHolder",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ])
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("label", [_vm._v("Bank Account #")]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "field" },
                          [
                            _c("validator", { attrs: { value: 0, reqs: "" } }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.appl.accounts[0].accountNumber,
                                    expression: "appl.accounts[0].accountNumber"
                                  }
                                ],
                                attrs: { type: "text", readonly: "" },
                                domProps: {
                                  value: _vm.appl.accounts[0].accountNumber
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.appl.accounts[0],
                                      "accountNumber",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ])
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("label", [_vm._v("Institution Number")]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "field" },
                          [
                            _c("validator", { attrs: { value: 0, reqs: "" } }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.appl.accounts[0].accountSwift,
                                    expression: "appl.accounts[0].accountSwift"
                                  }
                                ],
                                attrs: { type: "text", readonly: "" },
                                domProps: {
                                  value: _vm.appl.accounts[0].accountSwift
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.appl.accounts[0],
                                      "accountSwift",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ])
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("label", [_vm._v("Transit/Branch Number")]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "field" },
                          [
                            _c("validator", { attrs: { value: 0, reqs: "" } }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.appl.accounts[0].accountIBAN,
                                    expression: "appl.accounts[0].accountIBAN"
                                  }
                                ],
                                attrs: { type: "text", readonly: "" },
                                domProps: {
                                  value: _vm.appl.accounts[0].accountIBAN
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.appl.accounts[0],
                                      "accountIBAN",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ])
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("label", [_vm._v("ABA / routing number")]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "field" },
                          [
                            _c("validator", { attrs: { value: 0, reqs: "" } }, [
                              _c("input", {
                                attrs: { type: "text", readonly: "" },
                                domProps: {
                                  value:
                                    (_vm.appl.accounts[0].accountRouting ||
                                      "") +
                                    " " +
                                    (_vm.appl.accounts[0].accountSwift || "")
                                }
                              })
                            ])
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("label", [_vm._v("Bank Name")]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "field" },
                          [
                            _c("validator", { attrs: { value: 0, reqs: "" } }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.appl.accounts[0].bankName,
                                    expression: "appl.accounts[0].bankName"
                                  }
                                ],
                                attrs: { type: "text", readonly: "" },
                                domProps: {
                                  value: _vm.appl.accounts[0].bankName
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.appl.accounts[0],
                                      "bankName",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ])
                          ],
                          1
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _vm.internalNotes && _vm.internalNotes.agrBanking
                      ? _c("div", { staticClass: "notes important" }, [
                          _vm._v(
                            "Note: " +
                              _vm._s(_vm.internalNotes.agrBanking) +
                              " "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "buttons" }, [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-large btn-plain btn-back",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            _vm.step--
                          }
                        }
                      },
                      [_vm._v("Back")]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-large btn-success btn-next",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.nextStep.apply(null, arguments)
                          }
                        }
                      },
                      [_vm._v("Next")]
                    )
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            false
              ? _c("form", { key: "step4", staticClass: "form-step" }, [
                  _c("section", [
                    _c("h2", [_vm._v("Payment Methods")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "content-table-container" }, [
                      _c("table", { staticClass: "content-table" }, [
                        _c("tbody", [
                          _c("tr", [
                            _c(
                              "td",
                              [
                                _c("dyn-select", {
                                  attrs: {
                                    options: _vm.currentMethodTemplates
                                  },
                                  model: {
                                    value: _vm.methodTemplateId,
                                    callback: function($$v) {
                                      _vm.methodTemplateId = $$v
                                    },
                                    expression: "methodTemplateId"
                                  }
                                })
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("th", [
                              _c("label", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.item.payoptions,
                                      expression: "item.payoptions"
                                    }
                                  ],
                                  attrs: {
                                    type: "checkbox",
                                    value: "CardBiller",
                                    disabled:
                                      _vm.enabledPayOpts.indexOf(
                                        "CardBiller"
                                      ) == -1
                                  },
                                  domProps: {
                                    checked: Array.isArray(_vm.item.payoptions)
                                      ? _vm._i(
                                          _vm.item.payoptions,
                                          "CardBiller"
                                        ) > -1
                                      : _vm.item.payoptions
                                  },
                                  on: {
                                    change: function($event) {
                                      var $$a = _vm.item.payoptions,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = "CardBiller",
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.item,
                                              "payoptions",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.item,
                                              "payoptions",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(_vm.item, "payoptions", $$c)
                                      }
                                    }
                                  }
                                }),
                                _vm._v(
                                  "\n              CardBiller\n            "
                                )
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c(
                              "td",
                              { staticStyle: { "text-align": "left" } },
                              [
                                _c("label", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.item.payoptions,
                                        expression: "item.payoptions"
                                      }
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      value: "Visa",
                                      disabled:
                                        _vm.item.payoptions.indexOf(
                                          "CardBiller"
                                        ) == -1 ||
                                        _vm.enabledPayOpts.indexOf("Visa") == -1
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.item.payoptions
                                      )
                                        ? _vm._i(_vm.item.payoptions, "Visa") >
                                          -1
                                        : _vm.item.payoptions
                                    },
                                    on: {
                                      change: function($event) {
                                        var $$a = _vm.item.payoptions,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = "Visa",
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.item,
                                                "payoptions",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.item,
                                                "payoptions",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(_vm.item, "payoptions", $$c)
                                        }
                                      }
                                    }
                                  }),
                                  _vm._v(
                                    "\n                Visa\n              "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("label", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.item.payoptions,
                                        expression: "item.payoptions"
                                      }
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      value: "Mastercard",
                                      disabled:
                                        _vm.item.payoptions.indexOf(
                                          "CardBiller"
                                        ) == -1 ||
                                        _vm.enabledPayOpts.indexOf(
                                          "Mastercard"
                                        ) == -1
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.item.payoptions
                                      )
                                        ? _vm._i(
                                            _vm.item.payoptions,
                                            "Mastercard"
                                          ) > -1
                                        : _vm.item.payoptions
                                    },
                                    on: {
                                      change: function($event) {
                                        var $$a = _vm.item.payoptions,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = "Mastercard",
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.item,
                                                "payoptions",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.item,
                                                "payoptions",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(_vm.item, "payoptions", $$c)
                                        }
                                      }
                                    }
                                  }),
                                  _vm._v(
                                    "\n                Mastercard\n              "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("label", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.item.payoptions,
                                        expression: "item.payoptions"
                                      }
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      value: "Discover",
                                      disabled:
                                        _vm.item.payoptions.indexOf(
                                          "CardBiller"
                                        ) == -1 ||
                                        _vm.enabledPayOpts.indexOf(
                                          "Discover"
                                        ) == -1
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.item.payoptions
                                      )
                                        ? _vm._i(
                                            _vm.item.payoptions,
                                            "Discover"
                                          ) > -1
                                        : _vm.item.payoptions
                                    },
                                    on: {
                                      change: function($event) {
                                        var $$a = _vm.item.payoptions,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = "Discover",
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.item,
                                                "payoptions",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.item,
                                                "payoptions",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(_vm.item, "payoptions", $$c)
                                        }
                                      }
                                    }
                                  }),
                                  _vm._v(
                                    "\n                Discover\n              "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("label", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.item.payoptions,
                                        expression: "item.payoptions"
                                      }
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      value: "AMEX",
                                      disabled:
                                        _vm.item.payoptions.indexOf(
                                          "CardBiller"
                                        ) == -1 ||
                                        _vm.enabledPayOpts.indexOf("AMEX") == -1
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.item.payoptions
                                      )
                                        ? _vm._i(_vm.item.payoptions, "AMEX") >
                                          -1
                                        : _vm.item.payoptions
                                    },
                                    on: {
                                      change: function($event) {
                                        var $$a = _vm.item.payoptions,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = "AMEX",
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.item,
                                                "payoptions",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.item,
                                                "payoptions",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(_vm.item, "payoptions", $$c)
                                        }
                                      }
                                    }
                                  }),
                                  _vm._v(
                                    "\n                AMEX\n              "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("label", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.item.payoptions,
                                        expression: "item.payoptions"
                                      }
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      value: "China UnionPay",
                                      disabled:
                                        _vm.item.payoptions.indexOf(
                                          "CardBiller"
                                        ) == -1 ||
                                        _vm.enabledPayOpts.indexOf(
                                          "China UnionPay"
                                        ) == -1
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.item.payoptions
                                      )
                                        ? _vm._i(
                                            _vm.item.payoptions,
                                            "China UnionPay"
                                          ) > -1
                                        : _vm.item.payoptions
                                    },
                                    on: {
                                      change: function($event) {
                                        var $$a = _vm.item.payoptions,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = "China UnionPay",
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.item,
                                                "payoptions",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.item,
                                                "payoptions",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(_vm.item, "payoptions", $$c)
                                        }
                                      }
                                    }
                                  }),
                                  _vm._v(
                                    "\n                China UnionPay\n              "
                                  )
                                ])
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("th", [
                              _c("label", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.item.payoptions,
                                      expression: "item.payoptions"
                                    }
                                  ],
                                  attrs: {
                                    type: "checkbox",
                                    value: "CardBiller Plus",
                                    disabled:
                                      _vm.enabledPayOpts.indexOf(
                                        "CardBiller Plus"
                                      ) == -1
                                  },
                                  domProps: {
                                    checked: Array.isArray(_vm.item.payoptions)
                                      ? _vm._i(
                                          _vm.item.payoptions,
                                          "CardBiller Plus"
                                        ) > -1
                                      : _vm.item.payoptions
                                  },
                                  on: {
                                    change: function($event) {
                                      var $$a = _vm.item.payoptions,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = "CardBiller Plus",
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.item,
                                              "payoptions",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.item,
                                              "payoptions",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(_vm.item, "payoptions", $$c)
                                      }
                                    }
                                  }
                                }),
                                _vm._v(
                                  "\n              CardBiller Plus\n            "
                                )
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c(
                              "td",
                              { staticStyle: { "text-align": "left" } },
                              [
                                _c("label", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.item.payoptions,
                                        expression: "item.payoptions"
                                      }
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      value: "Apple Pay",
                                      disabled:
                                        _vm.item.payoptions.indexOf(
                                          "CardBiller Plus"
                                        ) == -1 ||
                                        _vm.enabledPayOpts.indexOf(
                                          "Apple Pay"
                                        ) == -1
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.item.payoptions
                                      )
                                        ? _vm._i(
                                            _vm.item.payoptions,
                                            "Apple Pay"
                                          ) > -1
                                        : _vm.item.payoptions
                                    },
                                    on: {
                                      change: function($event) {
                                        var $$a = _vm.item.payoptions,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = "Apple Pay",
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.item,
                                                "payoptions",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.item,
                                                "payoptions",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(_vm.item, "payoptions", $$c)
                                        }
                                      }
                                    }
                                  }),
                                  _vm._v(
                                    "\n                Apple Pay\n              "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("label", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.item.payoptions,
                                        expression: "item.payoptions"
                                      }
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      value: "Google Pay",
                                      disabled:
                                        _vm.item.payoptions.indexOf(
                                          "CardBiller Plus"
                                        ) == -1 ||
                                        _vm.enabledPayOpts.indexOf(
                                          "Google Pay"
                                        ) == -1
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.item.payoptions
                                      )
                                        ? _vm._i(
                                            _vm.item.payoptions,
                                            "Google Pay"
                                          ) > -1
                                        : _vm.item.payoptions
                                    },
                                    on: {
                                      change: function($event) {
                                        var $$a = _vm.item.payoptions,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = "Google Pay",
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.item,
                                                "payoptions",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.item,
                                                "payoptions",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(_vm.item, "payoptions", $$c)
                                        }
                                      }
                                    }
                                  }),
                                  _vm._v(
                                    "\n                Google Pay\n              "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("label", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.item.payoptions,
                                        expression: "item.payoptions"
                                      }
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      value: "Interac Online",
                                      disabled:
                                        _vm.item.payoptions.indexOf(
                                          "CardBiller Plus"
                                        ) == -1 ||
                                        _vm.enabledPayOpts.indexOf(
                                          "Interac Online"
                                        ) == -1
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.item.payoptions
                                      )
                                        ? _vm._i(
                                            _vm.item.payoptions,
                                            "Interac Online"
                                          ) > -1
                                        : _vm.item.payoptions
                                    },
                                    on: {
                                      change: function($event) {
                                        var $$a = _vm.item.payoptions,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = "Interac Online",
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.item,
                                                "payoptions",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.item,
                                                "payoptions",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(_vm.item, "payoptions", $$c)
                                        }
                                      }
                                    }
                                  }),
                                  _vm._v(
                                    "\n                Interac Online\n              "
                                  )
                                ])
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("th", [
                              _c("label", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.item.payoptions,
                                      expression: "item.payoptions"
                                    }
                                  ],
                                  attrs: {
                                    type: "checkbox",
                                    value: "BankBiller",
                                    disabled:
                                      _vm.enabledPayOpts.indexOf(
                                        "BankBiller"
                                      ) == -1
                                  },
                                  domProps: {
                                    checked: Array.isArray(_vm.item.payoptions)
                                      ? _vm._i(
                                          _vm.item.payoptions,
                                          "BankBiller"
                                        ) > -1
                                      : _vm.item.payoptions
                                  },
                                  on: {
                                    change: function($event) {
                                      var $$a = _vm.item.payoptions,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = "BankBiller",
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.item,
                                              "payoptions",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.item,
                                              "payoptions",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(_vm.item, "payoptions", $$c)
                                      }
                                    }
                                  }
                                }),
                                _vm._v(
                                  "\n              BankBiller\n            "
                                )
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c(
                              "td",
                              { staticStyle: { "text-align": "left" } },
                              [
                                _c("label", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.item.payoptions,
                                        expression: "item.payoptions"
                                      }
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      value: "EFT",
                                      disabled:
                                        _vm.item.payoptions.indexOf(
                                          "BankBiller"
                                        ) == -1 ||
                                        _vm.enabledPayOpts.indexOf("EFT") == -1
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.item.payoptions
                                      )
                                        ? _vm._i(_vm.item.payoptions, "EFT") >
                                          -1
                                        : _vm.item.payoptions
                                    },
                                    on: {
                                      change: function($event) {
                                        var $$a = _vm.item.payoptions,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = "EFT",
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.item,
                                                "payoptions",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.item,
                                                "payoptions",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(_vm.item, "payoptions", $$c)
                                        }
                                      }
                                    }
                                  }),
                                  _vm._v(
                                    "\n                EFT\n              "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("label", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.item.payoptions,
                                        expression: "item.payoptions"
                                      }
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      value: "ACH",
                                      disabled:
                                        _vm.item.payoptions.indexOf(
                                          "BankBiller"
                                        ) == -1 ||
                                        _vm.enabledPayOpts.indexOf("ACH") == -1
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.item.payoptions
                                      )
                                        ? _vm._i(_vm.item.payoptions, "ACH") >
                                          -1
                                        : _vm.item.payoptions
                                    },
                                    on: {
                                      change: function($event) {
                                        var $$a = _vm.item.payoptions,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = "ACH",
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.item,
                                                "payoptions",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.item,
                                                "payoptions",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(_vm.item, "payoptions", $$c)
                                        }
                                      }
                                    }
                                  }),
                                  _vm._v(
                                    "\n                ACH\n              "
                                  )
                                ])
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("th", [
                              _c("label", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.item.payoptions,
                                      expression: "item.payoptions"
                                    }
                                  ],
                                  attrs: {
                                    type: "checkbox",
                                    value: "CoinBiller",
                                    disabled:
                                      _vm.enabledPayOpts.indexOf(
                                        "CoinBiller"
                                      ) == -1
                                  },
                                  domProps: {
                                    checked: Array.isArray(_vm.item.payoptions)
                                      ? _vm._i(
                                          _vm.item.payoptions,
                                          "CoinBiller"
                                        ) > -1
                                      : _vm.item.payoptions
                                  },
                                  on: {
                                    change: function($event) {
                                      var $$a = _vm.item.payoptions,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = "CoinBiller",
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.item,
                                              "payoptions",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.item,
                                              "payoptions",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(_vm.item, "payoptions", $$c)
                                      }
                                    }
                                  }
                                }),
                                _vm._v(
                                  "\n              CoinBiller\n            "
                                )
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c(
                              "td",
                              { staticStyle: { "text-align": "left" } },
                              [
                                _c("label", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.item.payoptions,
                                        expression: "item.payoptions"
                                      }
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      value: "Bitcoin",
                                      disabled:
                                        _vm.item.payoptions.indexOf(
                                          "CoinBiller"
                                        ) == -1 ||
                                        _vm.enabledPayOpts.indexOf("Bitcoin") ==
                                          -1
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.item.payoptions
                                      )
                                        ? _vm._i(
                                            _vm.item.payoptions,
                                            "Bitcoin"
                                          ) > -1
                                        : _vm.item.payoptions
                                    },
                                    on: {
                                      change: function($event) {
                                        var $$a = _vm.item.payoptions,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = "Bitcoin",
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.item,
                                                "payoptions",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.item,
                                                "payoptions",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(_vm.item, "payoptions", $$c)
                                        }
                                      }
                                    }
                                  }),
                                  _vm._v(
                                    "\n                Bitcoin\n              "
                                  )
                                ])
                              ]
                            )
                          ])
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _vm.internalNotes && _vm.internalNotes.agrModules
                      ? _c("div", { staticClass: "notes important" }, [
                          _vm._v(
                            "Note: " +
                              _vm._s(_vm.internalNotes.agrModules) +
                              " "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "buttons" }, [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-large btn-plain btn-back",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            _vm.step--
                          }
                        }
                      },
                      [_vm._v("Back")]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-large btn-success btn-next",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.nextStep.apply(null, arguments)
                          }
                        }
                      },
                      [_vm._v("Next")]
                    )
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            false
              ? _c("form", { key: "step5", staticClass: "form-step" }, [
                  _c("section", [
                    _c("h2", [_vm._v("Modules")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "content-table-container" }, [
                      _c("table", { staticClass: "content-table" }, [
                        _vm._m(0),
                        _vm._v(" "),
                        _c(
                          "tbody",
                          [
                            _c("tr", [
                              _c("td", { staticStyle: { width: "80%" } }),
                              _c(
                                "td",
                                { staticStyle: { width: "15%" } },
                                [
                                  _c("dyn-select", {
                                    attrs: { options: _vm.currentModTemplates },
                                    model: {
                                      value: _vm.moduleTemplateId,
                                      callback: function($$v) {
                                        _vm.moduleTemplateId = $$v
                                      },
                                      expression: "moduleTemplateId"
                                    }
                                  })
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _vm._l(_vm.modules, function(item, index) {
                              return _c("tr", [
                                _c(
                                  "td",
                                  {
                                    staticClass: "text-left",
                                    staticStyle: { width: "45%" }
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(item.name) +
                                        "\n            "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  [
                                    _c("dyn-select", {
                                      attrs: {
                                        options:
                                          item.mode == "Count"
                                            ? _vm.optsCount
                                            : _vm.availOptEnabled
                                      },
                                      model: {
                                        value: _vm.modulesEnabled[index],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.modulesEnabled,
                                            index,
                                            $$v
                                          )
                                        },
                                        expression: "modulesEnabled[index]"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ])
                            })
                          ],
                          2
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _vm.internalNotes && _vm.internalNotes.agrModules
                      ? _c("div", { staticClass: "notes important" }, [
                          _vm._v(
                            "Note: " +
                              _vm._s(_vm.internalNotes.agrModules) +
                              " "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "buttons" }, [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-large btn-plain btn-back",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            _vm.step--
                          }
                        }
                      },
                      [_vm._v("Back")]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-large btn-success btn-next",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.nextStep.apply(null, arguments)
                          }
                        }
                      },
                      [_vm._v("Next")]
                    )
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.step == 4
              ? _c("form", { key: "step6", staticClass: "form-step" }, [
                  _c(
                    "section",
                    { staticClass: "appf pfx" },
                    [
                      _c("h2", [_vm._v("Equipment")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "column-form type2" },
                        [
                          _vm._l([_vm.item.equipment], function(q) {
                            return [
                              _vm._l(_vm.terminals, function(ti) {
                                return [
                                  _c("li", { staticClass: "intake-title" }, [
                                    _vm._v("Terminals Type #" + _vm._s(ti))
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "li",
                                    {
                                      staticStyle: {
                                        width: "calc(19.75% - 5px)"
                                      }
                                    },
                                    [
                                      _c("label", [_vm._v("Model")]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "field complex" },
                                        [
                                          _c(
                                            "validator",
                                            {
                                              attrs: {
                                                value:
                                                  q["terminal" + ti + "Type"],
                                                reqs: ""
                                              }
                                            },
                                            [
                                              _c("dyn-select", {
                                                attrs: {
                                                  options: _vm.equipmentOpt
                                                },
                                                model: {
                                                  value:
                                                    q["terminal" + ti + "Type"],
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      q,
                                                      "terminal" + ti + "Type",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "q['terminal'+ti+'Type']"
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("br"),
                                              _vm._v(
                                                "\n            $" +
                                                  _vm._s(
                                                    (
                                                      _vm.equi(ti)[
                                                        q[
                                                          "terminal" +
                                                            ti +
                                                            "PurchaseOpt"
                                                        ]
                                                          ? "purchase"
                                                          : q[
                                                              "terminal" +
                                                                ti +
                                                                "RentalOpt"
                                                            ]
                                                          ? "rental"
                                                          : "reprogram"
                                                      ] || 0
                                                    ).toFixed(2)
                                                  ) +
                                                  "\n"
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "li",
                                    {
                                      staticStyle: {
                                        width: "calc(6.25% - 5px)"
                                      }
                                    },
                                    [
                                      _c("label", [_vm._v("QTY")]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "field complex" },
                                        [
                                          _c(
                                            "validator",
                                            {
                                              attrs: {
                                                value:
                                                  q["terminal" + ti + "Qty"],
                                                reqs: ""
                                              }
                                            },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      q[
                                                        "terminal" + ti + "Qty"
                                                      ],
                                                    expression:
                                                      "q['terminal'+ti+'Qty']"
                                                  }
                                                ],
                                                attrs: { type: "text" },
                                                domProps: {
                                                  value:
                                                    q["terminal" + ti + "Qty"]
                                                },
                                                on: {
                                                  input: function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      q,
                                                      "terminal" + ti + "Qty",
                                                      $event.target.value
                                                    )
                                                  }
                                                }
                                              })
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "li",
                                    {
                                      staticStyle: { width: "calc(12% - 5px)" }
                                    },
                                    [
                                      _c("label", [_vm._v("Purchase")]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "field conditional" },
                                        [
                                          _c("checkbox-plain2", {
                                            attrs: {
                                              disabled: !_vm.equi(ti).purchase
                                            },
                                            on: {
                                              input: function($event) {
                                                _vm.$set(
                                                  q,
                                                  "terminal" + ti + "RentalOpt",
                                                  false
                                                )
                                                _vm.$set(
                                                  q,
                                                  "terminal" +
                                                    ti +
                                                    "LeasingOpt",
                                                  false
                                                )
                                                _vm.$set(
                                                  q,
                                                  "terminal" +
                                                    ti +
                                                    "ReprogramOpt",
                                                  false
                                                )
                                              }
                                            },
                                            model: {
                                              value:
                                                q[
                                                  "terminal" +
                                                    ti +
                                                    "PurchaseOpt"
                                                ],
                                              callback: function($$v) {
                                                _vm.$set(
                                                  q,
                                                  "terminal" +
                                                    ti +
                                                    "PurchaseOpt",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "q['terminal'+ti+'PurchaseOpt']"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "li",
                                    {
                                      staticStyle: { width: "calc(12% - 5px)" }
                                    },
                                    [
                                      _c("label", [_vm._v("Rental")]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "field conditional" },
                                        [
                                          _c("checkbox-plain2", {
                                            attrs: {
                                              disabled: !_vm.equi(ti).rental
                                            },
                                            on: {
                                              input: function($event) {
                                                _vm.$set(
                                                  q,
                                                  "terminal" +
                                                    ti +
                                                    "PurchaseOpt",
                                                  false
                                                )
                                                _vm.$set(
                                                  q,
                                                  "terminal" +
                                                    ti +
                                                    "LeasingOpt",
                                                  false
                                                )
                                                _vm.$set(
                                                  q,
                                                  "terminal" +
                                                    ti +
                                                    "ReprogramOpt",
                                                  false
                                                )
                                              }
                                            },
                                            model: {
                                              value:
                                                q[
                                                  "terminal" + ti + "RentalOpt"
                                                ],
                                              callback: function($$v) {
                                                _vm.$set(
                                                  q,
                                                  "terminal" + ti + "RentalOpt",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "q['terminal'+ti+'RentalOpt']"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "li",
                                    {
                                      staticStyle: { width: "calc(12% - 5px)" }
                                    },
                                    [
                                      _c("label", [_vm._v("Reprogram")]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "field conditional" },
                                        [
                                          _c("checkbox-plain2", {
                                            on: {
                                              input: function($event) {
                                                _vm.$set(
                                                  q,
                                                  "terminal" +
                                                    ti +
                                                    "PurchaseOpt",
                                                  false
                                                )
                                                _vm.$set(
                                                  q,
                                                  "terminal" + ti + "RentalOpt",
                                                  false
                                                )
                                                _vm.$set(
                                                  q,
                                                  "terminal" +
                                                    ti +
                                                    "LeasingOpt",
                                                  false
                                                )
                                              }
                                            },
                                            model: {
                                              value:
                                                q[
                                                  "terminal" +
                                                    ti +
                                                    "ReprogramOpt"
                                                ],
                                              callback: function($$v) {
                                                _vm.$set(
                                                  q,
                                                  "terminal" +
                                                    ti +
                                                    "ReprogramOpt",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "q['terminal'+ti+'ReprogramOpt']"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "li",
                                    {
                                      staticStyle: { width: "calc(12% - 5px)" }
                                    },
                                    [
                                      _c("label", [_vm._v("Leasing")]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "field conditional" },
                                        [
                                          _c("checkbox-plain2", {
                                            attrs: { disabled: "" },
                                            on: {
                                              input: function($event) {
                                                _vm.$set(
                                                  q,
                                                  "terminal" +
                                                    ti +
                                                    "PurchaseOpt",
                                                  false
                                                )
                                                _vm.$set(
                                                  q,
                                                  "terminal" + ti + "RentalOpt",
                                                  false
                                                )
                                                _vm.$set(
                                                  q,
                                                  "terminal" +
                                                    ti +
                                                    "ReprogramOpt",
                                                  false
                                                )
                                              }
                                            },
                                            model: {
                                              value:
                                                q[
                                                  "terminal" + ti + "LeasingOpt"
                                                ],
                                              callback: function($$v) {
                                                _vm.$set(
                                                  q,
                                                  "terminal" +
                                                    ti +
                                                    "LeasingOpt",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "q['terminal'+ti+'LeasingOpt']"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  false
                                    ? _c(
                                        "li",
                                        {
                                          staticStyle: {
                                            width: "calc(12% - 5px)"
                                          }
                                        },
                                        [
                                          _c("label", [_vm._v("Merchant Own")]),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "field conditional"
                                            },
                                            [
                                              _c("checkbox-plain2", {
                                                model: {
                                                  value:
                                                    q[
                                                      "terminal" + ti + "OwnOpt"
                                                    ],
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      q,
                                                      "terminal" +
                                                        ti +
                                                        "OwnOpt",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "q['terminal'+ti+'OwnOpt']"
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("span", [_vm._v("Qty:")]),
                                              _vm._v(" "),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      q[
                                                        "terminal" + ti + "Own"
                                                      ],
                                                    expression:
                                                      "q['terminal'+ti+'Own']"
                                                  }
                                                ],
                                                attrs: {
                                                  type: "text",
                                                  disabled: !q[
                                                    "terminal" + ti + "OwnOpt"
                                                  ]
                                                },
                                                domProps: {
                                                  value:
                                                    q["terminal" + ti + "Own"]
                                                },
                                                on: {
                                                  input: function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      q,
                                                      "terminal" + ti + "Own",
                                                      $event.target.value
                                                    )
                                                  }
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  false
                                    ? _c(
                                        "li",
                                        {
                                          staticStyle: {
                                            width: "calc(12% - 5px)"
                                          }
                                        },
                                        [
                                          _c("label", [_vm._v("Financing")]),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "field conditional"
                                            },
                                            [
                                              _c("checkbox-plain2", {
                                                model: {
                                                  value:
                                                    q[
                                                      "terminal" +
                                                        ti +
                                                        "FinancingOpt"
                                                    ],
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      q,
                                                      "terminal" +
                                                        ti +
                                                        "FinancingOpt",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "q['terminal'+ti+'FinancingOpt']"
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("span", [_vm._v("Qty:")]),
                                              _vm._v(" "),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      q[
                                                        "terminal" +
                                                          ti +
                                                          "Financing"
                                                      ],
                                                    expression:
                                                      "q['terminal'+ti+'Financing']"
                                                  }
                                                ],
                                                attrs: {
                                                  type: "text",
                                                  disabled: !q[
                                                    "terminal" +
                                                      ti +
                                                      "FinancingOpt"
                                                  ]
                                                },
                                                domProps: {
                                                  value:
                                                    q[
                                                      "terminal" +
                                                        ti +
                                                        "Financing"
                                                    ]
                                                },
                                                on: {
                                                  input: function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      q,
                                                      "terminal" +
                                                        ti +
                                                        "Financing",
                                                      $event.target.value
                                                    )
                                                  }
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  false
                                    ? _c(
                                        "li",
                                        {
                                          staticStyle: {
                                            width: "calc(12% - 5px)"
                                          }
                                        },
                                        [
                                          _c("label", [_vm._v("Insurance")]),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "field conditional"
                                            },
                                            [
                                              _c("checkbox-plain2", {
                                                model: {
                                                  value:
                                                    q[
                                                      "terminal" +
                                                        ti +
                                                        "InsuranceOpt"
                                                    ],
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      q,
                                                      "terminal" +
                                                        ti +
                                                        "InsuranceOpt",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "q['terminal'+ti+'InsuranceOpt']"
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("span", [_vm._v("Qty:")]),
                                              _vm._v(" "),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      q[
                                                        "terminal" +
                                                          ti +
                                                          "Insurance"
                                                      ],
                                                    expression:
                                                      "q['terminal'+ti+'Insurance']"
                                                  }
                                                ],
                                                attrs: {
                                                  type: "text",
                                                  disabled: !q[
                                                    "terminal" +
                                                      ti +
                                                      "InsuranceOpt"
                                                  ]
                                                },
                                                domProps: {
                                                  value:
                                                    q[
                                                      "terminal" +
                                                        ti +
                                                        "Insurance"
                                                    ]
                                                },
                                                on: {
                                                  input: function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      q,
                                                      "terminal" +
                                                        ti +
                                                        "Insurance",
                                                      $event.target.value
                                                    )
                                                  }
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              })
                            ]
                          })
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c("li", { staticStyle: { width: "100%" } }, [
                        _vm.terminalCount > 1
                          ? _c(
                              "a",
                              {
                                staticClass: "terminals-del",
                                attrs: { href: "" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    _vm.terminalCount--
                                  }
                                }
                              },
                              [_vm._v("‒")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "terminals-add",
                            attrs: { href: "" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                _vm.terminalCount++
                              }
                            }
                          },
                          [_vm._v("+")]
                        )
                      ]),
                      _vm._v(" "),
                      _vm._l([_vm.intake.formData], function(q) {
                        return _c("ul", { staticClass: "column-form type2" }, [
                          _c("li", { staticClass: "intake-title" }, [
                            _vm._v("Shipping")
                          ]),
                          _vm._v(" "),
                          _c("li", { staticClass: "fwidth fwx" }, [
                            _c("label", [_vm._v("Ship to")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field" },
                              [
                                _c(
                                  "validator",
                                  {
                                    attrs: {
                                      value:
                                        q.shipTo && q.shipTo.length
                                          ? q.shipTo
                                          : q.shipToOther,
                                      reqs: ""
                                    }
                                  },
                                  [
                                    _c("multiselect", {
                                      attrs: {
                                        other: q.shipToOther,
                                        options: [
                                          "Company",
                                          "Location",
                                          "Other"
                                        ],
                                        onlyone: true
                                      },
                                      on: {
                                        "update:other": function($event) {
                                          return _vm.$set(
                                            q,
                                            "shipToOther",
                                            $event
                                          )
                                        }
                                      },
                                      model: {
                                        value: q.shipTo,
                                        callback: function($$v) {
                                          _vm.$set(q, "shipTo", $$v)
                                        },
                                        expression: "q.shipTo"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", { staticClass: "fwidth fwx" }, [
                            _c("label", [_vm._v("Shipping Method")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field" },
                              [
                                _c(
                                  "validator",
                                  {
                                    attrs: {
                                      value:
                                        q.shippingMethod &&
                                        q.shippingMethod.length,
                                      reqs: ""
                                    }
                                  },
                                  [
                                    _c("multiselect", {
                                      attrs: {
                                        options: [
                                          "Regular",
                                          "2 Day",
                                          "Next Day",
                                          "Overnight"
                                        ],
                                        onlyone: true
                                      },
                                      model: {
                                        value: q.shippingMethod,
                                        callback: function($$v) {
                                          _vm.$set(q, "shippingMethod", $$v)
                                        },
                                        expression: "q.shippingMethod"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", {}, [
                            _c("label", [_vm._v("Ship to Other Address")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field complex" },
                              [
                                _c(
                                  "validator",
                                  {
                                    attrs: {
                                      value: q.shipOtherAddress,
                                      reqs:
                                        q.shipTo &&
                                        q.shipTo.indexOf("Other") != -1
                                          ? "required"
                                          : ""
                                    }
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: q.shipOtherAddress,
                                          expression: "q.shipOtherAddress"
                                        }
                                      ],
                                      attrs: {
                                        type: "text",
                                        disabled:
                                          !q.shipTo ||
                                          q.shipTo.indexOf("Other") == -1
                                      },
                                      domProps: { value: q.shipOtherAddress },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            q,
                                            "shipOtherAddress",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", {}, [
                            _c("label", [_vm._v("City")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field complex" },
                              [
                                _c(
                                  "validator",
                                  {
                                    attrs: {
                                      value: q.shipOtherCity,
                                      reqs:
                                        q.shipTo &&
                                        q.shipTo.indexOf("Other") != -1
                                          ? "required"
                                          : ""
                                    }
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: q.shipOtherCity,
                                          expression: "q.shipOtherCity"
                                        }
                                      ],
                                      attrs: {
                                        type: "text",
                                        disabled:
                                          !q.shipTo ||
                                          q.shipTo.indexOf("Other") == -1
                                      },
                                      domProps: { value: q.shipOtherCity },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            q,
                                            "shipOtherCity",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", {}, [
                            _c("label", [_vm._v("Province/State")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field complex" },
                              [
                                _c(
                                  "validator",
                                  {
                                    attrs: {
                                      value: q.shipOtherState,
                                      reqs:
                                        q.shipTo &&
                                        q.shipTo.indexOf("Other") != -1
                                          ? "required"
                                          : ""
                                    }
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: q.shipOtherState,
                                          expression: "q.shipOtherState"
                                        }
                                      ],
                                      attrs: {
                                        type: "text",
                                        disabled:
                                          !q.shipTo ||
                                          q.shipTo.indexOf("Other") == -1
                                      },
                                      domProps: { value: q.shipOtherState },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            q,
                                            "shipOtherState",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", {}, [
                            _c("label", [_vm._v("Postal Code/Zip")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field complex" },
                              [
                                _c(
                                  "validator",
                                  {
                                    attrs: {
                                      value: q.shipOtherPostal,
                                      reqs:
                                        q.shipTo &&
                                        q.shipTo.indexOf("Other") != -1
                                          ? "required"
                                          : ""
                                    }
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: q.shipOtherPostal,
                                          expression: "q.shipOtherPostal"
                                        }
                                      ],
                                      attrs: {
                                        type: "text",
                                        disabled:
                                          !q.shipTo ||
                                          q.shipTo.indexOf("Other") == -1
                                      },
                                      domProps: { value: q.shipOtherPostal },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            q,
                                            "shipOtherPostal",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", { staticStyle: { width: "100%" } }, [
                            _c("label", [_vm._v("Instructions")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field complex" },
                              [
                                _c(
                                  "validator",
                                  {
                                    attrs: {
                                      value: q.shipOtherInstructions,
                                      reqs: ""
                                    }
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: q.shipOtherInstructions,
                                          expression: "q.shipOtherInstructions"
                                        }
                                      ],
                                      attrs: { type: "text" },
                                      domProps: {
                                        value: q.shipOtherInstructions
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            q,
                                            "shipOtherInstructions",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ])
                        ])
                      }),
                      _vm._v(" "),
                      _vm.internalNotes && _vm.internalNotes.agrEquipment
                        ? _c("div", { staticClass: "notes important" }, [
                            _vm._v(
                              "Note: " +
                                _vm._s(_vm.internalNotes.agrEquipment) +
                                " "
                            )
                          ])
                        : _vm._e()
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "buttons" }, [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-large btn-plain btn-back",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            _vm.step--
                          }
                        }
                      },
                      [_vm._v("Back")]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-large btn-success",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.save.apply(null, arguments)
                          }
                        }
                      },
                      [_vm._v("Create Agreement")]
                    )
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            false
              ? _c("form", { key: "step7", staticClass: "form-step" }, [
                  _c("section", [
                    _c("h2", [_vm._v("Options")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "content-table-container" }, [
                      _c("table", { staticClass: "content-table" }, [
                        _vm._m(1),
                        _vm._v(" "),
                        _c(
                          "tbody",
                          [
                            _c("tr", [
                              _c("td", { staticStyle: { width: "80%" } }),
                              _c(
                                "td",
                                { staticStyle: { width: "15%" } },
                                [
                                  _c("dyn-select", {
                                    attrs: { options: _vm.currentOptTemplates },
                                    model: {
                                      value: _vm.optionTemplateId,
                                      callback: function($$v) {
                                        _vm.optionTemplateId = $$v
                                      },
                                      expression: "optionTemplateId"
                                    }
                                  })
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _vm._l(_vm.options, function(item, index) {
                              return _c("tr", [
                                _c(
                                  "td",
                                  {
                                    staticClass: "text-left",
                                    staticStyle: { width: "45%" }
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(item.name) +
                                        "\n            "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  [
                                    _c("dyn-select", {
                                      attrs: {
                                        options:
                                          item.mode == "Count"
                                            ? _vm.optsCount
                                            : _vm.availOptEnabled
                                      },
                                      model: {
                                        value: _vm.optionsEnabled[index],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.optionsEnabled,
                                            index,
                                            $$v
                                          )
                                        },
                                        expression: "optionsEnabled[index]"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ])
                            })
                          ],
                          2
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _vm.internalNotes && _vm.internalNotes.agrOptions
                      ? _c("div", { staticClass: "notes important" }, [
                          _vm._v(
                            "Note: " +
                              _vm._s(_vm.internalNotes.agrOptions) +
                              " "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "buttons" }, [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-large btn-plain btn-back",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            _vm.step--
                          }
                        }
                      },
                      [_vm._v("Back")]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-large btn-success btn-next",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.nextStep.apply(null, arguments)
                          }
                        }
                      },
                      [_vm._v("Next")]
                    )
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.step == 3
              ? _c("form", { key: "step8", staticClass: "form-step" }, [
                  _c(
                    "section",
                    [
                      _c("h2", [_vm._v("Pricing")]),
                      _vm._v(" "),
                      _c("pricing-details", {
                        attrs: {
                          currentTemplate: _vm.intake.formData,
                          solution: _vm.appl.solution
                        }
                      }),
                      _vm._v(" "),
                      _vm.internalNotes && _vm.internalNotes.agrPricing
                        ? _c("div", { staticClass: "notes important" }, [
                            _vm._v(
                              "Note: " +
                                _vm._s(_vm.internalNotes.agrPricing) +
                                " "
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "buttons" }, [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-large btn-plain btn-back",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            _vm.step--
                          }
                        }
                      },
                      [_vm._v("Back")]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-large btn-success btn-next",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.nextStep.apply(null, arguments)
                          }
                        }
                      },
                      [_vm._v("Next")]
                    )
                  ])
                ])
              : _vm._e()
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticStyle: { width: "80%" } }, [_vm._v("Module")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "15%" } }, [_vm._v("Enabled")])
      ]),
      _vm._v(" "),
      _c("tr", [_c("th", { staticClass: "spacer", attrs: { colspan: "2" } })])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticStyle: { width: "80%" } }, [_vm._v("Option")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "15%" } }, [_vm._v("Enabled")])
      ]),
      _vm._v(" "),
      _c("tr", [_c("th", { staticClass: "spacer", attrs: { colspan: "2" } })])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }