var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("aside", { class: { collapsed: _vm.collapsed } }, [
    _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "header-main" }, [
        _c(
          "button",
          {
            staticClass: "unstyled hamburger",
            on: {
              click: function($event) {
                return _vm.toggleCollapsed()
              }
            }
          },
          [_c("MenuIcon")],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "actions" },
          [
            _c(
              "div",
              {
                staticClass: "item-icon mr-5",
                on: {
                  mouseenter: function($event) {
                    _vm.hoverOn = "notifications"
                  },
                  mouseleave: function($event) {
                    _vm.hoverOn = null
                  }
                }
              },
              [_c("NotificationIcon")],
              1
            ),
            _vm._v(" "),
            _c(
              "popper",
              {
                attrs: { position: "right-top", offset: { y: 5 } },
                scopedSlots: _vm._u([
                  {
                    key: "reference",
                    fn: function() {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "item-icon",
                            on: {
                              mouseenter: function($event) {
                                _vm.hoverOn = "messages"
                              },
                              mouseleave: function($event) {
                                _vm.hoverOn = null
                              }
                            }
                          },
                          [
                            _c("SystemMessagesIcon", {
                              attrs: {
                                color:
                                  _vm.hoverOn === "messages"
                                    ? "#51b13e"
                                    : "#292929"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  }
                ])
              },
              [_c("messages")],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "logo-wrapper" }, [
        _c("img", { attrs: { src: _vm.logo } })
      ])
    ]),
    _vm._v(" "),
    _c("nav", [
      _c(
        "ul",
        [
          _c("nav-item", {
            attrs: { item: _vm.dashboardItem, name: "dashboard" }
          }),
          _vm._v(" "),
          _vm._l(_vm.navigation, function(item, key) {
            return _c("nav-item", {
              key: key,
              attrs: { name: key, item: item }
            })
          })
        ],
        2
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      [
        _c("div", { staticClass: "sidebar-footer" }, [
          _c("span", { staticClass: "version" }, [
            _vm._v(_vm._s(_vm.changeLog.version))
          ]),
          _vm._v(" "),
          !_vm.user.agentId
            ? _c(
                "a",
                { staticClass: "manage-logo", attrs: { href: "/manage" } },
                [_c("img", { attrs: { src: "/assets/img/plug-dark.png" } })]
              )
            : _vm._e()
        ]),
        _vm._v(" "),
        _c(
          "popper",
          {
            attrs: {
              position: _vm.collapsed ? "right-bottom" : "top",
              offset: _vm.collapsed ? { x: 10, y: 10 } : { y: 10 }
            },
            scopedSlots: _vm._u([
              {
                key: "reference",
                fn: function() {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "account",
                        on: {
                          mouseenter: function($event) {
                            _vm.hoverOn = "account"
                          },
                          mouseleave: function($event) {
                            _vm.hoverOn = null
                          }
                        }
                      },
                      [
                        _c("account-icon", {
                          staticClass: "icon",
                          attrs: {
                            size: 30,
                            color:
                              _vm.hoverOn === "account" ? "#51b13e" : "#292929"
                          }
                        }),
                        _vm._v(" "),
                        _c("span", [_vm._v("Account")])
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              }
            ])
          },
          [_c("account-dropdown")],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }