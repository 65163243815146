var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("ul", { staticClass: "appview-list" }, [
        _c("li", [
          _c("label", [_vm._v("Setup Date")]),
          _vm._v(" "),
          _c("div", { staticClass: "validator" }, [
            _c("span", [
              _vm._v(_vm._s(_vm.formatDate(_vm.employee.created, "MM/DD/YYYY")))
            ])
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("label", [_vm._v("Activation Date")]),
          _vm._v(" "),
          _c("div", { staticClass: "validator" }, [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.employee.userId.activationDate
                    ? _vm.formatDate(
                        _vm.employee.userId.activationDate,
                        "MM/DD/YYYY"
                      )
                    : "N/A"
                )
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("label", [_vm._v("Employee ID")]),
          _vm._v(" "),
          _c("div", { staticClass: "validator" }, [
            _c("span", [_vm._v(_vm._s(_vm.employee.employeeId))])
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("label", [_vm._v("Employee Solution")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "validator" },
            [
              _vm.editMode
                ? _c(
                    "validator",
                    { attrs: { value: _vm.form.companyId, reqs: "required" } },
                    [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.companyId,
                              expression: "form.companyId"
                            }
                          ],
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.form,
                                "companyId",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            }
                          }
                        },
                        _vm._l(_vm.companies, function(company) {
                          return _c(
                            "option",
                            { domProps: { value: company.id } },
                            [_vm._v(_vm._s(company.nameSolution))]
                          )
                        }),
                        0
                      )
                    ]
                  )
                : _c("span", [
                    _vm._v(_vm._s(_vm.employee.companyId.nameSolution))
                  ])
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c("portal", { attrs: { to: "actions" } }, [
        _c("ul", [
          _c("li", [
            _c(
              "a",
              {
                attrs: { href: "" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.$emit("update:editMode", true)
                  }
                }
              },
              [_vm._v("Edit")]
            )
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }