var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "status-page" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "status-list" }, [
      _c("ul", [
        _c("li", [
          _c("div", { staticClass: "terms-header" }, [
            _c("a", { staticClass: "toggle", attrs: { href: "" } }, [
              _vm._v(
                "\n            Last Updated - " +
                  _vm._s(_vm.items.privacyPolicyDate) +
                  "\n          "
              )
            ])
          ]),
          _vm._v(" "),
          _c("ul", [
            _c("div", {
              staticClass: "terms-content",
              domProps: { innerHTML: _vm._s(_vm.items.privacyPolicyText) }
            })
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ss-head" }, [
      _c("div", { staticClass: "publish-content" }, [
        _c("h1", [_vm._v("General Privacy Policy")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }