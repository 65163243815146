var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("view-app-extcheck", {
        ref: "extcheck",
        attrs: { type: "g2", uwList: _vm.itemList }
      }),
      _vm._v(" "),
      _c("portal", { attrs: { to: "actions" } }, [
        _c("ul", [
          _c("li", [
            _c(
              "a",
              {
                attrs: { href: "" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.$refs.extcheck.newCheck()
                  }
                }
              },
              [_vm._v("Run New")]
            )
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }