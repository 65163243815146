var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "content-table-container file-list-container" },
        [
          _c("table", { staticClass: "file-list content-table" }, [
            _c(
              "tbody",
              [
                _c("tr", [
                  _c("td", [_vm._v("Created")]),
                  _vm._v(" "),
                  _c("td", [_vm._v("Description")]),
                  _vm._v(" "),
                  _c("td", [_vm._v("Number")]),
                  _vm._v(" "),
                  _c("td", [_vm._v("Filename")]),
                  _vm._v(" "),
                  _c("td", [_vm._v("Status")]),
                  _vm._v(" "),
                  !_vm.simplified ? _c("td", [_vm._v("Options")]) : _vm._e()
                ]),
                _vm._v(" "),
                _vm.item.accountType == "Personal"
                  ? [
                      _vm.onboarding
                        ? _vm._l(_vm.item.personal.documents, function(
                            document
                          ) {
                            return document.status != "Archived"
                              ? _c("tr", { key: document.number }, [
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.formatDate(
                                          document.created,
                                          "YYYY-MM-DD"
                                        )
                                      )
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(document.title))]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(document.number))]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: _vm.fileLink(
                                            document.filename,
                                            document.id
                                          ),
                                          target: "_blank"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            document.filename || "(no file)"
                                          )
                                        )
                                      ]
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(document.status))]),
                                  _vm._v(" "),
                                  !_vm.simplified
                                    ? _c(
                                        "td",
                                        [
                                          document.status == "Pending"
                                            ? [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "option-btn",
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.updateOk(
                                                          document
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("img", {
                                                      attrs: {
                                                        src:
                                                          "/assets/img/table-approve.png"
                                                      }
                                                    })
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "option-btn",
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.update(
                                                          document
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("img", {
                                                      attrs: {
                                                        src:
                                                          "/assets/img/table-deny.png"
                                                      }
                                                    })
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "option-btn",
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.updateArchived(
                                                          document
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      DEL\n                    "
                                                    )
                                                  ]
                                                )
                                              ]
                                            : _vm._e()
                                        ],
                                        2
                                      )
                                    : _vm._e()
                                ])
                              : _vm._e()
                          })
                        : _vm._l(_vm.item.personal.documents, function(
                            document
                          ) {
                            return _c("tr", { key: document.number }, [
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatDate(
                                      document.created,
                                      "YYYY-MM-DD"
                                    )
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", [_vm._v(_vm._s(document.title))]),
                              _vm._v(" "),
                              _c("td", [_vm._v(_vm._s(document.number))]),
                              _vm._v(" "),
                              _c("td", [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: _vm.fileLink(
                                        document.filename,
                                        document.id
                                      ),
                                      target: "_blank"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(document.filename || "(no file)")
                                    )
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", [_vm._v(_vm._s(document.status))]),
                              _vm._v(" "),
                              !_vm.simplified
                                ? _c(
                                    "td",
                                    [
                                      document.status == "Pending"
                                        ? [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "option-btn",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.updateOk(
                                                      document
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src:
                                                      "/assets/img/table-approve.png"
                                                  }
                                                })
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass: "option-btn",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.update(document)
                                                  }
                                                }
                                              },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src:
                                                      "/assets/img/table-deny.png"
                                                  }
                                                })
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass: "option-btn",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.updateArchived(
                                                      document
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      DEL\n                    "
                                                )
                                              ]
                                            )
                                          ]
                                        : _vm._e()
                                    ],
                                    2
                                  )
                                : _vm._e()
                            ])
                          })
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.item.accountType == "Business"
                  ? [
                      _vm.onboarding
                        ? _vm._l(_vm.item.business.documents, function(
                            document
                          ) {
                            return document.status != "Archived"
                              ? _c("tr", { key: document.number }, [
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.formatDate(
                                          document.created,
                                          "YYYY-MM-DD"
                                        )
                                      )
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(document.title))]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(document.number))]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: _vm.fileLink(
                                            document.filename,
                                            document.id
                                          ),
                                          target: "_blank"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            document.filename || "(no file)"
                                          )
                                        )
                                      ]
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(document.status))]),
                                  _vm._v(" "),
                                  !_vm.simplified
                                    ? _c(
                                        "td",
                                        [
                                          document.status == "Pending"
                                            ? [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "option-btn",
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.updateOk(
                                                          document
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("img", {
                                                      attrs: {
                                                        src:
                                                          "/assets/img/table-approve.png"
                                                      }
                                                    })
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "option-btn",
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.update(
                                                          document
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("img", {
                                                      attrs: {
                                                        src:
                                                          "/assets/img/table-deny.png"
                                                      }
                                                    })
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "option-btn",
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.updateArchived(
                                                          document
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      DEL\n                    "
                                                    )
                                                  ]
                                                )
                                              ]
                                            : _vm._e()
                                        ],
                                        2
                                      )
                                    : _vm._e()
                                ])
                              : _vm._e()
                          })
                        : _vm._l(_vm.item.business.documents, function(
                            document
                          ) {
                            return _c("tr", { key: document.number }, [
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatDate(
                                      document.created,
                                      "YYYY-MM-DD"
                                    )
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", [_vm._v(_vm._s(document.title))]),
                              _vm._v(" "),
                              _c("td", [_vm._v(_vm._s(document.number))]),
                              _vm._v(" "),
                              _c("td", [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: _vm.fileLink(
                                        document.filename,
                                        document.id
                                      ),
                                      target: "_blank"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(document.filename || "(no file)")
                                    )
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", [_vm._v(_vm._s(document.status))]),
                              _vm._v(" "),
                              !_vm.simplified
                                ? _c(
                                    "td",
                                    [
                                      document.status == "Pending"
                                        ? [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "option-btn",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.updateOk(
                                                      document
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src:
                                                      "/assets/img/table-approve.png"
                                                  }
                                                })
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass: "option-btn",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.update(document)
                                                  }
                                                }
                                              },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src:
                                                      "/assets/img/table-deny.png"
                                                  }
                                                })
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass: "option-btn",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.updateArchived(
                                                      document
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      DEL\n                    "
                                                )
                                              ]
                                            )
                                          ]
                                        : _vm._e()
                                    ],
                                    2
                                  )
                                : _vm._e()
                            ])
                          }),
                      _vm._v(" "),
                      _vm.onboarding
                        ? [
                            _vm._l(_vm.item.business.contacts, function(
                              contact
                            ) {
                              return _vm._l(contact.documents, function(
                                document
                              ) {
                                return document.status != "Archived"
                                  ? _c("tr", { key: document.number }, [
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.formatDate(
                                              document.created,
                                              "YYYY-MM-DD"
                                            )
                                          )
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(_vm._s(document.title))
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(_vm._s(document.number))
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: _vm.fileLink(
                                                document.filename,
                                                document.id
                                              ),
                                              target: "_blank"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                document.filename || "(no file)"
                                              )
                                            )
                                          ]
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(_vm._s(document.status))
                                      ]),
                                      _vm._v(" "),
                                      !_vm.simplified
                                        ? _c(
                                            "td",
                                            [
                                              document.status == "Pending"
                                                ? [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "option-btn",
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.updateOk(
                                                              document
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c("img", {
                                                          attrs: {
                                                            src:
                                                              "/assets/img/table-approve.png"
                                                          }
                                                        })
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "option-btn",
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.update(
                                                              document
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c("img", {
                                                          attrs: {
                                                            src:
                                                              "/assets/img/table-deny.png"
                                                          }
                                                        })
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "option-btn",
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.updateArchived(
                                                              document
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      DEL\n                    "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                : _vm._e()
                                            ],
                                            2
                                          )
                                        : _vm._e()
                                    ])
                                  : _vm._e()
                              })
                            })
                          ]
                        : [
                            _vm._l(_vm.item.business.contacts, function(
                              contact
                            ) {
                              return _vm._l(contact.documents, function(
                                document
                              ) {
                                return _c("tr", { key: document.number }, [
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.formatDate(
                                          document.created,
                                          "YYYY-MM-DD"
                                        )
                                      )
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(document.title))]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(document.number))]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: _vm.fileLink(
                                            document.filename,
                                            document.id
                                          ),
                                          target: "_blank"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            document.filename || "(no file)"
                                          )
                                        )
                                      ]
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(document.status))]),
                                  _vm._v(" "),
                                  !_vm.simplified
                                    ? _c(
                                        "td",
                                        [
                                          document.status == "Pending"
                                            ? [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "option-btn",
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.updateOk(
                                                          document
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("img", {
                                                      attrs: {
                                                        src:
                                                          "/assets/img/table-approve.png"
                                                      }
                                                    })
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "option-btn",
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.update(
                                                          document
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("img", {
                                                      attrs: {
                                                        src:
                                                          "/assets/img/table-deny.png"
                                                      }
                                                    })
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "option-btn",
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.updateArchived(
                                                          document
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      DEL\n                    "
                                                    )
                                                  ]
                                                )
                                              ]
                                            : _vm._e()
                                        ],
                                        2
                                      )
                                    : _vm._e()
                                ])
                              })
                            })
                          ]
                    ]
                  : _vm._e()
              ],
              2
            )
          ])
        ]
      ),
      _vm._v(" "),
      _vm.onboarding
        ? _c("div", { staticClass: "actions" }, [
            _c(
              "button",
              {
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.goBack.apply(null, arguments)
                  }
                }
              },
              [_vm._v("Back")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                attrs: { disabled: _vm.disabledStatus },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.doNext.apply(null, arguments)
                  }
                }
              },
              [_vm._v("Save & Continue")]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.portalButtons && !_vm.onboarding
        ? _c("portal", { attrs: { to: "actions" } }, [
            _c("ul", [
              _c("li", [
                _c(
                  "a",
                  {
                    attrs: { href: "" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        _vm.newFile = true
                      }
                    }
                  },
                  [_vm._v("New File")]
                )
              ])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "popup",
        {
          attrs: { open: _vm.newFile },
          on: {
            "update:open": function($event) {
              _vm.newFile = $event
            }
          }
        },
        [
          _vm.newFile
            ? _c("treasury-document-popup", {
                attrs: { newFile: _vm.newFile },
                on: {
                  "update:newFile": function($event) {
                    _vm.newFile = $event
                  },
                  "update:new-file": function($event) {
                    _vm.newFile = $event
                  },
                  reloadnotes: function($event) {
                    return _vm.$emit("reloadnotes")
                  }
                },
                model: {
                  value: _vm.item,
                  callback: function($$v) {
                    _vm.item = $$v
                  },
                  expression: "item"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "popup",
        {
          attrs: { open: _vm.statusPopup },
          on: {
            "update:open": function($event) {
              _vm.statusPopup = $event
            }
          }
        },
        [
          _c("div", { staticClass: "static-page privacy-popup" }, [
            _c("h2", [_c("strong", [_vm._v("Update Document Status")])]),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("div", { staticClass: "content-form" }, [
              _c(
                "div",
                { staticClass: "form-row form-fullw" },
                [
                  _c("form-field-select", {
                    attrs: {
                      validator: _vm.$v.selectedStatus,
                      label: "Select Document Status",
                      required: "",
                      options: _vm.statusOptions
                    },
                    model: {
                      value: _vm.selectedStatus,
                      callback: function($$v) {
                        _vm.selectedStatus = $$v
                      },
                      expression: "selectedStatus"
                    }
                  }),
                  _vm._v(" "),
                  _c("p", [_vm._v(_vm._s(_vm.selectedText))])
                ],
                1
              ),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("div", { staticClass: "form-row form-split" }, [
                _c("div", [
                  _c(
                    "button",
                    {
                      attrs: { disabled: !_vm.selectedStatus },
                      on: {
                        click: function($event) {
                          return _vm.submit()
                        }
                      }
                    },
                    [_vm._v("Submit")]
                  )
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "button",
                    {
                      on: {
                        click: function($event) {
                          ;(_vm.statusPopup = false),
                            (_vm.selectedStatus = null)
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  )
                ])
              ])
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }