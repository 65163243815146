var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", { staticClass: "page-header" }, [
    _c("h1", [_vm._v(_vm._s(_vm.title))]),
    _vm._v(" "),
    _vm.breadcrumbs
      ? _c(
          "ul",
          { staticClass: "breadcrumbs" },
          _vm._l(_vm.breadcrumbs, function(item, index) {
            return _c(
              "li",
              [
                index > 0 ? _c("i") : _vm._e(),
                _vm._v(" "),
                item.to
                  ? _c("a", { attrs: { href: item.to } }, [
                      _vm._v(_vm._s(item.text))
                    ])
                  : [_c("span", [_vm._v(_vm._s(item.text))])]
              ],
              2
            )
          }),
          0
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }