var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ul", [
        _c("li", [
          _c(
            "a",
            {
              on: {
                click: function($event) {
                  $event.preventDefault()
                  _vm.termsModal = true
                }
              }
            },
            [_vm._v("Terms of Service")]
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _c(
            "a",
            {
              on: {
                click: function($event) {
                  $event.preventDefault()
                  _vm.privacyModal = true
                }
              }
            },
            [_vm._v("Privacy Policy")]
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "popup",
        {
          attrs: { open: _vm.termsModal },
          on: {
            "update:open": function($event) {
              _vm.termsModal = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "privacy-popup scrollable" },
            [_c("terms-popup-footer")],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "popup",
        {
          attrs: { open: _vm.privacyModal },
          on: {
            "update:open": function($event) {
              _vm.privacyModal = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "privacy-popup scrollable" },
            [_c("privacy-popup-footer")],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }