var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "merchant-panel" }, [
    _c("div", { staticClass: "data-content" }, [
      _c("ul", { staticClass: "tabs" }, [
        _c(
          "li",
          {
            class: { active: _vm.dataTab == 0 },
            on: {
              click: function($event) {
                _vm.dataTab = 0
              }
            }
          },
          [_vm._v("General")]
        ),
        _vm._v(" "),
        _c(
          "li",
          {
            class: { active: _vm.dataTab == 1 },
            on: {
              click: function($event) {
                _vm.dataTab = 1
              }
            }
          },
          [_vm._v("Business")]
        ),
        _vm._v(" "),
        _c(
          "li",
          {
            class: { active: _vm.dataTab == 2 },
            on: {
              click: function($event) {
                _vm.dataTab = 2
              }
            }
          },
          [_vm._v("Ownership")]
        ),
        _vm._v(" "),
        _c(
          "li",
          {
            class: { active: _vm.dataTab == 3 },
            on: {
              click: function($event) {
                _vm.dataTab = 3
              }
            }
          },
          [_vm._v("Website")]
        ),
        _vm._v(" "),
        _c(
          "li",
          {
            class: { active: _vm.dataTab == 4 },
            on: {
              click: function($event) {
                _vm.dataTab = 4
              }
            }
          },
          [_vm._v("Business details")]
        ),
        _vm._v(" "),
        _c(
          "li",
          {
            class: { active: _vm.dataTab == 5 },
            on: {
              click: function($event) {
                _vm.dataTab = 5
              }
            }
          },
          [_vm._v("Additional Notes")]
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "main" },
        [
          _c("formview-pre-main", {
            attrs: {
              item: _vm.item,
              tab: _vm.dataTab,
              notes: _vm.notes,
              editMode: _vm.editMode
            },
            on: {
              "update:editMode": function($event) {
                _vm.editMode = $event
              },
              "update:edit-mode": function($event) {
                _vm.editMode = $event
              }
            }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("aside", { staticClass: "actions" }, [
      _c("ul", { staticClass: "tabs" }, [
        _c(
          "li",
          {
            class: { active: _vm.actionsTab == 0 },
            on: {
              click: function($event) {
                _vm.actionsTab = 0
              }
            }
          },
          [_vm._v("General")]
        ),
        _vm._v(" "),
        _c(
          "li",
          {
            class: { active: _vm.actionsTab == 1 },
            on: {
              click: function($event) {
                _vm.actionsTab = 1
              }
            }
          },
          [_vm._v("Application")]
        ),
        _vm._v(" "),
        _c(
          "li",
          {
            class: { active: _vm.actionsTab == 2 },
            on: {
              click: function($event) {
                _vm.actionsTab = 2
              }
            }
          },
          [_vm._v("Documents")]
        ),
        _vm._v(" "),
        _c(
          "li",
          {
            class: { active: _vm.actionsTab == 3 },
            on: {
              click: function($event) {
                _vm.actionsTab = 3
              }
            }
          },
          [_vm._v("Compliance")]
        ),
        _vm._v(" "),
        _c(
          "li",
          {
            class: { active: _vm.actionsTab == 5 },
            on: {
              click: function($event) {
                _vm.actionsTab = 5
              }
            }
          },
          [_vm._v("Email")]
        )
      ]),
      _vm._v(" "),
      _vm.actionsTab == 0
        ? _c(
            "div",
            { staticClass: "main" },
            [
              _c("div", { staticClass: "action-buttons" }, [
                _c(
                  "a",
                  {
                    attrs: { href: "" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        _vm.listChecklist()
                        _vm.showFinalApproval = false
                      }
                    }
                  },
                  [_vm._v("Setup Checklist")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    attrs: { href: "" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        _vm.uwTab = 2
                        _vm.showFinalApproval = false
                      }
                    }
                  },
                  [_vm._v("Status Update")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    class: { disabled: _vm.editMode },
                    attrs: { href: "" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        _vm.editMode = true
                      }
                    }
                  },
                  [_vm._v("Edit")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    attrs: { href: "" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.listPreChecks.apply(null, arguments)
                      }
                    }
                  },
                  [_vm._v("Pre-Checks")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    attrs: { href: "" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                      }
                    }
                  },
                  [_vm._v("Negative List")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    attrs: { href: "" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                      }
                    }
                  },
                  [_vm._v("Follow-up")]
                )
              ]),
              _vm._v(" "),
              _vm.uwTab == 2
                ? _c("div", [
                    _c(
                      "div",
                      { staticClass: "action-sub-buttons" },
                      [
                        _vm._l(_vm.allActions, function(optLabel, optMethod) {
                          return _c(
                            "a",
                            {
                              class: {
                                disabled: _vm.isDisabledAction(optMethod)
                              },
                              attrs: { href: "" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  !_vm.isDisabledAction(optMethod) &&
                                    _vm.doAction(_vm.item, optMethod)
                                }
                              }
                            },
                            [_vm._v(_vm._s(optLabel))]
                          )
                        }),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "disabled",
                            attrs: { href: "" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                              }
                            }
                          },
                          [_vm._v("Hold Account")]
                        ),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "disabled",
                            attrs: { href: "" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                              }
                            }
                          },
                          [_vm._v("Close Account")]
                        )
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("table", { staticClass: "file-list content-table" }, [
                      _vm._m(0),
                      _vm._v(" "),
                      _c(
                        "tbody",
                        _vm._l(_vm.notes, function(uwItem) {
                          return uwItem.system &&
                            uwItem.message.indexOf("Updated to ") == 0
                            ? _c("tr", [
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatDate(
                                        uwItem.created,
                                        "YYYY-MM-DD h:mm a"
                                      )
                                    )
                                  )
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(_vm._s(uwItem.message.substring(11)))
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(_vm._s(uwItem.author.username))
                                ])
                              ])
                            : _vm._e()
                        }),
                        0
                      )
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.uwTab == 3
                ? _c("view-preq-setupcl", {
                    key: "setupcl",
                    attrs: { prechecks: _vm.uwList, weblist: _vm.webList }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.uwTab == 1
                ? _c("view-preq-precheck", {
                    key: "precheck",
                    attrs: { uwList: _vm.uwList, preq: true }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm.actionsTab == 1
        ? _c("div", { staticClass: "main" }, [
            _c(
              "div",
              {
                staticClass: "file-field",
                staticStyle: { "margin-top": "8px" }
              },
              [
                _c("label", [_vm._v("Pre-Qualification form:")]),
                _vm._v(" "),
                _c("a", { attrs: { href: _vm.pdfUrl, target: "_blank" } }, [
                  _vm._v(
                    _vm._s(_vm.item.applicationId + "-" + _vm.item.companyName)
                  )
                ])
              ]
            )
          ])
        : _vm.actionsTab == 2
        ? _c("div", { staticClass: "main" }, [
            _c("div", { staticClass: "file-field" }, [
              _c("label", [_vm._v("Processing statement:")]),
              _vm._v(" "),
              _c(
                "a",
                {
                  attrs: {
                    href: _vm.fileLink(_vm.item.fileStatement),
                    target: "_blank"
                  }
                },
                [_vm._v(_vm._s(_vm.item.fileStatement || "(no file)"))]
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.actionsTab == 3
        ? _c(
            "div",
            { staticClass: "main" },
            [
              _c("div", { staticClass: "action-buttons" }, [
                _c(
                  "a",
                  {
                    attrs: { href: "" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.listWebsiteChecks.apply(null, arguments)
                      }
                    }
                  },
                  [_vm._v("Website Check")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "disabled",
                    attrs: { href: "" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                      }
                    }
                  },
                  [_vm._v("Doc-Checks")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "disabled",
                    attrs: { href: "" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                      }
                    }
                  },
                  [_vm._v("Match/VMAS")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "disabled",
                    attrs: { href: "" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                      }
                    }
                  },
                  [_vm._v("OFAC")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "disabled",
                    attrs: { href: "" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                      }
                    }
                  },
                  [_vm._v("KYC")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "disabled",
                    attrs: { href: "" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                      }
                    }
                  },
                  [_vm._v("AML")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    class: { disabled: _vm.item.status != "A00" },
                    attrs: { href: "" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                      }
                    }
                  },
                  [_vm._v("IDM")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    class: { disabled: _vm.item.status != "A00" },
                    attrs: { href: "" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.listG2.apply(null, arguments)
                      }
                    }
                  },
                  [_vm._v("G2")]
                )
              ]),
              _vm._v(" "),
              _vm.uwTab == 2
                ? _c("view-app-websitecheck", {
                    key: "websitecheck",
                    attrs: { uwList: _vm.webList, preq: true }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.uwTab == 10
                ? _c("view-app-extcheck", {
                    key: "extcheckg2",
                    attrs: { type: "g2", uwList: _vm.checkList, preq: true }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.actionsTab == 4
        ? _c("div", { staticClass: "main" }, [
            _c("div", { staticClass: "action-buttons" }, [
              _c(
                "a",
                {
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [_vm._v("Modules")]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [_vm._v("Gateway")]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [_vm._v("Financial Setup")]
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.actionsTab == 5
        ? _c("div", { staticClass: "main" }, [
            _c("div", { staticClass: "action-buttons" }, [
              _c(
                "a",
                {
                  class: { disabled: _vm.item.status != "A01" },
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.item.status == "A01" && _vm.sendEmail("PreApproved")
                    }
                  }
                },
                [_vm._v("Pre-Approval")]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  class: { disabled: !_vm.appReminderAvailable },
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.appReminderAvailable &&
                        _vm.sendEmail("ApplicationReminder")
                    }
                  }
                },
                [_vm._v("Application Reminder")]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "disabled",
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [_vm._v("Applications")]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "disabled",
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [_vm._v("Declined")]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "disabled",
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [_vm._v("Agreements")]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "disabled",
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [_vm._v("Approved")]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "disabled",
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [_vm._v("Merchant Welcome")]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "disabled",
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [_vm._v("Gateway Info")]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "disabled",
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [_vm._v("Config Setup")]
              )
            ]),
            _vm._v(" "),
            _c("table", { staticClass: "file-list content-table" }, [
              _vm._m(1),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.emails, function(email) {
                  return _c("tr", [
                    _c("td", [_vm._v(_vm._s(email.emailType))]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          _vm.formatDate(email.created, "YYYY-MM-DD hh:mm:ss")
                        )
                      )
                    ])
                  ])
                }),
                0
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.actionsTab == 6
        ? _c("div", { staticClass: "main" }, [
            _c("div", { staticClass: "action-buttons" }, [
              _c(
                "a",
                {
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [_vm._v("Generate New")]
              )
            ])
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Date")]),
        _vm._v(" "),
        _c("th", [_vm._v("Status Update")]),
        _vm._v(" "),
        _c("th", [_vm._v("Updated By")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Type")]),
        _vm._v(" "),
        _c("th", [_vm._v("Created")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }