var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h2", { staticClass: "doc-title" }, [
      _vm._v("FRAUD RISK POLICY RECEIPT")
    ]),
    _vm._v(" "),
    _c("p", [
      _vm._v(
        "I, the undersigned Company Staff or consultant, certify that I have received the confidential Company Policy, either in paper or electronic form."
      )
    ]),
    _vm._v(" "),
    _c("p", [
      _vm._v(
        "I undertake to promptly return a copy of this receipt bearing my original signature in paper form or scanned as an email PDF to the Compliance Officer of the Company for record-retention purposes within three (3) days of my receipt of this Policy. "
      )
    ]),
    _vm._v(" "),
    _c("p", [
      _vm._v(
        "I further acknowledge that I will not appropriate, disclose, or make any unauthorized use of, or copy this Policy for the benefit of any third-party entity or for use outside of my job duties. I fully understand this Policy is the confidential, intellectual, and proprietary property of the Company."
      )
    ]),
    _vm._v(" "),
    _c("p", [
      _vm._v(
        "I undertake to read the Policy in full and to ensure that I understand all of its provisions. I understand I am to receive training where these policies and procedures, and the Company’s risk mitigation procedures related to the potential Money Laundering, Terrorist Financing or Fraud threats and how they relate to the Company’s products and services, thereby ensuring that I can conduct the tasks and duties I have been assigned to complete. I will comply with and assist in the enforcement of the provisions of the Policy in a manner that is consistent with my duties to the Company."
      )
    ]),
    _vm._v(" "),
    _c("p", [
      _vm._v(
        "I understand that after I have read this AML, ATF, Fraud and Risk Policy, I will address any questions that I may have regarding the Policy’s interpretation or application with the Compliance Officer or bring my questions to my training session to ensure I am clear on what my responsibilities are and that I comfortably understand the context of this Policy. I will furthermore keep up to date on all changes to this Policy. "
      )
    ]),
    _vm._v(" "),
    _c("p", [
      _vm._v(
        "I understand that failure to maintain the confidentiality of this Policy is a violation that will be grounds for termination with cause and that the Company will be within its rights to take legal and equitable action against me."
      )
    ]),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _c(
      "form",
      {
        staticClass: "search-form",
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.save()
          }
        }
      },
      [
        _c("div", { staticClass: "field" }, [
          _c("label", [_vm._v("PRINTED NAME OF EMPLOYEE:")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field-group" },
            [
              _c(
                "validator",
                {
                  staticClass: "w-half",
                  attrs: { value: _vm.form.first_name, reqs: "required" }
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.first_name,
                        expression: "form.first_name"
                      }
                    ],
                    staticClass: "w100",
                    attrs: {
                      type: "text",
                      placeholder: "First Name",
                      maxlength: "30"
                    },
                    domProps: { value: _vm.form.first_name },
                    on: {
                      keypress: function($event) {
                        return _vm.allowLetterNumber($event)
                      },
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.form, "first_name", $event.target.value)
                      }
                    }
                  })
                ]
              ),
              _vm._v(" "),
              _c(
                "validator",
                {
                  staticClass: "w-half",
                  attrs: { value: _vm.form.last_name, reqs: "required" }
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.last_name,
                        expression: "form.last_name"
                      }
                    ],
                    staticClass: "w100",
                    attrs: {
                      type: "text",
                      placeholder: "Last Name",
                      maxlength: "30"
                    },
                    domProps: { value: _vm.form.last_name },
                    on: {
                      keypress: function($event) {
                        return _vm.allowLetterNumber($event)
                      },
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.form, "last_name", $event.target.value)
                      }
                    }
                  })
                ]
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "field" },
          [
            _c("label", [_vm._v("PRINTED EMAIL:")]),
            _vm._v(" "),
            _c(
              "validator",
              {
                staticClass: "field-group",
                attrs: { value: _vm.form.email, reqs: "required email" }
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.email,
                      expression: "form.email"
                    }
                  ],
                  staticClass: "w100",
                  attrs: { type: "email" },
                  domProps: { value: _vm.form.email },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.form, "email", $event.target.value)
                    }
                  }
                })
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "field" },
          [
            _c("label", [_vm._v("PRINTED DEPARTMENT NAME:")]),
            _vm._v(" "),
            _c(
              "validator",
              {
                staticClass: "field-group",
                attrs: { value: _vm.form.department, reqs: "required" }
              },
              [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.department,
                        expression: "form.department"
                      }
                    ],
                    staticClass: "w100",
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.form,
                          "department",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      }
                    }
                  },
                  _vm._l(_vm.userGroups, function(opt, key) {
                    return _c(
                      "option",
                      { key: key, domProps: { value: key } },
                      [_vm._v(_vm._s(opt))]
                    )
                  }),
                  0
                )
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "field" },
          [
            _c("label", [_vm._v("DATE RECEIVED:")]),
            _vm._v(" "),
            _c(
              "validator",
              {
                staticClass: "field-group",
                attrs: { value: _vm.form.date, reqs: "required" }
              },
              [
                _c("datepicker", {
                  staticClass: "w100",
                  attrs: {
                    type: "text",
                    future: false,
                    minDate: _vm.beforeYesterday
                  },
                  model: {
                    value: _vm.form.date,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "date", $$v)
                    },
                    expression: "form.date"
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        !_vm.hideButtons
          ? _c("div", { staticClass: "buttons" }, [
              _c(
                "button",
                { staticClass: "btn btn-small", attrs: { type: "submit" } },
                [_vm._v("Submit")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-small",
                  on: {
                    click: function($event) {
                      return _vm.clear()
                    }
                  }
                },
                [_vm._v("Clear")]
              )
            ])
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field" }, [
      _c("label", [_vm._v("SIGNATURE:")]),
      _vm._v(" "),
      _c("span", { staticClass: "signature-line" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }