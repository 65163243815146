var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "tabs",
        [
          _c("tab", {
            attrs: { title: "Profile", active: _vm.open === 0 },
            on: {
              click: function($event) {
                _vm.open = 0
              }
            }
          }),
          _vm._v(" "),
          _c("tab", {
            attrs: { title: "Users", active: _vm.open === 0 },
            on: {
              click: function($event) {
                _vm.open = 1
              }
            }
          }),
          _vm._v(" "),
          _c("tab", {
            attrs: { title: "Login activity", active: _vm.open === 1 },
            on: {
              click: function($event) {
                _vm.open = 2
              }
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("ul", { staticClass: "up-acc" }, [
        _vm.open === 0
          ? _c("li", { staticClass: "open" }, [
              _c("div", { staticClass: "title" }, [_vm._v("Merchant Profile")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _c("div", { staticClass: "general" }, [
                  _c("div", { staticClass: "uinfo" }, [
                    _c("ul", { staticClass: "ui-prop" }, [
                      _c("li", [
                        _c("label", [_vm._v("Username")]),
                        _vm._v(" "),
                        _c("div", [_vm._v(_vm._s(_vm.profile.username))])
                      ]),
                      _vm._v(" "),
                      _vm._m(0),
                      _vm._v(" "),
                      _vm._m(1)
                    ]),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "tool-btn",
                        attrs: { href: "" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.resetPassword.apply(null, arguments)
                          }
                        }
                      },
                      [_vm._v("Reset password")]
                    )
                  ]),
                  _vm._v(" "),
                  _c("aside", [
                    _vm.manager.email
                      ? _c("div", [
                          _c("label", [_vm._v("Account Manager:")]),
                          _vm._v(
                            " " +
                              _vm._s(_vm.manager.fullName) +
                              "\n            "
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.manager.email
                      ? _c("div", [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.manager.email) +
                              ", " +
                              _vm._s(_vm.manager.phone) +
                              ",\n              " +
                              _vm._s(
                                _vm.manager.phoneExt
                                  ? "ext " + _vm.manager.phoneExt
                                  : ""
                              ) +
                              "\n            "
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", [
                      _c("label", [_vm._v("AccountID #:")]),
                      _vm._v(" " + _vm._s(_vm.profile.agentId))
                    ]),
                    _vm._v(" "),
                    _vm.profile.supportPin
                      ? _c("div", [
                          _c("label", [_vm._v("PIN:")]),
                          _vm._v(
                            " " +
                              _vm._s(_vm.profile.supportPin) +
                              "\n            "
                          )
                        ])
                      : _vm._e()
                  ])
                ]),
                _vm._v(" "),
                _c("ul", { staticClass: "mo-tabs" }, [
                  _c("li", { class: { active: _vm.tab == 0 } }, [
                    _c(
                      "a",
                      {
                        attrs: { href: "" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            _vm.tab = 0
                          }
                        }
                      },
                      [_vm._v("General")]
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", { class: { active: _vm.tab == 1 } }, [
                    _c(
                      "a",
                      {
                        attrs: { href: "" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            _vm.tab = 1
                          }
                        }
                      },
                      [_vm._v("Company")]
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", { class: { active: _vm.tab == 2 } }, [
                    _c(
                      "a",
                      {
                        attrs: { href: "" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            _vm.tab = 2
                          }
                        }
                      },
                      [_vm._v("Business")]
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", { class: { active: _vm.tab == 3 } }, [
                    _c(
                      "a",
                      {
                        attrs: { href: "" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            _vm.tab = 3
                          }
                        }
                      },
                      [_vm._v("Banking")]
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", { class: { active: _vm.tab == 4 } }, [
                    _c(
                      "a",
                      {
                        attrs: { href: "" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            _vm.tab = 4
                          }
                        }
                      },
                      [_vm._v("Contacts")]
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", { class: { active: _vm.tab == 5 } }, [
                    _c(
                      "a",
                      {
                        attrs: { href: "" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            _vm.tab = 5
                          }
                        }
                      },
                      [_vm._v("Terminal IDs")]
                    )
                  ])
                ]),
                _vm._v(" "),
                _vm.tab == 0
                  ? _c("ul", { staticClass: "mo-info" }, [
                      _c("li", [
                        _c("ul", [
                          _c("li", [
                            _c("label", [_vm._v("Approval Date:")]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(
                                    _vm.formatDate(
                                      _vm.application.approveDate ||
                                        _vm.application.submitDate,
                                      "YYYY-MM-DD"
                                    )
                                  ) +
                                  "\n                "
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v("Customer Service Email:")]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(_vm._s(_vm.application.emailService))
                            ])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v("Account ID:")]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(_vm._s(_vm.application.accountId))
                            ])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v("Customer Service Phone:")]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(_vm._s(_vm.application.phoneService))
                            ])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v("Account Class:")]),
                            _vm._v(" "),
                            _c("div", [_vm._v(_vm._s(""))])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v("Descriptor:")]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(_vm._s(_vm.application.descriptor1))
                            ])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v("Account Manager:")]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(
                                    _vm.application.manager &&
                                      (_vm.application.manager.fullName ||
                                        _vm.application.manager.companyName)
                                  ) +
                                  "\n                "
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v("Industry:")]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(_vm._s(_vm.application.industry))
                            ])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v("Source / Agent:")]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(
                                    _vm.application.source &&
                                      (_vm.application.source.fullName ||
                                        _vm.application.source.companyName)
                                  ) +
                                  "\n                "
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v("Gateway:")]),
                            _vm._v(" "),
                            _c("div", [_vm._v(_vm._s(""))])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v("MCC:")]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  (_vm.application.mcc || "").replace(/ .*/, "")
                                )
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v("Gateway Merchant ID:")]),
                            _vm._v(" "),
                            _c("div", [_vm._v(_vm._s(""))])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v("Merchant Type:")]),
                            _vm._v(" "),
                            _c("div", [_vm._v(_vm._s(""))])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v("Gateway Status:")]),
                            _vm._v(" "),
                            _c("div", [_vm._v(_vm._s(""))])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v(" ")]),
                            _vm._v(" "),
                            _c("div", [_vm._v(_vm._s(""))])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v("Acquirer:")]),
                            _vm._v(" "),
                            _c("div", [_vm._v(_vm._s(""))])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v(" ")]),
                            _vm._v(" "),
                            _c("div", [_vm._v(_vm._s(""))])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v("Acquirer Merchant ID:")]),
                            _vm._v(" "),
                            _c("div", [_vm._v(_vm._s(""))])
                          ])
                        ])
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.tab == 1
                  ? _c("ul", { staticClass: "mo-info" }, [
                      _c("li", [
                        _c("ul", [
                          _c("li", [
                            _c("label", [_vm._v("Company Name:")]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(_vm._s(_vm.application.companyName))
                            ])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v("City, State, Zip, Country:")]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(_vm.application.companyAddressCity) +
                                  ",\n                  " +
                                  _vm._s(_vm.application.companyAddressState) +
                                  ",\n                  " +
                                  _vm._s(
                                    _vm.application.companyAddressPostcode
                                  ) +
                                  ",\n                  " +
                                  _vm._s(
                                    _vm.application.companyAddressCountry
                                  ) +
                                  "\n                "
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v("Trade Name:")]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(_vm._s(_vm.application.companyNameTrade))
                            ])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v("Phone:")]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(_vm._s(_vm.application.companyPhone))
                            ])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v("Address:")]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(_vm._s(_vm.application.companyAddress))
                            ])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v("E-mail:")]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(_vm._s(_vm.application.companyEmail))
                            ])
                          ])
                        ])
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.tab == 2
                  ? _c("ul", { staticClass: "mo-info" }, [
                      _c("li", [
                        _c("ul", [
                          _c("li", [
                            _c("label", [_vm._v("Business:")]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(_vm._s(_vm.application.companyName))
                            ])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v("Descriptor:")]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(_vm._s(_vm.application.descriptor1))
                            ])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v("Website:")]),
                            _vm._v(" "),
                            _c("div", [_vm._v(_vm._s(_vm.application.website))])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v("CS Email:")]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(_vm._s(_vm.application.emailService))
                            ])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v("Beta website:")]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(_vm._s(_vm.application.websiteBeta))
                            ])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v("CS Phone:")]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(_vm._s(_vm.application.phoneService))
                            ])
                          ])
                        ])
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.tab == 3
                  ? _c("ul", { staticClass: "mo-info" }, [
                      _c("li", [
                        _c("ul", [
                          _c("li", [
                            _c("label", [_vm._v("Settlement Currency:")]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm.application.accounts[0].accountCurrency
                                )
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v("SWIFT:")]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(
                                _vm._s(_vm.application.accounts[0].accountSwift)
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v("Bank Name:")]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(
                                _vm._s(_vm.application.accounts[0].bankName)
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v("IBAN:")]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(
                                _vm._s(_vm.application.accounts[0].accountIBAN)
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v("Account Name:")]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm.application.accounts[0].accountHolder
                                )
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v("Bank Code:")]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm.application.accounts[0].accountBankCode
                                )
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v("Account Number:")]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm.application.accounts[0].accountNumber
                                )
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v("Routing:")]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm.application.accounts[0].accountRouting
                                )
                              )
                            ])
                          ])
                        ])
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.tab == 4
                  ? _c("ul", { staticClass: "mo-info" }, [
                      _c("li", [
                        _c("ul", [
                          _c("li", [
                            _c("label", [_vm._v("Primary:")]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(
                                    _vm.application.contacts[0].firstName
                                  ) +
                                  "\n                  " +
                                  _vm._s(_vm.application.contacts[0].lastName) +
                                  ",\n                  " +
                                  _vm._s(_vm.application.contacts[0].phone) +
                                  ",\n                  " +
                                  _vm._s(_vm.application.contacts[0].email) +
                                  "\n                "
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v("Financial:")]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(
                                    _vm.application.contacts[1].firstName
                                  ) +
                                  "\n                  " +
                                  _vm._s(_vm.application.contacts[1].lastName) +
                                  ",\n                  " +
                                  _vm._s(_vm.application.contacts[1].phone) +
                                  ",\n                  " +
                                  _vm._s(_vm.application.contacts[1].email) +
                                  "\n                "
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v("Technical:")]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(
                                    _vm.application.contacts[2].firstName
                                  ) +
                                  "\n                  " +
                                  _vm._s(_vm.application.contacts[2].lastName) +
                                  ",\n                  " +
                                  _vm._s(_vm.application.contacts[2].phone) +
                                  ",\n                  " +
                                  _vm._s(_vm.application.contacts[2].email) +
                                  "\n                "
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v("Risk:")]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(
                                    _vm.application.contacts[2].firstName
                                  ) +
                                  "\n                  " +
                                  _vm._s(_vm.application.contacts[2].lastName) +
                                  ",\n                  " +
                                  _vm._s(_vm.application.contacts[2].phone) +
                                  ",\n                  " +
                                  _vm._s(_vm.application.contacts[2].email) +
                                  "\n                "
                              )
                            ])
                          ])
                        ])
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.tab == 5 ? _c("ul", {}, [_vm._m(2)]) : _vm._e()
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.open === 1
          ? _c("li", { staticClass: "open" }, [
              _c("div", { staticClass: "title" }, [_vm._v("Users")]),
              _vm._v(" "),
              _c("div", { staticClass: "acc-list" }, [
                _c("table", {}, [
                  _vm._m(3),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    [
                      _vm._l(_vm.users, function(item, index) {
                        return _c("tr", { key: item.id }, [
                          _c("td", { staticClass: "text-left" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(item.username) +
                                "\n              "
                            )
                          ]),
                          _vm._v(" "),
                          _c("td", {}, [
                            _vm._v(
                              "\n                " +
                                _vm._s(item.id) +
                                "\n              "
                            )
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "text-right" }, [
                            _vm.profile != item && _vm.mainUser
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "block",
                                    attrs: { href: "" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.blockUser(index)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        item.active == "Blocked"
                                          ? "Unblock"
                                          : "Block"
                                      )
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.profile != item && _vm.mainUser
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "edit",
                                    attrs: { href: "" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.editUser(index)
                                      }
                                    }
                                  },
                                  [_vm._v("Edit")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.profile != item && _vm.mainUser
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "edit",
                                    attrs: { href: "" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.resetPwUser(index)
                                      }
                                    }
                                  },
                                  [_vm._v("Reset Password")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.profile != item && _vm.mainUser
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "remove",
                                    attrs: { href: "" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.deleteSubuser(index)
                                      }
                                    }
                                  },
                                  [_vm._v("Delete")]
                                )
                              : _vm._e()
                          ])
                        ])
                      }),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", { attrs: { colspan: "3" } }, [
                          _vm.mainUser
                            ? _c(
                                "a",
                                {
                                  attrs: { href: "" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.newUser.apply(null, arguments)
                                    }
                                  }
                                },
                                [_vm._v("New user")]
                              )
                            : _vm._e()
                        ])
                      ])
                    ],
                    2
                  )
                ])
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.open === 2
          ? _c("li", { staticClass: "open" }, [
              _c("div", { staticClass: "title" }, [_vm._v("Login Activity")]),
              _vm._v(" "),
              _c("div", { staticClass: "acc-list" }, [
                _c("table", {}, [
                  _vm._m(4),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.logins, function(item, index) {
                      return _c("tr", [
                        _c("td", { staticClass: "main-col" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.usersById[item.userId].username) +
                              "\n              "
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", {}, [
                          _vm._v(
                            "\n                " +
                              _vm._s(item.recorded) +
                              "\n              "
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", {}, [
                          _vm._v(
                            "\n                " +
                              _vm._s(item.ipAddress) +
                              "\n              "
                          )
                        ])
                      ])
                    }),
                    0
                  )
                ])
              ])
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("label", [_vm._v("Services")]),
      _vm._v(" "),
      _c("div", [_vm._v("-")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("label", [_vm._v("Plan")]),
      _vm._v(" "),
      _c("div", [_vm._v("-")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("ul", { staticClass: "acc-results opt-lists linkcolor" }, [
        _c("li", {}, [
          _c("h4", { staticClass: "osb-btns" }, [
            _c("span", { staticClass: "label" }, [_vm._v("TIDS")]),
            _vm._v(" "),
            _c("select", [
              _c("option", [_vm._v("Available")]),
              _vm._v(" "),
              _c("option", [_vm._v("Assigned")]),
              _vm._v(" "),
              _c("option", [_vm._v("Activated")]),
              _vm._v(" "),
              _c("option", [_vm._v("Closed")])
            ]),
            _vm._v(" "),
            _c("button", [_vm._v("Request TID")])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "acc-list", staticStyle: { display: "block" } },
            [
              _c("table", [
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v("Account ID")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("TID")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("Status")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("Date")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("Owner")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("Options")])
                  ])
                ]),
                _vm._v(" "),
                _c("tbody", [
                  _c("tr", [
                    _c("td", [_vm._v("501038")]),
                    _vm._v(" "),
                    _c("td", [
                      _c("a", { attrs: { href: "" } }, [_vm._v("AB1234")])
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v("Active")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("September 4, 2019")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("John Smith")]),
                    _vm._v(" "),
                    _c("td", { staticClass: "osb-btns" }, [
                      _c("a", { attrs: { href: "" } }, [_vm._v("Eval")]),
                      _c("a", { attrs: { href: "" } }, [_vm._v("Submit")])
                    ])
                  ])
                ])
              ])
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _c("ul", { staticClass: "acc-results opt-lists linkcolor" }, [
        _c("li", {}, [
          _c("h4", { staticClass: "osb-btns" }, [
            _c("span", { staticClass: "label" }, [_vm._v("KEYS")]),
            _vm._v(" "),
            _c("select", [
              _c("option", [_vm._v("Available")]),
              _vm._v(" "),
              _c("option", [_vm._v("Assigned")]),
              _vm._v(" "),
              _c("option", [_vm._v("Activated")]),
              _vm._v(" "),
              _c("option", [_vm._v("Closed")])
            ]),
            _vm._v(" "),
            _c("button", [_vm._v("Request KEY")])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "acc-list", staticStyle: { display: "block" } },
            [
              _c("table", [
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v("Account ID")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("Key")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("Status")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("Date")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("IP")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("Options")])
                  ])
                ]),
                _vm._v(" "),
                _c("tbody", [
                  _c("tr", [
                    _c("td", [_vm._v("501038")]),
                    _vm._v(" "),
                    _c("td", [
                      _c("a", { attrs: { href: "" } }, [
                        _vm._v("NmdtMk5FYndaVjNCSXQ1R3")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v("Active")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("September 4, 2019")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("172.33.222.68")]),
                    _vm._v(" "),
                    _c("td", { staticClass: "osb-btns" }, [
                      _c("a", { attrs: { href: "" } }, [_vm._v("Eval")]),
                      _c("a", { attrs: { href: "" } }, [_vm._v("Submit")])
                    ])
                  ])
                ])
              ])
            ]
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticStyle: { width: "40%" } }, [_vm._v("Username")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "10%" } }, [_vm._v("ID")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "20%" } }, [_vm._v("Actions")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticStyle: { width: "33%" } }, [_vm._v("Username")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "33%" } }, [_vm._v("Time")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "34%" } }, [_vm._v("IP Address")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }