var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form", { staticClass: "edit-system search-form" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "twocol" }, [
      _c("div", [
        _c("h4", { staticClass: "settings-text" }, [_vm._v("Settings")]),
        _vm._v(" "),
        _c("h5", { staticClass: "settings-subtitle" }, [_vm._v("General")]),
        _vm._v(" "),
        _c("div", { staticClass: "services-input-group" }, [
          _c("label", [_vm._v("Business Services")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "switch" },
            [
              _c("mnb-switch", {
                attrs: { disabled: !_vm.editing },
                model: {
                  value: _vm.form.settings.general.businessServices,
                  callback: function($$v) {
                    _vm.$set(_vm.form.settings.general, "businessServices", $$v)
                  },
                  expression: "form.settings.general.businessServices"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "services-input-group" }, [
          _c("label", [_vm._v("Custom Fees")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "switch" },
            [
              _c("mnb-switch", {
                attrs: { disabled: !_vm.editing },
                model: {
                  value: _vm.form.settings.general.customFees,
                  callback: function($$v) {
                    _vm.$set(_vm.form.settings.general, "customFees", $$v)
                  },
                  expression: "form.settings.general.customFees"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "services-input-group" }, [
          _c("label", [_vm._v("Compliance")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "switch" },
            [
              _c("mnb-switch", {
                attrs: { disabled: !_vm.editing },
                model: {
                  value: _vm.form.settings.general.compliance,
                  callback: function($$v) {
                    _vm.$set(_vm.form.settings.general, "compliance", $$v)
                  },
                  expression: "form.settings.general.compliance"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "services-input-group" }, [
          _c("label", { staticClass: "pl-20" }, [_vm._v("Boarding")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "switch" },
            [
              _c("mnb-switch", {
                attrs: {
                  disabled:
                    !_vm.form.settings.general.compliance || !_vm.editing
                },
                model: {
                  value: _vm.form.settings.general.boarding,
                  callback: function($$v) {
                    _vm.$set(_vm.form.settings.general, "boarding", $$v)
                  },
                  expression: "form.settings.general.boarding"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "services-input-group" }, [
          _c("label", { staticClass: "pl-20" }, [_vm._v("Monitoring")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "switch" },
            [
              _c("mnb-switch", {
                attrs: {
                  disabled:
                    !_vm.form.settings.general.compliance || !_vm.editing
                },
                model: {
                  value: _vm.form.settings.general.monitoring,
                  callback: function($$v) {
                    _vm.$set(_vm.form.settings.general, "monitoring", $$v)
                  },
                  expression: "form.settings.general.monitoring"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "services-input-group" }, [
          _c("label", [_vm._v("Sandbox")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "switch" },
            [
              _c("mnb-switch", {
                attrs: { disabled: !_vm.editing },
                model: {
                  value: _vm.form.settings.general.sandbox,
                  callback: function($$v) {
                    _vm.$set(_vm.form.settings.general, "sandbox", $$v)
                  },
                  expression: "form.settings.general.sandbox"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "services-input-group" }, [
          _c("label", [_vm._v("Invoicing")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "switch" },
            [
              _c("mnb-switch", {
                attrs: { disabled: !_vm.editing },
                model: {
                  value: _vm.form.settings.general.invoicing,
                  callback: function($$v) {
                    _vm.$set(_vm.form.settings.general, "invoicing", $$v)
                  },
                  expression: "form.settings.general.invoicing"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "services-input-group" }, [
          _c("label", [_vm._v("MailCast")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "switch" },
            [
              _c("mnb-switch", {
                attrs: { disabled: !_vm.editing },
                model: {
                  value: _vm.form.settings.general.mailCast,
                  callback: function($$v) {
                    _vm.$set(_vm.form.settings.general, "mailCast", $$v)
                  },
                  expression: "form.settings.general.mailCast"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "services-input-group" }, [
          _c("label", [_vm._v("Scheduler")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "switch" },
            [
              _c("mnb-switch", {
                attrs: { disabled: !_vm.editing },
                model: {
                  value: _vm.form.settings.general.scheduler,
                  callback: function($$v) {
                    _vm.$set(_vm.form.settings.general, "scheduler", $$v)
                  },
                  expression: "form.settings.general.scheduler"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "services-input-group" }, [
          _c("label", [_vm._v("Themes")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "switch" },
            [
              _c("mnb-switch", {
                attrs: { disabled: !_vm.editing },
                model: {
                  value: _vm.form.settings.general.themes,
                  callback: function($$v) {
                    _vm.$set(_vm.form.settings.general, "themes", $$v)
                  },
                  expression: "form.settings.general.themes"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("h5", { staticClass: "settings-subtitle" }, [
          _vm._v("Email Notifications")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "services-input-group" }, [
          _c("label", [_vm._v("Alerts")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "switch" },
            [
              _c("mnb-switch", {
                attrs: { disabled: !_vm.editing },
                model: {
                  value: _vm.form.settings.emailNotifications.alerts,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.form.settings.emailNotifications,
                      "alerts",
                      $$v
                    )
                  },
                  expression: "form.settings.emailNotifications.alerts"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "services-input-group" }, [
          _c("label", [_vm._v("Email Confirmation")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "switch" },
            [
              _c("mnb-switch", {
                attrs: { disabled: !_vm.editing },
                model: {
                  value: _vm.form.settings.emailNotifications.emailConfirmation,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.form.settings.emailNotifications,
                      "emailConfirmation",
                      $$v
                    )
                  },
                  expression:
                    "form.settings.emailNotifications.emailConfirmation"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "services-input-group" }, [
          _c("label", [_vm._v("Enrollment")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "switch" },
            [
              _c("mnb-switch", {
                attrs: { disabled: !_vm.editing },
                model: {
                  value: _vm.form.settings.emailNotifications.enrollment,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.form.settings.emailNotifications,
                      "enrollment",
                      $$v
                    )
                  },
                  expression: "form.settings.emailNotifications.enrollment"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "services-input-group" }, [
          _c("label", [_vm._v("New Account Request")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "switch" },
            [
              _c("mnb-switch", {
                attrs: { disabled: !_vm.editing },
                model: {
                  value: _vm.form.settings.emailNotifications.newAccountRequest,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.form.settings.emailNotifications,
                      "newAccountRequest",
                      $$v
                    )
                  },
                  expression:
                    "form.settings.emailNotifications.newAccountRequest"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "services-input-group" }, [
          _c("label", [_vm._v("Notifications")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "switch" },
            [
              _c("mnb-switch", {
                attrs: { disabled: !_vm.editing },
                model: {
                  value: _vm.form.settings.emailNotifications.notifications,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.form.settings.emailNotifications,
                      "notifications",
                      $$v
                    )
                  },
                  expression: "form.settings.emailNotifications.notifications"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "services-input-group" }, [
          _c("label", [_vm._v("Statement")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "switch" },
            [
              _c("mnb-switch", {
                attrs: { disabled: !_vm.editing },
                model: {
                  value: _vm.form.settings.emailNotifications.statement,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.form.settings.emailNotifications,
                      "statement",
                      $$v
                    )
                  },
                  expression: "form.settings.emailNotifications.statement"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "services-input-group" }, [
          _c("label", [_vm._v("Trade")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "switch" },
            [
              _c("mnb-switch", {
                attrs: { disabled: !_vm.editing },
                model: {
                  value: _vm.form.settings.emailNotifications.trade,
                  callback: function($$v) {
                    _vm.$set(_vm.form.settings.emailNotifications, "trade", $$v)
                  },
                  expression: "form.settings.emailNotifications.trade"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "services-input-group" }, [
          _c("label", [_vm._v("Transaction")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "switch" },
            [
              _c("mnb-switch", {
                attrs: { disabled: !_vm.editing },
                model: {
                  value: _vm.form.settings.emailNotifications.transaction,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.form.settings.emailNotifications,
                      "transaction",
                      $$v
                    )
                  },
                  expression: "form.settings.emailNotifications.transaction"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "services-input-group" }, [
          _c("label", [_vm._v("Welcome Activation")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "switch" },
            [
              _c("mnb-switch", {
                attrs: { disabled: !_vm.editing },
                model: {
                  value: _vm.form.settings.emailNotifications.welcomeActivation,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.form.settings.emailNotifications,
                      "welcomeActivation",
                      $$v
                    )
                  },
                  expression:
                    "form.settings.emailNotifications.welcomeActivation"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("h5", { staticClass: "settings-subtitle" }, [_vm._v("Enrollment")]),
        _vm._v(" "),
        _c("div", { staticClass: "services-input-group" }, [
          _c("label", [_vm._v("Payments")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "switch" },
            [
              _vm.form.modules.rPayments
                ? _c("mnb-switch", {
                    attrs: { disabled: !_vm.editing },
                    model: {
                      value: _vm.form.settings.enrollmentForms.payments,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.form.settings.enrollmentForms,
                          "payments",
                          $$v
                        )
                      },
                      expression: "form.settings.enrollmentForms.payments"
                    }
                  })
                : _c("mnb-switch", {
                    attrs: {
                      disabled: true,
                      set: (_vm.form.settings.enrollmentForms.payments = false)
                    }
                  })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "services-input-group" }, [
          _c("label", { staticClass: "pl-20" }, [_vm._v("Facilitators")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "switch" },
            [
              _c("mnb-switch", {
                attrs: {
                  disabled:
                    !_vm.form.settings.enrollmentForms.payments || !_vm.editing
                },
                model: {
                  value: _vm.form.settings.enrollmentForms.facilitators,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.form.settings.enrollmentForms,
                      "facilitators",
                      $$v
                    )
                  },
                  expression: "form.settings.enrollmentForms.facilitators"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "services-input-group" }, [
          _c("label", { staticClass: "pl-20" }, [_vm._v("Merchants")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "switch" },
            [
              _c("mnb-switch", {
                attrs: {
                  disabled:
                    !_vm.form.settings.enrollmentForms.payments || !_vm.editing
                },
                model: {
                  value: _vm.form.settings.enrollmentForms.merchants,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.form.settings.enrollmentForms,
                      "merchants",
                      $$v
                    )
                  },
                  expression: "form.settings.enrollmentForms.merchants"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "services-input-group" }, [
          _c("label", [_vm._v("Treasury")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "switch" },
            [
              _vm.form.modules.rBanking
                ? _c("mnb-switch", {
                    attrs: { disabled: !_vm.editing },
                    model: {
                      value: _vm.form.settings.enrollmentForms.treasury,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.form.settings.enrollmentForms,
                          "treasury",
                          $$v
                        )
                      },
                      expression: "form.settings.enrollmentForms.treasury"
                    }
                  })
                : _c("mnb-switch", {
                    attrs: {
                      disabled: true,
                      set: (_vm.form.settings.enrollmentForms.treasury = false)
                    }
                  })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "services-input-group" }, [
          _c("label", { staticClass: "pl-20" }, [_vm._v("Business")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "switch" },
            [
              _c("mnb-switch", {
                attrs: {
                  disabled:
                    !_vm.form.settings.enrollmentForms.treasury || !_vm.editing
                },
                model: {
                  value: _vm.form.settings.enrollmentForms.business,
                  callback: function($$v) {
                    _vm.$set(_vm.form.settings.enrollmentForms, "business", $$v)
                  },
                  expression: "form.settings.enrollmentForms.business"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "services-input-group" }, [
          _c("label", { staticClass: "pl-20" }, [_vm._v("Personal")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "switch" },
            [
              _c("mnb-switch", {
                attrs: {
                  disabled:
                    !_vm.form.settings.enrollmentForms.treasury || !_vm.editing
                },
                model: {
                  value: _vm.form.settings.enrollmentForms.personal,
                  callback: function($$v) {
                    _vm.$set(_vm.form.settings.enrollmentForms, "personal", $$v)
                  },
                  expression: "form.settings.enrollmentForms.personal"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "services-input-group" }, [
          _c("label", { staticClass: "pl-20" }, [_vm._v("Omnibus")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "switch" },
            [
              _vm.form.modules.banking.omnibus
                ? _c("mnb-switch", {
                    attrs: {
                      disabled:
                        !_vm.form.settings.enrollmentForms.treasury ||
                        !_vm.editing
                    },
                    model: {
                      value: _vm.form.settings.enrollmentForms.omnibus,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.form.settings.enrollmentForms,
                          "omnibus",
                          $$v
                        )
                      },
                      expression: "form.settings.enrollmentForms.omnibus"
                    }
                  })
                : _c("mnb-switch", {
                    attrs: {
                      disabled: true,
                      set: (_vm.form.settings.enrollmentForms.omnibus = false)
                    }
                  })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "services-input-group" }, [
          _c("label", [_vm._v("Partners")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "switch" },
            [
              _c("mnb-switch", {
                attrs: { disabled: !_vm.editing },
                model: {
                  value: _vm.form.settings.enrollmentForms.partners,
                  callback: function($$v) {
                    _vm.$set(_vm.form.settings.enrollmentForms, "partners", $$v)
                  },
                  expression: "form.settings.enrollmentForms.partners"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("h5", { staticClass: "settings-subtitle" }, [_vm._v("Partners")]),
        _vm._v(" "),
        _c("div", { staticClass: "services-input-group" }, [
          _c("label", [_vm._v("Amadel")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "switch" },
            [
              _c("mnb-switch", {
                attrs: { disabled: !_vm.editing },
                model: {
                  value: _vm.form.settings.partners.amadel,
                  callback: function($$v) {
                    _vm.$set(_vm.form.settings.partners, "amadel", $$v)
                  },
                  expression: "form.settings.partners.amadel"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "services-input-group" }, [
          _c("label", [_vm._v("Scheduler")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "switch" },
            [
              _c("mnb-switch", {
                attrs: { disabled: !_vm.editing },
                model: {
                  value: _vm.form.settings.partners.scheduler,
                  callback: function($$v) {
                    _vm.$set(_vm.form.settings.partners, "scheduler", $$v)
                  },
                  expression: "form.settings.partners.scheduler"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "services-input-group" }, [
          _c("label", [_vm._v("Partner Advance")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "switch" },
            [
              _c("mnb-switch", {
                attrs: { disabled: !_vm.editing },
                model: {
                  value: _vm.form.settings.partners.partnerAdvance,
                  callback: function($$v) {
                    _vm.$set(_vm.form.settings.partners, "partnerAdvance", $$v)
                  },
                  expression: "form.settings.partners.partnerAdvance"
                }
              })
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", [
        _c("h4", { staticClass: "settings-text" }, [_vm._v("Components")]),
        _vm._v(" "),
        _c("div", { staticClass: "services-input-group" }, [
          _c("h5", { staticClass: "settings-subtitle" }, [_vm._v("Payments")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "switch" },
            [
              _c("mnb-switch", {
                attrs: { disabled: !_vm.editing },
                model: {
                  value: _vm.form.modules.rPayments,
                  callback: function($$v) {
                    _vm.$set(_vm.form.modules, "rPayments", $$v)
                  },
                  expression: "form.modules.rPayments"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "services-input-group" }, [
          _c("label", [_vm._v("Daily Limit")]),
          _vm._v(" "),
          _c("div", { staticClass: "switch" }, [
            _vm._v("\n          $ "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.modules.payments.dailyLimit,
                  expression: "form.modules.payments.dailyLimit"
                }
              ],
              attrs: {
                type: "text",
                disabled: !_vm.form.modules.rPayments || !_vm.editing
              },
              domProps: { value: _vm.form.modules.payments.dailyLimit },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.form.modules.payments,
                    "dailyLimit",
                    $event.target.value
                  )
                }
              }
            })
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "services-input-group" }, [
          _c("label", [_vm._v("Monthly Limit")]),
          _vm._v(" "),
          _c("div", { staticClass: "switch" }, [
            _vm._v("\n          $ "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.modules.payments.monthlyLimit,
                  expression: "form.modules.payments.monthlyLimit"
                }
              ],
              attrs: {
                type: "text",
                disabled: !_vm.form.modules.rPayments || !_vm.editing
              },
              domProps: { value: _vm.form.modules.payments.monthlyLimit },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.form.modules.payments,
                    "monthlyLimit",
                    $event.target.value
                  )
                }
              }
            })
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "services-input-group" }, [
          _c("label", [_vm._v("Account Reconciliation")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "switch" },
            [
              _c("mnb-switch", {
                attrs: {
                  disabled: !_vm.form.modules.rPayments || !_vm.editing
                },
                model: {
                  value: _vm.form.modules.payments.accountReconciliation,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.form.modules.payments,
                      "accountReconciliation",
                      $$v
                    )
                  },
                  expression: "form.modules.payments.accountReconciliation"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "services-input-group" }, [
          _c("label", [_vm._v("Account Updater")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "switch" },
            [
              _c("mnb-switch", {
                attrs: {
                  disabled: !_vm.form.modules.rPayments || !_vm.editing
                },
                model: {
                  value: _vm.form.modules.payments.accountUpdater,
                  callback: function($$v) {
                    _vm.$set(_vm.form.modules.payments, "accountUpdater", $$v)
                  },
                  expression: "form.modules.payments.accountUpdater"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "services-input-group" }, [
          _c("label", [_vm._v("Gateway")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "switch" },
            [
              _c("mnb-switch", {
                attrs: {
                  disabled: !_vm.form.modules.rPayments || !_vm.editing
                },
                model: {
                  value: _vm.form.modules.payments.gateway,
                  callback: function($$v) {
                    _vm.$set(_vm.form.modules.payments, "gateway", $$v)
                  },
                  expression: "form.modules.payments.gateway"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "services-input-group" }, [
          _c("label", [_vm._v("Gift")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "switch" },
            [
              _c("mnb-switch", {
                attrs: {
                  disabled: !_vm.form.modules.rPayments || !_vm.editing
                },
                model: {
                  value: _vm.form.modules.payments.gift,
                  callback: function($$v) {
                    _vm.$set(_vm.form.modules.payments, "gift", $$v)
                  },
                  expression: "form.modules.payments.gift"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "services-input-group" }, [
          _c("label", [_vm._v("Gift & Loyalty")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "switch" },
            [
              _c("mnb-switch", {
                attrs: {
                  disabled: !_vm.form.modules.rPayments || !_vm.editing
                },
                model: {
                  value: _vm.form.modules.payments.giftLoyalty,
                  callback: function($$v) {
                    _vm.$set(_vm.form.modules.payments, "giftLoyalty", $$v)
                  },
                  expression: "form.modules.payments.giftLoyalty"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "services-input-group" }, [
          _c("label", [_vm._v("Multi-Account")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "switch" },
            [
              _c("mnb-switch", {
                attrs: {
                  disabled: !_vm.form.modules.rPayments || !_vm.editing
                },
                model: {
                  value: _vm.form.modules.payments.multiAccount,
                  callback: function($$v) {
                    _vm.$set(_vm.form.modules.payments, "multiAccount", $$v)
                  },
                  expression: "form.modules.payments.multiAccount"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "services-input-group" }, [
          _c("label", [_vm._v("Multi-Currency")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "switch" },
            [
              _c("mnb-switch", {
                attrs: {
                  disabled: !_vm.form.modules.rPayments || !_vm.editing
                },
                model: {
                  value: _vm.form.modules.payments.multiCurrency,
                  callback: function($$v) {
                    _vm.$set(_vm.form.modules.payments, "multiCurrency", $$v)
                  },
                  expression: "form.modules.payments.multiCurrency"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "services-input-group" }, [
          _c("label", [_vm._v("Payment Facilitators")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "switch" },
            [
              _c("mnb-switch", {
                attrs: {
                  disabled: !_vm.form.modules.rPayments || !_vm.editing
                },
                model: {
                  value: _vm.form.modules.payments.paymentFacilitators,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.form.modules.payments,
                      "paymentFacilitators",
                      $$v
                    )
                  },
                  expression: "form.modules.payments.paymentFacilitators"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "services-input-group" }, [
          _c("label", [_vm._v("Subscription")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "switch" },
            [
              _c("mnb-switch", {
                attrs: {
                  disabled: !_vm.form.modules.rPayments || !_vm.editing
                },
                model: {
                  value: _vm.form.modules.payments.subscription,
                  callback: function($$v) {
                    _vm.$set(_vm.form.modules.payments, "subscription", $$v)
                  },
                  expression: "form.modules.payments.subscription"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "services-input-group" }, [
          _c("label", [_vm._v("Titan")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "switch" },
            [
              _c("mnb-switch", {
                attrs: {
                  disabled: !_vm.form.modules.rPayments || !_vm.editing
                },
                model: {
                  value: _vm.form.modules.payments.titan,
                  callback: function($$v) {
                    _vm.$set(_vm.form.modules.payments, "titan", $$v)
                  },
                  expression: "form.modules.payments.titan"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "services-input-group" }, [
          _c("label", [_vm._v("Tokenization")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "switch" },
            [
              _c("mnb-switch", {
                attrs: {
                  disabled: !_vm.form.modules.rPayments || !_vm.editing
                },
                model: {
                  value: _vm.form.modules.payments.tokenization,
                  callback: function($$v) {
                    _vm.$set(_vm.form.modules.payments, "tokenization", $$v)
                  },
                  expression: "form.modules.payments.tokenization"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "services-input-group" }, [
          _c("label", [_vm._v("Virtual Terminal")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "switch" },
            [
              _c("mnb-switch", {
                attrs: {
                  disabled: !_vm.form.modules.rPayments || !_vm.editing
                },
                model: {
                  value: _vm.form.modules.payments.virtualTerminal,
                  callback: function($$v) {
                    _vm.$set(_vm.form.modules.payments, "virtualTerminal", $$v)
                  },
                  expression: "form.modules.payments.virtualTerminal"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "services-input-group" }, [
          _c("h5", { staticClass: "settings-subtitle" }, [_vm._v("Treasury")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "switch" },
            [
              _c("mnb-switch", {
                attrs: { disabled: !_vm.editing },
                model: {
                  value: _vm.form.modules.rBanking,
                  callback: function($$v) {
                    _vm.$set(_vm.form.modules, "rBanking", $$v)
                  },
                  expression: "form.modules.rBanking"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "services-input-group" }, [
          _c("label", [_vm._v("Daily Limit")]),
          _vm._v(" "),
          _c("div", { staticClass: "switch" }, [
            _vm._v("\n          $ "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.modules.banking.dailyLimit,
                  expression: "form.modules.banking.dailyLimit"
                }
              ],
              attrs: {
                type: "text",
                disabled: !_vm.form.modules.rBanking || !_vm.editing
              },
              domProps: { value: _vm.form.modules.banking.dailyLimit },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.form.modules.banking,
                    "dailyLimit",
                    $event.target.value
                  )
                }
              }
            })
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "services-input-group" }, [
          _c("label", [_vm._v("Monthly Limit")]),
          _vm._v(" "),
          _c("div", { staticClass: "switch" }, [
            _vm._v("\n          $ "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.modules.banking.monthlyLimit,
                  expression: "form.modules.banking.monthlyLimit"
                }
              ],
              attrs: {
                type: "text",
                disabled: !_vm.form.modules.rBanking || !_vm.editing
              },
              domProps: { value: _vm.form.modules.banking.monthlyLimit },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.form.modules.banking,
                    "monthlyLimit",
                    $event.target.value
                  )
                }
              }
            })
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "services-input-group" }, [
          _c("label", [_vm._v("Account Reconciliation")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "switch" },
            [
              _c("mnb-switch", {
                attrs: { disabled: !_vm.form.modules.rBanking || !_vm.editing },
                model: {
                  value: _vm.form.modules.banking.accountReconciliation,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.form.modules.banking,
                      "accountReconciliation",
                      $$v
                    )
                  },
                  expression: "form.modules.banking.accountReconciliation"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "services-input-group" }, [
          _c("label", [_vm._v("Advanced Deposit")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "switch" },
            [
              _c("mnb-switch", {
                attrs: { disabled: !_vm.form.modules.rBanking || !_vm.editing },
                model: {
                  value: _vm.form.modules.banking.advancedDeposit,
                  callback: function($$v) {
                    _vm.$set(_vm.form.modules.banking, "advancedDeposit", $$v)
                  },
                  expression: "form.modules.banking.advancedDeposit"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "services-input-group" }, [
          _c("label", [_vm._v("Billing Model")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "switch" },
            [
              _c("mnb-switch", {
                attrs: { disabled: !_vm.form.modules.rBanking || !_vm.editing },
                model: {
                  value: _vm.form.modules.banking.billingModel,
                  callback: function($$v) {
                    _vm.$set(_vm.form.modules.banking, "billingModel", $$v)
                  },
                  expression: "form.modules.banking.billingModel"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "services-input-group" }, [
          _c("label", { staticClass: "pl-20" }, [_vm._v("Prepay")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "switch" },
            [
              _c("mnb-switch", {
                attrs: {
                  disabled:
                    !_vm.form.modules.rBanking ||
                    !_vm.form.modules.banking.billingModel ||
                    !_vm.editing
                },
                model: {
                  value: _vm.form.modules.banking.prepay,
                  callback: function($$v) {
                    _vm.$set(_vm.form.modules.banking, "prepay", $$v)
                  },
                  expression: "form.modules.banking.prepay"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "services-input-group" }, [
          _c("label", { staticClass: "pl-20" }, [_vm._v("Postpay")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "switch" },
            [
              _c("mnb-switch", {
                attrs: {
                  disabled:
                    !_vm.form.modules.rBanking ||
                    !_vm.form.modules.banking.billingModel ||
                    !_vm.editing
                },
                model: {
                  value: _vm.form.modules.banking.postpay,
                  callback: function($$v) {
                    _vm.$set(_vm.form.modules.banking, "postpay", $$v)
                  },
                  expression: "form.modules.banking.postpay"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "services-input-group" }, [
          _c("label", [_vm._v("Card Issuing")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "switch" },
            [
              _c("mnb-switch", {
                attrs: { disabled: !_vm.form.modules.rBanking || !_vm.editing },
                model: {
                  value: _vm.form.modules.banking.cardIssuing,
                  callback: function($$v) {
                    _vm.$set(_vm.form.modules.banking, "cardIssuing", $$v)
                  },
                  expression: "form.modules.banking.cardIssuing"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "services-input-group" }, [
          _c("label", { staticClass: "pl-20" }, [_vm._v("Physical Cards")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "switch" },
            [
              _c("mnb-switch", {
                attrs: {
                  disabled:
                    !_vm.form.modules.rBanking ||
                    !_vm.form.modules.banking.cardIssuing ||
                    !_vm.editing
                },
                model: {
                  value: _vm.form.modules.banking.physicalCards,
                  callback: function($$v) {
                    _vm.$set(_vm.form.modules.banking, "physicalCards", $$v)
                  },
                  expression: "form.modules.banking.physicalCards"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "services-input-group" }, [
          _c("label", { staticClass: "pl-20" }, [_vm._v("Virtual Cards")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "switch" },
            [
              _c("mnb-switch", {
                attrs: {
                  disabled:
                    !_vm.form.modules.rBanking ||
                    !_vm.form.modules.banking.cardIssuing ||
                    !_vm.editing
                },
                model: {
                  value: _vm.form.modules.banking.virtualCards,
                  callback: function($$v) {
                    _vm.$set(_vm.form.modules.banking, "virtualCards", $$v)
                  },
                  expression: "form.modules.banking.virtualCards"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "services-input-group" }, [
          _c("label", { staticClass: "pl-20" }, [_vm._v("Dispute Manager")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "switch" },
            [
              _c("mnb-switch", {
                attrs: {
                  disabled:
                    !_vm.form.modules.rBanking ||
                    !_vm.form.modules.banking.cardIssuing ||
                    !_vm.editing
                },
                model: {
                  value: _vm.form.modules.banking.disputeManager,
                  callback: function($$v) {
                    _vm.$set(_vm.form.modules.banking, "disputeManager", $$v)
                  },
                  expression: "form.modules.banking.disputeManager"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "services-input-group" }, [
          _c("label", [_vm._v("Digital Assets")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "switch" },
            [
              _c("mnb-switch", {
                attrs: { disabled: !_vm.form.modules.rBanking || !_vm.editing },
                model: {
                  value: _vm.form.modules.banking.digitalAssets,
                  callback: function($$v) {
                    _vm.$set(_vm.form.modules.banking, "digitalAssets", $$v)
                  },
                  expression: "form.modules.banking.digitalAssets"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "services-input-group" }, [
          _c("label", { staticClass: "pl-20" }, [_vm._v("Trade Desk")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "switch" },
            [
              _c("mnb-switch", {
                attrs: {
                  disabled:
                    !_vm.form.modules.rBanking ||
                    !_vm.form.modules.banking.digitalAssets ||
                    !_vm.editing
                },
                model: {
                  value: _vm.form.modules.banking.tradeDesk,
                  callback: function($$v) {
                    _vm.$set(_vm.form.modules.banking, "tradeDesk", $$v)
                  },
                  expression: "form.modules.banking.tradeDesk"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "services-input-group" }, [
          _c("label", [_vm._v("Omnibus")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "switch" },
            [
              _c("mnb-switch", {
                attrs: { disabled: !_vm.form.modules.rBanking || !_vm.editing },
                model: {
                  value: _vm.form.modules.banking.omnibus,
                  callback: function($$v) {
                    _vm.$set(_vm.form.modules.banking, "omnibus", $$v)
                  },
                  expression: "form.modules.banking.omnibus"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "services-input-group" }, [
          _c("label", [_vm._v("Multi-Account")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "switch" },
            [
              _c("mnb-switch", {
                attrs: { disabled: !_vm.form.modules.rBanking || !_vm.editing },
                model: {
                  value: _vm.form.modules.banking.multiAccount,
                  callback: function($$v) {
                    _vm.$set(_vm.form.modules.banking, "multiAccount", $$v)
                  },
                  expression: "form.modules.banking.multiAccount"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "services-input-group" }, [
          _c("label", [_vm._v("Multi-Currency")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "switch" },
            [
              _c("mnb-switch", {
                attrs: { disabled: !_vm.form.modules.rBanking || !_vm.editing },
                model: {
                  value: _vm.form.modules.banking.multiCurrency,
                  callback: function($$v) {
                    _vm.$set(_vm.form.modules.banking, "multiCurrency", $$v)
                  },
                  expression: "form.modules.banking.multiCurrency"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "services-input-group" }, [
          _c("label", { staticClass: "pl-20" }, [_vm._v("Exchange Desk")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "switch" },
            [
              _c("mnb-switch", {
                attrs: {
                  disabled:
                    !_vm.form.modules.rBanking ||
                    !_vm.form.modules.banking.multiCurrency ||
                    !_vm.editing
                },
                model: {
                  value: _vm.form.modules.banking.exchangeDesk,
                  callback: function($$v) {
                    _vm.$set(_vm.form.modules.banking, "exchangeDesk", $$v)
                  },
                  expression: "form.modules.banking.exchangeDesk"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "services-input-group" }, [
          _c("label", [_vm._v("Remote Deposit")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "switch" },
            [
              _c("mnb-switch", {
                attrs: { disabled: !_vm.form.modules.rBanking || !_vm.editing },
                model: {
                  value: _vm.form.modules.banking.remoteDeposit,
                  callback: function($$v) {
                    _vm.$set(_vm.form.modules.banking, "remoteDeposit", $$v)
                  },
                  expression: "form.modules.banking.remoteDeposit"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "services-input-group" }, [
          _c("label", [_vm._v("Statements")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "switch" },
            [
              _c("mnb-switch", {
                attrs: { disabled: !_vm.form.modules.rBanking || !_vm.editing },
                model: {
                  value: _vm.form.modules.banking.statements,
                  callback: function($$v) {
                    _vm.$set(_vm.form.modules.banking, "statements", $$v)
                  },
                  expression: "form.modules.banking.statements"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "services-input-group" }, [
          _c("label", [_vm._v("Withdrawal")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "switch" },
            [
              _c("mnb-switch", {
                attrs: { disabled: !_vm.form.modules.rBanking || !_vm.editing },
                model: {
                  value: _vm.form.modules.banking.withdrawal,
                  callback: function($$v) {
                    _vm.$set(_vm.form.modules.banking, "withdrawal", $$v)
                  },
                  expression: "form.modules.banking.withdrawal"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "services-input-group" }, [
          _c("label", { staticClass: "pl-20" }, [_vm._v("Funds")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "switch" },
            [
              _c("mnb-switch", {
                attrs: {
                  disabled:
                    !_vm.form.modules.rBanking ||
                    !_vm.form.modules.banking.withdrawal ||
                    !_vm.editing
                },
                model: {
                  value: _vm.form.modules.banking.funds,
                  callback: function($$v) {
                    _vm.$set(_vm.form.modules.banking, "funds", $$v)
                  },
                  expression: "form.modules.banking.funds"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "services-input-group" }, [
          _c("label", { staticClass: "pl-20" }, [_vm._v("Assets")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "switch" },
            [
              _c("mnb-switch", {
                attrs: {
                  disabled:
                    !_vm.form.modules.rBanking ||
                    !_vm.form.modules.banking.withdrawal ||
                    !_vm.editing
                },
                model: {
                  value: _vm.form.modules.banking.assets,
                  callback: function($$v) {
                    _vm.$set(_vm.form.modules.banking, "assets", $$v)
                  },
                  expression: "form.modules.banking.assets"
                }
              })
            ],
            1
          )
        ])
      ])
    ]),
    _vm._v(" "),
    !_vm.readonly
      ? _c(
          "div",
          { staticClass: "buttons" },
          [
            !_vm.editing
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-small btn-primary",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        _vm.editing = true
                      }
                    }
                  },
                  [_vm._v("Update")]
                )
              : [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-small btn-primary",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.updateSettings.apply(null, arguments)
                        }
                      }
                    },
                    [_vm._v("Save")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-small btn-danger",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          _vm.editing = false
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  )
                ]
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("ul", { staticClass: "breadcrumbs" }, [
        _c("li", [_c("a", [_vm._v("Organization Settings")])])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }