var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form", { staticClass: "form-step" }, [
    _c("section", [
      _c("h2", { staticClass: "bline" }, [_vm._v("Banking profile")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Please put the details for your settlement bank. Account must be in the same name as company"
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "bank-accounts" },
        _vm._l(_vm.app.accounts, function(account, i) {
          return _c("div", { staticClass: "bank-account-box" }, [
            _vm.app.accounts.length > 1
              ? _c(
                  "a",
                  {
                    staticClass: "remove",
                    attrs: { href: "" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.app.accounts.splice(i, 1)
                      }
                    }
                  },
                  [_vm._v("×")]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "f2splitx" }, [
              _c("ul", { staticClass: "column-form type2 row5" }, [
                _c("li", [
                  _c("label", [_vm._v("Name on Bank Account")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "field" },
                    [
                      _c(
                        "validator",
                        {
                          attrs: {
                            value: account.accountHolder,
                            reqs: "required legalName"
                          }
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: account.accountHolder,
                                expression: "account.accountHolder"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: { value: account.accountHolder },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  account,
                                  "accountHolder",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ]
                      )
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Bank Account Number")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "field" },
                    [
                      _c(
                        "validator",
                        {
                          attrs: {
                            value: account.accountNumber,
                            reqs: "required num20"
                          }
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: account.accountNumber,
                                expression: "account.accountNumber"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: { value: account.accountNumber },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  account,
                                  "accountNumber",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ]
                      )
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Institution Number")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "field" },
                    [
                      _c(
                        "validator",
                        {
                          attrs: {
                            value: account.accountSwift,
                            reqs: "required num3e"
                          }
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: account.accountSwift,
                                expression: "account.accountSwift"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: { value: account.accountSwift },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  account,
                                  "accountSwift",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ]
                      )
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Transit/Branch Number")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "field" },
                    [
                      _c(
                        "validator",
                        {
                          attrs: {
                            value: account.accountIBAN,
                            reqs: "required num5e"
                          }
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: account.accountIBAN,
                                expression: "account.accountIBAN"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: { value: account.accountIBAN },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  account,
                                  "accountIBAN",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ]
                      )
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                false
                  ? _c("li", [
                      _vm._m(0, true),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "field" },
                        [
                          _c(
                            "validator",
                            {
                              attrs: {
                                value: account.accountBankCode,
                                reqs: "alnum20"
                              }
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: account.accountBankCode,
                                    expression: "account.accountBankCode"
                                  }
                                ],
                                attrs: { type: "text" },
                                domProps: { value: account.accountBankCode },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      account,
                                      "accountBankCode",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          )
                        ],
                        1
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("li", [
                  _vm._m(1, true),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "field" },
                    [
                      _c(
                        "validator",
                        {
                          attrs: {
                            value: account.accountRouting,
                            reqs: "num9e"
                          }
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: account.accountRouting,
                                expression: "account.accountRouting"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: { value: account.accountRouting },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  account,
                                  "accountRouting",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ]
                      )
                    ],
                    1
                  )
                ])
              ]),
              _vm._v(" "),
              _c("ul", { staticClass: "column-form type2" }, [
                _c("li", [
                  _c("label", [_vm._v("Bank Name")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "field" },
                    [
                      _c(
                        "validator",
                        {
                          attrs: {
                            value: account.bankName,
                            reqs: "required alnum30"
                          }
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: account.bankName,
                                expression: "account.bankName"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: { value: account.bankName },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  account,
                                  "bankName",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ]
                      )
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Bank Address")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "field" },
                    [
                      _c(
                        "validator",
                        {
                          attrs: {
                            value: account.bankAddress,
                            reqs: "required address"
                          }
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: account.bankAddress,
                                expression: "account.bankAddress"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: { value: account.bankAddress },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  account,
                                  "bankAddress",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ]
                      )
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Postal Code")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "field" },
                    [
                      _c(
                        "validator",
                        {
                          attrs: {
                            value: account.bankAddressPostCode,
                            reqs: "required postcode"
                          }
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: account.bankAddressPostCode,
                                expression: "account.bankAddressPostCode"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: { value: account.bankAddressPostCode },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  account,
                                  "bankAddressPostCode",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ]
                      )
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("City")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "field" },
                    [
                      _c(
                        "validator",
                        {
                          attrs: {
                            value: account.bankAddressCity,
                            reqs: "required city"
                          }
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: account.bankAddressCity,
                                expression: "account.bankAddressCity"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: { value: account.bankAddressCity },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  account,
                                  "bankAddressCity",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ]
                      )
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Province")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "field" },
                    [
                      _c(
                        "validator",
                        {
                          attrs: {
                            value: account.bankAddressState,
                            reqs: "required state"
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "combo-select" },
                            [
                              _c("dyn-select", {
                                attrs: { options: _vm.validProvinces },
                                model: {
                                  value: account.bankAddressState,
                                  callback: function($$v) {
                                    _vm.$set(account, "bankAddressState", $$v)
                                  },
                                  expression: "account.bankAddressState"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Country")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "field" },
                    [
                      _c(
                        "validator",
                        {
                          attrs: {
                            value: account.bankAddressCountry,
                            reqs: "required country"
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "combo-select" },
                            [
                              _c("dyn-select", {
                                attrs: { options: _vm.validCountries },
                                model: {
                                  value: account.bankAddressCountry,
                                  callback: function($$v) {
                                    _vm.$set(account, "bankAddressCountry", $$v)
                                  },
                                  expression: "account.bankAddressCountry"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Bank Telephone Number")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "field" },
                    [
                      _c(
                        "validator",
                        {
                          attrs: {
                            value: account.bankPhone,
                            reqs: "required phone"
                          }
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: account.bankPhone,
                                expression: "account.bankPhone"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: { value: account.bankPhone },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  account,
                                  "bankPhone",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ]
                      )
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("li", [_vm._v(" ")])
              ])
            ]),
            _vm._v(" "),
            i == 0
              ? _c("ul", { staticClass: "footnotes" }, [
                  _vm._m(2, true),
                  _vm._v(" "),
                  _vm._m(3, true)
                ])
              : _vm._e()
          ])
        }),
        0
      ),
      _vm._v(" "),
      _vm.app.accounts.length < 2
        ? _c("div", { staticClass: "add-dir adx" }, [
            _c("a", { on: { click: _vm.newAccount } }, [
              _vm._v("Add another settlement bank"),
              _c("span", [_vm._v("+")])
            ])
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "buttons" }, [
      _c(
        "a",
        {
          staticClass: "btn btn-large btn-plain btn-back",
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.$emit("prev")
            }
          }
        },
        [_vm._v("Back")]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "btn btn-large btn-warn",
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.$emit("save")
            }
          }
        },
        [_vm._v("Save")]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "btn btn-large btn-success btn-next",
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.nextStep.apply(null, arguments)
            }
          }
        },
        [_vm._v("Next Step")]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_vm._v("Bank sort code "), _c("sup", [_vm._v("1")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [
      _vm._v("ABA / Routing Number "),
      _c("sup", [_vm._v("1")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("sup", [_vm._v("1")]),
      _vm._v(
        " This information is required in certain countries. Please make sure these fields are completed if applicable."
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("sup", [_vm._v("2")]),
      _vm._v(
        " Including international country code (e.g. +1 for USA, +852 for Hong Kong, etc.)"
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }