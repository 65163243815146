var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "search-form",
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.save()
        }
      }
    },
    [
      _c("h2", { staticClass: "doc-title" }, [
        _vm._v("AML TRAINING CERTIFICATION")
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "If you have successfully received your AML training please complete this form, sign it, and deliver it to the chief compliance officer."
        )
      ]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c(
        "p",
        [
          _c("b", [_vm._v("THIS TRAINING SESSION WAS MY:")]),
          _c("br"),
          _vm._v(" "),
          _c(
            "validator",
            { attrs: { value: _vm.form.traning_session, reqs: "required" } },
            [
              _c("div", { staticClass: "doc-field" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.traning_session.initial,
                      expression: "traning_session.initial"
                    }
                  ],
                  attrs: { type: "checkbox", id: "rad1" },
                  domProps: {
                    checked: Array.isArray(_vm.traning_session.initial)
                      ? _vm._i(_vm.traning_session.initial, null) > -1
                      : _vm.traning_session.initial
                  },
                  on: {
                    input: function($event) {
                      return _vm.checkboxAsRadio("traning_session", "initial")
                    },
                    change: function($event) {
                      var $$a = _vm.traning_session.initial,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.traning_session,
                              "initial",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.traning_session,
                              "initial",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.traning_session, "initial", $$c)
                      }
                    }
                  }
                }),
                _vm._v(" "),
                _c("label", { attrs: { for: "rad1" } }, [
                  _vm._v("INITIAL TRAINING")
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "doc-field" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.traning_session.yearly,
                      expression: "traning_session.yearly"
                    }
                  ],
                  attrs: { type: "checkbox", id: "rad2" },
                  domProps: {
                    checked: Array.isArray(_vm.traning_session.yearly)
                      ? _vm._i(_vm.traning_session.yearly, null) > -1
                      : _vm.traning_session.yearly
                  },
                  on: {
                    input: function($event) {
                      return _vm.checkboxAsRadio("traning_session", "yearly")
                    },
                    change: function($event) {
                      var $$a = _vm.traning_session.yearly,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.traning_session,
                              "yearly",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.traning_session,
                              "yearly",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.traning_session, "yearly", $$c)
                      }
                    }
                  }
                }),
                _vm._v(" "),
                _c("label", { attrs: { for: "rad2" } }, [
                  _vm._v("YEARLY TRAINING")
                ])
              ])
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "field" },
        [
          _c("label", [_vm._v("DATE(S) OF TRAINING:")]),
          _vm._v(" "),
          _c(
            "validator",
            {
              staticClass: "w100",
              attrs: { value: _vm.form.dates_of_traning, reqs: "required" }
            },
            [
              _c("datepicker", {
                staticClass: "w100",
                attrs: {
                  type: "text",
                  future: false,
                  minDate: _vm.todaySubstractDate(7)
                },
                model: {
                  value: _vm.form.dates_of_traning,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "dates_of_traning", $$v)
                  },
                  expression: "form.dates_of_traning"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "field" },
        [
          _c("label", [_vm._v("TITLE OF TRAINING PROVIDED:")]),
          _vm._v(" "),
          _c(
            "validator",
            {
              staticClass: "w100",
              attrs: {
                value: _vm.form.title_of_traning_provided,
                reqs: "required legalName"
              }
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.title_of_traning_provided,
                    expression: "form.title_of_traning_provided"
                  }
                ],
                staticClass: "w100",
                attrs: { type: "text", maxlength: "100" },
                domProps: { value: _vm.form.title_of_traning_provided },
                on: {
                  keypress: function($event) {
                    return _vm.allowLetterNumber($event, { numbers: true })
                  },
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.form,
                      "title_of_traning_provided",
                      $event.target.value
                    )
                  }
                }
              })
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "p",
        [
          _vm._v("HOW WAS TRAINING TAKEN PLEASE SELECT ONE?\n    "),
          _c(
            "validator",
            { attrs: { value: _vm.form.traning_taken, reqs: "required" } },
            [
              _c("div", { staticClass: "doc-field" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.traning_taken.instructor,
                      expression: "traning_taken.instructor"
                    }
                  ],
                  attrs: { type: "checkbox", id: "rad54785434" },
                  domProps: {
                    checked: Array.isArray(_vm.traning_taken.instructor)
                      ? _vm._i(_vm.traning_taken.instructor, null) > -1
                      : _vm.traning_taken.instructor
                  },
                  on: {
                    input: function($event) {
                      return _vm.checkboxAsRadio("traning_taken", "instructor")
                    },
                    change: function($event) {
                      var $$a = _vm.traning_taken.instructor,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.traning_taken,
                              "instructor",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.traning_taken,
                              "instructor",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.traning_taken, "instructor", $$c)
                      }
                    }
                  }
                }),
                _vm._v(" "),
                _c("label", { attrs: { for: "rad54785434" } }, [
                  _vm._v("INSTRUCTOR")
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "doc-field" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.traning_taken.webinar,
                      expression: "traning_taken.webinar"
                    }
                  ],
                  attrs: { type: "checkbox", id: "rad54123434" },
                  domProps: {
                    checked: Array.isArray(_vm.traning_taken.webinar)
                      ? _vm._i(_vm.traning_taken.webinar, null) > -1
                      : _vm.traning_taken.webinar
                  },
                  on: {
                    input: function($event) {
                      return _vm.checkboxAsRadio("traning_taken", "webinar")
                    },
                    change: function($event) {
                      var $$a = _vm.traning_taken.webinar,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.traning_taken,
                              "webinar",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.traning_taken,
                              "webinar",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.traning_taken, "webinar", $$c)
                      }
                    }
                  }
                }),
                _vm._v(" "),
                _c("label", { attrs: { for: "rad54123434" } }, [
                  _vm._v("WEBINAR")
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "doc-field" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.traning_taken.online,
                      expression: "traning_taken.online"
                    }
                  ],
                  attrs: { type: "checkbox", id: "rad57335434" },
                  domProps: {
                    checked: Array.isArray(_vm.traning_taken.online)
                      ? _vm._i(_vm.traning_taken.online, null) > -1
                      : _vm.traning_taken.online
                  },
                  on: {
                    input: function($event) {
                      return _vm.checkboxAsRadio("traning_taken", "online")
                    },
                    change: function($event) {
                      var $$a = _vm.traning_taken.online,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.traning_taken,
                              "online",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.traning_taken,
                              "online",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.traning_taken, "online", $$c)
                      }
                    }
                  }
                }),
                _vm._v(" "),
                _c("label", { attrs: { for: "rad57335434" } }, [
                  _vm._v("ONLINE E-LEARNING")
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "doc-field" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.traning_taken.all,
                      expression: "traning_taken.all"
                    }
                  ],
                  attrs: { type: "checkbox", id: "rad547234534" },
                  domProps: {
                    checked: Array.isArray(_vm.traning_taken.all)
                      ? _vm._i(_vm.traning_taken.all, null) > -1
                      : _vm.traning_taken.all
                  },
                  on: {
                    input: function($event) {
                      return _vm.checkboxAsRadio("traning_taken", "all")
                    },
                    change: function($event) {
                      var $$a = _vm.traning_taken.all,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.traning_taken,
                              "all",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.traning_taken,
                              "all",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.traning_taken, "all", $$c)
                      }
                    }
                  }
                }),
                _vm._v(" "),
                _c("label", { attrs: { for: "rad547234534" } }, [
                  _vm._v("COMBINATION OF ALL")
                ])
              ])
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "p",
        [
          _vm._v("I, the undersigned\n    "),
          _c(
            "validator",
            {
              staticClass: "inline-block",
              attrs: { value: _vm.form.first_name, reqs: "required letters" }
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.first_name,
                    expression: "form.first_name"
                  }
                ],
                attrs: {
                  type: "text",
                  maxlength: "30",
                  placeholder: "First Name"
                },
                domProps: { value: _vm.form.first_name },
                on: {
                  keypress: function($event) {
                    return _vm.allowLetterNumber($event)
                  },
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.form, "first_name", $event.target.value)
                  }
                }
              })
            ]
          ),
          _vm._v(" "),
          _c(
            "validator",
            {
              staticClass: "inline-block",
              attrs: { value: _vm.form.last_name, reqs: "required letters" }
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.last_name,
                    expression: "form.last_name"
                  }
                ],
                attrs: {
                  type: "text",
                  maxlength: "30",
                  placeholder: "Last Name"
                },
                domProps: { value: _vm.form.last_name },
                on: {
                  keypress: function($event) {
                    return _vm.allowLetterNumber($event)
                  },
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.form, "last_name", $event.target.value)
                  }
                }
              })
            ]
          ),
          _vm._v(" "),
          _c(
            "validator",
            {
              staticClass: "inline-block",
              attrs: { value: _vm.form.email, reqs: "required email" }
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.email,
                    expression: "form.email"
                  }
                ],
                attrs: { type: "email", placeholder: "Email" },
                domProps: { value: _vm.form.email },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.form, "email", $event.target.value)
                  }
                }
              })
            ]
          ),
          _vm._v(" "),
          _c(
            "validator",
            {
              staticClass: "inline-block",
              attrs: { value: _vm.form.department, reqs: "required" }
            },
            [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.department,
                      expression: "form.department"
                    }
                  ],
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.form,
                        "department",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    }
                  }
                },
                [
                  _c("option", { attrs: { disabled: "", value: "" } }, [
                    _vm._v("Department")
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.userGroups, function(opt, key) {
                    return _c(
                      "option",
                      { key: key, domProps: { value: key } },
                      [_vm._v(_vm._s(opt))]
                    )
                  })
                ],
                2
              )
            ]
          ),
          _vm._v(
            "\n    certify that I attended the above-noted training either in person or remotely via simultaneous audio-visual means, and that I received the above-noted training on the above-noted date(s) concerning the Company’s AML Program outlining all the policies and procedures to follow so as to detect, deter, and protect the Company against money launderers, terrorist financiers or fraudsters."
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "SIGNED by " +
            _vm._s(_vm.form.first_name) +
            " " +
            _vm._s(_vm.form.last_name)
        )
      ]),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c("p", { staticClass: "text-bg-black" }, [
        _vm._v("TO BE COMPLETED BY THE CHIEF COMPLIANCE OFFICER")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "custom-doc-field" }, [
        _c("div", { staticClass: "label" }, [
          _vm._v("The Employee Completed the Training Described Above:")
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "field-wrapper" },
          [
            _c(
              "validator",
              {
                attrs: { value: _vm.form.completed_traning, reqs: "required" }
              },
              [
                _c("div", { staticClass: "yes-or-no" }, [
                  _c("div", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.completed_traning.yes,
                          expression: "completed_traning.yes"
                        }
                      ],
                      attrs: { type: "checkbox", id: "cbx43123768421" },
                      domProps: {
                        checked: Array.isArray(_vm.completed_traning.yes)
                          ? _vm._i(_vm.completed_traning.yes, null) > -1
                          : _vm.completed_traning.yes
                      },
                      on: {
                        input: function($event) {
                          return _vm.checkboxAsRadio("completed_traning", "yes")
                        },
                        change: function($event) {
                          var $$a = _vm.completed_traning.yes,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.completed_traning,
                                  "yes",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.completed_traning,
                                  "yes",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.completed_traning, "yes", $$c)
                          }
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "cbx43123768421" } }, [
                      _vm._v("YES")
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.completed_traning.no,
                          expression: "completed_traning.no"
                        }
                      ],
                      attrs: { type: "checkbox", id: "cbx437612338421" },
                      domProps: {
                        checked: Array.isArray(_vm.completed_traning.no)
                          ? _vm._i(_vm.completed_traning.no, null) > -1
                          : _vm.completed_traning.no
                      },
                      on: {
                        input: function($event) {
                          return _vm.checkboxAsRadio("completed_traning", "no")
                        },
                        change: function($event) {
                          var $$a = _vm.completed_traning.no,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.completed_traning,
                                  "no",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.completed_traning,
                                  "no",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.completed_traning, "no", $$c)
                          }
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "cbx437612338421" } }, [
                      _vm._v("NO")
                    ])
                  ])
                ])
              ]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "custom-doc-field" }, [
        _c("div", { staticClass: "label" }, [
          _vm._v("A Test Was Administered:")
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "field-wrapper" },
          [
            _c(
              "validator",
              { attrs: { value: _vm.form.administered, reqs: "required" } },
              [
                _c("div", { staticClass: "yes-or-no" }, [
                  _c("div", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.administered.yes,
                          expression: "administered.yes"
                        }
                      ],
                      attrs: { type: "checkbox", id: "cbx666448421" },
                      domProps: {
                        checked: Array.isArray(_vm.administered.yes)
                          ? _vm._i(_vm.administered.yes, null) > -1
                          : _vm.administered.yes
                      },
                      on: {
                        input: function($event) {
                          return _vm.checkboxAsRadio("administered", "yes")
                        },
                        change: function($event) {
                          var $$a = _vm.administered.yes,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.administered,
                                  "yes",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.administered,
                                  "yes",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.administered, "yes", $$c)
                          }
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "cbx666448421" } }, [
                      _vm._v("YES")
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.administered.no,
                          expression: "administered.no"
                        }
                      ],
                      attrs: { type: "checkbox", id: "cbx4367998421" },
                      domProps: {
                        checked: Array.isArray(_vm.administered.no)
                          ? _vm._i(_vm.administered.no, null) > -1
                          : _vm.administered.no
                      },
                      on: {
                        input: function($event) {
                          return _vm.checkboxAsRadio("administered", "no")
                        },
                        change: function($event) {
                          var $$a = _vm.administered.no,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.administered,
                                  "no",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.administered,
                                  "no",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.administered, "no", $$c)
                          }
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "cbx4367998421" } }, [
                      _vm._v("NO")
                    ])
                  ])
                ])
              ]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "custom-doc-field" },
        [
          _c("div", { staticClass: "label" }, [
            _vm._v("Test Was Passed with a Grade Of:")
          ]),
          _vm._v(" "),
          _c(
            "validator",
            {
              staticClass: "inline-block",
              attrs: { value: _vm.form.passed_grade, reqs: "required number" }
            },
            [
              _c("div", { staticClass: "field-wrapper yes-or-no" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number",
                      value: _vm.form.passed_grade,
                      expression: "form.passed_grade",
                      modifiers: { number: true }
                    }
                  ],
                  staticClass: "w100",
                  attrs: { type: "text", min: "0", max: "100", maxlength: "3" },
                  domProps: { value: _vm.form.passed_grade },
                  on: {
                    input: [
                      function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.form,
                          "passed_grade",
                          _vm._n($event.target.value)
                        )
                      },
                      function($event) {
                        return _vm.limitMinMaxValue()
                      }
                    ],
                    keypress: function($event) {
                      return _vm.allowLetterNumber($event, {
                        numbers: true,
                        letters: false
                      })
                    },
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                })
              ])
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "custom-doc-field" }, [
        _c("div", { staticClass: "label" }, [
          _vm._v("Training and Test Must Be Retaken:")
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "field-wrapper" },
          [
            _c(
              "validator",
              { attrs: { value: _vm.form.must_be_retaken, reqs: "required" } },
              [
                _c("div", { staticClass: "yes-or-no" }, [
                  _c("div", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.must_be_retaken.yes,
                          expression: "must_be_retaken.yes"
                        }
                      ],
                      attrs: { type: "checkbox", id: "cbx4311241118421" },
                      domProps: {
                        checked: Array.isArray(_vm.must_be_retaken.yes)
                          ? _vm._i(_vm.must_be_retaken.yes, null) > -1
                          : _vm.must_be_retaken.yes
                      },
                      on: {
                        input: function($event) {
                          return _vm.checkboxAsRadio("must_be_retaken", "yes")
                        },
                        change: function($event) {
                          var $$a = _vm.must_be_retaken.yes,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.must_be_retaken,
                                  "yes",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.must_be_retaken,
                                  "yes",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.must_be_retaken, "yes", $$c)
                          }
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "cbx4311241118421" } }, [
                      _vm._v("YES")
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.must_be_retaken.no,
                          expression: "must_be_retaken.no"
                        }
                      ],
                      attrs: { type: "checkbox", id: "cbx43662238421" },
                      domProps: {
                        checked: Array.isArray(_vm.must_be_retaken.no)
                          ? _vm._i(_vm.must_be_retaken.no, null) > -1
                          : _vm.must_be_retaken.no
                      },
                      on: {
                        input: function($event) {
                          return _vm.checkboxAsRadio("must_be_retaken", "no")
                        },
                        change: function($event) {
                          var $$a = _vm.must_be_retaken.no,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.must_be_retaken,
                                  "no",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.must_be_retaken,
                                  "no",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.must_be_retaken, "no", $$c)
                          }
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "cbx43662238421" } }, [
                      _vm._v("NO")
                    ])
                  ])
                ])
              ]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "custom-doc-field" },
        [
          _c("div", { staticClass: "label" }, [
            _vm._v("(In the event of a failed grade) on the following date:")
          ]),
          _vm._v(" "),
          _c(
            "validator",
            {
              staticClass: "inline-block",
              attrs: {
                value: _vm.form.failed_grade_date,
                reqs: _vm.form.must_be_retaken ? "required" : null
              }
            },
            [
              _c(
                "div",
                { staticClass: "field-wrapper yes-or-no" },
                [
                  _c("datepicker", {
                    staticClass: "w100",
                    attrs: {
                      type: "text",
                      future: true,
                      maxDate: _vm.todaySubstractDate(-14),
                      disabled: !_vm.form.must_be_retaken
                    },
                    model: {
                      value: _vm.form.failed_grade_date,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "failed_grade_date", $$v)
                      },
                      expression: "form.failed_grade_date"
                    }
                  })
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Chief Compliance Officer Signature: _________________________________________________ Date: ______________________________"
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "buttons" }, [
        _c(
          "button",
          { staticClass: "btn btn-small", attrs: { type: "submit" } },
          [_vm._v("Submit")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-small",
            attrs: { type: "button" },
            on: {
              click: function($event) {
                return _vm.clear()
              }
            }
          },
          [_vm._v("Clear")]
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("b", [_vm._v("PLEASE SELECT ONE OF THE FOLLOWING:")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("\n    SIGNATURE: "),
      _c("span", { staticClass: "signature-line" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }