var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "setupcl" }, [
    _c("ul", [
      _c("li", [
        _c("label", [_vm._v("Merchant Profile")]),
        _vm._v(" "),
        _vm.doneStep >= 0
          ? _c("div", { staticClass: "done" })
          : _c("div", { staticClass: "actions" }, [
              _c(
                "a",
                {
                  staticClass: "button",
                  class: { disabled: _vm.doneStep != -1 },
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.doneStep == -1 && _vm.setIntake()
                    }
                  }
                },
                [_vm._v("Set")]
              )
            ])
      ]),
      _vm._v(" "),
      _c("li", [
        _c("label", [_vm._v("Merchant Settings")]),
        _vm._v(" "),
        _vm.doneStep >= 1
          ? _c("div", { staticClass: "done" })
          : _c("div", { staticClass: "actions" }, [
              _c(
                "a",
                {
                  staticClass: "button",
                  class: { disabled: _vm.doneStep != 0 },
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.doneStep == 0 && _vm.setSettings()
                    }
                  }
                },
                [_vm._v("Set")]
              )
            ])
      ]),
      _vm._v(" "),
      _c("li", [
        _c("label", [_vm._v("Merchant Underwriting")]),
        _vm._v(" "),
        _vm.doneStep >= 2 || _vm.item.underwriting
          ? _c(
              "div",
              { staticClass: "done", staticStyle: { position: "relative" } },
              [
                _c("a", {
                  staticStyle: {
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%"
                  },
                  attrs: {
                    href: "",
                    target: "_blank",
                    href: "/applications/" + _vm.item.id + "/underwriting"
                  }
                })
              ]
            )
          : _c("div", { staticClass: "actions" }, [
              _c(
                "a",
                {
                  staticClass: "button",
                  class: { disabled: _vm.doneStep != 1 },
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.doneStep == 1 && _vm.startUnderwriting()
                    }
                  }
                },
                [_vm._v("Set")]
              )
            ])
      ]),
      _vm._v(" "),
      _c("li", [
        _c("label", [_vm._v("Status Update - In Process")]),
        _vm._v(" "),
        _vm.doneStep >= 2
          ? _c("div", { staticClass: "done" })
          : _c("div", { staticClass: "actions" }, [
              _c(
                "a",
                {
                  staticClass: "button",
                  class: {
                    disabled: _vm.doneStep != 1 || !_vm.item.underwriting
                  },
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.doneStep == 1 && _vm.setStatus()
                    }
                  }
                },
                [_vm._v("Set")]
              )
            ])
      ]),
      _vm._v(" "),
      _c("li", [
        _c("label", [_vm._v("Sign Application")]),
        _vm._v(" "),
        _vm.doneStep >= 3
          ? _c("div", { staticClass: "done" })
          : _c("div", { staticClass: "actions" }, [
              _c(
                "a",
                {
                  staticClass: "button",
                  class: { disabled: _vm.doneStep != 2 },
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.doneStep == 2 && _vm.uploadSignedApplication()
                    }
                  }
                },
                [_vm._v("Upload")]
              )
            ])
      ]),
      _vm._v(" "),
      _c("li", [
        _c("label", [_vm._v("Website Check")]),
        _vm._v(" "),
        _vm.doneStep >= 4
          ? _c("div", { staticClass: "done" })
          : _c("div", { staticClass: "actions" }, [
              _c(
                "a",
                {
                  staticClass: "button",
                  class: { disabled: _vm.doneStep != 3 },
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.doneStep == 3 && _vm.startWebsiteCheck()
                    }
                  }
                },
                [_vm._v("Run")]
              )
            ])
      ]),
      _vm._v(" "),
      _c("li", [
        _c("label", [_vm._v("Run Pre-Check")]),
        _vm._v(" "),
        _vm.doneStep >= 5
          ? _c("div", { staticClass: "done" })
          : _c("div", { staticClass: "actions" }, [
              _c(
                "a",
                {
                  staticClass: "button",
                  class: { disabled: _vm.doneStep != 4 },
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.doneStep == 4 && _vm.runPrecheck()
                    }
                  }
                },
                [_vm._v("Run")]
              )
            ])
      ]),
      _vm._v(" "),
      _c("li", [
        _c("label", [_vm._v("Compliance Scans")]),
        _vm._v(" "),
        _vm.doneStep >= 7 || _vm.complianceDone2
          ? _c("div", { staticClass: "done" })
          : _vm.doneStep >= 6
          ? _c("div", { staticClass: "actions" }, [
              _c(
                "a",
                {
                  staticClass: "button done",
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.doneStep == 6 && (_vm.complianceDone = false)
                    }
                  }
                },
                [_vm._v("Skipped")]
              )
            ])
          : _c("div", { staticClass: "actions" }, [
              _c(
                "a",
                {
                  staticClass: "button",
                  class: { disabled: _vm.doneStep != 5 },
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.doneStep == 5 && (_vm.complianceDone = true)
                    }
                  }
                },
                [_vm._v("Skip")]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "button",
                  class: { disabled: _vm.doneStep != 5 },
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.doneStep == 5 &&
                        (_vm.complianceDone = true) &&
                        (_vm.complianceDone2 = true) &&
                        (_vm.$parent.actionsTab = 3)
                    }
                  }
                },
                [_vm._v("Scan")]
              )
            ])
      ]),
      _vm._v(" "),
      _c("li", [
        _c("label", [_vm._v("Risk Exposure Calculator")]),
        _vm._v(" "),
        _vm.doneStep >= 7
          ? _c("div", { staticClass: "done" })
          : _c("div", { staticClass: "actions" }, [
              _c(
                "a",
                {
                  staticClass: "button",
                  class: { disabled: _vm.doneStep != 6 },
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.doneStep == 6 && _vm.runRisk()
                    }
                  }
                },
                [_vm._v("Run")]
              )
            ])
      ]),
      _vm._v(" "),
      _c("li", [
        _c("label", [_vm._v("Create Agreement")]),
        _vm._v(" "),
        _vm.doneStep >= 8
          ? _c("div", { staticClass: "done" })
          : _c("div", { staticClass: "actions" }, [
              _c(
                "a",
                {
                  staticClass: "button",
                  class: { disabled: _vm.doneStep != 7 },
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.doneStep == 7 && _vm.createAgreement()
                    }
                  }
                },
                [_vm._v("Start")]
              )
            ])
      ]),
      _vm._v(" "),
      _c("li", [
        _c("label", [_vm._v("Sign Agreement")]),
        _vm._v(" "),
        _vm.doneStep >= 9
          ? _c("div", { staticClass: "done" })
          : _c("div", { staticClass: "actions" }, [
              _c(
                "a",
                {
                  staticClass: "button",
                  class: { disabled: _vm.doneStep != 8 },
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.doneStep == 8 && _vm.signAgreement()
                    }
                  }
                },
                [_vm._v("Start")]
              )
            ])
      ]),
      _vm._v(" "),
      _c("li", [
        _c("label", [_vm._v("Pre-Check")]),
        _vm._v(" "),
        _vm.doneStep >= 11
          ? _c("div", { staticClass: "done" })
          : _vm.doneStep >= 10
          ? _c("div", { staticClass: "actions" }, [
              _c(
                "a",
                {
                  staticClass: "button done",
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.doneStep == 10 && _vm.secondaryPrecheck == false
                    }
                  }
                },
                [_vm._v("Skipped")]
              )
            ])
          : _c("div", { staticClass: "actions" }, [
              _c(
                "a",
                {
                  staticClass: "button",
                  class: { disabled: _vm.doneStep != 9 },
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.doneStep == 9 && _vm.setSecondaryPrecheck("skip")
                    }
                  }
                },
                [_vm._v("Skip")]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "button",
                  class: { disabled: _vm.doneStep != 9 },
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.doneStep == 9 && _vm.setSecondaryPrecheck("run")
                    }
                  }
                },
                [_vm._v("Run")]
              )
            ])
      ]),
      _vm._v(" "),
      _c("li", [
        _c("label", [_vm._v("Final Approval")]),
        _vm._v(" "),
        _vm.doneStep >= 11
          ? _c("div", { staticClass: "done" })
          : _c("div", { staticClass: "actions" }, [
              _c(
                "a",
                {
                  staticClass: "button",
                  class: { disabled: _vm.doneStep != 10 },
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.doneStep == 10 && _vm.doFinalApproval()
                    }
                  }
                },
                [_vm._v("Start")]
              )
            ])
      ]),
      _vm._v(" "),
      _c("li", [
        _c("label", [_vm._v("Boarding")]),
        _vm._v(" "),
        _vm.doneStep >= 12
          ? _c("div", { staticClass: "done" })
          : _c("div", { staticClass: "actions" }, [
              _c(
                "a",
                {
                  staticClass: "button",
                  class: { disabled: _vm.doneStep != 11 },
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.doneStep == 11 && _vm.doBoarding()
                    }
                  }
                },
                [_vm._v("Confirm")]
              )
            ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }