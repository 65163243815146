var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "idv-widget" }, [
    _c("h1", [_vm._v("Identity Verification")]),
    _vm._v(" "),
    _c("div", {}, [
      _vm.step == 0
        ? _c("div", [
            _c("p", [_vm._v("Lorem Ipsum Text")]),
            _vm._v(" "),
            _c("div", [
              _c(
                "button",
                {
                  staticClass: "ch-button btn-success",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.step++
                    }
                  }
                },
                [_vm._v("Start")]
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.step == 1
        ? _c("div", [
            _c("label", [_vm._v("Select your country")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "field" },
              [
                _c("validator", { attrs: { reqs: "required" } }, [
                  _c(
                    "div",
                    { staticClass: "combo-select" },
                    [
                      _c("dyn-select", {
                        attrs: { options: _vm.validCountries },
                        model: {
                          value: _vm.country,
                          callback: function($$v) {
                            _vm.country = $$v
                          },
                          expression: "country"
                        }
                      })
                    ],
                    1
                  )
                ])
              ],
              1
            ),
            _vm._v(" "),
            _vm.country
              ? _c("div", [
                  _c("label", [_vm._v("Select Document Type")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "field" },
                    [
                      _c("validator", { attrs: { reqs: "required" } }, [
                        _c(
                          "div",
                          { staticClass: "combo-select" },
                          [
                            _c("dyn-select", {
                              attrs: { options: _vm.documentTypeOptions },
                              model: {
                                value: _vm.docType,
                                callback: function($$v) {
                                  _vm.docType = $$v
                                },
                                expression: "docType"
                              }
                            })
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.docType
              ? _c("div", { staticStyle: { "margin-bottom": "10px" } }, [
                  _c(
                    "button",
                    {
                      staticClass: "ch-button btn-success",
                      on: { click: _vm.startCamera }
                    },
                    [_vm._v("Capture Photo")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "ch-button btn-success",
                      on: { click: _vm.uploadDocumentFile }
                    },
                    [_vm._v("File Upload")]
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", [
              _c(
                "button",
                {
                  staticClass: "ch-button btn-primary",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.step--
                    }
                  }
                },
                [_vm._v("Back")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "ch-button btn-success",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.saveImage.apply(null, arguments)
                    }
                  }
                },
                [_vm._v("Next")]
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.step == 2
        ? _c("div", [
            _c(
              "button",
              {
                staticClass: "ch-button btn-primary",
                on: { click: _vm.openFaceCapture }
              },
              [_vm._v("Capture Liveness Photo")]
            ),
            _vm._v(" "),
            _c("div", { staticStyle: { "margin-top": "10px" } }, [
              _c(
                "button",
                {
                  staticClass: "ch-button btn-primary",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.step--
                    }
                  }
                },
                [_vm._v("Next")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "ch-button btn-success",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.saveFace.apply(null, arguments)
                    }
                  }
                },
                [_vm._v("Finish")]
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.step == 3
        ? _c("div", [
            _c("p", [
              _vm._v(
                "Your Identity Verification has been submitted for verification"
              )
            ]),
            _vm._v(" "),
            _c("div")
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _vm.simpleVideoShown
      ? _c("div", { staticClass: "camera simple-camera-container" }, [
          _c("div", { staticClass: "simple-camera" }, [
            _c("video", { ref: "videoout" }, [
              _vm._v("Video stream not available.")
            ])
          ]),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "capture",
              attrs: { href: "" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.capturePhoto.apply(null, arguments)
                }
              }
            },
            [_vm._v("Capture")]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "cancel",
              attrs: { href: "" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.cancelSimpleCamera.apply(null, arguments)
                }
              }
            },
            [_vm._v("Cancel")]
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "camera" }, [
      _c("div", { attrs: { id: "acuant-camera" } }),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "cancel",
          attrs: { href: "" },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.cancelCapture.apply(null, arguments)
            }
          }
        },
        [_vm._v("Cancel")]
      )
    ]),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _vm._m(1),
    _vm._v(" "),
    _c(
      "div",
      { staticStyle: { display: "none" }, attrs: { id: "result-id" } },
      [
        _c(
          "p",
          { staticStyle: { display: "none" }, attrs: { id: "result-error" } },
          [
            _vm._v(
              "An error has occurred while cropping the image. Please try again."
            )
          ]
        ),
        _vm._v(" "),
        _c("img", {
          staticClass: "fit-screen",
          staticStyle: { display: "block" },
          attrs: { id: "result-id-img" }
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: false,
                expression: "false"
              }
            ],
            staticStyle: { "margin-top": "1%" }
          },
          [
            _c("div", [
              _c(
                "button",
                {
                  staticClass: "btn btn-success",
                  on: { click: _vm.saveImage }
                },
                [_vm._v("Save")]
              )
            ])
          ]
        )
      ]
    ),
    _vm._v(" "),
    _vm._m(2)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticStyle: { display: "none" }, attrs: { id: "acuant-loader" } },
      [
        _c("div", {}, [
          _c("div", {
            staticClass: "loader",
            staticStyle: { margin: "20% auto 0 auto" }
          })
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "face-camera-container",
        attrs: { id: "acuant-face-capture-container" }
      },
      [
        _c("div", {
          staticClass: "detection-state",
          attrs: { id: "face-detection-text" }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "modal-content",
        staticStyle: { display: "none", "margin-top": "30%" },
        attrs: { id: "acuant-modal" }
      },
      [
        _c("span", { staticClass: "close" }, [_vm._v("×")]),
        _vm._v(" "),
        _c("div", { staticClass: "row", staticStyle: { margin: "3%" } }, [
          _c("h3", { attrs: { id: "acuant-modal-text" } })
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "row text-right", staticStyle: { margin: "3%" } },
          [
            _c(
              "button",
              {
                staticClass: "btn btn-success mr-2",
                attrs: { onClick: "startManualCapture()" }
              },
              [_vm._v("Start Manual Capture")]
            )
          ]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }