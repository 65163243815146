var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("p", [_vm._v("Submission")]),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _c("ul", { staticClass: "appview-list" }, [
      _c("li", [
        _c("label", [_vm._v("Provider")]),
        _vm._v(" "),
        _c("div", [
          _vm._v(
            "\n        " +
              _vm._s(
                _vm.selectedProvider ? _vm.selectedProvider.providerName : ""
              ) +
              "\n      "
          )
        ])
      ]),
      _vm._v(" "),
      _c("li", [
        _c("label", [_vm._v("Label")]),
        _vm._v(" "),
        _c("div", [
          _vm._v(
            "\n        " +
              _vm._s(_vm.selectedProvider ? _vm.selectedProvider.label : "") +
              "\n      "
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "li",
        [
          _c("label", [_vm._v("IDV")]),
          _vm._v(" "),
          _c(
            "validator",
            [_c("dyn-select", { attrs: { options: _vm.yesnoOptions } })],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _vm._m(2)
    ]),
    _vm._v(" "),
    _c("p", [_vm._v("Options")]),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _vm._m(3),
    _vm._v(" "),
    _c("p", [_vm._v("Messages")]),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _vm._m(4),
    _vm._v(" "),
    _c("div", { staticClass: "actions" }, [
      _c(
        "button",
        {
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.goBack.apply(null, arguments)
            }
          }
        },
        [_vm._v("Back")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          attrs: { disabled: "" },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.doNext.apply(null, arguments)
            }
          }
        },
        [_vm._v("Save & Finalize")]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("label", [_vm._v("Date/Time")]),
      _vm._v(" "),
      _c("div")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("label", [_vm._v("Account Number")]),
      _vm._v(" "),
      _c("div")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [_c("label", [_vm._v("Result")]), _vm._v(" "), _c("div")])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { display: "flex" } }, [
      _c("button", [_vm._v("Submit")]),
      _vm._v(" "),
      _c("button", [_vm._v("Documents")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "content-table-container file-list-container" },
      [
        _c("table", { staticClass: "file-list content-table" }, [
          _c("thead", [
            _c("tr", [
              _c("td", [_vm._v("Name")]),
              _vm._v(" "),
              _c("td", [_vm._v("AML")]),
              _vm._v(" "),
              _c("td", [_vm._v("CIP")]),
              _vm._v(" "),
              _c("td", [_vm._v("KYC")]),
              _vm._v(" "),
              _c("td", [_vm._v("Messages")])
            ])
          ])
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }