var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "content-table-container resources dload" }, [
      _c("table", { staticClass: "content-table resources dload" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "tbody",
          [
            _vm._l(_vm.items, function(item, index) {
              return _c("tr", [
                _c(
                  "td",
                  { staticClass: "text-left", staticStyle: { width: "35%" } },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.formatDate(
                            item.created,
                            "MMMM D, YYYY [at] h:mm A z"
                          )
                        ) +
                        "\n          "
                    )
                  ]
                ),
                _vm._v(" "),
                _c("td", { staticStyle: { width: "25%" } }, [
                  _c("span", { staticClass: "username" }, [
                    _vm._v(_vm._s(item.admin && item.admin.username))
                  ])
                ]),
                _vm._v(" "),
                _c("td", { staticStyle: { width: "30%" } }, [
                  _c(
                    "a",
                    {
                      staticClass: "fname",
                      attrs: {
                        target: "_blank",
                        href: _vm.basepath + "/" + item.file_name
                      }
                    },
                    [_vm._v(_vm._s(item.file_name))]
                  )
                ]),
                _vm._v(" "),
                _c("td", { staticStyle: { width: "10%" } }, [
                  _c(
                    "a",
                    {
                      staticClass: "remove",
                      attrs: { href: "" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.deleteItem(index)
                        }
                      }
                    },
                    [_vm._v("Delete")]
                  )
                ])
              ])
            }),
            _vm._v(" "),
            _vm._m(1)
          ],
          2
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Created")]),
        _vm._v(" "),
        _c("th", [_vm._v("User")]),
        _vm._v(" "),
        _c("th", [_vm._v("File Name")]),
        _vm._v(" "),
        _c("th", [_vm._v("Options")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "inend" }, [
      _c("td", { attrs: { colspan: "4" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }