var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "element-checkbox-line" }, [
    _c("label", { class: { checked: _vm.value } }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.actualValue,
            expression: "actualValue"
          }
        ],
        attrs: { type: "checkbox" },
        domProps: {
          checked: Array.isArray(_vm.actualValue)
            ? _vm._i(_vm.actualValue, null) > -1
            : _vm.actualValue
        },
        on: {
          change: [
            function($event) {
              var $$a = _vm.actualValue,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.actualValue = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.actualValue = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.actualValue = $$c
              }
            },
            _vm.update
          ]
        }
      }),
      _vm._v(" "),
      _c("span")
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "title",
        on: {
          click: function($event) {
            $event.preventDefault()
            _vm.more = !_vm.more
          }
        }
      },
      [
        _vm._t("default"),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.more,
                expression: "more"
              }
            ],
            staticClass: "more"
          },
          [_vm._t("more")],
          2
        )
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "toggleMore",
        class: { opened: _vm.more },
        on: {
          click: function($event) {
            $event.preventDefault()
            _vm.more = !_vm.more
          }
        }
      },
      [_c("a", { attrs: { href: "" } })]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }