var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "company-select" },
    [
      _c("dyn-select", {
        attrs: { options: _vm.types, value: _vm.type },
        on: {
          input: function($event) {
            return _vm.$emit("update:type", $event)
          }
        }
      }),
      _vm._v(" "),
      !_vm.isNew
        ? _c("dyn-select", {
            attrs: { options: _vm.itemsToSelect, value: _vm.value },
            on: { input: _vm.selectCompany }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }