var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-table-container file-list-container" },
    [
      _c("table", { staticClass: "file-list content-table" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "tbody",
          _vm._l(_vm.emails, function(email) {
            return _c("tr", [
              _c("td", [_vm._v(_vm._s(email.emailType))]),
              _vm._v(" "),
              _c("td", [
                _vm._v(
                  _vm._s(_vm.formatDate(email.created, "YYYY-MM-DD HH:mm:ss"))
                )
              ])
            ])
          }),
          0
        )
      ]),
      _vm._v(" "),
      _c("portal", { attrs: { to: "actions" } }, [
        _c("ul", [
          _c("li", [
            _c(
              "a",
              {
                attrs: { href: "" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.sendEmailUser("ApplicationReminder")
                  }
                }
              },
              [_vm._v("Application Reminder")]
            )
          ]),
          _vm._v(" "),
          _c("li", [
            _c(
              "a",
              {
                attrs: { href: "" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.sendEmailUser("ApplicationAtt")
                  }
                }
              },
              [_vm._v("Applications")]
            )
          ]),
          _vm._v(" "),
          _c("li", [
            _c(
              "a",
              {
                attrs: { href: "" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.sendEmailUser("Declined")
                  }
                }
              },
              [_vm._v("Declined")]
            )
          ]),
          _vm._v(" "),
          _c("li", [
            _c(
              "a",
              {
                attrs: { href: "" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.sendEmailUser("AgreementAtt")
                  }
                }
              },
              [_vm._v("Agreements")]
            )
          ]),
          _vm._v(" "),
          _c("li", [
            _c(
              "a",
              {
                attrs: { href: "" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.sendEmailUser("Approved")
                  }
                }
              },
              [_vm._v("Approved")]
            )
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Type")]),
        _vm._v(" "),
        _c("th", [_vm._v("Created")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }