var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("p", [_vm._v("Profile Settings")]),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _c("ul", { staticClass: "appview-list" }, [
      _c(
        "li",
        [
          _c("label", [_vm._v("Relationship Manager")]),
          _vm._v(" "),
          _c(
            "validator",
            { attrs: { value: _vm.item.manager, reqs: "required" } },
            [
              _c("dyn-select", {
                attrs: { options: _vm.sourcesToShow },
                model: {
                  value: _vm.item.manager,
                  callback: function($$v) {
                    _vm.$set(_vm.item, "manager", $$v)
                  },
                  expression: "item.manager"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("li", [
        _c("label", [_vm._v("Referrer")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "validator" },
          [
            _c("dyn-select", {
              attrs: { options: _vm.usersToShow },
              model: {
                value: _vm.item.agentDb.referrer,
                callback: function($$v) {
                  _vm.$set(_vm.item.agentDb, "referrer", $$v)
                },
                expression: "item.agentDb.referrer"
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "li",
        [
          _c("label", [_vm._v("Language")]),
          _vm._v(" "),
          _c(
            "validator",
            { attrs: { value: _vm.item.agentDb.language, reqs: "required" } },
            [
              _c("dyn-select", {
                attrs: { options: _vm.languages },
                model: {
                  value: _vm.item.agentDb.language,
                  callback: function($$v) {
                    _vm.$set(_vm.item.agentDb, "language", $$v)
                  },
                  expression: "item.agentDb.language"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "actions" }, [
      _c(
        "button",
        {
          attrs: { disabled: !_vm.item.manager || !_vm.item.agentDb.language },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.doNext.apply(null, arguments)
            }
          }
        },
        [_vm._v("Save & Continue")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }