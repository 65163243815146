var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "ncontent view-profile" },
    [
      _vm.header
        ? [
            _c("a", { staticClass: "back-button" }, [_vm._v("Dashboard")]),
            _vm._v(" "),
            _c("div", { staticClass: "header" }, [
              _c("div", [
                _c(
                  "ul",
                  { staticClass: "breadcrumbs" },
                  _vm._l(_vm.header, function(headerItem) {
                    return _c("li", [
                      _c("a", { attrs: { href: "" } }, [
                        _vm._v(_vm._s(headerItem))
                      ])
                    ])
                  }),
                  0
                )
              ])
            ])
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "tabs-header" }, [_vm._t("tabsheader")], 2),
      _vm._v(" "),
      _c("div", { staticClass: "tabs-main" }, [_vm._t("tabsmain")], 2),
      _vm._v(" "),
      _vm.$slots.tabssub
        ? _c("div", { staticClass: "tabs-sub" }, [_vm._t("tabssub")], 2)
        : _vm._e(),
      _vm._v(" "),
      _vm.$slots.actions
        ? _c("div", { staticClass: "actions" }, [_vm._t("actions")], 2)
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "content" }, [_vm._t("default")], 2)
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }