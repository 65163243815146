var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "preform-outer" }, [
    _c("div", { staticClass: "preformbg" }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "ncontent appf appnat_ preform pfx" },
      [
        _c(
          "div",
          {
            staticClass: "cb-logo",
            style: {
              "background-image":
                'url("/files/company/' + _vm.companydata.appHeadLogo + '")'
            }
          },
          [_vm._v("CardBiller")]
        ),
        _vm._v(" "),
        _vm.step < 7 ? _c("h1", [_vm._v("Merchant Application")]) : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "form-info" }, [
          _c("p", [_vm._v(_vm._s(_vm.companydata.appHeadText))]),
          _vm._v(" "),
          _c("p", [
            _c("strong", [_vm._v(_vm._s(_vm.companydata.appHeadEmail))])
          ]),
          _vm._v(" "),
          _c("p", [
            _c("strong", [_vm._v(_vm._s(_vm.companydata.appHeadPhone))])
          ])
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "form-intro" }, [
          _vm._v("\n    " + _vm._s(_vm.companydata.appHeadDesc) + "\n  ")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "temp-link" }, [
          _c("label", [_vm._v("Guest access to this application:")]),
          _vm._v(" "),
          _c(
            "a",
            {
              attrs: { href: _vm.link.url, target: "_blank" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.copyLink.apply(null, arguments)
                }
              }
            },
            [_vm._v(_vm._s(_vm.link.url))]
          )
        ]),
        _vm._v(" "),
        _c("form-progress", {
          attrs: { step: _vm.step, steps: ["Questionnaire"] }
        }),
        _vm._v(" "),
        _vm.step == 0
          ? _c("form-apply-6a", {
              attrs: { app: _vm.app, onlyStep: true },
              on: { generate: _vm.generate }
            })
          : _vm.step == 1
          ? _c("div", { staticClass: "pre-done" }, [
              _c("h1", [_vm._v("Thank you for your application")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "One of our relationship managers will contact you shortly!"
                )
              ])
            ])
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }