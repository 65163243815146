var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        "enable-background": "new 0 0 64 64",
        id: "Layer_1",
        version: "1.1",
        viewBox: "0 0 64 64",
        "xml:space": "preserve",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        fill: _vm.color,
        width: _vm.size + "px",
        height: _vm.size + "px"
      }
    },
    [
      _c("g", [
        _c("path", {
          attrs: {
            d:
              "M59,10H5c-2.2060547,0-4,1.7944336-4,4v6c0,0.7675781,0.4394531,1.4672852,1.1308594,1.8012695l29,14   C31.4052734,35.9335938,31.7021484,36,32,36s0.5947266-0.0664063,0.8691406-0.1987305l29-14   C62.5605469,21.4672852,63,20.7675781,63,20v-6C63,11.7944336,61.2060547,10,59,10z M59,18.7446289l-27,13.034668L5,18.7446289V14   h54V18.7446289z"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M61,25c-1.1044922,0-2,0.8955078-2,2v23H5V27c0-1.1044922-0.8955078-2-2-2s-2,0.8955078-2,2v23   c0,2.2055664,1.7939453,4,4,4h54c2.2060547,0,4-1.7944336,4-4V27C63,25.8955078,62.1044922,25,61,25z"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M54,46c1.1044922,0,2-0.8955078,2-2s-0.8955078-2-2-2h-6c-1.1044922,0-2,0.8955078-2,2s0.8955078,2,2,2H54z"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }