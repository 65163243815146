var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "acc-list", staticStyle: { display: "block" } },
    [
      _c("table", {}, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "tbody",
          _vm._l(_vm.logins, function(item, index) {
            return _c("tr", [
              _c("td", { staticClass: "main-col" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.usersById[item.userId] &&
                        _vm.usersById[item.userId].username
                    ) +
                    "\n        "
                )
              ]),
              _vm._v(" "),
              _c("td", {}, [
                _vm._v("\n          " + _vm._s(item.recorded) + "\n        ")
              ]),
              _vm._v(" "),
              _c("td", {}, [
                _vm._v("\n          " + _vm._s(item.ipAddress) + "\n        ")
              ])
            ])
          }),
          0
        )
      ]),
      _vm._v(" "),
      _c("portal", { attrs: { to: "actions" } }, [
        _c(
          "ul",
          [
            _vm._l(_vm.actions, function(optLabel, optMethod) {
              return _c("li", [
                _c(
                  "a",
                  {
                    attrs: { href: "" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.$parent.$parent.doAction(optMethod)
                      }
                    }
                  },
                  [_vm._v(_vm._s(optMethod))]
                )
              ])
            })
          ],
          2
        )
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticStyle: { width: "25%" } }, [_vm._v("Username")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "25%" } }, [_vm._v("Time")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "25%" } }, [_vm._v("Data transfer")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "25%" } }, [_vm._v("IP Address")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }