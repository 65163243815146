var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.newCheck && !_vm.uwShown
        ? _c("div", { staticClass: "action-sub-buttons" }, [
            _c("a", { staticClass: "btn", on: { click: _vm.newWebCheck } }, [
              _vm._v("New")
            ]),
            _vm._v(" "),
            _vm.uwSelected
              ? _c(
                  "a",
                  {
                    staticClass: "btn",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.doShowItems(_vm.uwSelected)
                      }
                    }
                  },
                  [_vm._v("View")]
                )
              : _vm._e()
          ])
        : _c("div", { staticClass: "action-sub-buttons" }, [
            _c(
              "a",
              {
                staticClass: "btn",
                on: {
                  click: function($event) {
                    _vm.uwShown = null
                    _vm.newCheck = null
                  }
                }
              },
              [_vm._v("Back")]
            ),
            _vm._v(" "),
            _vm.uwShown
              ? _c(
                  "a",
                  {
                    staticClass: "btn",
                    attrs: { href: _vm.printUrl, target: "_blank" }
                  },
                  [_vm._v("Print")]
                )
              : _vm._e()
          ]),
      _vm._v(" "),
      !_vm.newCheck && !_vm.uwShown
        ? _c("table", { staticClass: "file-list content-table" }, [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "tbody",
              _vm._l(_vm.uwList, function(uwItem, uwI) {
                return _c("tr", [
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.uwSelected,
                          expression: "uwSelected"
                        }
                      ],
                      attrs: { type: "radio" },
                      domProps: {
                        value: uwItem.id,
                        checked: _vm._q(_vm.uwSelected, uwItem.id)
                      },
                      on: {
                        change: function($event) {
                          _vm.uwSelected = uwItem.id
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(_vm.uwList.length - uwI))]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(uwItem.companyName))]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        _vm.formatDate(uwItem.createdDate, "YYYY-MM-DD HH:mm")
                      )
                    )
                  ])
                ])
              }),
              0
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.newCheck
        ? _c(
            "div",
            { staticClass: "check-form" },
            [
              _vm._l(_vm.agent ? _vm.agentFields : _vm.fields, function(field) {
                return [
                  _c(
                    "validator",
                    {
                      staticClass: "check-field",
                      attrs: { value: _vm.newCheck[field[0]], reqs: "required" }
                    },
                    [
                      _c("label", [
                        _vm._v(_vm._s(field[1]) + " - "),
                        _c("span", [_vm._v(_vm._s(field[2]))])
                      ]),
                      _vm._v(" "),
                      _c("formel-toggle", {
                        model: {
                          value: _vm.newCheck[field[0]],
                          callback: function($$v) {
                            _vm.$set(_vm.newCheck, field[0], $$v)
                          },
                          expression: "newCheck[field[0]]"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.newCheck[field[0]] === "0"
                    ? _c(
                        "validator",
                        {
                          staticClass: "check-desc",
                          attrs: {
                            value: _vm.newCheck[field[0] + "Desc"],
                            reqs: "required"
                          }
                        },
                        [
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.newCheck[field[0] + "Desc"],
                                expression: "newCheck[field[0]+'Desc']"
                              }
                            ],
                            domProps: {
                              value: _vm.newCheck[field[0] + "Desc"]
                            },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.newCheck,
                                  field[0] + "Desc",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ]
                      )
                    : _vm._e()
                ]
              }),
              _vm._v(" "),
              _c("div", { staticClass: "actions" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-large",
                    on: { click: _vm.saveNewCheck }
                  },
                  [_vm._v("Save")]
                )
              ])
            ],
            2
          )
        : _vm.uwShown
        ? _c(
            "div",
            { staticClass: "web-check-details" },
            _vm._l(_vm.agent ? _vm.agentFields : _vm.fields, function(field) {
              return _c("div", { staticClass: "check-result" }, [
                _c("label", [
                  _vm._v(_vm._s(field[1]) + " - "),
                  _c("span", [_vm._v(_vm._s(field[2]))])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "val" }, [
                  _vm._v(_vm._s(_vm.uwShownItem[field[0]] == 0 ? "No" : "Yes"))
                ]),
                _vm._v(" "),
                _vm.uwShownItem[field[0]] == 0
                  ? _c("span", { staticClass: "note" }, [
                      _vm._v(_vm._s(_vm.uwShownItem[field[0] + "Desc"]))
                    ])
                  : _vm._e()
              ])
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _c("portal", { attrs: { to: "actions" } }, [
        _c("ul", [
          _c("li", [
            !_vm.newCheck && !_vm.uwShown
              ? _c(
                  "a",
                  {
                    attrs: { href: "" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.newWebCheck()
                      }
                    }
                  },
                  [_vm._v("New")]
                )
              : _c(
                  "a",
                  {
                    attrs: { href: "" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        _vm.uwShown = null
                        _vm.newCheck = null
                      }
                    }
                  },
                  [_vm._v("Back")]
                )
          ]),
          _vm._v(" "),
          _vm.uwShown
            ? _c("li", [
                _c("a", { attrs: { href: _vm.printUrl, target: "_blank" } }, [
                  _vm._v("Print")
                ])
              ])
            : _vm._e()
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticStyle: { width: "1%" } }),
        _vm._v(" "),
        _c("th", [_vm._v("ID")]),
        _vm._v(" "),
        _c("th", [_vm._v("Legal Name")]),
        _vm._v(" "),
        _c("th", [_vm._v("Date")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }