var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "element-toggle slin" }, [
    _c("label", { class: { checked: _vm.value == _vm.yesVal } }, [
      _c("span"),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.actualValue,
            expression: "actualValue"
          }
        ],
        attrs: { type: "radio" },
        domProps: {
          value: _vm.yesVal,
          checked: _vm._q(_vm.actualValue, _vm.yesVal)
        },
        on: {
          change: [
            function($event) {
              _vm.actualValue = _vm.yesVal
            },
            _vm.updateVal
          ]
        }
      }),
      _vm._v("\n    Yes\n  ")
    ]),
    _vm._v(" "),
    _c("label", { class: { checked: _vm.value == _vm.noVal } }, [
      _c("span"),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.actualValue,
            expression: "actualValue"
          }
        ],
        attrs: { type: "radio" },
        domProps: {
          value: _vm.noVal,
          checked: _vm._q(_vm.actualValue, _vm.noVal)
        },
        on: {
          change: [
            function($event) {
              _vm.actualValue = _vm.noVal
            },
            _vm.updateVal
          ]
        }
      }),
      _vm._v("\n    No\n  ")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }