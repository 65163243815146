import { render, staticRenderFns } from "./legal-payments.vue?vue&type=template&id=8eceabae&scoped=true&"
import script from "./legal-payments.vue?vue&type=script&lang=js&"
export * from "./legal-payments.vue?vue&type=script&lang=js&"
import style0 from "./legal-payments.vue?vue&type=style&index=0&id=8eceabae&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8eceabae",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/c6cbhq/assets/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('8eceabae')) {
      api.createRecord('8eceabae', component.options)
    } else {
      api.reload('8eceabae', component.options)
    }
    module.hot.accept("./legal-payments.vue?vue&type=template&id=8eceabae&scoped=true&", function () {
      api.rerender('8eceabae', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "js/legal-payments.vue"
export default component.exports