var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "action-sub-buttons" }, [
        _vm.uwSelected
          ? _c(
              "a",
              {
                staticClass: "btn",
                attrs: {
                  href:
                    "/applications/" +
                    this.item.id +
                    "/intakepdf/" +
                    _vm.uwSelected,
                  target: "_blank"
                }
              },
              [_vm._v("View")]
            )
          : _vm._e(),
        _vm._v(" "),
        this.item.hasIntake &&
        _vm.uwSelected &&
        _vm.uwSelected == _vm.uwList[0].id
          ? _c(
              "a",
              {
                staticClass: "btn",
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.updateApp.apply(null, arguments)
                  }
                }
              },
              [_vm._v("Update")]
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("table", { staticClass: "file-list content-table" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "tbody",
          _vm._l(_vm.uwList, function(uwItem, uwI) {
            return _c("tr", [
              _c("td", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.uwSelected,
                      expression: "uwSelected"
                    }
                  ],
                  attrs: { type: "radio" },
                  domProps: {
                    value: uwItem.id,
                    checked: _vm._q(_vm.uwSelected, uwItem.id)
                  },
                  on: {
                    change: function($event) {
                      _vm.uwSelected = uwItem.id
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(_vm.uwList.length - uwI))]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(_vm.item.companyName))]),
              _vm._v(" "),
              _c("td", [
                _vm._v(
                  _vm._s(_vm.formatDate(uwItem.submitted, "YYYY-MM-DD HH:mm"))
                )
              ])
            ])
          }),
          0
        )
      ]),
      _vm._v(" "),
      _c("portal", { attrs: { to: "actions" } }, [
        _c("ul", [
          _vm.uwSelected
            ? _c("li", [
                _c(
                  "a",
                  {
                    attrs: {
                      href:
                        "/applications/" +
                        _vm.item.id +
                        "/intakepdf/" +
                        _vm.uwSelected,
                      target: "_blank"
                    }
                  },
                  [_vm._v("View")]
                )
              ])
            : _vm._e()
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticStyle: { width: "1%" } }),
        _vm._v(" "),
        _c("th", [_vm._v("ID")]),
        _vm._v(" "),
        _c("th", [_vm._v("Legal Name")]),
        _vm._v(" "),
        _c("th", [_vm._v("Date")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }