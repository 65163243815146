var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "form-business" } },
    [
      _vm.step === undefined || _vm.step === 1
        ? [
            _c("h4", [
              _vm._v(_vm._s(_vm.$t("register.business_acc_info_header")) + " "),
              _c("span", [_vm._v("*")])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-row form-split" },
              [
                _c("form-field-text", {
                  attrs: {
                    validator: _vm.v.companyName,
                    label: _vm.$t("register.buss_legal_name"),
                    maxlength: 50,
                    required: ""
                  },
                  model: {
                    value: _vm.form.companyName,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "companyName", $$v)
                    },
                    expression: "form.companyName"
                  }
                }),
                _vm._v(" "),
                _c("form-field-text", {
                  attrs: {
                    validator: _vm.v.companyNameTrade,
                    label: _vm.$t("register.buss_dba_name"),
                    maxlength: 50,
                    required: ""
                  },
                  model: {
                    value: _vm.form.companyNameTrade,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "companyNameTrade", $$v)
                    },
                    expression: "form.companyNameTrade"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-row form-split" },
              [
                _c("form-field-date", {
                  attrs: {
                    dirty: _vm.v.$dirty,
                    label: _vm.$t("register.buss_inc_date"),
                    required: ""
                  },
                  model: {
                    value: _vm.form.companyDate,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "companyDate", $$v)
                    },
                    expression: "form.companyDate"
                  }
                }),
                _vm._v(" "),
                _c("form-field-text", {
                  attrs: {
                    validator: _vm.v.companyTaxId,
                    label: _vm.$t("register.buss_tax_name"),
                    maxlength: 20,
                    required: ""
                  },
                  model: {
                    value: _vm.form.companyTaxId,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "companyTaxId", $$v)
                    },
                    expression: "form.companyTaxId"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-row form-split" },
              [
                _c(
                  "div",
                  { staticStyle: { position: "relative" } },
                  [
                    _c("form-field-select", {
                      attrs: {
                        validator: _vm.v.companyAddressCountry,
                        label: _vm.$t("register.country_formation"),
                        options: _vm.countryOptions,
                        required: ""
                      },
                      model: {
                        value: _vm.form.companyAddressCountry,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "companyAddressCountry", $$v)
                        },
                        expression: "form.companyAddressCountry"
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "what-this",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            _vm.countryModal = true
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("register.prohibited_c")))]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.stateOptions
                  ? _c("form-field-select", {
                      attrs: {
                        validator: _vm.v.companyFormationState,
                        label: _vm.$t("register.state_formation"),
                        options: _vm.stateOptions,
                        maxlength: 25,
                        required: ""
                      },
                      model: {
                        value: _vm.form.companyFormationState,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "companyFormationState", $$v)
                        },
                        expression: "form.companyFormationState"
                      }
                    })
                  : _c("form-field-text", {
                      attrs: {
                        validator: _vm.v.companyFormationState,
                        label: _vm.$t("register.state_formation"),
                        required: ""
                      },
                      model: {
                        value: _vm.form.companyFormationState,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "companyFormationState", $$v)
                        },
                        expression: "form.companyFormationState"
                      }
                    })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-row form-fullw" },
              [
                _c("form-field-select", {
                  attrs: {
                    label: _vm.$t("register.business_types"),
                    options: _vm.businessTypeOptions,
                    validator: _vm.v.companyType,
                    required: ""
                  },
                  model: {
                    value: _vm.form.companyType,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "companyType", $$v)
                    },
                    expression: "form.companyType"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-row form-split" },
              [
                _c("form-field-text", {
                  attrs: {
                    label: _vm.$t("register.business_address"),
                    validator: _vm.v.companyAddress,
                    maxlength: 50,
                    required: ""
                  },
                  model: {
                    value: _vm.form.companyAddress,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "companyAddress", $$v)
                    },
                    expression: "form.companyAddress"
                  }
                }),
                _vm._v(" "),
                _c("form-field-text", {
                  attrs: {
                    validator: _vm.v.companyAddressCity,
                    label: _vm.$t("register.business_city"),
                    maxlength: 25,
                    required: ""
                  },
                  model: {
                    value: _vm.form.companyAddressCity,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "companyAddressCity", $$v)
                    },
                    expression: "form.companyAddressCity"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-row form-split" },
              [
                _c("form-field-text", {
                  attrs: {
                    label: _vm.$t("register.business_zip"),
                    validator: _vm.v.companyAddressPostcode,
                    maxlength: 15,
                    required: ""
                  },
                  model: {
                    value: _vm.form.companyAddressPostcode,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "companyAddressPostcode", $$v)
                    },
                    expression: "form.companyAddressPostcode"
                  }
                }),
                _vm._v(" "),
                _c("form-field-text", {
                  attrs: {
                    label: _vm.$t("register.business_phone"),
                    validator: _vm.v.companyPhone,
                    maxlength: 20,
                    required: ""
                  },
                  model: {
                    value: _vm.form.companyPhone,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "companyPhone", $$v)
                    },
                    expression: "form.companyPhone"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-row form-split" },
              [
                _c("form-field-text", {
                  attrs: {
                    label: _vm.$t("register.business_email"),
                    validator: _vm.v.companyEmail,
                    required: ""
                  },
                  model: {
                    value: _vm.form.companyEmail,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "companyEmail", $$v)
                    },
                    expression: "form.companyEmail"
                  }
                }),
                _vm._v(" "),
                _c("form-field-text", {
                  attrs: {
                    label: _vm.$t("register.business_website"),
                    validator: _vm.v.companyWebsite
                  },
                  model: {
                    value: _vm.form.companyWebsite,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "companyWebsite", $$v)
                    },
                    expression: "form.companyWebsite"
                  }
                })
              ],
              1
            )
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.step === undefined || _vm.step === 2
        ? [
            _vm._l(_vm.form.contacts, function(contact, index) {
              return _c(
                "div",
                { key: index },
                [
                  _c(
                    "h4",
                    [
                      _vm._v(_vm._s(_vm.$t("register.account_contacts")) + " "),
                      index ? [_vm._v(" " + _vm._s(index + 1))] : _vm._e(),
                      _vm._v(" "),
                      _c("span", [_vm._v("*")])
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("bisiness-contact", {
                    attrs: {
                      v: _vm.v.contacts.$each[index],
                      solution: _vm.solution
                    },
                    model: {
                      value: _vm.form.contacts[index],
                      callback: function($$v) {
                        _vm.$set(_vm.form.contacts, index, $$v)
                      },
                      expression: "form.contacts[index]"
                    }
                  })
                ],
                1
              )
            }),
            _vm._v(" "),
            _c("div", { staticClass: "form-row form-split" }, [
              _c("div", [
                _vm.form.contacts.length < 6
                  ? _c(
                      "a",
                      {
                        on: {
                          click: function($event) {
                            return _vm.$emit("add-contact")
                          }
                        }
                      },
                      [
                        _vm._v(
                          "+ " + _vm._s(_vm.$t("register.add_another_contact"))
                        )
                      ]
                    )
                  : _vm._e()
              ]),
              _vm._v(" "),
              _vm.form.contacts.length > 1
                ? _c(
                    "a",
                    {
                      on: {
                        click: function($event) {
                          return _vm.$emit("remove-contact")
                        }
                      }
                    },
                    [_vm._v("- " + _vm._s(_vm.$t("register.remove_contact")))]
                  )
                : _vm._e()
            ])
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.step === undefined || _vm.step === 3
        ? [
            _c("h4", [
              _vm._v(_vm._s(_vm.$t("register.business_desc_questions")))
            ]),
            _vm._v(" "),
            _vm._l(_vm.groupedQuestions, function(group, index) {
              return _c(
                "div",
                { key: "group_" + index, staticClass: "form-row form-split" },
                _vm._l(group, function(question) {
                  return _c("form-field-select", {
                    key: question.key,
                    attrs: {
                      label: question.question,
                      validator: _vm.v.questions[question.key],
                      options: question.options,
                      required: true
                    },
                    model: {
                      value: _vm.form.questions[question.key],
                      callback: function($$v) {
                        _vm.$set(_vm.form.questions, question.key, $$v)
                      },
                      expression: "form.questions[question.key]"
                    }
                  })
                }),
                1
              )
            })
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.step === undefined || _vm.step === 4
        ? [
            _c("h4", [_vm._v(_vm._s(_vm.$t("register.business_desc")))]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-row form-fullw" },
              [
                _c("form-field-textarea", {
                  attrs: {
                    label: _vm.$t("register.business_nature"),
                    rows: 3,
                    maxlength: 250,
                    validator: _vm.v.description,
                    required: true
                  },
                  model: {
                    value: _vm.form.description,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "description", $$v)
                    },
                    expression: "form.description"
                  }
                })
              ],
              1
            )
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.step === undefined || _vm.step === 5
        ? [
            _c("h4", [
              _vm._v(
                "\n    " +
                  _vm._s(_vm.$t("register.business_doc_submission")) +
                  "\n    "
              ),
              _c("span", [_vm._v("*")])
            ]),
            _vm._v(" "),
            _vm.form.submit_later
              ? _c("p", [
                  _vm._v(_vm._s(_vm.$t("register.final_acc_activation")))
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "form-row form-fullw",
                staticStyle: { position: "relative" }
              },
              [
                _c("form-field-upload", {
                  attrs: {
                    validator: _vm.v.doc_incorporation,
                    label:
                      _vm.form.companyName +
                      " " +
                      _vm.$t("register.business_inc_doc"),
                    disabled: _vm.form.submit_later,
                    required: ""
                  },
                  model: {
                    value: _vm.form.doc_incorporation,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "doc_incorporation", $$v)
                    },
                    expression: "form.doc_incorporation"
                  }
                }),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "what-this",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        _vm.incModal = true
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("register.whats_this")))]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "form-row form-fullw",
                staticStyle: { position: "relative" }
              },
              [
                _c("form-field-upload", {
                  attrs: {
                    validator: _vm.v.doc_address_confirmation,
                    label:
                      _vm.form.companyName +
                      " " +
                      _vm.$t("register.business_address_confirmation"),
                    disabled: _vm.form.submit_later,
                    required: ""
                  },
                  model: {
                    value: _vm.form.doc_address_confirmation,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "doc_address_confirmation", $$v)
                    },
                    expression: "form.doc_address_confirmation"
                  }
                }),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "what-this",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        _vm.addressModal = true
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("register.whats_this")))]
                )
              ],
              1
            ),
            _vm._v(" "),
            _vm._l(_vm.form.contacts, function(contact, index) {
              return _c(
                "div",
                { key: "docs_" + index },
                [
                  _vm._l(contact.id_types, function(type, idx) {
                    return _c(
                      "div",
                      {
                        key: "subdocs_" + idx,
                        staticClass: "form-row form-fullw",
                        staticStyle: { position: "relative" }
                      },
                      [
                        _c("form-field-upload", {
                          attrs: {
                            validator:
                              _vm.v.contacts.$each[index].id_types.$each[idx]
                                .document,
                            label:
                              contact.firstName +
                              " " +
                              contact.lastName +
                              " " +
                              (type.id_type ||
                                _vm.$t("register.contact_increment_ID_text")) +
                              " (10MB max)",
                            disabled: _vm.form.submit_later,
                            required: ""
                          },
                          model: {
                            value:
                              _vm.form.contacts[index].id_types[idx].document,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.form.contacts[index].id_types[idx],
                                "document",
                                $$v
                              )
                            },
                            expression:
                              "form.contacts[index].id_types[idx].document"
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "what-this",
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                _vm.issuedModal = true
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("register.whats_this")))]
                        )
                      ],
                      1
                    )
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      key: "subdocs_address",
                      staticClass: "form-row form-fullw",
                      staticStyle: { position: "relative" }
                    },
                    [
                      _c("form-field-upload", {
                        attrs: {
                          validator:
                            _vm.v.contacts.$each[index]
                              .doc_address_confirmation,
                          label:
                            contact.firstName +
                            " " +
                            contact.lastName +
                            " " +
                            _vm.$t("register.contact_increment_address_text") +
                            " (10MB max)",
                          disabled: _vm.form.submit_later,
                          required: ""
                        },
                        model: {
                          value:
                            _vm.form.contacts[index].doc_address_confirmation,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.form.contacts[index],
                              "doc_address_confirmation",
                              $$v
                            )
                          },
                          expression:
                            "form.contacts[index].doc_address_confirmation"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "what-this",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              _vm.issuedModal = true
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("register.whats_this")))]
                      )
                    ],
                    1
                  )
                ],
                2
              )
            })
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "popup",
        {
          attrs: { open: _vm.incModal },
          on: {
            "update:open": function($event) {
              _vm.incModal = $event
            }
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "scrollable",
              on: {
                "&scroll": function($event) {
                  return _vm.handleScroll.apply(null, arguments)
                }
              }
            },
            [
              _c("div", {}, [
                _c("h2", [
                  _c("strong", [
                    _vm._v(_vm._s(_vm.$t("register.bus_inc_doc_header")))
                  ])
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("register.bus_inc_doc_text")) +
                      "\n        "
                  )
                ])
              ])
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "popup",
        {
          attrs: { open: _vm.addressModal },
          on: {
            "update:open": function($event) {
              _vm.addressModal = $event
            }
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "scrollable",
              on: {
                "&scroll": function($event) {
                  return _vm.handleScroll.apply(null, arguments)
                }
              }
            },
            [
              _c("div", {}, [
                _c("h2", [
                  _c("strong", [
                    _vm._v(_vm._s(_vm.$t("register.bus_address_conf_header")))
                  ])
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("register.bus_address_conf_text")) +
                      "\n        "
                  )
                ])
              ])
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "popup",
        {
          attrs: { open: _vm.issuedModal },
          on: {
            "update:open": function($event) {
              _vm.issuedModal = $event
            }
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "scrollable",
              on: {
                "&scroll": function($event) {
                  return _vm.handleScroll.apply(null, arguments)
                }
              }
            },
            [
              _c("div", {}, [
                _c("h2", [
                  _c("strong", [
                    _vm._v(
                      _vm._s(_vm.$t("register.bus_gov_issues_ID_popup_header"))
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n         " +
                      _vm._s(_vm.$t("register.bus_gov_issues_ID_popup_line1")) +
                      "\n        "
                  )
                ]),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("register.bus_gov_issues_ID_popup_line2")) +
                      "\n        "
                  )
                ]),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("register.bus_gov_issues_ID_popup_line3")) +
                      "\n        "
                  )
                ])
              ])
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "popup",
        {
          attrs: { open: _vm.countryModal },
          on: {
            "update:open": function($event) {
              _vm.countryModal = $event
            }
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "scrollable",
              on: {
                "&scroll": function($event) {
                  return _vm.handleScroll.apply(null, arguments)
                }
              }
            },
            [
              _c("div", {}, [
                _c("h2", [
                  _c("strong", [
                    _vm._v(_vm._s(_vm.$t("register.prohibited_c")))
                  ])
                ]),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c(
                  "ul",
                  { staticStyle: { "column-count": "3" } },
                  _vm._l(_vm.prohibitedCountries, function(item) {
                    return _c("li", [
                      _vm._v("\n            " + _vm._s(item) + "\n          ")
                    ])
                  }),
                  0
                )
              ])
            ]
          )
        ]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }