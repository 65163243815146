var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("p", [_vm._v("Account Settings")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("ul", { staticClass: "appview-list" }, [
        _c(
          "li",
          [
            _c("label", [_vm._v("Provider")]),
            _vm._v(" "),
            _c(
              "validator",
              { attrs: { value: _vm.item.provider, reqs: "required" } },
              [
                _c("dyn-select", {
                  attrs: { options: _vm.providerOptions },
                  model: {
                    value: _vm.item.provider,
                    callback: function($$v) {
                      _vm.$set(_vm.item, "provider", $$v)
                    },
                    expression: "item.provider"
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("li", [
          _c("label", [_vm._v("Label")]),
          _vm._v(" "),
          _c("div", [
            _vm._v(
              "\n        " +
                _vm._s(_vm.selectedProvider ? _vm.selectedProvider.label : "") +
                "\n      "
            )
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("label", [_vm._v("Account Type")]),
          _vm._v(" "),
          _c("div", [
            _vm._v("\n        " + _vm._s(_vm.item.accountType) + "\n      ")
          ])
        ]),
        _vm._v(" "),
        _c(
          "li",
          [
            _c("label", [_vm._v("Template")]),
            _vm._v(" "),
            _c(
              "validator",
              { attrs: { value: _vm.item.pricingId, reqs: "required" } },
              [
                _c("dyn-select", {
                  attrs: {
                    disabled: !_vm.item.provider,
                    options: _vm.templateOptions
                  },
                  model: {
                    value: _vm.item.pricingId,
                    callback: function($$v) {
                      _vm.$set(_vm.item, "pricingId", $$v)
                    },
                    expression: "item.pricingId"
                  }
                })
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("ul", { staticClass: "appview-list" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("li", [
          _c("label", [_vm._v("Administrative")]),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("mnb-switch", {
                attrs: { disabled: !_vm.item.provider },
                model: {
                  value: _vm.item.administrative,
                  callback: function($$v) {
                    _vm.$set(_vm.item, "administrative", $$v)
                  },
                  expression: "item.administrative"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _c("label", [_vm._v("Remote Deposit Capture")]),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("mnb-switch", {
                attrs: { disabled: !_vm.item.provider },
                model: {
                  value: _vm.item.remoteDepositCapture,
                  callback: function($$v) {
                    _vm.$set(_vm.item, "remoteDepositCapture", $$v)
                  },
                  expression: "item.remoteDepositCapture"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _c("label", [_vm._v("Double Verification")]),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { value: _vm.item.provider, reqs: "required" } },
            [
              _c("mnb-switch", {
                attrs: { disabled: !_vm.item.provider },
                model: {
                  value: _vm.item.doubleVerification,
                  callback: function($$v) {
                    _vm.$set(_vm.item, "doubleVerification", $$v)
                  },
                  expression: "item.doubleVerification"
                }
              })
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c("p", [_vm._v("Treasury Pricing Template")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _vm.solutionId
        ? _c("pricing-details-treasury", {
            attrs: {
              currentTemplate: _vm.item.pricing,
              solution: _vm.solutionId,
              parentTemplate: _vm.currentTemplate,
              boarding: true
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("p", [_vm._v("Profile Settings")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("ul", { staticClass: "appview-list" }, [
        _vm._m(1),
        _vm._v(" "),
        _c("li", [
          _c("label", [_vm._v("Create Sub-users")]),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("mnb-switch", {
                attrs: { disabled: !_vm.item.provider },
                model: {
                  value: _vm.item.createSubUsers,
                  callback: function($$v) {
                    _vm.$set(_vm.item, "createSubUsers", $$v)
                  },
                  expression: "item.createSubUsers"
                }
              })
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c("ul", { staticClass: "appview-list" }, [
        _vm._m(2),
        _vm._v(" "),
        _c(
          "li",
          [
            _c("label", [_vm._v("Welcome Email")]),
            _vm._v(" "),
            _c(
              "validator",
              { attrs: { value: _vm.item.welcomeEmail, reqs: "required" } },
              [
                _c("dyn-select", {
                  attrs: {
                    disabled: !_vm.item.provider,
                    options: _vm.welcomeOptions
                  },
                  model: {
                    value: _vm.item.welcomeEmail,
                    callback: function($$v) {
                      _vm.$set(_vm.item, "welcomeEmail", $$v)
                    },
                    expression: "item.welcomeEmail"
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          [
            _c("label", [_vm._v("Profile Review Days")]),
            _vm._v(" "),
            _c(
              "validator",
              {
                attrs: { value: _vm.item.profileReviewDays, reqs: "required" }
              },
              [
                _c("dyn-select", {
                  attrs: {
                    disabled: !_vm.item.provider,
                    options: _vm.daysOptions
                  },
                  model: {
                    value: _vm.item.profileReviewDays,
                    callback: function($$v) {
                      _vm.$set(_vm.item, "profileReviewDays", $$v)
                    },
                    expression: "item.profileReviewDays"
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          [
            _c("label", [_vm._v("Language")]),
            _vm._v(" "),
            _c(
              "validator",
              { attrs: { value: _vm.item.language, reqs: "required" } },
              [
                _c("dyn-select", {
                  attrs: {
                    disabled: !_vm.item.provider,
                    options: _vm.lanuageOptions
                  },
                  model: {
                    value: _vm.item.language,
                    callback: function($$v) {
                      _vm.$set(_vm.item, "language", $$v)
                    },
                    expression: "item.language"
                  }
                })
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "actions" }, [
        _c(
          "button",
          {
            attrs: { disabled: !_vm.validate() },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.doNext.apply(null, arguments)
              }
            }
          },
          [_vm._v("Save & Continue")]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [_c("label", [_c("b", [_vm._v("Verifications")])])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [_c("label", [_c("b", [_vm._v("Permissions")])])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [_c("label", [_c("b", [_vm._v("Options")])])])
  }
]
render._withStripped = true

export { render, staticRenderFns }