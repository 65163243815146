var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("p", [_vm._v("Compliance")]),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _c("div", { staticClass: "content-table-container file-list-container" }, [
      _c("table", { staticClass: "file-list content-table" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "tbody",
          [
            _c("tr", [
              _c("td", [_vm._v("KYC Boarding Report")]),
              _vm._v(" "),
              _c("td", { staticClass: "partner-boarding-doc-actions" }, [
                _c(
                  "button",
                  {
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.startKyc.apply(null, arguments)
                      }
                    }
                  },
                  [_vm._v("Start")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.skipKyc.apply(null, arguments)
                      }
                    }
                  },
                  [_vm._v("Skip")]
                )
              ]),
              _vm._v(" "),
              _vm.kycResult
                ? _c("td", [
                    _vm._v(
                      "\n            " + _vm._s(_vm.kycResult) + "\n          "
                    )
                  ])
                : _c("td", [_vm._v("Pending")]),
              _vm._v(" "),
              _c("td")
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", [_vm._v("Scans")]),
              _vm._v(" "),
              _c("td", { staticClass: "partner-boarding-doc-actions" }, [
                _c(
                  "button",
                  {
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.startBoarding.apply(null, arguments)
                      }
                    }
                  },
                  [_vm._v("Start")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.skipBoarding.apply(null, arguments)
                      }
                    }
                  },
                  [_vm._v("Skip")]
                )
              ]),
              _vm._v(" "),
              _c("td"),
              _vm._v(" "),
              _c("td")
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", [_vm._v(_vm._s(_vm.item.agentDb.name))]),
              _vm._v(" "),
              _c("td", { staticClass: "partner-boarding-doc-actions" }),
              _vm._v(" "),
              _vm.boardingResult
                ? _c("td", [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.boardingResult) +
                        "\n          "
                    )
                  ])
                : _c("td", [_vm._v("Pending")]),
              _vm._v(" "),
              _c("td")
            ]),
            _vm._v(" "),
            _vm._l(_vm.item.contacts, function(ubo, ui) {
              return _c("tr", [
                _c("td", [
                  _vm._v(_vm._s(ubo.firstName) + " " + _vm._s(ubo.lastName))
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "partner-boarding-doc-actions" }),
                _vm._v(" "),
                _vm.boardingResult
                  ? _c("td", [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.boardingResult) +
                          "\n          "
                      )
                    ])
                  : _c("td", [_vm._v("Pending")]),
                _vm._v(" "),
                _c("td")
              ])
            })
          ],
          2
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "actions" }, [
      _c(
        "button",
        {
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.goBack.apply(null, arguments)
            }
          }
        },
        [_vm._v("Back")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          attrs: {
            disabled: this.kycResult == null || this.boardingResult == null
          },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.doNext.apply(null, arguments)
            }
          }
        },
        [_vm._v("Save & Continue")]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("td", [_vm._v("Type")]),
        _vm._v(" "),
        _c("td"),
        _vm._v(" "),
        _c("td", [_vm._v("Result")]),
        _vm._v(" "),
        _c("td", [_vm._v("Options")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }