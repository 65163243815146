var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "merchant-panel" }, [
    _c(
      "div",
      { staticClass: "data-content" },
      [
        _c("ul", { staticClass: "tabs" }, [
          _c(
            "li",
            {
              class: { active: _vm.dataTab == 0 },
              on: {
                click: function($event) {
                  _vm.dataTab = 0
                }
              }
            },
            [_vm._v("General")]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              class: { active: _vm.dataTab == 1 },
              on: {
                click: function($event) {
                  _vm.dataTab = 1
                }
              }
            },
            [_vm._v("Company")]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              class: { active: _vm.dataTab == 2 },
              on: {
                click: function($event) {
                  _vm.dataTab = 2
                }
              }
            },
            [_vm._v("Ownership")]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              class: { active: _vm.dataTab == 3 },
              on: {
                click: function($event) {
                  _vm.dataTab = 3
                }
              }
            },
            [_vm._v("Business")]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              class: { active: _vm.dataTab == 4 },
              on: {
                click: function($event) {
                  _vm.dataTab = 4
                }
              }
            },
            [_vm._v("Banking")]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              class: { active: _vm.dataTab == 5 },
              on: {
                click: function($event) {
                  _vm.dataTab = 5
                }
              }
            },
            [_vm._v("Contact")]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              class: { active: _vm.dataTab == 6 },
              on: {
                click: function($event) {
                  _vm.dataTab = 6
                }
              }
            },
            [_vm._v("Notes")]
          )
        ]),
        _vm._v(" "),
        _vm.isMerchant(this.item)
          ? [
              _c("p", { staticClass: "tabs" }, [_vm._v("Merchant Settings")]),
              _vm._v(" "),
              _c("ul", { staticClass: "tabs-smaller" }, [
                _c(
                  "li",
                  {
                    class: {
                      active: _vm.dataTab == 7,
                      disabled: !_vm.merchant
                    },
                    on: {
                      click: function($event) {
                        _vm.merchant && (_vm.dataTab = 7)
                      }
                    }
                  },
                  [_vm._v("Account")]
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    class: {
                      active: _vm.dataTab == 8,
                      disabled: !_vm.merchant
                    },
                    on: {
                      click: function($event) {
                        _vm.merchant && (_vm.dataTab = 8)
                      }
                    }
                  },
                  [_vm._v("Methods")]
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    class: {
                      active: _vm.dataTab == 9,
                      disabled: !_vm.merchant
                    },
                    on: {
                      click: function($event) {
                        _vm.merchant && (_vm.dataTab = 9)
                      }
                    }
                  },
                  [_vm._v("Modules")]
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    class: {
                      active: _vm.dataTab == 10,
                      disabled: !_vm.merchant
                    },
                    on: {
                      click: function($event) {
                        _vm.merchant && (_vm.dataTab = 10)
                      }
                    }
                  },
                  [_vm._v("Options")]
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    class: {
                      active: _vm.dataTab == 11,
                      disabled: !_vm.merchant
                    },
                    on: {
                      click: function($event) {
                        _vm.merchant && (_vm.dataTab = 11)
                      }
                    }
                  },
                  [_vm._v("Equipment")]
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    class: {
                      active: _vm.dataTab == 12,
                      disabled: !_vm.merchant
                    },
                    on: {
                      click: function($event) {
                        _vm.merchant && (_vm.dataTab = 12)
                      }
                    }
                  },
                  [_vm._v("Pricing")]
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    class: {
                      active: _vm.dataTab == 16,
                      disabled: !_vm.merchant
                    },
                    on: {
                      click: function($event) {
                        _vm.merchant && (_vm.dataTab = 16)
                      }
                    }
                  },
                  [_vm._v("Compliance")]
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    class: {
                      active: _vm.dataTab == 13,
                      disabled: !_vm.merchant
                    },
                    on: {
                      click: function($event) {
                        _vm.merchant && (_vm.dataTab = 13)
                      }
                    }
                  },
                  [_vm._v("Financial")]
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    class: {
                      active: _vm.dataTab == 14,
                      disabled: !_vm.merchant
                    },
                    on: {
                      click: function($event) {
                        _vm.merchant && (_vm.dataTab = 14)
                      }
                    }
                  },
                  [_vm._v("Processing")]
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    class: {
                      active: _vm.dataTab == 15,
                      disabled: !_vm.merchant
                    },
                    on: {
                      click: function($event) {
                        _vm.merchant && (_vm.dataTab = 15)
                      }
                    }
                  },
                  [_vm._v("Accounting")]
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    class: {
                      active: _vm.dataTab == 17,
                      disabled: !_vm.merchant
                    },
                    on: {
                      click: function($event) {
                        _vm.merchant && (_vm.dataTab = 17)
                      }
                    }
                  },
                  [_vm._v("Access")]
                )
              ])
            ]
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "main" },
          [
            _c("formview-app-main", {
              attrs: {
                item: _vm.item,
                merchant: _vm.merchant,
                tab: _vm.dataTab,
                notes: _vm.notes,
                messagesApi: _vm.messagesApi,
                editMode: _vm.editMode,
                lastAgreement: _vm.lastAgreement
              },
              on: {
                "update:editMode": function($event) {
                  _vm.editMode = $event
                },
                "update:edit-mode": function($event) {
                  _vm.editMode = $event
                }
              }
            })
          ],
          1
        )
      ],
      2
    ),
    _vm._v(" "),
    _c("aside", { staticClass: "actions" }, [
      _c("ul", { staticClass: "tabs" }, [
        _c(
          "li",
          {
            class: { active: _vm.actionsTab == 0 },
            on: {
              click: function($event) {
                _vm.actionsTab = 0
              }
            }
          },
          [_vm._v("General")]
        ),
        _vm._v(" "),
        _c(
          "li",
          {
            class: { active: _vm.actionsTab == 1 },
            on: {
              click: function($event) {
                _vm.actionsTab = 1
              }
            }
          },
          [_vm._v("Application")]
        ),
        _vm._v(" "),
        _c(
          "li",
          {
            class: { active: _vm.actionsTab == 2 },
            on: {
              click: function($event) {
                _vm.actionsTab = 2
              }
            }
          },
          [_vm._v("Documents")]
        ),
        _vm._v(" "),
        _c(
          "li",
          {
            class: { active: _vm.actionsTab == 3 },
            on: {
              click: function($event) {
                _vm.actionsTab = 3
              }
            }
          },
          [_vm._v("Compliance")]
        ),
        _vm._v(" "),
        _c(
          "li",
          {
            class: { active: _vm.actionsTab == 7 },
            on: {
              click: function($event) {
                _vm.actionsTab = 7
              }
            }
          },
          [_vm._v("Agreements")]
        ),
        _vm._v(" "),
        _c(
          "li",
          {
            class: { active: _vm.actionsTab == 5 },
            on: {
              click: function($event) {
                _vm.actionsTab = 5
                _vm.listEmails()
              }
            }
          },
          [_vm._v("Email")]
        ),
        _vm._v(" "),
        _c(
          "li",
          {
            class: { active: _vm.actionsTab == 4 },
            on: {
              click: function($event) {
                _vm.actionsTab = 4
                _vm.listSetups()
              }
            }
          },
          [_vm._v("Merchant Setup")]
        ),
        _vm._v(" "),
        _c(
          "li",
          {
            class: { active: _vm.actionsTab == 6 },
            on: {
              click: function($event) {
                _vm.actionsTab = 6
              }
            }
          },
          [_vm._v("Accounting")]
        )
      ]),
      _vm._v(" "),
      _vm.actionsTab == 0
        ? _c(
            "div",
            { staticClass: "main" },
            [
              _c("div", { staticClass: "action-buttons" }, [
                _c(
                  "a",
                  {
                    attrs: { href: "" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        _vm.listChecklist()
                        _vm.showFinalApproval = false
                      }
                    }
                  },
                  [_vm._v("Setup Checklist")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    attrs: { href: "" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        _vm.uwTab = 2
                        _vm.showFinalApproval = false
                      }
                    }
                  },
                  [_vm._v("Status Update")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    class: { disabled: _vm.editMode },
                    attrs: { href: "" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        _vm.editMode = true
                      }
                    }
                  },
                  [_vm._v("Edit")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    class: { disabled: _vm.negativeList },
                    attrs: { href: "" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        !_vm.negativeList && _vm.doAction(_vm.item, "negative")
                      }
                    }
                  },
                  [_vm._v("Negative List")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    class: { disabled: !_vm.negativeList },
                    attrs: { href: "" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        _vm.negativeList && _vm.doAction(_vm.item, "removeNeg")
                      }
                    }
                  },
                  [_vm._v("Remove Neg List")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    class: {
                      disabled: !(
                        _vm.isMerchant(_vm.item) && !_vm.item.followup
                      )
                    },
                    attrs: { href: "" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        _vm.isMerchant(_vm.item) &&
                          !_vm.item.followup &&
                          _vm.doAction(_vm.item, "followUp")
                      }
                    }
                  },
                  [_vm._v("Follow-up")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    class: {
                      disabled: !(_vm.isMerchant(_vm.item) && _vm.item.followup)
                    },
                    attrs: { href: "" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        _vm.isMerchant(_vm.item) &&
                          !_vm.item.followup &&
                          _vm.doAction(_vm.item, "removeFup")
                      }
                    }
                  },
                  [_vm._v("No follow-up")]
                )
              ]),
              _vm._v(" "),
              _vm.uwTab == 3
                ? _c("view-app-setupcl", {
                    key: "setupcl",
                    attrs: {
                      prechecks: _vm.uwList,
                      weblist: _vm.webList,
                      item: _vm.item,
                      merchant: _vm.merchant
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.showFinalApproval
                ? _c("view-app-finalcheck", {
                    key: "finalcheck",
                    attrs: { uwList: _vm.uwListFinal }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.uwTab == 2
                ? _c("div", [
                    _c(
                      "div",
                      { staticClass: "action-sub-buttons" },
                      [
                        _vm._l(_vm.allActions, function(optLabel, optMethod) {
                          return _c(
                            "a",
                            {
                              class: {
                                disabled: _vm.isDisabledAction(optMethod)
                              },
                              attrs: { href: "" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  !_vm.isDisabledAction(optMethod) &&
                                    _vm.doAction(_vm.item, optMethod)
                                }
                              }
                            },
                            [_vm._v(_vm._s(optLabel))]
                          )
                        }),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "disabled",
                            attrs: { href: "" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                              }
                            }
                          },
                          [_vm._v("Hold Account")]
                        ),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "disabled",
                            attrs: { href: "" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                              }
                            }
                          },
                          [_vm._v("Close Account")]
                        ),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "disabled",
                            attrs: { href: "" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                              }
                            }
                          },
                          [_vm._v("App reminder")]
                        )
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("table", { staticClass: "file-list content-table" }, [
                      _vm._m(0),
                      _vm._v(" "),
                      _c(
                        "tbody",
                        _vm._l(_vm.notes, function(uwItem) {
                          return uwItem.system &&
                            uwItem.message.indexOf("Updated to ") == 0
                            ? _c("tr", [
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatDate(
                                        uwItem.created,
                                        "YYYY-MM-DD h:mm a"
                                      )
                                    )
                                  )
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(_vm._s(uwItem.message.substring(11)))
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(_vm._s(uwItem.author.username))
                                ])
                              ])
                            : _vm._e()
                        }),
                        0
                      )
                    ])
                  ])
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.actionsTab == 1
        ? _c("div", { staticClass: "main" }, [
            _c("div", { staticClass: "action-buttons" }, [
              !_vm.viewApp
                ? _c(
                    "a",
                    {
                      attrs: { href: "" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          _vm.viewApp = true
                        }
                      }
                    },
                    [_vm._v("View")]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.viewApp
                ? _c(
                    "a",
                    {
                      attrs: { href: "" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.uploadFile(_vm.signedApplicationField)
                        }
                      }
                    },
                    [_vm._v("Upload signed")]
                  )
                : _c(
                    "a",
                    {
                      attrs: { href: "" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          _vm.viewApp = false
                        }
                      }
                    },
                    [_vm._v("Back")]
                  )
            ]),
            _vm._v(" "),
            !_vm.viewApp
              ? _c(
                  "div",
                  {
                    staticClass: "content-table-container file-list-container"
                  },
                  [
                    _c("table", { staticClass: "file-list content-table" }, [
                      _vm._m(1),
                      _vm._v(" "),
                      _c(
                        "tbody",
                        [
                          _c("tr", [
                            _vm._m(2),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(_vm.item.applicationId))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(_vm.item.accountId))]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.formatDate(
                                    _vm.item.submitDate,
                                    "YYYY-MM-DD"
                                  )
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(_vm.item.status))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(_vm.item.companyName))])
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("td", { attrs: { colspan: "3" } }, [
                              _c(
                                "a",
                                {
                                  staticStyle: { display: "block" },
                                  attrs: {
                                    target: "_blank",
                                    href: _vm.appPdfUrl
                                  }
                                },
                                [_vm._v("Completed Merchant Application")]
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", { attrs: { colspan: "3" } }, [
                              _c(
                                "a",
                                {
                                  staticStyle: { display: "block" },
                                  attrs: {
                                    target: "_blank",
                                    href: _vm.appPdfUrl
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.item.applicationId) +
                                      " " +
                                      _vm._s(_vm.item.companyName) +
                                      " " +
                                      _vm._s(_vm.item.companyType) +
                                      " " +
                                      _vm._s(
                                        _vm.formatDate(
                                          _vm.item.submitDate,
                                          "YYYY-MM-DD hh:mm"
                                        )
                                      )
                                  )
                                ]
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.signedApplications, function(signedDoc) {
                            return _c("tr", [
                              _c("td", { attrs: { colspan: "3" } }, [
                                _c(
                                  "a",
                                  {
                                    staticStyle: { display: "block" },
                                    attrs: {
                                      target: "_blank",
                                      href: _vm.fileLink(
                                        signedDoc.filename,
                                        signedDoc.id
                                      )
                                    }
                                  },
                                  [_vm._v("Signed Merchant Application")]
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", { attrs: { colspan: "3" } }, [
                                _c(
                                  "a",
                                  {
                                    staticStyle: { display: "block" },
                                    attrs: {
                                      target: "_blank",
                                      href: _vm.fileLink(
                                        signedDoc.filename,
                                        signedDoc.id
                                      )
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.item.applicationId) +
                                        " " +
                                        _vm._s(_vm.item.companyName) +
                                        " " +
                                        _vm._s(
                                          _vm.formatDate(
                                            signedDoc.created,
                                            "YYYY-MM-DD hh:mm"
                                          )
                                        )
                                    )
                                  ]
                                )
                              ])
                            ])
                          })
                        ],
                        2
                      )
                    ])
                  ]
                )
              : _c("div", { staticClass: "app-details" }, [
                  _c("p", [
                    _c("label", [_vm._v("Application ID:")]),
                    _vm._v(" " + _vm._s(_vm.item.applicationId))
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _c("label", [_vm._v("Account ID:")]),
                    _vm._v(" " + _vm._s(_vm.item.accountId))
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _c("label", [_vm._v("Submitted Date:")]),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.formatDate(_vm.item.submitDate, "YYYY-MM-DD")
                        )
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _c("label", [_vm._v("Status:")]),
                    _vm._v(" " + _vm._s(_vm.item.status))
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _c("label", [_vm._v("Legal Name:")]),
                    _vm._v(" " + _vm._s(_vm.item.companyName))
                  ])
                ])
          ])
        : _vm.actionsTab == 2
        ? _c("div", { staticClass: "main" }, [
            _c("div", { staticClass: "action-buttons" }, [
              _c(
                "a",
                {
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.newFile = true
                    }
                  }
                },
                [_vm._v("New File")]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  class: {
                    disabled:
                      !_vm.selectedFile ||
                      _vm.fileFields[_vm.selectedFile].indexOf("*") != -1
                  },
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.uploadFile()
                    }
                  }
                },
                [_vm._v("Upload")]
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "content-table-container file-list-container" },
              [
                _c("table", { staticClass: "file-list content-table" }, [
                  _vm._m(3),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    [
                      _vm.newFile
                        ? _c("tr", [
                            _c("td", [
                              _c(
                                "a",
                                {
                                  staticClass: "close",
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      _vm.newFile = false
                                    }
                                  }
                                },
                                [_vm._v("×")]
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.newFileType,
                                    expression: "newFileType"
                                  }
                                ],
                                staticStyle: { width: "100%" },
                                domProps: { value: _vm.newFileType },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.newFileType = $event.target.value
                                  }
                                }
                              })
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _c("input", {
                                ref: "newFileFile",
                                staticStyle: { width: "100%" },
                                attrs: { type: "file" }
                              })
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _c(
                                "button",
                                {
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.uploadNew.apply(
                                        null,
                                        arguments
                                      )
                                    }
                                  }
                                },
                                [_vm._v("Upload")]
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._l(_vm.item.customDocuments, function(file) {
                        return _c("tr", [
                          _c("td", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.selectedFile,
                                  expression: "selectedFile"
                                }
                              ],
                              attrs: { type: "radio" },
                              domProps: {
                                value: "custom" + file.id,
                                checked: _vm._q(
                                  _vm.selectedFile,
                                  "custom" + file.id
                                )
                              },
                              on: {
                                change: function($event) {
                                  _vm.selectedFile = "custom" + file.id
                                }
                              }
                            })
                          ]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(file.type))]),
                          _vm._v(" "),
                          _c("td", [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: _vm.fileLinkCustom(
                                    file.filename,
                                    file.id
                                  ),
                                  target: "_blank"
                                }
                              },
                              [_vm._v(_vm._s(file.filename || "(no file)"))]
                            )
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              _vm._s(_vm.formatDate(file.created, "YYYY-MM-DD"))
                            )
                          ])
                        ])
                      }),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td"),
                        _vm._v(" "),
                        _c("td", [_vm._v("Completed Merchant Application")]),
                        _vm._v(" "),
                        _c("td", [
                          _c(
                            "a",
                            {
                              attrs: { href: _vm.appPdfUrl, target: "_blank" }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.item.applicationId) +
                                  " - " +
                                  _vm._s(_vm.item.companyName)
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm.formatDate(_vm.item.submitDate, "YYYY-MM-DD")
                            )
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.fileFields, function(fileLabel, fileField) {
                        return _c("tr", [
                          _c("td", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.selectedFile,
                                  expression: "selectedFile"
                                }
                              ],
                              attrs: { type: "radio" },
                              domProps: {
                                value: fileField,
                                checked: _vm._q(_vm.selectedFile, fileField)
                              },
                              on: {
                                change: function($event) {
                                  _vm.selectedFile = fileField
                                }
                              }
                            })
                          ]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(fileLabel))]),
                          _vm._v(" "),
                          _c("td", [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: _vm.fileLink(
                                    _vm.documents[fileField] &&
                                      _vm.documents[fileField].filename,
                                    _vm.documents[fileField] &&
                                      _vm.documents[fileField].id
                                  ),
                                  target: "_blank"
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    (_vm.documents[fileField] &&
                                      _vm.documents[fileField].filename) ||
                                      "(no file)"
                                  )
                                )
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.formatDate(
                                  _vm.documents[fileField] &&
                                    _vm.documents[fileField].created,
                                  "YYYY-MM-DD"
                                )
                              )
                            )
                          ])
                        ])
                      })
                    ],
                    2
                  )
                ])
              ]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.actionsTab == 3
        ? _c(
            "div",
            { staticClass: "main" },
            [
              _c("div", { staticClass: "action-buttons" }, [
                _c(
                  "a",
                  {
                    staticClass: "disabled",
                    attrs: { href: "" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                      }
                    }
                  },
                  [_vm._v("Merchant Checklist")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    attrs: { href: "" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        _vm.uwTab = 5
                      }
                    }
                  },
                  [_vm._v("Merchant Submission Form")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    attrs: { href: "" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.listPreChecks.apply(null, arguments)
                      }
                    }
                  },
                  [_vm._v("Pre-Checks")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    attrs: { href: "" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.listWebsiteChecks.apply(null, arguments)
                      }
                    }
                  },
                  [_vm._v("Website Check")]
                ),
                _vm._v(" "),
                false
                  ? _c(
                      "a",
                      {
                        attrs: { href: "" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.listDocChecks.apply(null, arguments)
                          }
                        }
                      },
                      [_vm._v("Doc-Checks")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    class: { disabled: !_vm.toBeReviewed },
                    attrs: { href: "" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        _vm.toBeReviewed && _vm.startReview()
                      }
                    }
                  },
                  [_vm._v("Start Account Review")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    attrs: { href: "" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        _vm.uwTab = 4
                      }
                    }
                  },
                  [_vm._v("Risk Exposure Calculator")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    attrs: { href: "" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.listG2.apply(null, arguments)
                      }
                    }
                  },
                  [_vm._v("G2")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    attrs: { href: "" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.listIDM.apply(null, arguments)
                      }
                    }
                  },
                  [_vm._v("Acuant")]
                )
              ]),
              _vm._v(" "),
              _vm.uwTab == 1
                ? _c("view-app-precheck", {
                    key: "precheck",
                    attrs: { uwList: _vm.uwList }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.uwTab == 2
                ? _c("view-app-websitecheck", {
                    key: "websitecheck",
                    attrs: { uwList: _vm.webList }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.uwTab == 3
                ? _c("view-app-doccheck", {
                    key: "doccheck",
                    attrs: { uwList: _vm.docList }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.uwTab == 10
                ? _c("div", { staticClass: "action-sub-buttons" }, [
                    _c(
                      "a",
                      {
                        staticClass: "btn",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            _vm.uwTabSub = 1
                          }
                        }
                      },
                      [_vm._v("KYC Reports")]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "btn",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            _vm.uwTabSub = 0
                          }
                        }
                      },
                      [_vm._v("G2 Monitoring")]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.uwTab == 10 && _vm.uwTabSub == 1
                ? _c("view-app-extcheck", {
                    key: "extcheckg2",
                    attrs: { type: "g2", uwList: _vm.checkList }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.uwTab == 11
                ? _c("view-app-extcheck", {
                    key: "extcheckidm",
                    attrs: { type: "idm", uwList: _vm.checkList },
                    on: { update: _vm.listIDM }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.reviewStarted
                ? _c("div", { key: "review" }, [
                    _c("div", { staticClass: "action-sub-buttons" }, [
                      _c(
                        "a",
                        {
                          staticClass: "btn",
                          staticStyle: { "margin-left": "auto" },
                          on: {
                            click: function($event) {
                              _vm.resetReview()
                              _vm.reviewStarted = false
                            }
                          }
                        },
                        [_vm._v("Confirm Review")]
                      )
                    ]),
                    _vm._v(" "),
                    _c("h3", [_vm._v("Account Compliance Review")]),
                    _vm._v(" "),
                    _vm._m(4)
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.uwTab == 4
                ? _c(
                    "div",
                    [_c("view-app-risk", { attrs: { item2: _vm.item } })],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.uwTab == 5
                ? _c(
                    "div",
                    [_c("view-app-intake", { attrs: { item: _vm.item } })],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.actionsTab == 4
        ? _c("div", { staticClass: "main" }, [
            _c("div", { staticClass: "action-buttons" }, [
              _c(
                "a",
                {
                  class: { disabled: !_vm.isMerchantSetupCompleted },
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.isMerchantSetupCompleted &&
                        _vm.openAccountConfirmation()
                    }
                  }
                },
                [_vm._v("Account Confirmation")]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  class: { disabled: !_vm.item.hasIntake * 1 },
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.item.hasIntake * 1 && _vm.startSetup()
                    }
                  }
                },
                [_vm._v("Start New Setup")]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  class: {
                    disabled: !_vm.currentSetup || _vm.currentSetup.status
                  },
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.currentSetup &&
                        !_vm.currentSetup.status &&
                        _vm.resumeSetup()
                    }
                  }
                },
                [_vm._v("Continue Setup")]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  class: {
                    disabled: !_vm.currentSetup || !_vm.currentSetup.status
                  },
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.currentSetup &&
                        _vm.currentSetup.status &&
                        _vm.viewSetup()
                    }
                  }
                },
                [_vm._v("View Setup")]
              )
            ]),
            _vm._v(" "),
            _c("table", { staticClass: "content-table file-list" }, [
              _vm._m(5),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.setups, function(setup, ai) {
                  return _c("tr", [
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.currentSetupId,
                            expression: "currentSetupId"
                          }
                        ],
                        attrs: { type: "radio", checked: "" },
                        domProps: {
                          value: setup.id,
                          checked: _vm._q(_vm.currentSetupId, setup.id)
                        },
                        on: {
                          change: function($event) {
                            _vm.currentSetupId = setup.id
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          _vm.formatDate(setup.created, "YYYY-MM-DD hh:mm")
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(setup.buildId))]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(setup.id))]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(setup.status || "Started"))])
                  ])
                }),
                0
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.actionsTab == 5
        ? _c("div", { staticClass: "main" }, [
            _c("div", { staticClass: "action-buttons" }, [
              _c(
                "a",
                {
                  staticClass: "disabled",
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [_vm._v("Pre-Approval")]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.sendEmail("ApplicationReminder")
                    }
                  }
                },
                [_vm._v("Application Reminder")]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.sendEmail("ApplicationAtt")
                    }
                  }
                },
                [_vm._v("Applications")]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.sendEmail("Declined")
                    }
                  }
                },
                [_vm._v("Declined")]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.sendEmail("AgreementAtt")
                    }
                  }
                },
                [_vm._v("Agreements")]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.sendEmail("Approved")
                    }
                  }
                },
                [_vm._v("Approved")]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [_vm._v("Merchant Welcome")]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  class: {
                    disabled: !_vm.approvedSent || _vm.item.status == "M00"
                  },
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.approvedSent &&
                        _vm.item.status != "M00" &&
                        _vm.sendEmail("ConfigSetup")
                    }
                  }
                },
                [_vm._v("Account Confirmation")]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  class: { disabled: _vm.agreementSigned },
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      !_vm.agreementSigned && _vm.sendEmail("Questionnaire")
                    }
                  }
                },
                [_vm._v("Questionnaire")]
              )
            ]),
            _vm._v(" "),
            _c("table", { staticClass: "file-list content-table" }, [
              _vm._m(6),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.emails, function(email) {
                  return _c("tr", [
                    _c("td", [_vm._v(_vm._s(email.emailType))]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          _vm.formatDate(email.created, "YYYY-MM-DD HH:mm:ss")
                        )
                      )
                    ])
                  ])
                }),
                0
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.actionsTab == 6
        ? _c("div", { staticClass: "main" }, [
            _c("div", { staticClass: "action-buttons" }, [
              _c(
                "a",
                {
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [_vm._v("Generate New")]
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.actionsTab == 7
        ? _c("div", { staticClass: "main" }, [
            _c("div", { staticClass: "action-buttons" }, [
              _c(
                "a",
                {
                  class: { disabled: !_vm.item.hasIntake * 1 },
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.item.hasIntake * 1 && _vm.startAgreement()
                    }
                  }
                },
                [_vm._v("Start Agreement")]
              )
            ]),
            _vm._v(" "),
            _c("table", { staticClass: "content-table file-list" }, [
              _vm._m(7),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.agreements, function(agreement, ai) {
                  return _c("tr", [
                    _c("td", [_vm._v(_vm._s(ai + 1))]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          _vm.formatDate(agreement.created, "YYYY-MM-DD hh:mm")
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _c(
                        "a",
                        {
                          attrs: {
                            target: "_blank",
                            href: _vm.agreementPath(agreement)
                          }
                        },
                        [_vm._v(_vm._s(agreement.name))]
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      agreement.filename
                        ? _c(
                            "a",
                            {
                              attrs: {
                                target: "_blank",
                                href: _vm.fileLink(agreement.filename)
                              }
                            },
                            [_vm._v(_vm._s(agreement.name + " signed"))]
                          )
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          _vm.formatDate(agreement.signed, "YYYY-MM-DD hh:mm")
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _c(
                        "a",
                        {
                          staticClass: "button",
                          attrs: { href: "" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.uploadSigned(agreement, ai)
                            }
                          }
                        },
                        [_vm._v("Upload")]
                      )
                    ])
                  ])
                }),
                0
              )
            ])
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Date")]),
        _vm._v(" "),
        _c("th", [_vm._v("Status Update")]),
        _vm._v(" "),
        _c("th", [_vm._v("Updated By")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th"),
        _vm._v(" "),
        _c("th", [_vm._v("AppID")]),
        _vm._v(" "),
        _c("th", [_vm._v("AccountID")]),
        _vm._v(" "),
        _c("th", [_vm._v("Submitted")]),
        _vm._v(" "),
        _c("th", [_vm._v("Status")]),
        _vm._v(" "),
        _c("th", [_vm._v("Legal Name")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("input", { attrs: { type: "radio", checked: "" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th"),
        _vm._v(" "),
        _c("th", [_vm._v("Type")]),
        _vm._v(" "),
        _c("th", [_vm._v("Filename")]),
        _vm._v(" "),
        _c("th", [_vm._v("Uploaded")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [_vm._v("Pre-Check")]),
      _vm._v(" "),
      _c("li", [_vm._v("IDM Review Scan")]),
      _vm._v(" "),
      _c("li", [_vm._v("G2 Review Scan")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticStyle: { width: "1%" } }, [_vm._v("#")]),
        _vm._v(" "),
        _c("th", [_vm._v("Build Date")]),
        _vm._v(" "),
        _c("th", [_vm._v("Build Id")]),
        _vm._v(" "),
        _c("th", [_vm._v("Build By")]),
        _vm._v(" "),
        _c("th", [_vm._v("Status")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Type")]),
        _vm._v(" "),
        _c("th", [_vm._v("Created")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticStyle: { width: "1%" } }, [_vm._v("#")]),
        _vm._v(" "),
        _c("th", [_vm._v("Date Created")]),
        _vm._v(" "),
        _c("th", [_vm._v("Agreement")]),
        _vm._v(" "),
        _c("th", [_vm._v("Date Signed")]),
        _vm._v(" "),
        _c("th", [_vm._v("Signed Agreement")]),
        _vm._v(" "),
        _c("th", [_vm._v("Action")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }