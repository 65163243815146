var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "content-filters" },
      [
        _c(
          "basic-form",
          [
            _c("h4", [_vm._v("Terms and Conditions")]),
            _vm._v(" "),
            _c("mnb-input", {
              attrs: {
                label: "Terms and Conditions Header",
                disabled: !_vm.updateInformations,
                maxlength: 100
              },
              model: {
                value: _vm.legalDisclaimers.termsAndConditionsHeader,
                callback: function($$v) {
                  _vm.$set(
                    _vm.legalDisclaimers,
                    "termsAndConditionsHeader",
                    $$v
                  )
                },
                expression: "legalDisclaimers.termsAndConditionsHeader"
              }
            }),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("label", [_vm._v("Terms and Conditions Updated:")]),
            _vm._v(" "),
            _c("date-time-picker", {
              attrs: {
                "value-type": "String",
                "value-format": "yyyy-LL-dd",
                format: "yyyy-LL-dd",
                "time-picker": false,
                "auto-close": true,
                "min-date": "2020-01-01",
                "max-date": _vm.today,
                disabled: !_vm.updateInformations
              },
              model: {
                value: _vm.legalDisclaimers.termsAndConditionsDate,
                callback: function($$v) {
                  _vm.$set(_vm.legalDisclaimers, "termsAndConditionsDate", $$v)
                },
                expression: "legalDisclaimers.termsAndConditionsDate"
              }
            }),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("label", [_vm._v("Terms and Conditions Text:")]),
            _vm._v(" "),
            _c("block-edit", {
              attrs: {
                rows: "10",
                placeholder: "",
                editable: _vm.updateInformations
              },
              model: {
                value: _vm.legalDisclaimers.termsAndConditionsText,
                callback: function($$v) {
                  _vm.$set(_vm.legalDisclaimers, "termsAndConditionsText", $$v)
                },
                expression: "legalDisclaimers.termsAndConditionsText"
              }
            }),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("h4", [_vm._v("Privacy Policy")]),
            _vm._v(" "),
            _c("mnb-input", {
              attrs: {
                label: "Privacy Policy Header",
                disabled: !_vm.updateInformations,
                maxlength: 100
              },
              model: {
                value: _vm.legalDisclaimers.privacyPolicyHeader,
                callback: function($$v) {
                  _vm.$set(_vm.legalDisclaimers, "privacyPolicyHeader", $$v)
                },
                expression: "legalDisclaimers.privacyPolicyHeader"
              }
            }),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("label", [_vm._v("Privacy Policy Updated:")]),
            _vm._v(" "),
            _c("date-time-picker", {
              attrs: {
                "value-type": "String",
                "value-format": "yyyy-LL-dd",
                format: "yyyy-LL-dd",
                "time-picker": false,
                "auto-close": true,
                "min-date": "2020-01-01",
                "max-date": _vm.today,
                disabled: !_vm.updateInformations
              },
              model: {
                value: _vm.legalDisclaimers.privacyPolicyDate,
                callback: function($$v) {
                  _vm.$set(_vm.legalDisclaimers, "privacyPolicyDate", $$v)
                },
                expression: "legalDisclaimers.privacyPolicyDate"
              }
            }),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("label", [_vm._v("Privacy Policy Text:")]),
            _vm._v(" "),
            _c("block-edit", {
              attrs: {
                rows: "10",
                placeholder: "",
                editable: _vm.updateInformations
              },
              model: {
                value: _vm.legalDisclaimers.privacyPolicyText,
                callback: function($$v) {
                  _vm.$set(_vm.legalDisclaimers, "privacyPolicyText", $$v)
                },
                expression: "legalDisclaimers.privacyPolicyText"
              }
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "buttons" },
              [
                _vm.updateInformations
                  ? [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-large btn-primary",
                          on: {
                            click: function($event) {
                              return _vm.save()
                            }
                          }
                        },
                        [_vm._v("Submit")]
                      )
                    ]
                  : [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-large btn-primary",
                          on: {
                            click: function($event) {
                              _vm.updateInformations = true
                            }
                          }
                        },
                        [_vm._v("Update")]
                      )
                    ],
                _vm._v(" "),
                _c("button", { staticClass: "btn btn-large btn-primary" }, [
                  _vm._v("Reset Fields")
                ])
              ],
              2
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }