var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "business-length" }, [
    _c("input", {
      attrs: { type: "text" },
      domProps: { value: _vm.years },
      on: {
        input: function($event) {
          return _vm.update($event, "years")
        },
        keypress: _vm.kp
      }
    }),
    _vm._v(" "),
    _c("label", [_vm._v("years")]),
    _vm._v(" "),
    _c("input", {
      attrs: { type: "text" },
      domProps: { value: _vm.months },
      on: {
        input: function($event) {
          return _vm.update($event, "months")
        },
        keypress: _vm.kp
      }
    }),
    _vm._v(" "),
    _c("label", [_vm._v("months")])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }