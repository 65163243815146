var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "next-schedule",
        staticStyle: { "margin-bottom": "30px" }
      },
      [
        _c("label", { staticStyle: { display: "block" } }, [
          _vm._v("Scheduled maintenance or downtime:")
        ]),
        _vm._v(" "),
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.scheduled,
              expression: "scheduled"
            }
          ],
          staticStyle: { width: "100%", height: "100px" },
          domProps: { value: _vm.scheduled },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.scheduled = $event.target.value
            }
          }
        })
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "content-table-container" }, [
      _c("table", { staticClass: "content-table" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "tbody",
          [
            _vm._l(_vm.items, function(item, index) {
              return [
                _c("tr", { staticStyle: { background: "#999" } }, [
                  _c(
                    "td",
                    { staticClass: "main-col", attrs: { colspan: "4" } },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: item.title,
                            expression: "item.title"
                          }
                        ],
                        staticStyle: { "text-align": "left" },
                        attrs: { type: "text" },
                        domProps: { value: item.title },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(item, "title", $event.target.value)
                          }
                        }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c("td", [
                    _c(
                      "a",
                      {
                        staticClass: "remove",
                        attrs: { href: "" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.items.splice(index, 1)
                          }
                        }
                      },
                      [_vm._v("×")]
                    )
                  ])
                ]),
                _vm._v(" "),
                _vm._l(item.items, function(subitem, si) {
                  return _c("tr", [
                    _c(
                      "td",
                      {
                        staticClass: "main-col",
                        staticStyle: { "padding-left": "20px" }
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: subitem.name,
                              expression: "subitem.name"
                            }
                          ],
                          staticStyle: { "text-align": "left" },
                          attrs: { type: "text" },
                          domProps: { value: subitem.name },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(subitem, "name", $event.target.value)
                            }
                          }
                        })
                      ]
                    ),
                    _vm._v(" "),
                    _c("td", {}, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: subitem.downMsg,
                            expression: "subitem.downMsg"
                          }
                        ],
                        staticStyle: { "text-align": "left" },
                        attrs: { type: "text" },
                        domProps: { value: subitem.downMsg },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(subitem, "downMsg", $event.target.value)
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c(
                      "td",
                      {},
                      [
                        _c("dyn-select", {
                          staticStyle: { "text-align": "left" },
                          attrs: {
                            options: [
                              { value: "green", label: "Green" },
                              { value: "yellow", label: "Yellow" },
                              { value: "red", label: "Red" },
                              { value: "black", label: "Black" }
                            ]
                          },
                          model: {
                            value: subitem.color,
                            callback: function($$v) {
                              _vm.$set(subitem, "color", $$v)
                            },
                            expression: "subitem.color"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      {},
                      [
                        _c("dyn-select", {
                          staticStyle: { "text-align": "left" },
                          attrs: {
                            options: [
                              { value: "Operational", label: "Operational" },
                              { value: "Maintenance", label: "Maintenance" },
                              { value: "Down", label: "Down" },
                              { value: "Offline", label: "Offline" }
                            ]
                          },
                          model: {
                            value: subitem.downTime,
                            callback: function($$v) {
                              _vm.$set(subitem, "downTime", $$v)
                            },
                            expression: "subitem.downTime"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("td", [
                      _c(
                        "a",
                        {
                          staticClass: "remove",
                          attrs: { href: "" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return item.items.splice(si, 1)
                            }
                          }
                        },
                        [_vm._v("×")]
                      )
                    ])
                  ])
                }),
                _vm._v(" "),
                _c("td", { attrs: { colspan: "5" } }, [
                  _c(
                    "a",
                    {
                      staticClass: "add",
                      attrs: { href: "" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.newItem(index)
                        }
                      }
                    },
                    [_vm._v("+ Item")]
                  )
                ])
              ]
            }),
            _vm._v(" "),
            _c("tr", { staticClass: "new-row" }, [
              _c("td", { attrs: { colspan: "5" } }, [
                _c(
                  "a",
                  {
                    staticClass: "add",
                    attrs: { href: "" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.newCategory.apply(null, arguments)
                      }
                    }
                  },
                  [_vm._v("+ Category")]
                )
              ])
            ])
          ],
          2
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "buttons" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-large btn-primary",
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.updateStatus.apply(null, arguments)
            }
          }
        },
        [_vm._v("Submit")]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticStyle: { width: "20%" } }, [_vm._v("Name")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "40%" } }, [_vm._v("Down message")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "16%" } }, [_vm._v("Color")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "16%" } }, [_vm._v("Down time")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "1%" } }, [_vm._v(" ")])
      ]),
      _vm._v(" "),
      _c("tr", [_c("th", { staticClass: "spacer", attrs: { colspan: "5" } })])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }