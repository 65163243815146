var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("table", { staticClass: "bordered" }, [
      _c("tbody", [
        _vm._m(0),
        _vm._v(" "),
        _vm._m(1),
        _vm._v(" "),
        _vm._m(2),
        _vm._v(" "),
        _c(
          "tr",
          [
            _c("td", [_vm._v("Select One")]),
            _vm._v(" "),
            _vm._l(8, function(i) {
              return _c("td", { key: i, staticClass: "pen-checkbox" }, [
                _c("i")
              ])
            })
          ],
          2
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "header" }, [
      _c("td", { attrs: { colspan: "9" } }, [_vm._v("MERCHANT CHECKLIST")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "header text-center" }, [
      _c("td", [_vm._v("Class Note"), _c("br"), _vm._v("Class")]),
      _vm._v(" "),
      _c("td", [_vm._v("LR-AA"), _c("br"), _vm._v("00")]),
      _vm._v(" "),
      _c("td", [_vm._v("LR-CP"), _c("br"), _vm._v("10")]),
      _vm._v(" "),
      _c("td", [_vm._v("LR-CP"), _c("br"), _vm._v("20")]),
      _vm._v(" "),
      _c("td", [_vm._v("MR-MIX"), _c("br"), _vm._v("30")]),
      _vm._v(" "),
      _c("td", [_vm._v("HR-CNP"), _c("br"), _vm._v("40")]),
      _vm._v(" "),
      _c("td", [_vm._v("HR-CNP"), _c("br"), _vm._v("50")]),
      _vm._v(" "),
      _c("td", [_vm._v("HRS-CP"), _c("br"), _vm._v("98")]),
      _vm._v(" "),
      _c("td", [_vm._v("HRS-CNP"), _c("br"), _vm._v("99")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "ver-allign text-center" }, [
      _c("td", [_vm._v("Criteria")]),
      _vm._v(" "),
      _c("td", [
        _vm._v("Credit 500 +"),
        _c("br"),
        _vm._v("> 70% CP "),
        _c("br"),
        _vm._v("Gets Pin or"),
        _c("br"),
        _vm._v("Signature"),
        _c("br"),
        _vm._v("Avg Ticket"),
        _c("br"),
        _vm._v(" < $1,000"),
        _c("br"),
        _vm._v("< 1.2M Yr Vol"),
        _c("br"),
        _c("br"),
        _vm._v("NOT MCC’s:"),
        _c("br"),
        _vm._v("1520; 4816;"),
        _c("br"),
        _vm._v("4829; 5734;"),
        _c("br"),
        _vm._v("5999; 6051;"),
        _c("br"),
        _vm._v("7321; 7375")
      ]),
      _vm._v(" "),
      _c("td", [
        _vm._v("Credit 400 +"),
        _c("br"),
        _vm._v("> 70% CP"),
        _c("br"),
        _vm._v(" Gets Pin or"),
        _c("br"),
        _vm._v("Signature"),
        _c("br"),
        _vm._v(" Avg Ticket"),
        _c("br"),
        _vm._v("< $1,000"),
        _c("br"),
        _vm._v(" < 6M Yr Vol")
      ]),
      _vm._v(" "),
      _c("td", [
        _vm._v("Credit 400 +"),
        _c("br"),
        _vm._v("> 70% CP"),
        _c("br"),
        _vm._v(" Gets Pin or"),
        _c("br"),
        _vm._v("Signature"),
        _c("br"),
        _vm._v(" Avg Ticket"),
        _c("br"),
        _vm._v("< $1,000 "),
        _c("br"),
        _vm._v("> 6M Yr Vol")
      ]),
      _vm._v(" "),
      _c("td", [
        _vm._v("Credit 400 +"),
        _c("br"),
        _vm._v("CP & CNP"),
        _c("br"),
        _vm._v(" Gets Pin or"),
        _c("br"),
        _vm._v("Signature"),
        _c("br"),
        _vm._v(" Avg Ticket"),
        _c("br"),
        _vm._v("< $1,000"),
        _c("br"),
        _vm._v("< 10M Yr Vol")
      ]),
      _vm._v(" "),
      _c("td", [
        _vm._v("Credit 400 +"),
        _c("br"),
        _vm._v("> 30% CNP "),
        _c("br"),
        _vm._v("Avg Ticket"),
        _c("br"),
        _vm._v("< $1,000"),
        _c("br"),
        _vm._v("< 3M Yr Vol")
      ]),
      _vm._v(" "),
      _c("td", [
        _vm._v("Credit 400 +"),
        _c("br"),
        _vm._v("> 30% CNP"),
        _c("br"),
        _vm._v(" Avg Ticket"),
        _c("br"),
        _vm._v("< $1,000"),
        _c("br"),
        _vm._v(" > 3M Yr Vol")
      ]),
      _vm._v(" "),
      _c("td", [
        _vm._v("Credit 400 +"),
        _c("br"),
        _vm._v("> 70% CP"),
        _c("br"),
        _vm._v(" Gets Pin or"),
        _c("br"),
        _vm._v("Signature"),
        _c("br"),
        _vm._v("Avg Ticket"),
        _c("br"),
        _vm._v("> $1,000"),
        _c("br"),
        _vm._v("> 10M Yr Vol")
      ]),
      _vm._v(" "),
      _c("td", [
        _vm._v("Credit 400 +"),
        _c("br"),
        _vm._v("> 30% CNP"),
        _c("br"),
        _vm._v(" Avg Ticket"),
        _c("br"),
        _vm._v("> $1,000"),
        _c("br"),
        _vm._v(" > 10M Yr Vol")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }