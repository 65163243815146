var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      [_c("merchant-setup-popup", { ref: "setup", attrs: { inline: true } })],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "actions" }, [
      _c(
        "button",
        {
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.$emit("back")
            }
          }
        },
        [_vm._v("Back")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }