var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "content-table-container usermgmt" }, [
      _c("table", { staticClass: "content-table usermgmt" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "tbody",
          _vm._l(_vm.items, function(item, index) {
            return _c("tr", [
              _c("td", { staticClass: "text-left" }, [
                _c(
                  "a",
                  {
                    attrs: { href: "" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.showItem(index)
                      }
                    }
                  },
                  [_vm._v(_vm._s(item.username))]
                )
              ]),
              _vm._v(" "),
              _c("td", {}, [
                _vm._v(
                  "\n            " + _vm._s(item.agentId || 0) + "\n          "
                )
              ]),
              _vm._v(" "),
              _c("td", {}, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.groups[item.groups]) +
                    "\n          "
                )
              ]),
              _vm._v(" "),
              _c("td", { staticClass: "text-right" }, [
                _c(
                  "a",
                  {
                    staticClass: "block",
                    attrs: { href: "" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.blockItem(index)
                      }
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(item.active == "Blocked" ? "Unblock" : "Block")
                    )
                  ]
                ),
                _vm._v(" "),
                item.groups && item.groups.indexOf("admin-") == 0
                  ? _c(
                      "a",
                      {
                        staticClass: "edit",
                        attrs: { href: "" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.editItem(index)
                          }
                        }
                      },
                      [_vm._v("Edit")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                item.groups && item.groups.indexOf("admin-") == 0
                  ? _c(
                      "a",
                      {
                        staticClass: "remove",
                        attrs: { href: "" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.deleteItem(index)
                          }
                        }
                      },
                      [_vm._v("Delete")]
                    )
                  : _vm._e()
              ])
            ])
          }),
          0
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticStyle: { width: "30%" } }, [_vm._v("Username")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "10%" } }, [_vm._v("ID")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "30%" } }, [_vm._v("Role")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "30%" } }, [_vm._v("Actions")])
      ]),
      _vm._v(" "),
      _c("tr", [_c("th", { staticClass: "spacer", attrs: { colspan: "3" } })])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }