var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "progress-bar" },
    _vm._l(_vm.steps, function(cstep, index) {
      return _c(
        "li",
        { class: { completed: index < _vm.step, current: index == _vm.step } },
        [_c("span", { domProps: { innerHTML: _vm._s(cstep) } })]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }