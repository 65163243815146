var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "preform-outer" }, [
    _c("div", { staticClass: "preformbg" }),
    _vm._v(" "),
    _c("div", { staticClass: "appf preform ncontent" }, [
      _c("div", { staticClass: "wcontent" }, [
        _c(
          "div",
          {
            staticClass: "cb-logo",
            style: {
              "background-image":
                'url("/files/company/' + _vm.companydata.preqHeadLogo + '")'
            }
          },
          [_vm._v("CardBiller")]
        ),
        _vm._v(" "),
        _c("h1", [_vm._v("Merchant Qualification")]),
        _vm._v(" "),
        _c("div", { staticClass: "form-info" }, [
          _c("p", [_vm._v(_vm._s(_vm.companydata.preqHeadText))]),
          _vm._v(" "),
          _c("p", [
            _c("strong", [_vm._v(_vm._s(_vm.companydata.preqHeadEmail))])
          ]),
          _vm._v(" "),
          _c("p", [
            _c("strong", [_vm._v(_vm._s(_vm.companydata.preqHeadPhone))])
          ])
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "form-intro" }, [
          _vm._v("\r\n    " + _vm._s(_vm.companydata.preqHeadDesc) + "\r\n  ")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "temp-link" }, [
          _c("label", [_vm._v("Guest access to this application:")]),
          _vm._v(" "),
          !_vm.link
            ? _c("span", [
                _c(
                  "button",
                  {
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.generateLink.apply(null, arguments)
                      }
                    }
                  },
                  [_vm._v("Generate Link")]
                )
              ])
            : _c(
                "a",
                {
                  attrs: { href: _vm.link.url, target: "_blank" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.copyLink.apply(null, arguments)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.link.url))]
              )
        ]),
        _vm._v(" "),
        _c(
          "ul",
          { staticClass: "pqf-progress" },
          _vm._l(_vm.steps, function(stepInfo) {
            return _c(
              "li",
              {
                class: {
                  past: _vm.step > stepInfo.index,
                  current: _vm.step == stepInfo.index
                }
              },
              [
                _c("span", [
                  _vm._v(_vm._s(stepInfo.index) + ". " + _vm._s(stepInfo.title))
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "stat ip" }, [_vm._v("In Progress")]),
                _vm._v(" "),
                _c("span", { staticClass: "stat co" }, [_vm._v("Complete")])
              ]
            )
          }),
          0
        ),
        _vm._v(" "),
        _vm.step <= 4
          ? _c("form", { staticClass: "preq-form" }, [
              _vm.step == 0
                ? _c(
                    "section",
                    { staticClass: "pqcat-select" },
                    [
                      _c("h3", [
                        _vm._v(
                          "To get started, tell us what type of merchant you are:"
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "validator",
                        {
                          attrs: { value: _vm.item.industry, reqs: "required" }
                        },
                        [
                          _c(
                            "ul",
                            { staticClass: "pqcat-list" },
                            [
                              _c(
                                "li",
                                {
                                  staticClass: "card-select",
                                  class: { selected: _vm.mainType == "cp" }
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "" },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          _vm.mainType = "cp"
                                        }
                                      }
                                    },
                                    [_vm._v("Card Present (Point of Sale)")]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "li",
                                {
                                  staticClass: "card-select",
                                  class: { selected: _vm.mainType == "cnp" }
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "" },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          _vm.mainType = "cnp"
                                        }
                                      }
                                    },
                                    [_vm._v("Card not Present (E-Commerce)")]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _vm._l(_vm.industries, function(ind) {
                                return _c(
                                  "li",
                                  {
                                    class:
                                      ind.class +
                                      " " +
                                      (_vm.item.industry == ind.label
                                        ? "selected"
                                        : "") +
                                      " " +
                                      (_vm.indAvail(ind) ? "" : "disabled")
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "" },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            _vm.indAvail(ind) &&
                                              (_vm.item.industry = ind.label)
                                          }
                                        }
                                      },
                                      [_vm._v(_vm._s(ind.label))]
                                    )
                                  ]
                                )
                              })
                            ],
                            2
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.step == 2
                ? _c("section", [
                    _vm._m(0),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "checkbox maincheck",
                        class: { checked: _vm.item.companyNew }
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.companyNew,
                              expression: "item.companyNew"
                            }
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.item.companyNew)
                              ? _vm._i(_vm.item.companyNew, null) > -1
                              : _vm.item.companyNew
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.item.companyNew,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.item,
                                      "companyNew",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.item,
                                      "companyNew",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.item, "companyNew", $$c)
                              }
                            }
                          }
                        }),
                        _c("span"),
                        _vm._v("\r\n        This is a "),
                        _c("strong", [_vm._v("New Business")])
                      ]
                    ),
                    _vm._v(" "),
                    _c("ul", { staticClass: "preform-sp3" }, [
                      _c(
                        "li",
                        [
                          _c("label", [_vm._v("Legal Business Name")]),
                          _vm._v(" "),
                          _c(
                            "validator",
                            {
                              attrs: {
                                value: _vm.item.companyName,
                                reqs: "required legalName"
                              }
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.item.companyName,
                                    expression: "item.companyName"
                                  }
                                ],
                                attrs: { type: "text" },
                                domProps: { value: _vm.item.companyName },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.item,
                                      "companyName",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        [
                          _c("label", [_vm._v("Trade Name/DBA")]),
                          _vm._v(" "),
                          _c(
                            "validator",
                            {
                              attrs: {
                                value: _vm.item.companyNameTrade,
                                reqs: "legalName"
                              }
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.item.companyNameTrade,
                                    expression: "item.companyNameTrade"
                                  }
                                ],
                                attrs: { type: "text" },
                                domProps: { value: _vm.item.companyNameTrade },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.item,
                                      "companyNameTrade",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        {},
                        [
                          _c("label", [_vm._v("Business Start Date")]),
                          _vm._v(" "),
                          _c(
                            "validator",
                            {
                              attrs: {
                                value: _vm.item.companyDate,
                                reqs: "required"
                              }
                            },
                            [
                              _c("datepicker", {
                                attrs: { future: false },
                                model: {
                                  value: _vm.item.companyDate,
                                  callback: function($$v) {
                                    _vm.$set(_vm.item, "companyDate", $$v)
                                  },
                                  expression: "item.companyDate"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("ul", { staticClass: "preform-sp3" }, [
                      _c(
                        "li",
                        { staticStyle: { width: "31.3333333%" } },
                        [
                          _c("label", [_vm._v("Address")]),
                          _vm._v(" "),
                          _c(
                            "validator",
                            {
                              attrs: {
                                value: _vm.item.companyAddress,
                                reqs: "required address"
                              }
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.item.companyAddress,
                                    expression: "item.companyAddress"
                                  }
                                ],
                                attrs: { type: "text" },
                                domProps: { value: _vm.item.companyAddress },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.item,
                                      "companyAddress",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        { staticStyle: { width: "13.6666666%" } },
                        [
                          _c("label", [_vm._v("City")]),
                          _vm._v(" "),
                          _c(
                            "validator",
                            {
                              attrs: {
                                value: _vm.item.companyAddressCity,
                                reqs: "required city"
                              }
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.item.companyAddressCity,
                                    expression: "item.companyAddressCity"
                                  }
                                ],
                                attrs: { type: "text" },
                                domProps: {
                                  value: _vm.item.companyAddressCity
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.item,
                                      "companyAddressCity",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        { staticStyle: { width: "13.6666666%" } },
                        [
                          _c("label", [_vm._v("Postal Code")]),
                          _vm._v(" "),
                          _c(
                            "validator",
                            {
                              attrs: {
                                value: _vm.item.companyAddressPostcode,
                                reqs: "required postcode2"
                              }
                            },
                            [
                              _c("formel-zipcode", {
                                model: {
                                  value: _vm.item.companyAddressPostcode,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.item,
                                      "companyAddressPostcode",
                                      $$v
                                    )
                                  },
                                  expression: "item.companyAddressPostcode"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        { staticStyle: { width: "31.3333333%" } },
                        [
                          _c("label", [_vm._v("Province")]),
                          _vm._v(" "),
                          _vm.item.companyAddressCountry == "CA" ||
                          _vm.item.companyAddressCountry == "US"
                            ? _c(
                                "validator",
                                {
                                  attrs: {
                                    value: _vm.item.companyAddressState,
                                    reqs: "required"
                                  }
                                },
                                [
                                  _c("province-select", {
                                    attrs: {
                                      country: _vm.item.companyAddressCountry
                                    },
                                    model: {
                                      value: _vm.item.companyAddressState,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.item,
                                          "companyAddressState",
                                          $$v
                                        )
                                      },
                                      expression: "item.companyAddressState"
                                    }
                                  })
                                ],
                                1
                              )
                            : _c("validator", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.item.companyAddressState,
                                      expression: "item.companyAddressState"
                                    }
                                  ],
                                  attrs: { type: "text" },
                                  domProps: {
                                    value: _vm.item.companyAddressState
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.item,
                                        "companyAddressState",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ])
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("ul", { staticClass: "preform-sp3" }, [
                      _c(
                        "li",
                        [
                          _c("label", [_vm._v("Country")]),
                          _vm._v(" "),
                          _c(
                            "validator",
                            {
                              attrs: {
                                value: _vm.item.companyAddressCountry,
                                reqs: "required postcode"
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "combo-select" },
                                [
                                  _c("dyn-select", {
                                    attrs: { options: _vm.validCountries },
                                    model: {
                                      value: _vm.item.companyAddressCountry,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.item,
                                          "companyAddressCountry",
                                          $$v
                                        )
                                      },
                                      expression: "item.companyAddressCountry"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        [
                          _c("label", [_vm._v("Phone Number")]),
                          _vm._v(" "),
                          _c(
                            "validator",
                            {
                              attrs: {
                                value: _vm.item.companyPhone,
                                reqs: "required phone"
                              }
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.item.companyPhone,
                                    expression: "item.companyPhone"
                                  }
                                ],
                                attrs: { type: "text" },
                                domProps: { value: _vm.item.companyPhone },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.item,
                                      "companyPhone",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        [
                          _c("label", [_vm._v("Company Email")]),
                          _vm._v(" "),
                          _c(
                            "validator",
                            {
                              attrs: {
                                value: _vm.item.companyEmail,
                                reqs: "required email"
                              }
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.item.companyEmail,
                                    expression: "item.companyEmail"
                                  }
                                ],
                                attrs: { type: "text" },
                                domProps: { value: _vm.item.companyEmail },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.item,
                                      "companyEmail",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          )
                        ],
                        1
                      )
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.step == 1
                ? _c(
                    "section",
                    [
                      _vm._m(1),
                      _vm._v(" "),
                      _vm._l(_vm.item.ubos, function(ubo, ui) {
                        return _c("ul", { staticClass: "preform-sp3" }, [
                          _vm.item.ubos.length > 1 && ui
                            ? _c("li", { staticClass: "remove" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "remove",
                                    attrs: { href: "" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.removeUbo(ui)
                                      }
                                    }
                                  },
                                  [_vm._v("×")]
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "li",
                            [
                              _c("label", [
                                _c("strong", [
                                  _vm._v("Contact " + _vm._s(ui + 1))
                                ]),
                                _vm._v(" First Name")
                              ]),
                              _vm._v(" "),
                              _c(
                                "validator",
                                {
                                  attrs: {
                                    value: ubo.firstName,
                                    reqs: "required city"
                                  }
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: ubo.firstName,
                                        expression: "ubo.firstName"
                                      }
                                    ],
                                    attrs: { type: "text" },
                                    domProps: { value: ubo.firstName },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          ubo,
                                          "firstName",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "li",
                            [
                              _c("label", [
                                _c("strong", [
                                  _vm._v("Contact " + _vm._s(ui + 1))
                                ]),
                                _vm._v(" Last Name")
                              ]),
                              _vm._v(" "),
                              _c(
                                "validator",
                                {
                                  attrs: {
                                    value: ubo.lastName,
                                    reqs: "required city"
                                  }
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: ubo.lastName,
                                        expression: "ubo.lastName"
                                      }
                                    ],
                                    attrs: { type: "text" },
                                    domProps: { value: ubo.lastName },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          ubo,
                                          "lastName",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "li",
                            [
                              _c("label", [
                                _c("strong", [
                                  _vm._v("Contact " + _vm._s(ui + 1))
                                ]),
                                _vm._v(" E-mail")
                              ]),
                              _vm._v(" "),
                              _c(
                                "validator",
                                {
                                  attrs: {
                                    value: ubo.email,
                                    reqs: "required email"
                                  }
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: ubo.email,
                                        expression: "ubo.email"
                                      }
                                    ],
                                    attrs: { type: "text" },
                                    domProps: { value: ubo.email },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          ubo,
                                          "email",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "li",
                            [
                              _c("label", [
                                _c("strong", [
                                  _vm._v("Contact " + _vm._s(ui + 1))
                                ]),
                                _vm._v(" Phone")
                              ]),
                              _vm._v(" "),
                              _c(
                                "validator",
                                {
                                  attrs: {
                                    value: ubo.phone,
                                    reqs: "required phone"
                                  }
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: ubo.phone,
                                        expression: "ubo.phone"
                                      }
                                    ],
                                    attrs: { type: "text" },
                                    domProps: { value: ubo.phone },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          ubo,
                                          "phone",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        ])
                      }),
                      _vm._v(" "),
                      _vm.item.ubos.length < 5
                        ? _c("div", { staticClass: "new-ubo" }, [
                            _c(
                              "a",
                              {
                                attrs: { href: "" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.newUbo.apply(null, arguments)
                                  }
                                }
                              },
                              [_vm._v("Add Additional Contact")]
                            )
                          ])
                        : _vm._e()
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.step == 3
                ? _c("section", [
                    _vm._m(2),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "pgf-main-cb type2" },
                      [
                        _c("p", { staticClass: "intro" }, [
                          _vm._v(
                            "Does this business have a website that accepts payments?"
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "validator",
                          {
                            attrs: {
                              value: _vm.item.hasWebsite,
                              reqs: "required"
                            }
                          },
                          [
                            _c("ul", { staticClass: "toggle-main" }, [
                              _c("li", { staticClass: "disabled" }, [
                                _c(
                                  "label",
                                  {
                                    class: {
                                      checked: _vm.item.hasWebsite === "1"
                                    }
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.item.hasWebsite,
                                          expression: "item.hasWebsite"
                                        }
                                      ],
                                      attrs: {
                                        type: "radio",
                                        name: "hasParent",
                                        value: "1",
                                        disabled: ""
                                      },
                                      domProps: {
                                        checked: _vm._q(
                                          _vm.item.hasWebsite,
                                          "1"
                                        )
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.$set(
                                            _vm.item,
                                            "hasWebsite",
                                            "1"
                                          )
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("i"),
                                    _vm._v(" "),
                                    _c("strong", [_vm._v("Yes")])
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c("li", { staticClass: "disabled" }, [
                                _c(
                                  "label",
                                  {
                                    class: {
                                      checked: _vm.item.hasWebsite === "0"
                                    }
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.item.hasWebsite,
                                          expression: "item.hasWebsite"
                                        }
                                      ],
                                      attrs: {
                                        type: "radio",
                                        name: "hasParent",
                                        value: "0",
                                        disabled: ""
                                      },
                                      domProps: {
                                        checked: _vm._q(
                                          _vm.item.hasWebsite,
                                          "0"
                                        )
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.$set(
                                            _vm.item,
                                            "hasWebsite",
                                            "0"
                                          )
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("i"),
                                    _vm._v(" "),
                                    _c("strong", [_vm._v("No")])
                                  ]
                                )
                              ])
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.item.hasWebsite * 1
                      ? _c("ul", { staticClass: "preform-sp3 tol" }, [
                          _c(
                            "li",
                            [
                              _c("label", [_vm._v("Website URL")]),
                              _vm._v(" "),
                              _c(
                                "validator",
                                {
                                  attrs: {
                                    value: _vm.item.website,
                                    reqs: "required url"
                                  }
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.item.website,
                                        expression: "item.website"
                                      }
                                    ],
                                    attrs: { type: "text" },
                                    domProps: { value: _vm.item.website },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.item,
                                          "website",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "li",
                            [
                              _c("label", [_vm._v("Is Website Live?")]),
                              _vm._v(" "),
                              _c(
                                "validator",
                                {
                                  staticClass: "reds",
                                  attrs: {
                                    value: _vm.item.websiteBeta,
                                    reqs: "required"
                                  }
                                },
                                [
                                  _c("formel-toggle-date", {
                                    attrs: {
                                      future: true,
                                      custom: "",
                                      default: "No",
                                      customLabel: "No (If not when)",
                                      defaultLabel: "Yes"
                                    },
                                    model: {
                                      value: _vm.item.websiteBeta,
                                      callback: function($$v) {
                                        _vm.$set(_vm.item, "websiteBeta", $$v)
                                      },
                                      expression: "item.websiteBeta"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.item.hasWebsite * 1
                      ? _c("ul", { staticClass: "preform-sp3 tol" }, [
                          _c(
                            "li",
                            [
                              _c("label", [_vm._v("Username")]),
                              _vm._v(" "),
                              _c(
                                "validator",
                                {
                                  attrs: {
                                    value: _vm.item.websiteUsername,
                                    reqs: ""
                                  }
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.item.websiteUsername,
                                        expression: "item.websiteUsername"
                                      }
                                    ],
                                    attrs: { type: "text" },
                                    domProps: {
                                      value: _vm.item.websiteUsername
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.item,
                                          "websiteUsername",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "li",
                            [
                              _c("label", [_vm._v("Password")]),
                              _vm._v(" "),
                              _c(
                                "validator",
                                {
                                  attrs: {
                                    value: _vm.item.websitePassword,
                                    reqs: ""
                                  }
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.item.websitePassword,
                                        expression: "item.websitePassword"
                                      }
                                    ],
                                    attrs: { type: "text" },
                                    domProps: {
                                      value: _vm.item.websitePassword
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.item,
                                          "websitePassword",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("ul", [
                      _c(
                        "li",
                        { staticClass: "fullw1" },
                        [
                          _c("label", [
                            _vm._v(
                              "Full Description of Products and/or Services"
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "validator",
                            {
                              attrs: {
                                value: _vm.item.businessDescription,
                                reqs: "required char250"
                              }
                            },
                            [
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.item.businessDescription,
                                    expression: "item.businessDescription"
                                  }
                                ],
                                domProps: {
                                  value: _vm.item.businessDescription
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.item,
                                      "businessDescription",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          )
                        ],
                        1
                      )
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.step == 3
                ? _c(
                    "section",
                    [
                      _vm._m(3),
                      _vm._v(" "),
                      _c("ul", { staticClass: "preform-sp3" }, [
                        _c(
                          "li",
                          { staticClass: "wq" },
                          [
                            _c("label", [_vm._v("Monthly Limit Requested")]),
                            _vm._v(" "),
                            _c(
                              "validator",
                              {
                                attrs: {
                                  value: _vm.item.volume,
                                  reqs: "required num10"
                                }
                              },
                              [
                                _c("formel-money-amount", {
                                  model: {
                                    value: _vm.item.volume,
                                    callback: function($$v) {
                                      _vm.$set(_vm.item, "volume", $$v)
                                    },
                                    expression: "item.volume"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "li",
                          { staticClass: "wq" },
                          [
                            _c("label", [_vm._v("Highest Ticket")]),
                            _vm._v(" "),
                            _c(
                              "validator",
                              {
                                attrs: {
                                  value: _vm.item.maxTransaction,
                                  reqs: "required num10"
                                }
                              },
                              [
                                _c("formel-money-amount", {
                                  model: {
                                    value: _vm.item.maxTransaction,
                                    callback: function($$v) {
                                      _vm.$set(_vm.item, "maxTransaction", $$v)
                                    },
                                    expression: "item.maxTransaction"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "li",
                          { staticClass: "wq" },
                          [
                            _c("label", [_vm._v("Average Ticket")]),
                            _vm._v(" "),
                            _c(
                              "validator",
                              {
                                attrs: {
                                  value: _vm.item.avgTransaction,
                                  reqs: "required num10"
                                }
                              },
                              [
                                _c("formel-money-amount", {
                                  model: {
                                    value: _vm.item.avgTransaction,
                                    callback: function($$v) {
                                      _vm.$set(_vm.item, "avgTransaction", $$v)
                                    },
                                    expression: "item.avgTransaction"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "li",
                          { staticClass: "wq" },
                          [
                            _c("label", [_vm._v("Lowest Ticket")]),
                            _vm._v(" "),
                            _c(
                              "validator",
                              {
                                attrs: {
                                  value: _vm.item.minTransaction,
                                  reqs: "required num10"
                                }
                              },
                              [
                                _c("formel-money-amount", {
                                  model: {
                                    value: _vm.item.minTransaction,
                                    callback: function($$v) {
                                      _vm.$set(_vm.item, "minTransaction", $$v)
                                    },
                                    expression: "item.minTransaction"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("ul", { staticClass: "preform-sp3" }, [
                        _c(
                          "li",
                          { staticClass: "wf" },
                          [
                            _c("label", [
                              _vm._v("Are you currently processing?")
                            ]),
                            _vm._v(" "),
                            _c(
                              "validator",
                              {
                                attrs: {
                                  value: _vm.item.acceptsCards,
                                  reqs: "required"
                                }
                              },
                              [
                                _c("formel-toggle", {
                                  model: {
                                    value: _vm.item.acceptsCards,
                                    callback: function($$v) {
                                      _vm.$set(_vm.item, "acceptsCards", $$v)
                                    },
                                    expression: "item.acceptsCards"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "li",
                          { staticClass: "wf" },
                          [
                            _c("label", [
                              _vm._v("Have you previously processed?")
                            ]),
                            _vm._v(" "),
                            _c(
                              "validator",
                              {
                                attrs: {
                                  value: _vm.item.acceptedCards,
                                  reqs: "required"
                                }
                              },
                              [
                                _c("formel-toggle", {
                                  model: {
                                    value: _vm.item.acceptedCards,
                                    callback: function($$v) {
                                      _vm.$set(_vm.item, "acceptedCards", $$v)
                                    },
                                    expression: "item.acceptedCards"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "li",
                          { staticClass: "wf" },
                          [
                            _c("label", [
                              _vm._v("Do you offer an affiliate program?")
                            ]),
                            _vm._v(" "),
                            _c(
                              "validator",
                              {
                                attrs: {
                                  value: _vm.item.affiliate,
                                  reqs: "required"
                                }
                              },
                              [
                                _c("formel-toggle", {
                                  class: { disabled: _vm.mainType == "cp" },
                                  style:
                                    _vm.mainType == "cp"
                                      ? "pointer-events: none; opacity: 0.5"
                                      : "",
                                  model: {
                                    value: _vm.item.affiliate,
                                    callback: function($$v) {
                                      _vm.$set(_vm.item, "affiliate", $$v)
                                    },
                                    expression: "item.affiliate"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "li",
                          { staticClass: "wf" },
                          [
                            _c("label", [
                              _vm._v(
                                "Has the company or any owner(s) ever been terminated and/or placed on the TMF/Match List?"
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "validator",
                              {
                                staticClass: "noinput",
                                attrs: {
                                  value: _vm.item.violationDetails,
                                  reqs: "required"
                                }
                              },
                              [
                                _c("formel-toggle", {
                                  model: {
                                    value: _vm.item.violationDetails,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.item,
                                        "violationDetails",
                                        $$v
                                      )
                                    },
                                    expression: "item.violationDetails"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("h2", [
                        _c("em", [_vm._v("Payment Acceptance")]),
                        _vm._v(" "),
                        _c("span", { staticClass: "total-percent" }, [
                          _vm._v(
                            _vm._s(
                              (_vm.item.methodECommerce * 1 || 0) +
                                (_vm.item.methodPOS * 1 || 0) +
                                (_vm.item.methodMPOS * 1 || 0) +
                                (_vm.item.methodMCommerce * 1 || 0) +
                                (_vm.item.methodMOTO * 1 || 0) +
                                (_vm.item.methodApp * 1 || 0)
                            ) + "%"
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "validator",
                        {
                          staticClass: "percentSplit",
                          attrs: {
                            value: [
                              _vm.item.methodECommerce,
                              _vm.item.methodMCommerce,
                              _vm.item.methodPOS,
                              _vm.item.methodMOTO,
                              _vm.item.methodMPOS,
                              _vm.item.methodApp
                            ],
                            reqs: "sum100"
                          }
                        },
                        [
                          _c("ul", { staticClass: "preform-sp3 preform-pi" }, [
                            _c(
                              "li",
                              { class: { disabled: _vm.mainType != "cnp" } },
                              [
                                _c("label", [_vm._v("E-Commerce %")]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.item.methodECommerce,
                                      expression: "item.methodECommerce"
                                    }
                                  ],
                                  attrs: {
                                    type: "text",
                                    disabled: _vm.mainType != "cnp"
                                  },
                                  domProps: { value: _vm.item.methodECommerce },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.item,
                                        "methodECommerce",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ]
                            ),
                            _vm._v(" "),
                            false
                              ? _c("li", [
                                  _c("label", [_vm._v("M-Commerce %")]),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.item.methodMCommerce,
                                        expression: "item.methodMCommerce"
                                      }
                                    ],
                                    attrs: { type: "text" },
                                    domProps: {
                                      value: _vm.item.methodMCommerce
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.item,
                                          "methodMCommerce",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "li",
                              { class: { disabled: _vm.mainType != "cp" } },
                              [
                                _c("label", [_vm._v("Card Present %")]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.item.methodPOS,
                                      expression: "item.methodPOS"
                                    }
                                  ],
                                  attrs: {
                                    type: "text",
                                    disabled: _vm.mainType != "cp"
                                  },
                                  domProps: { value: _vm.item.methodPOS },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.item,
                                        "methodPOS",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "li",
                              { class: { disabled: _vm.mainType != "cnp" } },
                              [
                                _c("label", [_vm._v("MOTO %")]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.item.methodMOTO,
                                      expression: "item.methodMOTO"
                                    }
                                  ],
                                  attrs: {
                                    type: "text",
                                    disabled: _vm.mainType != "cnp"
                                  },
                                  domProps: { value: _vm.item.methodMOTO },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.item,
                                        "methodMOTO",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "li",
                              { class: { disabled: _vm.mainType != "cp" } },
                              [
                                _c("label", [_vm._v("M-POS %")]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.item.methodMPOS,
                                      expression: "item.methodMPOS"
                                    }
                                  ],
                                  attrs: {
                                    type: "text",
                                    disabled: _vm.mainType != "cp"
                                  },
                                  domProps: { value: _vm.item.methodMPOS },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.item,
                                        "methodMPOS",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "li",
                              { class: { disabled: _vm.mainType != "cnp" } },
                              [
                                _c("label", [_vm._v("In-App %")]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.item.methodApp,
                                      expression: "item.methodApp"
                                    }
                                  ],
                                  attrs: {
                                    type: "text",
                                    disabled: _vm.mainType != "cnp"
                                  },
                                  domProps: { value: _vm.item.methodApp },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.item,
                                        "methodApp",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ]
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.step == 4
                ? _c(
                    "section",
                    [
                      _vm._m(4),
                      _vm._v(" "),
                      _vm._m(5),
                      _vm._v(" "),
                      _c(
                        "validator",
                        {
                          attrs: {
                            value: _vm.item.additionalComments,
                            reqs: "char250"
                          }
                        },
                        [
                          _c("div", { staticClass: "single-field" }, [
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.item.additionalComments,
                                  expression: "item.additionalComments"
                                }
                              ],
                              staticClass: "more-details",
                              domProps: { value: _vm.item.additionalComments },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.item,
                                    "additionalComments",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ])
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.step == 4
                ? _c(
                    "section",
                    { staticClass: "upload" },
                    [
                      _vm._m(6),
                      _vm._v(" "),
                      _vm._m(7),
                      _vm._v(" "),
                      _c("p", { staticClass: "intro" }, [
                        _vm._v(
                          "Please use the section below to upload documents:"
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "clearfx" }),
                      _vm._v(" "),
                      _c(
                        "validator",
                        { attrs: { value: _vm.files[0], reqs: "" } },
                        [
                          _c(
                            "checkbox-file",
                            {
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "more",
                                    fn: function() {
                                      return [
                                        _vm._v(
                                          "6 Most Recent Processing Statements for Company"
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                false,
                                73084209
                              ),
                              model: {
                                value: _vm.files[0],
                                callback: function($$v) {
                                  _vm.$set(_vm.files, 0, $$v)
                                },
                                expression: "files[0]"
                              }
                            },
                            [
                              _vm._v(
                                "\r\n          6 Month Processing Statements\r\n          "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.step == 4
                ? _c("ul", { staticClass: "notes" }, [
                    _c("li", [_vm._v("Additional requirements may apply")]),
                    _vm._v(" "),
                    _c("li", [
                      _vm._v(
                        "Please submit all requested items on the following page"
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _vm._v(
                        "By completing this form, you agree that your personal data may be shared with third parties for on boarding and Know-Your-Customer purposes."
                      )
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "buttons" }, [
                _vm.step >= 1
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-large btn-primary btn-back",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.prevStep.apply(null, arguments)
                          }
                        }
                      },
                      [_vm._v("Previous Step")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.step < 4
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-large btn-primary",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.nextStep.apply(null, arguments)
                          }
                        }
                      },
                      [_vm._v("Next Step")]
                    )
                  : _c(
                      "button",
                      {
                        staticClass: "btn btn-large btn-primary",
                        attrs: { disabled: _vm.sending },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.send.apply(null, arguments)
                          }
                        }
                      },
                      [_vm._v("Submit")]
                    )
              ])
            ])
          : _vm.step == 5
          ? _c("div", { staticClass: "pre-done" }, [
              _c("h1", [_vm._v("Thank you for submitting your application!")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "One of our account Representatives will contact you shortly."
                )
              ]),
              _vm._v(" "),
              !_vm.guest
                ? _c(
                    "a",
                    {
                      staticClass: "btn btn-large btn-primary btn-black",
                      attrs: { href: "" }
                    },
                    [_vm._v("Back to Dashboard")]
                  )
                : _vm._e()
            ])
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", [_c("em", [_vm._v("Company Information")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", [_c("em", [_vm._v("Contact information")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", [_c("em", [_vm._v("Business Information")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", [_c("em", [_vm._v("Business Details")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", [_c("em", [_vm._v("Additional Notes")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "forta" }, [
      _c("span", [
        _vm._v("Use this section to include any additional notes or comments")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", [_c("em", [_vm._v("Upload documents")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "page-7-right-text" }, [
      _vm._v("Only the following file types are accepted:"),
      _c("br"),
      _vm._v("jpg, jpeg, gif, tif, tiff, pdf")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }