var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "action-buttons" }, [
      _c(
        "a",
        {
          class: { disabled: _vm.item.agentDb.status != "A02" },
          attrs: { href: "" },
          on: {
            click: function($event) {
              $event.preventDefault()
              _vm.item.agentDb.status == "A02" && _vm.startAgreement()
            }
          }
        },
        [_vm._v("Start Agreement")]
      )
    ]),
    _vm._v(" "),
    _c("table", { staticClass: "content-table file-list" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "tbody",
        _vm._l(_vm.agreements, function(agreement, ai) {
          return _c("tr", [
            _c("td", [_vm._v(_vm._s(ai + 1))]),
            _vm._v(" "),
            _c("td", [
              _vm._v(
                _vm._s(_vm.formatDate(agreement.created, "YYYY-MM-DD hh:mm"))
              )
            ]),
            _vm._v(" "),
            _c("td", [
              _c(
                "a",
                {
                  attrs: {
                    target: "_blank",
                    href: _vm.agreementPath(agreement)
                  }
                },
                [_vm._v(_vm._s(agreement.name))]
              )
            ]),
            _vm._v(" "),
            _c("td", [
              agreement.filename
                ? _c(
                    "a",
                    {
                      attrs: {
                        target: "_blank",
                        href: _vm.fileLink(agreement.filename)
                      }
                    },
                    [_vm._v(_vm._s(agreement.name + " signed"))]
                  )
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("td", [
              _vm._v(
                _vm._s(_vm.formatDate(agreement.signed, "YYYY-MM-DD hh:mm"))
              )
            ]),
            _vm._v(" "),
            _c("td", [
              _c(
                "a",
                {
                  staticClass: "button",
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.uploadSigned(agreement, ai)
                    }
                  }
                },
                [_vm._v("Upload")]
              )
            ])
          ])
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticStyle: { width: "1%" } }, [_vm._v("#")]),
        _vm._v(" "),
        _c("th", [_vm._v("Date Created")]),
        _vm._v(" "),
        _c("th", [_vm._v("Agreement")]),
        _vm._v(" "),
        _c("th", [_vm._v("Date Signed")]),
        _vm._v(" "),
        _c("th", [_vm._v("Signed Agreement")]),
        _vm._v(" "),
        _c("th", [_vm._v("Action")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }