var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tr", { staticClass: "chat" }, [
    _c("td", { attrs: { colspan: "7" } }, [
      _c("div", { staticClass: "chat-con" }, [
        _c("ul", { staticClass: "chat-info" }, [
          _c("li", [
            _c("label", [_vm._v("Ticket #:")]),
            _c("em", [_vm._v(_vm._s(_vm.ticket.id))])
          ]),
          _vm._v(" "),
          _c("li", [
            _c("label", [_vm._v("Type:")]),
            _c("em", [_vm._v(_vm._s(_vm.ticket.type))])
          ]),
          _vm._v(" "),
          _c("li", [
            _c("label", [_vm._v("Account ID:")]),
            _c("em", [
              _vm._v(_vm._s(_vm.ticket.user && _vm.ticket.user.agentId))
            ])
          ]),
          _vm._v(" "),
          _c("li", [
            _c("label", [_vm._v("Name:")]),
            _c("em", [
              _vm._v(
                _vm._s(
                  _vm.ticket.user &&
                    (_vm.ticket.user.fullName ||
                      _vm.ticket.user.companyName ||
                      (_vm.ticket.user.contacts &&
                        _vm.ticket.user.contacts[0] &&
                        _vm.ticket.user.contacts[0].firstName +
                          " " +
                          _vm.ticket.user.contacts[0].lastName))
                )
              )
            ])
          ]),
          _vm._v(" "),
          _c("li", [
            _c("label", [_vm._v("E-Mail:")]),
            _c("em", [
              _vm._v(
                _vm._s(
                  _vm.ticket.user &&
                    (_vm.ticket.user.email ||
                      _vm.ticket.user.companyEmail ||
                      (_vm.ticket.user.agentDb &&
                        _vm.ticket.user.agentDb.email))
                )
              )
            ])
          ]),
          _vm._v(" "),
          _c("li", [
            _c("label", [_vm._v("Subject:")]),
            _c("em", [
              _vm._v(_vm._s(_vm.subjOptions[_vm.ticket.subject] || "Other"))
            ])
          ]),
          _vm._v(" "),
          _c("li", [
            _c("label", [_vm._v("Status:")]),
            _c("em", [_vm._v(_vm._s(_vm.ticket.status))])
          ]),
          _vm._v(" "),
          _c(
            "li",
            { staticClass: "close", staticStyle: { "margin-left": "auto" } },
            [
              _c(
                "button",
                {
                  staticClass: "btn",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.$parent.currentTicket = null
                    }
                  }
                },
                [_vm._v("Minimize")]
              )
            ]
          )
        ]),
        _vm._v(" "),
        _c(
          "ul",
          { ref: "messagesbox", staticClass: "cmessages" },
          [
            _vm._l(_vm.ticket.updates, function(update) {
              return [
                update.newStatus == null
                  ? _c(
                      "li",
                      {
                        class: {
                          in:
                            _vm.ticket.user &&
                            update.user &&
                            _vm.ticket.user.id == update.user.id,
                          out:
                            _vm.ticket.user &&
                            update.user &&
                            _vm.ticket.user.id != update.user.id
                        }
                      },
                      [
                        _c("p", {
                          domProps: {
                            innerHTML: _vm._s(_vm.ticketMessage(update))
                          }
                        }),
                        _vm._v(" "),
                        _c("date", [
                          _vm._v(
                            _vm._s(_vm.showUsersName(update.user)) +
                              " | " +
                              _vm._s(
                                _vm.formatDate(
                                  update.created,
                                  "MM/DD/YYYY h:mmA"
                                )
                              )
                          )
                        ])
                      ],
                      1
                    )
                  : _c("li", { staticClass: "sta" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.formatDate(update.created, "MM/DD/YYYY h:mmA")
                          ) +
                          " | "
                      ),
                      _c("strong", [
                        _vm._v(_vm._s(_vm.showUsersName(update.user)))
                      ]),
                      _vm._v(" has changed status to "),
                      _c("em", {
                        domProps: { innerHTML: _vm._s(update.newStatus) }
                      })
                    ])
              ]
            })
          ],
          2
        ),
        _vm._v(" "),
        _c("div", { staticClass: "chat-form" }, [
          _c("form", [
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.newMessage,
                  expression: "newMessage"
                }
              ],
              domProps: { value: _vm.newMessage },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.newMessage = $event.target.value
                }
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "chf-act" }, [
              _c(
                "button",
                {
                  staticClass: "main",
                  style: {
                    opacity:
                      _vm.ticket.status == "Closed" ||
                      _vm.ticket.status == "Resolved"
                        ? 0.5
                        : 1
                  },
                  attrs: {
                    disabled:
                      _vm.ticket.status == "Closed" ||
                      _vm.ticket.status == "Resolved"
                  },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.createMessage.apply(null, arguments)
                    }
                  }
                },
                [_vm._v("Send")]
              ),
              _vm._v(" "),
              _vm.admin &&
              (_vm.ticket.status == "Pending" || _vm.ticket.status == "Closed")
                ? _c(
                    "button",
                    {
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.setStatus("Open")
                        }
                      }
                    },
                    [_vm._v("Open")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.admin && _vm.ticket.status == "Open"
                ? _c(
                    "button",
                    {
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.setStatus("Resolved")
                        }
                      }
                    },
                    [_vm._v("Resolve")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.admin &&
              (_vm.ticket.status == "Pending" || _vm.ticket.status == "Open")
                ? _c(
                    "button",
                    {
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.setStatus("Closed")
                        }
                      }
                    },
                    [_vm._v("Close")]
                  )
                : _vm._e()
            ])
          ])
        ]),
        _vm._v(" "),
        _vm.admin
          ? _c("div", { staticClass: "updates-table" }, [
              _c("h3", [_vm._v("Ticket Actions History:")]),
              _vm._v(" "),
              _c(
                "table",
                [
                  _vm._l([_vm.ticket.updates[0]], function(update) {
                    return _c("tr", [
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm.formatDate(update.created, "MM/DD/YYYY h:mmA")
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v("ticket started by "),
                        _c("strong", [
                          _vm._v(_vm._s(_vm.showUsersName(update.user)))
                        ])
                      ])
                    ])
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.ticket.updates, function(update) {
                    return update.newStatus
                      ? _c("tr", [
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.formatDate(
                                  update.created,
                                  "MM/DD/YYYY h:mmA"
                                )
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _c("strong", [
                              _vm._v(_vm._s(_vm.showUsersName(update.user)))
                            ]),
                            _vm._v(" has changed status to "),
                            _c("em", {
                              domProps: { innerHTML: _vm._s(update.newStatus) }
                            })
                          ])
                        ])
                      : _vm._e()
                  })
                ],
                2
              )
            ])
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }