var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form", { staticClass: "form-step" }, [
    _c(
      "section",
      [
        _c("h2", [_vm._v("Ownership Profile")]),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _vm._l(_vm.app.directors, function(director, i) {
          return _c("ul", { staticClass: "column-form type2 bh" }, [
            _c(
              "li",
              {
                ref: "dirHead" + i,
                refInFor: true,
                staticClass: "head",
                class: { collapsable: _vm.app.directors.length > 1 },
                on: {
                  click: function($event) {
                    return _vm.toggleCollapse("dirHead" + i)
                  }
                }
              },
              [
                _c("div", [
                  _vm._v("\n          Director " + _vm._s(i + 1) + " "),
                  _vm.app.directors.length > 1 && i
                    ? _c(
                        "a",
                        {
                          staticClass: "remove",
                          attrs: { href: "", title: "Remove" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              $event.stopPropagation()
                              return _vm.app.directors.splice(i, 1)
                            }
                          }
                        },
                        [_c("span", [_vm._v("×")]), _vm._v("Remove director")]
                      )
                    : _vm._e()
                ])
              ]
            ),
            _vm._v(" "),
            _c("li", [
              _c("label", [_vm._v("First Name")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                [
                  _c(
                    "validator",
                    {
                      key: "df1x" + i,
                      attrs: {
                        value: director.firstName,
                        reqs: "required city"
                      }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: director.firstName,
                            expression: "director.firstName"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: director.firstName },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(director, "firstName", $event.target.value)
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("li", [
              _c("label", [_vm._v("Last name")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                [
                  _c(
                    "validator",
                    {
                      key: "df2x" + i,
                      attrs: { value: director.lastName, reqs: "required city" }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: director.lastName,
                            expression: "director.lastName"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: director.lastName },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(director, "lastName", $event.target.value)
                          }
                        }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _vm.app.directors.length < 5
                    ? _c("div", { staticClass: "add-dir" })
                    : _vm._e()
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("li", [
              _c("label", [_vm._v("Date of birth")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                [
                  _c(
                    "validator",
                    {
                      key: "df3x" + i,
                      attrs: { value: director.dob, reqs: "required" }
                    },
                    [
                      _c("datepicker", {
                        attrs: { type: "text", future: "18+" },
                        model: {
                          value: director.dob,
                          callback: function($$v) {
                            _vm.$set(director, "dob", $$v)
                          },
                          expression: "director.dob"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.app.directors.length < 5
                    ? _c("div", { staticClass: "add-dir" })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c("span", { staticClass: "pfx-info" }, [_vm._v("(YYYY MM DD)")])
            ]),
            _vm._v(" "),
            _c("li", [
              _c("label", [_vm._v("Street address")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                [
                  _c(
                    "validator",
                    {
                      key: "df4x" + i,
                      attrs: {
                        value: director.address,
                        reqs: "required address"
                      }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: director.address,
                            expression: "director.address"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: director.address },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(director, "address", $event.target.value)
                          }
                        }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _vm.app.directors.length < 5
                    ? _c("div", { staticClass: "add-dir" })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c("span", { staticClass: "pfx-info" }, [_vm._v("(home)")])
            ]),
            _vm._v(" "),
            _c("li", [
              _c("label", [_vm._v("Postal code / ZIP code")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                [
                  _c(
                    "validator",
                    {
                      key: "df6x" + i,
                      attrs: {
                        value: director.addressPostcode,
                        reqs: "required postcode"
                      }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: director.addressPostcode,
                            expression: "director.addressPostcode"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: director.addressPostcode },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              director,
                              "addressPostcode",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _vm.app.directors.length < 5
                    ? _c("div", { staticClass: "add-dir" })
                    : _vm._e()
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("li", [
              _c("label", [_vm._v("City")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                [
                  _c(
                    "validator",
                    {
                      key: "df7x" + i,
                      attrs: {
                        value: director.addressCity,
                        reqs: "required city"
                      }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: director.addressCity,
                            expression: "director.addressCity"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: director.addressCity },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              director,
                              "addressCity",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _vm.app.directors.length < 5
                    ? _c("div", { staticClass: "add-dir" })
                    : _vm._e()
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("li", [
              _c("label", [_vm._v("Country")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                [
                  _c(
                    "validator",
                    {
                      key: "df8x" + i,
                      attrs: {
                        value: director.addressCountry,
                        reqs: "required state"
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "combo-select" },
                        [
                          _c("dyn-select", {
                            attrs: { options: _vm.validCountries },
                            model: {
                              value: director.addressCountry,
                              callback: function($$v) {
                                _vm.$set(director, "addressCountry", $$v)
                              },
                              expression: "director.addressCountry"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _vm.app.directors.length < 5
                    ? _c("div", { staticClass: "add-dir" })
                    : _vm._e()
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("li", [
              _c("label", [_vm._v("Province / State")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                [
                  director.addressCountry == "CA" ||
                  director.addressCountry == "US"
                    ? _c(
                        "validator",
                        {
                          key: "df5x" + i,
                          attrs: {
                            value: director.addressNumber,
                            reqs: "required state"
                          }
                        },
                        [
                          _c("province-select", {
                            attrs: { country: director.addressCountry },
                            model: {
                              value: director.addressNumber,
                              callback: function($$v) {
                                _vm.$set(director, "addressNumber", $$v)
                              },
                              expression: "director.addressNumber"
                            }
                          })
                        ],
                        1
                      )
                    : _c("validator", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: director.addressNumber,
                              expression: "director.addressNumber"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: director.addressNumber },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                director,
                                "addressNumber",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ]),
                  _vm._v(" "),
                  _vm.app.directors.length < 5
                    ? _c("div", { staticClass: "add-dir" })
                    : _vm._e()
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._m(1, true),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                [
                  _c(
                    "validator",
                    {
                      key: "df9x" + i,
                      attrs: { value: director.phone, reqs: "required phone" }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: director.phone,
                            expression: "director.phone"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: director.phone },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(director, "phone", $event.target.value)
                          }
                        }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _vm.app.directors.length < 5
                    ? _c("div", { staticClass: "add-dir" })
                    : _vm._e()
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("li", [
              _c("label", [_vm._v("Email")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                [
                  _c(
                    "validator",
                    {
                      key: "df12x" + i,
                      attrs: { value: director.email, reqs: "required email" }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: director.email,
                            expression: "director.email"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: director.email },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(director, "email", $event.target.value)
                          }
                        }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _vm.app.directors.length < 5
                    ? _c("div", { staticClass: "add-dir" })
                    : _vm._e()
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("li", [
              _c("label", [_vm._v("Passport / ID number")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                [
                  _c(
                    "validator",
                    {
                      key: "df10x" + i,
                      attrs: {
                        value: director.passportNum,
                        reqs: "required postcode"
                      }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: director.passportNum,
                            expression: "director.passportNum"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: director.passportNum },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              director,
                              "passportNum",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _vm.app.directors.length < 5
                    ? _c("div", { staticClass: "add-dir" })
                    : _vm._e()
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("li", [
              _c("label", [_vm._v("S.I.N. / S.S.N.")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                [
                  _c(
                    "validator",
                    {
                      key: "df11x" + i,
                      attrs: { value: director.ssn, reqs: "required num10" }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: director.ssn,
                            expression: "director.ssn"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: director.ssn },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(director, "ssn", $event.target.value)
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("li", [_vm._v(" ")])
          ])
        }),
        _vm._v(" "),
        _vm.app.directors.length < 5
          ? _c("div", { staticClass: "add-dir" }, [
              _c("a", { on: { click: _vm.addDirector } }, [
                _vm._v("Add Additional Director"),
                _c("span", [_vm._v("+")])
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm._m(2)
      ],
      2
    ),
    _vm._v(" "),
    _vm.app.companyIsPublic !== "1" && _vm.app.companyIsNonProfit !== "1"
      ? _c(
          "section",
          [
            _vm._m(3),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "pgf-main-cb" },
              [
                _vm._m(4),
                _vm._v(" "),
                _c(
                  "validator",
                  { attrs: { value: _vm.app.largeUBO, reqs: "required" } },
                  [
                    _c("ul", { staticClass: "toggle-main" }, [
                      _c("li", [
                        _c(
                          "label",
                          { class: { checked: _vm.app.largeUBO === "1" } },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.app.largeUBO,
                                  expression: "app.largeUBO"
                                }
                              ],
                              attrs: {
                                type: "radio",
                                name: "largeUBO",
                                value: "1"
                              },
                              domProps: {
                                checked: _vm._q(_vm.app.largeUBO, "1")
                              },
                              on: {
                                change: function($event) {
                                  return _vm.$set(_vm.app, "largeUBO", "1")
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("i"),
                            _vm._v(" "),
                            _c("strong", [_vm._v("Yes")]),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                "(list all UBOs below: mention UBOs owning largest share % first)"
                              )
                            ])
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c(
                          "label",
                          { class: { checked: _vm.app.largeUBO === "0" } },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.app.largeUBO,
                                  expression: "app.largeUBO"
                                }
                              ],
                              attrs: {
                                type: "radio",
                                name: "largeUBO",
                                value: "0"
                              },
                              domProps: {
                                checked: _vm._q(_vm.app.largeUBO, "0")
                              },
                              on: {
                                change: function($event) {
                                  return _vm.$set(_vm.app, "largeUBO", "0")
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("i"),
                            _vm._v(" "),
                            _c("strong", [_vm._v("No")]),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                "(list the largest 5 UBOs below: mention all additional UBOs in a separate document)"
                              )
                            ])
                          ]
                        )
                      ])
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _vm._l(_vm.app.ubos, function(ubo, i) {
              return _c("ul", { staticClass: "column-form type2 bh" }, [
                _c(
                  "li",
                  {
                    ref: "uboHead" + i,
                    refInFor: true,
                    staticClass: "head",
                    class: { collapsable: _vm.app.ubos.length > 1 },
                    on: {
                      click: function($event) {
                        return _vm.toggleCollapse("uboHead" + i)
                      }
                    }
                  },
                  [
                    _c("label"),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v("\n          UBO " + _vm._s(i + 1) + " "),
                      _vm.app.ubos.length > 1 && i
                        ? _c(
                            "a",
                            {
                              staticClass: "remove",
                              attrs: { href: "", title: "Remove" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  $event.stopPropagation()
                                  return _vm.app.ubos.splice(i, 1)
                                }
                              }
                            },
                            [_c("span", [_vm._v("×")]), _vm._v(" Remove UBO")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      i < _vm.app.directors.length
                        ? _c(
                            "label",
                            {
                              staticClass: "copy",
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                }
                              }
                            },
                            [
                              _c("input", {
                                attrs: { type: "checkbox" },
                                on: {
                                  change: function($event) {
                                    return _vm.copyFromDirector(i, $event)
                                  }
                                }
                              }),
                              _c("span"),
                              _vm._v("Copy Director " + _vm._s(i + 1))
                            ]
                          )
                        : _vm._e()
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("First Name")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "field" },
                    [
                      _c(
                        "validator",
                        {
                          key: "df1x" + i,
                          attrs: { value: ubo.firstName, reqs: "required city" }
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: ubo.firstName,
                                expression: "ubo.firstName"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: { value: ubo.firstName },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(ubo, "firstName", $event.target.value)
                              }
                            }
                          })
                        ]
                      )
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Last name")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "field" },
                    [
                      _c(
                        "validator",
                        {
                          key: "df2x" + i,
                          attrs: { value: ubo.lastName, reqs: "required city" }
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: ubo.lastName,
                                expression: "ubo.lastName"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: { value: ubo.lastName },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(ubo, "lastName", $event.target.value)
                              }
                            }
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _vm.app.ubos.length < 5
                        ? _c("div", { staticClass: "add-dir" })
                        : _vm._e()
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Date of birth")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "field" },
                    [
                      _c(
                        "validator",
                        {
                          key: "df3x" + i,
                          attrs: { value: ubo.dob, reqs: "required" }
                        },
                        [
                          _c("datepicker", {
                            attrs: { type: "text", future: "18+" },
                            model: {
                              value: ubo.dob,
                              callback: function($$v) {
                                _vm.$set(ubo, "dob", $$v)
                              },
                              expression: "ubo.dob"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.app.ubos.length < 5
                        ? _c("div", { staticClass: "add-dir" })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("span", { staticClass: "pfx-info" }, [
                    _vm._v("(YYYY MM DD)")
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Street address")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "field" },
                    [
                      _c(
                        "validator",
                        {
                          key: "df4x" + i,
                          attrs: {
                            value: ubo.address,
                            reqs: "required address"
                          }
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: ubo.address,
                                expression: "ubo.address"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: { value: ubo.address },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(ubo, "address", $event.target.value)
                              }
                            }
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _vm.app.ubos.length < 5
                        ? _c("div", { staticClass: "add-dir" })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("span", { staticClass: "pfx-info" }, [_vm._v("(home)")])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Postal code / ZIP code")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "field" },
                    [
                      _c(
                        "validator",
                        {
                          key: "df6x" + i,
                          attrs: {
                            value: ubo.addressPostcode,
                            reqs: "required postcode"
                          }
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: ubo.addressPostcode,
                                expression: "ubo.addressPostcode"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: { value: ubo.addressPostcode },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  ubo,
                                  "addressPostcode",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _vm.app.ubos.length < 5
                        ? _c("div", { staticClass: "add-dir" })
                        : _vm._e()
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("City")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "field" },
                    [
                      _c(
                        "validator",
                        {
                          key: "df7x" + i,
                          attrs: {
                            value: ubo.addressCity,
                            reqs: "required city"
                          }
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: ubo.addressCity,
                                expression: "ubo.addressCity"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: { value: ubo.addressCity },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  ubo,
                                  "addressCity",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _vm.app.ubos.length < 5
                        ? _c("div", { staticClass: "add-dir" })
                        : _vm._e()
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Country")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "field" },
                    [
                      _c(
                        "validator",
                        {
                          key: "df8x" + i,
                          attrs: {
                            value: ubo.addressCountry,
                            reqs: "required state"
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "combo-select" },
                            [
                              _c("dyn-select", {
                                attrs: { options: _vm.validCountries },
                                model: {
                                  value: ubo.addressCountry,
                                  callback: function($$v) {
                                    _vm.$set(ubo, "addressCountry", $$v)
                                  },
                                  expression: "ubo.addressCountry"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _vm.app.ubos.length < 5
                        ? _c("div", { staticClass: "add-dir" })
                        : _vm._e()
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Province / State")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "field" },
                    [
                      ubo.addressCountry == "CA" || ubo.addressCountry == "US"
                        ? _c(
                            "validator",
                            {
                              key: "df5x" + i,
                              attrs: {
                                value: ubo.addressNumber,
                                reqs: "required state"
                              }
                            },
                            [
                              _c("province-select", {
                                attrs: { country: ubo.addressCountry },
                                model: {
                                  value: ubo.addressNumber,
                                  callback: function($$v) {
                                    _vm.$set(ubo, "addressNumber", $$v)
                                  },
                                  expression: "ubo.addressNumber"
                                }
                              })
                            ],
                            1
                          )
                        : _c("validator", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: ubo.addressNumber,
                                  expression: "ubo.addressNumber"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: ubo.addressNumber },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    ubo,
                                    "addressNumber",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]),
                      _vm._v(" "),
                      _vm.app.ubos.length < 5
                        ? _c("div", { staticClass: "add-dir" })
                        : _vm._e()
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._m(5, true),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "field" },
                    [
                      _c(
                        "validator",
                        {
                          key: "df9x" + i,
                          attrs: { value: ubo.phone, reqs: "required phone" }
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: ubo.phone,
                                expression: "ubo.phone"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: { value: ubo.phone },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(ubo, "phone", $event.target.value)
                              }
                            }
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _vm.app.ubos.length < 5
                        ? _c("div", { staticClass: "add-dir" })
                        : _vm._e()
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Email")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "field" },
                    [
                      _c(
                        "validator",
                        {
                          key: "df12x" + i,
                          attrs: { value: ubo.email, reqs: "required email" }
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: ubo.email,
                                expression: "ubo.email"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: { value: ubo.email },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(ubo, "email", $event.target.value)
                              }
                            }
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _vm.app.directors.length < 5
                        ? _c("div", { staticClass: "add-dir" })
                        : _vm._e()
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Passport / ID number")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "field" },
                    [
                      _c(
                        "validator",
                        {
                          key: "df10x" + i,
                          attrs: {
                            value: ubo.passportNum,
                            reqs: "required postcode"
                          }
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: ubo.passportNum,
                                expression: "ubo.passportNum"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: { value: ubo.passportNum },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  ubo,
                                  "passportNum",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _vm.app.ubos.length < 5
                        ? _c("div", { staticClass: "add-dir" })
                        : _vm._e()
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("S.I.N. / S.S.N.")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "field" },
                    [
                      _c(
                        "validator",
                        {
                          key: "df11x" + i,
                          attrs: { value: ubo.ssn, reqs: "required num10" }
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: ubo.ssn,
                                expression: "ubo.ssn"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: { value: ubo.ssn },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(ubo, "ssn", $event.target.value)
                              }
                            }
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _vm.app.ubos.length < 5
                        ? _c("div", { staticClass: "add-dir" })
                        : _vm._e()
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Percentage of ownership")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "field" },
                    [
                      _c(
                        "validator",
                        {
                          key: "df1x" + i,
                          attrs: {
                            value: ubo.ownership,
                            reqs: "required numberPercent"
                          }
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: ubo.ownership,
                                expression: "ubo.ownership"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: { value: ubo.ownership },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(ubo, "ownership", $event.target.value)
                              }
                            }
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _vm.app.ubos.length < 5
                        ? _c("div", { staticClass: "add-dir" })
                        : _vm._e()
                    ],
                    1
                  )
                ])
              ])
            }),
            _vm._v(" "),
            _vm.app.ubos.length < 5
              ? _c("div", { staticClass: "add-dir" }, [
                  _c("a", { on: { click: _vm.addUBO } }, [
                    _vm._v("Add Additional UBO"),
                    _c("span", [_vm._v("+")])
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm._m(6)
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "buttons" }, [
      _c(
        "a",
        {
          staticClass: "btn btn-large btn-plain btn-back",
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.$emit("prev")
            }
          }
        },
        [_vm._v("Back")]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "btn btn-large btn-warn",
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.$emit("save")
            }
          }
        },
        [_vm._v("Save")]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "btn btn-large btn-success btn-next",
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.nextStep.apply(null, arguments)
            }
          }
        },
        [_vm._v("Next Step")]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pgf-main-cb center" }, [
      _c("h3", { staticClass: "intro" }, [
        _vm._v("Directors "),
        _c("sup", [_vm._v("1")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_vm._v("Telephone number "), _c("sup", [_vm._v("2")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "footnotes" }, [
      _c("li", [
        _c("sup", [_vm._v("1")]),
        _vm._v(
          " All directors should be mentioned on the incorporation documents"
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _c("sup", [_vm._v("2")]),
        _vm._v(
          " Including international country code (eg. +1 for USA, +852 for Hong Kong, etc)"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", [
      _vm._v("Ultimate Beneficial Owners "),
      _c("sup", [_vm._v("3")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "intro" }, [
      _vm._v("Are there any UBOs "),
      _vm._v(" owning 10% or more of the company?")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_vm._v("Telephone number "), _c("sup", [_vm._v("2")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "footnotes" }, [
      _c("li", [
        _c("sup", [_vm._v("3")]),
        _vm._v(
          " UBO (Ultimate Beneficial Owner) is natural person owning (part of) the company. All UBOs should be listed and should be clearly identifiable from the incorporation documents. Mention UBO owning the largest share % first. If there are more than 5 UBOs, please disclose all additional UBOs in a separate document. In case of a publicly (stock) listed company, or a registered not-for-profit organization, UBOs do not have to be disclosed."
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }