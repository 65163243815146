var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form-wrapper",
    {
      class: {
        focused: _vm.focused,
        filled: _vm.focused || _vm.data,
        disabled: _vm.disabled
      },
      attrs: { label: _vm.label, required: _vm.isRequired, errors: _vm.errors }
    },
    [
      _c(
        "div",
        {
          staticClass: "select select-vue",
          class: { error: _vm.hasError, disabled: _vm.disabled }
        },
        [
          _c("span", [_vm._v(_vm._s(_vm.selected))]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.data,
                  expression: "data"
                }
              ],
              attrs: { required: _vm.isRequired, disabled: _vm.disabled },
              on: {
                focus: function($event) {
                  _vm.focused = true
                },
                blur: function($event) {
                  _vm.focused = false
                },
                input: function($event) {
                  return _vm.$emit("input", $event.target.value)
                },
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.data = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                }
              }
            },
            [
              !_vm.noNull
                ? _c("option", { domProps: { value: null } }, [_vm._v(" ")])
                : _vm._e(),
              _vm._v(" "),
              _vm.optionGroup
                ? _vm._l(_vm.options, function(groupOptions, group) {
                    return _c(
                      "optgroup",
                      { key: group, attrs: { label: group } },
                      _vm._l(groupOptions, function(option) {
                        return _c(
                          "option",
                          {
                            key: option.id,
                            attrs: { disabled: option.disabled },
                            domProps: { value: option.id }
                          },
                          [_vm._v(_vm._s(option.text))]
                        )
                      }),
                      0
                    )
                  })
                : _vm._l(_vm.parsedOptions, function(option) {
                    return _c(
                      "option",
                      {
                        key: option.id,
                        attrs: { disabled: option.disabled },
                        domProps: { value: option.id }
                      },
                      [_vm._v(_vm._s(option.text))]
                    )
                  })
            ],
            2
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }