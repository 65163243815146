var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form", { staticClass: "system-messages" }, [
    _c("label", [_vm._v("Enter new system message:")]),
    _vm._v(" "),
    _c("textarea", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.message,
          expression: "message"
        }
      ],
      domProps: { value: _vm.message },
      on: {
        input: function($event) {
          if ($event.target.composing) {
            return
          }
          _vm.message = $event.target.value
        }
      }
    }),
    _vm._v(" "),
    _c("div", { staticClass: "buttons" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-large btn-primary",
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.newMessage.apply(null, arguments)
            }
          }
        },
        [_vm._v("Create new message")]
      ),
      _vm._v(" "),
      _c("label", { staticClass: "checkbox" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.dashboardOnly,
              expression: "dashboardOnly"
            }
          ],
          attrs: { type: "checkbox" },
          domProps: {
            checked: Array.isArray(_vm.dashboardOnly)
              ? _vm._i(_vm.dashboardOnly, null) > -1
              : _vm.dashboardOnly
          },
          on: {
            change: function($event) {
              var $$a = _vm.dashboardOnly,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.dashboardOnly = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.dashboardOnly = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.dashboardOnly = $$c
              }
            }
          }
        }),
        _vm._v("\n      Dashboard only\n    ")
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }