var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.employee
    ? _c("div", { staticClass: "ncontent wide" }, [
        _c("a", { staticClass: "back-button" }, [_vm._v("Dashboard")]),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "merchant-panel" }, [
          _c("div", { staticClass: "data-content" }, [
            _c("ul", { staticClass: "tabs" }, [
              _c(
                "li",
                {
                  class: { active: _vm.activeTab === "general" },
                  on: {
                    click: function($event) {
                      _vm.activeTab = "general"
                    }
                  }
                },
                [_vm._v("General")]
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  class: { active: _vm.activeTab === "personal" },
                  on: {
                    click: function($event) {
                      _vm.activeTab = "personal"
                    }
                  }
                },
                [_vm._v("Personal")]
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  class: { active: _vm.activeTab === "notes" },
                  on: {
                    click: function($event) {
                      _vm.activeTab = "notes"
                    }
                  }
                },
                [_vm._v("Notes")]
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "tabs" }, [_vm._v("Employee Settings")]),
            _vm._v(" "),
            _c("ul", { staticClass: "tabs-smaller" }, [
              _c(
                "li",
                {
                  class: { active: _vm.activeTab === "profile" },
                  on: {
                    click: function($event) {
                      _vm.activeTab = "profile"
                    }
                  }
                },
                [_vm._v("Profile")]
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  class: { active: _vm.activeTab === "access" },
                  on: {
                    click: function($event) {
                      _vm.activeTab = "access"
                    }
                  }
                },
                [_vm._v("Access")]
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  class: { active: _vm.activeTab === "activity" },
                  on: {
                    click: function($event) {
                      _vm.activeTab = "activity"
                    }
                  }
                },
                [_vm._v("Activity")]
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "main" },
              [
                _c("div", { staticClass: "status" }, [
                  _c("label", [
                    _vm._v("Current Status: "),
                    _c("strong", [_vm._v(_vm._s(_vm.employee.status))])
                  ])
                ]),
                _vm._v(" "),
                _c("employee-data-activity", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activeTab === "activity",
                      expression: "activeTab === 'activity'"
                    }
                  ],
                  attrs: { employee: _vm.employee }
                }),
                _vm._v(" "),
                _c(
                  "form",
                  { staticClass: "preq-form preq-view" },
                  [
                    _vm.activeTab === "notes"
                      ? _c("notes", {
                          staticClass: "appl-notes",
                          attrs: {
                            notes: _vm.notes,
                            type: "Employee",
                            id: _vm.id
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("employee-data-general", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.activeTab === "general",
                          expression: "activeTab === 'general'"
                        }
                      ],
                      attrs: {
                        employee: _vm.employee,
                        value: _vm.form,
                        editMode: _vm.editMode
                      }
                    }),
                    _vm._v(" "),
                    _c("employee-data-personal", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.activeTab === "personal",
                          expression: "activeTab === 'personal'"
                        }
                      ],
                      attrs: {
                        employee: _vm.employee,
                        value: _vm.form,
                        editMode: _vm.editMode
                      }
                    }),
                    _vm._v(" "),
                    _c("employee-data-access", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.activeTab === "access",
                          expression: "activeTab === 'access'"
                        }
                      ],
                      attrs: {
                        employee: _vm.employee,
                        value: _vm.form,
                        editMode: _vm.editMode
                      },
                      on: {
                        active: function($event) {
                          _vm.employee.userId.active = $event
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("employee-data-profile", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.activeTab === "profile",
                          expression: "activeTab === 'profile'"
                        }
                      ],
                      attrs: {
                        employee: _vm.employee,
                        value: _vm.form,
                        editMode: _vm.editMode
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.editMode
                  ? [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-small",
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              return _vm.edit()
                            }
                          }
                        },
                        [_vm._v("Save")]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-small",
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              _vm.editMode = false
                            }
                          }
                        },
                        [_vm._v("Cancel")]
                      )
                    ]
                  : _vm._e()
              ],
              2
            )
          ]),
          _vm._v(" "),
          _c("aside", { staticClass: "actions" }, [
            _vm._m(1),
            _vm._v(" "),
            _c("div", { staticClass: "main" }, [
              _c("div", { staticClass: "action-buttons" }, [
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        _vm.editMode = true
                      }
                    }
                  },
                  [_vm._v("Edit")]
                )
              ])
            ])
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("div", [
        _c("ul", { staticClass: "breadcrumbs" }, [
          _c("li", [
            _c("a", { attrs: { href: "" } }, [_vm._v("Employee Overview")])
          ]),
          _vm._v(" "),
          _c("li", [_c("a", { attrs: { href: "" } }, [_vm._v("Employee")])])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "tabs" }, [_c("li", [_vm._v("General")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }