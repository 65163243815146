var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ncontent touch-it" },
    [
      _c("div", { staticClass: "header split" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("aside", [
          _c("h3", [_vm._v("Summary Report")]),
          _vm._v(" "),
          _c("table", { staticClass: "breakdown-table" }, [
            _vm._m(1),
            _vm._v(" "),
            _c("tbody", [
              _c("tr", [
                _c("td", [_vm._v("Fraud Risk Policy Receipt")]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(_vm.list.FRPR.data.length))])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("AML Training Certification")]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(_vm.list.AMLTC.data.length))])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("Internal Unusual Activity Report")]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(_vm.list.IUAR.data.length))])
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "hr" }),
      _vm._v(" "),
      _c("div", { staticClass: "content" }, [
        _c("div", [
          _c("p", { staticClass: "result-count" }, [
            _vm._v("Your search returned "),
            _c("span", [_vm._v(_vm._s(_vm.countResults))]),
            _vm._v(" result(s).")
          ]),
          _vm._v(" "),
          _c(
            "ul",
            { staticClass: "acc-results" },
            _vm._l(_vm.list, function(item, type) {
              return _c(
                "li",
                { key: type, class: { active: _vm.show[type] } },
                [
                  _c(
                    "h4",
                    {
                      on: {
                        click: function($event) {
                          _vm.show[type] = !_vm.show[type]
                        }
                      }
                    },
                    [
                      _c("span", { staticClass: "label" }, [
                        _vm._v(_vm._s(item.name))
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.show[type],
                          expression: "show[type]"
                        }
                      ],
                      staticClass: "acc-list",
                      staticStyle: { display: "block" }
                    },
                    [
                      _c("table", [
                        _vm._m(2, true),
                        _vm._v(" "),
                        _c(
                          "tbody",
                          _vm._l(item.data, function(data) {
                            return _c("tr", { key: data.id }, [
                              _c("td", [
                                _c(
                                  "a",
                                  {
                                    staticClass: "link",
                                    on: {
                                      click: function($event) {
                                        return _vm.openPreview(data)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.formatID(type, data.docIndex))
                                    )
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", [_vm._v(_vm._s(data.status))]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatDate(data.created, "MM/DD/YYYY")
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.showFromForm(data.type)
                                      ? _vm.getData(data, "first_name")
                                      : "First Name"
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.showFromForm(data.type)
                                      ? _vm.getData(data, "last_name")
                                      : "Last Name"
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.showFromForm(data.type)
                                      ? _vm.getData(data, "email")
                                      : data.userId.email
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.showFromForm(data.type)
                                      ? _vm.userGroup(
                                          _vm.getData(data, "department")
                                        )
                                      : _vm.userGroup(data.userId.groups)
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(_vm._s(data.signed ? "Y" : "N"))
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    data.signed
                                      ? _vm.formatDate(
                                          data.signed,
                                          "MM/DD/YYYY"
                                        )
                                      : "N/A"
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                !["EXPIRED", "CXL", "RESOLVED"].includes(
                                  data.status
                                )
                                  ? _c("div", [
                                      data.status !== "COMPLETE"
                                        ? _c(
                                            "a",
                                            {
                                              staticClass: "btn-like ext-btn",
                                              staticStyle: {
                                                padding: "2px",
                                                display: "inline-block"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.cancel(data)
                                                }
                                              }
                                            },
                                            [_vm._v("CXL")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      ["COMPLETE", "PENDING"].includes(
                                        data.status
                                      )
                                        ? _c(
                                            "a",
                                            {
                                              staticClass: "btn-like ext-btn",
                                              staticStyle: {
                                                padding: "2px",
                                                display: "inline-block"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.openUpload(data)
                                                }
                                              }
                                            },
                                            [_vm._v("UPL")]
                                          )
                                        : _vm._e()
                                    ])
                                  : _vm._e()
                              ])
                            ])
                          }),
                          0
                        )
                      ])
                    ]
                  )
                ]
              )
            }),
            0
          )
        ])
      ]),
      _vm._v(" "),
      _vm.openedDoc
        ? _c("recmatrix-form-doc-preview", {
            ref: "preview",
            attrs: {
              document: _vm.openedDoc,
              documentId: _vm.openedDoc
                ? _vm.formatID(_vm.openedDoc.type, _vm.openedDoc.docIndex)
                : null
            },
            on: {
              cxl: function($event) {
                return _vm.prepCancel($event)
              },
              res: function($event) {
                return _vm.resolve($event)
              },
              upl: function($event) {
                return _vm.openUpload()
              },
              close: function($event) {
                _vm.openedDoc = null
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("input", {
        directives: [
          { name: "show", rawName: "v-show", value: false, expression: "false" }
        ],
        ref: "upload",
        attrs: { type: "file" },
        on: { change: _vm.uploadFile }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("ul", { staticClass: "breadcrumbs" }, [
        _c("li", [
          _c("a", { attrs: { href: "https://secure.chammp.net/dashboard" } }, [
            _vm._v("Forms Report")
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "toolbar" }),
      _vm._v(" "),
      _c("form", { staticClass: "search-form" }, [
        _c("h3", [_vm._v("Search Options")]),
        _vm._v(" "),
        _c("div", { staticClass: "f2split" }, [
          _c("div", [
            _c("div", { staticClass: "field" }, [
              _c("label", [_vm._v("Date Range")]),
              _vm._v(" "),
              _c("div", { staticClass: "field-group" }, [
                _c("input", {
                  staticClass: "hasDatepicker",
                  attrs: { type: "text", id: "dp1569254393711" }
                }),
                _vm._v(" "),
                _c("input", {
                  staticClass: "hasDatepicker",
                  attrs: { type: "text", id: "dp1569254393712" }
                })
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "field" }, [
              _c("label", [_vm._v("Trans ID")]),
              _vm._v(" "),
              _c("input", { attrs: { type: "text" } })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "field" }, [
              _c("label", [_vm._v("TID")]),
              _vm._v(" "),
              _c("select", [
                _c("option", { attrs: { value: "" } }, [_vm._v("(all)")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "2" } }, [_vm._v("Allium")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "3" } }, [_vm._v("Acme Inc.")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "4" } }, [
                  _vm._v("Internal Agent")
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "5" } }, [
                  _vm._v("David Collins Jr")
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "7" } }, [_vm._v("GBWEB")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "14" } }),
                _vm._v(" "),
                _c("option", { attrs: { value: "15" } }),
                _vm._v(" "),
                _c("option", { attrs: { value: "20" } })
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "field" }, [
              _c("label", [_vm._v("Trans Type")]),
              _vm._v(" "),
              _c("select", [
                _c("option", { attrs: { value: "" } }, [_vm._v("(all)")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "A00" } }, [
                  _vm._v("A00 - To be Assessed")
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "A01" } }, [
                  _vm._v("A01 - Pre-Qualification Complete")
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "A02" } }, [
                  _vm._v("A02 - New Application")
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "A03" } }, [
                  _vm._v("A03 - In Process")
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "A04" } }, [
                  _vm._v("A04 - Approved")
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "A05" } }, [
                  _vm._v("A05 - Denied")
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "A06" } }, [
                  _vm._v("A06 - Duplicate")
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "field" }, [
              _c("label", [_vm._v("Entry Type")]),
              _vm._v(" "),
              _c("select", [
                _c("option", { attrs: { value: "" } }, [_vm._v("(all)")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "A00" } }, [
                  _vm._v("A00 - To be Assessed")
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "A01" } }, [
                  _vm._v("A01 - Pre-Qualification Complete")
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "A02" } }, [
                  _vm._v("A02 - New Application")
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "A03" } }, [
                  _vm._v("A03 - In Process")
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "A04" } }, [
                  _vm._v("A04 - Approved")
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "A05" } }, [
                  _vm._v("A05 - Denied")
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "A06" } }, [
                  _vm._v("A06 - Duplicate")
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", [
            _c("div", { staticClass: "field" }, [
              _c("label", [_vm._v("Scheme")]),
              _vm._v(" "),
              _c("select", [
                _c("option", { attrs: { value: "" } }, [_vm._v("(all)")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "A00" } }, [
                  _vm._v("A00 - To be Assessed")
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "A01" } }, [
                  _vm._v("A01 - Pre-Qualification Complete")
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "A02" } }, [
                  _vm._v("A02 - New Application")
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "A03" } }, [
                  _vm._v("A03 - In Process")
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "A04" } }, [
                  _vm._v("A04 - Approved")
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "A05" } }, [
                  _vm._v("A05 - Denied")
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "A06" } }, [
                  _vm._v("A06 - Duplicate")
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "field" }, [
              _c("label", [_vm._v("Card")]),
              _vm._v(" "),
              _c("input", { attrs: { type: "text" } })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "field" }, [
              _c("label", [_vm._v("Ammount")]),
              _vm._v(" "),
              _c("input", { attrs: { type: "text" } })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "field" }, [
              _c("label", [_vm._v("Batch")]),
              _vm._v(" "),
              _c("input", { attrs: { type: "text" } })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "field" }, [
              _c("label", [_vm._v("Status")]),
              _vm._v(" "),
              _c("select", [
                _c("option", { attrs: { value: "" } }, [_vm._v("(all)")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "Pending" } }, [
                  _vm._v("Pending")
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "Complete" } }, [
                  _vm._v("Complete")
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "Expired" } }, [
                  _vm._v("Expired")
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "Cancel" } }, [
                  _vm._v("Cancel")
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "Resolved" } }, [
                  _vm._v("Resolved")
                ])
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "buttons" }, [
          _c("button", { staticClass: "btn btn-large btn-primary" }, [
            _vm._v("Search")
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Status")]),
        _vm._v(" "),
        _c("th", [_vm._v("Count")])
      ]),
      _vm._v(" "),
      _c("tr", [_c("th", { staticClass: "spacer", attrs: { colspan: "2" } })])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Form ID")]),
        _vm._v(" "),
        _c("th", [_vm._v("Status")]),
        _vm._v(" "),
        _c("th", [_vm._v("Submit Date")]),
        _vm._v(" "),
        _c("th", [_vm._v("First Name")]),
        _vm._v(" "),
        _c("th", [_vm._v("Last Name")]),
        _vm._v(" "),
        _c("th", [_vm._v("Email")]),
        _vm._v(" "),
        _c("th", [_vm._v("Dept")]),
        _vm._v(" "),
        _c("th", [_vm._v("Signed")]),
        _vm._v(" "),
        _c("th", [_vm._v("Signed Date")]),
        _vm._v(" "),
        _c("th", [_vm._v("Options")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }