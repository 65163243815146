var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "acc-list", staticStyle: { display: "block" } },
    [
      _c("table", [
        _vm._m(0),
        _vm._v(" "),
        _vm.data && _vm.data.length
          ? _c(
              "tbody",
              [
                _vm._l(_vm.data, function(item, index) {
                  return _c("tr", { key: index }, [
                    _c("td", [
                      _vm._v(_vm._s(_vm.formatDate(item.created, "MM/DD/YYYY")))
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(_vm._s(item.sourceId ? item.sourceId.name : "N/A"))
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        _vm._s(item.sourceId ? item.sourceId.sourceId : "N/A")
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      item.status === "Successful"
                        ? _c(
                            "a",
                            {
                              staticClass: "link",
                              on: {
                                click: function($event) {
                                  return _vm.openPreview(item)
                                }
                              }
                            },
                            [_vm._v(_vm._s(item.leadFileId || item.id))]
                          )
                        : _c("span", [
                            _vm._v(_vm._s(item.leadFileId || item.id))
                          ])
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "100" +
                          _vm._s(item.leadIds) +
                          " - 100" +
                          _vm._s(
                            parseInt(item.leadIds) + parseInt(item.records) - 1
                          )
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(item.filename))]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(item.records))]),
                    _vm._v(" "),
                    _c("td", [
                      _c(
                        "span",
                        {
                          class:
                            item.status === "Successful"
                              ? "text-success"
                              : "text-danger"
                        },
                        [_vm._v(_vm._s(item.status))]
                      )
                    ])
                  ])
                }),
                _vm._v(" "),
                !_vm.data.length
                  ? _c("tr", [
                      _c(
                        "td",
                        { staticClass: "text-center", attrs: { colspan: "9" } },
                        [_vm._v("No data")]
                      )
                    ])
                  : _vm._e()
              ],
              2
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm.preview
        ? _c("amadel-data-transfer-preview", {
            attrs: { data: _vm.preview },
            on: {
              close: function($event) {
                _vm.preview = null
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Date/Time")]),
        _vm._v(" "),
        _c("th", [_vm._v("Souce Name")]),
        _vm._v(" "),
        _c("th", [_vm._v("Source ID")]),
        _vm._v(" "),
        _c("th", [_vm._v("Lead File ID")]),
        _vm._v(" "),
        _c("th", [_vm._v("Lead ID's")]),
        _vm._v(" "),
        _c("th", [_vm._v("File Name")]),
        _vm._v(" "),
        _c("th", [_vm._v("# of Records")]),
        _vm._v(" "),
        _c("th", [_vm._v("File Status")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }