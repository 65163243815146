var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.user
    ? _c("div", { staticClass: "ncontent wide" }, [
        _c("a", { staticClass: "back-button" }, [_vm._v("Dashboard")]),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "merchant-panel" }, [
          _c("div", { staticClass: "data-content" }, [
            _c("ul", { staticClass: "tabs" }, [
              _c(
                "li",
                {
                  class: { active: _vm.activeTab === "general" },
                  on: {
                    click: function($event) {
                      _vm.activeTab = "general"
                    }
                  }
                },
                [_vm._v("General")]
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  class: { active: _vm.activeTab === "notes" },
                  on: {
                    click: function($event) {
                      _vm.activeTab = "notes"
                    }
                  }
                },
                [_vm._v("Notes")]
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "tabs" }, [_vm._v("Partner Settings")]),
            _vm._v(" "),
            _c("ul", { staticClass: "tabs-smaller" }, [
              _c(
                "li",
                {
                  class: { active: _vm.activeTab === "profile" },
                  on: {
                    click: function($event) {
                      _vm.activeTab = "profile"
                    }
                  }
                },
                [_vm._v("Profile")]
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  class: { active: _vm.activeTab === "access" },
                  on: {
                    click: function($event) {
                      _vm.activeTab = "access"
                    }
                  }
                },
                [_vm._v("Access")]
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  class: { active: _vm.activeTab === "activity" },
                  on: {
                    click: function($event) {
                      _vm.activeTab = "activity"
                    }
                  }
                },
                [_vm._v("Activity")]
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "main" },
              [
                _c(
                  "form",
                  { staticClass: "preq-form preq-view" },
                  [
                    _vm.activeTab === "notes"
                      ? _c("notes", {
                          staticClass: "appl-notes",
                          attrs: { notes: _vm.notes, type: "User", id: _vm.id }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.activeTab === "general",
                            expression: "activeTab === 'general'"
                          }
                        ]
                      },
                      [
                        _c("ul", { staticClass: "appview-list" }, [
                          _c("li", [
                            _c("label", [_vm._v("First Name")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "validator" },
                              [
                                _vm.editMode
                                  ? _c(
                                      "validator",
                                      {
                                        attrs: {
                                          value: _vm.form.first_name,
                                          reqs: "required"
                                        }
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.form.first_name,
                                              expression: "form.first_name"
                                            }
                                          ],
                                          attrs: { type: "text" },
                                          domProps: {
                                            value: _vm.form.first_name
                                          },
                                          on: {
                                            keypress: function($event) {
                                              return _vm.allowLetterNumber(
                                                $event,
                                                { numbers: false }
                                              )
                                            },
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.form,
                                                "first_name",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                      ]
                                    )
                                  : _c("span", [
                                      _vm._v(_vm._s(_vm.form.first_name))
                                    ])
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v("Last Name")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "validator" },
                              [
                                _vm.editMode
                                  ? _c(
                                      "validator",
                                      {
                                        attrs: {
                                          value: _vm.form.last_name,
                                          reqs: "required"
                                        }
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.form.last_name,
                                              expression: "form.last_name"
                                            }
                                          ],
                                          attrs: { type: "text" },
                                          domProps: {
                                            value: _vm.form.last_name
                                          },
                                          on: {
                                            keypress: function($event) {
                                              return _vm.allowLetterNumber(
                                                $event,
                                                { numbers: false }
                                              )
                                            },
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.form,
                                                "last_name",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                      ]
                                    )
                                  : _c("span", [
                                      _vm._v(_vm._s(_vm.form.last_name))
                                    ])
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v("Email")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "validator" },
                              [
                                _vm.editMode
                                  ? _c(
                                      "validator",
                                      {
                                        attrs: {
                                          value: _vm.form.email,
                                          reqs: "required email"
                                        }
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.form.email,
                                              expression: "form.email"
                                            }
                                          ],
                                          attrs: { type: "text" },
                                          domProps: { value: _vm.form.email },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.form,
                                                "email",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                      ]
                                    )
                                  : _c("span", [_vm._v(_vm._s(_vm.form.email))])
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v("Phone")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "validator" },
                              [
                                _vm.editMode
                                  ? _c(
                                      "validator",
                                      {
                                        attrs: {
                                          value: _vm.form.phone,
                                          reqs: "required"
                                        }
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.form.phone,
                                              expression: "form.phone"
                                            }
                                          ],
                                          staticClass: "w100",
                                          attrs: { type: "text" },
                                          domProps: { value: _vm.form.phone },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.form,
                                                "phone",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                      ]
                                    )
                                  : _c("span", [_vm._v(_vm._s(_vm.form.phone))])
                              ],
                              1
                            )
                          ])
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.activeTab === "profile",
                          expression: "activeTab === 'profile'"
                        }
                      ]
                    }),
                    _vm._v(" "),
                    _c("employee-data-access", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.activeTab === "access",
                          expression: "activeTab === 'access'"
                        }
                      ],
                      attrs: {
                        employee: {
                          userId: _vm.user,
                          username: _vm.user.username
                        },
                        value: _vm.form,
                        editMode: _vm.editMode
                      },
                      on: {
                        active: function($event) {
                          _vm.user.active = $event
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.activeTab === "activity",
                            expression: "activeTab === 'activity'"
                          }
                        ],
                        staticClass: "up-acc"
                      },
                      [
                        _c("div", { staticClass: "open" }, [
                          _c("div", { staticClass: "title" }, [
                            _vm._v("Login activity\n              ")
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "acc-list" }, [
                            _c("table", {}, [
                              _vm._m(1),
                              _vm._v(" "),
                              _c(
                                "tbody",
                                _vm._l(_vm.logins, function(item, index) {
                                  return _c("tr", [
                                    _c("td", { staticClass: "main-col" }, [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(
                                            _vm.user && _vm.user.username
                                          ) +
                                          "\n                      "
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("td", {}, [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(item.recorded) +
                                          "\n                      "
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("td", {}, [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(item.ipAddress) +
                                          "\n                      "
                                      )
                                    ])
                                  ])
                                }),
                                0
                              )
                            ])
                          ])
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.editMode
                  ? [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-small",
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              return _vm.edit()
                            }
                          }
                        },
                        [_vm._v("Save")]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-small",
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              return _vm.cancel()
                            }
                          }
                        },
                        [_vm._v("Cancel")]
                      )
                    ]
                  : _vm._e()
              ],
              2
            )
          ]),
          _vm._v(" "),
          _c("aside", { staticClass: "actions" }, [
            _vm._m(2),
            _vm._v(" "),
            _c("div", { staticClass: "main" }, [
              _c("div", { staticClass: "action-buttons" }, [
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        _vm.editMode = true
                      }
                    }
                  },
                  [_vm._v("Edit")]
                )
              ])
            ])
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("div", [
        _c("ul", { staticClass: "breadcrumbs" }, [
          _c("li", [
            _c("a", { attrs: { href: "" } }, [_vm._v("Partner Overview")])
          ]),
          _vm._v(" "),
          _c("li", [_c("a", { attrs: { href: "" } }, [_vm._v("Partner")])])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticStyle: { width: "33%" } }, [_vm._v("Username")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "33%" } }, [_vm._v("Time")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "34%" } }, [_vm._v("IP Address")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "tabs" }, [_c("li", [_vm._v("General")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }