var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "content-table-container" }, [
      _c("table", { staticClass: "content-table" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "tbody",
          [
            _vm._l(_vm.items, function(item, index) {
              return _c("tr", [
                _c(
                  "td",
                  { staticClass: "text-left", staticStyle: { width: "45%" } },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: item.name,
                          expression: "item.name"
                        }
                      ],
                      staticStyle: { "text-align": "left" },
                      attrs: { type: "text" },
                      domProps: { value: item.name },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(item, "name", $event.target.value)
                        }
                      }
                    })
                  ]
                ),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c("multiselect", {
                      attrs: { options: ["Partners", "Merchants"] },
                      model: {
                        value: item.options,
                        callback: function($$v) {
                          _vm.$set(item, "options", $$v)
                        },
                        expression: "item.options"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "td",
                  { staticStyle: { width: "20%" } },
                  [
                    _c("file-obj", {
                      attrs: { basepath: _vm.basepath },
                      model: {
                        value: item.filename,
                        callback: function($$v) {
                          _vm.$set(item, "filename", $$v)
                        },
                        expression: "item.filename"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "td",
                  { staticStyle: { width: "20%" } },
                  [
                    _c("dyn-select", {
                      attrs: { options: _vm.filetypes },
                      model: {
                        value: item.type,
                        callback: function($$v) {
                          _vm.$set(item, "type", $$v)
                        },
                        expression: "item.type"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("td", [
                  _c(
                    "a",
                    {
                      staticClass: "remove",
                      attrs: { href: "" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.deleteItem(index)
                        }
                      }
                    },
                    [_vm._v("×")]
                  )
                ])
              ])
            }),
            _vm._v(" "),
            _c("tr", { staticClass: "new-row" }, [
              _c("td", { attrs: { colspan: "5" } }, [
                _c(
                  "a",
                  {
                    staticClass: "add",
                    attrs: { href: "" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.newItem.apply(null, arguments)
                      }
                    }
                  },
                  [_vm._v("+")]
                )
              ])
            ])
          ],
          2
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "buttons" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-large btn-primary",
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.updateResources.apply(null, arguments)
            }
          }
        },
        [_vm._v("Submit")]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticStyle: { width: "45%" } }, [_vm._v("Description")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "15%" } }, [_vm._v("Options")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "20%" } }, [_vm._v("File")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "10%" } }, [_vm._v("Type")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "10%" } }, [_vm._v(" ")])
      ]),
      _vm._v(" "),
      _c("tr", [_c("th", { staticClass: "spacer", attrs: { colspan: "3" } })])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }