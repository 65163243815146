var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("p", [_vm._v("Approval")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("view-agent-finalcheck", {
        ref: "finalCheck",
        attrs: {
          uwList: _vm.approvalList,
          acParent: _vm.$parent.$parent.$parent,
          skipAuto: true
        },
        on: {
          final: function($event) {
            return _vm.$emit("next")
          }
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "actions" }, [
        _c(
          "button",
          {
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.goBack.apply(null, arguments)
              }
            }
          },
          [_vm._v("Back")]
        ),
        _vm._v(" "),
        _vm.item.agentDb.status == "A03"
          ? _c(
              "button",
              {
                attrs: {
                  disabled:
                    !_vm.$refs.finalCheck || !_vm.$refs.finalCheck.passed
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.doNext.apply(null, arguments)
                  }
                }
              },
              [_vm._v("Final Approval")]
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }