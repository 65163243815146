var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "content-table-container" }, [
      _c("table", { staticClass: "content-table" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "tbody",
          [
            _vm._l(_vm.items, function(item, index) {
              return _c("tr", [
                _c("td", { staticClass: "text-center main-col" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: item.status,
                        expression: "item.status"
                      }
                    ],
                    attrs: { type: "text" },
                    domProps: { value: item.status },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(item, "status", $event.target.value)
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "text-center" },
                  [
                    _c("combo-select", {
                      attrs: { options: _vm.categories },
                      model: {
                        value: item.category,
                        callback: function($$v) {
                          _vm.$set(item, "category", $$v)
                        },
                        expression: "item.category"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("td", { staticClass: "text-left" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: item.description,
                        expression: "item.description"
                      }
                    ],
                    attrs: { type: "text" },
                    domProps: { value: item.description },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(item, "description", $event.target.value)
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "text-left" },
                  [
                    _c("dyn-select", {
                      attrs: { options: _vm.classifications },
                      model: {
                        value: item.classification,
                        callback: function($$v) {
                          _vm.$set(item, "classification", $$v)
                        },
                        expression: "item.classification"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "text-left" },
                  [
                    _c("dyn-select", {
                      attrs: { options: _vm.designations },
                      model: {
                        value: item.designation,
                        callback: function($$v) {
                          _vm.$set(item, "designation", $$v)
                        },
                        expression: "item.designation"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("td", { staticClass: "text-left" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: item.note,
                        expression: "item.note"
                      }
                    ],
                    attrs: { type: "text" },
                    domProps: { value: item.note },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(item, "note", $event.target.value)
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("td", [
                  _c(
                    "a",
                    {
                      staticClass: "remove",
                      attrs: { href: "" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.deleteItem(index)
                        }
                      }
                    },
                    [_vm._v("×")]
                  )
                ])
              ])
            }),
            _vm._v(" "),
            _c("tr", { staticClass: "new-row" }, [
              _c("td", { attrs: { colspan: "7" } }, [
                _c(
                  "a",
                  {
                    staticClass: "add",
                    attrs: { href: "" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.newItem.apply(null, arguments)
                      }
                    }
                  },
                  [_vm._v("+")]
                )
              ])
            ])
          ],
          2
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "buttons" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-large btn-primary",
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.updateStatusCodes.apply(null, arguments)
            }
          }
        },
        [_vm._v("Submit")]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticStyle: { width: "9%" } }, [_vm._v("MCC code")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "17%" } }, [_vm._v("Category")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "30%" } }, [_vm._v("Description")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "15%" } }, [_vm._v("Classification")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "15%" } }, [_vm._v("Designation")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "30%" } }, [_vm._v("Note")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "1%" } }, [_vm._v(" ")])
      ]),
      _vm._v(" "),
      _c("tr", [_c("th", { staticClass: "spacer", attrs: { colspan: "4" } })])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }