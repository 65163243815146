var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "element-toggle slin" }, [
    _c(
      "label",
      { class: { checked: _vm.value, disabled: !!_vm.disabled } },
      [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.actualValue,
              expression: "actualValue"
            }
          ],
          attrs: { disabled: _vm.disabled, type: "checkbox" },
          domProps: {
            checked: Array.isArray(_vm.actualValue)
              ? _vm._i(_vm.actualValue, null) > -1
              : _vm.actualValue
          },
          on: {
            change: [
              function($event) {
                var $$a = _vm.actualValue,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.actualValue = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.actualValue = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.actualValue = $$c
                }
              },
              _vm.update
            ]
          }
        }),
        _vm._v(" "),
        _c("span"),
        _vm._v(" "),
        _vm._t("default")
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }