var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "ncontent" }, [
      _c("form", { staticClass: "search-form" }, [
        _c("div", { staticClass: "header split" }, [
          _c("div", [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "toolbar" }),
            _vm._v(" "),
            _c("h3", [_vm._v("Search Options")]),
            _vm._v(" "),
            _c("div", { staticClass: "f2split" }, [
              _c("div", [
                _c("div", { staticClass: "field" }, [
                  _c("label", [_vm._v("Date")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "field-group" },
                    [
                      _c("datepicker", {
                        attrs: { type: "text" },
                        model: {
                          value: _vm.search.dateStart,
                          callback: function($$v) {
                            _vm.$set(_vm.search, "dateStart", $$v)
                          },
                          expression: "search.dateStart"
                        }
                      }),
                      _vm._v(" "),
                      _c("datepicker", {
                        attrs: { type: "text" },
                        model: {
                          value: _vm.search.dateEnd,
                          callback: function($$v) {
                            _vm.$set(_vm.search, "dateEnd", $$v)
                          },
                          expression: "search.dateEnd"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "field" }, [
                  _c("label", [_vm._v("First Name")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.search.first_name,
                        expression: "search.first_name"
                      }
                    ],
                    attrs: { type: "text" },
                    domProps: { value: _vm.search.first_name },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.search, "first_name", $event.target.value)
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "field" }, [
                  _c("label", [_vm._v("Last Name")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.search.last_name,
                        expression: "search.last_name"
                      }
                    ],
                    attrs: { type: "text" },
                    domProps: { value: _vm.search.last_name },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.search, "last_name", $event.target.value)
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "field" }, [
                  _c("label", [_vm._v("Username")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.search.username,
                        expression: "search.username"
                      }
                    ],
                    attrs: { type: "text" },
                    domProps: { value: _vm.search.username },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.search, "username", $event.target.value)
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "field" }, [
                  _c("label", [_vm._v("Email")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.search.email,
                        expression: "search.email"
                      }
                    ],
                    attrs: { type: "email" },
                    domProps: { value: _vm.search.email },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.search, "email", $event.target.value)
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "field" }, [
                  _c("label", [_vm._v("Title")]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.search.title,
                          expression: "search.title"
                        }
                      ],
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.search,
                            "title",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        }
                      }
                    },
                    _vm._l(_vm.titleGroups, function(group) {
                      return _c(
                        "option",
                        { key: group, domProps: { value: group } },
                        [_vm._v(_vm._s(group))]
                      )
                    }),
                    0
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", [
                _c("div", { staticClass: "field" }, [
                  _c("label", [_vm._v("Status")]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.search.status,
                          expression: "search.status"
                        }
                      ],
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.search,
                            "status",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        }
                      }
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [_vm._v("(all)")]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "PENDING" } }, [
                        _vm._v("Pending")
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "ACTIVE" } }, [
                        _vm._v("Active")
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "INACTIVE" } }, [
                        _vm._v("Inactive")
                      ])
                    ]
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-small btn-primary",
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.updateResults.apply(null, arguments)
                  }
                }
              },
              [_vm._v("Search")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-small btn-primary",
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.clearResults.apply(null, arguments)
                  }
                }
              },
              [_vm._v("Clear")]
            )
          ]),
          _vm._v(" "),
          _c("aside", [
            _c("h3", [_vm._v("Summary Report")]),
            _vm._v(" "),
            _c("table", { staticClass: "breakdown-table" }, [
              _vm._m(1),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.list, function(item) {
                  return _c("tr", [
                    _c("td", [_vm._v(_vm._s(item.name))]),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        item.data
                          ? [_vm._v(_vm._s(item.data.length))]
                          : _vm._e()
                      ],
                      2
                    )
                  ])
                }),
                0
              )
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "hr" }),
      _vm._v(" "),
      _c("div", { staticClass: "content" }, [
        _c("div", [
          _c("p", { staticClass: "result-count" }, [
            _vm._v("Your search returned "),
            _c("span", [_vm._v(_vm._s(_vm.data.length))]),
            _vm._v(" result(s).")
          ]),
          _vm._v(" "),
          _c("ul", { staticClass: "acc-results" }, [
            _c("li", [
              _c(
                "h4",
                {
                  on: {
                    click: function($event) {
                      _vm.show = !_vm.show
                    }
                  }
                },
                [_c("span", { staticClass: "label" }, [_vm._v("Employees")])]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.show,
                      expression: "show"
                    }
                  ],
                  staticClass: "acc-list",
                  staticStyle: { display: "block" }
                },
                [
                  _c("table", [
                    _vm._m(2),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      _vm._l(_vm.data, function(emp) {
                        return _c("tr", { key: _vm.data.id }, [
                          _c("td", [
                            _c(
                              "a",
                              {
                                staticClass: "link",
                                attrs: { href: "/employees/view/" + emp.id }
                              },
                              [_vm._v(_vm._s(emp.employeeId))]
                            )
                          ]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(emp.companyId.name))]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              _vm._s(emp.first_name) +
                                " " +
                                _vm._s(emp.last_name)
                            )
                          ]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(emp.username))]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(emp.email))]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(emp.title))]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(emp.status))]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                emp.lastLogin
                                  ? _vm.formatDate(emp.lastLogin, "MM/DD/YYYY")
                                  : "N/A"
                              )
                            )
                          ]),
                          _vm._v(" "),
                          emp.status == "PENDING"
                            ? _c("td", [
                                _c(
                                  "a",
                                  {
                                    attrs: { href: "" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.removeEmp(emp)
                                      }
                                    }
                                  },
                                  [_vm._v("Remove")]
                                )
                              ])
                            : _c("td", [_vm._v("-")])
                        ])
                      }),
                      0
                    )
                  ])
                ]
              )
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "breadcrumbs" }, [
      _c("li", [_c("a", [_vm._v("Employee Overview")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Status")]),
        _vm._v(" "),
        _c("th", [_vm._v("Count")])
      ]),
      _vm._v(" "),
      _c("tr", [_c("th", { staticClass: "spacer", attrs: { colspan: "2" } })])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Empl ID")]),
        _vm._v(" "),
        _c("th", [_vm._v("Company")]),
        _vm._v(" "),
        _c("th", [_vm._v("Name")]),
        _vm._v(" "),
        _c("th", [_vm._v("Username")]),
        _vm._v(" "),
        _c("th", [_vm._v("Email")]),
        _vm._v(" "),
        _c("th", [_vm._v("Title")]),
        _vm._v(" "),
        _c("th", [_vm._v("Status")]),
        _vm._v(" "),
        _c("th", [_vm._v("Last Login")]),
        _vm._v(" "),
        _c("th", [_vm._v("Options")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }