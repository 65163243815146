var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "popup usermgmt", class: { shown: _vm.visible } },
    [
      _c("div", { staticClass: "pop-med" }, [
        _c("div", { staticClass: "pop-inner" }, [
          _c("a", {
            staticClass: "close",
            attrs: { href: "" },
            on: {
              click: function($event) {
                $event.preventDefault()
                _vm.visible = false
              }
            }
          }),
          _vm._v(" "),
          _c("h2", [_vm._v("Set new password for: " + _vm._s(_vm.username))]),
          _vm._v(" "),
          _c("form", { staticClass: "search-form" }, [
            _c(
              "div",
              [
                _c(
                  "validator",
                  {
                    staticClass: "field",
                    attrs: { value: _vm.agentId, reqs: "" }
                  },
                  [
                    _c("label", [_vm._v("Account #")]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.agentId,
                          expression: "agentId"
                        }
                      ],
                      attrs: { tabindex: "2", type: "text", readonly: "" },
                      domProps: { value: _vm.agentId },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.agentId = $event.target.value
                        }
                      }
                    })
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              [
                _c(
                  "validator",
                  {
                    staticClass: "field",
                    attrs: { value: _vm.username, reqs: "" }
                  },
                  [
                    _c("label", [_vm._v("Username")]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.username,
                          expression: "username"
                        }
                      ],
                      attrs: { tabindex: "2", type: "text", readonly: "" },
                      domProps: { value: _vm.username },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.username = $event.target.value
                        }
                      }
                    })
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              [
                _c(
                  "validator",
                  {
                    staticClass: "field",
                    attrs: { value: _vm.password, reqs: "password" }
                  },
                  [
                    _c("label", [_vm._v("New Password")]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.password,
                          expression: "password"
                        }
                      ],
                      attrs: { tabindex: "2", type: "password" },
                      domProps: { value: _vm.password },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.password = $event.target.value
                        }
                      }
                    })
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              [
                _c(
                  "validator",
                  {
                    staticClass: "field",
                    attrs: { value: _vm.password2, reqs: "password" }
                  },
                  [
                    _c("label", [_vm._v("Confirm Password")]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.password2,
                          expression: "password2"
                        }
                      ],
                      attrs: { tabindex: "2", type: "password" },
                      domProps: { value: _vm.password2 },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.password2 = $event.target.value
                        }
                      }
                    })
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "buttons" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-large btn-primary",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.send.apply(null, arguments)
                    }
                  }
                },
                [_vm._v("Update")]
              )
            ])
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }