var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.uwShown
      ? _c("div", { staticClass: "action-sub-buttons" }, [
          _c("a", { staticClass: "btn", on: { click: _vm.newPreCheck } }, [
            _vm._v("Run new")
          ]),
          _vm._v(" "),
          _vm.uwSelected
            ? _c(
                "a",
                {
                  staticClass: "btn",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.doShowItems(_vm.uwSelected)
                    }
                  }
                },
                [_vm._v("View")]
              )
            : _vm._e()
        ])
      : _c("div", { staticClass: "action-sub-buttons" }, [
          _c(
            "a",
            {
              staticClass: "btn",
              on: {
                click: function($event) {
                  _vm.uwShown = null
                  _vm.hideShown()
                }
              }
            },
            [_vm._v("Back")]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "btn",
              attrs: { href: _vm.printUrl, target: "_blank" }
            },
            [_vm._v("Print")]
          )
        ]),
    _vm._v(" "),
    !_vm.uwShown
      ? _c("table", { staticClass: "file-list content-table" }, [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "tbody",
            _vm._l(_vm.uwList, function(uwItem, uwI) {
              return _c("tr", [
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.uwSelected,
                        expression: "uwSelected"
                      }
                    ],
                    attrs: { type: "radio" },
                    domProps: {
                      value: uwItem.id,
                      checked: _vm._q(_vm.uwSelected, uwItem.id)
                    },
                    on: {
                      change: function($event) {
                        _vm.uwSelected = uwItem.id
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(_vm.uwList.length - uwI))]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(uwItem.companyName))]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    _vm._s(
                      _vm.formatDate(uwItem.createdDate, "YYYY-MM-DD HH:mm")
                    )
                  )
                ])
              ])
            }),
            0
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.uwShown
      ? _c("div", { ref: "detailsBox", staticClass: "pre-check-details" }, [
          _c("div", { staticClass: "t2precheck" }, [
            _c("div", { staticClass: "t2sect" }, [
              _c("div", { staticClass: "t2box" }, [
                _c("h3", [_vm._v("General Information")]),
                _vm._v(" "),
                _c("ul", [
                  _c("li", [
                    _c("label", [_vm._v("Solution:")]),
                    _vm._v(" "),
                    _vm.uwShownItem.solution
                      ? _c("span", [
                          _vm._v(_vm._s(_vm.solution(_vm.uwShownItem.solution)))
                        ])
                      : _c("span", { staticClass: "empty" }, [_vm._v("NO")])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("label", [_vm._v("Status:")]),
                    _vm._v(" "),
                    _vm.uwShownItem.status
                      ? _c("span", [_vm._v(_vm._s(_vm.uwShownItem.status))])
                      : _c("span", { staticClass: "empty" }, [_vm._v("NO")])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("label", [_vm._v("Account ID:")]),
                    _vm._v(" "),
                    _vm.uwShownItem.accountId
                      ? _c("span", [_vm._v(_vm._s(_vm.uwShownItem.accountId))])
                      : _c("span", { staticClass: "empty" }, [_vm._v("NO")])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("label", [_vm._v("Merchant Industry:")]),
                    _vm._v(" "),
                    _vm.uwShownItem.industry
                      ? _c("span", [_vm._v(_vm._s(_vm.uwShownItem.industry))])
                      : _c("span", { staticClass: "empty" }, [_vm._v("NO")])
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "t2sect" }, [
              _c("div", { staticClass: "t2box" }, [
                _c("h3", [_vm._v("Business Information")]),
                _vm._v(" "),
                _c("ul", [
                  _c("li", [
                    _c("label", [_vm._v("Business Legal Name:")]),
                    _vm._v(" "),
                    _vm.uwShownItem.companyName
                      ? _c("span", [
                          _vm._v(_vm._s(_vm.uwShownItem.companyName))
                        ])
                      : _c("span", { staticClass: "empty" }, [_vm._v("NO")])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("label", [_vm._v("Business Trade Name:")]),
                    _vm._v(" "),
                    _vm.uwShownItem.companyNameTrade
                      ? _c("span", [
                          _vm._v(_vm._s(_vm.uwShownItem.companyNameTrade))
                        ])
                      : _c("span", { staticClass: "empty" }, [_vm._v("NO")])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("label", [_vm._v("Business Email:")]),
                    _vm._v(" "),
                    _vm.uwShownItem.companyEmail
                      ? _c(
                          "span",
                          {
                            class: {
                              duplicate: _vm.uwShownItem.dupCompanyEmail
                            }
                          },
                          [_vm._v(_vm._s(_vm.uwShownItem.companyEmail))]
                        )
                      : _c("span", { staticClass: "empty" }, [_vm._v("NO")])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("label", [_vm._v("Business Country:")]),
                    _vm._v(" "),
                    _vm.uwShownItem.companyAddressCountry
                      ? _c("span", [
                          _vm._v(_vm._s(_vm.uwShownItem.companyAddressCountry))
                        ])
                      : _c("span", { staticClass: "empty" }, [_vm._v("NO")])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("label", [_vm._v("Business Address:")]),
                    _vm._v(" "),
                    _vm.uwShownItem.companyAddress
                      ? _c(
                          "span",
                          { class: { duplicate: _vm.uwShownItem.dupAddress } },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.uwShownItem.companyAddress) +
                                "\n                " +
                                _vm._s(_vm.uwShownItem.companyAddressNum) +
                                "\n                " +
                                _vm._s(_vm.uwShownItem.companyAddressPostcode) +
                                "\n                " +
                                _vm._s(_vm.uwShownItem.companyAddressCity) +
                                "\n                " +
                                _vm._s(_vm.uwShownItem.companyAddressState) +
                                "\n              "
                            )
                          ]
                        )
                      : _c("span", { staticClass: "empty" }, [_vm._v("NO")])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("label", [_vm._v("URL:")]),
                    _vm._v(" "),
                    _vm.uwShownItem.website
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.uwShownItem.hasWebsite
                                ? "No website"
                                : _vm.uwShownItem.website
                            )
                          )
                        ])
                      : _c("span", { staticClass: "empty" }, [_vm._v("NO")])
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "t2sect" }, [
              _c("div", { staticClass: "t2box" }, [
                _c("h3", [_vm._v("Principals Information")]),
                _vm._v(" "),
                _c(
                  "ul",
                  [
                    _c("li", [
                      _c("label", [_vm._v("Number Owners:")]),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          class: {
                            duplicate:
                              _vm.uwShownItem.numberDirectors !=
                              _vm.uwShownItem.ubos.length
                          }
                        },
                        [_vm._v(_vm._s(_vm.uwShownItem.ubos.length))]
                      )
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.uwShownItem.ubos, function(subi, si) {
                      return [
                        _c("li", [
                          _c("label", [
                            _vm._v("Owner " + _vm._s(si + 1) + " Name:")
                          ]),
                          _vm._v(" "),
                          subi.firstName
                            ? _c(
                                "span",
                                {
                                  class: {
                                    duplicate:
                                      _vm.uwShownItem.ubos[si] &&
                                      _vm.uwShownItem.ubos[si].firstName !=
                                        subi.firstName
                                  }
                                },
                                [_vm._v(_vm._s(subi.firstName))]
                              )
                            : _c("span", { staticClass: "empty" }, [
                                _vm._v("NO")
                              ])
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c("label", [
                            _vm._v("Owner " + _vm._s(si + 1) + " Last Name:")
                          ]),
                          _vm._v(" "),
                          subi.lastName
                            ? _c(
                                "span",
                                {
                                  class: {
                                    duplicate:
                                      _vm.uwShownItem.ubos[si] &&
                                      _vm.uwShownItem.ubos[si].lastName !=
                                        subi.lastName
                                  }
                                },
                                [_vm._v(_vm._s(subi.lastName))]
                              )
                            : _c("span", { staticClass: "empty" }, [
                                _vm._v("NO")
                              ])
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c("label", [
                            _vm._v("Owner " + _vm._s(si + 1) + " Phone:")
                          ]),
                          _vm._v(" "),
                          subi.phone
                            ? _c("span", [_vm._v(_vm._s(subi.phone))])
                            : _c("span", { staticClass: "empty" }, [
                                _vm._v("NO")
                              ])
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c("label", [
                            _vm._v("Owner " + _vm._s(si + 1) + " Email:")
                          ]),
                          _vm._v(" "),
                          subi.email
                            ? _c("span", [_vm._v(_vm._s(subi.email))])
                            : _c("span", { staticClass: "empty" }, [
                                _vm._v("NO")
                              ])
                        ])
                      ]
                    }),
                    _vm._v(" "),
                    _vm._l(_vm.uwShownItem.contacts, function(contact, ci) {
                      return _c("li", [
                        _c("label", [
                          _vm._v(
                            _vm._s(
                              contact.type[0].toUpperCase() +
                                contact.type.substring(1)
                            ) + " contact:"
                          )
                        ]),
                        _vm._v(" "),
                        contact.firstName
                          ? _c(
                              "span",
                              {
                                class: {
                                  duplicate: _vm.uwShownItem.dupContacts
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    contact.firstName + " " + contact.lastName
                                  )
                                )
                              ]
                            )
                          : _c("span", { staticClass: "empty" }, [_vm._v("NO")])
                      ])
                    })
                  ],
                  2
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "t2sect" }, [
              _c("div", { staticClass: "t2box" }, [
                _c("h3", [_vm._v("Documents Information")]),
                _vm._v(" "),
                _vm.uwShownItem.documents ? _c("ul", [_vm._m(1)]) : _vm._e()
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "t2sect" }, [
              _c("div", { staticClass: "t2box" }, [
                _c("h3", [_vm._v("System")]),
                _vm._v(" "),
                _c("ul", [
                  _vm._m(2),
                  _vm._v(" "),
                  _vm.uwShownItem.dupCompanyName
                    ? _c(
                        "li",
                        [
                          _vm._v(
                            "\n              Company Name: \n              "
                          ),
                          _vm._l(
                            _vm.uwShownItem.dupCompanyName.split(","),
                            function(dupId) {
                              return [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      _target: "blank",
                                      href: "/applications/" + dupId
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.appIds[dupId] || dupId))]
                                ),
                                _vm._v(" \n              ")
                              ]
                            }
                          )
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.uwShownItem.dupCompanyNameTrade
                    ? _c(
                        "li",
                        [
                          _vm._v(
                            "\n              Company Trade Name: \n              "
                          ),
                          _vm._l(
                            _vm.uwShownItem.dupCompanyNameTrade.split(","),
                            function(dupId) {
                              return [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      _target: "blank",
                                      href: "/applications/" + dupId
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.appIds[dupId] || dupId))]
                                ),
                                _vm._v(" \n              ")
                              ]
                            }
                          )
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.uwShownItem.dupCompanyEmail
                    ? _c(
                        "li",
                        [
                          _vm._v(
                            "\n              Company Name: \n              "
                          ),
                          _vm._l(
                            _vm.uwShownItem.dupCompanyEmail.split(","),
                            function(dupId) {
                              return [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      _target: "blank",
                                      href: "/applications/" + dupId
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.appIds[dupId] || dupId))]
                                ),
                                _vm._v(" \n              ")
                              ]
                            }
                          )
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.uwShownItem.dupWebsite
                    ? _c(
                        "li",
                        [
                          _vm._v("\n              Website: \n              "),
                          _vm._l(
                            _vm.uwShownItem.dupWebsite.split(","),
                            function(dupId) {
                              return [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      _target: "blank",
                                      href: "/applications/" + dupId
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.appIds[dupId] || dupId))]
                                ),
                                _vm._v(" \n              ")
                              ]
                            }
                          )
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.uwShownItem.dupWebsiteBeta
                    ? _c(
                        "li",
                        [
                          _vm._v(
                            "\n              Website Beta: \n              "
                          ),
                          _vm._l(
                            _vm.uwShownItem.dupWebsiteBeta.split(","),
                            function(dupId) {
                              return [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      _target: "blank",
                                      href: "/applications/" + dupId
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.appIds[dupId] || dupId))]
                                ),
                                _vm._v(" \n              ")
                              ]
                            }
                          )
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.uwShownItem.dupEmailService
                    ? _c(
                        "li",
                        [
                          _vm._v(
                            "\n              Support Email: \n              "
                          ),
                          _vm._l(
                            _vm.uwShownItem.dupEmailService.split(","),
                            function(dupId) {
                              return [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      _target: "blank",
                                      href: "/applications/" + dupId
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.appIds[dupId] || dupId))]
                                ),
                                _vm._v(" \n              ")
                              ]
                            }
                          )
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.uwShownItem.dupPhoneService
                    ? _c(
                        "li",
                        [
                          _vm._v(
                            "\n              Support Phone: \n              "
                          ),
                          _vm._l(
                            _vm.uwShownItem.dupPhoneService.split(","),
                            function(dupId) {
                              return [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      _target: "blank",
                                      href: "/applications/" + dupId
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.appIds[dupId] || dupId))]
                                ),
                                _vm._v(" \n              ")
                              ]
                            }
                          )
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.uwShownItem.dupAddress
                    ? _c(
                        "li",
                        [
                          _vm._v("\n              Address: \n              "),
                          _vm._l(
                            _vm.uwShownItem.dupAddress.split(","),
                            function(dupId) {
                              return [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      _target: "blank",
                                      href: "/applications/" + dupId
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.appIds[dupId] || dupId))]
                                ),
                                _vm._v(" \n              ")
                              ]
                            }
                          )
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.uwShownItem.dupDirectors
                    ? _c(
                        "li",
                        [
                          _vm._v("\n              Director: \n              "),
                          _vm._l(_vm.uwShownItem.dupDirectors, function(dupId) {
                            return [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    _target: "blank",
                                    href: "/applications/" + dupId[1]
                                  }
                                },
                                [_vm._v(_vm._s(_vm.appIds[dupId[1]] || dupId))]
                              ),
                              _vm._v(" \n              ")
                            ]
                          })
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.uwShownItem.dupUbos
                    ? _c(
                        "li",
                        [
                          _vm._v("\n              UBO:\n              "),
                          _vm._l(_vm.uwShownItem.dupUbos, function(dupId) {
                            return [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    _target: "blank",
                                    href: "/applications/" + dupId[1]
                                  }
                                },
                                [_vm._v(_vm._s(_vm.appIds[dupId[1]] || dupId))]
                              ),
                              _vm._v(" \n              ")
                            ]
                          })
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.uwShownItem.dupAccounts
                    ? _c(
                        "li",
                        [
                          _vm._v(
                            "\n              Bank Account:\n              "
                          ),
                          _vm._l(_vm.uwShownItem.dupAccounts, function(dupId) {
                            return [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    _target: "blank",
                                    href: "/applications/" + dupId[1]
                                  }
                                },
                                [_vm._v(_vm._s(_vm.appIds[dupId[1]] || dupId))]
                              ),
                              _vm._v(" \n              ")
                            ]
                          })
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.uwShownItem.dupContacts
                    ? _c(
                        "li",
                        [
                          _vm._v("\n              Contacts:\n              "),
                          _vm._l(_vm.uwShownItem.dupContacts, function(dupId) {
                            return [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    _target: "blank",
                                    href: "/applications/" + dupId[1]
                                  }
                                },
                                [_vm._v(_vm._s(_vm.appIds[dupId[1]] || dupId))]
                              ),
                              _vm._v(" \n              ")
                            ]
                          })
                        ],
                        2
                      )
                    : _vm._e()
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "t2sect" }, [
              _c("div", { staticClass: "t2box" }, [
                _c("h3", [_vm._v("Pre-Check Score Report")]),
                _vm._v(" "),
                _c("ul", [
                  _c("li", [
                    _c("label", [_vm._v("=")]),
                    _vm._v(" " + _vm._s(_vm.validItems) + " / 16\n            ")
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("label", [_vm._v("Negative List Report:")]),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(0))])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("label", [_vm._v("Duplicate List Report:")]),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.dupCount))])
                  ])
                ])
              ])
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticStyle: { width: "1%" } }),
        _vm._v(" "),
        _c("th", [_vm._v("ID")]),
        _vm._v(" "),
        _c("th", [_vm._v("Legal Name")]),
        _vm._v(" "),
        _c("th", [_vm._v("Date")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("label", [_vm._v("Completed Merchant Application:")]),
      _vm._v(" "),
      _c("span", [_vm._v("Yes")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [_c("label", [_vm._v("Duplicates:")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }