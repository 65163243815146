var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "setup-box" }, [
    _c(
      "div",
      { staticClass: "ncontent appf appnat", class: { "in-popup": _vm.popup } },
      [
        _c("div", { staticClass: "implementation-note" }, [
          _c("p", [_vm._v(_vm._s(_vm.appl.agentDb.implMessage))])
        ]),
        _vm._v(" "),
        _c("form", { key: "step1", staticClass: "form-step" }, [
          false
            ? _c("section", [
                _c("h2", [_vm._v("General Information")]),
                _vm._v(" "),
                _c("ul", { staticClass: "column-form column-form-compact" }, [
                  false
                    ? _c("li", [
                        _c("label", [_vm._v("Signup Form ID")]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "field" },
                          [
                            _c("validator", { attrs: { value: 0, reqs: "" } }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.appl.id,
                                    expression: "appl.id"
                                  }
                                ],
                                attrs: { type: "text", readonly: "" },
                                domProps: { value: _vm.appl.id },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.appl,
                                      "id",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ])
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("li", [
                    _c("label", [_vm._v("Partner Solutions")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "field" },
                      [
                        _c(
                          "validator",
                          {
                            attrs: {
                              value: _vm.item.solutions,
                              reqs: "required"
                            }
                          },
                          _vm._l(_vm.solutions, function(solution) {
                            return _c(
                              "label",
                              {
                                class: {
                                  checked:
                                    _vm.item.solutions.indexOf(
                                      solution.value
                                    ) != -1
                                }
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.item.solutions,
                                      expression: "item.solutions"
                                    }
                                  ],
                                  attrs: { type: "checkbox", name: "plan" },
                                  domProps: {
                                    value: solution.value,
                                    checked: Array.isArray(_vm.item.solutions)
                                      ? _vm._i(
                                          _vm.item.solutions,
                                          solution.value
                                        ) > -1
                                      : _vm.item.solutions
                                  },
                                  on: {
                                    change: function($event) {
                                      var $$a = _vm.item.solutions,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = solution.value,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.item,
                                              "solutions",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.item,
                                              "solutions",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(_vm.item, "solutions", $$c)
                                      }
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("span"),
                                _vm._v(
                                  _vm._s(solution.label) + "\n                "
                                )
                              ]
                            )
                          }),
                          0
                        )
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("label", [_vm._v("Relationship Manager")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "field" },
                      [
                        _c(
                          "validator",
                          {
                            attrs: {
                              value: _vm.item.managerId,
                              reqs: "required"
                            }
                          },
                          [
                            _c("dyn-select", {
                              attrs: { options: _vm.managers },
                              model: {
                                value: _vm.item.managerId,
                                callback: function($$v) {
                                  _vm.$set(_vm.item, "managerId", $$v)
                                },
                                expression: "item.managerId"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("label", [_vm._v("Language")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "field" },
                      [
                        _c(
                          "validator",
                          { attrs: { value: 0, reqs: "" } },
                          [
                            _c("dyn-select", {
                              attrs: { options: _vm.languages },
                              model: {
                                value: _vm.item.language,
                                callback: function($$v) {
                                  _vm.$set(_vm.item, "language", $$v)
                                },
                                expression: "item.language"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("section", [
            _c("h2", [_vm._v("Schedule A")]),
            _vm._v(" "),
            _c("ul", { staticClass: "column-form column-form-compact" }, [
              _c("li", [
                _c("label", [_vm._v("Partner Tier")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "field" },
                  [
                    _c(
                      "validator",
                      { attrs: { value: 0, reqs: "" } },
                      [
                        _c("dyn-select", {
                          attrs: { options: _vm.accountTiers },
                          model: {
                            value: _vm.item.accClass,
                            callback: function($$v) {
                              _vm.$set(_vm.item, "accClass", $$v)
                            },
                            expression: "item.accClass"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("li", [
                _c("label", [_vm._v("BIN/ICA Rental Fee")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "field" },
                  [
                    _c(
                      "validator",
                      { attrs: { value: 0, reqs: "" } },
                      [
                        _c("formel-money-amount4", {
                          attrs: { suffix: "%" },
                          model: {
                            value: _vm.item.rentalFee,
                            callback: function($$v) {
                              _vm.$set(_vm.item, "rentalFee", $$v)
                            },
                            expression: "item.rentalFee"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("li", [
                _c("label", [_vm._v("Partner Setup Fee")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "field" },
                  [
                    _c(
                      "validator",
                      { attrs: { value: 0, reqs: "" } },
                      [
                        _c("formel-money-amount4", {
                          attrs: { prefix: "$" },
                          model: {
                            value: _vm.item.setupFee,
                            callback: function($$v) {
                              _vm.$set(_vm.item, "setupFee", $$v)
                            },
                            expression: "item.setupFee"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("li", [
                _c("label", [_vm._v("Monthly Minimum")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "field" },
                  [
                    _c(
                      "validator",
                      { attrs: { value: 0, reqs: "" } },
                      [
                        _c("formel-money-amount4", {
                          attrs: { prefix: "$" },
                          model: {
                            value: _vm.item.monthlyMinimum,
                            callback: function($$v) {
                              _vm.$set(_vm.item, "monthlyMinimum", $$v)
                            },
                            expression: "item.monthlyMinimum"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("li", [
                _c("label", [_vm._v("Compliance Review (days):")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "field" },
                  [
                    _c(
                      "validator",
                      { attrs: { value: 0, reqs: "" } },
                      [
                        _c("dyn-select", {
                          attrs: { options: _vm.reviewDaysOpts },
                          model: {
                            value: _vm.item.complianceReview,
                            callback: function($$v) {
                              _vm.$set(_vm.item, "complianceReview", $$v)
                            },
                            expression: "item.complianceReview"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ]),
            _vm._v(" "),
            _c("h2", [_vm._v("Banking")]),
            _vm._v(" "),
            false &&
            ["agent-inside", "agent-outside"].indexOf(_vm.appl.groups) != -1
              ? _c("ul", { staticClass: "column-form column-form-compact" }, [
                  _vm._m(0),
                  _vm._v(" "),
                  _vm._m(1),
                  _vm._v(" "),
                  _vm._m(2),
                  _vm._v(" "),
                  _vm._m(3),
                  _vm._v(" "),
                  _vm._m(4),
                  _vm._v(" "),
                  _vm._m(5),
                  _vm._v(" "),
                  _vm._m(6),
                  _vm._v(" "),
                  _vm._m(7),
                  _vm._v(" "),
                  _vm._m(8)
                ])
              : _vm.appl.agentDb.accounts.length
              ? _c("ul", { staticClass: "column-form column-form-compact" }, [
                  _c("li", [
                    _c("label", [_vm._v("Account Currency")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "field" },
                      [
                        _c(
                          "validator",
                          { attrs: { value: 0, reqs: "" } },
                          [
                            _c("dyn-select", {
                              attrs: { options: _vm.currencies2 },
                              model: {
                                value:
                                  _vm.appl.agentDb.accounts[0].accountCurrency,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.appl.agentDb.accounts[0],
                                    "accountCurrency",
                                    $$v
                                  )
                                },
                                expression:
                                  "appl.agentDb.accounts[0].accountCurrency"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("label", [_vm._v("Name on Account")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "field" },
                      [
                        _c("validator", { attrs: { value: 0, reqs: "" } }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.appl.agentDb.accounts[0].accountHolder,
                                expression:
                                  "appl.agentDb.accounts[0].accountHolder"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: {
                              value: _vm.appl.agentDb.accounts[0].accountHolder
                            },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.appl.agentDb.accounts[0],
                                  "accountHolder",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ])
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("label", [_vm._v("Bank Country")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "field" },
                      [
                        _c(
                          "validator",
                          {
                            attrs: {
                              value:
                                _vm.appl.agentDb.accounts[0].bankAddressCountry,
                              reqs: "required"
                            }
                          },
                          [
                            _c("dyn-select", {
                              attrs: { options: _vm.countries },
                              model: {
                                value:
                                  _vm.appl.agentDb.accounts[0]
                                    .bankAddressCountry,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.appl.agentDb.accounts[0],
                                    "bankAddressCountry",
                                    $$v
                                  )
                                },
                                expression:
                                  "appl.agentDb.accounts[0].bankAddressCountry"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("label", [_vm._v("Bank State")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "field" },
                      [
                        _c(
                          "validator",
                          {
                            attrs: {
                              value:
                                _vm.appl.agentDb.accounts[0].bankAddressState,
                              reqs: "required"
                            }
                          },
                          [
                            _vm.states.length
                              ? _c("dyn-select", {
                                  attrs: { options: _vm.states },
                                  model: {
                                    value:
                                      _vm.appl.agentDb.accounts[0]
                                        .bankAddressState,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.appl.agentDb.accounts[0],
                                        "bankAddressState",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "appl.agentDb.accounts[0].bankAddressState"
                                  }
                                })
                              : _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.appl.agentDb.accounts[0]
                                          .bankAddressState,
                                      expression:
                                        "appl.agentDb.accounts[0].bankAddressState"
                                    }
                                  ],
                                  attrs: { type: "texta" },
                                  domProps: {
                                    value:
                                      _vm.appl.agentDb.accounts[0]
                                        .bankAddressState
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.appl.agentDb.accounts[0],
                                        "bankAddressState",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("label", [_vm._v("Bank Name")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "field" },
                      [
                        _c("validator", { attrs: { value: 0, reqs: "" } }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.appl.agentDb.accounts[0].bankName,
                                expression: "appl.agentDb.accounts[0].bankName"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: {
                              value: _vm.appl.agentDb.accounts[0].bankName
                            },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.appl.agentDb.accounts[0],
                                  "bankName",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ])
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("label", [_vm._v("Bank Account Number")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "field" },
                      [
                        _c("validator", { attrs: { value: 0, reqs: "" } }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.appl.agentDb.accounts[0].accountNumber,
                                expression:
                                  "appl.agentDb.accounts[0].accountNumber"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: {
                              value: _vm.appl.agentDb.accounts[0].accountNumber
                            },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.appl.agentDb.accounts[0],
                                  "accountNumber",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ])
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("label", [_vm._v("Institution Number")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "field" },
                      [
                        _c("validator", { attrs: { value: 0, reqs: "" } }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.appl.agentDb.accounts[0].accountSwift,
                                expression:
                                  "appl.agentDb.accounts[0].accountSwift"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: {
                              value: _vm.appl.agentDb.accounts[0].accountSwift
                            },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.appl.agentDb.accounts[0],
                                  "accountSwift",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ])
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("label", [_vm._v("Transit Number")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "field" },
                      [
                        _c("validator", { attrs: { value: 0, reqs: "" } }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.appl.agentDb.accounts[0].accountIBAN,
                                expression:
                                  "appl.agentDb.accounts[0].accountIBAN"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: {
                              value: _vm.appl.agentDb.accounts[0].accountIBAN
                            },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.appl.agentDb.accounts[0],
                                  "accountIBAN",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ])
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("label", [_vm._v("Routing number")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "field" },
                      [
                        _c("validator", { attrs: { value: 0, reqs: "" } }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.appl.agentDb.accounts[0].accountRouting,
                                expression:
                                  "appl.agentDb.accounts[0].accountRouting"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: {
                              value: _vm.appl.agentDb.accounts[0].accountRouting
                            },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.appl.agentDb.accounts[0],
                                  "accountRouting",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ])
                      ],
                      1
                    )
                  ])
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("section", [
            _c("h2", [_vm._v("Access Information")]),
            _vm._v(" "),
            _c("ul", { staticClass: "column-form column-form-compact" }, [
              _c("li", [
                _c("label", [_vm._v("Portal")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "field" },
                  [
                    _c(
                      "validator",
                      { attrs: { value: 0, reqs: "" } },
                      [
                        _c("dyn-select", {
                          attrs: { options: _vm.portals },
                          model: {
                            value: _vm.item.portal,
                            callback: function($$v) {
                              _vm.$set(_vm.item, "portal", $$v)
                            },
                            expression: "item.portal"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("li", [
                _c("label", [_vm._v("Username")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "field" },
                  [
                    _c("validator", { attrs: { value: 0, reqs: "" } }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.item.username,
                            expression: "item.username"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.item.username },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.item, "username", $event.target.value)
                          }
                        }
                      })
                    ])
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("li", [
                _c("label", [_vm._v("Send welcome email")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "field" },
                  [
                    _c(
                      "validator",
                      {
                        attrs: { value: _vm.item.sendEmail, reqs: "required" }
                      },
                      [
                        _c("dyn-select", {
                          attrs: { options: _vm.toggleYesNo },
                          model: {
                            value: _vm.item.sendEmail,
                            callback: function($$v) {
                              _vm.$set(_vm.item, "sendEmail", $$v)
                            },
                            expression: "item.sendEmail"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              ["agent-inside", "agent-outside"].indexOf(_vm.appl.groups) != -1
                ? _c("li", [
                    _c("label", [_vm._v("Company Email")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "field" },
                      [
                        _c("validator", { attrs: { value: 0, reqs: "" } }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.agentFormattedEmail,
                                expression: "agentFormattedEmail"
                              }
                            ],
                            attrs: { type: "text", readonly: "" },
                            domProps: { value: _vm.agentFormattedEmail },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.agentFormattedEmail = $event.target.value
                              }
                            }
                          })
                        ])
                      ],
                      1
                    )
                  ])
                : _c("li", [
                    _c("label", [_vm._v("Company Email")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "field" },
                      [
                        _c("validator", { attrs: { value: 0, reqs: "" } }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.appl.agentDb.email,
                                expression: "appl.agentDb.email"
                              }
                            ],
                            attrs: { type: "text", readonly: "" },
                            domProps: { value: _vm.appl.agentDb.email },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.appl.agentDb,
                                  "email",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ])
                      ],
                      1
                    )
                  ]),
              _vm._v(" "),
              _c("li", [
                _c("label", [_vm._v("PIN")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "field" },
                  [
                    _c(
                      "validator",
                      { attrs: { value: _vm.item.pin, reqs: "required" } },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.pin,
                              expression: "item.pin"
                            }
                          ],
                          attrs: { type: "text", readonly: "" },
                          domProps: { value: _vm.item.pin },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.item, "pin", $event.target.value)
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.generatePin.apply(null, arguments)
                              }
                            }
                          },
                          [_vm._v("Generate PIN")]
                        )
                      ]
                    )
                  ],
                  1
                )
              ])
            ]),
            _vm._v(" "),
            _c("h2", [_vm._v("Solutions")]),
            _vm._v(" "),
            _c("ul", { staticClass: "appview-list" }, [
              _c(
                "li",
                { staticStyle: { width: "33%" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "validator",
                      staticStyle: { display: "flex" }
                    },
                    [
                      _c("span", { staticStyle: { width: "66%" } }, [
                        _vm._v("Payments")
                      ]),
                      _vm._v(" "),
                      _vm.settings && _vm.settings.modules.rPayments
                        ? _c("mnb-switch", {
                            attrs: {
                              disabled: !_vm.settings.modules.rPayments
                            },
                            model: {
                              value: _vm.item.payments,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "payments", $$v)
                              },
                              expression: "item.payments"
                            }
                          })
                        : _c("mnb-switch", {
                            attrs: {
                              disabled: true,
                              set: (_vm.item.payments = false)
                            }
                          })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.msolutions, function(msolution) {
                    return _c(
                      "div",
                      {
                        staticClass: "validator",
                        staticStyle: { display: "flex" }
                      },
                      [
                        _c("span", { staticStyle: { width: "66%" } }, [
                          _vm._v(_vm._s(msolution.label))
                        ]),
                        _vm._v(" "),
                        _c("mnb-switch", {
                          attrs: { disabled: !_vm.item.payments },
                          model: {
                            value: _vm.msolutionsSwitches[msolution.value],
                            callback: function($$v) {
                              _vm.$set(
                                _vm.msolutionsSwitches,
                                msolution.value,
                                $$v
                              )
                            },
                            expression: "msolutionsSwitches[msolution.value]"
                          }
                        })
                      ],
                      1
                    )
                  })
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "li",
                { staticStyle: { width: "33%" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "validator",
                      staticStyle: { display: "flex" }
                    },
                    [
                      _c("span", { staticStyle: { width: "66%" } }, [
                        _vm._v("Treasury")
                      ]),
                      _vm._v(" "),
                      _vm.settings && _vm.settings.modules.rBanking
                        ? _c("mnb-switch", {
                            attrs: { disabled: !_vm.settings.modules.rBanking },
                            model: {
                              value: _vm.item.treasury,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "treasury", $$v)
                              },
                              expression: "item.treasury"
                            }
                          })
                        : _c("mnb-switch", {
                            attrs: {
                              disabled: true,
                              set: (_vm.item.treasury = false)
                            }
                          })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.tsolutions, function(tsolution) {
                    return _c(
                      "div",
                      {
                        staticClass: "validator",
                        staticStyle: { display: "flex" }
                      },
                      [
                        _c("span", { staticStyle: { width: "66%" } }, [
                          _vm._v(_vm._s(tsolution.label))
                        ]),
                        _vm._v(" "),
                        _c("mnb-switch", {
                          attrs: { disabled: !_vm.item.treasury },
                          model: {
                            value: _vm.tsolutionsSwitches[tsolution.value],
                            callback: function($$v) {
                              _vm.$set(
                                _vm.tsolutionsSwitches,
                                tsolution.value,
                                $$v
                              )
                            },
                            expression: "tsolutionsSwitches[tsolution.value]"
                          }
                        })
                      ],
                      1
                    )
                  })
                ],
                2
              )
            ]),
            _vm._v(" "),
            _c("h2", [_vm._v("Modules")]),
            _vm._v(" "),
            _c("ul", { staticClass: "appview-list" }, [
              _c("li", { staticStyle: { width: "33%" } }, [
                _c(
                  "div",
                  {
                    staticClass: "validator",
                    staticStyle: { display: "flex" }
                  },
                  [
                    _c("span", { staticStyle: { width: "66%" } }, [
                      _vm._v("Amadel")
                    ]),
                    _vm._v(" "),
                    _vm.settings && _vm.settings.settings.partners.amadel
                      ? _c("mnb-switch", {
                          attrs: {
                            disabled: !_vm.settings.settings.partners.amadel
                          },
                          model: {
                            value: _vm.appl.agentDb.amadel,
                            callback: function($$v) {
                              _vm.$set(_vm.appl.agentDb, "amadel", $$v)
                            },
                            expression: "appl.agentDb.amadel"
                          }
                        })
                      : _c("mnb-switch", {
                          attrs: {
                            disabled: true,
                            set: (_vm.appl.agentDb.amadel = false)
                          }
                        })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "validator",
                    staticStyle: { display: "flex" }
                  },
                  [
                    _c("span", { staticStyle: { width: "66%" } }, [
                      _vm._v("Scheduler")
                    ]),
                    _vm._v(" "),
                    _vm.settings && _vm.settings.settings.partners.scheduler
                      ? _c("mnb-switch", {
                          attrs: {
                            disabled: !_vm.settings.settings.partners.scheduler
                          },
                          model: {
                            value: _vm.appl.agentDb.scheduler,
                            callback: function($$v) {
                              _vm.$set(_vm.appl.agentDb, "scheduler", $$v)
                            },
                            expression: "appl.agentDb.scheduler"
                          }
                        })
                      : _c("mnb-switch", {
                          attrs: {
                            disabled: true,
                            set: (_vm.appl.agentDb.scheduler = false)
                          }
                        })
                  ],
                  1
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c("section", [
            _c("h2", [_vm._v("Advanced Information")]),
            _vm._v(" "),
            _c("ul", { staticClass: "column-form column-form-compact" }, [
              _c("li", [
                _c("label", [_vm._v("Plugg & Co. Registration")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "field" },
                  [
                    _c(
                      "validator",
                      { attrs: { value: _vm.item.pluggReg, reqs: "required" } },
                      [
                        _c("dyn-select", {
                          attrs: { options: _vm.toggleYesNo },
                          model: {
                            value: _vm.item.pluggReg,
                            callback: function($$v) {
                              _vm.$set(_vm.item, "pluggReg", $$v)
                            },
                            expression: "item.pluggReg"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c("section", [
            _c("h2", [_vm._v("Additional Information")]),
            _vm._v(" "),
            _c("ul", { staticClass: "column-form column-form-compact" }, [
              _c("li", [
                _c("label", [_vm._v("Additional Build Notes")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "field" },
                  [
                    _c("validator", { attrs: { value: 0, reqs: "" } }, [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.item.notes,
                            expression: "item.notes"
                          }
                        ],
                        domProps: { value: _vm.item.notes },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.item, "notes", $event.target.value)
                          }
                        }
                      })
                    ])
                  ],
                  1
                )
              ])
            ])
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("label", [_vm._v("Settlement Currency")]),
      _c("div", { staticClass: "field" }, [
        _c("div", { staticClass: "validator" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("label", [_vm._v("Name on Account")]),
      _c("div", { staticClass: "field" }, [
        _c("div", { staticClass: "validator" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("label", [_vm._v("Bank Country")]),
      _c("div", { staticClass: "field" }, [
        _c("div", { staticClass: "validator" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("label", [_vm._v("Bank State")]),
      _c("div", { staticClass: "field" }, [
        _c("div", { staticClass: "validator" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("label", [_vm._v("Bank Name")]),
      _c("div", { staticClass: "field" }, [
        _c("div", { staticClass: "validator" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("label", [_vm._v("Bank Account Number")]),
      _c("div", { staticClass: "field" }, [
        _c("div", { staticClass: "validator" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("label", [_vm._v("Institution Number")]),
      _c("div", { staticClass: "field" }, [
        _c("div", { staticClass: "validator" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("label", [_vm._v("Transit Number")]),
      _c("div", { staticClass: "field" }, [
        _c("div", { staticClass: "validator" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("label", [_vm._v("Routing number")]),
      _c("div", { staticClass: "field" }, [
        _c("div", { staticClass: "validator" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }