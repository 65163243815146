var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "acc-list", staticStyle: { display: "block" } },
    [
      _c("table", {}, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "tbody",
          _vm._l(_vm.subusers, function(itemx, index) {
            return _c("tr", [
              _c("td", { staticClass: "text-left" }, [
                _c("a", { attrs: { href: "/profile/subuser/" + itemx.id } }, [
                  _vm._v(_vm._s(itemx.agentId))
                ])
              ]),
              _vm._v(" "),
              _c("td", { staticClass: "text-left" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      itemx.fullName || (itemx.agentDb && itemx.agentDb.name)
                    ) +
                    "\n        "
                )
              ]),
              _vm._v(" "),
              _c("td", { staticClass: "text-left" }, [
                _vm._v("\n          " + _vm._s(itemx.lastName) + "\n        ")
              ]),
              _vm._v(" "),
              _c("td", { staticClass: "text-left" }, [
                _vm._v("\n          " + _vm._s(itemx.email) + "\n        ")
              ]),
              _vm._v(" "),
              _c("td", { staticClass: "text-right" })
            ])
          }),
          0
        )
      ]),
      _vm._v(" "),
      _c("portal", { attrs: { to: "actions" } }, [
        _c(
          "ul",
          [
            _vm._l(_vm.actions, function(optLabel, optMethod) {
              return _c(
                "li",
                { class: { disabled: _vm.isDisabled(optMethod) } },
                [
                  _c(
                    "a",
                    {
                      attrs: { href: "" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.doAction(optMethod)
                        }
                      }
                    },
                    [_vm._v(_vm._s(optMethod))]
                  )
                ]
              )
            })
          ],
          2
        )
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", {}),
        _vm._v(" "),
        _c("th", {}, [_vm._v("Username")]),
        _vm._v(" "),
        _c("th", {}, [_vm._v("Email")]),
        _vm._v(" "),
        _c("th", {}, [_vm._v("Activation Code")]),
        _vm._v(" "),
        _c("th", {}, [_vm._v("Activation Code Expires")]),
        _vm._v(" "),
        _c("th", {}, [_vm._v("Activation Date")]),
        _vm._v(" "),
        _c("th", {}, [_vm._v("Reset Pass Request")]),
        _vm._v(" "),
        _c("th", {}, [_vm._v("Reset Pass Complete")]),
        _vm._v(" "),
        _c("th", {}, [_vm._v("Blocked")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }