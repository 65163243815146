var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "element-toggle" }, [
    _c("label", { class: { checked: _vm.actualValue == _vm.customVal } }, [
      _c("span"),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.actualValue,
            expression: "actualValue"
          }
        ],
        attrs: { type: "radio" },
        domProps: {
          value: _vm.customVal,
          checked: _vm._q(_vm.actualValue, _vm.customVal)
        },
        on: {
          change: [
            function($event) {
              _vm.actualValue = _vm.customVal
            },
            _vm.updateVal
          ]
        }
      }),
      _vm._v("\n    " + _vm._s(_vm.customLabel || "No") + "\n    "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.customValue,
            expression: "customValue"
          }
        ],
        attrs: { type: "text", disabled: _vm.actualValue == _vm.defaultVal },
        domProps: { value: _vm.customValue },
        on: {
          input: [
            function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.customValue = $event.target.value
            },
            _vm.updateVal
          ]
        }
      })
    ]),
    _vm._v(" "),
    _c("label", { class: { checked: _vm.actualValue == _vm.defaultVal } }, [
      _c("span"),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.actualValue,
            expression: "actualValue"
          }
        ],
        attrs: { type: "radio" },
        domProps: {
          value: _vm.defaultVal,
          checked: _vm._q(_vm.actualValue, _vm.defaultVal)
        },
        on: {
          change: [
            function($event) {
              _vm.actualValue = _vm.defaultVal
            },
            _vm.updateVal
          ]
        }
      }),
      _vm._v("\n    " + _vm._s(_vm.defaultLabel || "Yes") + "\n  ")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }