var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form-wrapper",
    {
      class: {
        focused: _vm.focused,
        filled: _vm.focused || _vm.data,
        disabled: _vm.disabled
      },
      attrs: { label: _vm.label, required: _vm.isRequired, errors: _vm.errors }
    },
    [
      _vm.type === "checkbox"
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.data,
                expression: "data"
              }
            ],
            ref: "input",
            class: { error: _vm.hasError },
            attrs: {
              required: _vm.isRequired,
              disabled: _vm.disabled,
              maxlength: _vm.maxlength || null,
              type: "checkbox"
            },
            domProps: {
              checked: Array.isArray(_vm.data)
                ? _vm._i(_vm.data, null) > -1
                : _vm.data
            },
            on: {
              focus: function($event) {
                _vm.focused = true
              },
              blur: function($event) {
                _vm.focused = false
              },
              input: function($event) {
                return _vm.$emit("input", $event.target.value)
              },
              change: [
                function($event) {
                  var $$a = _vm.data,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.data = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.data = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.data = $$c
                  }
                },
                function($event) {
                  return _vm.$emit("input", $event.target.value)
                }
              ]
            }
          })
        : _vm.type === "radio"
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.data,
                expression: "data"
              }
            ],
            ref: "input",
            class: { error: _vm.hasError },
            attrs: {
              required: _vm.isRequired,
              disabled: _vm.disabled,
              maxlength: _vm.maxlength || null,
              type: "radio"
            },
            domProps: { checked: _vm._q(_vm.data, null) },
            on: {
              focus: function($event) {
                _vm.focused = true
              },
              blur: function($event) {
                _vm.focused = false
              },
              input: function($event) {
                return _vm.$emit("input", $event.target.value)
              },
              change: [
                function($event) {
                  _vm.data = null
                },
                function($event) {
                  return _vm.$emit("input", $event.target.value)
                }
              ]
            }
          })
        : _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.data,
                expression: "data"
              }
            ],
            ref: "input",
            class: { error: _vm.hasError },
            attrs: {
              required: _vm.isRequired,
              disabled: _vm.disabled,
              maxlength: _vm.maxlength || null,
              type: _vm.type
            },
            domProps: { value: _vm.data },
            on: {
              focus: function($event) {
                _vm.focused = true
              },
              blur: function($event) {
                _vm.focused = false
              },
              input: [
                function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.data = $event.target.value
                },
                function($event) {
                  return _vm.$emit("input", $event.target.value)
                }
              ],
              change: function($event) {
                return _vm.$emit("input", $event.target.value)
              }
            }
          })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }