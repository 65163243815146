var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "messages" }, [
    _c("h2", [_vm._v("System Messages")]),
    _vm._v(" "),
    _c(
      "ul",
      _vm._l(_vm.messages, function(message) {
        return _c("li", { key: message.id }, [
          _c(
            "div",
            { staticClass: "date" },
            [
              _vm.formatDate(message.date, "X") < _vm.dateNow("X") - 86400
                ? [_c("span", { staticClass: "new" }, [_vm._v("New")])]
                : _vm._e(),
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.formatDate(message.date, "MMMM D, YYYY [at] h:mm A z")
                  ) +
                  "\n      "
              )
            ],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "message" }, [
            _vm._v(_vm._s(message.message))
          ])
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }