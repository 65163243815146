var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("view-profile", { attrs: { header: ["Profile Detail"] } }, [
    _vm.customer
      ? _c("div", { staticClass: "merchant-panel" }, [
          _c(
            "div",
            { staticClass: "content-wrapper" },
            [
              _c(
                "panel",
                {
                  attrs: { title: "Profile" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "actions",
                        fn: function() {
                          return [
                            _c("span", { staticClass: "status" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.customer.accountType == "Personal"
                                    ? _vm.customer.personal.status
                                    : _vm.customer.business.status
                                ) +
                                  " - " +
                                  _vm._s(_vm.statusDescription)
                              )
                            ])
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    2278541526
                  )
                },
                [
                  _vm._v(" "),
                  _c("formview-treasury-main", {
                    attrs: {
                      customer: _vm.customer,
                      tab: 0,
                      notes: _vm.notes,
                      columns: 5
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              !!_vm.customer.business
                ? _c(
                    "panel",
                    { staticClass: "mt-20", attrs: { title: "Company" } },
                    [
                      _c("formview-treasury-main", {
                        attrs: {
                          customer: _vm.customer,
                          tab: 1,
                          notes: _vm.notes,
                          bg: false,
                          columns: 5,
                          simplified: true
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "panel",
                { staticClass: "mt-20", attrs: { title: "Contacts" } },
                [
                  _c("formview-treasury-main", {
                    attrs: {
                      customer: _vm.customer,
                      tab: 2,
                      notes: _vm.notes,
                      bg: false,
                      columns: 5
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "panel",
                { staticClass: "mt-20", attrs: { title: "Documents" } },
                [
                  _c("treasury-documents", {
                    attrs: {
                      item: _vm.customer,
                      "portal-buttons": false,
                      simplified: true
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("panel", {
                staticClass: "mt-20",
                attrs: { title: "Accounts" }
              }),
              _vm._v(" "),
              _c("panel", {
                staticClass: "mt-20",
                attrs: { title: "Settings" }
              })
            ],
            1
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }