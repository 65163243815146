var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "status-page" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "status-list" }, [
      _c(
        "ul",
        _vm._l(_vm.items, function(item, index) {
          return _c("li", { class: { opened: _vm.opened[index] } }, [
            _c(
              "div",
              {
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.toggleOpened(index)
                  }
                }
              },
              [
                _c("a", { staticClass: "toggle", attrs: { href: "" } }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(item.created) +
                      " - " +
                      _vm._s(item.version) +
                      "\n          "
                  )
                ])
              ]
            ),
            _vm._v(" "),
            _vm.opened[index]
              ? _c("ul", [
                  _c(
                    "textarea",
                    { attrs: { disabled: "", cols: "100", rows: "20" } },
                    [
                      _vm._v(
                        "            " +
                          _vm._s(item.description) +
                          "\n          "
                      )
                    ]
                  )
                ])
              : _vm._e()
          ])
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ss-head" }, [
      _c("div", { staticClass: "publish-content" }, [
        _c("h1", [_vm._v("Change Log")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }