var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("ul", { staticClass: "appview-list" }, [
        _c("li", [
          _c("label", [_vm._v("Title")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "validator" },
            [
              _vm.editMode
                ? _c(
                    "validator",
                    { attrs: { value: _vm.form.title, reqs: "required" } },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.title,
                            expression: "form.title"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.form.title },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.form, "title", $event.target.value)
                          }
                        }
                      })
                    ]
                  )
                : _c("span", [_vm._v(_vm._s(_vm.employee.title))])
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _c("label", [_vm._v("Role")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "validator" },
            [
              _vm.editMode
                ? _c(
                    "validator",
                    { attrs: { value: _vm.form.role, reqs: "required" } },
                    [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.role,
                              expression: "form.role"
                            }
                          ],
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.form,
                                "role",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            }
                          }
                        },
                        _vm._l(_vm.titleGroups, function(group) {
                          return _c(
                            "option",
                            { key: group, domProps: { value: group } },
                            [_vm._v(_vm._s(_vm.groups[group]))]
                          )
                        }),
                        0
                      )
                    ]
                  )
                : _c("span", [_vm._v(_vm._s(_vm.userGroup(_vm.employee.role)))])
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _c("label", [_vm._v("First Name")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "validator" },
            [
              _vm.editMode
                ? _c(
                    "validator",
                    { attrs: { value: _vm.form.first_name, reqs: "required" } },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.first_name,
                            expression: "form.first_name"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.form.first_name },
                        on: {
                          keypress: function($event) {
                            return _vm.allowLetterNumber($event, {
                              numbers: false
                            })
                          },
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.form,
                              "first_name",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  )
                : _c("span", [_vm._v(_vm._s(_vm.employee.first_name))])
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _c("label", [_vm._v("Last Name")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "validator" },
            [
              _vm.editMode
                ? _c(
                    "validator",
                    { attrs: { value: _vm.form.last_name, reqs: "required" } },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.last_name,
                            expression: "form.last_name"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.form.last_name },
                        on: {
                          keypress: function($event) {
                            return _vm.allowLetterNumber($event, {
                              numbers: false
                            })
                          },
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.form, "last_name", $event.target.value)
                          }
                        }
                      })
                    ]
                  )
                : _c("span", [_vm._v(_vm._s(_vm.employee.last_name))])
            ],
            1
          )
        ]),
        _vm._v(" "),
        false
          ? _c("li", [
              _c("label", [_vm._v("Emp ID")]),
              _vm._v(" "),
              _c("div", { staticClass: "validator" }, [
                _c("span", [_vm._v(_vm._s(_vm.employee.employeeId))])
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        false
          ? _c("li", [
              _c("label", [_vm._v("Employee Solution")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "validator" },
                [
                  _vm.editMode
                    ? _c(
                        "validator",
                        {
                          attrs: { value: _vm.form.companyId, reqs: "required" }
                        },
                        [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.form.companyId,
                                  expression: "form.companyId"
                                }
                              ],
                              on: {
                                change: function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.form,
                                    "companyId",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                }
                              }
                            },
                            _vm._l(_vm.companies, function(company) {
                              return _c(
                                "option",
                                { domProps: { value: company.id } },
                                [_vm._v(_vm._s(company.nameSolution))]
                              )
                            }),
                            0
                          )
                        ]
                      )
                    : _c("span", [
                        _vm._v(_vm._s(_vm.employee.companyId.nameSolution))
                      ])
                ],
                1
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        false
          ? _c("li", [
              _c("label", [_vm._v("Company Email")]),
              _vm._v(" "),
              _c("div", { staticClass: "validator" }, [
                _c("span", [_vm._v(_vm._s(_vm.employee.companyId.emailSuffix))])
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("li", [
          _c("label", [_vm._v("Email")]),
          _vm._v(" "),
          _c("div", { staticClass: "validator" }, [
            _c("span", [_vm._v(_vm._s(_vm.employee.email))])
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("label", [_vm._v("Phone")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "validator" },
            [
              _vm.editMode
                ? _c(
                    "validator",
                    { attrs: { value: _vm.form.phone, reqs: "required" } },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.phone,
                            expression: "form.phone"
                          }
                        ],
                        staticClass: "w100",
                        attrs: { type: "text" },
                        domProps: { value: _vm.form.phone },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.form, "phone", $event.target.value)
                          }
                        }
                      })
                    ]
                  )
                : _c("span", [_vm._v(_vm._s(_vm.employee.phone))])
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _c("label", [_vm._v("Ext")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "validator" },
            [
              _vm.editMode
                ? _c(
                    "validator",
                    { attrs: { value: _vm.form.phone_ext, reqs: "" } },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.phone_ext,
                            expression: "form.phone_ext"
                          }
                        ],
                        attrs: { type: "text", maxlength: "5" },
                        domProps: { value: _vm.form.phone_ext },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.form, "phone_ext", $event.target.value)
                          }
                        }
                      })
                    ]
                  )
                : _c("span", [_vm._v(_vm._s(_vm.employee.phone_ext))])
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c("portal", { attrs: { to: "actions" } }, [
        _c("ul", [
          _c("li", [
            _c(
              "a",
              {
                attrs: { href: "" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.$emit("update:editMode", true)
                  }
                }
              },
              [_vm._v("Edit")]
            )
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }