var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "static-page privacy-popup" }, [
    _c("h2", [
      _c("strong", [_vm._v(_vm._s(_vm.legal.termsAndConditionsHeader))])
    ]),
    _vm._v(" "),
    _c("p", [
      _vm._v(
        "\n    Last updated: " +
          _vm._s(_vm.legal.termsAndConditionsDate) +
          "\n  "
      )
    ]),
    _vm._v(" "),
    _c("div", {
      domProps: { innerHTML: _vm._s(_vm.legal.termsAndConditionsText) }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }