var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    { staticClass: "form" },
    [
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("label", [_vm._v("Subject:")]),
          _vm._v(" "),
          _c(
            "validator",
            { attrs: { value: _vm.form.subject, reqs: "required" } },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.subject,
                    expression: "form.subject"
                  }
                ],
                staticClass: "w100",
                attrs: { maxlength: "100", disabled: _vm.readonly },
                domProps: { value: _vm.form.subject },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.form, "subject", $event.target.value)
                  }
                }
              })
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "inline-form-group" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("label", [_vm._v("Date:")]),
            _vm._v(" "),
            _c(
              "validator",
              { attrs: { value: _vm.form.date, reqs: "required" } },
              [
                _c("datepicker", {
                  attrs: { type: "text", future: true, disabled: _vm.readonly },
                  model: {
                    value: _vm.form.date,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "date", $$v)
                    },
                    expression: "form.date"
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("label", [_vm._v("Time from:")]),
            _vm._v(" "),
            _c(
              "validator",
              {
                staticClass: "inline-block",
                attrs: { value: _vm.form.time_from_hours, reqs: "required" }
              },
              [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.time_from_hours,
                        expression: "form.time_from_hours"
                      }
                    ],
                    attrs: { disabled: _vm.readonly },
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.form,
                          "time_from_hours",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      }
                    }
                  },
                  _vm._l(12, function(i) {
                    return _c("option", { key: i }, [_vm._v(_vm._s(i))])
                  }),
                  0
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "validator",
              {
                staticClass: "inline-block",
                attrs: { value: _vm.form.time_from_time, reqs: "required" }
              },
              [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.time_from_time,
                        expression: "form.time_from_time"
                      }
                    ],
                    attrs: { disabled: _vm.readonly },
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.form,
                          "time_from_time",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      }
                    }
                  },
                  _vm._l(_vm.timeOptions, function(t) {
                    return _c("option", { key: t, domProps: { value: t } }, [
                      _vm._v(_vm._s(t))
                    ])
                  }),
                  0
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "validator",
              {
                staticClass: "inline-block",
                attrs: { value: _vm.form.time_from_am_pm, reqs: "required" }
              },
              [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.time_from_am_pm,
                        expression: "form.time_from_am_pm"
                      }
                    ],
                    attrs: { disabled: _vm.readonly },
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.form,
                          "time_from_am_pm",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      }
                    }
                  },
                  _vm._l(_vm.amPm, function(type) {
                    return _c(
                      "option",
                      { key: type, domProps: { value: type } },
                      [_vm._v(_vm._s(type))]
                    )
                  }),
                  0
                )
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("label", [_vm._v("Time to")]),
            _vm._v(" "),
            _c(
              "validator",
              {
                staticClass: "inline-block",
                attrs: { value: _vm.form.time_to_hours, reqs: "required" }
              },
              [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.time_to_hours,
                        expression: "form.time_to_hours"
                      }
                    ],
                    attrs: { disabled: _vm.readonly },
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.form,
                          "time_to_hours",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      }
                    }
                  },
                  _vm._l(12, function(i) {
                    return _c("option", { key: i }, [_vm._v(_vm._s(i))])
                  }),
                  0
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "validator",
              {
                staticClass: "inline-block",
                attrs: { value: _vm.form.time_to_time, reqs: "required" }
              },
              [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.time_to_time,
                        expression: "form.time_to_time"
                      }
                    ],
                    attrs: { disabled: _vm.readonly },
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.form,
                          "time_to_time",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      }
                    }
                  },
                  _vm._l(_vm.timeOptions, function(t) {
                    return _c("option", { key: t, domProps: { value: t } }, [
                      _vm._v(_vm._s(t))
                    ])
                  }),
                  0
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "validator",
              {
                staticClass: "inline-block",
                attrs: { value: _vm.form.time_to_am_pm, reqs: "required" }
              },
              [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.time_to_am_pm,
                        expression: "form.time_to_am_pm"
                      }
                    ],
                    attrs: { disabled: _vm.readonly },
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.form,
                          "time_to_am_pm",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      }
                    }
                  },
                  _vm._l(_vm.amPm, function(type) {
                    return _c(
                      "option",
                      { key: type, domProps: { value: type } },
                      [_vm._v(_vm._s(type))]
                    )
                  }),
                  0
                )
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c("label", [_vm._v("Timezone:")]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.timezone,
                  expression: "form.timezone"
                }
              ],
              attrs: { disabled: _vm.readonly },
              on: {
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.form,
                    "timezone",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                }
              }
            },
            _vm._l(_vm.timezones, function(i) {
              return _c("option", { key: i }, [_vm._v(_vm._s(i))])
            }),
            0
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c("label", [_vm._v("Color Code:")]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.color,
                  expression: "form.color"
                }
              ],
              attrs: { disabled: _vm.readonly },
              on: {
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.form,
                    "color",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                }
              }
            },
            _vm._l(_vm.colors, function(color) {
              return _c("option", { key: color }, [_vm._v(_vm._s(color))])
            }),
            0
          )
        ])
      ]),
      _vm._v(" "),
      _vm.isScheduler
        ? _c("tag-employees", {
            ref: "tagEmployees",
            attrs: { readonly: _vm.readonly, "self-remove": _vm.selfTagRemove },
            on: {
              remove: function($event) {
                return _vm.$emit("remove", $event)
              }
            },
            model: {
              value: _vm.selectedEmployees,
              callback: function($$v) {
                _vm.selectedEmployees = $$v
              },
              expression: "selectedEmployees"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c("label", [_vm._v("Note:")]),
        _vm._v(" "),
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.form.note,
              expression: "form.note"
            }
          ],
          attrs: { maxlength: "500", disabled: _vm.readonly },
          domProps: { value: _vm.form.note },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.form, "note", $event.target.value)
            }
          }
        })
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }