var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "feather feather-menu",
      attrs: {
        fill: "none",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        "stroke-width": "2",
        viewBox: "0 0 24 24",
        stroke: _vm.color,
        width: _vm.size + "px",
        height: _vm.size + "px",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("line", { attrs: { x1: "3", x2: "21", y1: "12", y2: "12" } }),
      _vm._v(" "),
      _c("line", { attrs: { x1: "3", x2: "21", y1: "6", y2: "6" } }),
      _vm._v(" "),
      _c("line", { attrs: { x1: "3", x2: "21", y1: "18", y2: "18" } })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }