var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "preform-outer" }, [
      _c("div", { staticClass: "appf preform ncontent" }, [
        _vm.bankingSolution
          ? _c("div", { staticClass: "wcontent" }, [
              _c("h1", [_vm._v("Treasury Enrollment")]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "cb-logo",
                  style: {
                    "background-image":
                      'url("/files/company/' +
                      _vm.bankingSolution.appHeadLogo +
                      '")'
                  }
                },
                [_vm._v("CardBiller")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "form-info" }, [
                _c("p", [_vm._v(_vm._s(_vm.bankingSolution.appHeadText))]),
                _vm._v(" "),
                _c("p", [
                  _c("strong", [
                    _vm._v(_vm._s(_vm.bankingSolution.appHeadEmail))
                  ])
                ]),
                _vm._v(" "),
                _c("p", [
                  _c("strong", [
                    _vm._v(_vm._s(_vm.bankingSolution.appHeadPhone))
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "form-intro" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.bankingSolution.appHeadDesc) +
                    "\n        "
                )
              ])
            ])
          : _vm._e()
      ])
    ]),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "form-box reg-form" },
      [
        true || _vm.system.registration_form
          ? [
              !_vm.submitted
                ? [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              !_vm.internal &&
                              (!_vm.accountCurrency || !_vm.accountType),
                            expression:
                              "!internal && (!accountCurrency || !accountType)"
                          }
                        ],
                        staticClass: "content-form"
                      },
                      [
                        _c("h4", [
                          _vm._v(_vm._s(_vm.$t("register.getting_started")))
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "form-row",
                            class: {
                              "form-split": _vm.hasAccount === null,
                              "form-fullw": _vm.hasAccount !== null
                            }
                          },
                          [
                            _c("form-field-select", {
                              attrs: {
                                label: "Language",
                                required: true,
                                options: { en: "English", es: "Español" },
                                "no-null": ""
                              },
                              model: {
                                value: _vm.$i18n.locale,
                                callback: function($$v) {
                                  _vm.$set(_vm.$i18n, "locale", $$v)
                                },
                                expression: "$i18n.locale"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.hasAccount == "Yes",
                            expression: "hasAccount=='Yes'"
                          }
                        ],
                        staticClass: "content-form"
                      },
                      [
                        _c("p", [
                          _vm._v(_vm._s(_vm.$t("register.yes_description")))
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-row form-center" }, [
                          _c("div", {}, [
                            _c("button", [
                              _c(
                                "a",
                                {
                                  staticClass: "login-link",
                                  attrs: { href: "/login" }
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("register.login_button"))
                                  )
                                ]
                              )
                            ])
                          ])
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.hasAccount == "No",
                            expression: "hasAccount=='No'"
                          }
                        ],
                        staticClass: "content-form"
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "form-row form-fullw" },
                          [
                            _c("form-field-select", {
                              attrs: {
                                label: _vm.$t("register.acc_type_selection"),
                                required: true,
                                options: _vm.businessPersonalOptions
                              },
                              model: {
                                value: _vm.accountType,
                                callback: function($$v) {
                                  _vm.accountType = $$v
                                },
                                expression: "accountType"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.accountCurrency && _vm.accountType && _vm.form
                      ? [
                          _c(
                            "div",
                            { staticClass: "content-form" },
                            [
                              _vm.isPersonal
                                ? _c("personal-form", {
                                    attrs: {
                                      solution: _vm.solution,
                                      v: _vm.$v.form
                                    },
                                    model: {
                                      value: _vm.form,
                                      callback: function($$v) {
                                        _vm.form = $$v
                                      },
                                      expression: "form"
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.isPersonal
                                ? _c("business-form", {
                                    attrs: {
                                      v: _vm.$v.form,
                                      solution: _vm.solution
                                    },
                                    on: {
                                      "add-contact": function($event) {
                                        return _vm.addContact()
                                      },
                                      "remove-contact": function($event) {
                                        return _vm.removeContact()
                                      }
                                    },
                                    model: {
                                      value: _vm.form,
                                      callback: function($$v) {
                                        _vm.form = $$v
                                      },
                                      expression: "form"
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "form-row form-fullw" },
                                [
                                  _c("div", { staticClass: "txtc" }, [
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(_vm.$t("register.agree_terms"))
                                      )
                                    ])
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "form-row form-fullw" },
                                [
                                  _c("div", { staticClass: "autoh" }, [
                                    _c("ul", { staticClass: "cmarks" }, [
                                      _c("li", [
                                        _c(
                                          "label",
                                          {
                                            staticClass: "check",
                                            class: { error: _vm.errors.terms }
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.terms,
                                                  expression: "terms"
                                                }
                                              ],
                                              attrs: { type: "checkbox" },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.terms
                                                )
                                                  ? _vm._i(_vm.terms, null) > -1
                                                  : _vm.terms
                                              },
                                              on: {
                                                change: function($event) {
                                                  var $$a = _vm.terms,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        (_vm.terms = $$a.concat(
                                                          [$$v]
                                                        ))
                                                    } else {
                                                      $$i > -1 &&
                                                        (_vm.terms = $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          ))
                                                    }
                                                  } else {
                                                    _vm.terms = $$c
                                                  }
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("span"),
                                            _vm._v(" "),
                                            _c(
                                              "a",
                                              {
                                                on: {
                                                  click: function($event) {
                                                    $event.preventDefault()
                                                    _vm.termsModal = true
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("register.terms")
                                                  )
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("li", [
                                        _c(
                                          "label",
                                          {
                                            staticClass: "check",
                                            class: {
                                              error: _vm.errors.privacy_policy
                                            }
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.privacy_policy,
                                                  expression: "privacy_policy"
                                                }
                                              ],
                                              attrs: { type: "checkbox" },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.privacy_policy
                                                )
                                                  ? _vm._i(
                                                      _vm.privacy_policy,
                                                      null
                                                    ) > -1
                                                  : _vm.privacy_policy
                                              },
                                              on: {
                                                change: function($event) {
                                                  var $$a = _vm.privacy_policy,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        (_vm.privacy_policy = $$a.concat(
                                                          [$$v]
                                                        ))
                                                    } else {
                                                      $$i > -1 &&
                                                        (_vm.privacy_policy = $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          ))
                                                    }
                                                  } else {
                                                    _vm.privacy_policy = $$c
                                                  }
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("span"),
                                            _vm._v(" "),
                                            _c(
                                              "a",
                                              {
                                                on: {
                                                  click: function($event) {
                                                    $event.preventDefault()
                                                    _vm.privacyModal = true
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("register.privacy")
                                                  )
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ])
                                    ])
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "form-row form-center" },
                                [
                                  _c("div", {}, [
                                    !_vm.fileError
                                      ? _c(
                                          "button",
                                          {
                                            attrs: { disabled: _vm.saving },
                                            on: {
                                              click: function($event) {
                                                return _vm.submit()
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("register.submit_reg")
                                              )
                                            )
                                          ]
                                        )
                                      : _c(
                                          "button",
                                          {
                                            on: {
                                              click: function($event) {
                                                return _vm.retry()
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("register.retry_reg")
                                              )
                                            )
                                          ]
                                        )
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      : _vm._e()
                  ]
                : _c("div", { staticClass: "submitted-note" }, [
                    _vm.success
                      ? _c("div", { staticClass: "success" }, [
                          _vm._v(
                            "\n          Registration Successfully Submitted!\n          "
                          ),
                          _vm.internal
                            ? _c("div", { staticClass: "register-shade" })
                            : _vm._e()
                        ])
                      : _c("div", { staticClass: "denied" }, [
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("register.unable_1")) + " "),
                            _c("b", [_vm._v(_vm._s(_vm.system.support_email))]),
                            _vm._v(
                              " " + _vm._s(_vm.$t("register.unable_2")) + " "
                            ),
                            _c("b", [_vm._v(_vm._s(_vm.system.support_phone))]),
                            _vm._v(
                              " " + _vm._s(_vm.$t("register.unable_3")) + "."
                            )
                          ]),
                          _vm._v(" "),
                          _c("br"),
                          _c("br"),
                          _vm._v(
                            _vm._s(_vm.$t("register.unable_4")) +
                              ", \n          "
                          ),
                          _c("br"),
                          _c("br"),
                          _c("b", [_vm._v(_vm._s(_vm.system.dba_name))])
                        ])
                  ])
            ]
          : _c("h4", { staticClass: "app-page-text" }, [
              _vm._v(_vm._s(_vm.system.disabled_form_text))
            ]),
        _vm._v(" "),
        _c(
          "popup",
          {
            attrs: { open: _vm.termsModal },
            on: {
              "update:open": function($event) {
                _vm.termsModal = $event
              }
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "privacy-popup scrollable",
                on: {
                  "&scroll": function($event) {
                    return _vm.handleScroll.apply(null, arguments)
                  }
                }
              },
              [_c("terms-popup")],
              1
            ),
            _vm._v(" "),
            _vm.submitClicked
              ? _c(
                  "div",
                  { staticClass: "buttons" },
                  [
                    _c(
                      "button",
                      {
                        class: _vm.reachTerms ? "main" : "second",
                        on: {
                          click: function($event) {
                            return _vm.acceptTerms()
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("register.accept_button")))]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "second",
                        on: {
                          click: function($event) {
                            _vm.termsModal = false
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("register.cancel_button")))]
                    ),
                    _vm._v(" "),
                    _vm.showTermsMessage
                      ? [_vm._v(_vm._s(_vm.$t("register.must_complete_terms")))]
                      : _vm._e()
                  ],
                  2
                )
              : _vm._e()
          ]
        ),
        _vm._v(" "),
        _c(
          "popup",
          {
            attrs: { open: _vm.privacyModal },
            on: {
              "update:open": function($event) {
                _vm.privacyModal = $event
              }
            }
          },
          [
            _c(
              "div",
              { staticClass: "privacy-popup scrollable" },
              [_c("privacy-popup")],
              1
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "popup",
          {
            attrs: { open: _vm.fileErrorPopup },
            on: {
              "update:open": function($event) {
                _vm.fileErrorPopup = $event
              }
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "scrollable",
                on: {
                  "&scroll": function($event) {
                    return _vm.handleScroll.apply(null, arguments)
                  }
                }
              },
              [
                _c("div", {}, [
                  _c("h2", [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.$t("register.document_upload_error")))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.$t("register.document_upload_error_text_1")
                        ) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.$t("register.document_upload_error_text_2")
                        ) +
                        "\n          "
                    )
                  ])
                ])
              ]
            )
          ]
        )
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }