var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "popper" },
    [
      _vm.open
        ? _c("Teleport", { attrs: { to: "body" } }, [
            _c(
              "div",
              {
                staticClass: "popper-content",
                style: _vm.positionStyle,
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                  }
                }
              },
              [_vm._t("default")],
              2
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "span",
        {
          ref: "reference",
          on: {
            click: function($event) {
              return _vm.onToggle()
            }
          }
        },
        [_vm._t("reference")],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }