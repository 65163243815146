var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "content-table-container" }, [
      _c("table", { staticClass: "content-table" }, [
        _c("tbody", [
          _c("tr", [
            _c("th", [
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.options.enabledMethods,
                      expression: "options.enabledMethods"
                    }
                  ],
                  attrs: { type: "checkbox", value: "CardBiller" },
                  domProps: {
                    checked: Array.isArray(_vm.options.enabledMethods)
                      ? _vm._i(_vm.options.enabledMethods, "CardBiller") > -1
                      : _vm.options.enabledMethods
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.options.enabledMethods,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = "CardBiller",
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.options,
                              "enabledMethods",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.options,
                              "enabledMethods",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.options, "enabledMethods", $$c)
                      }
                    }
                  }
                }),
                _vm._v("\n            Payment Methods\n          ")
              ])
            ])
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("td", { staticStyle: { "text-align": "left" } }, [
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.options.enabledMethods,
                      expression: "options.enabledMethods"
                    }
                  ],
                  attrs: { type: "checkbox", value: "Visa" },
                  domProps: {
                    checked: Array.isArray(_vm.options.enabledMethods)
                      ? _vm._i(_vm.options.enabledMethods, "Visa") > -1
                      : _vm.options.enabledMethods
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.options.enabledMethods,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = "Visa",
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.options,
                              "enabledMethods",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.options,
                              "enabledMethods",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.options, "enabledMethods", $$c)
                      }
                    }
                  }
                }),
                _vm._v("\n              Visa\n            ")
              ]),
              _vm._v(" "),
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.options.enabledMethods,
                      expression: "options.enabledMethods"
                    }
                  ],
                  attrs: { type: "checkbox", value: "Mastercard" },
                  domProps: {
                    checked: Array.isArray(_vm.options.enabledMethods)
                      ? _vm._i(_vm.options.enabledMethods, "Mastercard") > -1
                      : _vm.options.enabledMethods
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.options.enabledMethods,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = "Mastercard",
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.options,
                              "enabledMethods",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.options,
                              "enabledMethods",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.options, "enabledMethods", $$c)
                      }
                    }
                  }
                }),
                _vm._v("\n              Mastercard\n            ")
              ]),
              _vm._v(" "),
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.options.enabledMethods,
                      expression: "options.enabledMethods"
                    }
                  ],
                  attrs: { type: "checkbox", value: "Discover" },
                  domProps: {
                    checked: Array.isArray(_vm.options.enabledMethods)
                      ? _vm._i(_vm.options.enabledMethods, "Discover") > -1
                      : _vm.options.enabledMethods
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.options.enabledMethods,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = "Discover",
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.options,
                              "enabledMethods",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.options,
                              "enabledMethods",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.options, "enabledMethods", $$c)
                      }
                    }
                  }
                }),
                _vm._v("\n              Discover\n            ")
              ]),
              _vm._v(" "),
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.options.enabledMethods,
                      expression: "options.enabledMethods"
                    }
                  ],
                  attrs: { type: "checkbox", value: "AMEX" },
                  domProps: {
                    checked: Array.isArray(_vm.options.enabledMethods)
                      ? _vm._i(_vm.options.enabledMethods, "AMEX") > -1
                      : _vm.options.enabledMethods
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.options.enabledMethods,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = "AMEX",
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.options,
                              "enabledMethods",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.options,
                              "enabledMethods",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.options, "enabledMethods", $$c)
                      }
                    }
                  }
                }),
                _vm._v("\n              AMEX\n            ")
              ]),
              _vm._v(" "),
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.options.enabledMethods,
                      expression: "options.enabledMethods"
                    }
                  ],
                  attrs: { type: "checkbox", value: "China UnionPay" },
                  domProps: {
                    checked: Array.isArray(_vm.options.enabledMethods)
                      ? _vm._i(_vm.options.enabledMethods, "China UnionPay") >
                        -1
                      : _vm.options.enabledMethods
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.options.enabledMethods,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = "China UnionPay",
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.options,
                              "enabledMethods",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.options,
                              "enabledMethods",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.options, "enabledMethods", $$c)
                      }
                    }
                  }
                }),
                _vm._v("\n              China UnionPay\n            ")
              ]),
              _vm._v(" "),
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.options.enabledMethods,
                      expression: "options.enabledMethods"
                    }
                  ],
                  attrs: { type: "checkbox", value: "Debit" },
                  domProps: {
                    checked: Array.isArray(_vm.options.enabledMethods)
                      ? _vm._i(_vm.options.enabledMethods, "Debit") > -1
                      : _vm.options.enabledMethods
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.options.enabledMethods,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = "Debit",
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.options,
                              "enabledMethods",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.options,
                              "enabledMethods",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.options, "enabledMethods", $$c)
                      }
                    }
                  }
                }),
                _vm._v("\n              Debit\n            ")
              ])
            ])
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", [
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.options.enabledMethods,
                      expression: "options.enabledMethods"
                    }
                  ],
                  attrs: { type: "checkbox", value: "CardBiller Plus" },
                  domProps: {
                    checked: Array.isArray(_vm.options.enabledMethods)
                      ? _vm._i(_vm.options.enabledMethods, "CardBiller Plus") >
                        -1
                      : _vm.options.enabledMethods
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.options.enabledMethods,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = "CardBiller Plus",
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.options,
                              "enabledMethods",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.options,
                              "enabledMethods",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.options, "enabledMethods", $$c)
                      }
                    }
                  }
                }),
                _vm._v("\n            Advanced Payment Methods\n          ")
              ])
            ])
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("td", { staticStyle: { "text-align": "left" } }, [
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.options.enabledMethods,
                      expression: "options.enabledMethods"
                    }
                  ],
                  attrs: { type: "checkbox", value: "Apple Pay" },
                  domProps: {
                    checked: Array.isArray(_vm.options.enabledMethods)
                      ? _vm._i(_vm.options.enabledMethods, "Apple Pay") > -1
                      : _vm.options.enabledMethods
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.options.enabledMethods,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = "Apple Pay",
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.options,
                              "enabledMethods",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.options,
                              "enabledMethods",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.options, "enabledMethods", $$c)
                      }
                    }
                  }
                }),
                _vm._v("\n              Apple Pay\n            ")
              ]),
              _vm._v(" "),
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.options.enabledMethods,
                      expression: "options.enabledMethods"
                    }
                  ],
                  attrs: { type: "checkbox", value: "Google Pay" },
                  domProps: {
                    checked: Array.isArray(_vm.options.enabledMethods)
                      ? _vm._i(_vm.options.enabledMethods, "Google Pay") > -1
                      : _vm.options.enabledMethods
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.options.enabledMethods,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = "Google Pay",
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.options,
                              "enabledMethods",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.options,
                              "enabledMethods",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.options, "enabledMethods", $$c)
                      }
                    }
                  }
                }),
                _vm._v("\n              Google Pay\n            ")
              ]),
              _vm._v(" "),
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.options.enabledMethods,
                      expression: "options.enabledMethods"
                    }
                  ],
                  attrs: { type: "checkbox", value: "Interac Online" },
                  domProps: {
                    checked: Array.isArray(_vm.options.enabledMethods)
                      ? _vm._i(_vm.options.enabledMethods, "Interac Online") >
                        -1
                      : _vm.options.enabledMethods
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.options.enabledMethods,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = "Interac Online",
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.options,
                              "enabledMethods",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.options,
                              "enabledMethods",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.options, "enabledMethods", $$c)
                      }
                    }
                  }
                }),
                _vm._v("\n              Interac Online\n            ")
              ])
            ])
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", [
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.options.enabledMethods,
                      expression: "options.enabledMethods"
                    }
                  ],
                  attrs: { type: "checkbox", value: "BankBiller" },
                  domProps: {
                    checked: Array.isArray(_vm.options.enabledMethods)
                      ? _vm._i(_vm.options.enabledMethods, "BankBiller") > -1
                      : _vm.options.enabledMethods
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.options.enabledMethods,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = "BankBiller",
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.options,
                              "enabledMethods",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.options,
                              "enabledMethods",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.options, "enabledMethods", $$c)
                      }
                    }
                  }
                }),
                _vm._v("\n            Bank Clearing\n          ")
              ])
            ])
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("td", { staticStyle: { "text-align": "left" } }, [
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.options.enabledMethods,
                      expression: "options.enabledMethods"
                    }
                  ],
                  attrs: { type: "checkbox", value: "EFT" },
                  domProps: {
                    checked: Array.isArray(_vm.options.enabledMethods)
                      ? _vm._i(_vm.options.enabledMethods, "EFT") > -1
                      : _vm.options.enabledMethods
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.options.enabledMethods,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = "EFT",
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.options,
                              "enabledMethods",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.options,
                              "enabledMethods",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.options, "enabledMethods", $$c)
                      }
                    }
                  }
                }),
                _vm._v("\n              EFT\n            ")
              ]),
              _vm._v(" "),
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.options.enabledMethods,
                      expression: "options.enabledMethods"
                    }
                  ],
                  attrs: { type: "checkbox", value: "ACH" },
                  domProps: {
                    checked: Array.isArray(_vm.options.enabledMethods)
                      ? _vm._i(_vm.options.enabledMethods, "ACH") > -1
                      : _vm.options.enabledMethods
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.options.enabledMethods,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = "ACH",
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.options,
                              "enabledMethods",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.options,
                              "enabledMethods",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.options, "enabledMethods", $$c)
                      }
                    }
                  }
                }),
                _vm._v("\n              ACH\n            ")
              ])
            ])
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", [
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.options.enabledMethods,
                      expression: "options.enabledMethods"
                    }
                  ],
                  attrs: { type: "checkbox", value: "CoinBiller" },
                  domProps: {
                    checked: Array.isArray(_vm.options.enabledMethods)
                      ? _vm._i(_vm.options.enabledMethods, "CoinBiller") > -1
                      : _vm.options.enabledMethods
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.options.enabledMethods,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = "CoinBiller",
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.options,
                              "enabledMethods",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.options,
                              "enabledMethods",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.options, "enabledMethods", $$c)
                      }
                    }
                  }
                }),
                _vm._v("\n            CoinBiller\n          ")
              ])
            ])
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("td", { staticStyle: { "text-align": "left" } }, [
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.options.enabledMethods,
                      expression: "options.enabledMethods"
                    }
                  ],
                  attrs: { type: "checkbox", value: "Bitcoin" },
                  domProps: {
                    checked: Array.isArray(_vm.options.enabledMethods)
                      ? _vm._i(_vm.options.enabledMethods, "Bitcoin") > -1
                      : _vm.options.enabledMethods
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.options.enabledMethods,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = "Bitcoin",
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.options,
                              "enabledMethods",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.options,
                              "enabledMethods",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.options, "enabledMethods", $$c)
                      }
                    }
                  }
                }),
                _vm._v("\n              Bitcoin\n            ")
              ])
            ])
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    !_vm.hideSubmit
      ? _c("div", { staticClass: "buttons" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-large btn-primary",
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.updateSettings.apply(null, arguments)
                }
              }
            },
            [_vm._v("Submit")]
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }