var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("multiselect", {
    attrs: { options: _vm.dynOptions, hasOther: 0, readonly: _vm.readonly },
    model: {
      value: _vm.dynValue,
      callback: function($$v) {
        _vm.dynValue = $$v
      },
      expression: "dynValue"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }