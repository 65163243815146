var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "multi-dropdown", class: { disabled: _vm.disabled } },
    [
      _c(
        "span",
        {
          staticClass: "value",
          on: {
            click: function($event) {
              _vm.open = !_vm.open
            }
          }
        },
        [_vm._v(_vm._s(_vm.shownValue))]
      ),
      _vm._v(" "),
      _vm.open
        ? _c(
            "ul",
            { ref: "ddlist" },
            _vm._l(_vm.options, function(option) {
              return _c("li", [
                _vm.isMulti
                  ? _c("label", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.componentValue,
                            expression: "componentValue"
                          }
                        ],
                        attrs: { type: "checkbox" },
                        domProps: {
                          value: option.value,
                          checked: Array.isArray(_vm.componentValue)
                            ? _vm._i(_vm.componentValue, option.value) > -1
                            : _vm.componentValue
                        },
                        on: {
                          click: _vm.doSelect,
                          change: function($event) {
                            var $$a = _vm.componentValue,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = option.value,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.componentValue = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.componentValue = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.componentValue = $$c
                            }
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("span"),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(option.label))])
                    ])
                  : _c("label", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.componentValue,
                            expression: "componentValue"
                          }
                        ],
                        attrs: { type: "checkbox" },
                        domProps: {
                          value: option.value,
                          checked: Array.isArray(_vm.componentValue)
                            ? _vm._i(_vm.componentValue, option.value) > -1
                            : _vm.componentValue
                        },
                        on: {
                          click: _vm.doSelect,
                          change: function($event) {
                            var $$a = _vm.componentValue,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = option.value,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.componentValue = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.componentValue = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.componentValue = $$c
                            }
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("span"),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(option.label))])
                    ])
              ])
            }),
            0
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }