var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("amadel-menu", { attrs: { page: _vm.currentPage } }),
      _vm._v(" "),
      _c("div", { staticClass: "ncontent" }, [
        _c("div", { staticClass: "header" }, [
          _c("div", { staticClass: "content-table-container usermgmt" }, [
            _c("table", { staticClass: "content-table usermgmt" }, [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "tbody",
                [
                  _vm._l(_vm.data, function(item, index) {
                    return _c("tr", { key: index }, [
                      _c("td", [_vm._v(_vm._s(item.name))]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(item.sourceId))]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(item.password))]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(item.email))]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(item.phone))]),
                      _vm._v(" "),
                      _c("td", [
                        _c(
                          "span",
                          {
                            staticClass: "text-danger",
                            staticStyle: {
                              "font-size": "17px",
                              cursor: "pointer"
                            },
                            on: {
                              click: function($event) {
                                return _vm.remove(item)
                              }
                            }
                          },
                          [_vm._v("x")]
                        )
                      ])
                    ])
                  }),
                  _vm._v(" "),
                  _vm.showAdd
                    ? _c("tr", [
                        _c(
                          "td",
                          [
                            _c(
                              "validator",
                              {
                                attrs: {
                                  value: _vm.form.name,
                                  reqs: "required"
                                }
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.form.name,
                                      expression: "form.name"
                                    }
                                  ],
                                  attrs: {
                                    type: "text",
                                    placeholder: "Souce Name"
                                  },
                                  domProps: { value: _vm.form.name },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.form,
                                        "name",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ]
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c(
                              "validator",
                              {
                                attrs: {
                                  value: _vm.form.sourceId,
                                  reqs: "required"
                                }
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.form.sourceId,
                                      expression: "form.sourceId"
                                    }
                                  ],
                                  attrs: {
                                    type: "text",
                                    placeholder: "Source ID"
                                  },
                                  domProps: { value: _vm.form.sourceId },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.form,
                                        "sourceId",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ]
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c(
                              "validator",
                              {
                                attrs: {
                                  value: _vm.form.password,
                                  reqs: "required"
                                }
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.form.password,
                                      expression: "form.password"
                                    }
                                  ],
                                  attrs: {
                                    type: "text",
                                    placeholder: "Source Password"
                                  },
                                  domProps: { value: _vm.form.password },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.form,
                                        "password",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ]
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c(
                              "validator",
                              {
                                attrs: {
                                  value: _vm.form.email,
                                  reqs: "required email"
                                }
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.form.email,
                                      expression: "form.email"
                                    }
                                  ],
                                  attrs: {
                                    type: "text",
                                    placeholder: "Contact Email"
                                  },
                                  domProps: { value: _vm.form.email },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.form,
                                        "email",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ]
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c(
                              "validator",
                              {
                                attrs: {
                                  value: _vm.form.phone,
                                  reqs: "required"
                                }
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.form.phone,
                                      expression: "form.phone"
                                    }
                                  ],
                                  attrs: {
                                    type: "text",
                                    placeholder: "Contact Phone"
                                  },
                                  domProps: { value: _vm.form.phone },
                                  on: {
                                    keypress: function($event) {
                                      return _vm.allowLetterNumber($event, {
                                        numbers: true,
                                        letters: false,
                                        allow: [43]
                                      })
                                    },
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.form,
                                        "phone",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ]
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("td", [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-xs",
                              on: {
                                click: function($event) {
                                  return _vm.save()
                                }
                              }
                            },
                            [_vm._v("Save")]
                          )
                        ])
                      ])
                    : _vm._e()
                ],
                2
              )
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "table-add-button",
                on: {
                  click: function($event) {
                    _vm.showAdd = !_vm.showAdd
                  }
                }
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(!_vm.showAdd ? "+" : "-") +
                    "\n        "
                )
              ]
            )
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Souce Name")]),
        _vm._v(" "),
        _c("th", [_vm._v("Source ID")]),
        _vm._v(" "),
        _c("th", [_vm._v("Source Password")]),
        _vm._v(" "),
        _c("th", [_vm._v("Contact Email")]),
        _vm._v(" "),
        _c("th", [_vm._v("Contact Phone")]),
        _vm._v(" "),
        _c("th")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }