var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("amadel-menu", { attrs: { page: _vm.currentPage } }),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-large align-center",
          on: {
            click: function($event) {
              return _vm.$refs.upload.click()
            }
          }
        },
        [_vm._v("Select to Upload")]
      ),
      _vm._v(" "),
      _c("input", {
        directives: [
          { name: "show", rawName: "v-show", value: false, expression: "false" }
        ],
        ref: "upload",
        attrs: { type: "file" },
        on: { change: _vm.uploadFile }
      }),
      _vm._v(" "),
      _vm.errors
        ? _c("div", { staticClass: "ncontent wide data-transfer-validation" }, [
            _c("h3", { staticClass: "error" }, [
              _vm._v("File not accepted. Validation has failed.")
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "info-text text-center" }, [
              _vm._v(
                "Fields marked red are the ones that did not pass our validation."
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "table-wrapper" }, [
              _c("table", { staticClass: "normal-table" }, [
                _c("thead", [
                  _c(
                    "tr",
                    [
                      _c("td", [_vm._v("Row")]),
                      _vm._v(" "),
                      _vm._l(_vm.errors.columns, function(column) {
                        return _c("td", { key: column }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(column) +
                              "\n            "
                          )
                        ])
                      })
                    ],
                    2
                  )
                ]),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(_vm.errors.errors, function(err, idx) {
                    return _c(
                      "tr",
                      { key: idx },
                      [
                        _c("td", [_vm._v(_vm._s(idx))]),
                        _vm._v(" "),
                        _vm._l(_vm.errors.data[idx], function(val, i) {
                          return _c(
                            "td",
                            {
                              key: "td" + i,
                              class: { error: err.includes(i) }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(val) +
                                  "\n            "
                              )
                            ]
                          )
                        })
                      ],
                      2
                    )
                  }),
                  0
                )
              ])
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "info-text" }, [
              _vm._v("Validation Rules:")
            ]),
            _vm._v(" "),
            _c(
              "ul",
              _vm._l(_vm.displayErrors, function(rule) {
                return _c("li", { key: rule.name }, [
                  _vm._v("- " + _vm._s(rule.value))
                ])
              }),
              0
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-xs",
                on: {
                  click: function($event) {
                    _vm.errors = null
                  }
                }
              },
              [_vm._v("I understand")]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "ncontent" }, [
        _c(
          "div",
          { staticClass: "header" },
          [_c("data-transfer-history", { attrs: { data: _vm.data } })],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }