var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ncontent newappsp" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "partner-form" },
      [
        _c(
          "div",
          { staticClass: "partner-item" },
          [
            _c("label", [_vm._v("Solution")]),
            _vm._v(" "),
            _c("dyn-select", {
              attrs: { options: _vm.solutions },
              model: {
                value: _vm.solution,
                callback: function($$v) {
                  _vm.solution = $$v
                },
                expression: "solution"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "partner-item" },
          [
            _c("label", [_vm._v("\n        Form\n      ")]),
            _vm._v(" "),
            _c("dyn-select", {
              attrs: { options: _vm.forms },
              model: {
                value: _vm.form,
                callback: function($$v) {
                  _vm.form = $$v
                },
                expression: "form"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _vm.solution && _vm.form
          ? [
              _c("div", [
                _c("div", { staticStyle: { display: "flex" } }, [
                  _c("div", { staticClass: "partner-item" }, [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-small btn-green",
                        attrs: { href: _vm.link + _vm.solution }
                      },
                      [_vm._v("Start Form")]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      { attrs: { href: _vm.pubLink, target: "_blank" } },
                      [_vm._v(_vm._s(_vm.pubLink))]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "partner-item" }, [
                    !_vm.tempLink
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-small btn-primary",
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.generateTempLink.apply(
                                  null,
                                  arguments
                                )
                              }
                            }
                          },
                          [_vm._v("\n              Get URL\n            ")]
                        )
                      : _c(
                          "button",
                          {
                            staticClass: "btn btn-small btn-primary",
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.doCopy(_vm.tempLink)
                              }
                            }
                          },
                          [_vm._v("Copy URL")]
                        )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "partner-item" },
                    [
                      _c("label", [
                        _vm._v("\n              Send Email\n            ")
                      ]),
                      _vm._v(" "),
                      _c("dyn-select", {
                        attrs: { options: _vm.emailTypes },
                        model: {
                          value: _vm.emailType,
                          callback: function($$v) {
                            _vm.emailType = $$v
                          },
                          expression: "emailType"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "partner-input" }, [
                  _vm.tempLink
                    ? _c("div", [
                        _c("input", {
                          attrs: { type: "text", reaonly: "" },
                          domProps: { value: _vm.tempLink }
                        })
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.emailType == "New Signup"
                    ? _c(
                        "div",
                        { staticClass: "new-setup-email" },
                        [
                          _c("agent-signup", {
                            attrs: { type: "SignUp", solution: _vm.solution }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.emailType == "Signup Reminder"
                    ? _c(
                        "div",
                        { staticClass: "new-setup-email" },
                        [
                          _c("agent-signup", {
                            attrs: {
                              type: "SignUpReminder",
                              solution: _vm.solution
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ])
              ])
            ]
          : _vm._e()
      ],
      2
    ),
    _vm._v(" "),
    _vm._m(1),
    _vm._v(" "),
    _c("div", { staticStyle: { display: "none" } }, [
      _c("div", { staticStyle: { width: "50%", background: "#f0f0f0" } }, [
        _c("div", { staticClass: "header new-app-header" }, [
          _c("div", { staticClass: "toolbar" }, [
            _c(
              "div",
              {
                staticClass: "form-link-btn link-gen-agent",
                class: { generated: _vm.tempLink },
                staticStyle: { width: "100%" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.generateTempLink.apply(null, arguments)
                  }
                }
              },
              [
                _c("strong", [_vm._v("Partner Sign-Up Form")]),
                _vm._v(" "),
                _c("em", [_vm._v("Generate URL")]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                      }
                    }
                  },
                  [
                    _c("input", {
                      attrs: { type: "text", reaonly: "" },
                      domProps: { value: _vm.tempLink }
                    }),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.doCopy(_vm.tempLink)
                          }
                        }
                      },
                      [_vm._v("Copy")]
                    )
                  ]
                )
              ]
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "new-app-split" }, [
          _c(
            "div",
            { staticClass: "new-app-apply", staticStyle: { width: "100%" } },
            [
              _vm._m(2),
              _vm._v(" "),
              _vm.solution
                ? _c(
                    "a",
                    {
                      staticClass: "btn btn-large btn-green",
                      attrs: { href: _vm.link + _vm.solution }
                    },
                    [_vm._v("Start")]
                  )
                : _c(
                    "button",
                    {
                      staticClass: "btn btn-large btn-green",
                      attrs: { disabled: "" }
                    },
                    [_vm._v("Start")]
                  )
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticStyle: {
            width: "50%",
            "box-sizing": "border-box",
            background: "#f0f0f0",
            display: "flex",
            "flex-direction": "column",
            padding: "30px 4.32% 0 0"
          }
        },
        [
          _c(
            "div",
            { staticClass: "new-setup-email" },
            [
              _c("h2", [_vm._v("New Partner Sign-Up")]),
              _vm._v(" "),
              _vm.solution
                ? [
                    _c("agent-signup", {
                      attrs: { type: "SignUp", solution: _vm.solution }
                    })
                  ]
                : [_vm._m(3)]
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "new-setup-email" },
            [
              _c("h2", [_vm._v("Partner Reminder")]),
              _vm._v(" "),
              _vm.solution
                ? [
                    _c("agent-signup", {
                      attrs: { type: "SignUpReminder", solution: _vm.solution }
                    })
                  ]
                : [_vm._m(4)]
            ],
            2
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "new-app-title" }, [
      _c("h1", [_vm._v("New Partner Sign-Up")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "new-app-title" }, [
      _c("p", [
        _vm._v("For assistance or more information, please contact support.")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("h2", [_vm._v("Partner Sign-Up")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("form", [
      _c("input", {
        attrs: {
          required: "",
          type: "email",
          disabled: "",
          placeholder: "email"
        }
      }),
      _vm._v(" "),
      _c("button", { staticClass: "disabled-send", attrs: { disabled: "" } }, [
        _vm._v("Send")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("form", [
      _c("input", {
        attrs: {
          required: "",
          type: "email",
          disabled: "",
          placeholder: "email"
        }
      }),
      _vm._v(" "),
      _c("button", { staticClass: "disabled-send", attrs: { disabled: "" } }, [
        _vm._v("Send")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }