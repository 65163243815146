var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ncontent specpad custom-width" },
    [
      _c("div", { staticClass: "tabs" }, [
        _c(
          "a",
          {
            staticClass: "btn",
            on: {
              click: function($event) {
                $event.preventDefault()
                _vm.currentTab = "reports"
              }
            }
          },
          [_vm._v("Boarding Scan")]
        )
      ]),
      _vm._v(" "),
      _vm.currentTab == "reports"
        ? _c("scan-list-acuant", { attrs: { type: "idm" } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }