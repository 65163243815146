var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "preform-outer" }, [
    _c("div", { staticClass: "preformbg agent" }),
    _vm._v(" "),
    _c("div", { staticClass: "appf preform ncontent pfx" }, [
      _c("div", { staticClass: "wcontent" }, [
        _c(
          "div",
          {
            staticClass: "cb-logo",
            style: {
              "background-image":
                'url("/files/company/' + _vm.companydata.agentHeadLogo + '")'
            }
          },
          [_vm._v("CardBiller")]
        ),
        _vm._v(" "),
        _c("h1", [_vm._v("Partner Sign-Up Form")]),
        _vm._v(" "),
        _c("div", { staticClass: "form-info" }, [
          _c("p", [_vm._v(_vm._s(_vm.companydata.agentHeadText))]),
          _vm._v(" "),
          _c("p", [
            _c("strong", [_vm._v(_vm._s(_vm.companydata.agentHeadEmail))])
          ]),
          _vm._v(" "),
          _c("p", [
            _c("strong", [_vm._v(_vm._s(_vm.companydata.agentHeadPhone))])
          ])
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "form-intro" }, [
          _vm._v("\r\n    " + _vm._s(_vm.companydata.agentHeadDesc) + "\r\n  ")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "temp-link" }, [
          _c("label", [_vm._v("Guest access to this application:")]),
          _vm._v(" "),
          !_vm.link
            ? _c("span", [
                _c(
                  "button",
                  {
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.generateLink.apply(null, arguments)
                      }
                    }
                  },
                  [_vm._v("Generate Link")]
                )
              ])
            : _c(
                "a",
                {
                  attrs: { href: _vm.link.url, target: "_blank" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.copyLink.apply(null, arguments)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.link.url))]
              )
        ]),
        _vm._v(" "),
        _c(
          "ul",
          { staticClass: "pqf-progress four" },
          _vm._l(_vm.steps, function(stepInfo) {
            return _c(
              "li",
              {
                class: {
                  past: _vm.step > stepInfo.index,
                  current: _vm.step == stepInfo.index
                }
              },
              [
                _c("span", [
                  _vm._v(_vm._s(stepInfo.index) + ". " + _vm._s(stepInfo.title))
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "stat ip" }, [_vm._v("In Progress")]),
                _vm._v(" "),
                _c("span", { staticClass: "stat co" }, [_vm._v("Complete")])
              ]
            )
          }),
          0
        ),
        _vm._v(" "),
        _vm.step <= 4
          ? _c("form", { staticClass: "preq-form type2" }, [
              _vm.step == 0
                ? _c(
                    "section",
                    { staticClass: "pqcat-select" },
                    [
                      _c("h3", [
                        _vm._v("To get started, tell us what type of sign-up:")
                      ]),
                      _vm._v(" "),
                      _c(
                        "validator",
                        { attrs: { value: _vm.item.groups, reqs: "required" } },
                        [
                          _c(
                            "ul",
                            { staticClass: "pqcat-list" },
                            _vm._l(_vm.industries, function(ind) {
                              return _c(
                                "li",
                                {
                                  class:
                                    "plain-item " +
                                    (_vm.item.groups == ind.value
                                      ? "selected"
                                      : "")
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "" },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          _vm.item.groups = ind.value
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(ind.label))]
                                  )
                                ]
                              )
                            }),
                            0
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.step == 1
                ? _c(
                    "section",
                    [
                      _vm._m(0),
                      _vm._v(" "),
                      _vm._l(_vm.item.contacts, function(ubo, ui) {
                        return _c("ul", { staticClass: "preform-sp3" }, [
                          _c(
                            "li",
                            [
                              _c("label", [
                                _c("strong", [
                                  _vm._v("Contact " + _vm._s(ui + 1))
                                ]),
                                _vm._v(" First Name")
                              ]),
                              _vm._v(" "),
                              _c(
                                "validator",
                                {
                                  attrs: {
                                    value: ubo.firstName,
                                    reqs: "required city"
                                  }
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: ubo.firstName,
                                        expression: "ubo.firstName"
                                      }
                                    ],
                                    attrs: { type: "text" },
                                    domProps: { value: ubo.firstName },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          ubo,
                                          "firstName",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "li",
                            [
                              _c("label", [
                                _c("strong", [
                                  _vm._v("Contact " + _vm._s(ui + 1))
                                ]),
                                _vm._v(" Last Name")
                              ]),
                              _vm._v(" "),
                              _c(
                                "validator",
                                {
                                  attrs: {
                                    value: ubo.lastName,
                                    reqs: "required city"
                                  }
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: ubo.lastName,
                                        expression: "ubo.lastName"
                                      }
                                    ],
                                    attrs: { type: "text" },
                                    domProps: { value: ubo.lastName },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          ubo,
                                          "lastName",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "li",
                            [
                              _c("label", [
                                _c("strong", [
                                  _vm._v("Contact " + _vm._s(ui + 1))
                                ]),
                                _vm._v(" E-mail")
                              ]),
                              _vm._v(" "),
                              _c(
                                "validator",
                                {
                                  attrs: {
                                    value: ubo.email,
                                    reqs: "required email"
                                  }
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: ubo.email,
                                        expression: "ubo.email"
                                      }
                                    ],
                                    attrs: { type: "text" },
                                    domProps: { value: ubo.email },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          ubo,
                                          "email",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "li",
                            [
                              _c("label", [
                                _c("strong", [
                                  _vm._v("Contact " + _vm._s(ui + 1))
                                ]),
                                _vm._v(" Phone")
                              ]),
                              _vm._v(" "),
                              _c(
                                "validator",
                                {
                                  attrs: {
                                    value: ubo.phone,
                                    reqs: "required phone"
                                  }
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: ubo.phone,
                                        expression: "ubo.phone"
                                      }
                                    ],
                                    attrs: { type: "text" },
                                    domProps: { value: ubo.phone },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          ubo,
                                          "phone",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.item.contacts.length > 1 && ui
                            ? _c("li", { staticClass: "remove" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "remove",
                                    attrs: { href: "" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.removeUbo(ui)
                                      }
                                    }
                                  },
                                  [_vm._v("×")]
                                )
                              ])
                            : _vm._e()
                        ])
                      }),
                      _vm._v(" "),
                      _vm.item.contacts.length < 5
                        ? _c("div", { staticClass: "new-ubo" }, [
                            _c(
                              "a",
                              {
                                attrs: { href: "" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.newUbo.apply(null, arguments)
                                  }
                                }
                              },
                              [_vm._v("Add Additional Contact")]
                            )
                          ])
                        : _vm._e()
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.step == 2 &&
              _vm.item.groups != "agent-accex" &&
              _vm.item.groups != "agent-sales"
                ? _c("section", [
                    _vm._m(1),
                    _vm._v(" "),
                    _c("ul", { staticClass: "preform-sp3" }, [
                      _c(
                        "li",
                        [
                          _c("label", [_vm._v("Legal Business Name")]),
                          _vm._v(" "),
                          _c(
                            "validator",
                            {
                              attrs: {
                                value: _vm.item.agentDb.name,
                                reqs: "required legalName"
                              }
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.item.agentDb.name,
                                    expression: "item.agentDb.name"
                                  }
                                ],
                                attrs: { type: "text" },
                                domProps: { value: _vm.item.agentDb.name },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.item.agentDb,
                                      "name",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        [
                          _c("label", [_vm._v("Trade Name/DBA")]),
                          _vm._v(" "),
                          _c(
                            "validator",
                            {
                              attrs: {
                                value: _vm.item.agentDb.nameTrade,
                                reqs: "legalName"
                              }
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.item.agentDb.nameTrade,
                                    expression: "item.agentDb.nameTrade"
                                  }
                                ],
                                attrs: { type: "text" },
                                domProps: { value: _vm.item.agentDb.nameTrade },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.item.agentDb,
                                      "nameTrade",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        [
                          _c("label", [_vm._v("Type")]),
                          _vm._v(" "),
                          _c(
                            "validator",
                            {
                              attrs: {
                                value: _vm.item.agentDb.nameTrade,
                                reqs: "legalName"
                              }
                            },
                            [
                              _c("combo-select-ext", {
                                attrs: {
                                  options: _vm.tobOptions,
                                  type: "text"
                                },
                                model: {
                                  value: _vm.item.agentDb.companyType,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.item.agentDb,
                                      "companyType",
                                      $$v
                                    )
                                  },
                                  expression: "item.agentDb.companyType"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("ul", { staticClass: "preform-sp3" }, [
                      _c(
                        "li",
                        { staticStyle: { width: "31.3333333%" } },
                        [
                          _c("label", [_vm._v("Country")]),
                          _vm._v(" "),
                          _c(
                            "validator",
                            {
                              attrs: {
                                value: _vm.item.agentDb.country,
                                reqs: "required postcode"
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "combo-select" },
                                [
                                  _c("dyn-select", {
                                    attrs: { options: _vm.validCountries },
                                    model: {
                                      value: _vm.item.agentDb.country,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.item.agentDb,
                                          "country",
                                          $$v
                                        )
                                      },
                                      expression: "item.agentDb.country"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        { staticStyle: { width: "31.3333333%" } },
                        [
                          _c("label", [_vm._v("Address")]),
                          _vm._v(" "),
                          _c(
                            "validator",
                            {
                              attrs: {
                                value: _vm.item.agentDb.address,
                                reqs: "required address"
                              }
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.item.agentDb.address,
                                    expression: "item.agentDb.address"
                                  }
                                ],
                                attrs: { type: "text" },
                                domProps: { value: _vm.item.agentDb.address },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.item.agentDb,
                                      "address",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        { staticStyle: { width: "13.6666666%" } },
                        [
                          _c("label", [_vm._v("City")]),
                          _vm._v(" "),
                          _c(
                            "validator",
                            {
                              attrs: {
                                value: _vm.item.agentDb.city,
                                reqs: "required city"
                              }
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.item.agentDb.city,
                                    expression: "item.agentDb.city"
                                  }
                                ],
                                attrs: { type: "text" },
                                domProps: { value: _vm.item.agentDb.city },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.item.agentDb,
                                      "city",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        { staticStyle: { width: "13.6666666%" } },
                        [
                          _c("label", [_vm._v("Postal/ZIP Code")]),
                          _vm._v(" "),
                          _c(
                            "validator",
                            {
                              attrs: {
                                value: _vm.item.agentDb.zip,
                                reqs: "required postcode"
                              }
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.item.agentDb.zip,
                                    expression: "item.agentDb.zip"
                                  }
                                ],
                                attrs: { type: "text" },
                                domProps: { value: _vm.item.agentDb.zip },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.item.agentDb,
                                      "zip",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          )
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("ul", { staticClass: "preform-sp3" }, [
                      _c(
                        "li",
                        [
                          _c("label", [_vm._v("State/Province")]),
                          _vm._v(" "),
                          _c(
                            "validator",
                            {
                              attrs: {
                                value: _vm.item.agentDb.state,
                                reqs: "required"
                              }
                            },
                            [
                              _vm.validProvinces[_vm.item.agentDb.country]
                                ? _c(
                                    "div",
                                    { staticClass: "combo-select" },
                                    [
                                      _c("dyn-select", {
                                        attrs: {
                                          options:
                                            _vm.validProvinces[
                                              _vm.item.agentDb.country
                                            ]
                                        },
                                        model: {
                                          value: _vm.item.agentDb.state,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.item.agentDb,
                                              "state",
                                              $$v
                                            )
                                          },
                                          expression: "item.agentDb.state"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.item.agentDb.state,
                                        expression: "item.agentDb.state"
                                      }
                                    ],
                                    attrs: { type: "text" },
                                    domProps: { value: _vm.item.agentDb.state },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.item.agentDb,
                                          "state",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        [
                          _c("label", [_vm._v("Phone Number")]),
                          _vm._v(" "),
                          _c(
                            "validator",
                            {
                              attrs: {
                                value: _vm.item.agentDb.phone,
                                reqs: "required phone"
                              }
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.item.agentDb.phone,
                                    expression: "item.agentDb.phone"
                                  }
                                ],
                                attrs: { type: "text" },
                                domProps: { value: _vm.item.agentDb.phone },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.item.agentDb,
                                      "phone",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        [
                          _c("label", [_vm._v("Company Email")]),
                          _vm._v(" "),
                          _c(
                            "validator",
                            {
                              attrs: {
                                value: _vm.item.agentDb.email,
                                reqs: "required email"
                              }
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.item.agentDb.email,
                                    expression: "item.agentDb.email"
                                  }
                                ],
                                attrs: { type: "text" },
                                domProps: { value: _vm.item.agentDb.email },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.item.agentDb,
                                      "email",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          )
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("ul", { staticClass: "preform-sp3" }, [
                      _c(
                        "li",
                        { staticStyle: { width: "100%" } },
                        [
                          _c("label", [_vm._v("Business Website")]),
                          _vm._v(" "),
                          _c(
                            "validator",
                            {
                              staticStyle: { width: "33.333%" },
                              attrs: {
                                value: _vm.item.agentDb.website,
                                reqs: "url"
                              }
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.item.agentDb.website,
                                    expression: "item.agentDb.website"
                                  }
                                ],
                                attrs: { type: "text" },
                                domProps: { value: _vm.item.agentDb.website },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.item.agentDb,
                                      "website",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          )
                        ],
                        1
                      )
                    ])
                  ])
                : _vm.step == 2
                ? _c("section", { staticClass: "shaded" }, [
                    _vm._m(2),
                    _vm._v(" "),
                    _c("ul", { staticClass: "preform-sp3" }, [
                      _c(
                        "li",
                        [
                          _c("label", [_vm._v("Legal Business Name")]),
                          _vm._v(" "),
                          _c(
                            "validator",
                            {
                              attrs: { value: _vm.item.agentDb.name, reqs: "" }
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.item.agentDb.name,
                                    expression: "item.agentDb.name"
                                  }
                                ],
                                attrs: { disabled: "", type: "text" },
                                domProps: { value: _vm.item.agentDb.name },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.item.agentDb,
                                      "name",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        [
                          _c("label", [_vm._v("Trade Name/DBA")]),
                          _vm._v(" "),
                          _c(
                            "validator",
                            {
                              attrs: {
                                value: _vm.item.agentDb.nameTrade,
                                reqs: ""
                              }
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.item.agentDb.nameTrade,
                                    expression: "item.agentDb.nameTrade"
                                  }
                                ],
                                attrs: { disabled: "", type: "text" },
                                domProps: { value: _vm.item.agentDb.nameTrade },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.item.agentDb,
                                      "nameTrade",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        [
                          _c("label", [_vm._v("Type")]),
                          _vm._v(" "),
                          _c(
                            "validator",
                            {
                              attrs: {
                                value: _vm.item.agentDb.nameTrade,
                                reqs: "legalName"
                              }
                            },
                            [
                              _c("combo-select-ext", {
                                attrs: {
                                  options: _vm.tobOptions,
                                  type: "text"
                                },
                                model: {
                                  value: _vm.item.agentDb.companyType,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.item.agentDb,
                                      "companyType",
                                      $$v
                                    )
                                  },
                                  expression: "item.agentDb.companyType"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("ul", { staticClass: "preform-sp3" }, [
                      _c(
                        "li",
                        { staticStyle: { width: "31.3333333%" } },
                        [
                          _c("label", [_vm._v("Address")]),
                          _vm._v(" "),
                          _c(
                            "validator",
                            {
                              attrs: {
                                value: _vm.item.agentDb.address,
                                reqs: ""
                              }
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.item.agentDb.address,
                                    expression: "item.agentDb.address"
                                  }
                                ],
                                attrs: { disabled: "", type: "text" },
                                domProps: { value: _vm.item.agentDb.address },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.item.agentDb,
                                      "address",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        { staticStyle: { width: "13.6666666%" } },
                        [
                          _c("label", [_vm._v("City")]),
                          _vm._v(" "),
                          _c(
                            "validator",
                            {
                              attrs: { value: _vm.item.agentDb.city, reqs: "" }
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.item.agentDb.city,
                                    expression: "item.agentDb.city"
                                  }
                                ],
                                attrs: { disabled: "", type: "text" },
                                domProps: { value: _vm.item.agentDb.city },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.item.agentDb,
                                      "city",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        { staticStyle: { width: "13.6666666%" } },
                        [
                          _c("label", [_vm._v("Postal/ZIP Code")]),
                          _vm._v(" "),
                          _c(
                            "validator",
                            {
                              attrs: { value: _vm.item.agentDb.zip, reqs: "" }
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.item.agentDb.zip,
                                    expression: "item.agentDb.zip"
                                  }
                                ],
                                attrs: { disabled: "", type: "text" },
                                domProps: { value: _vm.item.agentDb.zip },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.item.agentDb,
                                      "zip",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        { staticStyle: { width: "31.3333333%" } },
                        [
                          _c("label", [_vm._v("State/Province")]),
                          _vm._v(" "),
                          _c(
                            "validator",
                            {
                              attrs: { value: _vm.item.agentDb.state, reqs: "" }
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.item.agentDb.state,
                                    expression: "item.agentDb.state"
                                  }
                                ],
                                attrs: { disabled: "", type: "text" },
                                domProps: { value: _vm.item.agentDb.state },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.item.agentDb,
                                      "state",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          )
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("ul", { staticClass: "preform-sp3" }, [
                      _c(
                        "li",
                        [
                          _c("label", [_vm._v("Country")]),
                          _vm._v(" "),
                          _c(
                            "validator",
                            {
                              attrs: {
                                value: _vm.item.agentDb.country,
                                reqs: ""
                              }
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.item.agentDb.country,
                                    expression: "item.agentDb.country"
                                  }
                                ],
                                attrs: { disabled: "", type: "text" },
                                domProps: { value: _vm.item.agentDb.country },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.item.agentDb,
                                      "country",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        [
                          _c("label", [_vm._v("Phone Number")]),
                          _vm._v(" "),
                          _c(
                            "validator",
                            {
                              attrs: { value: _vm.item.agentDb.phone, reqs: "" }
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.item.agentDb.phone,
                                    expression: "item.agentDb.phone"
                                  }
                                ],
                                attrs: { disabled: "", type: "text" },
                                domProps: { value: _vm.item.agentDb.phone },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.item.agentDb,
                                      "phone",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        [
                          _c("label", [_vm._v("Company Email")]),
                          _vm._v(" "),
                          _c(
                            "validator",
                            {
                              attrs: { value: _vm.item.agentDb.email, reqs: "" }
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.item.agentDb.email,
                                    expression: "item.agentDb.email"
                                  }
                                ],
                                attrs: { disabled: "", type: "text" },
                                domProps: { value: _vm.item.agentDb.email },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.item.agentDb,
                                      "email",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          )
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("ul", { staticClass: "preform-sp3" }, [
                      _c(
                        "li",
                        { staticStyle: { width: "100%" } },
                        [
                          _c("label", [_vm._v("Business Website")]),
                          _vm._v(" "),
                          _c(
                            "validator",
                            {
                              staticStyle: { width: "33.333%" },
                              attrs: {
                                value: _vm.item.agentDb.website,
                                reqs: "url"
                              }
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.item.agentDb.website,
                                    expression: "item.agentDb.website"
                                  }
                                ],
                                attrs: { type: "text" },
                                domProps: { value: _vm.item.agentDb.website },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.item.agentDb,
                                      "website",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          )
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "shade" }),
                    _vm._v(" "),
                    _vm._m(3)
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.step == 2
                ? _c(
                    "section",
                    [
                      _vm._m(4),
                      _vm._v(" "),
                      _vm._m(5),
                      _vm._v(" "),
                      _c(
                        "validator",
                        {
                          attrs: {
                            value: _vm.item.agentDb.description,
                            reqs: "char250"
                          }
                        },
                        [
                          _c("div", { staticClass: "single-field" }, [
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.item.agentDb.description,
                                  expression: "item.agentDb.description"
                                }
                              ],
                              staticClass: "more-details",
                              staticStyle: { resize: "none", heigth: "300px" },
                              domProps: { value: _vm.item.agentDb.description },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.item.agentDb,
                                    "description",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _vm._m(6),
                      _vm._v(" "),
                      _c("ul", { staticClass: "preform-sp3" }, [
                        _c("li", { staticStyle: { width: "50%" } }, [
                          _c("div", { staticClass: "payment-checkbox" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.item.agentDb.payments,
                                  expression: "item.agentDb.payments"
                                }
                              ],
                              attrs: {
                                id: "payment_checkbox",
                                type: "checkbox"
                              },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.item.agentDb.payments
                                )
                                  ? _vm._i(_vm.item.agentDb.payments, null) > -1
                                  : _vm.item.agentDb.payments
                              },
                              on: {
                                change: function($event) {
                                  var $$a = _vm.item.agentDb.payments,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.item.agentDb,
                                          "payments",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.item.agentDb,
                                          "payments",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(_vm.item.agentDb, "payments", $$c)
                                  }
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              { attrs: { for: "payment_checkbox" } },
                              [_vm._v("  PAYMENTS")]
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "field" },
                            [
                              _c(
                                "validator",
                                {
                                  staticClass: "payment-multi",
                                  staticStyle: { width: "23%" },
                                  attrs: {
                                    value: "item.agentDb.merchantTypes",
                                    reqs: "required"
                                  }
                                },
                                [
                                  _c("multiselect", {
                                    class: _vm.payment - _vm.multi,
                                    attrs: {
                                      readonly: !_vm.item.agentDb.payments,
                                      hasOther: 0,
                                      options: [
                                        "POS",
                                        "E-Commerce",
                                        "Unattended",
                                        "EFT / ACH",
                                        "Debit Only"
                                      ]
                                    },
                                    model: {
                                      value: _vm.item.agentDb.merchantTypes,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.item.agentDb,
                                          "merchantTypes",
                                          $$v
                                        )
                                      },
                                      expression: "item.agentDb.merchantTypes"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("label", [
                                _vm._v(
                                  "Estimated Merchant Activations Monthly?"
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "validator",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    value: _vm.item.agentDb.appMonthly,
                                    reqs: "required"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "combo-select" },
                                    [
                                      _c("dyn-select", {
                                        attrs: { options: _vm.validMapps },
                                        model: {
                                          value: _vm.item.agentDb.appMonthly,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.item.agentDb,
                                              "appMonthly",
                                              $$v
                                            )
                                          },
                                          expression: "item.agentDb.appMonthly"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("label", [
                                _vm._v("Estimated Monthly Merchant Volume:")
                              ]),
                              _vm._v(" "),
                              _c(
                                "validator",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    value: _vm.item.agentDb.monthlyVolume,
                                    reqs: "required"
                                  }
                                },
                                [
                                  _c("formel-money-amount", {
                                    model: {
                                      value: _vm.item.agentDb.monthlyVolume,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.item.agentDb,
                                          "monthlyVolume",
                                          $$v
                                        )
                                      },
                                      expression: "item.agentDb.monthlyVolume"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", { staticStyle: { width: "50%" } }, [
                          _c("div", { staticClass: "payment-checkbox" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.item.agentDb.treasury,
                                  expression: "item.agentDb.treasury"
                                }
                              ],
                              attrs: {
                                id: "treasury_checkbox",
                                type: "checkbox"
                              },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.item.agentDb.treasury
                                )
                                  ? _vm._i(_vm.item.agentDb.treasury, null) > -1
                                  : _vm.item.agentDb.treasury
                              },
                              on: {
                                change: function($event) {
                                  var $$a = _vm.item.agentDb.treasury,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.item.agentDb,
                                          "treasury",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.item.agentDb,
                                          "treasury",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(_vm.item.agentDb, "treasury", $$c)
                                  }
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              { attrs: { for: "treasury_checkbox" } },
                              [_vm._v("  TREASURY")]
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "field" },
                            [
                              _c(
                                "validator",
                                {
                                  staticClass: "payment-multi",
                                  staticStyle: { width: "30%" },
                                  attrs: {
                                    value: "item.agentDb.treasuryTypes",
                                    reqs: "required"
                                  }
                                },
                                [
                                  _c("multiselect", {
                                    attrs: {
                                      readonly: !_vm.item.agentDb.treasury,
                                      hasOther: 0,
                                      options: [
                                        "Personal Accounts",
                                        "Business Accounts",
                                        "Debit Cards",
                                        "Digital Assets",
                                        "Assets Pay"
                                      ]
                                    },
                                    model: {
                                      value: _vm.item.agentDb.treasuryTypes,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.item.agentDb,
                                          "treasuryTypes",
                                          $$v
                                        )
                                      },
                                      expression: "item.agentDb.treasuryTypes"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("label", [
                                _vm._v("Estimated Accounts Monthly?")
                              ]),
                              _vm._v(" "),
                              _c(
                                "validator",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    value: _vm.item.agentDb.treasuryMonthly,
                                    reqs: "required"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "combo-select" },
                                    [
                                      _c("dyn-select", {
                                        attrs: { options: _vm.validMapps },
                                        model: {
                                          value:
                                            _vm.item.agentDb.treasuryMonthly,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.item.agentDb,
                                              "treasuryMonthly",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "item.agentDb.treasuryMonthly"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("label", [
                                _vm._v("Estimated Account Volume:")
                              ]),
                              _vm._v(" "),
                              _c(
                                "validator",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    value: _vm.item.agentDb.treasuryVolume,
                                    reqs: "required"
                                  }
                                },
                                [
                                  _c("formel-money-amount", {
                                    model: {
                                      value: _vm.item.agentDb.treasuryVolume,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.item.agentDb,
                                          "treasuryVolume",
                                          $$v
                                        )
                                      },
                                      expression: "item.agentDb.treasuryVolume"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ])
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.step == 3
                ? _c(
                    "section",
                    [
                      _vm._m(7),
                      _vm._v(" "),
                      _vm._m(8),
                      _vm._v(" "),
                      _c(
                        "validator",
                        {
                          attrs: {
                            value: _vm.item.agentDb.additionalComments,
                            reqs: "char250"
                          }
                        },
                        [
                          _c("div", { staticClass: "single-field" }, [
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.item.agentDb.additionalComments,
                                  expression: "item.agentDb.additionalComments"
                                }
                              ],
                              staticClass: "more-details",
                              staticStyle: { resize: "none", heigth: "300px" },
                              domProps: {
                                value: _vm.item.agentDb.additionalComments
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.item.agentDb,
                                    "additionalComments",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ])
                        ]
                      )
                    ],
                    1
                  )
                : _vm.step == 4
                ? _c("div", { staticClass: "pre-done" }, [
                    _c("h1", [
                      _vm._v("Thank you for submitting your application!")
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "One of our account Representatives will contact you shortly."
                      )
                    ]),
                    _vm._v(" "),
                    !_vm.guest
                      ? _c(
                          "a",
                          {
                            staticClass: "btn btn-large btn-primary btn-black",
                            attrs: { href: "" }
                          },
                          [_vm._v("Back to Dashboard")]
                        )
                      : _vm._e()
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "buttons" }, [
                _vm.step >= 1 && _vm.step < 4
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-large btn-primary btn-back",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.prevStep.apply(null, arguments)
                          }
                        }
                      },
                      [_vm._v("Previous Step")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.step < 3
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-large btn-primary",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.nextStep.apply(null, arguments)
                          }
                        }
                      },
                      [_vm._v("Next Step")]
                    )
                  : _vm.step == 3
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-large btn-primary",
                        attrs: { disabled: _vm.sending },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.send.apply(null, arguments)
                          }
                        }
                      },
                      [_vm._v("Submit")]
                    )
                  : _vm._e()
              ])
            ])
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", [_c("em", [_vm._v("Contact information")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", [_c("em", [_vm._v("Company Information")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", [_c("em", [_vm._v("Company Information")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "shade-message" }, [
      _c("p", [
        _vm._v(
          "Company information is only completed for ISO, ISA, Authorized Agent, and Referral Partner Sign-Ups"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", [_c("em", [_vm._v("Business Information")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "forta" }, [
      _c("b", [_vm._v("Tell us more about your business")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "preform-sp3" }, [
      _c("li", { staticStyle: { width: "100%" } }, [
        _c("label", [
          _vm._v("Services Requested "),
          _c("span", [_vm._v("(Check all that apply)")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", [_c("em", [_vm._v("Additional Notes")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "forta" }, [
      _c("span", [
        _vm._v("Use this section to include any additional notes or comments")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }