import { render, staticRenderFns } from "./TermsPopupFooter.vue?vue&type=template&id=a6d75c88&scoped=true&"
import script from "./TermsPopupFooter.vue?vue&type=script&lang=js&"
export * from "./TermsPopupFooter.vue?vue&type=script&lang=js&"
import style0 from "./TermsPopupFooter.vue?vue&type=style&index=0&id=a6d75c88&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a6d75c88",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/c6cbhq/assets/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a6d75c88')) {
      api.createRecord('a6d75c88', component.options)
    } else {
      api.reload('a6d75c88', component.options)
    }
    module.hot.accept("./TermsPopupFooter.vue?vue&type=template&id=a6d75c88&scoped=true&", function () {
      api.rerender('a6d75c88', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "js/footer/TermsPopupFooter.vue"
export default component.exports