var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    { staticClass: "edit-system search-form company-branding" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "twocol" }, [
        _c("div", [
          _c("div", { staticClass: "field" }, [
            _c("label", [_vm._v("System Name:")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.systemName,
                  expression: "form.systemName"
                }
              ],
              domProps: { value: _vm.form.systemName },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "systemName", $event.target.value)
                }
              }
            })
          ]),
          _vm._v(" "),
          _c("h1", [_vm._v("HQ Management Portal")]),
          _vm._v(" "),
          _c("div", { staticClass: "field" }, [
            _c("label", [_vm._v("Login URL:")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.loginURL,
                  expression: "form.loginURL"
                }
              ],
              domProps: { value: _vm.form.loginURL },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "loginURL", $event.target.value)
                }
              }
            })
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field" },
            [
              _c("label", [_vm._v("Login Logo:")]),
              _vm._v(" "),
              _c("photo-obj", {
                attrs: { basepath: _vm.basePath },
                model: {
                  value: _vm.files.loginLogo,
                  callback: function($$v) {
                    _vm.$set(_vm.files, "loginLogo", $$v)
                  },
                  expression: "files.loginLogo"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "field" }, [
            _c("label", [_vm._v("Login Text:")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.loginText,
                  expression: "form.loginText"
                }
              ],
              domProps: { value: _vm.form.loginText },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "loginText", $event.target.value)
                }
              }
            })
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field" },
            [
              _c("label", [_vm._v("Main Logo:")]),
              _vm._v(" "),
              _c("photo-obj", {
                attrs: { basepath: _vm.basePath },
                model: {
                  value: _vm.files.logo,
                  callback: function($$v) {
                    _vm.$set(_vm.files, "logo", $$v)
                  },
                  expression: "files.logo"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field" },
            [
              _c("label", [_vm._v("Favicon:")]),
              _vm._v(" "),
              _c("photo-obj", {
                attrs: { basepath: _vm.basePath },
                model: {
                  value: _vm.files.favicon,
                  callback: function($$v) {
                    _vm.$set(_vm.files, "favicon", $$v)
                  },
                  expression: "files.favicon"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", [
          _vm._m(1),
          _vm._v(" "),
          _c("h1", [_vm._v("Footer")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field" },
            [
              _c("label", [_vm._v("Logo:")]),
              _vm._v(" "),
              _c("photo-obj", {
                attrs: { basepath: _vm.basePath },
                model: {
                  value: _vm.files.footerLogo,
                  callback: function($$v) {
                    _vm.$set(_vm.files, "footerLogo", $$v)
                  },
                  expression: "files.footerLogo"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "field" }, [
            _c("label", [_vm._v("Text:")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.footerText,
                  expression: "form.footerText"
                }
              ],
              domProps: { value: _vm.form.footerText },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "footerText", $event.target.value)
                }
              }
            })
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "twocol" }, [
        _c("div", [
          _c("h1", [_vm._v("Partner Portal")]),
          _vm._v(" "),
          _c("div", { staticClass: "field" }, [
            _c("label", [_vm._v("Login URL:")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.partnerLoginURL,
                  expression: "form.partnerLoginURL"
                }
              ],
              domProps: { value: _vm.form.partnerLoginURL },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "partnerLoginURL", $event.target.value)
                }
              }
            })
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field" },
            [
              _c("label", [_vm._v("Login Logo:")]),
              _vm._v(" "),
              _c("photo-obj", {
                attrs: { basepath: _vm.basePath },
                model: {
                  value: _vm.files.partnerLoginLogo,
                  callback: function($$v) {
                    _vm.$set(_vm.files, "partnerLoginLogo", $$v)
                  },
                  expression: "files.partnerLoginLogo"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "field" }, [
            _c("label", [_vm._v("Login Text:")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.partnerLoginText,
                  expression: "form.partnerLoginText"
                }
              ],
              domProps: { value: _vm.form.partnerLoginText },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "partnerLoginText", $event.target.value)
                }
              }
            })
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field" },
            [
              _c("label", [_vm._v("Main Logo:")]),
              _vm._v(" "),
              _c("photo-obj", {
                attrs: { basepath: _vm.basePath },
                model: {
                  value: _vm.files.partnerLogo,
                  callback: function($$v) {
                    _vm.$set(_vm.files, "partnerLogo", $$v)
                  },
                  expression: "files.partnerLogo"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field" },
            [
              _c("label", [_vm._v("Favicon:")]),
              _vm._v(" "),
              _c("photo-obj", {
                attrs: { basepath: _vm.basePath },
                model: {
                  value: _vm.files.partnerFavicon,
                  callback: function($$v) {
                    _vm.$set(_vm.files, "partnerFavicon", $$v)
                  },
                  expression: "files.partnerFavicon"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", [
          _c("h1", [_vm._v("Merchant Portal")]),
          _vm._v(" "),
          _c("div", { staticClass: "field" }, [
            _c("label", [_vm._v("Login URL:")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.merchantLoginURL,
                  expression: "form.merchantLoginURL"
                }
              ],
              domProps: { value: _vm.form.merchantLoginURL },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "merchantLoginURL", $event.target.value)
                }
              }
            })
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field" },
            [
              _c("label", [_vm._v("Login Logo:")]),
              _vm._v(" "),
              _c("photo-obj", {
                attrs: { basepath: _vm.basePath },
                model: {
                  value: _vm.files.merchantLoginLogo,
                  callback: function($$v) {
                    _vm.$set(_vm.files, "merchantLoginLogo", $$v)
                  },
                  expression: "files.merchantLoginLogo"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "field" }, [
            _c("label", [_vm._v("Login Text:")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.merchantLoginText,
                  expression: "form.merchantLoginText"
                }
              ],
              domProps: { value: _vm.form.merchantLoginText },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "merchantLoginText", $event.target.value)
                }
              }
            })
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field" },
            [
              _c("label", [_vm._v("Main Logo:")]),
              _vm._v(" "),
              _c("photo-obj", {
                attrs: { basepath: _vm.basePath },
                model: {
                  value: _vm.files.merchantLogo,
                  callback: function($$v) {
                    _vm.$set(_vm.files, "merchantLogo", $$v)
                  },
                  expression: "files.merchantLogo"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field" },
            [
              _c("label", [_vm._v("Favicon:")]),
              _vm._v(" "),
              _c("photo-obj", {
                attrs: { basepath: _vm.basePath },
                model: {
                  value: _vm.files.merchantFavicon,
                  callback: function($$v) {
                    _vm.$set(_vm.files, "merchantFavicon", $$v)
                  },
                  expression: "files.merchantFavicon"
                }
              })
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "twocol" }, [
        _c("div", [
          _c("h1", [_vm._v("Dashboard Portal")]),
          _vm._v(" "),
          _c("div", { staticClass: "field" }, [
            _c("label", [_vm._v("Login URL:")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.dashboardLoginURL,
                  expression: "form.dashboardLoginURL"
                }
              ],
              domProps: { value: _vm.form.dashboardLoginURL },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "dashboardLoginURL", $event.target.value)
                }
              }
            })
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field" },
            [
              _c("label", [_vm._v("Login Logo:")]),
              _vm._v(" "),
              _c("photo-obj", {
                attrs: { basepath: _vm.basePath },
                model: {
                  value: _vm.files.dashboardLoginLogo,
                  callback: function($$v) {
                    _vm.$set(_vm.files, "dashboardLoginLogo", $$v)
                  },
                  expression: "files.dashboardLoginLogo"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "field" }, [
            _c("label", [_vm._v("Login Text:")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.dashboardLoginText,
                  expression: "form.dashboardLoginText"
                }
              ],
              domProps: { value: _vm.form.dashboardLoginText },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "dashboardLoginText", $event.target.value)
                }
              }
            })
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field" },
            [
              _c("label", [_vm._v("Main Logo:")]),
              _vm._v(" "),
              _c("photo-obj", {
                attrs: { basepath: _vm.basePath },
                model: {
                  value: _vm.files.dashboardLogo,
                  callback: function($$v) {
                    _vm.$set(_vm.files, "dashboardLogo", $$v)
                  },
                  expression: "files.dashboardLogo"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field" },
            [
              _c("label", [_vm._v("Favicon:")]),
              _vm._v(" "),
              _c("photo-obj", {
                attrs: { basepath: _vm.basePath },
                model: {
                  value: _vm.files.dashboardFavicon,
                  callback: function($$v) {
                    _vm.$set(_vm.files, "dashboardFavicon", $$v)
                  },
                  expression: "files.dashboardFavicon"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", [
          _c("h1", [_vm._v("Sandbox")]),
          _vm._v(" "),
          _c("div", { staticClass: "field" }, [
            _c("label", [_vm._v("Login URL:")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.sandboxLoginURL,
                  expression: "form.sandboxLoginURL"
                }
              ],
              domProps: { value: _vm.form.sandboxLoginURL },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "sandboxLoginURL", $event.target.value)
                }
              }
            })
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field" },
            [
              _c("label", [_vm._v("Login Logo:")]),
              _vm._v(" "),
              _c("photo-obj", {
                attrs: { basepath: _vm.basePath },
                model: {
                  value: _vm.files.sandboxLoginLogo,
                  callback: function($$v) {
                    _vm.$set(_vm.files, "sandboxLoginLogo", $$v)
                  },
                  expression: "files.sandboxLoginLogo"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "field" }, [
            _c("label", [_vm._v("Login Text:")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.sandboxLoginText,
                  expression: "form.sandboxLoginText"
                }
              ],
              domProps: { value: _vm.form.sandboxLoginText },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "sandboxLoginText", $event.target.value)
                }
              }
            })
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field" },
            [
              _c("label", [_vm._v("Main Logo:")]),
              _vm._v(" "),
              _c("photo-obj", {
                attrs: { basepath: _vm.basePath },
                model: {
                  value: _vm.files.sandboxLogo,
                  callback: function($$v) {
                    _vm.$set(_vm.files, "sandboxLogo", $$v)
                  },
                  expression: "files.sandboxLogo"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field" },
            [
              _c("label", [_vm._v("Favicon:")]),
              _vm._v(" "),
              _c("photo-obj", {
                attrs: { basepath: _vm.basePath },
                model: {
                  value: _vm.files.sandboxFavicon,
                  callback: function($$v) {
                    _vm.$set(_vm.files, "sandboxFavicon", $$v)
                  },
                  expression: "files.sandboxFavicon"
                }
              })
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "buttons" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-large btn-primary",
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.updateSettings.apply(null, arguments)
              }
            }
          },
          [_vm._v("Submit")]
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("ul", { staticClass: "breadcrumbs" }, [
        _c("li", [_c("a", [_vm._v("Organization Branding")])])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field" }, [_c("label", [_vm._v("  ")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }