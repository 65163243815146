var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "popup", class: { shown: _vm.visible } }, [
    _c("div", { staticClass: "pop-med" }, [
      _c("div", { staticClass: "pop-inner" }, [
        _c("a", {
          staticClass: "close",
          attrs: { href: "" },
          on: {
            click: function($event) {
              $event.preventDefault()
              _vm.visible = false
            }
          }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "text-to-copy" }, [
          _c("span", { on: { click: _vm.doCopy } }, [_vm._v(_vm._s(_vm.text))]),
          _vm._v(" "),
          _c("button", { on: { click: _vm.doCopy } }, [_vm._v("Copy")])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }