var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ncontent nc-flat" },
    [
      _c(
        "tabs",
        { staticClass: "tabs" },
        [
          _c("tab", { attrs: { title: "New Enrollment", active: "" } }),
          _vm._v(" "),
          _c("tab", {
            attrs: { title: "Merchants Overview" },
            on: {
              click: function($event) {
                return _vm.openLink("/applications")
              }
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "ncontent newappsp marginless" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "partner-form" },
          [
            _c(
              "div",
              { staticClass: "partner-item" },
              [
                _c("label", [_vm._v("Solution")]),
                _vm._v(" "),
                _c("dyn-select", {
                  attrs: { options: _vm.solutions },
                  model: {
                    value: _vm.type,
                    callback: function($$v) {
                      _vm.type = $$v
                    },
                    expression: "type"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "partner-item" },
              [
                _c("label", [_vm._v(" Form ")]),
                _vm._v(" "),
                _c("dyn-select", {
                  attrs: { disabled: !_vm.type, options: _vm.forms },
                  model: {
                    value: _vm.form,
                    callback: function($$v) {
                      _vm.form = $$v
                    },
                    expression: "form"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _vm.type && _vm.form
              ? [
                  _vm.form == "Merchant Qualification"
                    ? _c("div", [
                        _c("div", { staticStyle: { display: "flex" } }, [
                          _c("div", { staticClass: "partner-item" }, [
                            _c(
                              "a",
                              {
                                staticClass: "btn btn-small btn-green",
                                attrs: { href: _vm.linkPre + _vm.type }
                              },
                              [_vm._v("Start Qualification")]
                            ),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                attrs: { href: _vm.pubLink, target: "_blank" }
                              },
                              [_vm._v(_vm._s(_vm.pubLink))]
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "partner-item" }, [
                            !_vm.links.Prequalification
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-small btn-primary",
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.generateTempLink(
                                          "Prequalification"
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                Get URL\n              "
                                    )
                                  ]
                                )
                              : _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-small btn-primary",
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.doCopy(
                                          _vm.links.Prequalification
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                Copy URL\n              "
                                    )
                                  ]
                                )
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "partner-item" },
                            [
                              _c("label", [_vm._v(" Send Email ")]),
                              _vm._v(" "),
                              _c("dyn-select", {
                                attrs: { options: _vm.emailTypes },
                                model: {
                                  value: _vm.emailType,
                                  callback: function($$v) {
                                    _vm.emailType = $$v
                                  },
                                  expression: "emailType"
                                }
                              })
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "partner-input" }, [
                          _vm.links.Prequalification
                            ? _c("div", [
                                _c("input", {
                                  attrs: { type: "text", reaonly: "" },
                                  domProps: {
                                    value: _vm.links.Prequalification
                                  }
                                })
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.emailType == "New Signup"
                            ? _c(
                                "div",
                                { staticClass: "form-link-ext" },
                                [
                                  _c("application-signup", {
                                    attrs: {
                                      disabled: !_vm.type,
                                      type: "Prequalification",
                                      solutions: _vm.solutions,
                                      solution: _vm.type
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.form == "Merchant Application"
                    ? _c("div", [
                        _c("div", { staticStyle: { display: "flex" } }, [
                          _c("div", { staticClass: "partner-item" }, [
                            _c(
                              "a",
                              {
                                staticClass: "btn btn-small btn-green",
                                attrs: { href: _vm.linkApp + _vm.type }
                              },
                              [_vm._v("Start Application")]
                            ),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                attrs: { href: _vm.pubLink, target: "_blank" }
                              },
                              [_vm._v(_vm._s(_vm.pubLink))]
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "partner-item" }, [
                            !_vm.links.Application
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-small btn-primary",
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.generateTempLink(
                                          "Application"
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                Get URL\n              "
                                    )
                                  ]
                                )
                              : _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-small btn-primary",
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.doCopy(_vm.links.Application)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                Copy URL\n              "
                                    )
                                  ]
                                )
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "partner-item" },
                            [
                              _c("label", [_vm._v(" Send Email ")]),
                              _vm._v(" "),
                              _c("dyn-select", {
                                attrs: { options: _vm.emailTypes },
                                model: {
                                  value: _vm.emailType,
                                  callback: function($$v) {
                                    _vm.emailType = $$v
                                  },
                                  expression: "emailType"
                                }
                              })
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "partner-input" }, [
                          _vm.links.Application
                            ? _c("div", [
                                _c("input", {
                                  attrs: { type: "text", reaonly: "" },
                                  domProps: { value: _vm.links.Application }
                                })
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.emailType == "New Signup"
                            ? _c(
                                "div",
                                { staticClass: "form-link-ext" },
                                [
                                  _c("application-signup", {
                                    attrs: {
                                      disabled: !_vm.type,
                                      type: "Application",
                                      solutions: _vm.solutions,
                                      solution: _vm.type
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.form == "Facilitator Application"
                    ? _c("div", [
                        _c("div", { staticStyle: { display: "flex" } }, [
                          _c("div", { staticClass: "partner-item" }, [
                            _c(
                              "a",
                              {
                                staticClass: "btn btn-small btn-green",
                                attrs: { href: _vm.linkFac + _vm.type }
                              },
                              [_vm._v("Start Application")]
                            ),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                attrs: { href: _vm.pubLink, target: "_blank" }
                              },
                              [_vm._v(_vm._s(_vm.pubLink))]
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "partner-item" }, [
                            !_vm.links.Facilitator
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-small btn-primary",
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.generateTempLink(
                                          "Facilitator"
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                Get URL\n              "
                                    )
                                  ]
                                )
                              : _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-small btn-primary",
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.doCopy(_vm.links.Facilitator)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                Copy URL\n              "
                                    )
                                  ]
                                )
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "partner-item" },
                            [
                              _c("label", [_vm._v(" Send Email ")]),
                              _vm._v(" "),
                              _c("dyn-select", {
                                attrs: { options: _vm.emailTypes },
                                model: {
                                  value: _vm.emailType,
                                  callback: function($$v) {
                                    _vm.emailType = $$v
                                  },
                                  expression: "emailType"
                                }
                              })
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "partner-input" }, [
                          _vm.links.Facilitator
                            ? _c("div", [
                                _c("input", {
                                  attrs: { type: "text", reaonly: "" },
                                  domProps: { value: _vm.links.Facilitator }
                                })
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.emailType == "New Signup"
                            ? _c(
                                "div",
                                { staticClass: "form-link-ext" },
                                [
                                  _c("application-signup", {
                                    attrs: {
                                      disabled: !_vm.type,
                                      type: "Facilitator",
                                      solutions: _vm.solutions,
                                      solution: _vm.type
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ])
                      ])
                    : _vm._e()
                ]
              : _vm._e()
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "header new-app-header",
            staticStyle: { display: "none" }
          },
          [
            _c(
              "div",
              {
                staticClass: "toolbar",
                class: { "two-way-split": _vm.isAgent }
              },
              [
                _c("div", [
                  _c(
                    "div",
                    {
                      staticClass: "form-link-btn link-gen-preq",
                      class: { generated: _vm.links.Prequalification },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.generateTempLink("Prequalification")
                        }
                      }
                    },
                    [
                      _c("strong", [_vm._v("Prequalification Form")]),
                      _vm._v(" "),
                      _c("em", [_vm._v("Generate URL")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                            }
                          }
                        },
                        [
                          _c("input", {
                            attrs: { type: "text", readonly: "" },
                            domProps: { value: _vm.links.Prequalification }
                          }),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.doCopy(_vm.links.Prequalification)
                                }
                              }
                            },
                            [_vm._v("\n                Copy\n              ")]
                          )
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-link-ext" },
                    [
                      _c("application-signup", {
                        attrs: {
                          disabled: !_vm.type,
                          type: "Prequalification",
                          solutions: _vm.solutions,
                          solution: _vm.type
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "div",
                    {
                      staticClass: "form-link-btn link-gen-app",
                      class: { generated: _vm.links.Application },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.generateTempLink("Application")
                        }
                      }
                    },
                    [
                      _c("strong", [_vm._v("Merchant Application Form")]),
                      _vm._v(" "),
                      _c("em", [_vm._v("Generate URL")]),
                      _vm._v(" "),
                      _c("div", [
                        _c("input", {
                          attrs: { type: "text", readonly: "" },
                          domProps: { value: _vm.links.Application }
                        }),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.doCopy(_vm.links.Application)
                              }
                            }
                          },
                          [_vm._v("\n                Copy\n              ")]
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-link-ext" },
                    [
                      _c("application-signup", {
                        attrs: {
                          disabled: !_vm.type,
                          type: "Application",
                          solutions: _vm.solutions,
                          solution: _vm.type
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                !_vm.isAgent
                  ? [
                      _c("div", [
                        _c(
                          "div",
                          {
                            staticClass: "form-link-btn link-gen-app",
                            class: { generated: _vm.links.Facilitator },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.generateTempLink("Facilitator")
                              }
                            }
                          },
                          [
                            _c("strong", [
                              _vm._v("Facilitator Application Form")
                            ]),
                            _vm._v(" "),
                            _c("em", [_vm._v("Generate URL")]),
                            _vm._v(" "),
                            _c("div", [
                              _c("input", {
                                attrs: { type: "text", readonly: "" },
                                domProps: { value: _vm.links.Facilitator }
                              }),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.doCopy(_vm.links.Facilitator)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                  Copy\n                "
                                  )
                                ]
                              )
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "form-link-ext" },
                          [
                            _c("application-signup", {
                              attrs: {
                                disabled: !_vm.type,
                                type: "Facilitator",
                                solutions: _vm.solutions,
                                solution: _vm.type
                              }
                            })
                          ],
                          1
                        )
                      ])
                    ]
                  : _vm._e()
              ],
              2
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "new-app-split",
            class: { "two-way-split": _vm.isAgent },
            staticStyle: { display: "none" }
          },
          [
            _c("div", { staticClass: "new-app-pre" }, [
              _c("div", [
                _c("h2", [_vm._v("Merchant Qualification")]),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: false,
                        expression: "false"
                      }
                    ],
                    staticClass: "merchant-app-desc"
                  },
                  [
                    _vm._v(
                      "\n            Please select type of merchant qualification:\n          "
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _vm.type
                ? _c(
                    "a",
                    {
                      staticClass: "btn btn-large ch-button btn-success",
                      attrs: { href: _vm.linkPre + _vm.type }
                    },
                    [_vm._v("Start Qualification")]
                  )
                : _c(
                    "button",
                    {
                      staticClass: "btn btn-large ch-button btn-success",
                      attrs: { disabled: "" }
                    },
                    [_vm._v("\n          Start Qualification\n        ")]
                  )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "new-app-apply" }, [
              _c(
                "div",
                [
                  _c("h2", [_vm._v("Merchant Application")]),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: false,
                          expression: "false"
                        }
                      ],
                      staticClass: "merchant-app-desc"
                    },
                    [
                      _vm._v(
                        "\n            Please select type of merchant application:\n          "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("dyn-select", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: false,
                        expression: "false"
                      }
                    ],
                    attrs: { options: _vm.solutions },
                    model: {
                      value: _vm.type,
                      callback: function($$v) {
                        _vm.type = $$v
                      },
                      expression: "type"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm.type
                ? _c(
                    "a",
                    {
                      staticClass: "btn btn-large ch-button btn-success",
                      attrs: { href: _vm.linkApp + _vm.type }
                    },
                    [_vm._v("Start Application")]
                  )
                : _c(
                    "button",
                    {
                      staticClass: "btn btn-large ch-button btn-success",
                      attrs: { disabled: "" }
                    },
                    [_vm._v("\n          Start Application\n        ")]
                  )
            ]),
            _vm._v(" "),
            !_vm.isAgent
              ? [
                  _c("div", { staticClass: "new-app-apply" }, [
                    _c(
                      "div",
                      [
                        _c("h2", [_vm._v("Facilitator Application")]),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: false,
                                expression: "false"
                              }
                            ],
                            staticClass: "merchant-app-desc"
                          },
                          [
                            _vm._v(
                              "\n              Please select type of merchant application:\n            "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("dyn-select", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: false,
                              expression: "false"
                            }
                          ],
                          attrs: { options: _vm.solutions },
                          model: {
                            value: _vm.type,
                            callback: function($$v) {
                              _vm.type = $$v
                            },
                            expression: "type"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.type
                      ? _c(
                          "a",
                          {
                            staticClass: "btn btn-large btn-green",
                            attrs: { href: _vm.linkFac + _vm.type }
                          },
                          [_vm._v("Start Application")]
                        )
                      : _c(
                          "button",
                          {
                            staticClass: "btn btn-large btn-green",
                            attrs: { disabled: "" }
                          },
                          [
                            _vm._v(
                              "\n            Start Application\n          "
                            )
                          ]
                        )
                  ])
                ]
              : _vm._e()
          ],
          2
        ),
        _vm._v(" "),
        _c("div", { staticClass: "new-application-note" }, [
          _c("p", [
            _vm._v(
              "\n        For assistance or more information, please contact\n        "
            ),
            _c("b", [_vm._v(_vm._s(_vm.company.applicationEmail))]),
            _vm._v(" or\n        "),
            _c("b", [_vm._v(_vm._s(_vm.company.servicesTel))]),
            _vm._v(". "),
            _c("br"),
            _vm._v(
              "\n        You may also contact your Relationship Manager or Account Manager for\n        assistance.\n      "
            )
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "new-app-title" }, [
      _c("h1", [_vm._v("New Enrollment")]),
      _vm._v(" "),
      _c("p", [_vm._v("What type of enrollment do you want to complete?")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }