import { render, staticRenderFns } from "./support-filters.vue?vue&type=template&id=588448f6&scoped=true&"
import script from "./support-filters.vue?vue&type=script&lang=js&"
export * from "./support-filters.vue?vue&type=script&lang=js&"
import style0 from "./support-filters.vue?vue&type=style&index=0&id=588448f6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "588448f6",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/c6cbhq/assets/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('588448f6')) {
      api.createRecord('588448f6', component.options)
    } else {
      api.reload('588448f6', component.options)
    }
    module.hot.accept("./support-filters.vue?vue&type=template&id=588448f6&scoped=true&", function () {
      api.rerender('588448f6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "js/support/support-filters.vue"
export default component.exports