var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "search-form", staticStyle: { "margin-bottom": "10px" } },
      [
        _c("datepicker", {
          attrs: { type: "text", future: false },
          model: {
            value: _vm.dateStart,
            callback: function($$v) {
              _vm.dateStart = $$v
            },
            expression: "dateStart"
          }
        }),
        _vm._v(" "),
        _c("datepicker", {
          attrs: { type: "text", future: false },
          model: {
            value: _vm.dateEnd,
            callback: function($$v) {
              _vm.dateEnd = $$v
            },
            expression: "dateEnd"
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "content-table-container usermgmt" }, [
      _c("table", { staticClass: "content-table usermgmt" }, [
        _c("thead", [
          _c("tr", [
            _c(
              "th",
              {
                staticStyle: { width: "25%", cursor: "pointer" },
                on: {
                  click: function($event) {
                    _vm.sortColumn = null
                  }
                }
              },
              [_vm._v("Username")]
            ),
            _vm._v(" "),
            _c(
              "th",
              {
                staticStyle: { width: "25%", cursor: "pointer" },
                on: {
                  click: function($event) {
                    _vm.sortColumn = "recorded"
                  }
                }
              },
              [_vm._v("Time")]
            ),
            _vm._v(" "),
            _c(
              "th",
              {
                staticStyle: { width: "25%", cursor: "pointer" },
                on: {
                  click: function($event) {
                    _vm.sortColumn = "transferred"
                  }
                }
              },
              [_vm._v("Data transfer")]
            ),
            _vm._v(" "),
            _c(
              "th",
              {
                staticStyle: { width: "25%", cursor: "pointer" },
                on: {
                  click: function($event) {
                    _vm.sortColumn = "ipAddress"
                  }
                }
              },
              [_vm._v("IP Address")]
            )
          ])
        ]),
        _vm._v(" "),
        _c(
          "tbody",
          _vm._l(_vm.sortedLogins, function(item, index) {
            return _c("tr", [
              _c("td", { staticClass: "main-col" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm.users[item.userId] && _vm.users[item.userId].username
                    ) +
                    "\n          "
                )
              ]),
              _vm._v(" "),
              _c("td", {}, [
                _vm._v(
                  "\n            " + _vm._s(item.recorded) + "\n          "
                )
              ]),
              _vm._v(" "),
              _c("td", {}, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.formatData(item.transferred)) +
                    "\n          "
                )
              ]),
              _vm._v(" "),
              _c("td", {}, [
                _vm._v(
                  "\n            " + _vm._s(item.ipAddress) + "\n          "
                )
              ])
            ])
          }),
          0
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }