var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    { staticClass: "edit-system search-form system-information" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "twocol" }, [
        _c("div", [
          _c(
            "div",
            { staticClass: "field" },
            [
              _c("label", [_vm._v("PIN:")]),
              _vm._v(" "),
              _c(
                "validator",
                { attrs: { value: _vm.opts.pin, reqs: "required" } },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.opts.pin,
                        expression: "opts.pin"
                      }
                    ],
                    attrs: { readonly: _vm.readonly, disabled: !_vm.editing },
                    domProps: { value: _vm.opts.pin },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.opts, "pin", $event.target.value)
                      }
                    }
                  })
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field" },
            [
              _c("label", [_vm._v("Name:")]),
              _vm._v(" "),
              _c(
                "validator",
                { attrs: { value: _vm.form.companyName, reqs: "required" } },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.companyName,
                        expression: "form.companyName"
                      }
                    ],
                    attrs: { readonly: _vm.readonly, disabled: !_vm.editing },
                    domProps: { value: _vm.form.companyName },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.form, "companyName", $event.target.value)
                      }
                    }
                  })
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field" },
            [
              _c("label", [_vm._v("DBA Name:")]),
              _vm._v(" "),
              _c(
                "validator",
                { attrs: { value: _vm.form.companyDba, reqs: "required" } },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.companyDba,
                        expression: "form.companyDba"
                      }
                    ],
                    attrs: { readonly: _vm.readonly, disabled: !_vm.editing },
                    domProps: { value: _vm.form.companyDba },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.form, "companyDba", $event.target.value)
                      }
                    }
                  })
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field" },
            [
              _c("label", [_vm._v("Address:")]),
              _vm._v(" "),
              _c(
                "validator",
                { attrs: { value: _vm.form.companyAddress, reqs: "required" } },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.companyAddress,
                        expression: "form.companyAddress"
                      }
                    ],
                    attrs: { readonly: _vm.readonly, disabled: !_vm.editing },
                    domProps: { value: _vm.form.companyAddress },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.form,
                          "companyAddress",
                          $event.target.value
                        )
                      }
                    }
                  })
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field" },
            [
              _c("label", [_vm._v("City:")]),
              _vm._v(" "),
              _c(
                "validator",
                { attrs: { value: _vm.form.companyCity, reqs: "required" } },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.companyCity,
                        expression: "form.companyCity"
                      }
                    ],
                    attrs: { readonly: _vm.readonly, disabled: !_vm.editing },
                    domProps: { value: _vm.form.companyCity },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.form, "companyCity", $event.target.value)
                      }
                    }
                  })
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field" },
            [
              _c("label", [_vm._v("State:")]),
              _vm._v(" "),
              _c(
                "validator",
                { attrs: { value: _vm.form.companyState, reqs: "required" } },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.companyState,
                        expression: "form.companyState"
                      }
                    ],
                    attrs: { readonly: _vm.readonly, disabled: !_vm.editing },
                    domProps: { value: _vm.form.companyState },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.form, "companyState", $event.target.value)
                      }
                    }
                  })
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field" },
            [
              _c("label", [_vm._v("Zip/Postal Code:")]),
              _vm._v(" "),
              _c(
                "validator",
                { attrs: { value: _vm.form.companyPostal, reqs: "required" } },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.companyPostal,
                        expression: "form.companyPostal"
                      }
                    ],
                    attrs: { readonly: _vm.readonly, disabled: !_vm.editing },
                    domProps: { value: _vm.form.companyPostal },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.form, "companyPostal", $event.target.value)
                      }
                    }
                  })
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field" },
            [
              _c("label", [_vm._v("Country:")]),
              _vm._v(" "),
              _c(
                "validator",
                { attrs: { value: _vm.form.companyCountry, reqs: "required" } },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.companyCountry,
                        expression: "form.companyCountry"
                      }
                    ],
                    attrs: { readonly: _vm.readonly, disabled: !_vm.editing },
                    domProps: { value: _vm.form.companyCountry },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.form,
                          "companyCountry",
                          $event.target.value
                        )
                      }
                    }
                  })
                ]
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", [
          _c(
            "div",
            { staticClass: "field" },
            [
              _c("label", [_vm._v("Email:")]),
              _vm._v(" "),
              _c(
                "validator",
                { attrs: { value: _vm.form.companyEmail, reqs: "required" } },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.companyEmail,
                        expression: "form.companyEmail"
                      }
                    ],
                    attrs: { readonly: _vm.readonly, disabled: !_vm.editing },
                    domProps: { value: _vm.form.companyEmail },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.form, "companyEmail", $event.target.value)
                      }
                    }
                  })
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field" },
            [
              _c("label", [_vm._v("Phone:")]),
              _vm._v(" "),
              _c(
                "validator",
                { attrs: { value: _vm.form.companyPhone, reqs: "required" } },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.companyPhone,
                        expression: "form.companyPhone"
                      }
                    ],
                    attrs: { readonly: _vm.readonly, disabled: !_vm.editing },
                    domProps: { value: _vm.form.companyPhone },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.form, "companyPhone", $event.target.value)
                      }
                    }
                  })
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field" },
            [
              _c("label", [_vm._v("Website:")]),
              _vm._v(" "),
              _c(
                "validator",
                { attrs: { value: _vm.form.companyWebsite, reqs: "required" } },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.companyWebsite,
                        expression: "form.companyWebsite"
                      }
                    ],
                    attrs: { readonly: _vm.readonly, disabled: !_vm.editing },
                    domProps: { value: _vm.form.companyWebsite },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.form,
                          "companyWebsite",
                          $event.target.value
                        )
                      }
                    }
                  })
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field" },
            [
              _c("label", [_vm._v("Helpdesk Phone:")]),
              _vm._v(" "),
              _c(
                "validator",
                { attrs: { value: _vm.form.helpdeskPhone, reqs: "required" } },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.helpdeskPhone,
                        expression: "form.helpdeskPhone"
                      }
                    ],
                    attrs: { readonly: _vm.readonly, disabled: !_vm.editing },
                    domProps: { value: _vm.form.helpdeskPhone },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.form, "helpdeskPhone", $event.target.value)
                      }
                    }
                  })
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field" },
            [
              _c("label", [_vm._v("Helpdesk Email:")]),
              _vm._v(" "),
              _c(
                "validator",
                { attrs: { value: _vm.form.helpdeskEmail, reqs: "required" } },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.helpdeskEmail,
                        expression: "form.helpdeskEmail"
                      }
                    ],
                    attrs: { readonly: _vm.readonly, disabled: !_vm.editing },
                    domProps: { value: _vm.form.helpdeskEmail },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.form, "helpdeskEmail", $event.target.value)
                      }
                    }
                  })
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field" },
            [
              _c("label", [_vm._v("Representative Name:")]),
              _vm._v(" "),
              _c(
                "validator",
                {
                  attrs: {
                    value: _vm.form.representativeName,
                    reqs: "required"
                  }
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.representativeName,
                        expression: "form.representativeName"
                      }
                    ],
                    attrs: { readonly: _vm.readonly, disabled: !_vm.editing },
                    domProps: { value: _vm.form.representativeName },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.form,
                          "representativeName",
                          $event.target.value
                        )
                      }
                    }
                  })
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field" },
            [
              _c("label", [_vm._v("Representative Title:")]),
              _vm._v(" "),
              _c(
                "validator",
                {
                  attrs: {
                    value: _vm.form.representativeTitle,
                    reqs: "required"
                  }
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.representativeTitle,
                        expression: "form.representativeTitle"
                      }
                    ],
                    attrs: { readonly: _vm.readonly, disabled: !_vm.editing },
                    domProps: { value: _vm.form.representativeTitle },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.form,
                          "representativeTitle",
                          $event.target.value
                        )
                      }
                    }
                  })
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field" },
            [
              _c("label", [_vm._v("Representative Signature:")]),
              _vm._v(" "),
              _vm.readonly
                ? [
                    _c("img", {
                      staticClass: "representative-signature",
                      attrs: {
                        src:
                          _vm.basePath + "/" + _vm.form.representativeSignature
                      }
                    })
                  ]
                : _c("photo-obj", {
                    attrs: { basepath: _vm.basePath, disabled: !_vm.editing },
                    model: {
                      value: _vm.files.representativeSignature,
                      callback: function($$v) {
                        _vm.$set(_vm.files, "representativeSignature", $$v)
                      },
                      expression: "files.representativeSignature"
                    }
                  })
            ],
            2
          )
        ])
      ]),
      _vm._v(" "),
      !_vm.readonly
        ? _c("div", { staticClass: "buttons" }, [
            !_vm.editing
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-large btn-primary",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        _vm.editing = true
                      }
                    }
                  },
                  [_vm._v("Update")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.editing
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-large btn-primary",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.updateSettings.apply(null, arguments)
                      }
                    }
                  },
                  [_vm._v("Save")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.editing
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-large btn-danger",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        _vm.editing = false
                      }
                    }
                  },
                  [_vm._v("Cancel")]
                )
              : _vm._e()
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("ul", { staticClass: "breadcrumbs" }, [
        _c("li", [_c("a", [_vm._v("Organization Information")])])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }