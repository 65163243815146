var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sup-cont" },
    [
      _c("ul", { staticClass: "sup-tabs" }, [
        _c("li", { class: { active: _vm.tab == "Pending" } }, [
          _c(
            "a",
            {
              attrs: { href: "" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.changeTab("Pending")
                }
              }
            },
            [_vm._v("Pending")]
          )
        ]),
        _vm._v(" "),
        _c("li", { class: { active: _vm.tab == "Open" } }, [
          _c(
            "a",
            {
              attrs: { href: "" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.changeTab("Open")
                }
              }
            },
            [_vm._v("Open")]
          )
        ]),
        _vm._v(" "),
        _c("li", { class: { active: _vm.tab == "Closed" } }, [
          _c(
            "a",
            {
              attrs: { href: "" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.changeTab("Closed")
                }
              }
            },
            [_vm._v("Closed")]
          )
        ]),
        _vm._v(" "),
        _c("li", { class: { active: _vm.tab == "Resolved" } }, [
          _c(
            "a",
            {
              attrs: { href: "" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.changeTab("Resolved")
                }
              }
            },
            [_vm._v("Resolved")]
          )
        ]),
        _vm._v(" "),
        _c("li", { class: { active: _vm.tab === null } }, [
          _c(
            "a",
            {
              attrs: { href: "" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.changeTab(null)
                }
              }
            },
            [_vm._v("All")]
          )
        ]),
        _vm._v(" "),
        _c(
          "li",
          {
            class: { filters: true, applied: _vm.hasAppliedFilters },
            on: {
              click: function($event) {
                _vm.openFilters = true
              }
            }
          },
          [_c("img", { attrs: { src: "/assets/img/filters.png" } })]
        )
      ]),
      _vm._v(" "),
      _c("ul", { staticClass: "sup-tabbedc" }, [
        _c("li", { staticClass: "active" }, [
          _c("div", { staticClass: "sup-table" }, [
            _c(
              "table",
              [
                _vm._m(0),
                _vm._v(" "),
                _vm._l(_vm.filteredTickets, function(ticket) {
                  return [
                    _vm.currentTicket !== ticket.id
                      ? _c("tr", { key: ticket.id }, [
                          _c("td", [_c("span", [_vm._v(_vm._s(ticket.id))])]),
                          _vm._v(" "),
                          _c("td", [_c("span", [_vm._v(_vm._s(ticket.type))])]),
                          _vm._v(" "),
                          _c("td", [
                            _c("span", [
                              _vm._v(_vm._s(ticket.user && ticket.user.agentId))
                            ])
                          ]),
                          _vm._v(" "),
                          _c("td", [_c("span", [_vm._v(_vm._s(ticket.name))])]),
                          _vm._v(" "),
                          _c("td", [
                            _c("span", [_vm._v(_vm._s(ticket.email))])
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.subjOptions[ticket.subject] || "Other"
                                )
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _c("span", [_vm._v(_vm._s(ticket.status))])
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _c("span", [
                              _c(
                                "a",
                                {
                                  staticClass: "table-btn",
                                  attrs: { href: "" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.showTicket(ticket.id)
                                    }
                                  }
                                },
                                [
                                  _vm._v("View"),
                                  _vm.unreadCount(ticket)
                                    ? _c("em", [
                                        _vm._v(_vm._s(_vm.unreadCount(ticket)))
                                      ])
                                    : _vm._e()
                                ]
                              )
                            ])
                          ])
                        ])
                      : _c("support-chat", {
                          key: "ticketChat" + _vm.tab + ticket.id,
                          attrs: { admin: _vm.admin, ticket: ticket }
                        })
                  ]
                })
              ],
              2
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _vm.openFilters
        ? _c("support-filters", {
            attrs: { filters: _vm.filters },
            on: {
              close: function($event) {
                _vm.openFilters = false
              },
              filter: function($event) {
                return _vm.updateFilters($event)
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("Ticket #")]),
      _vm._v(" "),
      _c("th", [_vm._v("Type")]),
      _vm._v(" "),
      _c("th", [_vm._v("Account id")]),
      _vm._v(" "),
      _c("th", [_vm._v("Name")]),
      _vm._v(" "),
      _c("th", [_vm._v("E-Mail")]),
      _vm._v(" "),
      _c("th", [_vm._v("Subject")]),
      _vm._v(" "),
      _c("th", [_vm._v("Status")]),
      _vm._v(" "),
      _c("th", [_vm._v(" ")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }