var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        "enable-background": "new 0 0 64 64",
        fill: _vm.color,
        width: _vm.size + "px",
        height: _vm.size + "px",
        id: "Layer_1",
        version: "1.1",
        viewBox: "0 0 64 64",
        "xml:space": "preserve",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink"
      }
    },
    [
      _c("g", [
        _c("path", {
          attrs: {
            d:
              "M9,29c0-1.1044922-0.8955078-2-2-2H5V5h22v22H14c-1.1044922,0-2,0.8955078-2,2s0.8955078,2,2,2h15   c1.1044922,0,2-0.8955078,2-2V3c0-1.1044922-0.8955078-2-2-2H3C1.8955078,1,1,1.8955078,1,3v26c0,1.1044922,0.8955078,2,2,2h4   C8.1044922,31,9,30.1044922,9,29z"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M61,36H48c-1.1044922,0-2,0.8955078-2,2s0.8955078,2,2,2h11v19H40V40h1c1.1044922,0,2-0.8955078,2-2s-0.8955078-2-2-2l-3-4   c-1.1044922,0-2,4.8955078-2,6v23c0,1.1044922,0.8955078,2,2,2h23c1.1044922,0,2-0.8955078,2-2V38   C63,36.8955078,62.1044922,36,61,36z"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M30,35H10c-1.1044922,0-2,0.8955078-2,2v20c0,1.1044922,0.8955078,2,2,2h20c1.1044922,0,2-0.8955078,2-2v-2   c0-1.1044922-0.8955078-2-2-2s-2,0.8955078-2,2H12V39h16v9c0,1.1044922,0.8955078,2,2,2s2-0.8955078,2-2V37   C32,35.8955078,31.1044922,35,30,35z"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M38,28h21c1.1044922,0,2-0.8955078,2-2V5c0-1.1044922-0.8955078-2-2-2H38c-1.1044922,0-2,0.8955078-2,2v2   c0,1.1044922,0.8955078,2,2,2s2-0.8955078,2-2h17v17H40V14c0-1.1044922-0.8955078-2-2-2s-2,0.8955078-2,2v12   C36,27.1044922,36.8955078,28,38,28z"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }