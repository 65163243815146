var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form", { staticClass: "form-step fx" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "section",
      [
        _c("h2", [_vm._v("Required Documents")]),
        _vm._v(" "),
        _c(
          "checkbox-toggle",
          {
            scopedSlots: _vm._u([
              {
                key: "more",
                fn: function() {
                  return [
                    _vm._v(
                      "If yes, provide incorporation documents for parent company and information of beneficial owners, directors and shareholders if different than company applying."
                    )
                  ]
                },
                proxy: true
              }
            ]),
            model: {
              value: _vm.app.companyParent,
              callback: function($$v) {
                _vm.$set(_vm.app, "companyParent", $$v)
              },
              expression: "app.companyParent"
            }
          },
          [_vm._v("\r\n      Parent Company\r\n      ")]
        ),
        _vm._v(" "),
        false
          ? _c(
              "validator",
              {
                attrs: {
                  value: _vm.app.requirements.merchantApplication,
                  reqs: "required"
                }
              },
              [
                _c(
                  "checkbox-plain",
                  {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "more",
                          fn: function() {
                            return [
                              _vm._v(
                                "Incomplete, handwritten or unsigned applications will not be accepted"
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      2022671038
                    ),
                    model: {
                      value: _vm.app.requirements.merchantApplication,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.app.requirements,
                          "merchantApplication",
                          $$v
                        )
                      },
                      expression: "app.requirements.merchantApplication"
                    }
                  },
                  [
                    _vm._v(
                      "\r\n      Completed & signed merchant application\r\n      "
                    )
                  ]
                )
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "validator",
          {
            attrs: {
              value: _vm.app.requirements.incorporationDocuments,
              reqs: "required"
            }
          },
          [
            _c(
              "checkbox-plain",
              {
                scopedSlots: _vm._u([
                  {
                    key: "more",
                    fn: function() {
                      return [
                        _vm._v(
                          "Must include certificate of incorporation, memorandum and articles of association, certificates listing all current beneficial owners, directors and shareholders"
                        )
                      ]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.app.requirements.incorporationDocuments,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.app.requirements,
                      "incorporationDocuments",
                      $$v
                    )
                  },
                  expression: "app.requirements.incorporationDocuments"
                }
              },
              [_vm._v("\r\n      Incorporation Documents\r\n      ")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "validator",
          {
            attrs: {
              value: _vm.app.requirements.passportCopy,
              reqs: "required"
            }
          },
          [
            _c(
              "checkbox-plain",
              {
                scopedSlots: _vm._u([
                  {
                    key: "more",
                    fn: function() {
                      return [
                        _vm._v(
                          "Passport copy for all beneficial owners, directors and authorized signatories"
                        )
                      ]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.app.requirements.passportCopy,
                  callback: function($$v) {
                    _vm.$set(_vm.app.requirements, "passportCopy", $$v)
                  },
                  expression: "app.requirements.passportCopy"
                }
              },
              [_vm._v("\r\n      Passport Copy\r\n      ")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "validator",
          {
            attrs: { value: _vm.app.requirements.utilityBill, reqs: "required" }
          },
          [
            _c(
              "checkbox-plain",
              {
                scopedSlots: _vm._u([
                  {
                    key: "more",
                    fn: function() {
                      return [
                        _vm._v(
                          "Utility bill for all beneficial owners, directors and authorized signatories.  No older than 60 days."
                        )
                      ]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.app.requirements.utilityBill,
                  callback: function($$v) {
                    _vm.$set(_vm.app.requirements, "utilityBill", $$v)
                  },
                  expression: "app.requirements.utilityBill"
                }
              },
              [_vm._v("\r\n      Recent Utility Bill\r\n      ")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "validator",
          {
            attrs: {
              value: _vm.app.requirements.bankStatement,
              reqs: "required"
            }
          },
          [
            _c(
              "checkbox-plain",
              {
                scopedSlots: _vm._u([
                  {
                    key: "more",
                    fn: function() {
                      return [
                        _vm._v(
                          "3 Months company bank statement clearly showing company name and address"
                        )
                      ]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.app.requirements.bankStatement,
                  callback: function($$v) {
                    _vm.$set(_vm.app.requirements, "bankStatement", $$v)
                  },
                  expression: "app.requirements.bankStatement"
                }
              },
              [
                _vm._v(
                  "\r\n      3 Months Bank Statement or Signed Bank Letter\r\n      "
                )
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "validator",
          {
            attrs: { value: _vm.app.requirements.voidCheck, reqs: "required" }
          },
          [
            _c(
              "checkbox-plain",
              {
                scopedSlots: _vm._u([
                  {
                    key: "more",
                    fn: function() {
                      return [_vm._v("Must be in company name")]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.app.requirements.voidCheck,
                  callback: function($$v) {
                    _vm.$set(_vm.app.requirements, "voidCheck", $$v)
                  },
                  expression: "app.requirements.voidCheck"
                }
              },
              [_vm._v("\r\n      Void Check or Signed Bank Letter\r\n      ")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _vm.app.industry == "Facilitator"
          ? _c(
              "validator",
              {
                attrs: {
                  value: _vm.app.requirements.businessPlan,
                  reqs: "required"
                }
              },
              [
                _c(
                  "checkbox-plain",
                  {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "more",
                          fn: function() {
                            return [
                              _vm._v("Provide business plan with projections")
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      723255074
                    ),
                    model: {
                      value: _vm.app.requirements.businessPlan,
                      callback: function($$v) {
                        _vm.$set(_vm.app.requirements, "businessPlan", $$v)
                      },
                      expression: "app.requirements.businessPlan"
                    }
                  },
                  [_vm._v("\r\n      Business Plan and Projections\r\n      ")]
                )
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.app.industry == "Facilitator"
          ? _c(
              "validator",
              {
                attrs: {
                  value: _vm.app.requirements.riskPolicies,
                  reqs: "required"
                }
              },
              [
                _c(
                  "checkbox-plain",
                  {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "more",
                          fn: function() {
                            return undefined
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      3165073180
                    ),
                    model: {
                      value: _vm.app.requirements.riskPolicies,
                      callback: function($$v) {
                        _vm.$set(_vm.app.requirements, "riskPolicies", $$v)
                      },
                      expression: "app.requirements.riskPolicies"
                    }
                  },
                  [
                    _vm._v(
                      "\r\n      Copy of Rist, AML, ATF, Fraud Policies\r\n      "
                    )
                  ]
                )
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.app.industry == "Facilitator"
          ? _c(
              "validator",
              {
                attrs: {
                  value: _vm.app.requirements.subApplication,
                  reqs: "required"
                }
              },
              [
                _c(
                  "checkbox-plain",
                  {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "more",
                          fn: function() {
                            return undefined
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      3165073180
                    ),
                    model: {
                      value: _vm.app.requirements.subApplication,
                      callback: function($$v) {
                        _vm.$set(_vm.app.requirements, "subApplication", $$v)
                      },
                      expression: "app.requirements.subApplication"
                    }
                  },
                  [
                    _vm._v(
                      "\r\n      Copy of Sub-Merchant Application\r\n      "
                    )
                  ]
                )
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.app.industry == "Facilitator"
          ? _c(
              "validator",
              {
                attrs: {
                  value: _vm.app.requirements.subAgreement,
                  reqs: "required"
                }
              },
              [
                _c(
                  "checkbox-plain",
                  {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "more",
                          fn: function() {
                            return undefined
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      3165073180
                    ),
                    model: {
                      value: _vm.app.requirements.subAgreement,
                      callback: function($$v) {
                        _vm.$set(_vm.app.requirements, "subAgreement", $$v)
                      },
                      expression: "app.requirements.subAgreement"
                    }
                  },
                  [_vm._v("\r\n      Copy of Sub-Merchant Agreement\r\n      ")]
                )
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.app.industry == "Facilitator"
          ? _c(
              "validator",
              {
                attrs: { value: _vm.app.requirements.listDba, reqs: "required" }
              },
              [
                _c(
                  "checkbox-plain",
                  {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "more",
                          fn: function() {
                            return undefined
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      3165073180
                    ),
                    model: {
                      value: _vm.app.requirements.listDba,
                      callback: function($$v) {
                        _vm.$set(_vm.app.requirements, "listDba", $$v)
                      },
                      expression: "app.requirements.listDba"
                    }
                  },
                  [_vm._v("\r\n      List of All DBAs\r\n      ")]
                )
              ],
              1
            )
          : _vm._e()
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "section",
      [
        _c("h2", [_vm._v("Conditional Documents")]),
        _vm._v(" "),
        _c(
          "validator",
          {
            attrs: { value: _vm.app.requirements.processingStatement, reqs: "" }
          },
          [
            _c(
              "checkbox-plain",
              {
                scopedSlots: _vm._u([
                  {
                    key: "more",
                    fn: function() {
                      return [
                        _vm._v(
                          "6 most recent processing statements for company"
                        )
                      ]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.app.requirements.processingStatement,
                  callback: function($$v) {
                    _vm.$set(_vm.app.requirements, "processingStatement", $$v)
                  },
                  expression: "app.requirements.processingStatement"
                }
              },
              [_vm._v("\r\n      6 Months Processing Statement\r\n      ")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "validator",
          { attrs: { value: _vm.app.requirements.govId, reqs: "" } },
          [
            _c(
              "checkbox-plain",
              {
                scopedSlots: _vm._u([
                  {
                    key: "more",
                    fn: function() {
                      return undefined
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.app.requirements.govId,
                  callback: function($$v) {
                    _vm.$set(_vm.app.requirements, "govId", $$v)
                  },
                  expression: "app.requirements.govId"
                }
              },
              [_vm._v("\r\n      Government Issued ID\r\n      ")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "validator",
          {
            attrs: { value: _vm.app.requirements.corporateStructure, reqs: "" }
          },
          [
            _c(
              "checkbox-plain",
              {
                scopedSlots: _vm._u([
                  {
                    key: "more",
                    fn: function() {
                      return [
                        _vm._v(
                          "Show parent and related companies and beneficial owners, directors and shareholders"
                        )
                      ]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.app.requirements.corporateStructure,
                  callback: function($$v) {
                    _vm.$set(_vm.app.requirements, "corporateStructure", $$v)
                  },
                  expression: "app.requirements.corporateStructure"
                }
              },
              [_vm._v("\r\n      Corporate Structure Diagram\r\n      ")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "validator",
          {
            attrs: { value: _vm.app.requirements.financialStatements, reqs: "" }
          },
          [
            _c(
              "checkbox-plain",
              {
                scopedSlots: _vm._u([
                  {
                    key: "more",
                    fn: function() {
                      return [
                        _vm._v("Most recent financial statements for company")
                      ]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.app.requirements.financialStatements,
                  callback: function($$v) {
                    _vm.$set(_vm.app.requirements, "financialStatements", $$v)
                  },
                  expression: "app.requirements.financialStatements"
                }
              },
              [_vm._v("\r\n      Financial Statements\r\n      ")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "validator",
          { attrs: { value: _vm.app.requirements.copyOfLicense, reqs: "" } },
          [
            _c(
              "checkbox-plain",
              {
                scopedSlots: _vm._u([
                  {
                    key: "more",
                    fn: function() {
                      return [_vm._v("Provide if is a regulated business type")]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.app.requirements.copyOfLicense,
                  callback: function($$v) {
                    _vm.$set(_vm.app.requirements, "copyOfLicense", $$v)
                  },
                  expression: "app.requirements.copyOfLicense"
                }
              },
              [_vm._v("\r\n      Copy of License(s)\r\n      ")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "validator",
          { attrs: { value: _vm.app.requirements.pciDssCert, reqs: "" } },
          [
            _c(
              "checkbox-plain",
              {
                scopedSlots: _vm._u([
                  {
                    key: "more",
                    fn: function() {
                      return [_vm._v("PCI DSS compliance certification")]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.app.requirements.pciDssCert,
                  callback: function($$v) {
                    _vm.$set(_vm.app.requirements, "pciDssCert", $$v)
                  },
                  expression: "app.requirements.pciDssCert"
                }
              },
              [_vm._v("\r\n      PCI DSS Certificate or Adherence\r\n      ")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "validator",
          { attrs: { value: _vm.app.requirements.padAuth, reqs: "" } },
          [
            _c(
              "checkbox-plain",
              {
                scopedSlots: _vm._u([
                  {
                    key: "more",
                    fn: function() {
                      return undefined
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.app.requirements.padAuth,
                  callback: function($$v) {
                    _vm.$set(_vm.app.requirements, "padAuth", $$v)
                  },
                  expression: "app.requirements.padAuth"
                }
              },
              [_vm._v("\r\n      Sample PAD Authorization\r\n      ")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "validator",
          {
            attrs: { value: _vm.app.requirements.motoQuestionnaire, reqs: "" }
          },
          [
            _c(
              "checkbox-plain",
              {
                scopedSlots: _vm._u([
                  {
                    key: "more",
                    fn: function() {
                      return undefined
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.app.requirements.motoQuestionnaire,
                  callback: function($$v) {
                    _vm.$set(_vm.app.requirements, "motoQuestionnaire", $$v)
                  },
                  expression: "app.requirements.motoQuestionnaire"
                }
              },
              [
                _vm._v(
                  "\r\n      Completed MOTO/Internet Questionnaire\r\n      "
                )
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "validator",
          {
            attrs: { value: _vm.app.requirements.marketingMaterials, reqs: "" }
          },
          [
            _c(
              "checkbox-plain",
              {
                scopedSlots: _vm._u([
                  {
                    key: "more",
                    fn: function() {
                      return undefined
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.app.requirements.marketingMaterials,
                  callback: function($$v) {
                    _vm.$set(_vm.app.requirements, "marketingMaterials", $$v)
                  },
                  expression: "app.requirements.marketingMaterials"
                }
              },
              [_vm._v("\r\n      Marketing Materials if no Website\r\n      ")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "validator",
          { attrs: { value: _vm.app.requirements.businessSummary, reqs: "" } },
          [
            _c(
              "checkbox-plain",
              {
                scopedSlots: _vm._u([
                  {
                    key: "more",
                    fn: function() {
                      return undefined
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.app.requirements.businessSummary,
                  callback: function($$v) {
                    _vm.$set(_vm.app.requirements, "businessSummary", $$v)
                  },
                  expression: "app.requirements.businessSummary"
                }
              },
              [_vm._v("\r\n      Complete Business Summary\r\n      ")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "validator",
          {
            attrs: { value: _vm.app.requirements.personalGuarantee, reqs: "" }
          },
          [
            _c(
              "checkbox-plain",
              {
                scopedSlots: _vm._u([
                  {
                    key: "more",
                    fn: function() {
                      return undefined
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.app.requirements.personalGuarantee,
                  callback: function($$v) {
                    _vm.$set(_vm.app.requirements, "personalGuarantee", $$v)
                  },
                  expression: "app.requirements.personalGuarantee"
                }
              },
              [_vm._v("\r\n      Personal Guarantee\r\n      ")]
            )
          ],
          1
        )
      ],
      1
    ),
    _vm._v(" "),
    _vm.app.industry != "Facilitator"
      ? _c(
          "section",
          [
            _c("h2", [
              _vm._v("New Business "),
              _c("span"),
              _vm._v(" "),
              _c("span", { staticClass: "huge-checkbox website-checkbox" }, [
                _c("label", { staticClass: "radio" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.app.companyNew,
                        expression: "app.companyNew"
                      }
                    ],
                    attrs: { type: "radio", name: "new" },
                    domProps: {
                      value: true,
                      checked: _vm._q(_vm.app.companyNew, true)
                    },
                    on: {
                      change: function($event) {
                        return _vm.$set(_vm.app, "companyNew", true)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("i"),
                  _vm._v("\r\n        Yes\r\n      ")
                ]),
                _vm._v(" "),
                _c("label", { staticClass: "radio" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.app.companyNew,
                        expression: "app.companyNew"
                      }
                    ],
                    attrs: { type: "radio", name: "new" },
                    domProps: {
                      value: false,
                      checked: _vm._q(_vm.app.companyNew, false)
                    },
                    on: {
                      change: function($event) {
                        return _vm.$set(_vm.app, "companyNew", false)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("i"),
                  _vm._v("\r\n        No\r\n      ")
                ])
              ])
            ]),
            _vm._v(" "),
            _c(
              "validator",
              {
                attrs: {
                  value: _vm.app.requirements.businessPlan,
                  reqs: _vm.app.companyNew == "1" ? "required" : ""
                }
              },
              [
                _c(
                  "checkbox-plain",
                  {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "more",
                          fn: function() {
                            return [
                              _vm._v("Provide business plan with projections ")
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      2699953954
                    ),
                    model: {
                      value: _vm.app.requirements.businessPlan,
                      callback: function($$v) {
                        _vm.$set(_vm.app.requirements, "businessPlan", $$v)
                      },
                      expression: "app.requirements.businessPlan"
                    }
                  },
                  [_vm._v("\r\n      Business Plan and Projections\r\n      ")]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "validator",
              {
                attrs: {
                  value: _vm.app.requirements.bankStatement2,
                  reqs: _vm.app.companyNew == "1" ? "required" : ""
                }
              },
              [
                _c(
                  "checkbox-plain",
                  {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "more",
                          fn: function() {
                            return [
                              _vm._v(
                                "Provide bank statement for all beneficial owners, directors and shareholders"
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      3616815798
                    ),
                    model: {
                      value: _vm.app.requirements.bankStatement2,
                      callback: function($$v) {
                        _vm.$set(_vm.app.requirements, "bankStatement2", $$v)
                      },
                      expression: "app.requirements.bankStatement2"
                    }
                  },
                  [
                    _vm._v(
                      "\r\n      Bank Statement or Signed Bank Letter\r\n      "
                    )
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "validator",
              {
                attrs: {
                  value: _vm.app.requirements.businessSummary2,
                  reqs: ""
                }
              },
              [
                _c(
                  "checkbox-plain",
                  {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "more",
                          fn: function() {
                            return undefined
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      3165073180
                    ),
                    model: {
                      value: _vm.app.requirements.businessSummary2,
                      callback: function($$v) {
                        _vm.$set(_vm.app.requirements, "businessSummary2", $$v)
                      },
                      expression: "app.requirements.businessSummary2"
                    }
                  },
                  [_vm._v("\r\n      Complete Business Summary\r\n      ")]
                )
              ],
              1
            )
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "section",
      [
        _c("h2", [
          _vm._v("\r\n      Website Requirements "),
          _c("span", [_vm._v("Do you have a website?")]),
          _vm._v(" "),
          _c("span", { staticClass: "huge-checkbox website-checkbox" }, [
            _c("label", { staticClass: "radio" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.app.hasWebsite,
                    expression: "app.hasWebsite"
                  }
                ],
                attrs: { type: "radio", name: "web", disabled: "" },
                domProps: {
                  value: "1",
                  checked: _vm._q(_vm.app.hasWebsite, "1")
                },
                on: {
                  change: function($event) {
                    return _vm.$set(_vm.app, "hasWebsite", "1")
                  }
                }
              }),
              _vm._v(" "),
              _c("i"),
              _vm._v("\r\n        Yes\r\n      ")
            ]),
            _vm._v(" "),
            _c("label", { staticClass: "radio" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.app.hasWebsite,
                    expression: "app.hasWebsite"
                  }
                ],
                attrs: { type: "radio", name: "web", disabled: "" },
                domProps: {
                  value: "0",
                  checked: _vm._q(_vm.app.hasWebsite, "0")
                },
                on: {
                  change: function($event) {
                    return _vm.$set(_vm.app, "hasWebsite", "0")
                  }
                }
              }),
              _vm._v(" "),
              _c("i"),
              _vm._v("\r\n        No\r\n      ")
            ])
          ])
        ]),
        _vm._v(" "),
        _vm.app.hasWebsite != "0"
          ? [
              _c("p", { staticClass: "section-intro" }, [
                _vm._v(
                  "Website must be fully functional when applying and must contain:\r\n      "
                ),
                _c(
                  "label",
                  { staticClass: "checkbox", class: { checked: _vm.webAll } },
                  [
                    _c("input", {
                      attrs: { type: "checkbox" },
                      domProps: { checked: _vm.webAll },
                      on: { change: _vm.toggleWebAll }
                    }),
                    _vm._v(" "),
                    _c("i"),
                    _vm._v("\r\n        Select all\r\n      ")
                  ]
                )
              ]),
              _vm._v(" "),
              _c(
                "validator",
                {
                  attrs: {
                    value: _vm.app.requirements.webContact,
                    reqs: "required"
                  }
                },
                [
                  _c(
                    "checkbox-plain",
                    {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "more",
                            fn: function() {
                              return [
                                _vm._v(
                                  "Business name, address, telephone and email of company"
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        2902558322
                      ),
                      model: {
                        value: _vm.app.requirements.webContact,
                        callback: function($$v) {
                          _vm.$set(_vm.app.requirements, "webContact", $$v)
                        },
                        expression: "app.requirements.webContact"
                      }
                    },
                    [_vm._v("\r\n      Contact Information\r\n      ")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "validator",
                {
                  attrs: {
                    value: _vm.app.requirements.webCustService,
                    reqs: "boolean"
                  }
                },
                [
                  _c(
                    "checkbox-plain",
                    {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "more",
                            fn: function() {
                              return [_vm._v("Telephone Number and Email")]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        3866436712
                      ),
                      model: {
                        value: _vm.app.requirements.webCustService,
                        callback: function($$v) {
                          _vm.$set(_vm.app.requirements, "webCustService", $$v)
                        },
                        expression: "app.requirements.webCustService"
                      }
                    },
                    [_vm._v("\r\n      Customer Service Information\r\n      ")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.app.industry != "Facilitator"
                ? _c(
                    "validator",
                    {
                      attrs: {
                        value: _vm.app.requirements.webBilling,
                        reqs: "boolean"
                      }
                    },
                    [
                      _c(
                        "checkbox-plain",
                        {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "more",
                                fn: function() {
                                  return [
                                    _vm._v(
                                      "Statement showing the billing descriptor which will appear"
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            false,
                            1501448507
                          ),
                          model: {
                            value: _vm.app.requirements.webBilling,
                            callback: function($$v) {
                              _vm.$set(_vm.app.requirements, "webBilling", $$v)
                            },
                            expression: "app.requirements.webBilling"
                          }
                        },
                        [_vm._v("\r\n      Billing Descriptor\r\n      ")]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.app.industry != "Facilitator"
                ? _c(
                    "validator",
                    {
                      attrs: {
                        value: _vm.app.requirements.webShipping,
                        reqs: "boolean"
                      }
                    },
                    [
                      _c(
                        "checkbox-plain",
                        {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "more",
                                fn: function() {
                                  return [
                                    _vm._v(
                                      "Information on shipping/delivery time frames"
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            false,
                            2539467423
                          ),
                          model: {
                            value: _vm.app.requirements.webShipping,
                            callback: function($$v) {
                              _vm.$set(_vm.app.requirements, "webShipping", $$v)
                            },
                            expression: "app.requirements.webShipping"
                          }
                        },
                        [_vm._v("\r\n      Shipping/Delivery Policy\r\n      ")]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.app.industry != "Facilitator"
                ? _c(
                    "validator",
                    {
                      attrs: {
                        value: _vm.app.requirements.webReturn,
                        reqs: "boolean"
                      }
                    },
                    [
                      _c(
                        "checkbox-plain",
                        {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "more",
                                fn: function() {
                                  return [
                                    _vm._v(
                                      "Information on procedure for refunds/returns"
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            false,
                            27494168
                          ),
                          model: {
                            value: _vm.app.requirements.webReturn,
                            callback: function($$v) {
                              _vm.$set(_vm.app.requirements, "webReturn", $$v)
                            },
                            expression: "app.requirements.webReturn"
                          }
                        },
                        [_vm._v("\r\n      Refund/Return Policy\r\n      ")]
                      )
                    ],
                    1
                  )
                : _c(
                    "validator",
                    {
                      attrs: {
                        value: _vm.app.requirements.webCsInfo,
                        reqs: "boolean"
                      }
                    },
                    [
                      _c(
                        "checkbox-plain",
                        {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "more",
                                fn: function() {
                                  return undefined
                                },
                                proxy: true
                              }
                            ],
                            null,
                            false,
                            3165073180
                          ),
                          model: {
                            value: _vm.app.requirements.webCsInfo,
                            callback: function($$v) {
                              _vm.$set(_vm.app.requirements, "webCsInfo", $$v)
                            },
                            expression: "app.requirements.webCsInfo"
                          }
                        },
                        [
                          _vm._v(
                            "\r\n      Customer Support Information\r\n      "
                          )
                        ]
                      )
                    ],
                    1
                  ),
              _vm._v(" "),
              _c(
                "validator",
                {
                  attrs: {
                    value: _vm.app.requirements.webTerms,
                    reqs: "boolean"
                  }
                },
                [
                  _c(
                    "checkbox-plain",
                    {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "more",
                            fn: function() {
                              return [_vm._v("Complete terms and conditions")]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        547698615
                      ),
                      model: {
                        value: _vm.app.requirements.webTerms,
                        callback: function($$v) {
                          _vm.$set(_vm.app.requirements, "webTerms", $$v)
                        },
                        expression: "app.requirements.webTerms"
                      }
                    },
                    [_vm._v("\r\n      Terms and Conditions\r\n      ")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "validator",
                {
                  attrs: {
                    value: _vm.app.requirements.webPrivacy,
                    reqs: "boolean"
                  }
                },
                [
                  _c(
                    "checkbox-plain",
                    {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "more",
                            fn: function() {
                              return [_vm._v("Privacy statement")]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        2012744939
                      ),
                      model: {
                        value: _vm.app.requirements.webPrivacy,
                        callback: function($$v) {
                          _vm.$set(_vm.app.requirements, "webPrivacy", $$v)
                        },
                        expression: "app.requirements.webPrivacy"
                      }
                    },
                    [_vm._v("\r\n      Privacy Policy\r\n      ")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "validator",
                {
                  attrs: {
                    value: _vm.app.requirements.webDescription,
                    reqs: "boolean"
                  }
                },
                [
                  _c(
                    "checkbox-plain",
                    {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "more",
                            fn: function() {
                              return [
                                _vm._v(
                                  "Complete description of goods/services offered"
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        17105254
                      ),
                      model: {
                        value: _vm.app.requirements.webDescription,
                        callback: function($$v) {
                          _vm.$set(_vm.app.requirements, "webDescription", $$v)
                        },
                        expression: "app.requirements.webDescription"
                      }
                    },
                    [
                      _vm._v(
                        "\r\n      Description of Goods/Services " +
                          _vm._s(
                            _vm.app.industry == "Facilitator"
                              ? "offered to Sub-Merchants"
                              : ""
                          ) +
                          "\r\n      "
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.app.industry != "Facilitator"
                ? _c(
                    "validator",
                    {
                      attrs: {
                        value: _vm.app.requirements.webCurrency,
                        reqs: "boolean"
                      }
                    },
                    [
                      _c(
                        "checkbox-plain",
                        {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "more",
                                fn: function() {
                                  return [
                                    _vm._v(
                                      "List all transaction currencies accepted"
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            false,
                            2047012783
                          ),
                          model: {
                            value: _vm.app.requirements.webCurrency,
                            callback: function($$v) {
                              _vm.$set(_vm.app.requirements, "webCurrency", $$v)
                            },
                            expression: "app.requirements.webCurrency"
                          }
                        },
                        [_vm._v("\r\n      Transaction Currency\r\n      ")]
                      )
                    ],
                    1
                  )
                : _c(
                    "validator",
                    {
                      attrs: {
                        value: _vm.app.requirements.webListProhibited,
                        reqs: "boolean"
                      }
                    },
                    [
                      _c(
                        "checkbox-plain",
                        {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "more",
                                fn: function() {
                                  return undefined
                                },
                                proxy: true
                              }
                            ],
                            null,
                            false,
                            3165073180
                          ),
                          model: {
                            value: _vm.app.requirements.webListProhibited,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.app.requirements,
                                "webListProhibited",
                                $$v
                              )
                            },
                            expression: "app.requirements.webListProhibited"
                          }
                        },
                        [
                          _vm._v(
                            "\r\n      List of restricted and prohibited merchant types\r\n      "
                          )
                        ]
                      )
                    ],
                    1
                  ),
              _vm._v(" "),
              _c(
                "validator",
                {
                  attrs: { value: _vm.app.requirements.webSsl, reqs: "boolean" }
                },
                [
                  _c(
                    "checkbox-plain",
                    {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "more",
                            fn: function() {
                              return [_vm._v("Secured payment page")]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        2175883538
                      ),
                      model: {
                        value: _vm.app.requirements.webSsl,
                        callback: function($$v) {
                          _vm.$set(_vm.app.requirements, "webSsl", $$v)
                        },
                        expression: "app.requirements.webSsl"
                      }
                    },
                    [_vm._v("\r\n      SSL Secured\r\n      ")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "validator",
                {
                  attrs: {
                    value: _vm.app.requirements.webUrlList,
                    reqs: "boolean"
                  }
                },
                [
                  _c(
                    "checkbox-plain",
                    {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "more",
                            fn: function() {
                              return [
                                _vm._v(
                                  "List of all URLs that will be accepting payments"
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        3688092099
                      ),
                      model: {
                        value: _vm.app.requirements.webUrlList,
                        callback: function($$v) {
                          _vm.$set(_vm.app.requirements, "webUrlList", $$v)
                        },
                        expression: "app.requirements.webUrlList"
                      }
                    },
                    [
                      _vm._v(
                        "\r\n      URL List " +
                          _vm._s(
                            _vm.app.industry == "Facilitator"
                              ? "of all DBAs"
                              : ""
                          ) +
                          "\r\n      "
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.app.industry != "Facilitator"
                ? _c(
                    "validator",
                    {
                      attrs: {
                        value: _vm.app.requirements.webUser,
                        reqs: "boolean"
                      }
                    },
                    [
                      _c(
                        "checkbox-plain",
                        {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "more",
                                fn: function() {
                                  return [
                                    _vm._v(
                                      "Non-expiring username and password for each site"
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            false,
                            3619289409
                          ),
                          model: {
                            value: _vm.app.requirements.webUser,
                            callback: function($$v) {
                              _vm.$set(_vm.app.requirements, "webUser", $$v)
                            },
                            expression: "app.requirements.webUser"
                          }
                        },
                        [_vm._v("\r\n      Username and Password\r\n      ")]
                      )
                    ],
                    1
                  )
                : _c(
                    "validator",
                    {
                      attrs: {
                        value: _vm.app.requirements.webOnlineApp,
                        reqs: "boolean"
                      }
                    },
                    [
                      _c(
                        "checkbox-plain",
                        {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "more",
                                fn: function() {
                                  return undefined
                                },
                                proxy: true
                              }
                            ],
                            null,
                            false,
                            3165073180
                          ),
                          model: {
                            value: _vm.app.requirements.webOnlineApp,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.app.requirements,
                                "webOnlineApp",
                                $$v
                              )
                            },
                            expression: "app.requirements.webOnlineApp"
                          }
                        },
                        [
                          _vm._v(
                            "\r\n      Online application collecting all information\r\n      "
                          )
                        ]
                      )
                    ],
                    1
                  )
            ]
          : _vm._e()
      ],
      2
    ),
    _vm._v(" "),
    _c("ul", { staticClass: "notes" }, [
      _c("li", [
        _vm._v(
          "For assistance, please contact " +
            _vm._s(_vm.companydata.applicationEmail) +
            " or call " +
            _vm._s(_vm.companydata.servicesTel)
        )
      ]),
      _vm._v(" "),
      _c("li", [_vm._v("Additional requirements may apply")]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "Ensure that all above requested items are complete when submitted"
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "Please submit/upload all requested items on the last page of application."
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "buttons" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-large btn-success",
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.nextStep.apply(null, arguments)
            }
          }
        },
        [_vm._v("Next Step")]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "form-intro fx" }, [
      _vm._v(
        "\r\n    Be sure to have the required documents as outlined below available as you will need to submit them at the end. "
      ),
      _c("br"),
      _c("strong", [
        _vm._v("This application should take 15 minutes to complete.")
      ]),
      _vm._v(" You may save the application and resume if necessary.\r\n  ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }