var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "popup usermgmt", class: { shown: _vm.visible } },
    [
      _c("div", { staticClass: "pop-med" }, [
        _c("div", { staticClass: "pop-inner" }, [
          _c("a", {
            staticClass: "close",
            attrs: { href: "" },
            on: {
              click: function($event) {
                $event.preventDefault()
                _vm.visible = false
              }
            }
          }),
          _vm._v(" "),
          _c("h2", [
            _vm._v(
              _vm._s(
                _vm.user.id
                  ? "Editing user: " +
                      _vm.user.username +
                      " " +
                      (_vm.user.agentId ? "(" + _vm.user.agentId + ")" : "")
                  : "New user"
              )
            )
          ]),
          _vm._v(" "),
          _c("form", { staticClass: "search-form" }, [
            _c("div", { staticClass: "f2split" }, [
              _c(
                "div",
                [
                  _c(
                    "validator",
                    {
                      staticClass: "field",
                      attrs: { value: _vm.user.username, reqs: "required" }
                    },
                    [
                      _c("label", [_vm._v("Username")]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.user.username,
                            expression: "user.username"
                          }
                        ],
                        attrs: { tabindex: "1", type: "text" },
                        domProps: { value: _vm.user.username },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.user, "username", $event.target.value)
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "validator",
                    {
                      staticClass: "field",
                      attrs: { value: _vm.user.email, reqs: "required" }
                    },
                    [
                      _c("label", [_vm._v("Email")]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.user.email,
                            expression: "user.email"
                          }
                        ],
                        attrs: { tabindex: "1", type: "text" },
                        domProps: { value: _vm.user.email },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.user, "email", $event.target.value)
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "f2split" }, [
              _c(
                "div",
                [
                  _c(
                    "validator",
                    {
                      staticClass: "field",
                      attrs: { value: _vm.user.fullName, reqs: "required" }
                    },
                    [
                      _c("label", [_vm._v("First name")]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.user.fullName,
                            expression: "user.fullName"
                          }
                        ],
                        attrs: { tabindex: "1", type: "text" },
                        domProps: { value: _vm.user.fullName },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.user, "fullName", $event.target.value)
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "validator",
                    {
                      staticClass: "field",
                      attrs: { value: _vm.user.lastName, reqs: "required" }
                    },
                    [
                      _c("label", [_vm._v("Last name")]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.user.lastName,
                            expression: "user.lastName"
                          }
                        ],
                        attrs: { tabindex: "1", type: "text" },
                        domProps: { value: _vm.user.lastName },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.user, "lastName", $event.target.value)
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _vm.user.id
              ? _c(
                  "a",
                  {
                    staticClass: "newcon",
                    attrs: { href: "" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.resetPassword.apply(null, arguments)
                      }
                    }
                  },
                  [_vm._v("Reset Password")]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "buttons" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-large btn-primary",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.send.apply(null, arguments)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.user.id ? "Update" : "Create"))]
              )
            ])
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }