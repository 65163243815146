var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form", { staticClass: "edit-system search-form" }, [
    _c("div", { staticClass: "content-table-container" }, [
      _c("table", { staticClass: "content-table" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "tbody",
          [
            _c("tr", [
              _c("td", { attrs: { colspan: "3" } }, [
                _c(
                  "div",
                  { staticStyle: { display: "flex", "align-items": "center" } },
                  [
                    _vm._v("\n              Template:\n              "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.currentTemplate.name,
                          expression: "currentTemplate.name"
                        }
                      ],
                      staticStyle: {
                        display: "block",
                        width: "100%",
                        "margin-left": "auto",
                        "text-align": "left"
                      },
                      attrs: { type: "text" },
                      domProps: { value: _vm.currentTemplate.name },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.currentTemplate,
                            "name",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]
                )
              ]),
              _vm._v(" "),
              _c(
                "td",
                [
                  _c("dyn-select", {
                    attrs: { options: _vm.currentTemplates },
                    model: {
                      value: _vm.templateId,
                      callback: function($$v) {
                        _vm.templateId = $$v
                      },
                      expression: "templateId"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("td", [
                _vm.templateId
                  ? _c(
                      "a",
                      {
                        staticClass: "remove",
                        attrs: { href: "" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.deleteTemplate.apply(null, arguments)
                          }
                        }
                      },
                      [_vm._v("×")]
                    )
                  : _vm._e()
              ])
            ]),
            _vm._v(" "),
            _vm._l(_vm.options, function(item, index) {
              return _c("tr", [
                _c("td", { staticClass: "text-left" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: item.name,
                        expression: "item.name"
                      }
                    ],
                    staticStyle: { "text-align": "left" },
                    attrs: { type: "text" },
                    domProps: { value: item.name },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(item, "name", $event.target.value)
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c("dyn-select", {
                      attrs: {
                        options: [
                          { value: "Toggle", label: "Toggle" },
                          { value: "Count", label: "Count" }
                        ]
                      },
                      model: {
                        value: item.mode,
                        callback: function($$v) {
                          _vm.$set(item, "mode", $$v)
                        },
                        expression: "item.mode"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c("dyn-select", {
                      attrs: { options: _vm.optionList },
                      model: {
                        value: item.parent,
                        callback: function($$v) {
                          _vm.$set(item, "parent", $$v)
                        },
                        expression: "item.parent"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    item.id
                      ? _c("dyn-select", {
                          attrs: {
                            options:
                              item.mode == "Count"
                                ? _vm.optsCount
                                : _vm.optsToggle
                          },
                          model: {
                            value: _vm.currentTemplate.selected[item.id],
                            callback: function($$v) {
                              _vm.$set(
                                _vm.currentTemplate.selected,
                                item.id,
                                $$v
                              )
                            },
                            expression: "currentTemplate.selected[item.id]"
                          }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                _c("td", [
                  _c(
                    "a",
                    {
                      staticClass: "remove",
                      attrs: { href: "" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.deleteOption(index)
                        }
                      }
                    },
                    [_vm._v("×")]
                  )
                ])
              ])
            }),
            _vm._v(" "),
            _c("tr", { staticClass: "new-row" }, [
              _c("td", { attrs: { colspan: "5" } }, [
                _c(
                  "a",
                  {
                    staticClass: "add",
                    attrs: { href: "" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.newOption.apply(null, arguments)
                      }
                    }
                  },
                  [_vm._v("+")]
                )
              ])
            ])
          ],
          2
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "buttons" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-large btn-primary",
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.updateSettings.apply(null, arguments)
            }
          }
        },
        [_vm._v("Submit")]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticStyle: { width: "60%" } }, [_vm._v("Options")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "10%" } }, [_vm._v("Mode")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "10%" } }, [_vm._v("Parent")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "10%" } }, [_vm._v("Selected")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "10%" } }, [_vm._v(" ")])
      ]),
      _vm._v(" "),
      _c("tr", [_c("th", { staticClass: "spacer", attrs: { colspan: "2" } })])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }