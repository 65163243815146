var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form", { staticClass: "form-step" }, [
    _c("section", [
      _vm.auxtype == "Facilitator"
        ? _c("ul", { staticClass: "column-form type2" }, [
            _c("li", [
              _c("label", [_vm._v("MMID")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                [
                  _c(
                    "validator",
                    { attrs: { value: _vm.app.mmid, reqs: "required" } },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.app.mmid,
                            expression: "app.mmid"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.app.mmid },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.app, "mmid", $event.target.value)
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("li", [
              _c("label", [_vm._v("Username")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                [
                  _c(
                    "validator",
                    {
                      attrs: { value: _vm.app.mmidUsername, reqs: "required" }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.app.mmidUsername,
                            expression: "app.mmidUsername"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.app.mmidUsername },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.app,
                              "mmidUsername",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("li", [
              _c("label", [_vm._v("Password")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                [
                  _c(
                    "validator",
                    {
                      attrs: { value: _vm.app.mmidPassword, reqs: "required" }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.app.mmidPassword,
                            expression: "app.mmidPassword"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.app.mmidPassword },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.app,
                              "mmidPassword",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("li", [
              _c("label", [_vm._v("IP Address")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                [
                  _c(
                    "validator",
                    { attrs: { value: _vm.app.ipAddress, reqs: "required" } },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.app.ipAddress,
                            expression: "app.ipAddress"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.app.ipAddress },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.app, "ipAddress", $event.target.value)
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("h2", [_vm._v("Company Profile")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pgf-main-cb" },
        [
          _c("p", { staticClass: "intro" }, [
            _vm._v("Is applying company owned by a parent company?")
          ]),
          _vm._v(" "),
          _c(
            "validator",
            { attrs: { value: _vm.app.hasParent, reqs: "required" } },
            [
              _c("ul", { staticClass: "toggle-main" }, [
                _c("li", [
                  _c(
                    "label",
                    { class: { checked: _vm.app.hasParent === "1" } },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.app.hasParent,
                            expression: "app.hasParent"
                          }
                        ],
                        attrs: { type: "radio", name: "hasParent", value: "1" },
                        domProps: { checked: _vm._q(_vm.app.hasParent, "1") },
                        on: {
                          change: function($event) {
                            return _vm.$set(_vm.app, "hasParent", "1")
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("i"),
                      _vm._v(" "),
                      _c("strong", [_vm._v("Yes")]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          "(provide details of applying company and parent company below)"
                        )
                      ])
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c(
                    "label",
                    { class: { checked: _vm.app.hasParent === "0" } },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.app.hasParent,
                            expression: "app.hasParent"
                          }
                        ],
                        attrs: { type: "radio", name: "hasParent", value: "0" },
                        domProps: { checked: _vm._q(_vm.app.hasParent, "0") },
                        on: {
                          change: function($event) {
                            return _vm.$set(_vm.app, "hasParent", "0")
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("i"),
                      _vm._v(" "),
                      _c("strong", [_vm._v("No")]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          "(only provide details of applying company company below)"
                        )
                      ])
                    ]
                  )
                ])
              ])
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.hasParent
        ? _c("ul", { staticClass: "column-form type2" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("li", [
              _c("label", [_vm._v("Company registration number")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                [
                  _c(
                    "validator",
                    {
                      attrs: {
                        value: _vm.app.parentRegnum,
                        reqs: "required regnum"
                      }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.app.parentRegnum,
                            expression: "app.parentRegnum"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.app.parentRegnum },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.app,
                              "parentRegnum",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("li", [
              _c("label", [_vm._v("Legal name of company")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                [
                  _c(
                    "validator",
                    {
                      attrs: {
                        value: _vm.app.parentName,
                        reqs: "required legalName"
                      }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.app.parentName,
                            expression: "app.parentName"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.app.parentName },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.app, "parentName", $event.target.value)
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("li", [
              _c("label", [_vm._v("Registered DBA/Trade name")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                [
                  _c(
                    "validator",
                    {
                      attrs: {
                        value: _vm.app.parentNameTrade,
                        reqs: "legalName"
                      }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.app.parentNameTrade,
                            expression: "app.parentNameTrade"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.app.parentNameTrade },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.app,
                              "parentNameTrade",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("span", { staticClass: "pfx-info" }, [
                _vm._v("(if other than legal name)")
              ])
            ]),
            _vm._v(" "),
            _c("li", [
              _c("label", [_vm._v("Type of business")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                [
                  _c(
                    "validator",
                    {
                      attrs: {
                        value: _vm.app.parentType,
                        reqs: "required businessType"
                      }
                    },
                    [
                      _c("combo-select-ext", {
                        attrs: { options: _vm.tobOptions, type: "text" },
                        model: {
                          value: _vm.app.parentType,
                          callback: function($$v) {
                            _vm.$set(_vm.app, "parentType", $$v)
                          },
                          expression: "app.parentType"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("span", { staticClass: "pfx-info" }, [
                _vm._v("(LLC, Ltd, Sole proprietor, etc)")
              ])
            ]),
            _vm._v(" "),
            _c("li", [
              _c("label", [_vm._v("Registered street address")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                [
                  _c(
                    "validator",
                    {
                      attrs: {
                        value: _vm.app.parentAddress,
                        reqs: "required address"
                      }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.app.parentAddress,
                            expression: "app.parentAddress"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.app.parentAddress },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.app,
                              "parentAddress",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            false
              ? _c("li", [
                  _c("label", [_vm._v("House number")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "field" },
                    [
                      _c(
                        "validator",
                        {
                          attrs: {
                            value: _vm.app.parentAddressNum,
                            reqs: "number"
                          }
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.app.parentAddressNum,
                                expression: "app.parentAddressNum"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: { value: _vm.app.parentAddressNum },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.app,
                                  "parentAddressNum",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("span", { staticClass: "pfx-ingo" }, [
                    _vm._v("(P.O. Box not accepted)")
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("li", [
              _c("label", [_vm._v("Postal code / ZIP code")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                [
                  _c(
                    "validator",
                    {
                      attrs: {
                        value: _vm.app.parentAddressPostcode,
                        reqs: "required postcode"
                      }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.app.parentAddressPostcode,
                            expression: "app.parentAddressPostcode"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.app.parentAddressPostcode },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.app,
                              "parentAddressPostcode",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("li", [
              _c("label", [_vm._v("City")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                [
                  _c(
                    "validator",
                    {
                      attrs: {
                        value: _vm.app.parentAddressCity,
                        reqs: "required city"
                      }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.app.parentAddressCity,
                            expression: "app.parentAddressCity"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.app.parentAddressCity },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.app,
                              "parentAddressCity",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("li", [
              _c("label", [_vm._v("Province / State")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                [
                  _c(
                    "validator",
                    {
                      attrs: {
                        value: _vm.app.parentAddressState,
                        reqs: "required state"
                      }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.app.parentAddressState,
                            expression: "app.parentAddressState"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.app.parentAddressState },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.app,
                              "parentAddressState",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("li", [
              _c("label", [_vm._v("Country")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                [
                  _c(
                    "validator",
                    {
                      attrs: {
                        value: _vm.app.parentAddressCountry,
                        reqs: "required state"
                      }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.app.parentAddressCountry,
                            expression: "app.parentAddressCountry"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.app.parentAddressCountry },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.app,
                              "parentAddressCountry",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("li", [
              _c("label", [_vm._v("Telephone number")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                [
                  _c(
                    "validator",
                    {
                      attrs: {
                        value: _vm.app.parentPhone,
                        reqs: "required phone"
                      }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.app.parentPhone,
                            expression: "app.parentPhone"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.app.parentPhone },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.app,
                              "parentPhone",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("li", [
              _c("label", [_vm._v("Incorporation Date")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                [
                  _c(
                    "validator",
                    { attrs: { value: _vm.app.parentFax, reqs: "required" } },
                    [
                      _c("datepicker", {
                        model: {
                          value: _vm.app.parentFax,
                          callback: function($$v) {
                            _vm.$set(_vm.app, "parentFax", $$v)
                          },
                          expression: "app.parentFax"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("li", [
              _c("label", [_vm._v("Company Email")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                [
                  _c(
                    "validator",
                    {
                      attrs: {
                        value: _vm.app.parentEmail,
                        reqs: "required email char100"
                      }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.app.parentEmail,
                            expression: "app.parentEmail"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.app.parentEmail },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.app,
                              "parentEmail",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "sthird" }, [
              _c("label", [
                _vm._v(
                  "Are there any current legal proceedings being taken against the business, parent copmany or any associated directors?"
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                [
                  _c(
                    "validator",
                    {
                      attrs: { value: _vm.app.parentLawsuit, reqs: "required" }
                    },
                    [
                      _c("formel-toggle", {
                        model: {
                          value: _vm.app.parentLawsuit,
                          callback: function($$v) {
                            _vm.$set(_vm.app, "parentLawsuit", $$v)
                          },
                          expression: "app.parentLawsuit"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "sthird2" }, [
              _c(
                "div",
                { staticClass: "field" },
                [
                  _c(
                    "validator",
                    {
                      attrs: {
                        value: _vm.app.parentLawsuitDetails,
                        reqs: _vm.app.parentLawsuit * 1 ? "required" : ""
                      }
                    },
                    [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.app.parentLawsuitDetails,
                            expression: "app.parentLawsuitDetails"
                          }
                        ],
                        attrs: {
                          disabled: !(_vm.app.parentLawsuit * 1),
                          placeholder: "Explain"
                        },
                        domProps: { value: _vm.app.parentLawsuitDetails },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.app,
                              "parentLawsuitDetails",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("ul", { staticClass: "column-form type2" }, [
        _vm._m(1),
        _vm._v(" "),
        _c("li", [
          _c("label", [_vm._v("Company registration number")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field" },
            [
              _c(
                "validator",
                {
                  attrs: {
                    value: _vm.app.companyRegnum,
                    reqs: "required regnum"
                  }
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.app.companyRegnum,
                        expression: "app.companyRegnum"
                      }
                    ],
                    attrs: { type: "text" },
                    domProps: { value: _vm.app.companyRegnum },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.app, "companyRegnum", $event.target.value)
                      }
                    }
                  })
                ]
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _c("label", [_vm._v("Legal name of company")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field" },
            [
              _c(
                "validator",
                {
                  attrs: {
                    value: _vm.app.companyName,
                    reqs: "required legalName"
                  }
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.app.companyName,
                        expression: "app.companyName"
                      }
                    ],
                    attrs: { type: "text" },
                    domProps: { value: _vm.app.companyName },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.app, "companyName", $event.target.value)
                      }
                    }
                  })
                ]
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _c("label", [_vm._v("Registered DBA/Trade name")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field" },
            [
              _c(
                "validator",
                {
                  attrs: { value: _vm.app.companyNameTrade, reqs: "legalName" }
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.app.companyNameTrade,
                        expression: "app.companyNameTrade"
                      }
                    ],
                    attrs: { type: "text" },
                    domProps: { value: _vm.app.companyNameTrade },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.app,
                          "companyNameTrade",
                          $event.target.value
                        )
                      }
                    }
                  })
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("span", { staticClass: "pfx-info" }, [
            _vm._v("(if other than legal name)")
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("label", [_vm._v("Type of business")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field" },
            [
              _c(
                "validator",
                {
                  attrs: {
                    value: _vm.app.companyType,
                    reqs: "required businessType"
                  }
                },
                [
                  _c("combo-select-ext", {
                    attrs: { options: _vm.tobOptions, type: "text" },
                    model: {
                      value: _vm.app.companyType,
                      callback: function($$v) {
                        _vm.$set(_vm.app, "companyType", $$v)
                      },
                      expression: "app.companyType"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("span", { staticClass: "pfx-info" }, [
            _vm._v("(LLC, Ltd, Sole proprietor, etc)")
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("label", [_vm._v("Registered street address")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field" },
            [
              _c(
                "validator",
                {
                  attrs: {
                    value: _vm.app.companyAddress,
                    reqs: "required address"
                  }
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.app.companyAddress,
                        expression: "app.companyAddress"
                      }
                    ],
                    attrs: { type: "text" },
                    domProps: { value: _vm.app.companyAddress },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.app, "companyAddress", $event.target.value)
                      }
                    }
                  })
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("span", { staticClass: "pfx-info" }, [
            _vm._v("(P.O. Box not accepted)")
          ])
        ]),
        _vm._v(" "),
        false
          ? _c("li", [
              _c("label", [_vm._v("House number")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                [
                  _c(
                    "validator",
                    {
                      attrs: {
                        value: _vm.app.companyAddressNum,
                        reqs: "number"
                      }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.app.companyAddressNum,
                            expression: "app.companyAddressNum"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.app.companyAddressNum },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.app,
                              "companyAddressNum",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("li", [
          _c("label", [_vm._v("Postal code")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field" },
            [
              _c(
                "validator",
                {
                  attrs: {
                    value: _vm.app.companyAddressPostcode,
                    reqs: "required postcode"
                  }
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.app.companyAddressPostcode,
                        expression: "app.companyAddressPostcode"
                      }
                    ],
                    attrs: { type: "text" },
                    domProps: { value: _vm.app.companyAddressPostcode },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.app,
                          "companyAddressPostcode",
                          $event.target.value
                        )
                      }
                    }
                  })
                ]
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _c("label", [_vm._v("City")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field" },
            [
              _c(
                "validator",
                {
                  attrs: {
                    value: _vm.app.companyAddressCity,
                    reqs: "required city"
                  }
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.app.companyAddressCity,
                        expression: "app.companyAddressCity"
                      }
                    ],
                    attrs: { type: "text" },
                    domProps: { value: _vm.app.companyAddressCity },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.app,
                          "companyAddressCity",
                          $event.target.value
                        )
                      }
                    }
                  })
                ]
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _c("label", [_vm._v("Province")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field" },
            [
              _vm.app.companyAddressCountry == "CA" ||
              _vm.app.companyAddressCountry == "US"
                ? _c(
                    "validator",
                    {
                      attrs: {
                        value: _vm.app.companyAddressState,
                        reqs: "required"
                      }
                    },
                    [
                      _c("province-select", {
                        attrs: { country: _vm.app.companyAddressCountry },
                        model: {
                          value: _vm.app.companyAddressState,
                          callback: function($$v) {
                            _vm.$set(_vm.app, "companyAddressState", $$v)
                          },
                          expression: "app.companyAddressState"
                        }
                      })
                    ],
                    1
                  )
                : _c("validator", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.app.companyAddressState,
                          expression: "app.companyAddressState"
                        }
                      ],
                      attrs: { type: "text" },
                      domProps: { value: _vm.app.companyAddressState },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.app,
                            "companyAddressState",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ])
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _c("label", [_vm._v("Country")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field" },
            [
              _c(
                "validator",
                {
                  attrs: {
                    value: _vm.app.companyAddressCountry,
                    reqs: "required"
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "combo-select" },
                    [
                      _c("dyn-select", {
                        attrs: { options: _vm.validCountries },
                        model: {
                          value: _vm.app.companyAddressCountry,
                          callback: function($$v) {
                            _vm.$set(_vm.app, "companyAddressCountry", $$v)
                          },
                          expression: "app.companyAddressCountry"
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _c("label", [_vm._v("Telephone number")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field" },
            [
              _c(
                "validator",
                {
                  attrs: { value: _vm.app.companyPhone, reqs: "required phone" }
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.app.companyPhone,
                        expression: "app.companyPhone"
                      }
                    ],
                    attrs: { type: "text" },
                    domProps: { value: _vm.app.companyPhone },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.app, "companyPhone", $event.target.value)
                      }
                    }
                  })
                ]
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _c("label", [_vm._v("Company website")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field" },
            [
              _c(
                "validator",
                { attrs: { value: _vm.app.companyFax, reqs: "" } },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.app.companyFax,
                        expression: "app.companyFax"
                      }
                    ],
                    attrs: { type: "text" },
                    domProps: { value: _vm.app.companyFax },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.app, "companyFax", $event.target.value)
                      }
                    }
                  })
                ]
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _c("label", [_vm._v("Company Email")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field" },
            [
              _c(
                "validator",
                {
                  attrs: {
                    value: _vm.app.companyEmail,
                    reqs: "required email char100"
                  }
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.app.companyEmail,
                        expression: "app.companyEmail"
                      }
                    ],
                    attrs: { type: "text" },
                    domProps: { value: _vm.app.companyEmail },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.app, "companyEmail", $event.target.value)
                      }
                    }
                  })
                ]
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "sthird" }, [
          _c("label", [_vm._v("Turnover last year")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field" },
            [
              _c(
                "validator",
                {
                  attrs: {
                    value:
                      _vm.app.companyTurnover &&
                      _vm.app.companyTurnoverCurrency,
                    reqs: ""
                  }
                },
                [
                  _c("money", {
                    attrs: {
                      amount: _vm.app.companyTurnover,
                      currency: _vm.app.companyTurnoverCurrency
                    },
                    on: {
                      "update:amount": function($event) {
                        return _vm.$set(_vm.app, "companyTurnover", $event)
                      },
                      "update:currency": function($event) {
                        return _vm.$set(
                          _vm.app,
                          "companyTurnoverCurrency",
                          $event
                        )
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("span", { staticClass: "pfx-info" }, [_vm._v("(plus currency)")])
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "sthird" }, [
          _c("label", [_vm._v("Incorporation date")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field" },
            [
              _c(
                "validator",
                { attrs: { value: _vm.app.companyDate, reqs: "required" } },
                [
                  _c("datepicker", {
                    attrs: { reqs: "past" },
                    model: {
                      value: _vm.app.companyDate,
                      callback: function($$v) {
                        _vm.$set(_vm.app, "companyDate", $$v)
                      },
                      expression: "app.companyDate"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("span", { staticClass: "pfx-info" }, [_vm._v("(YYYY MM DD)")])
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "sthird" }, [
          _c("label", [_vm._v("Tax identification number")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field" },
            [
              _c(
                "validator",
                {
                  attrs: { value: _vm.app.companyTaxId, reqs: "required num20" }
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.app.companyTaxId,
                        expression: "app.companyTaxId"
                      }
                    ],
                    attrs: { type: "text" },
                    domProps: { value: _vm.app.companyTaxId },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.app, "companyTaxId", $event.target.value)
                      }
                    }
                  })
                ]
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "sthird" }, [
          _c("label", [
            _vm._v("Is company publicly listed on a stock exchange?")
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field" },
            [
              _c(
                "validator",
                { attrs: { value: _vm.app.companyIsPublic, reqs: "required" } },
                [
                  _c("formel-toggle", {
                    model: {
                      value: _vm.app.companyIsPublic,
                      callback: function($$v) {
                        _vm.$set(_vm.app, "companyIsPublic", $$v)
                      },
                      expression: "app.companyIsPublic"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "sthird" }, [
          _c("label", [
            _vm._v('Is company a registered "not-for-profit" organization?')
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field" },
            [
              _c(
                "validator",
                {
                  attrs: { value: _vm.app.companyIsNonProfit, reqs: "required" }
                },
                [
                  _c("formel-toggle", {
                    model: {
                      value: _vm.app.companyIsNonProfit,
                      callback: function($$v) {
                        _vm.$set(_vm.app, "companyIsNonProfit", $$v)
                      },
                      expression: "app.companyIsNonProfit"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "sthird" }, [
          _c("label", [
            _vm._v(
              "Are there any current legal proceedings being taken against the business, parent copmany or any associated directors?"
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field" },
            [
              _c(
                "validator",
                { attrs: { value: _vm.app.companyLawsuit, reqs: "required" } },
                [
                  _c("formel-toggle", {
                    model: {
                      value: _vm.app.companyLawsuit,
                      callback: function($$v) {
                        _vm.$set(_vm.app, "companyLawsuit", $$v)
                      },
                      expression: "app.companyLawsuit"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "sthird" }, [
          _c("label", [
            _vm._v(
              "Has the business, parent company or any associated director ever been declined for processing?"
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field" },
            [
              _c(
                "validator",
                { attrs: { value: _vm.app.companyDeclined, reqs: "required" } },
                [
                  _c("formel-toggle", {
                    model: {
                      value: _vm.app.companyDeclined,
                      callback: function($$v) {
                        _vm.$set(_vm.app, "companyDeclined", $$v)
                      },
                      expression: "app.companyDeclined"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "sthird" }, [
          _c("label", [
            _vm._v(
              "Has the business, parent company or any associated director ever been terminated for processing?"
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field" },
            [
              _c(
                "validator",
                {
                  attrs: { value: _vm.app.companyTerminated, reqs: "required" }
                },
                [
                  _c("formel-toggle", {
                    model: {
                      value: _vm.app.companyTerminated,
                      callback: function($$v) {
                        _vm.$set(_vm.app, "companyTerminated", $$v)
                      },
                      expression: "app.companyTerminated"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "sthird" }, [
          _c(
            "div",
            { staticClass: "field" },
            [
              _c(
                "validator",
                {
                  attrs: {
                    value: _vm.app.companyTerminatedDetails,
                    reqs: _vm.app.companyTerminated * 1 ? "required" : ""
                  }
                },
                [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.app.companyTerminatedDetails,
                        expression: "app.companyTerminatedDetails"
                      }
                    ],
                    attrs: {
                      disabled: !(_vm.app.companyTerminated * 1),
                      placeholder: "Explain"
                    },
                    domProps: { value: _vm.app.companyTerminatedDetails },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.app,
                          "companyTerminatedDetails",
                          $event.target.value
                        )
                      }
                    }
                  })
                ]
              )
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c("ul", { staticClass: "footnotes" }, [
        _vm._m(2),
        _vm._v(" "),
        _vm._m(3),
        _vm._v(" "),
        false
          ? _c("li", [
              _c("sup", [_vm._v("3")]),
              _vm._v(
                " If not provided, VAT may be applicable to transaction charges"
              )
            ])
          : _vm._e()
      ])
    ]),
    _vm._v(" "),
    _c("section", [
      _c("h2", [_vm._v("Location Address")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pgf-main-cb" },
        [
          _c("p", { staticClass: "intro" }, [
            _vm._v("Is location address different from registered address?")
          ]),
          _vm._v(" "),
          _c(
            "validator",
            { attrs: { value: _vm.app.billingDifferent, reqs: "required" } },
            [
              _c("ul", { staticClass: "toggle-main" }, [
                _c("li", [
                  _c(
                    "label",
                    { class: { checked: _vm.app.billingDifferent === "1" } },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.app.billingDifferent,
                            expression: "app.billingDifferent"
                          }
                        ],
                        attrs: {
                          type: "radio",
                          name: "billingDifferent",
                          value: "1"
                        },
                        domProps: {
                          checked: _vm._q(_vm.app.billingDifferent, "1")
                        },
                        on: {
                          change: function($event) {
                            return _vm.$set(_vm.app, "billingDifferent", "1")
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("i"),
                      _vm._v(" "),
                      _c("strong", [_vm._v("Yes")]),
                      _vm._v(" "),
                      _c("span", [_vm._v("(provide location address below)")])
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c(
                    "label",
                    { class: { checked: _vm.app.billingDifferent === "0" } },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.app.billingDifferent,
                            expression: "app.billingDifferent"
                          }
                        ],
                        attrs: {
                          type: "radio",
                          name: "billingDifferent",
                          value: "0"
                        },
                        domProps: {
                          checked: _vm._q(_vm.app.billingDifferent, "0")
                        },
                        on: {
                          change: function($event) {
                            return _vm.$set(_vm.app, "billingDifferent", "0")
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("i"),
                      _vm._v(" "),
                      _c("strong", [_vm._v("No")]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          "(proceed to section 2: Company Ownership Profile)"
                        )
                      ])
                    ]
                  )
                ])
              ])
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.app.billingDifferent === "1"
        ? _c("ul", { staticClass: "column-form type2" }, [
            _c("li", [
              _c("label", [_vm._v("Street address")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                [
                  _c(
                    "validator",
                    {
                      attrs: {
                        value: _vm.app.billingAddress,
                        reqs: "required address"
                      }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.app.billingAddress,
                            expression: "app.billingAddress"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.app.billingAddress },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.app,
                              "billingAddress",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("span", { staticClass: "pfx-info" }, [
                _vm._v("(for billing purposes)")
              ])
            ]),
            _vm._v(" "),
            false
              ? _c("li", [
                  _c("label", [_vm._v("House number")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "field" },
                    [
                      _c(
                        "validator",
                        {
                          attrs: {
                            value: _vm.app.billingAddressNum,
                            reqs: "required address"
                          }
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.app.billingAddressNum,
                                expression: "app.billingAddressNum"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: { value: _vm.app.billingAddressNum },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.app,
                                  "billingAddressNum",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ]
                      )
                    ],
                    1
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("li", [
              _c("label", [_vm._v("Postal code")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                [
                  _c(
                    "validator",
                    {
                      attrs: {
                        value: _vm.app.billingAddressPostcode,
                        reqs: "required postcode"
                      }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.app.billingAddressPostcode,
                            expression: "app.billingAddressPostcode"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.app.billingAddressPostcode },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.app,
                              "billingAddressPostcode",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("li", [
              _c("label", [_vm._v("City")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                [
                  _c(
                    "validator",
                    {
                      attrs: {
                        value: _vm.app.billingAddressCity,
                        reqs: "required city"
                      }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.app.billingAddressCity,
                            expression: "app.billingAddressCity"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.app.billingAddressCity },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.app,
                              "billingAddressCity",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("li", [
              _c("label", [_vm._v("Province")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                [
                  _vm.app.billingAddressCountry == "CA" ||
                  _vm.app.billingAddressCountry == "US"
                    ? _c(
                        "validator",
                        {
                          attrs: {
                            value: _vm.app.billingAddressState,
                            reqs: "required state"
                          }
                        },
                        [
                          _c("province-select", {
                            attrs: { country: _vm.app.billingAddressCountry },
                            model: {
                              value: _vm.app.billingAddressState,
                              callback: function($$v) {
                                _vm.$set(_vm.app, "billingAddressState", $$v)
                              },
                              expression: "app.billingAddressState"
                            }
                          })
                        ],
                        1
                      )
                    : _c("validator", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.app.billingAddressState,
                              expression: "app.billingAddressState"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: _vm.app.billingAddressState },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.app,
                                "billingAddressState",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("li", [
              _c("label", [_vm._v("Country")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                [
                  _c(
                    "validator",
                    {
                      attrs: {
                        value: _vm.app.billingAddressCountry,
                        reqs: "required state"
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "combo-select" },
                        [
                          _c("dyn-select", {
                            attrs: { options: _vm.validCountries },
                            model: {
                              value: _vm.app.billingAddressCountry,
                              callback: function($$v) {
                                _vm.$set(_vm.app, "billingAddressCountry", $$v)
                              },
                              expression: "app.billingAddressCountry"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ])
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "buttons" }, [
      _c(
        "a",
        {
          staticClass: "btn btn-large btn-plain btn-back",
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.$emit("prev")
            }
          }
        },
        [_vm._v("Back")]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "btn btn-large btn-warn",
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.$emit("save")
            }
          }
        },
        [_vm._v("Save")]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "btn btn-large btn-success btn-next",
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.nextStep.apply(null, arguments)
            }
          }
        },
        [_vm._v("Next Step")]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "head" }, [
      _c("label", [_vm._v(" ")]),
      _vm._v(" "),
      _c("div", [_c("div", [_vm._v("Details of Parent Company")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "head" }, [
      _c("label", [_vm._v(" ")]),
      _vm._v(" "),
      _c("div", [_c("div", [_vm._v("Details of Applying Company")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("sup", [_vm._v("1")]),
      _vm._v(" Should be mentioned on incorporation documents")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("sup", [_vm._v("2")]),
      _vm._v(
        " Including international country code (eg. +1 for USA, +852 for Hong Kong, etc)"
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }