var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-table-container file-list-container" },
    [
      _c("table", { staticClass: "file-list content-table" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "tbody",
          _vm._l(_vm.emails, function(email) {
            return _c("tr", [
              _c("td", [_vm._v(_vm._s(email.emailType))]),
              _vm._v(" "),
              _c("td", [
                _vm._v(
                  _vm._s(_vm.formatDate(email.created, "YYYY-MM-DD HH:mm:ss"))
                )
              ])
            ])
          }),
          0
        )
      ]),
      _vm._v(" "),
      _c("portal", { attrs: { to: "actions" } }, [
        _c(
          "ul",
          [
            _vm.item.emailConfirmation === false
              ? [
                  _vm.settings.settings.emailNotifications.emailConfirmation
                    ? [
                        _vm.item.accountType == "Personal"
                          ? [
                              _vm.item.personal.status == "A02"
                                ? [
                                    _c("li", [
                                      _c(
                                        "a",
                                        {
                                          attrs: { href: "" },
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              return _vm.sendConfirmationEmail1()
                                            }
                                          }
                                        },
                                        [_vm._v("Email Confirmation")]
                                      )
                                    ])
                                  ]
                                : _vm._e()
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.item.accountType == "Business"
                          ? [
                              _vm.item.business.status == "A02"
                                ? [
                                    _c("li", [
                                      _c(
                                        "a",
                                        {
                                          attrs: { href: "" },
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              return _vm.sendConfirmationEmail1()
                                            }
                                          }
                                        },
                                        [_vm._v("Email Confirmation")]
                                      )
                                    ])
                                  ]
                                : _vm._e()
                            ]
                          : _vm._e()
                      ]
                    : _vm._e()
                ]
              : _vm._e()
          ],
          2
        )
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Type")]),
        _vm._v(" "),
        _c("th", [_vm._v("Created")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }