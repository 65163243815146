var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "preform-outer" }, [
    _c("div", { staticClass: "preformbg" }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "ncontent appf appnat_ preform pfx pfx-simulation" },
      [
        _c("div", { staticClass: "cb-logo" }, [_vm._v("CardBiller")]),
        _vm._v(" "),
        _c("h1", [_vm._v("Merchant Application")]),
        _vm._v(" "),
        _c("div", { staticClass: "form-info" }, [
          _c("p", [_vm._v("Application Assistance")]),
          _vm._v(" "),
          _c("p", [
            _c("strong", [_vm._v(_vm._s(_vm.companydata.applicationEmail))])
          ]),
          _vm._v(" "),
          _c("p", [_c("strong", [_vm._v(_vm._s(_vm.companydata.servicesTel))])])
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "form-intro" }, [
          _vm._v(
            "\n    Please complete this form in full. Incomplete forms are not accepted.\n  "
          )
        ]),
        _vm._v(" "),
        false
          ? _c("div", { staticClass: "temp-link" }, [
              _c("label", [_vm._v("Guest access to this application:")]),
              _vm._v(" "),
              !_vm.link
                ? _c("span", [
                    _c(
                      "button",
                      {
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.save.apply(null, arguments)
                          }
                        }
                      },
                      [_vm._v("Generate and Save Link")]
                    )
                  ])
                : _c(
                    "a",
                    {
                      attrs: { href: _vm.link.url, target: "_blank" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.copyLink.apply(null, arguments)
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.link.url))]
                  )
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("form-apply-0", { attrs: { app: _vm.app } }),
        _vm._v(" "),
        _c("form-apply-1", { attrs: { app: _vm.app } }),
        _vm._v(" "),
        _c("form-apply-2", { attrs: { app: _vm.app, auxtype: _vm.auxtype } }),
        _vm._v(" "),
        _c("form-apply-3", { attrs: { app: _vm.app } }),
        _vm._v(" "),
        _c("form-apply-4", { attrs: { app: _vm.app } }),
        _vm._v(" "),
        _c("form-apply-5", { attrs: { app: _vm.app } }),
        _vm._v(" "),
        _c("form-apply-6", { attrs: { app: _vm.app } }),
        _vm._v(" "),
        _c("form-apply-7", {
          attrs: {
            app: _vm.app,
            basepath: _vm.basepath,
            files: _vm.files,
            simulation: true
          },
          on: { next: _vm.submit, reset: _vm.reset }
        }),
        _vm._v(" "),
        _c(
          "ul",
          { staticClass: "notes" },
          [
            _vm.errorMessage
              ? _c("li", [
                  _vm._v(_vm._s(_vm.errorCode) + " " + _vm._s(_vm.errorMessage))
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.errorFields, function(field) {
              return _c("li", [_vm._v(_vm._s(field))])
            })
          ],
          2
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }