var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "amadel-logo-wrapper" },
        [
          _c("page-header", {
            attrs: { title: _vm.page.title, breadcrumbs: _vm.breadcrumbs }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("sub-menu", {
        attrs: {
          dashboard: { to: "/agents/amadel", text: "Amadel Dashboard" },
          menu: _vm.menus
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }