var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "boarding-container" }, [
    _c("aside", { staticClass: "boarding-steps" }, [
      _c(
        "ul",
        _vm._l(_vm.steps, function(stepLabel, si) {
          return _c(
            "li",
            {
              class: { current: _vm.step == si, available: si <= _vm.doneSteps }
            },
            [
              _c(
                "a",
                {
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.goToStep(si)
                    }
                  }
                },
                [_vm._v(_vm._s(stepLabel))]
              )
            ]
          )
        }),
        0
      )
    ]),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "boarding-main" },
      [
        _vm.item.status == "A07"
          ? _c("div", [_vm._v("\n      Activation\n    ")])
          : [
              _vm.step == 0
                ? _c("partner-boarding-profile", {
                    attrs: { item: _vm.item },
                    on: { next: _vm.goToNext }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.step == 1
                ? _c("partner-boarding-documents", {
                    attrs: { item: _vm.item },
                    on: {
                      next: _vm.goToNext,
                      back: function($event) {
                        return _vm.goToStep(0)
                      },
                      reloadnotes: function($event) {
                        return _vm.$emit("reloadnotes")
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.step == 2
                ? _c("partner-boarding-compliance", {
                    attrs: { item: _vm.item },
                    on: {
                      next: _vm.goToNext,
                      back: function($event) {
                        return _vm.goToStep(1)
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.step == 3
                ? _c("partner-boarding-final", {
                    attrs: { item: _vm.item, approvalList: _vm.approvalList },
                    on: {
                      next: _vm.goToNext,
                      back: function($event) {
                        return _vm.goToStep(2)
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.step == 4
                ? _c("partner-boarding-boarding", {
                    attrs: { item: _vm.item },
                    on: {
                      next: _vm.goToNext,
                      back: function($event) {
                        return _vm.goToStep(3)
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.step == 5
                ? _c("div", [_vm._v("\n        Boarding completed\n      ")])
                : _vm._e()
            ]
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }