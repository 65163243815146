var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "view-profile",
    {
      attrs: { header: ["Employee Detail"] },
      scopedSlots: _vm._u(
        [
          {
            key: "tabsmain",
            fn: function() {
              return [
                _c(
                  "ul",
                  _vm._l(_vm.tabs, function(tabData, tabId) {
                    return _c("li", { class: { active: _vm.tab == tabId } }, [
                      _c(
                        "a",
                        {
                          attrs: { href: "" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              _vm.tab = tabId
                            }
                          }
                        },
                        [_vm._v(_vm._s(tabData.label))]
                      )
                    ])
                  }),
                  0
                ),
                _vm._v(" "),
                _vm.employee
                  ? _c("div", { staticClass: "status" }, [
                      _c("label", [
                        _vm._v("Employee ID: "),
                        _c("strong", [_vm._v(_vm._s(_vm.employee.employeeId))])
                      ]),
                      _vm._v(" "),
                      _c("label", [
                        _vm._v("Name: "),
                        _c("strong", [
                          _vm._v(
                            _vm._s(_vm.employee.first_name) +
                              " " +
                              _vm._s(_vm.employee.last_name)
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("label", [
                        _vm._v("Status: "),
                        _c("strong", [_vm._v(_vm._s(_vm.employee.status))])
                      ])
                    ])
                  : _vm._e()
              ]
            },
            proxy: true
          },
          _vm.tab == "general"
            ? {
                key: "tabssub",
                fn: function() {
                  return [
                    _c(
                      "ul",
                      _vm._l(_vm.profileTabs1, function(tabData, tabId) {
                        return _c(
                          "li",
                          { class: { active: _vm.subTab == tabId } },
                          [
                            _c(
                              "a",
                              {
                                attrs: { href: "" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    _vm.subTab = tabId
                                  }
                                }
                              },
                              [_vm._v(_vm._s(tabData.label))]
                            )
                          ]
                        )
                      }),
                      0
                    )
                  ]
                },
                proxy: true
              }
            : _vm.tab == "employee"
            ? {
                key: "tabssub",
                fn: function() {
                  return [
                    _c(
                      "ul",
                      _vm._l(_vm.profileTabs2, function(tabData, tabId) {
                        return _c(
                          "li",
                          { class: { active: _vm.subTab == tabId } },
                          [
                            _c(
                              "a",
                              {
                                attrs: { href: "" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    _vm.subTab = tabId
                                  }
                                }
                              },
                              [_vm._v(_vm._s(tabData.label))]
                            )
                          ]
                        )
                      }),
                      0
                    )
                  ]
                },
                proxy: true
              }
            : _vm.tab == "notes"
            ? {
                key: "tabssub",
                fn: function() {
                  return [
                    _c(
                      "ul",
                      _vm._l(_vm.profileTabs3, function(tabData, tabId) {
                        return _c(
                          "li",
                          { class: { active: _vm.subTab == tabId } },
                          [
                            _c(
                              "a",
                              {
                                attrs: { href: "" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    _vm.subTab = tabId
                                  }
                                }
                              },
                              [_vm._v(_vm._s(tabData.label))]
                            )
                          ]
                        )
                      }),
                      0
                    )
                  ]
                },
                proxy: true
              }
            : _vm.tab == "access"
            ? {
                key: "tabssub",
                fn: function() {
                  return [
                    _c(
                      "ul",
                      _vm._l(_vm.profileTabs4, function(tabData, tabId) {
                        return _c(
                          "li",
                          { class: { active: _vm.subTab == tabId } },
                          [
                            _c(
                              "a",
                              {
                                attrs: { href: "" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    _vm.subTab = tabId
                                  }
                                }
                              },
                              [_vm._v(_vm._s(tabData.label))]
                            )
                          ]
                        )
                      }),
                      0
                    )
                  ]
                },
                proxy: true
              }
            : _vm.tab == "documents"
            ? {
                key: "tabssub",
                fn: function() {
                  return [
                    _c(
                      "ul",
                      _vm._l([], function(tabData, tabId) {
                        return _c(
                          "li",
                          { class: { active: _vm.subTab == tabId } },
                          [
                            _c(
                              "a",
                              {
                                attrs: { href: "" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    _vm.subTab = tabId
                                  }
                                }
                              },
                              [_vm._v(_vm._s(tabData.label))]
                            )
                          ]
                        )
                      }),
                      0
                    )
                  ]
                },
                proxy: true
              }
            : _vm.tab == "general"
            ? {
                key: "tabssub",
                fn: function() {
                  return [
                    _c(
                      "ul",
                      _vm._l(_vm.generalTabs, function(tabData, tabId) {
                        return _c(
                          "li",
                          { class: { active: _vm.subTab == tabId } },
                          [
                            _c(
                              "a",
                              {
                                attrs: { href: "" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    _vm.subTab = tabId
                                  }
                                }
                              },
                              [_vm._v(_vm._s(tabData.label))]
                            )
                          ]
                        )
                      }),
                      0
                    )
                  ]
                },
                proxy: true
              }
            : null,
          {
            key: "actions",
            fn: function() {
              return [_c("PortalTarget", { attrs: { name: "actions" } })]
            },
            proxy: true
          }
        ],
        null,
        true
      )
    },
    [
      _vm._v(" "),
      _vm._v(" "),
      _vm._v(" "),
      _c("div", { staticClass: "merchant-panel" }, [
        (_vm.tab == "general" ||
          _vm.tab == "employee" ||
          _vm.tab == "notes" ||
          _vm.tab == "access") &&
        _vm.employee
          ? _c("div", { staticClass: "data-content" }, [
              _c(
                "div",
                { staticClass: "main" },
                [
                  _c("employee-data-activity", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.subTab === "activity",
                        expression: "subTab === 'activity'"
                      }
                    ],
                    attrs: { employee: _vm.employee }
                  }),
                  _vm._v(" "),
                  _c(
                    "form",
                    { staticClass: "preq-form preq-view" },
                    [
                      _vm.subTab === "notes"
                        ? _c("notes", {
                            ref: "notes",
                            staticClass: "appl-notes",
                            attrs: {
                              notes: _vm.notes,
                              type: "Employee",
                              id: _vm.id
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.subTab === "general"
                        ? _c("employee-data-general2", {
                            attrs: {
                              employee: _vm.employee,
                              value: _vm.form,
                              editMode: _vm.editMode
                            },
                            on: {
                              "update:editMode": function($event) {
                                _vm.editMode = $event
                              },
                              "update:edit-mode": function($event) {
                                _vm.editMode = $event
                              }
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.subTab === "general-old"
                        ? _c("employee-data-general", {
                            attrs: {
                              employee: _vm.employee,
                              value: _vm.form,
                              editMode: _vm.editMode
                            },
                            on: {
                              "update:editMode": function($event) {
                                _vm.editMode = $event
                              },
                              "update:edit-mode": function($event) {
                                _vm.editMode = $event
                              }
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.subTab === "profile"
                        ? _c("employee-data-profile", {
                            attrs: {
                              employee: _vm.employee,
                              value: _vm.form,
                              editMode: _vm.editMode
                            },
                            on: {
                              "update:editMode": function($event) {
                                _vm.editMode = $event
                              },
                              "update:edit-mode": function($event) {
                                _vm.editMode = $event
                              }
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.subTab === "personal"
                        ? _c("employee-data-personal", {
                            attrs: {
                              employee: _vm.employee,
                              value: _vm.form,
                              editMode: _vm.editMode
                            },
                            on: {
                              "update:editMode": function($event) {
                                _vm.editMode = $event
                              },
                              "update:edit-mode": function($event) {
                                _vm.editMode = $event
                              }
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.subTab === "access"
                        ? _c("employee-data-access", {
                            ref: "access",
                            attrs: {
                              employee: _vm.employee,
                              value: _vm.form,
                              editMode: _vm.editMode
                            },
                            on: {
                              "update:editMode": function($event) {
                                _vm.editMode = $event
                              },
                              "update:edit-mode": function($event) {
                                _vm.editMode = $event
                              },
                              active: function($event) {
                                _vm.employee.userId.active = $event
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.editMode
                    ? _c("div", { staticClass: "form-buttons" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-small",
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                return _vm.edit()
                              }
                            }
                          },
                          [_vm._v("Save")]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-small",
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                _vm.editMode = false
                              }
                            }
                          },
                          [_vm._v("Cancel")]
                        )
                      ])
                    : _vm._e()
                ],
                1
              )
            ])
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }