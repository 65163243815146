var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "content-table-container" }, [
      _c("table", { staticClass: "content-table" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "tbody",
          [
            _vm._l(_vm.items, function(item, index) {
              return _c("tr", [
                _c(
                  "td",
                  { staticClass: "text-left", staticStyle: { width: "5%" } },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: item.enabled,
                          expression: "item.enabled"
                        }
                      ],
                      attrs: { type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(item.enabled)
                          ? _vm._i(item.enabled, null) > -1
                          : item.enabled
                      },
                      on: {
                        change: function($event) {
                          var $$a = item.enabled,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(item, "enabled", $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  item,
                                  "enabled",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(item, "enabled", $$c)
                          }
                        }
                      }
                    })
                  ]
                ),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "text-left", staticStyle: { width: "45%" } },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: item.name,
                          expression: "item.name"
                        }
                      ],
                      staticStyle: { "text-align": "left" },
                      attrs: { type: "text" },
                      domProps: { value: item.name },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(item, "name", $event.target.value)
                        }
                      }
                    })
                  ]
                ),
                _vm._v(" "),
                _c("td", { staticStyle: { width: "20%" } }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: item.purchase,
                        expression: "item.purchase"
                      }
                    ],
                    staticStyle: { "text-align": "left" },
                    attrs: { type: "text", step: "0.01" },
                    domProps: { value: item.purchase },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(item, "purchase", $event.target.value)
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("td", { staticStyle: { width: "20%" } }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: item.rental,
                        expression: "item.rental"
                      }
                    ],
                    staticStyle: { "text-align": "left" },
                    attrs: { type: "text", step: "0.01" },
                    domProps: { value: item.rental },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(item, "rental", $event.target.value)
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("td", [
                  _c(
                    "a",
                    {
                      staticClass: "remove",
                      attrs: { href: "" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.deleteItem(index)
                        }
                      }
                    },
                    [_vm._v("×")]
                  )
                ])
              ])
            }),
            _vm._v(" "),
            _c("tr", { staticClass: "new-row" }, [
              _c("td", { attrs: { colspan: "5" } }, [
                _c(
                  "a",
                  {
                    staticClass: "add",
                    attrs: { href: "" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.newItem.apply(null, arguments)
                      }
                    }
                  },
                  [_vm._v("+")]
                )
              ])
            ])
          ],
          2
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "buttons" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-large btn-primary",
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.updateEquipment.apply(null, arguments)
            }
          }
        },
        [_vm._v("Submit")]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticStyle: { width: "5%" } }, [_vm._v("Enabled")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "45%" } }, [_vm._v("Equipment")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "20%" } }, [_vm._v("Purchase Price")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "20%" } }, [_vm._v("Rental Price")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "10%" } }, [_vm._v(" ")])
      ]),
      _vm._v(" "),
      _c("tr", [_c("th", { staticClass: "spacer", attrs: { colspan: "3" } })])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }