var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.value
    ? _c(
        "div",
        {
          staticClass: "form-popup popup-detail activex",
          class: { active: _vm.value }
        },
        [
          _c(
            "div",
            {
              staticClass: "ncontent appnat",
              style: _vm.autoWidth ? "width: auto;" : null
            },
            [
              _c("div", { staticClass: "title" }, [
                _c(
                  "a",
                  {
                    staticClass: "close",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.$emit("close")
                      }
                    }
                  },
                  [_vm._v("×")]
                )
              ]),
              _vm._v(" "),
              _vm._t("default")
            ],
            2
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }