var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "notes-list" }, [
    _c("div", { staticClass: "notes-props" }, [
      _c("span", [
        _vm._v("Messaging: "),
        _c("em", [
          _vm._v(_vm._s(_vm.application.apiMessaging ? "Enabled" : "Disabled"))
        ])
      ]),
      _vm._v(" "),
      _c("span", [
        _vm._v("Updates: "),
        _c("em", [
          _vm._v(_vm._s(_vm.application.apiUpdates ? "Enabled" : "Disabled"))
        ])
      ])
    ]),
    _vm._v(" "),
    _c("h4", [_vm._v("Messages")]),
    _vm._v(" "),
    _c(
      "ul",
      [
        _vm._l(_vm.notesList, function(note, ni) {
          return _vm.filter == null || note.system == _vm.filter
            ? _c("li", [
                note.system == 0
                  ? _c(
                      "a",
                      {
                        staticClass: "remove",
                        attrs: { href: "" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.removeNote(ni)
                          }
                        }
                      },
                      [_vm._v("×")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("strong", [
                  _vm._v(
                    _vm._s(
                      note.created === "CURRENT_TIMESTAMP"
                        ? _vm.now
                        : note.created
                    ) + "\n        "
                  ),
                  _c("em", { staticClass: "link" }, [
                    _vm._v(
                      _vm._s(
                        (note.author && note.author.username) ||
                          (note.apiAuthor && note.apiAuthor.username)
                      )
                    )
                  ]),
                  _vm._v(":\n      ")
                ]),
                _vm._v("\n      " + _vm._s(note.message) + "\n    ")
              ])
            : _vm._e()
        }),
        _vm._v(" "),
        !_vm.notesList.length
          ? _c("li", [_vm._v("\n      (no messages yet)\n    ")])
          : _vm._e()
      ],
      2
    ),
    _vm._v(" "),
    _vm.application.apiMessaging
      ? _c("div", { staticClass: "new-note" }, [
          _c("h4", [_vm._v("New message")]),
          _vm._v(" "),
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.newNote,
                expression: "newNote"
              }
            ],
            domProps: { value: _vm.newNote },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.newNote = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          _c(
            "button",
            {
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.createNote.apply(null, arguments)
                }
              }
            },
            [_vm._v("Create")]
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }