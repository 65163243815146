var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "ncontent nc-flat custom-width" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "ncontent touch-it" }, [
        _c("div", { staticClass: "merchant-panel" }, [
          _c("div", { staticClass: "data-content" }, [
            _c(
              "ul",
              { staticClass: "tabs" },
              _vm._l(_vm.tabs, function(tab, idx) {
                return _c("li", { class: { active: _vm.activeTab == idx } }, [
                  _vm._v(_vm._s(tab))
                ])
              }),
              0
            )
          ])
        ]),
        _vm._v(" "),
        _vm.activeTab === 0
          ? _c("div", { staticClass: "custom-form pad20" }, [
              _c(
                "div",
                { staticClass: "input-group" },
                [
                  _c("label", [_vm._v("First Name")]),
                  _vm._v(" "),
                  _c(
                    "validator",
                    { attrs: { value: _vm.form.first_name, reqs: "required" } },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.first_name,
                            expression: "form.first_name"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.form.first_name },
                        on: {
                          keypress: function($event) {
                            return _vm.allowLetterNumber($event, {
                              numbers: false
                            })
                          },
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.form,
                              "first_name",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "input-group" },
                [
                  _c("label", [_vm._v("Email First Name")]),
                  _vm._v(" "),
                  _c(
                    "validator",
                    {
                      attrs: {
                        value: _vm.form.email_first_name,
                        reqs: "required"
                      }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.email_first_name,
                            expression: "form.email_first_name"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.form.email_first_name },
                        on: {
                          keypress: function($event) {
                            return _vm.allowLetterNumber($event, {
                              numbers: false
                            })
                          },
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.form,
                              "email_first_name",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "input-group" },
                [
                  _c("label", [_vm._v("Last Name")]),
                  _vm._v(" "),
                  _c(
                    "validator",
                    { attrs: { value: _vm.form.last_name, reqs: "required" } },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.last_name,
                            expression: "form.last_name"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.form.last_name },
                        on: {
                          keypress: function($event) {
                            return _vm.allowLetterNumber($event, {
                              numbers: false
                            })
                          },
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.form, "last_name", $event.target.value)
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.companies
                ? _c(
                    "div",
                    { staticClass: "input-group" },
                    [
                      _c("label", [_vm._v("Employee Solution")]),
                      _vm._v(" "),
                      _c(
                        "validator",
                        {
                          attrs: { value: _vm.form.companyId, reqs: "required" }
                        },
                        [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.form.companyId,
                                  expression: "form.companyId"
                                }
                              ],
                              on: {
                                change: function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.form,
                                    "companyId",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                }
                              }
                            },
                            _vm._l(_vm.companies, function(company) {
                              return _c(
                                "option",
                                { domProps: { value: company.id } },
                                [_vm._v(_vm._s(company.nameSolution))]
                              )
                            }),
                            0
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "input-group" },
                [
                  _c("label", [_vm._v("Company Email")]),
                  _vm._v(" "),
                  _c("validator", { attrs: { value: "1", reqs: "required" } }, [
                    _c("select", { domProps: { value: null } }, [
                      _c("option", { domProps: { value: null } }, [
                        _vm._v(_vm._s(_vm.currentCompany.emailSuffix))
                      ])
                    ])
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "input-group" },
                [
                  _c("label", [_vm._v("Title")]),
                  _vm._v(" "),
                  _c(
                    "validator",
                    { attrs: { value: _vm.form.title, reqs: "required" } },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.title,
                            expression: "form.title"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.form.title },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.form, "title", $event.target.value)
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "input-group" },
                [
                  _c("label", [_vm._v("Email")]),
                  _vm._v(" "),
                  _c(
                    "validator",
                    {
                      attrs: { value: _vm.uniqueEmail, reqs: "required email" }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.email,
                            expression: "form.email"
                          }
                        ],
                        attrs: { type: "text", readonly: !_vm.customEmail },
                        domProps: { value: _vm.form.email },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.form, "email", $event.target.value)
                          }
                        }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c("label", { staticClass: "checkbox" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.customEmail,
                          expression: "customEmail"
                        }
                      ],
                      attrs: { type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(_vm.customEmail)
                          ? _vm._i(_vm.customEmail, null) > -1
                          : _vm.customEmail
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.customEmail,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.customEmail = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.customEmail = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.customEmail = $$c
                          }
                        }
                      }
                    }),
                    _vm._v("\n            Custom email\n          ")
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "input-group phone-input" }, [
                _c(
                  "div",
                  { staticClass: "w100" },
                  [
                    _c("label", [_vm._v("Phone")]),
                    _vm._v(" "),
                    _c(
                      "validator",
                      { attrs: { value: _vm.form.phone, reqs: "required" } },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.phone,
                              expression: "form.phone"
                            }
                          ],
                          staticClass: "w100",
                          attrs: { type: "text" },
                          domProps: { value: _vm.form.phone },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.form, "phone", $event.target.value)
                            }
                          }
                        })
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "ext" },
                  [
                    _c("label", [_vm._v("Ext")]),
                    _vm._v(" "),
                    _c(
                      "validator",
                      { attrs: { value: _vm.form.phone_ext, reqs: "" } },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.phone_ext,
                              expression: "form.phone_ext"
                            }
                          ],
                          attrs: { type: "text", maxlength: "5" },
                          domProps: { value: _vm.form.phone_ext },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.form,
                                "phone_ext",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ]
                    )
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "input-group" },
                [
                  _c("label", [_vm._v("Role")]),
                  _vm._v(" "),
                  _c(
                    "validator",
                    { attrs: { value: _vm.form.role, reqs: "required" } },
                    [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.role,
                              expression: "form.role"
                            }
                          ],
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.form,
                                "role",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            }
                          }
                        },
                        _vm._l(_vm.titleGroups, function(group) {
                          return _c(
                            "option",
                            { key: group, domProps: { value: group } },
                            [_vm._v(_vm._s(_vm.groups[group]))]
                          )
                        }),
                        0
                      )
                    ]
                  )
                ],
                1
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.activeTab === 1
          ? _c("div", { staticClass: "custom-form pad20" }, [
              _c("h3", { staticClass: "subtitle" }, [
                _vm._v("Personal Information")
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "input-group large" },
                [
                  _c("label", [_vm._v("Address")]),
                  _vm._v(" "),
                  _c(
                    "validator",
                    { attrs: { value: _vm.form.address, reqs: "required" } },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.address,
                            expression: "form.address"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.form.address },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.form, "address", $event.target.value)
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "input-group medium" },
                [
                  _c("label", [_vm._v("City")]),
                  _vm._v(" "),
                  _c(
                    "validator",
                    { attrs: { value: _vm.form.city, reqs: "required" } },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.city,
                            expression: "form.city"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.form.city },
                        on: {
                          keypress: function($event) {
                            return _vm.allowLetterNumber($event)
                          },
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.form, "city", $event.target.value)
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "input-group medium" },
                [
                  _c("label", [_vm._v("Province")]),
                  _vm._v(" "),
                  _c(
                    "validator",
                    { attrs: { value: _vm.form.province, reqs: "required" } },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.province,
                            expression: "form.province"
                          }
                        ],
                        attrs: { type: "text", maxlength: "2" },
                        domProps: { value: _vm.form.province },
                        on: {
                          keypress: function($event) {
                            return _vm.allowLetterNumber($event)
                          },
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.form, "province", $event.target.value)
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "input-group medium" },
                [
                  _c("label", [_vm._v("Postal")]),
                  _vm._v(" "),
                  _c(
                    "validator",
                    { attrs: { value: _vm.form.postal, reqs: "required" } },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.postal,
                            expression: "form.postal"
                          }
                        ],
                        attrs: { type: "text", maxlength: "7" },
                        domProps: { value: _vm.form.postal },
                        on: {
                          keypress: function($event) {
                            return _vm.allowLetterNumber($event, _vm.postalRule)
                          },
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.form, "postal", $event.target.value)
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "input-group medium" },
                [
                  _c("label", [_vm._v("Country")]),
                  _vm._v(" "),
                  _c(
                    "validator",
                    { attrs: { value: _vm.form.country, reqs: "required" } },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.country,
                            expression: "form.country"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.form.country },
                        on: {
                          keypress: function($event) {
                            return _vm.allowLetterNumber($event)
                          },
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.form, "country", $event.target.value)
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("h3", { staticClass: "subtitle" }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "input-group large" },
                [
                  _c("label", [_vm._v("Email")]),
                  _vm._v(" "),
                  _c(
                    "validator",
                    {
                      attrs: {
                        value: _vm.form.personalemail,
                        reqs: "required email"
                      }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.personalemail,
                            expression: "form.personalemail"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.form.personalemail },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.form,
                              "personalemail",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "input-group large" },
                [
                  _c("label", [_vm._v("Phone")]),
                  _vm._v(" "),
                  _c(
                    "validator",
                    {
                      attrs: {
                        value: _vm.form.personalphone,
                        reqs: "required phone"
                      }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.personalphone,
                            expression: "form.personalphone"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.form.personalphone },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.form,
                              "personalphone",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("h3", { staticClass: "subtitle" }, [
                _vm._v("Emergency Contact")
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "input-group w25" },
                [
                  _c("label", [_vm._v("First Name")]),
                  _vm._v(" "),
                  _c(
                    "validator",
                    {
                      attrs: { value: _vm.form.ec_first_name, reqs: "required" }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.ec_first_name,
                            expression: "form.ec_first_name"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.form.ec_first_name },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.form,
                              "ec_first_name",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "input-group w25" },
                [
                  _c("label", [_vm._v("Last Name")]),
                  _vm._v(" "),
                  _c(
                    "validator",
                    {
                      attrs: { value: _vm.form.ec_last_name, reqs: "required" }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.ec_last_name,
                            expression: "form.ec_last_name"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.form.ec_last_name },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.form,
                              "ec_last_name",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "input-group w25" },
                [
                  _c("label", [_vm._v("Email")]),
                  _vm._v(" "),
                  _c(
                    "validator",
                    {
                      attrs: {
                        value: _vm.form.ec_email,
                        reqs: "required email"
                      }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.ec_email,
                            expression: "form.ec_email"
                          }
                        ],
                        attrs: { type: "email" },
                        domProps: { value: _vm.form.ec_email },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.form, "ec_email", $event.target.value)
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "input-group w25" },
                [
                  _c("label", [_vm._v("Phone")]),
                  _vm._v(" "),
                  _c(
                    "validator",
                    { attrs: { value: _vm.form.ec_phone, reqs: "required" } },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.ec_phone,
                            expression: "form.ec_phone"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.form.ec_phone },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.form, "ec_phone", $event.target.value)
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.activeTab === 2
          ? _c("div", { staticClass: "custom-form pad20 inline-form" }, [
              _c(
                "div",
                { staticClass: "input-group w100" },
                [
                  _c("label", [_vm._v("Portal:")]),
                  _vm._v(" "),
                  _c(
                    "validator",
                    {
                      staticClass: "inline-block",
                      attrs: { value: _vm.form.portal, reqs: "required" }
                    },
                    [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.portal,
                              expression: "form.portal"
                            }
                          ],
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.form,
                                "portal",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            }
                          }
                        },
                        _vm._l(_vm.portalOptions, function(opt, key) {
                          return _c(
                            "option",
                            { key: key, domProps: { value: key } },
                            [_vm._v(_vm._s(opt))]
                          )
                        }),
                        0
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "input-group w100" }, [
                _c("label", [_vm._v("Username: " + _vm._s(_vm.username))])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "input-group w100" }, [
                _c("label", [
                  _vm._v("Email: " + _vm._s(_vm.autogeneratedEmail))
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "input-group w100" }, [
                _c("label", [
                  _vm._v("Role: " + _vm._s(_vm.userGroup(_vm.form.role)))
                ])
              ]),
              _vm._v(" "),
              _c("h3", { staticClass: "subtitle" }, [_vm._v("Permissions")])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.activeTab === 3
          ? _c(
              "div",
              { staticClass: "pad20" },
              [
                _c("notes", {
                  ref: "notes",
                  staticClass: "appl-notes",
                  attrs: { notes: [], type: "Employee", await: "" }
                })
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "buttons clear-after" }, [
          _vm.activeTab > 0
            ? _c(
                "button",
                {
                  staticClass: "btn btn-large btn-primary btn-back",
                  on: {
                    click: function($event) {
                      return _vm.prevNext(false)
                    }
                  }
                },
                [_vm._v("Previous Step")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.activeTab < 3
            ? _c(
                "button",
                {
                  staticClass: "btn btn-large btn-primary floatr no-margin",
                  on: {
                    click: function($event) {
                      return _vm.prevNext()
                    }
                  }
                },
                [_vm._v("Next Step")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.activeTab === 3
            ? _c(
                "button",
                {
                  staticClass: "btn btn-large btn-primary floatr no-margin",
                  on: {
                    click: function($event) {
                      return _vm.save()
                    }
                  }
                },
                [_vm._v("FINALIZE")]
              )
            : _vm._e()
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { "margin-left": "25px" } }, [
      _c("ul", { staticClass: "breadcrumbs" }, [
        _c("li", [_c("a", [_vm._v("New Employee")])])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }