var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "preform-outer" }, [
    _c("div", { staticClass: "preformbg" }),
    _vm._v(" "),
    _c("div", { staticClass: "appf preform ncontent" }, [
      _c("div", { staticClass: "wcontent mc-setup" }, [
        _c(
          "div",
          {
            staticClass: "cb-logo",
            style: {
              "background-image":
                'url("/files/company/' + _vm.companydata.appHeadLogo + '")'
            }
          },
          [_vm._v("CardBiller")]
        ),
        _vm._v(" "),
        _c("h1", [_vm._v("Account Confirmation")]),
        _vm._v(" "),
        _c("div", { staticClass: "fi-split" }, [
          _c("div", { staticClass: "form-info" }, [
            _c("p", [_vm._v("Application Assistance")]),
            _vm._v(" "),
            _c("p", [
              _c("strong", [_vm._v(_vm._s(_vm.companydata.applicationEmail))])
            ]),
            _vm._v(" "),
            _c("p", [
              _c("strong", [_vm._v(_vm._s(_vm.companydata.servicesTel))])
            ])
          ]),
          _vm._v(" "),
          _vm.item.manager
            ? _c("div", { staticClass: "form-info" }, [
                _c("p", [_vm._v("Account Manager:")]),
                _vm._v(" "),
                _c("p", [
                  _c("strong", [_vm._v(_vm._s(_vm.item.manager.fullName))])
                ]),
                _vm._v(" "),
                _c("p", [
                  _c("strong", [_vm._v(_vm._s(_vm.item.manager.email))])
                ]),
                _vm._v(" "),
                _c("p", [
                  _c("strong", [_vm._v(_vm._s(_vm.item.manager.phone))])
                ])
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _vm.step == 0
          ? _c("form", { staticClass: "preq-form" }, [
              _c(
                "section",
                [
                  _vm._m(1),
                  _vm._v(" "),
                  _c("ul", { staticClass: "preform-sp3" }, [
                    _c("li", [
                      _c("label", [_vm._v("Merchant Account ID")]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.item.accountId) +
                            "\n          "
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Business Type:")]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.item.industry) +
                            "\n          "
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Merchant Type")]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.item.merchantType) +
                            "\n          "
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("MCC:")]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.item.mcc || "") +
                            "\n          "
                        )
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("ul", { staticClass: "preform-sp3" }, [
                    _c("li", [
                      _c("label", [_vm._v("Legal Business Name:")]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.item.companyName) +
                            "\n          "
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Company Email:")]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.item.companyEmail) +
                            "\n          "
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Company Phone:")]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.item.companyPhone) +
                            "\n          "
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Billing Descriptor:")]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.item.descriptor1) +
                            "\n          "
                        )
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _vm._m(2),
                  _vm._v(" "),
                  _vm._l(_vm.item.contacts, function(contact) {
                    return _c("ul", { staticClass: "preform-sp3" }, [
                      _c("li", [
                        _c("label", [_vm._v("Type:")]),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                (contact.type || "")[0].toUpperCase() +
                                  (contact.type || "")
                                    .substring(1)
                                    .toLowerCase() +
                                  " contact"
                              ) +
                              "\n          "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("label", [_vm._v("Full Name:")]),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                contact.firstName + " " + contact.lastName
                              ) +
                              "\n          "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("label", [_vm._v("Email:")]),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(
                            "\n            " +
                              _vm._s(contact.email) +
                              "\n          "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("label", [_vm._v("Phone:")]),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(
                            "\n            " +
                              _vm._s(contact.phone) +
                              "\n          "
                          )
                        ])
                      ])
                    ])
                  }),
                  _vm._v(" "),
                  _vm._m(3),
                  _vm._v(" "),
                  _c("p", { staticClass: "intro" }, [
                    _vm._v(
                      "\n        How will you process your transactions with " +
                        _vm._s(_vm.companydata.nameDba) +
                        "?  Check all that apply\n      "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "validator",
                    {
                      attrs: {
                        value:
                          _vm.item.methodPos ||
                          _vm.item.methodMobile ||
                          _vm.item.methodApi ||
                          _vm.item.methodCheckout ||
                          _vm.item.methodVirtual ||
                          _vm.item.methodGateway,
                        reqs: "boolean"
                      }
                    },
                    [
                      _c(
                        "ul",
                        {
                          staticClass: "preform-sp3 preform-nogrow preform-line"
                        },
                        [
                          _c("li", [
                            _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.item.methodPos,
                                    expression: "item.methodPos"
                                  }
                                ],
                                attrs: {
                                  type: "checkbox",
                                  disabled: _vm.readonly
                                },
                                domProps: {
                                  checked: Array.isArray(_vm.item.methodPos)
                                    ? _vm._i(_vm.item.methodPos, null) > -1
                                    : _vm.item.methodPos
                                },
                                on: {
                                  change: function($event) {
                                    var $$a = _vm.item.methodPos,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.item,
                                            "methodPos",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.item,
                                            "methodPos",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(_vm.item, "methodPos", $$c)
                                    }
                                  }
                                }
                              }),
                              _c("span"),
                              _vm._v("\n            POS ")
                            ])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.item.methodMobile,
                                    expression: "item.methodMobile"
                                  }
                                ],
                                attrs: {
                                  type: "checkbox",
                                  disabled: _vm.readonly
                                },
                                domProps: {
                                  checked: Array.isArray(_vm.item.methodMobile)
                                    ? _vm._i(_vm.item.methodMobile, null) > -1
                                    : _vm.item.methodMobile
                                },
                                on: {
                                  change: function($event) {
                                    var $$a = _vm.item.methodMobile,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.item,
                                            "methodMobile",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.item,
                                            "methodMobile",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(_vm.item, "methodMobile", $$c)
                                    }
                                  }
                                }
                              }),
                              _c("span"),
                              _vm._v("\n            Mobile ")
                            ])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.item.methodCheckout,
                                    expression: "item.methodCheckout"
                                  }
                                ],
                                attrs: {
                                  type: "checkbox",
                                  disabled: _vm.readonly
                                },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.item.methodCheckout
                                  )
                                    ? _vm._i(_vm.item.methodCheckout, null) > -1
                                    : _vm.item.methodCheckout
                                },
                                on: {
                                  change: function($event) {
                                    var $$a = _vm.item.methodCheckout,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.item,
                                            "methodCheckout",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.item,
                                            "methodCheckout",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(_vm.item, "methodCheckout", $$c)
                                    }
                                  }
                                }
                              }),
                              _c("span"),
                              _vm._v(
                                "\n            CardBiller Checkout\n          "
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.item.methodVirtual,
                                    expression: "item.methodVirtual"
                                  }
                                ],
                                attrs: {
                                  type: "checkbox",
                                  disabled: _vm.readonly
                                },
                                domProps: {
                                  checked: Array.isArray(_vm.item.methodVirtual)
                                    ? _vm._i(_vm.item.methodVirtual, null) > -1
                                    : _vm.item.methodVirtual
                                },
                                on: {
                                  change: function($event) {
                                    var $$a = _vm.item.methodVirtual,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.item,
                                            "methodVirtual",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.item,
                                            "methodVirtual",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(_vm.item, "methodVirtual", $$c)
                                    }
                                  }
                                }
                              }),
                              _c("span"),
                              _vm._v(
                                "\n            Virtual Terminal\n          "
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.item.methodApi,
                                    expression: "item.methodApi"
                                  }
                                ],
                                attrs: {
                                  type: "checkbox",
                                  disabled: _vm.readonly
                                },
                                domProps: {
                                  checked: Array.isArray(_vm.item.methodApi)
                                    ? _vm._i(_vm.item.methodApi, null) > -1
                                    : _vm.item.methodApi
                                },
                                on: {
                                  change: function($event) {
                                    var $$a = _vm.item.methodApi,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.item,
                                            "methodApi",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.item,
                                            "methodApi",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(_vm.item, "methodApi", $$c)
                                    }
                                  }
                                }
                              }),
                              _c("span"),
                              _vm._v("\n            API  ")
                            ])
                          ])
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("p", { staticClass: "intro" }, [
                    _vm._v(
                      "\n        Your account is setup to accept the following payment types:\n      "
                    )
                  ]),
                  _vm._v(" "),
                  _c("ul", { staticClass: "preform-auto preform-nogrow" }, [
                    _vm.item.cardTypes.indexOf("Visa") != -1
                      ? _c("li", [
                          _c("label", [
                            _vm._v("\n            Visa\n          ")
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.item.cardTypes.indexOf("MasterCard") != -1
                      ? _c("li", [
                          _c("label", [
                            _vm._v("\n            MasterCard\n          ")
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.item.cardTypes.indexOf("Discover") != -1
                      ? _c("li", [
                          _c("label", [
                            _vm._v("\n            Discover\n          ")
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.item.cardTypes.indexOf("AMEX") != -1
                      ? _c("li", [
                          _c("label", [
                            _vm._v("\n            AMEX\n          ")
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.item.cardTypes.indexOf("China UnionPay") != -1
                      ? _c("li", [
                          _c("label", [
                            _vm._v("\n            China UnionPay\n          ")
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.item.cardTypes.indexOf("ApplePay") != -1
                      ? _c("li", [
                          _c("label", [
                            _vm._v("\n            ApplePay\n          ")
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.item.cardTypes.indexOf("GooglePay") != -1
                      ? _c("li", [
                          _c("label", [
                            _vm._v("\n            GooglePay\n          ")
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.item.cardTypes.indexOf("SamsungPay") != -1
                      ? _c("li", [
                          _c("label", [
                            _vm._v("\n            SamsungPay\n          ")
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.item.cardTypes.indexOf("Interac Online") != -1
                      ? _c("li", [
                          _c("label", [
                            _vm._v("\n            Interac Online\n          ")
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.item.cardTypes.indexOf("Interac Debit") != -1
                      ? _c("li", [
                          _c("label", [_vm._v("\n            Interac Debit ")])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.item.cardTypes.indexOf("EFT") != -1
                      ? _c("li", [
                          _c("label", [
                            _vm._v("\n            ACH/EFT\n          ")
                          ])
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("ul", { staticClass: "preform-sp3" }, [
                    _c(
                      "li",
                      { staticClass: "sub-select compact" },
                      [
                        _c("label", [
                          _vm._v("You can accept the following currencies:")
                        ]),
                        _vm._v(" "),
                        _c(
                          "validator",
                          {
                            attrs: {
                              value: _vm.merchant.currencies,
                              reqs: "required city"
                            }
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.merchant.currencies))
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _vm._m(4),
                  _vm._v(" "),
                  false
                    ? _c("p", { staticClass: "intro" }, [
                        _vm._v(
                          "\n        All equipment will be shipped to the business address listed above.\n      "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("table", { staticClass: "preform-sp3 preform-nogrow" }, [
                    _vm._m(5),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      _vm._l(_vm.equipmentList, function(equItem) {
                        return _c("tr", [
                          _c("td", [_vm._v(_vm._s(equItem.name))]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(equItem.quantity))]),
                          _vm._v(" "),
                          _c("th", [
                            _vm._v(
                              _vm._s(
                                equItem.rental == 1 ? "Rental" : "Purchase"
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c("th", [
                            _vm._v(
                              _vm._s(
                                equItem.rental == 1
                                  ? equItem.rentalPrice
                                  : equItem.price
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c("th", [
                            _vm._v(
                              _vm._s(
                                (
                                  (equItem.rental == 1
                                    ? equItem.rentalPrice
                                    : equItem.price) * equItem.quantity
                                ).toFixed(2)
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c("th", [_vm._v(" ")])
                        ])
                      }),
                      0
                    )
                  ]),
                  _vm._v(" "),
                  _vm._m(6),
                  _vm._v(" "),
                  _c("p", { staticClass: "intro" }, [
                    _c("strong", [_vm._v("Account Manager:")]),
                    _c("br"),
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.item.manager && _vm.item.manager.fullName) +
                        ", " +
                        _vm._s(_vm.item.manager && _vm.item.manager.email) +
                        ", " +
                        _vm._s(_vm.item.manager && _vm.item.manager.phone) +
                        " " +
                        _vm._s(_vm.item.manager && _vm.item.manager.phoneExt) +
                        "\n      "
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "intro" }, [
                    _c("strong", [_vm._v("Account Setup Assistance:")]),
                    _c("br"),
                    _vm._v("\n        " + _vm._s(_vm.companydata.supportEmail)),
                    _c("br"),
                    _vm._v("\n        " + _vm._s(_vm.companydata.supportTel)),
                    _c("br")
                  ])
                ],
                2
              ),
              _vm._v(" "),
              !_vm.readonly
                ? _c("div", { staticClass: "buttons" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-large btn-primary",
                        attrs: { disabled: _vm.sending },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.send.apply(null, arguments)
                          }
                        }
                      },
                      [_vm._v("Submit")]
                    )
                  ])
                : _vm._e()
            ])
          : _vm.step == 1
          ? _c("div", { staticClass: "pre-done" }, [
              _c("h1", [
                _vm._v("Thank you for submitting your setup configuration!")
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "One of our account Representatives will contact you shortly."
                )
              ]),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "btn btn-large btn-primary btn-black",
                  attrs: { href: "" }
                },
                [_vm._v("Back to Dashboard")]
              )
            ])
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "form-intro" }, [
      _c("strong", [_vm._v("Dear Merchant:")]),
      _c("br"),
      _c("br"),
      _vm._v(
        "\n    We are pleased to inform you that we have opened your new payment processing account! Below is important information that will be used to correctly configure your account to maximize your processing experience. Review and complete the required fields below and submit when completed in order to finalize setting up your account. If there is information that needs to be updated, do not submit and contact your Account Manager or Account Setup Assistance.\n  "
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", [_c("em", [_vm._v("Merchant Information")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", [_c("em", [_vm._v("Contact Information")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", [_c("em", [_vm._v("Account Setup")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", [_c("strong", [_vm._v("Equipment")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Item")]),
        _vm._v(" "),
        _c("th", [_vm._v("Qty")]),
        _vm._v(" "),
        _c("th", [_vm._v("Contract Type")]),
        _vm._v(" "),
        _c("th", [_vm._v("Unit Price")]),
        _vm._v(" "),
        _c("th", [_vm._v("Total Price")]),
        _vm._v(" "),
        _c("th", [_vm._v("Note")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "intro" }, [
      _vm._v("\n         If there is information that needs to be updated, "),
      _c("em", [_vm._v("do not submit this form")]),
      _vm._v(
        " and contact your Account Manager or Account Setup Assistance.\n      "
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }