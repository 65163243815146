var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "form",
      {
        staticClass: "scan-filter",
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.activateFilter.apply(null, arguments)
          }
        }
      },
      [
        _c("div", { staticClass: "actions" }, [
          _c(
            "button",
            {
              staticClass: "btn",
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.refresh.apply(null, arguments)
                }
              }
            },
            [_vm._v("Get list")]
          )
        ]),
        _vm._v(" "),
        _c("ul", { staticClass: "filter" })
      ]
    ),
    _vm._v(" "),
    _c("ul", { staticClass: "acc-results" }, [
      _c("li", { staticClass: "active" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "acc-list", staticStyle: { display: "block" } },
          [
            _c("table", [
              _vm._m(1),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.itemsToShow, function(item) {
                  return _c("tr", [
                    _c("td", [
                      _vm._v(_vm._s(item.attributes && item.attributes.id_1))
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        _vm._s(item.attributes && item.attributes.merchant_name)
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          item.attributes &&
                            _vm.formatDate(
                              item.attributes.created_at,
                              "YYYY-MM-DD"
                            )
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(_vm._s(item.attributes && item.attributes.url))
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s("Yes"))]),
                    _vm._v(" "),
                    _c("td", [
                      _c(
                        "a",
                        {
                          staticClass: "tb-btn",
                          attrs: { href: "" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.doDelete(item)
                            }
                          }
                        },
                        [_vm._v("Delete")]
                      )
                    ])
                  ])
                }),
                0
              )
            ])
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h4", [
      _c("span", { staticClass: "label" }, [_vm._v("G2 Monitoring List")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("G2 MDM ID")]),
        _vm._v(" "),
        _c("th", [_vm._v("Company Name")]),
        _vm._v(" "),
        _c("th", [_vm._v("Date")]),
        _vm._v(" "),
        _c("th", [_vm._v("URL")]),
        _vm._v(" "),
        _c("th", [_vm._v("Active")]),
        _vm._v(" "),
        _c("th", [_vm._v("Actions")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }