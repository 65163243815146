var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "action-sub-buttons" },
      _vm._l(_vm.allActions, function(optLabel, optMethod) {
        return !_vm.isDisabledAction(optMethod)
          ? _c(
              "a",
              {
                class: { disabled: _vm.isDisabledAction(optMethod) },
                attrs: { href: "" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    !_vm.isDisabledAction(optMethod) && _vm.doAction(optMethod)
                  }
                }
              },
              [_vm._v(_vm._s(optLabel))]
            )
          : _vm._e()
      }),
      0
    ),
    _vm._v(" "),
    _c("table", { staticClass: "file-list content-table" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "tbody",
        _vm._l(_vm.notes, function(uwItem) {
          return uwItem.system && uwItem.message.indexOf("Updated to ") == 0
            ? _c("tr", [
                _c("td", [
                  _vm._v(
                    _vm._s(_vm.formatDate(uwItem.created, "YYYY-MM-DD h:mm a"))
                  )
                ]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(uwItem.message.substring(11)))]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(uwItem.author.username))])
              ])
            : _vm._e()
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Date")]),
        _vm._v(" "),
        _c("th", [_vm._v("Status Update")]),
        _vm._v(" "),
        _c("th", [_vm._v("Updated By")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }