var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pricing-template" }, [
    !_vm.boarding
      ? _c("ul", { staticClass: "pricing-list pl2 madon" }, [
          _c(
            "li",
            { staticStyle: { width: "100%" } },
            [
              _c("label", [_vm._v("Account Type")]),
              _vm._v(" "),
              _c(
                "validator",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    value: _vm.currentTemplate.accountType,
                    reqs: "required"
                  }
                },
                [
                  _c("multiselect-dropdown", {
                    attrs: {
                      disabled: !_vm.editMode,
                      options: _vm.accountTypeOpts,
                      isMulti: true
                    },
                    model: {
                      value: _vm.currentTemplate.accountType,
                      callback: function($$v) {
                        _vm.$set(_vm.currentTemplate, "accountType", $$v)
                      },
                      expression: "currentTemplate.accountType"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("h2", [_vm._v("MONTHLY PLAN")]),
    _vm._v(" "),
    _c("ul", { staticClass: "pricing-list" }, [
      _c(
        "li",
        [
          _c("label", [_vm._v("Access Plan")]),
          _vm._v(" "),
          _c(
            "validator",
            {
              staticStyle: { width: "100%" },
              attrs: { value: _vm.currentTemplate.accessPlan, reqs: "required" }
            },
            [
              _c("multiselect-dropdown", {
                attrs: {
                  disabled: !_vm.editMode,
                  options: _vm.planOptions,
                  isMulti: true,
                  isExclusive: true
                },
                model: {
                  value: _vm.currentTemplate.accessPlan,
                  callback: function($$v) {
                    _vm.$set(_vm.currentTemplate, "accessPlan", $$v)
                  },
                  expression: "currentTemplate.accessPlan"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.methodOptions.enabledMethods.find(function(a) {
        return _vm.methodOptions.allMethods.find(function(b) {
          return a == b
        })
      })
        ? _c("li", [
            _vm._m(0),
            _vm._v(" "),
            _c("ul", [
              _c(
                "li",
                [
                  _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
                  _vm._v(" "),
                  _c(
                    "validator",
                    {
                      attrs: {
                        value: _vm.currentTemplate.treasuryAccessMonthly,
                        reqs: "required"
                      }
                    },
                    [
                      _c("number-edit", {
                        attrs: { disabled: !_vm.editMode, step: "0.01" },
                        model: {
                          value: _vm.currentTemplate.treasuryAccessMonthly,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.currentTemplate,
                              "treasuryAccessMonthly",
                              $$v
                            )
                          },
                          expression: "currentTemplate.treasuryAccessMonthly"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("h2", [_vm._v("Account Limits")]),
    _vm._v(" "),
    _c("ul", { staticClass: "pricing-list pl2 madon" }, [
      _c("li", [
        _vm._m(1),
        _vm._v(" "),
        _c("ul", [
          _c(
            "li",
            [
              _c("label", [_vm._v("Daily")]),
              _vm._v(" "),
              _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
              _vm._v(" "),
              _c("number-edit", {
                attrs: { disabled: !_vm.editMode, step: "0.01" },
                model: {
                  value: _vm.currentTemplate.accountLimitsDaily,
                  callback: function($$v) {
                    _vm.$set(_vm.currentTemplate, "accountLimitsDaily", $$v)
                  },
                  expression: "currentTemplate.accountLimitsDaily"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("label", [_vm._v("Monthly")]),
              _vm._v(" "),
              _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
              _vm._v(" "),
              _c("number-edit", {
                attrs: { disabled: !_vm.editMode, step: "0.01" },
                model: {
                  value: _vm.currentTemplate.accountLimitsMonthly,
                  callback: function($$v) {
                    _vm.$set(_vm.currentTemplate, "accountLimitsMonthly", $$v)
                  },
                  expression: "currentTemplate.accountLimitsMonthly"
                }
              })
            ],
            1
          )
        ])
      ])
    ]),
    _vm._v(" "),
    _c("h2", [_vm._v("PRICING")]),
    _vm._v(" "),
    _c("div", { staticClass: "fee-types" }, [
      _vm._m(2),
      _vm._v(" "),
      _c("div", [
        _c("h3", [_vm._v(" ")]),
        _vm._v(" "),
        _c("ul", { staticClass: "fee-list" }, [
          _c("li"),
          _vm._v(" "),
          _c("li"),
          _vm._v(" "),
          _c("li"),
          _vm._v(" "),
          _c(
            "li",
            [
              _vm.treasurySolution.service.methods.domesticWires &&
              (!_vm.parentTemplate ||
                _vm.parentTemplate.feeSchedule.domesticWire.setting)
                ? _c("mnb-switch", {
                    attrs: { disabled: !_vm.editMode },
                    model: {
                      value:
                        _vm.currentTemplate.feeSchedule.domesticWire.setting,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.currentTemplate.feeSchedule.domesticWire,
                          "setting",
                          $$v
                        )
                      },
                      expression:
                        "currentTemplate.feeSchedule.domesticWire.setting"
                    }
                  })
                : _c("mnb-switch", {
                    attrs: {
                      disabled: true,
                      set: (_vm.currentTemplate.feeSchedule.domesticWire.setting = false)
                    }
                  })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _vm.treasurySolution.service.methods.internationalWires &&
              (!_vm.parentTemplate ||
                _vm.parentTemplate.feeSchedule.internationalWire.setting)
                ? _c("mnb-switch", {
                    attrs: { disabled: !_vm.editMode },
                    model: {
                      value:
                        _vm.currentTemplate.feeSchedule.internationalWire
                          .setting,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.currentTemplate.feeSchedule.internationalWire,
                          "setting",
                          $$v
                        )
                      },
                      expression:
                        "currentTemplate.feeSchedule.internationalWire.setting"
                    }
                  })
                : _c("mnb-switch", {
                    attrs: {
                      disabled: true,
                      set: (_vm.currentTemplate.feeSchedule.internationalWire.setting = false)
                    }
                  })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _vm.treasurySolution.service.methods.ach &&
              (!_vm.parentTemplate ||
                _vm.parentTemplate.feeSchedule.ach.setting)
                ? _c("mnb-switch", {
                    attrs: { disabled: !_vm.editMode },
                    model: {
                      value: _vm.currentTemplate.feeSchedule.ach.setting,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.currentTemplate.feeSchedule.ach,
                          "setting",
                          $$v
                        )
                      },
                      expression: "currentTemplate.feeSchedule.ach.setting"
                    }
                  })
                : _c("mnb-switch", {
                    attrs: {
                      disabled: true,
                      set: (_vm.currentTemplate.feeSchedule.ach.setting = false)
                    }
                  })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _vm.treasurySolution.service.methods.accountTransfer &&
              (!_vm.parentTemplate ||
                _vm.parentTemplate.feeSchedule.accountTransfer.setting)
                ? _c("mnb-switch", {
                    attrs: { disabled: !_vm.editMode },
                    model: {
                      value:
                        _vm.currentTemplate.feeSchedule.accountTransfer.setting,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.currentTemplate.feeSchedule.accountTransfer,
                          "setting",
                          $$v
                        )
                      },
                      expression:
                        "currentTemplate.feeSchedule.accountTransfer.setting"
                    }
                  })
                : _c("mnb-switch", {
                    attrs: {
                      disabled: true,
                      set: (_vm.currentTemplate.feeSchedule.accountTransfer.setting = false)
                    }
                  })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _vm.treasurySolution.service.methods.checks &&
              (!_vm.parentTemplate ||
                _vm.parentTemplate.feeSchedule.checks.setting)
                ? _c("mnb-switch", {
                    attrs: { disabled: !_vm.editMode },
                    model: {
                      value: _vm.currentTemplate.feeSchedule.checks.setting,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.currentTemplate.feeSchedule.checks,
                          "setting",
                          $$v
                        )
                      },
                      expression: "currentTemplate.feeSchedule.checks.setting"
                    }
                  })
                : _c("mnb-switch", {
                    attrs: {
                      disabled: true,
                      set: (_vm.currentTemplate.feeSchedule.checks.setting = false)
                    }
                  })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _vm.treasurySolution.service.options.debitCards &&
              (!_vm.parentTemplate ||
                _vm.parentTemplate.feeSchedule.debitCard.setting)
                ? _c("mnb-switch", {
                    attrs: { disabled: !_vm.editMode },
                    model: {
                      value: _vm.currentTemplate.feeSchedule.debitCard.setting,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.currentTemplate.feeSchedule.debitCard,
                          "setting",
                          $$v
                        )
                      },
                      expression:
                        "currentTemplate.feeSchedule.debitCard.setting"
                    }
                  })
                : _c("mnb-switch", {
                    attrs: {
                      disabled: true,
                      set: (_vm.currentTemplate.feeSchedule.debitCard.setting = false)
                    }
                  })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _vm.treasurySolution.service.advancedMethods.digitalAssets &&
              (!_vm.parentTemplate ||
                _vm.parentTemplate.feeSchedule.assets.setting)
                ? _c("mnb-switch", {
                    attrs: { disabled: !_vm.editMode },
                    model: {
                      value: _vm.currentTemplate.feeSchedule.assets.setting,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.currentTemplate.feeSchedule.assets,
                          "setting",
                          $$v
                        )
                      },
                      expression: "currentTemplate.feeSchedule.assets.setting"
                    }
                  })
                : _c("mnb-switch", {
                    attrs: {
                      disabled: true,
                      set: (_vm.currentTemplate.feeSchedule.assets.setting = false)
                    }
                  })
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", [
        _c("h3", [_vm._v("Fee")]),
        _vm._v(" "),
        _c("ul", { staticClass: "fee-list" }, [
          _c(
            "li",
            [
              _c("span", { staticClass: "prefix" }),
              _vm._v(" "),
              _c(
                "validator",
                {
                  attrs: {
                    value: _vm.currentTemplate.feeSchedule.volumeFee.fee,
                    reqs: "required"
                  }
                },
                [
                  _c("number-edit", {
                    attrs: {
                      disabled: !_vm.editMode,
                      step: "0.01",
                      oninput:
                        "javascript: if (!/^\\d+(\\.\\d{0,2})?$/.test(this.value)) { this.value = this.value.slice(0, -1); }"
                    },
                    model: {
                      value: _vm.currentTemplate.feeSchedule.volumeFee.fee,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.currentTemplate.feeSchedule.volumeFee,
                          "fee",
                          $$v
                        )
                      },
                      expression: "currentTemplate.feeSchedule.volumeFee.fee"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("span", { staticClass: "suffix" }, [_vm._v("%")])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
              _vm._v(" "),
              _c(
                "validator",
                {
                  attrs: {
                    value: _vm.currentTemplate.feeSchedule.exchange.fee,
                    reqs: "required"
                  }
                },
                [
                  _c("number-edit", {
                    attrs: {
                      disabled: !_vm.editMode,
                      step: "0.01",
                      oninput:
                        "javascript: if (!/^\\d+(\\.\\d{0,2})?$/.test(this.value)) { this.value = this.value.slice(0, -1); }"
                    },
                    model: {
                      value: _vm.currentTemplate.feeSchedule.exchange.fee,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.currentTemplate.feeSchedule.exchange,
                          "fee",
                          $$v
                        )
                      },
                      expression: "currentTemplate.feeSchedule.exchange.fee"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
              _vm._v(" "),
              _c(
                "validator",
                {
                  attrs: {
                    value: _vm.currentTemplate.feeSchedule.dormantAccounts.fee,
                    reqs: "required"
                  }
                },
                [
                  _c("number-edit", {
                    attrs: {
                      disabled: !_vm.editMode,
                      step: "0.01",
                      oninput:
                        "javascript: if (!/^\\d+(\\.\\d{0,2})?$/.test(this.value)) { this.value = this.value.slice(0, -1); }"
                    },
                    model: {
                      value:
                        _vm.currentTemplate.feeSchedule.dormantAccounts.fee,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.currentTemplate.feeSchedule.dormantAccounts,
                          "fee",
                          $$v
                        )
                      },
                      expression:
                        "currentTemplate.feeSchedule.dormantAccounts.fee"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("li"),
          _vm._v(" "),
          _c("li"),
          _vm._v(" "),
          _c("li"),
          _vm._v(" "),
          _c("li"),
          _vm._v(" "),
          _c("li"),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
              _vm._v(" "),
              _c(
                "validator",
                {
                  attrs: {
                    value: _vm.currentTemplate.feeSchedule.debitCard.fee,
                    reqs: _vm.currentTemplate.feeSchedule.debitCard.setting
                      ? "required"
                      : ""
                  }
                },
                [
                  _c("number-edit", {
                    attrs: {
                      disabled:
                        !_vm.currentTemplate.feeSchedule.debitCard.setting ||
                        !_vm.editMode,
                      step: "0.01",
                      oninput:
                        "javascript: if (!/^\\d+(\\.\\d{0,2})?$/.test(this.value)) { this.value = this.value.slice(0, -1); }"
                    },
                    model: {
                      value: _vm.currentTemplate.feeSchedule.debitCard.fee,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.currentTemplate.feeSchedule.debitCard,
                          "fee",
                          $$v
                        )
                      },
                      expression: "currentTemplate.feeSchedule.debitCard.fee"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
              _vm._v(" "),
              _c(
                "validator",
                {
                  attrs: {
                    value: _vm.currentTemplate.feeSchedule.assets.fee,
                    reqs: _vm.currentTemplate.feeSchedule.assets.setting
                      ? "required"
                      : ""
                  }
                },
                [
                  _c("number-edit", {
                    attrs: {
                      disabled:
                        !_vm.currentTemplate.feeSchedule.assets.setting ||
                        !_vm.editMode,
                      step: "0.01",
                      oninput:
                        "javascript: if (!/^\\d+(\\.\\d{0,2})?$/.test(this.value)) { this.value = this.value.slice(0, -1); }"
                    },
                    model: {
                      value: _vm.currentTemplate.feeSchedule.assets.fee,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.currentTemplate.feeSchedule.assets,
                          "fee",
                          $$v
                        )
                      },
                      expression: "currentTemplate.feeSchedule.assets.fee"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", [
        _c("h3", [_vm._v("Deposit")]),
        _vm._v(" "),
        _c("ul", { staticClass: "fee-list" }, [
          _c("li"),
          _vm._v(" "),
          _c("li"),
          _vm._v(" "),
          _c("li"),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
              _vm._v(" "),
              _c(
                "validator",
                {
                  attrs: {
                    value:
                      _vm.currentTemplate.feeSchedule.domesticWire.per_deposit,
                    reqs: _vm.currentTemplate.feeSchedule.domesticWire.setting
                      ? "required"
                      : ""
                  }
                },
                [
                  _c("number-edit", {
                    attrs: {
                      disabled:
                        !_vm.currentTemplate.feeSchedule.domesticWire.setting ||
                        !_vm.editMode,
                      step: "0.01",
                      oninput:
                        "javascript: if (!/^\\d+(\\.\\d{0,2})?$/.test(this.value)) { this.value = this.value.slice(0, -1); }"
                    },
                    model: {
                      value:
                        _vm.currentTemplate.feeSchedule.domesticWire
                          .per_deposit,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.currentTemplate.feeSchedule.domesticWire,
                          "per_deposit",
                          $$v
                        )
                      },
                      expression:
                        "currentTemplate.feeSchedule.domesticWire.per_deposit"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
              _vm._v(" "),
              _c(
                "validator",
                {
                  attrs: {
                    value:
                      _vm.currentTemplate.feeSchedule.internationalWire
                        .per_deposit,
                    reqs: _vm.currentTemplate.feeSchedule.internationalWire
                      .setting
                      ? "required"
                      : ""
                  }
                },
                [
                  _c("number-edit", {
                    attrs: {
                      disabled:
                        !_vm.currentTemplate.feeSchedule.internationalWire
                          .setting || !_vm.editMode,
                      step: "0.01",
                      oninput:
                        "javascript: if (!/^\\d+(\\.\\d{0,2})?$/.test(this.value)) { this.value = this.value.slice(0, -1); }"
                    },
                    model: {
                      value:
                        _vm.currentTemplate.feeSchedule.internationalWire
                          .per_deposit,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.currentTemplate.feeSchedule.internationalWire,
                          "per_deposit",
                          $$v
                        )
                      },
                      expression:
                        "currentTemplate.feeSchedule.internationalWire.per_deposit"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
              _vm._v(" "),
              _c(
                "validator",
                {
                  attrs: {
                    value: _vm.currentTemplate.feeSchedule.ach.per_deposit,
                    reqs: _vm.currentTemplate.feeSchedule.ach.setting
                      ? "required"
                      : ""
                  }
                },
                [
                  _c("number-edit", {
                    attrs: {
                      disabled:
                        !_vm.currentTemplate.feeSchedule.ach.setting ||
                        !_vm.editMode,
                      step: "0.01",
                      oninput:
                        "javascript: if (!/^\\d+(\\.\\d{0,2})?$/.test(this.value)) { this.value = this.value.slice(0, -1); }"
                    },
                    model: {
                      value: _vm.currentTemplate.feeSchedule.ach.per_deposit,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.currentTemplate.feeSchedule.ach,
                          "per_deposit",
                          $$v
                        )
                      },
                      expression: "currentTemplate.feeSchedule.ach.per_deposit"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("li"),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
              _vm._v(" "),
              _c(
                "validator",
                {
                  attrs: {
                    value: _vm.currentTemplate.feeSchedule.checks.per_deposit,
                    reqs: _vm.currentTemplate.feeSchedule.checks.setting
                      ? "required"
                      : ""
                  }
                },
                [
                  _c("number-edit", {
                    attrs: {
                      disabled:
                        !_vm.currentTemplate.feeSchedule.checks.setting ||
                        !_vm.editMode,
                      step: "0.01",
                      oninput:
                        "javascript: if (!/^\\d+(\\.\\d{0,2})?$/.test(this.value)) { this.value = this.value.slice(0, -1); }"
                    },
                    model: {
                      value: _vm.currentTemplate.feeSchedule.checks.per_deposit,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.currentTemplate.feeSchedule.checks,
                          "per_deposit",
                          $$v
                        )
                      },
                      expression:
                        "currentTemplate.feeSchedule.checks.per_deposit"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("li"),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
              _vm._v(" "),
              _c(
                "validator",
                {
                  attrs: {
                    value: _vm.currentTemplate.feeSchedule.assets.per_deposit,
                    reqs: _vm.currentTemplate.feeSchedule.assets.setting
                      ? "required"
                      : ""
                  }
                },
                [
                  _c("number-edit", {
                    attrs: {
                      disabled:
                        !_vm.currentTemplate.feeSchedule.assets.setting ||
                        !_vm.editMode,
                      step: "0.01",
                      oninput:
                        "javascript: if (!/^\\d+(\\.\\d{0,2})?$/.test(this.value)) { this.value = this.value.slice(0, -1); }"
                    },
                    model: {
                      value: _vm.currentTemplate.feeSchedule.assets.per_deposit,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.currentTemplate.feeSchedule.assets,
                          "per_deposit",
                          $$v
                        )
                      },
                      expression:
                        "currentTemplate.feeSchedule.assets.per_deposit"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", [
        _c("h3", [_vm._v("Withdrawal")]),
        _vm._v(" "),
        _c("ul", { staticClass: "fee-list" }, [
          _c("li"),
          _vm._v(" "),
          _c("li"),
          _vm._v(" "),
          _c("li"),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
              _vm._v(" "),
              _c(
                "validator",
                {
                  attrs: {
                    value:
                      _vm.currentTemplate.feeSchedule.domesticWire
                        .per_withdrawal,
                    reqs: _vm.currentTemplate.feeSchedule.domesticWire.setting
                      ? "required"
                      : ""
                  }
                },
                [
                  _c("number-edit", {
                    attrs: {
                      disabled:
                        !_vm.currentTemplate.feeSchedule.domesticWire.setting ||
                        !_vm.editMode,
                      step: "0.01",
                      oninput:
                        "javascript: if (!/^\\d+(\\.\\d{0,2})?$/.test(this.value)) { this.value = this.value.slice(0, -1); }"
                    },
                    model: {
                      value:
                        _vm.currentTemplate.feeSchedule.domesticWire
                          .per_withdrawal,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.currentTemplate.feeSchedule.domesticWire,
                          "per_withdrawal",
                          $$v
                        )
                      },
                      expression:
                        "currentTemplate.feeSchedule.domesticWire.per_withdrawal"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
              _vm._v(" "),
              _c(
                "validator",
                {
                  attrs: {
                    value:
                      _vm.currentTemplate.feeSchedule.internationalWire
                        .per_withdrawal,
                    reqs: _vm.currentTemplate.feeSchedule.internationalWire
                      .setting
                      ? "required"
                      : ""
                  }
                },
                [
                  _c("number-edit", {
                    attrs: {
                      disabled:
                        !_vm.currentTemplate.feeSchedule.internationalWire
                          .setting || !_vm.editMode,
                      step: "0.01",
                      oninput:
                        "javascript: if (!/^\\d+(\\.\\d{0,2})?$/.test(this.value)) { this.value = this.value.slice(0, -1); }"
                    },
                    model: {
                      value:
                        _vm.currentTemplate.feeSchedule.internationalWire
                          .per_withdrawal,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.currentTemplate.feeSchedule.internationalWire,
                          "per_withdrawal",
                          $$v
                        )
                      },
                      expression:
                        "currentTemplate.feeSchedule.internationalWire.per_withdrawal"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
              _vm._v(" "),
              _c(
                "validator",
                {
                  attrs: {
                    value: _vm.currentTemplate.feeSchedule.ach.per_withdrawal,
                    reqs: _vm.currentTemplate.feeSchedule.ach.setting
                      ? "required"
                      : ""
                  }
                },
                [
                  _c("number-edit", {
                    attrs: {
                      disabled:
                        !_vm.currentTemplate.feeSchedule.ach.setting ||
                        !_vm.editMode,
                      step: "0.01",
                      oninput:
                        "javascript: if (!/^\\d+(\\.\\d{0,2})?$/.test(this.value)) { this.value = this.value.slice(0, -1); }"
                    },
                    model: {
                      value: _vm.currentTemplate.feeSchedule.ach.per_withdrawal,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.currentTemplate.feeSchedule.ach,
                          "per_withdrawal",
                          $$v
                        )
                      },
                      expression:
                        "currentTemplate.feeSchedule.ach.per_withdrawal"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
              _vm._v(" "),
              _c(
                "validator",
                {
                  attrs: {
                    value:
                      _vm.currentTemplate.feeSchedule.accountTransfer
                        .per_withdrawal,
                    reqs: _vm.currentTemplate.feeSchedule.accountTransfer
                      .setting
                      ? "required"
                      : ""
                  }
                },
                [
                  _c("number-edit", {
                    attrs: {
                      disabled:
                        !_vm.currentTemplate.feeSchedule.accountTransfer
                          .setting || !_vm.editMode,
                      step: "0.01",
                      oninput:
                        "javascript: if (!/^\\d+(\\.\\d{0,2})?$/.test(this.value)) { this.value = this.value.slice(0, -1); }"
                    },
                    model: {
                      value:
                        _vm.currentTemplate.feeSchedule.accountTransfer
                          .per_withdrawal,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.currentTemplate.feeSchedule.accountTransfer,
                          "per_withdrawal",
                          $$v
                        )
                      },
                      expression:
                        "currentTemplate.feeSchedule.accountTransfer.per_withdrawal"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
              _vm._v(" "),
              _c(
                "validator",
                {
                  attrs: {
                    value:
                      _vm.currentTemplate.feeSchedule.checks.per_withdrawal,
                    reqs: _vm.currentTemplate.feeSchedule.checks.setting
                      ? "required"
                      : ""
                  }
                },
                [
                  _c("number-edit", {
                    attrs: {
                      disabled:
                        !_vm.currentTemplate.feeSchedule.checks.setting ||
                        !_vm.editMode,
                      step: "0.01",
                      oninput:
                        "javascript: if (!/^\\d+(\\.\\d{0,2})?$/.test(this.value)) { this.value = this.value.slice(0, -1); }"
                    },
                    model: {
                      value:
                        _vm.currentTemplate.feeSchedule.checks.per_withdrawal,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.currentTemplate.feeSchedule.checks,
                          "per_withdrawal",
                          $$v
                        )
                      },
                      expression:
                        "currentTemplate.feeSchedule.checks.per_withdrawal"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
              _vm._v(" "),
              _c(
                "validator",
                {
                  attrs: {
                    value:
                      _vm.currentTemplate.feeSchedule.debitCard.per_withdrawal,
                    reqs: _vm.currentTemplate.feeSchedule.debitCard.setting
                      ? "required"
                      : ""
                  }
                },
                [
                  _c("number-edit", {
                    attrs: {
                      disabled:
                        !_vm.currentTemplate.feeSchedule.debitCard.setting ||
                        !_vm.editMode,
                      step: "0.01",
                      oninput:
                        "javascript: if (!/^\\d+(\\.\\d{0,2})?$/.test(this.value)) { this.value = this.value.slice(0, -1); }"
                    },
                    model: {
                      value:
                        _vm.currentTemplate.feeSchedule.debitCard
                          .per_withdrawal,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.currentTemplate.feeSchedule.debitCard,
                          "per_withdrawal",
                          $$v
                        )
                      },
                      expression:
                        "currentTemplate.feeSchedule.debitCard.per_withdrawal"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
              _vm._v(" "),
              _c(
                "validator",
                {
                  attrs: {
                    value:
                      _vm.currentTemplate.feeSchedule.assets.per_withdrawal,
                    reqs: _vm.currentTemplate.feeSchedule.assets.setting
                      ? "required"
                      : ""
                  }
                },
                [
                  _c("number-edit", {
                    attrs: {
                      disabled:
                        !_vm.currentTemplate.feeSchedule.assets.setting ||
                        !_vm.editMode,
                      step: "0.01",
                      oninput:
                        "javascript: if (!/^\\d+(\\.\\d{0,2})?$/.test(this.value)) { this.value = this.value.slice(0, -1); }"
                    },
                    model: {
                      value:
                        _vm.currentTemplate.feeSchedule.assets.per_withdrawal,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.currentTemplate.feeSchedule.assets,
                          "per_withdrawal",
                          $$v
                        )
                      },
                      expression:
                        "currentTemplate.feeSchedule.assets.per_withdrawal"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", [
        _c("h3", [_vm._v("Limits")]),
        _vm._v(" "),
        _c("ul", { staticClass: "fee-list" }, [
          _c("li"),
          _vm._v(" "),
          _c("li"),
          _vm._v(" "),
          _c("li"),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
              _vm._v(" "),
              _c("number-edit", {
                attrs: {
                  disabled:
                    !_vm.currentTemplate.feeSchedule.domesticWire.setting ||
                    !_vm.editMode,
                  step: "0.01",
                  oninput:
                    "javascript: if (!/^\\d+(\\.\\d{0,2})?$/.test(this.value)) { this.value = this.value.slice(0, -1); }"
                },
                model: {
                  value: _vm.currentTemplate.feeSchedule.domesticWire.limit,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.currentTemplate.feeSchedule.domesticWire,
                      "limit",
                      $$v
                    )
                  },
                  expression: "currentTemplate.feeSchedule.domesticWire.limit"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
              _vm._v(" "),
              _c("number-edit", {
                attrs: {
                  disabled:
                    !_vm.currentTemplate.feeSchedule.internationalWire
                      .setting || !_vm.editMode,
                  step: "0.01",
                  oninput:
                    "javascript: if (!/^\\d+(\\.\\d{0,2})?$/.test(this.value)) { this.value = this.value.slice(0, -1); }"
                },
                model: {
                  value:
                    _vm.currentTemplate.feeSchedule.internationalWire.limit,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.currentTemplate.feeSchedule.internationalWire,
                      "limit",
                      $$v
                    )
                  },
                  expression:
                    "currentTemplate.feeSchedule.internationalWire.limit"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
              _vm._v(" "),
              _c("number-edit", {
                attrs: {
                  disabled:
                    !_vm.currentTemplate.feeSchedule.ach.setting ||
                    !_vm.editMode,
                  step: "0.01",
                  oninput:
                    "javascript: if (!/^\\d+(\\.\\d{0,2})?$/.test(this.value)) { this.value = this.value.slice(0, -1); }"
                },
                model: {
                  value: _vm.currentTemplate.feeSchedule.ach.limit,
                  callback: function($$v) {
                    _vm.$set(_vm.currentTemplate.feeSchedule.ach, "limit", $$v)
                  },
                  expression: "currentTemplate.feeSchedule.ach.limit"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
              _vm._v(" "),
              _c("number-edit", {
                attrs: {
                  disabled:
                    !_vm.currentTemplate.feeSchedule.accountTransfer.setting ||
                    !_vm.editMode,
                  step: "0.01",
                  oninput:
                    "javascript: if (!/^\\d+(\\.\\d{0,2})?$/.test(this.value)) { this.value = this.value.slice(0, -1); }"
                },
                model: {
                  value: _vm.currentTemplate.feeSchedule.accountTransfer.limit,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.currentTemplate.feeSchedule.accountTransfer,
                      "limit",
                      $$v
                    )
                  },
                  expression:
                    "currentTemplate.feeSchedule.accountTransfer.limit"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
              _vm._v(" "),
              _c("number-edit", {
                attrs: {
                  disabled:
                    !_vm.currentTemplate.feeSchedule.checks.setting ||
                    !_vm.editMode,
                  step: "0.01",
                  oninput:
                    "javascript: if (!/^\\d+(\\.\\d{0,2})?$/.test(this.value)) { this.value = this.value.slice(0, -1); }"
                },
                model: {
                  value: _vm.currentTemplate.feeSchedule.checks.limit,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.currentTemplate.feeSchedule.checks,
                      "limit",
                      $$v
                    )
                  },
                  expression: "currentTemplate.feeSchedule.checks.limit"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("li"),
          _vm._v(" "),
          _c("li")
        ])
      ])
    ]),
    _vm._v(" "),
    _c("h2", [_vm._v("MODULES")]),
    _vm._v(" "),
    _c("ul", { staticClass: "pricing-list pl2 madon" }, [
      _c(
        "li",
        { staticStyle: { width: "100%" } },
        [
          _c("label", { staticStyle: { "min-width": "16.66%" } }),
          _vm._v(" "),
          _c("multiselect-dropdown", {
            attrs: {
              disabled: !_vm.editMode,
              options: _vm.modulesOpts,
              isMulti: true
            },
            model: {
              value: _vm.currentTemplate.feeSchedule.modules,
              callback: function($$v) {
                _vm.$set(_vm.currentTemplate.feeSchedule, "modules", $$v)
              },
              expression: "currentTemplate.feeSchedule.modules"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.currentTemplate.feeSchedule.modules &&
      _vm.currentTemplate.feeSchedule.modules.indexOf("exchangeDesk") != -1
        ? _c("li", [
            _vm._m(3),
            _vm._v(" "),
            _c("ul", [
              _c(
                "li",
                [
                  _c("label", [_vm._v("Setup")]),
                  _vm._v(" "),
                  _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
                  _vm._v(" "),
                  _c(
                    "validator",
                    {
                      attrs: {
                        value:
                          _vm.currentTemplate.feeSchedule.exchangeDesksetup,
                        reqs:
                          _vm.currentTemplate.feeSchedule.modules.indexOf(
                            "exchangeDesk"
                          ) != -1
                            ? "required"
                            : ""
                      }
                    },
                    [
                      _c("number-edit", {
                        attrs: {
                          disabled: !_vm.editMode,
                          step: "0.01",
                          oninput:
                            "javascript: if (!/^\\d+(\\.\\d{0,2})?$/.test(this.value)) { this.value = this.value.slice(0, -1); }"
                        },
                        model: {
                          value:
                            _vm.currentTemplate.feeSchedule.exchangeDesksetup,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.currentTemplate.feeSchedule,
                              "exchangeDesksetup",
                              $$v
                            )
                          },
                          expression:
                            "currentTemplate.feeSchedule.exchangeDesksetup"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                [
                  _c("label", [_vm._v("Monthly")]),
                  _vm._v(" "),
                  _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
                  _vm._v(" "),
                  _c(
                    "validator",
                    {
                      attrs: {
                        value:
                          _vm.currentTemplate.feeSchedule.exchangeDeskmonthly,
                        reqs:
                          _vm.currentTemplate.feeSchedule.modules.indexOf(
                            "exchangeDesk"
                          ) != -1
                            ? "required"
                            : ""
                      }
                    },
                    [
                      _c("number-edit", {
                        attrs: {
                          disabled: !_vm.editMode,
                          step: "0.01",
                          oninput:
                            "javascript: if (!/^\\d+(\\.\\d{0,2})?$/.test(this.value)) { this.value = this.value.slice(0, -1); }"
                        },
                        model: {
                          value:
                            _vm.currentTemplate.feeSchedule.exchangeDeskmonthly,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.currentTemplate.feeSchedule,
                              "exchangeDeskmonthly",
                              $$v
                            )
                          },
                          expression:
                            "currentTemplate.feeSchedule.exchangeDeskmonthly"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.currentTemplate.feeSchedule.modules &&
      _vm.currentTemplate.feeSchedule.modules.indexOf("tradeDesk") != -1
        ? _c("li", [
            _vm._m(4),
            _vm._v(" "),
            _c("ul", [
              _c(
                "li",
                [
                  _c("label", [_vm._v("Setup")]),
                  _vm._v(" "),
                  _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
                  _vm._v(" "),
                  _c(
                    "validator",
                    {
                      attrs: {
                        value: _vm.currentTemplate.feeSchedule.tradeDesksetup,
                        reqs:
                          _vm.currentTemplate.feeSchedule.modules.indexOf(
                            "tradeDesk"
                          ) != -1
                            ? "required"
                            : ""
                      }
                    },
                    [
                      _c("number-edit", {
                        attrs: {
                          disabled: !_vm.editMode,
                          step: "0.01",
                          oninput:
                            "javascript: if (!/^\\d+(\\.\\d{0,2})?$/.test(this.value)) { this.value = this.value.slice(0, -1); }"
                        },
                        model: {
                          value: _vm.currentTemplate.feeSchedule.tradeDesksetup,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.currentTemplate.feeSchedule,
                              "tradeDesksetup",
                              $$v
                            )
                          },
                          expression:
                            "currentTemplate.feeSchedule.tradeDesksetup"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                [
                  _c("label", [_vm._v("Monthly")]),
                  _vm._v(" "),
                  _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
                  _vm._v(" "),
                  _c(
                    "validator",
                    {
                      attrs: {
                        value: _vm.currentTemplate.feeSchedule.tradeDeskmonthly,
                        reqs:
                          _vm.currentTemplate.feeSchedule.modules.indexOf(
                            "tradeDesk"
                          ) != -1
                            ? "required"
                            : ""
                      }
                    },
                    [
                      _c("number-edit", {
                        attrs: {
                          disabled: !_vm.editMode,
                          step: "0.01",
                          oninput:
                            "javascript: if (!/^\\d+(\\.\\d{0,2})?$/.test(this.value)) { this.value = this.value.slice(0, -1); }"
                        },
                        model: {
                          value:
                            _vm.currentTemplate.feeSchedule.tradeDeskmonthly,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.currentTemplate.feeSchedule,
                              "tradeDeskmonthly",
                              $$v
                            )
                          },
                          expression:
                            "currentTemplate.feeSchedule.tradeDeskmonthly"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("h2", [_vm._v("OPTIONS")]),
    _vm._v(" "),
    _c("ul", { staticClass: "pricing-list pl2 madon" }, [
      _c(
        "li",
        { staticStyle: { width: "100%" } },
        [
          _c("label", { staticStyle: { "min-width": "16.66%" } }),
          _vm._v(" "),
          _c("multiselect-dropdown", {
            attrs: {
              disabled: !_vm.editMode,
              options: _vm.optionsOpts,
              isMulti: true
            },
            model: {
              value: _vm.currentTemplate.feeSchedule.options,
              callback: function($$v) {
                _vm.$set(_vm.currentTemplate.feeSchedule, "options", $$v)
              },
              expression: "currentTemplate.feeSchedule.options"
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("em"), _vm._v("Price")])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("em"), _vm._v("Account Limits")])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("h3", [_vm._v("Name")]),
      _vm._v(" "),
      _c("ul", { staticClass: "fee-list" }, [
        _c("li", [_c("label", [_vm._v("Volume Fee")])]),
        _vm._v(" "),
        _c("li", [_c("label", [_vm._v("Exchange")])]),
        _vm._v(" "),
        _c("li", [_c("label", [_vm._v("Dormant Accounts")])]),
        _vm._v(" "),
        _c("li", [_c("label", [_vm._v("Domestic Wire")])]),
        _vm._v(" "),
        _c("li", [_c("label", [_vm._v("International Wire")])]),
        _vm._v(" "),
        _c("li", [_c("label", [_vm._v("ACH")])]),
        _vm._v(" "),
        _c("li", [_c("label", [_vm._v("Account Transfer")])]),
        _vm._v(" "),
        _c("li", [_c("label", [_vm._v("Checks")])]),
        _vm._v(" "),
        _c("li", [_c("label", [_vm._v("Debit Card")])]),
        _vm._v(" "),
        _c("li", [_c("label", [_vm._v("Digital Assets")])])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("em"), _vm._v("Exchange Desk")])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("em"), _vm._v("Trade Desk")])
  }
]
render._withStripped = true

export { render, staticRenderFns }