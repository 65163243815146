var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticStyle: { "enable-background": "new 0 0 24 24" },
      attrs: {
        id: "Layer_1",
        version: "1.1",
        viewBox: "0 0 24 24",
        width: _vm.size + "px",
        height: _vm.size + "px",
        "xml:space": "preserve",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink"
      }
    },
    [
      _c("g", [
        _c("circle", {
          attrs: {
            fill: "none",
            stroke: _vm.color,
            "stroke-width": "1.5",
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-miterlimit": "10",
            cx: "12",
            cy: "12",
            r: "11.3"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            fill: "none",
            stroke: _vm.color,
            "stroke-width": "1.5",
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-miterlimit": "10",
            d:
              "M12,14.9c-3.5,0-6.5,2-8,5c2,2.1,4.9,3.3,8,3.3s6-1.3,8-3.3C18.5,17,15.5,14.9,12,14.9z"
          }
        }),
        _vm._v(" "),
        _c("circle", {
          attrs: {
            fill: "none",
            stroke: _vm.color,
            "stroke-width": "1.5",
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-miterlimit": "10",
            cx: "12",
            cy: "8.7",
            r: "3.6"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }