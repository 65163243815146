var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-table-container file-list-container" },
    [
      _c("table", { staticClass: "file-list content-table" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "tbody",
          _vm._l(_vm.emails, function(email) {
            return _c("tr", [
              _c("td", [_vm._v(_vm._s(email.emailType))]),
              _vm._v(" "),
              _c("td", [
                _vm._v(
                  _vm._s(_vm.formatDate(email.created, "YYYY-MM-DD HH:mm:ss"))
                )
              ])
            ])
          }),
          0
        )
      ]),
      _vm._v(" "),
      _c("portal", { attrs: { to: "actions" } }, [
        _c(
          "ul",
          [
            this.agreements.length > 0 && this.item.agentDb.status == "A03"
              ? _c("li", [
                  _c(
                    "a",
                    {
                      attrs: { href: "" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          !_vm.emailDisabled.Agreement &&
                            _vm.sendEmailUser("Agreement")
                        }
                      }
                    },
                    [_vm._v("Partner Agreement")]
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            this.item.agentDb.status == "A05"
              ? _c("li", [
                  _c(
                    "a",
                    {
                      attrs: { href: "" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          !_vm.emailDisabled.Declined &&
                            _vm.sendEmailUser("Declined")
                        }
                      }
                    },
                    [_vm._v("Partner Declined")]
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            this.item.agentDb.status == "A07"
              ? _c("li", [
                  _c(
                    "a",
                    {
                      attrs: { href: "" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          !_vm.emailDisabled.Welcome &&
                            _vm.sendEmail1("Welcome", true)
                        }
                      }
                    },
                    [_vm._v("Partner Welcome")]
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.item.agentDb.emailConfirmation === false
              ? [
                  _vm.settings.settings.emailNotifications.emailConfirmation
                    ? [
                        _vm.item.agentDb.status == "A02"
                          ? [
                              _c("li", [
                                _c(
                                  "a",
                                  {
                                    attrs: { href: "" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.sendConfirmationEmail1()
                                      }
                                    }
                                  },
                                  [_vm._v("Email Confirmation")]
                                )
                              ])
                            ]
                          : _vm._e()
                      ]
                    : _vm._e()
                ]
              : _vm._e()
          ],
          2
        )
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Type")]),
        _vm._v(" "),
        _c("th", [_vm._v("Created")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }