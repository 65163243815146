var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "select",
    {
      domProps: { value: _vm.value },
      on: {
        input: function($event) {
          return _vm.$emit("input", $event.target.value)
        }
      }
    },
    _vm._l(_vm.options, function(opt) {
      return _c(
        "option",
        {
          attrs: { disabled: opt.disabled },
          domProps: { value: opt.value, selected: opt.value == _vm.value }
        },
        [_vm._v(_vm._s(opt.label))]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }