var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.shown,
          expression: "shown"
        }
      ],
      staticClass: "setup-box"
    },
    [
      _c("div", [
        _c(
          "div",
          { staticClass: "ncontent appf appnat" },
          [
            _vm.step < 5
              ? _c("h3", { staticStyle: { "text-align": "center" } }, [
                  _vm._v("Merchant Setup")
                ])
              : _c("h3", { staticStyle: { "text-align": "center" } }, [
                  _vm._v("Finalize")
                ]),
            _vm._v(" "),
            _c("form-progress", {
              attrs: {
                step: _vm.step,
                steps: [
                  "Merchant<br>Profile",
                  "Company<br>Profile",
                  "Pricing<br>Profile",
                  "Payments<br>Profile",
                  "Equipment<br>Profile",
                  "Access<br>Profile",
                  "Advanced",
                  "Summary"
                ]
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "setup-details" }, [
              _c("div", { staticStyle: { width: "30%" } }, [
                _c("label", [_vm._v("Build ID")]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.item.buildId))]),
                _vm._v(" "),
                _c("label", { staticStyle: { "margin-top": "15px" } }, [
                  _vm._v("Solution")
                ]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(_vm._s(_vm.viewSolutions(_vm.item.app.solution)))
                ]),
                _vm._v(" "),
                _c("label", { staticStyle: { "margin-top": "15px" } }, [
                  _vm._v("Account Manager")
                ]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.item.app.manager
                        ? _vm.item.app.manager
                            .fullName /*+" "+item.app.manager.lastName*/
                        : ""
                    )
                  )
                ]),
                _vm._v(" "),
                _c("label", [_vm._v("Partner")]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.item.app.source
                        ? _vm.item.app.source.agentDb.name
                        : ""
                    )
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticStyle: { width: "30%" } }, [
                _c("label", [_vm._v("Build By")]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.item.buildBy &&
                        _vm.item.buildBy.fullName +
                          " " +
                          _vm.item.buildBy.lastName
                    )
                  )
                ]),
                _vm._v(" "),
                _c("label", { staticStyle: { "margin-top": "15px" } }, [
                  _vm._v("Account Number")
                ]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.item.app.accountId))]),
                _vm._v(" "),
                _c("label", { staticStyle: { "margin-top": "15px" } }, [
                  _vm._v("Company Name")
                ]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.item.app.companyName))]),
                _vm._v(" "),
                _c("label", [_vm._v("Merchant Type")]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.item.app.industry))])
              ]),
              _vm._v(" "),
              _c("div", { staticStyle: { width: "30%" } }, [
                _c("label", [_vm._v("Build Start Date")]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(_vm._s(_vm.formatDate(_vm.item.created, "YYYY-MM-DD")))
                ]),
                _vm._v(" "),
                _c("label", { staticStyle: { "margin-top": "15px" } }, [
                  _vm._v("Account Type")
                ]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.accountTypes[_vm.item.app.accountType] || "")
                  )
                ]),
                _vm._v(" "),
                _c("label", { staticStyle: { "margin-top": "15px" } }, [
                  _vm._v("Company DBA")
                ]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.item.app.companyNameTrade))]),
                _vm._v(" "),
                _c("label", [_vm._v("MCC")]),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticStyle: {
                      "text-overflow": "ellipsis",
                      overflow: "hidden"
                    }
                  },
                  [_vm._v(_vm._s(_vm.item.app.mcc))]
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "implementation-note" }, [
              _c("p", [_vm._v(_vm._s(_vm.item.merchant.implMessage))])
            ]),
            _vm._v(" "),
            _vm._l([_vm.item.setupData || {}], function(sdata) {
              return [
                _vm.step == 0
                  ? _c("form", { key: "step1", staticClass: "form-step" }, [
                      _c("section", [
                        _c("h2", [_vm._v("General Information")]),
                        _vm._v(" "),
                        _c(
                          "ul",
                          { staticClass: "column-form column-form-compact" },
                          [
                            _c("li", [
                              _c("label", [_vm._v("Approval Date")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field" },
                                [
                                  _c(
                                    "validator",
                                    { attrs: { value: 0, reqs: "" } },
                                    [
                                      _c("input", {
                                        attrs: { type: "text", readonly: "" },
                                        domProps: {
                                          value: _vm.formatDate(
                                            _vm.item.app.approveDate,
                                            "YYYY-MM-DD"
                                          )
                                        }
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _c("label", [_vm._v("Language")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field" },
                                [
                                  _c(
                                    "validator",
                                    {
                                      attrs: {
                                        value: _vm.item.app.language,
                                        reqs: "required"
                                      }
                                    },
                                    [
                                      _c("dyn-select", {
                                        attrs: { options: _vm.languages },
                                        model: {
                                          value: _vm.item.app.language,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.item.app,
                                              "language",
                                              $$v
                                            )
                                          },
                                          expression: "item.app.language"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c("h2", [_vm._v("Business Information")]),
                        _vm._v(" "),
                        _c(
                          "ul",
                          { staticClass: "column-form column-form-compact" },
                          [
                            _c("li", [
                              _c("label", [_vm._v("Merchant Type")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field" },
                                [
                                  _c(
                                    "validator",
                                    { attrs: { value: 0, reqs: "" } },
                                    [
                                      _c("input", {
                                        attrs: { type: "text", readonly: "" },
                                        domProps: {
                                          value:
                                            _vm.accountTypes[
                                              _vm.item.app.accountType
                                            ]
                                        }
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _c("label", [_vm._v("Merchant Industry")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field" },
                                [
                                  _c(
                                    "validator",
                                    { attrs: { value: 0, reqs: "" } },
                                    [
                                      _c("input", {
                                        attrs: { type: "text", readonly: "" },
                                        domProps: {
                                          value: _vm.item.app.industry
                                        }
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _c("label", [_vm._v("Website")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field" },
                                [
                                  _c(
                                    "validator",
                                    { attrs: { value: 0, reqs: "" } },
                                    [
                                      _c("input", {
                                        attrs: { type: "text", readonly: "" },
                                        domProps: {
                                          value: _vm.item.app.website
                                        }
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _c("label", [_vm._v("Website User/Pass")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field" },
                                [
                                  _c(
                                    "validator",
                                    { attrs: { value: 0, reqs: "" } },
                                    [
                                      _c("input", {
                                        attrs: { type: "text", readonly: "" },
                                        domProps: {
                                          value:
                                            _vm.item.app.websiteUsername +
                                            " " +
                                            _vm.item.app.websitePassword
                                        }
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _c("label", [_vm._v("Customer Service Email")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field" },
                                [
                                  _c(
                                    "validator",
                                    { attrs: { value: 0, reqs: "" } },
                                    [
                                      _c("input", {
                                        attrs: { type: "text", readonly: "" },
                                        domProps: {
                                          value: _vm.item.app.emailService
                                        }
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _c("label", [_vm._v("Customer Service Phone")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field" },
                                [
                                  _c(
                                    "validator",
                                    { attrs: { value: 0, reqs: "" } },
                                    [
                                      _c("input", {
                                        attrs: { type: "text", readonly: "" },
                                        domProps: {
                                          value: _vm.item.app.phoneService
                                        }
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _c("label", [_vm._v("Billing Descriptor")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field" },
                                [
                                  _c(
                                    "validator",
                                    { attrs: { value: 0, reqs: "" } },
                                    [
                                      _c("input", {
                                        attrs: { type: "text", readonly: "" },
                                        domProps: {
                                          value: _vm.item.app.descriptor1
                                        }
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _c("label", [_vm._v("Customers Located")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field" },
                                [
                                  _c(
                                    "validator",
                                    { attrs: { value: 0, reqs: "" } },
                                    [
                                      _c("input", {
                                        attrs: { type: "text", readonly: "" },
                                        domProps: { value: "TODO" }
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _c("label", [_vm._v("Customer Type")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field" },
                                [
                                  _c(
                                    "validator",
                                    { attrs: { value: 0, reqs: "" } },
                                    [
                                      _c("input", {
                                        attrs: { type: "text", readonly: "" },
                                        domProps: { value: "TODO" }
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            ])
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "buttons" }, [
                        _c(
                          "a",
                          {
                            staticClass:
                              "btn btn-large btn-plain btn-back btn-disabled",
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                              }
                            }
                          },
                          [_vm._v("Back")]
                        ),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "btn btn-large btn-success btn-next",
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.nextStep.apply(null, arguments)
                              }
                            }
                          },
                          [_vm._v("Next")]
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.step == 1
                  ? _c("form", { key: "step2", staticClass: "form-step" }, [
                      _c(
                        "section",
                        [
                          _c("h2", [_vm._v("Company Information")]),
                          _vm._v(" "),
                          _c(
                            "ul",
                            { staticClass: "column-form column-form-compact" },
                            [
                              _c("li", [
                                _c("label", [_vm._v("Parent Company")]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "field" },
                                  [
                                    _c(
                                      "validator",
                                      { attrs: { value: 0, reqs: "" } },
                                      [
                                        _c("input", {
                                          attrs: { type: "text", readonly: "" },
                                          domProps: {
                                            value:
                                              _vm.item.app.hasParent == 1
                                                ? "Yes"
                                                : "No"
                                          }
                                        })
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]),
                              _vm._v(" "),
                              _c("li", [
                                _c("label", [_vm._v("New Business")]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "field" },
                                  [
                                    _c(
                                      "validator",
                                      { attrs: { value: 0, reqs: "" } },
                                      [
                                        _c("input", {
                                          attrs: { type: "text", readonly: "" },
                                          domProps: {
                                            value: _vm.item.app.companyNew
                                              ? "Yes"
                                              : "No"
                                          }
                                        })
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]),
                              _vm._v(" "),
                              _c("li", [
                                _c("label", [_vm._v("Registration ID")]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "field" },
                                  [
                                    _c(
                                      "validator",
                                      { attrs: { value: 0, reqs: "" } },
                                      [
                                        _c("input", {
                                          attrs: { type: "text", readonly: "" },
                                          domProps: {
                                            value: _vm.item.app.companyRegnum
                                          }
                                        })
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]),
                              _vm._v(" "),
                              _c("li", [
                                _c("label", [_vm._v("Tax ID")]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "field" },
                                  [
                                    _c(
                                      "validator",
                                      { attrs: { value: 0, reqs: "" } },
                                      [
                                        _c("input", {
                                          attrs: { type: "text", readonly: "" },
                                          domProps: {
                                            value: _vm.item.app.companyTaxId
                                          }
                                        })
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]),
                              _vm._v(" "),
                              _c("li", [
                                _c("label", [_vm._v("Company Country")]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "field" },
                                  [
                                    _c(
                                      "validator",
                                      { attrs: { value: 0, reqs: "" } },
                                      [
                                        _c("input", {
                                          attrs: { type: "text", readonly: "" },
                                          domProps: {
                                            value:
                                              _vm.item.app.companyAddressCountry
                                          }
                                        })
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]),
                              _vm._v(" "),
                              _c("li", [
                                _c("label", [_vm._v("Legal Business Name")]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "field" },
                                  [
                                    _c(
                                      "validator",
                                      { attrs: { value: 0, reqs: "" } },
                                      [
                                        _c("input", {
                                          attrs: { type: "text", readonly: "" },
                                          domProps: {
                                            value: _vm.item.app.companyName
                                          }
                                        })
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]),
                              _vm._v(" "),
                              _c("li", [
                                _c("label", [_vm._v("Company DBA Name")]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "field" },
                                  [
                                    _c(
                                      "validator",
                                      { attrs: { value: 0, reqs: "" } },
                                      [
                                        _c("input", {
                                          attrs: { type: "text", readonly: "" },
                                          domProps: {
                                            value: _vm.item.app.companyNameTrade
                                          }
                                        })
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]),
                              _vm._v(" "),
                              _c("li", [
                                _c("label", [_vm._v("Company Website")]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "field" },
                                  [
                                    _c(
                                      "validator",
                                      { attrs: { value: 0, reqs: "" } },
                                      [
                                        _c("input", {
                                          attrs: { type: "text", readonly: "" },
                                          domProps: {
                                            value: _vm.item.app.website
                                          }
                                        })
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]),
                              _vm._v(" "),
                              _c("li", [
                                _c("label", [_vm._v("Company Email")]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "field" },
                                  [
                                    _c(
                                      "validator",
                                      { attrs: { value: 0, reqs: "" } },
                                      [
                                        _c("input", {
                                          attrs: { type: "text", readonly: "" },
                                          domProps: {
                                            value: _vm.item.app.companyEmail
                                          }
                                        })
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]),
                              _vm._v(" "),
                              _c("li", [
                                _c("label", [_vm._v("Company Phone")]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "field" },
                                  [
                                    _c(
                                      "validator",
                                      { attrs: { value: 0, reqs: "" } },
                                      [
                                        _c("input", {
                                          attrs: { type: "text", readonly: "" },
                                          domProps: {
                                            value: _vm.item.app.companyPhone
                                          }
                                        })
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]),
                              _vm._v(" "),
                              _c("li", [
                                _c("label", [_vm._v("Company Address")]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "field" },
                                  [
                                    _c(
                                      "validator",
                                      { attrs: { value: 0, reqs: "" } },
                                      [
                                        _c("input", {
                                          attrs: { type: "text", readonly: "" },
                                          domProps: {
                                            value: _vm.item.app.companyAddress
                                          }
                                        })
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]),
                              _vm._v(" "),
                              _c("li", [
                                _c("label", [_vm._v("Company City")]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "field" },
                                  [
                                    _c(
                                      "validator",
                                      { attrs: { value: 0, reqs: "" } },
                                      [
                                        _c("input", {
                                          attrs: { type: "text", readonly: "" },
                                          domProps: {
                                            value:
                                              _vm.item.app.companyAddressCity
                                          }
                                        })
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]),
                              _vm._v(" "),
                              _c("li", [
                                _c("label", [_vm._v("Company Prov/State")]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "field" },
                                  [
                                    _c(
                                      "validator",
                                      { attrs: { value: 0, reqs: "" } },
                                      [
                                        _c("input", {
                                          attrs: { type: "text", readonly: "" },
                                          domProps: {
                                            value:
                                              _vm.item.app.companyAddressState
                                          }
                                        })
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]),
                              _vm._v(" "),
                              _c("li", [
                                _c("label", [_vm._v("Company Postal/Zip")]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "field" },
                                  [
                                    _c(
                                      "validator",
                                      { attrs: { value: 0, reqs: "" } },
                                      [
                                        _c("input", {
                                          attrs: { type: "text", readonly: "" },
                                          domProps: {
                                            value:
                                              _vm.item.app
                                                .companyAddressPostcode
                                          }
                                        })
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]),
                              _vm._v(" "),
                              false
                                ? _c("li", [
                                    _c("label"),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "field" },
                                      [
                                        _c(
                                          "validator",
                                          {
                                            attrs: {
                                              value: _vm.item.app.language,
                                              reqs: "required"
                                            }
                                          },
                                          [
                                            _c("dyn-select", {
                                              attrs: { options: _vm.languages },
                                              model: {
                                                value: _vm.item.app.language,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.item.app,
                                                    "language",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.app.language"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ])
                                : _vm._e()
                            ]
                          ),
                          _vm._v(" "),
                          _c("h2", [_vm._v("UBOs Information")]),
                          _vm._v(" "),
                          _vm._l(_vm.item.app.ubos || [], function(ubo, ui) {
                            return _c(
                              "ul",
                              {
                                staticClass: "column-form column-form-compact"
                              },
                              [
                                _c("li", [
                                  _c("label", [
                                    _vm._v("UBO " + _vm._s(ui + 1) + " Title")
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "field" },
                                    [
                                      _c(
                                        "validator",
                                        { attrs: { value: 0, reqs: "" } },
                                        [
                                          _c("input", {
                                            attrs: {
                                              type: "text",
                                              readonly: ""
                                            },
                                            domProps: { value: "" }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _c("label", [
                                    _vm._v(
                                      "UBO " + _vm._s(ui + 1) + " First Name"
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "field" },
                                    [
                                      _c(
                                        "validator",
                                        { attrs: { value: 0, reqs: "" } },
                                        [
                                          _c("input", {
                                            attrs: {
                                              type: "text",
                                              readonly: ""
                                            },
                                            domProps: { value: ubo.firstName }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _c("label", [
                                    _vm._v(
                                      "UBO " + _vm._s(ui + 1) + " Last Name"
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "field" },
                                    [
                                      _c(
                                        "validator",
                                        { attrs: { value: 0, reqs: "" } },
                                        [
                                          _c("input", {
                                            attrs: {
                                              type: "text",
                                              readonly: ""
                                            },
                                            domProps: { value: ubo.lastName }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _c("label", [
                                    _vm._v(
                                      "UBO " + _vm._s(ui + 1) + " Ownership %"
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "field" },
                                    [
                                      _c(
                                        "validator",
                                        { attrs: { value: 0, reqs: "" } },
                                        [
                                          _c("input", {
                                            attrs: {
                                              type: "text",
                                              readonly: ""
                                            },
                                            domProps: { value: ubo.ownership }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ])
                              ]
                            )
                          }),
                          _vm._v(" "),
                          _c("h2", [_vm._v("Directors Information")]),
                          _vm._v(" "),
                          _vm._l(_vm.item.app.directors || [], function(
                            ubo,
                            ui
                          ) {
                            return _c(
                              "ul",
                              {
                                staticClass: "column-form column-form-compact"
                              },
                              [
                                _c("li", [
                                  _c("label", [
                                    _vm._v(
                                      "Director " + _vm._s(ui + 1) + " Title"
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "field" },
                                    [
                                      _c(
                                        "validator",
                                        { attrs: { value: 0, reqs: "" } },
                                        [
                                          _c("input", {
                                            attrs: {
                                              type: "text",
                                              readonly: ""
                                            },
                                            domProps: { value: "" }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _c("label", [
                                    _vm._v(
                                      "Director " +
                                        _vm._s(ui + 1) +
                                        " First Name"
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "field" },
                                    [
                                      _c(
                                        "validator",
                                        { attrs: { value: 0, reqs: "" } },
                                        [
                                          _c("input", {
                                            attrs: {
                                              type: "text",
                                              readonly: ""
                                            },
                                            domProps: { value: ubo.firstName }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _c("label", [
                                    _vm._v(
                                      "Director " +
                                        _vm._s(ui + 1) +
                                        " Last Name"
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "field" },
                                    [
                                      _c(
                                        "validator",
                                        { attrs: { value: 0, reqs: "" } },
                                        [
                                          _c("input", {
                                            attrs: {
                                              type: "text",
                                              readonly: ""
                                            },
                                            domProps: { value: ubo.lastName }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ])
                              ]
                            )
                          }),
                          _vm._v(" "),
                          _c("h2", [_vm._v("Contacts Information")]),
                          _vm._v(" "),
                          _c(
                            "ul",
                            { staticClass: "column-form column-form-compact" },
                            [
                              _c("li", [
                                _c("label", [_vm._v("Company Email")]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "field" },
                                  [
                                    _c(
                                      "validator",
                                      { attrs: { value: 0, reqs: "" } },
                                      [
                                        _c("input", {
                                          attrs: { type: "text", readonly: "" },
                                          domProps: {
                                            value: _vm.item.app.companyEmail
                                          }
                                        })
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]),
                              _vm._v(" "),
                              _c("li", [
                                _c("label", [_vm._v("Company Phone")]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "field" },
                                  [
                                    _c(
                                      "validator",
                                      { attrs: { value: 0, reqs: "" } },
                                      [
                                        _c("input", {
                                          attrs: { type: "text", readonly: "" },
                                          domProps: {
                                            value: _vm.item.app.companyPhone
                                          }
                                        })
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _vm._l(_vm.item.app.contacts || [], function(
                            contact
                          ) {
                            return _c(
                              "ul",
                              {
                                staticClass: "column-form column-form-compact"
                              },
                              [
                                _c("li", [
                                  _c("label", [
                                    _vm._v(
                                      _vm._s(_vm.capitalize(contact.type)) +
                                        " Contact First Name"
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "field" },
                                    [
                                      _c(
                                        "validator",
                                        { attrs: { value: 0, reqs: "" } },
                                        [
                                          _c("input", {
                                            attrs: {
                                              type: "text",
                                              readonly: ""
                                            },
                                            domProps: {
                                              value: contact.firstName
                                            }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _c("label", [
                                    _vm._v(
                                      _vm._s(_vm.capitalize(contact.type)) +
                                        " Contact Last Name"
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "field" },
                                    [
                                      _c(
                                        "validator",
                                        { attrs: { value: 0, reqs: "" } },
                                        [
                                          _c("input", {
                                            attrs: {
                                              type: "text",
                                              readonly: ""
                                            },
                                            domProps: {
                                              value: contact.lastName
                                            }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _c("label", [
                                    _vm._v(
                                      _vm._s(_vm.capitalize(contact.type)) +
                                        " Contact Email"
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "field" },
                                    [
                                      _c(
                                        "validator",
                                        { attrs: { value: 0, reqs: "" } },
                                        [
                                          _c("input", {
                                            attrs: {
                                              type: "text",
                                              readonly: ""
                                            },
                                            domProps: { value: contact.email }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _c("label", [
                                    _vm._v(
                                      _vm._s(_vm.capitalize(contact.type)) +
                                        " Contact Phone"
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "field" },
                                    [
                                      _c(
                                        "validator",
                                        { attrs: { value: 0, reqs: "" } },
                                        [
                                          _c("input", {
                                            attrs: {
                                              type: "text",
                                              readonly: ""
                                            },
                                            domProps: { value: contact.phone }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ])
                              ]
                            )
                          }),
                          _vm._v(" "),
                          _c("h2", [_vm._v("Locations Information")]),
                          _vm._v(" "),
                          _c("h2", [_vm._v("Banking Information")]),
                          _vm._v(" "),
                          _vm._l(_vm.item.app.accounts || [], function(
                            account,
                            ai
                          ) {
                            return _c(
                              "ul",
                              {
                                staticClass: "column-form column-form-compact"
                              },
                              [
                                _c("li", [
                                  _c("label", [
                                    _vm._v(
                                      "Bank " +
                                        _vm._s(ai + 1) +
                                        " Account Currency"
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "field" },
                                    [
                                      _c(
                                        "validator",
                                        { attrs: { value: 0, reqs: "" } },
                                        [
                                          _c("input", {
                                            attrs: {
                                              type: "text",
                                              readonly: ""
                                            },
                                            domProps: {
                                              value: _vm.currency(
                                                account.accountCurrency
                                              )
                                            }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _c("label", [
                                    _vm._v(
                                      "Bank " +
                                        _vm._s(ai + 1) +
                                        " Name on Account"
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "field" },
                                    [
                                      _c(
                                        "validator",
                                        { attrs: { value: 0, reqs: "" } },
                                        [
                                          _c("input", {
                                            attrs: {
                                              type: "text",
                                              readonly: ""
                                            },
                                            domProps: {
                                              value: account.accountHolder
                                            }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _c("label", [
                                    _vm._v(
                                      "Bank " + _vm._s(ai + 1) + " Country"
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "field" },
                                    [
                                      _c(
                                        "validator",
                                        { attrs: { value: 0, reqs: "" } },
                                        [
                                          _c("input", {
                                            attrs: {
                                              type: "text",
                                              readonly: ""
                                            },
                                            domProps: {
                                              value: account.bankAddressCountry
                                            }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _c("label", [
                                    _vm._v("Bank " + _vm._s(ai + 1) + " Name")
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "field" },
                                    [
                                      _c(
                                        "validator",
                                        { attrs: { value: 0, reqs: "" } },
                                        [
                                          _c("input", {
                                            attrs: {
                                              type: "text",
                                              readonly: ""
                                            },
                                            domProps: {
                                              value: account.bankName
                                            }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _c("label", [
                                    _vm._v(
                                      "Bank " + _vm._s(ai + 1) + " Account Type"
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "field" },
                                    [
                                      _c(
                                        "validator",
                                        { attrs: { value: 0, reqs: "" } },
                                        [
                                          _c("input", {
                                            attrs: {
                                              type: "text",
                                              readonly: ""
                                            },
                                            domProps: { value: "" }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _c("label", [
                                    _vm._v(
                                      "Bank " +
                                        _vm._s(ai + 1) +
                                        " Account Number"
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "field" },
                                    [
                                      _c(
                                        "validator",
                                        { attrs: { value: 0, reqs: "" } },
                                        [
                                          _c("input", {
                                            attrs: {
                                              type: "text",
                                              readonly: ""
                                            },
                                            domProps: {
                                              value: account.accountNumber
                                            }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _c("label", [
                                    _vm._v(
                                      "Bank " +
                                        _vm._s(ai + 1) +
                                        " Institution Number"
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "field" },
                                    [
                                      _c(
                                        "validator",
                                        { attrs: { value: 0, reqs: "" } },
                                        [
                                          _c("input", {
                                            attrs: {
                                              type: "text",
                                              readonly: ""
                                            },
                                            domProps: {
                                              value: account.accountSwift
                                            }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _c("label", [
                                    _vm._v(
                                      "Bank " +
                                        _vm._s(ai + 1) +
                                        " Transit Number"
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "field" },
                                    [
                                      _c(
                                        "validator",
                                        { attrs: { value: 0, reqs: "" } },
                                        [
                                          _c("input", {
                                            attrs: {
                                              type: "text",
                                              readonly: ""
                                            },
                                            domProps: {
                                              value: account.accountIBAN
                                            }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _c("label", [
                                    _vm._v(
                                      "Bank " +
                                        _vm._s(ai + 1) +
                                        " Routing Number"
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "field" },
                                    [
                                      _c(
                                        "validator",
                                        { attrs: { value: 0, reqs: "" } },
                                        [
                                          _c("input", {
                                            attrs: {
                                              type: "text",
                                              readonly: ""
                                            },
                                            domProps: {
                                              value: account.accountRouting
                                            }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ])
                              ]
                            )
                          })
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "buttons" }, [
                        _c(
                          "a",
                          {
                            staticClass: "btn btn-large btn-plain btn-back",
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.prevStep.apply(null, arguments)
                              }
                            }
                          },
                          [_vm._v("Back")]
                        ),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "btn btn-large btn-success btn-next",
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.nextStep.apply(null, arguments)
                              }
                            }
                          },
                          [_vm._v("Next")]
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.step == 2
                  ? _c("form", { key: "step3", staticClass: "form-step" }, [
                      _c(
                        "section",
                        [
                          _c("h2", [_vm._v("Pricing Info")]),
                          _vm._v(" "),
                          _c("pricing-details", {
                            attrs: {
                              currentTemplate: _vm.intake.formData,
                              solution: _vm.item.app.solution
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "buttons" }, [
                        _c(
                          "a",
                          {
                            staticClass: "btn btn-large btn-plain btn-back",
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.prevStep.apply(null, arguments)
                              }
                            }
                          },
                          [_vm._v("Back")]
                        ),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "btn btn-large btn-success btn-next",
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.nextStep.apply(null, arguments)
                              }
                            }
                          },
                          [_vm._v("Next")]
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.step == 3
                  ? _c("form", { key: "step5", staticClass: "form-step" }, [
                      _c("section", [
                        _c("h2", [_vm._v("Connectivity")]),
                        _vm._v(" "),
                        _c(
                          "ul",
                          { staticClass: "column-form column-form-compact" },
                          [
                            _c("li", [
                              _c("label", [_vm._v("Gateway")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field" },
                                [
                                  _c(
                                    "validator",
                                    { attrs: { value: 0, reqs: "" } },
                                    [
                                      _c("dyn-select", {
                                        attrs: { options: _vm.gatewaysList },
                                        model: {
                                          value: _vm.item.app.gateway,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.item.app,
                                              "gateway",
                                              $$v
                                            )
                                          },
                                          expression: "item.app.gateway"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _c("label", [_vm._v("Processor")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field" },
                                [
                                  _c(
                                    "validator",
                                    { attrs: { value: 0, reqs: "" } },
                                    [
                                      _c("dyn-select", {
                                        attrs: { options: _vm.processorsList },
                                        model: {
                                          value: _vm.item.app.processor,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.item.app,
                                              "processor",
                                              $$v
                                            )
                                          },
                                          expression: "item.app.processor"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c("h2", [_vm._v("Payment Methods")]),
                        _vm._v(" "),
                        _c(
                          "ul",
                          { staticClass: "column-form column-form-compact" },
                          [
                            _c("li", [
                              _c("label", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.item.setupData.cardPresent,
                                      expression: "item.setupData.cardPresent"
                                    }
                                  ],
                                  staticStyle: {
                                    "margin-right": "4px",
                                    "vertical-align": "middle"
                                  },
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.item.setupData.cardPresent
                                    )
                                      ? _vm._i(
                                          _vm.item.setupData.cardPresent,
                                          null
                                        ) > -1
                                      : _vm.item.setupData.cardPresent
                                  },
                                  on: {
                                    change: function($event) {
                                      var $$a = _vm.item.setupData.cardPresent,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.item.setupData,
                                              "cardPresent",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.item.setupData,
                                              "cardPresent",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.item.setupData,
                                          "cardPresent",
                                          $$c
                                        )
                                      }
                                    }
                                  }
                                }),
                                _vm._v("\n        Card Present / POS")
                              ])
                            ]),
                            _vm._v(" "),
                            _c("li", { staticStyle: { width: "25%" } }, [
                              _c("label", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.item.setupData.cardNotPresent,
                                      expression:
                                        "item.setupData.cardNotPresent"
                                    }
                                  ],
                                  staticStyle: {
                                    "margin-right": "4px",
                                    "vertical-align": "middle"
                                  },
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.item.setupData.cardNotPresent
                                    )
                                      ? _vm._i(
                                          _vm.item.setupData.cardNotPresent,
                                          null
                                        ) > -1
                                      : _vm.item.setupData.cardNotPresent
                                  },
                                  on: {
                                    change: function($event) {
                                      var $$a =
                                          _vm.item.setupData.cardNotPresent,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.item.setupData,
                                              "cardNotPresent",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.item.setupData,
                                              "cardNotPresent",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.item.setupData,
                                          "cardNotPresent",
                                          $$c
                                        )
                                      }
                                    }
                                  }
                                }),
                                _vm._v(
                                  "\n        Card not Present / E-Commerce"
                                )
                              ])
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c("h2", [_vm._v("Payment Settings")]),
                        _vm._v(" "),
                        _c(
                          "ul",
                          {
                            staticClass:
                              "column-form column-form-compact column-form-compact-3"
                          },
                          [
                            _c("li", [
                              _c("label", [_vm._v("Transaction Currencies")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field" },
                                [
                                  _c(
                                    "validator",
                                    { attrs: { value: 0, reqs: "" } },
                                    [
                                      _c("input", {
                                        attrs: { readonly: "", type: "text" },
                                        domProps: {
                                          value:
                                            _vm.item.app.transactionCurrencies
                                        }
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _c("label", [
                                _vm._v("Monthly Transaction Volume Limit")
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field" },
                                [
                                  _c(
                                    "validator",
                                    { attrs: { value: 0, reqs: "" } },
                                    [
                                      _c("input", {
                                        attrs: { type: "text", readonly: "" },
                                        domProps: {
                                          value: _vm.item.merchant.monthlyLimit
                                        }
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _c("label", [
                                _vm._v("Annual Transaction Volume Limit")
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field" },
                                [
                                  _c(
                                    "validator",
                                    { attrs: { value: 0, reqs: "" } },
                                    [
                                      _c("input", {
                                        attrs: { type: "text" },
                                        domProps: {
                                          value: _vm.item.merchant.annualLimit
                                        }
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "ul",
                          {
                            staticClass:
                              "column-form column-form-compact column-form-compact-3"
                          },
                          [
                            _c("li", [
                              _c("label", [
                                _vm._v("Minimum Transaction Amount")
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field" },
                                [
                                  _c(
                                    "validator",
                                    { attrs: { value: 0, reqs: "" } },
                                    [
                                      _c("input", {
                                        attrs: { type: "text" },
                                        domProps: {
                                          value:
                                            _vm.item.setupData.minTransaction
                                        }
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _c("label", [
                                _vm._v("Average Transaction Amount")
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field" },
                                [
                                  _c(
                                    "validator",
                                    { attrs: { value: 0, reqs: "" } },
                                    [
                                      _c("input", {
                                        attrs: { readonly: "", type: "text" },
                                        domProps: {
                                          value: _vm.item.app.avgTransaction
                                        }
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _c("label", [
                                _vm._v("Maximum Transaction Amount")
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field" },
                                [
                                  _c(
                                    "validator",
                                    { attrs: { value: 0, reqs: "" } },
                                    [
                                      _c("input", {
                                        attrs: { readonly: "", type: "text" },
                                        domProps: {
                                          value: _vm.item.app.maxTransaction
                                        }
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c("h2", { staticStyle: { "font-size": "1.3em" } }, [
                          _vm._v("EFT / ACH")
                        ]),
                        _vm._v(" "),
                        _c(
                          "ul",
                          {
                            staticClass:
                              "column-form column-form-compact column-form-compact-3"
                          },
                          [
                            _c("li", [
                              _c("label", [
                                _vm._v("Monthly Transaction Volume Limit")
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field" },
                                [
                                  _c(
                                    "validator",
                                    { attrs: { value: 0, reqs: "" } },
                                    [
                                      _c("input", {
                                        attrs: { readonly: "", type: "text" },
                                        domProps: {
                                          value:
                                            _vm.item.setupData.eftMonthlyLimit
                                        }
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _c("label", [
                                _vm._v("Annual Transaction Volume Limit")
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field" },
                                [
                                  _c(
                                    "validator",
                                    { attrs: { value: 0, reqs: "" } },
                                    [
                                      _c("input", {
                                        attrs: { readonly: "", type: "text" },
                                        domProps: {
                                          value:
                                            _vm.item.setupData.eftAnnualLimit
                                        }
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "ul",
                          {
                            staticClass:
                              "column-form column-form-compact column-form-compact-3"
                          },
                          [
                            _c("li", [
                              _c("label", [
                                _vm._v("Minimum Transaction Amount")
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field" },
                                [
                                  _c(
                                    "validator",
                                    { attrs: { value: 0, reqs: "" } },
                                    [
                                      _c("input", {
                                        attrs: { readonly: "", type: "text" },
                                        domProps: {
                                          value:
                                            _vm.item.setupData.eftMinTransaction
                                        }
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _c("label", [
                                _vm._v("Average Transaction Amount")
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field" },
                                [
                                  _c(
                                    "validator",
                                    { attrs: { value: 0, reqs: "" } },
                                    [
                                      _c("input", {
                                        attrs: { readonly: "", type: "text" },
                                        domProps: {
                                          value:
                                            _vm.item.setupData.eftAvgTransaction
                                        }
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _c("label", [
                                _vm._v("Maximum Transaction Amount")
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field" },
                                [
                                  _c(
                                    "validator",
                                    { attrs: { value: 0, reqs: "" } },
                                    [
                                      _c("input", {
                                        attrs: { readonly: "", type: "text" },
                                        domProps: {
                                          value:
                                            _vm.item.setupData.eftMaxTransaction
                                        }
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c("h2", [_vm._v("TIDS & KEYS Settings")]),
                        _vm._v(" "),
                        _c("ul", {
                          staticClass: "column-form column-form-compact"
                        }),
                        _vm._v(" "),
                        _c("h2", [_vm._v("Risk & Compliance Settings")]),
                        _vm._v(" "),
                        _c(
                          "ul",
                          { staticClass: "column-form column-form-compact" },
                          [
                            _c("li", [
                              _c("label", [_vm._v("Compliance Review Days")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field" },
                                [
                                  _c(
                                    "validator",
                                    { attrs: { value: 0, reqs: "" } },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.item.merchant.reviewDays,
                                            expression:
                                              "item.merchant.reviewDays"
                                          }
                                        ],
                                        attrs: { type: "text", readonly: "" },
                                        domProps: {
                                          value: _vm.item.merchant.reviewDays
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.item.merchant,
                                              "reviewDays",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _c("label", [_vm._v("Account Reserve (%)")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field" },
                                [
                                  _c(
                                    "validator",
                                    { attrs: { value: 0, reqs: "" } },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.item.merchant.reserve,
                                            expression: "item.merchant.reserve"
                                          }
                                        ],
                                        attrs: { type: "text", readonly: "" },
                                        domProps: {
                                          value: _vm.item.merchant.reserve
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.item.merchant,
                                              "reserve",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _c("label", [_vm._v("Account Reserve (Days)")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field" },
                                [
                                  _c(
                                    "validator",
                                    { attrs: { value: 0, reqs: "" } },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.item.merchant.reserveHold,
                                            expression:
                                              "item.merchant.reserveHold"
                                          }
                                        ],
                                        attrs: { type: "text", readonly: "" },
                                        domProps: {
                                          value: _vm.item.merchant.reserveHold
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.item.merchant,
                                              "reserveHold",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _c("label", [_vm._v("Settlement Delay (Days)")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field" },
                                [
                                  _c(
                                    "validator",
                                    { attrs: { value: 0, reqs: "" } },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.item.merchant.settlementDays,
                                            expression:
                                              "item.merchant.settlementDays"
                                          }
                                        ],
                                        attrs: { type: "text", readonly: "" },
                                        domProps: {
                                          value:
                                            _vm.item.merchant.settlementDays
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.item.merchant,
                                              "settlementDays",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _c("label", [_vm._v("Fraud Prevention")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field" },
                                [
                                  _c(
                                    "validator",
                                    { attrs: { value: 0, reqs: "" } },
                                    [
                                      _c("dyn-select", {
                                        attrs: { options: [] },
                                        model: {
                                          value:
                                            _vm.item.setupData.fraudPrevention,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.item.setupData,
                                              "fraudPrevention",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "item.setupData.fraudPrevention"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", { staticClass: "double" }, [
                              _c("label", [_vm._v("Billing Descriptor")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field" },
                                [
                                  _c(
                                    "validator",
                                    { attrs: { value: 0, reqs: "" } },
                                    [
                                      _c("input", {
                                        attrs: { readonly: "", type: "text" },
                                        domProps: {
                                          value: _vm.item.app.descriptor1
                                        }
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", { staticClass: "triple" }, [
                              _c("label", [_vm._v("Account Class")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field" },
                                [
                                  _c(
                                    "validator",
                                    { attrs: { value: 0, reqs: "" } },
                                    [
                                      _c("input", {
                                        attrs: { readonly: "", type: "text" },
                                        domProps: {
                                          value:
                                            _vm.item.app.class.name +
                                            " " +
                                            _vm.item.app.class.description
                                        }
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", { staticClass: "quintuple" }, [
                              _c("label", [_vm._v("MCC")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field" },
                                [
                                  _c(
                                    "validator",
                                    { attrs: { value: 0, reqs: "" } },
                                    [
                                      _c("input", {
                                        attrs: { readonly: "", type: "text" },
                                        domProps: { value: _vm.item.app.mcc }
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            ])
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "buttons" }, [
                        _c(
                          "a",
                          {
                            staticClass: "btn btn-large btn-plain btn-back",
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.prevStep.apply(null, arguments)
                              }
                            }
                          },
                          [_vm._v("Back")]
                        ),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "btn btn-large btn-success btn-next",
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.nextStep.apply(null, arguments)
                              }
                            }
                          },
                          [_vm._v("Next")]
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.step == 4
                  ? _c(
                      "form",
                      { key: "step5", staticClass: "form-step" },
                      [
                        _vm._l([_vm.intake.formData || {}], function(q) {
                          return _c("section", [
                            _c("h2", [_vm._v("EQUIPMENT")]),
                            _vm._v(" "),
                            _c(
                              "ul",
                              { staticClass: "column-form" },
                              [
                                false
                                  ? _c("li", { staticClass: "fwidth fwx" }, [
                                      _c("label", [_vm._v("Special Offers")]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "field" },
                                        [
                                          _c(
                                            "validator",
                                            {
                                              attrs: {
                                                value:
                                                  q.specialOffer &&
                                                  q.specialOffer.length,
                                                reqs: ""
                                              }
                                            },
                                            [
                                              _c(
                                                "multiselect-desc",
                                                {
                                                  attrs: {
                                                    options: [
                                                      "Free Terminal Program"
                                                    ]
                                                  },
                                                  model: {
                                                    value: q.specialOffer,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        q,
                                                        "specialOffer",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "q.specialOffer"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n              Must ensure that merchant meets program criteria.\n            "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm._l(_vm.terminals, function(ti) {
                                  return [
                                    _c(
                                      "h2",
                                      { staticStyle: { "font-size": "1.2em" } },
                                      [_vm._v("Terminals Type #" + _vm._s(ti))]
                                    ),
                                    _vm._v(" "),
                                    _c("li", {}, [
                                      _c("label", [_vm._v("Model")]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "field complex" },
                                        [
                                          _c(
                                            "validator",
                                            {
                                              attrs: {
                                                value:
                                                  q["terminal" + ti + "Type"],
                                                reqs: ""
                                              }
                                            },
                                            [
                                              _c("dyn-select", {
                                                attrs: {
                                                  options: _vm.equipmentOpt
                                                },
                                                model: {
                                                  value:
                                                    q["terminal" + ti + "Type"],
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      q,
                                                      "terminal" + ti + "Type",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "q['terminal'+ti+'Type']"
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("br"),
                                              _vm._v(
                                                "\n            $" +
                                                  _vm._s(
                                                    (
                                                      _vm.equi(ti)[
                                                        q[
                                                          "terminal" +
                                                            ti +
                                                            "PurchaseOpt"
                                                        ]
                                                          ? "purchase"
                                                          : q[
                                                              "terminal" +
                                                                ti +
                                                                "RentalOpt"
                                                            ]
                                                          ? "rental"
                                                          : "reprogram"
                                                      ] || 0
                                                    ).toFixed(2)
                                                  ) +
                                                  "\n"
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("li", {}, [
                                      _c("label", [_vm._v("QTY")]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "field complex" },
                                        [
                                          _c(
                                            "validator",
                                            {
                                              attrs: {
                                                value:
                                                  q["terminal" + ti + "Qty"],
                                                reqs: ""
                                              }
                                            },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      q[
                                                        "terminal" + ti + "Qty"
                                                      ],
                                                    expression:
                                                      "q['terminal'+ti+'Qty']"
                                                  }
                                                ],
                                                attrs: { type: "text" },
                                                domProps: {
                                                  value:
                                                    q["terminal" + ti + "Qty"]
                                                },
                                                on: {
                                                  input: function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      q,
                                                      "terminal" + ti + "Qty",
                                                      $event.target.value
                                                    )
                                                  }
                                                }
                                              })
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("li", {}, [
                                      _c("label", [_vm._v("Purchase")]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "field conditional" },
                                        [
                                          _c("checkbox-plain2", {
                                            attrs: {
                                              disabled: !_vm.equi(ti).purchase
                                            },
                                            on: {
                                              input: function($event) {
                                                _vm.$set(
                                                  q,
                                                  "terminal" + ti + "RentalOpt",
                                                  false
                                                )
                                                _vm.$set(
                                                  q,
                                                  "terminal" +
                                                    ti +
                                                    "LeasingOpt",
                                                  false
                                                )
                                                _vm.$set(
                                                  q,
                                                  "terminal" +
                                                    ti +
                                                    "ReprogramOpt",
                                                  false
                                                )
                                              }
                                            },
                                            model: {
                                              value:
                                                q[
                                                  "terminal" +
                                                    ti +
                                                    "PurchaseOpt"
                                                ],
                                              callback: function($$v) {
                                                _vm.$set(
                                                  q,
                                                  "terminal" +
                                                    ti +
                                                    "PurchaseOpt",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "q['terminal'+ti+'PurchaseOpt']"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("li", {}, [
                                      _c("label", [_vm._v("Rental")]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "field conditional" },
                                        [
                                          _c("checkbox-plain2", {
                                            attrs: {
                                              disabled: !_vm.equi(ti).rental
                                            },
                                            on: {
                                              input: function($event) {
                                                _vm.$set(
                                                  q,
                                                  "terminal" +
                                                    ti +
                                                    "PurchaseOpt",
                                                  false
                                                )
                                                _vm.$set(
                                                  q,
                                                  "terminal" +
                                                    ti +
                                                    "LeasingOpt",
                                                  false
                                                )
                                                _vm.$set(
                                                  q,
                                                  "terminal" +
                                                    ti +
                                                    "ReprogramOpt",
                                                  false
                                                )
                                              }
                                            },
                                            model: {
                                              value:
                                                q[
                                                  "terminal" + ti + "RentalOpt"
                                                ],
                                              callback: function($$v) {
                                                _vm.$set(
                                                  q,
                                                  "terminal" + ti + "RentalOpt",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "q['terminal'+ti+'RentalOpt']"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("li", {}, [
                                      _c("label", [_vm._v("Reprogram")]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "field conditional" },
                                        [
                                          _c("checkbox-plain2", {
                                            on: {
                                              input: function($event) {
                                                _vm.$set(
                                                  q,
                                                  "terminal" +
                                                    ti +
                                                    "PurchaseOpt",
                                                  false
                                                )
                                                _vm.$set(
                                                  q,
                                                  "terminal" + ti + "RentalOpt",
                                                  false
                                                )
                                                _vm.$set(
                                                  q,
                                                  "terminal" +
                                                    ti +
                                                    "LeasingOpt",
                                                  false
                                                )
                                              }
                                            },
                                            model: {
                                              value:
                                                q[
                                                  "terminal" +
                                                    ti +
                                                    "ReprogramOpt"
                                                ],
                                              callback: function($$v) {
                                                _vm.$set(
                                                  q,
                                                  "terminal" +
                                                    ti +
                                                    "ReprogramOpt",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "q['terminal'+ti+'ReprogramOpt']"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("li", {}, [
                                      _c("label", [_vm._v("Leasing")]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "field conditional" },
                                        [
                                          _c("checkbox-plain2", {
                                            attrs: { disabled: "" },
                                            on: {
                                              input: function($event) {
                                                _vm.$set(
                                                  q,
                                                  "terminal" +
                                                    ti +
                                                    "PurchaseOpt",
                                                  false
                                                )
                                                _vm.$set(
                                                  q,
                                                  "terminal" + ti + "RentalOpt",
                                                  false
                                                )
                                                _vm.$set(
                                                  q,
                                                  "terminal" +
                                                    ti +
                                                    "ReprogramOpt",
                                                  false
                                                )
                                              }
                                            },
                                            model: {
                                              value:
                                                q[
                                                  "terminal" + ti + "LeasingOpt"
                                                ],
                                              callback: function($$v) {
                                                _vm.$set(
                                                  q,
                                                  "terminal" +
                                                    ti +
                                                    "LeasingOpt",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "q['terminal'+ti+'LeasingOpt']"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]),
                                    _vm._v(" "),
                                    false
                                      ? _c(
                                          "li",
                                          {
                                            staticStyle: {
                                              width: "calc(12% - 5px)"
                                            }
                                          },
                                          [
                                            _c("label", [
                                              _vm._v("Merchant Own")
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "field conditional"
                                              },
                                              [
                                                _c("checkbox-plain2", {
                                                  model: {
                                                    value:
                                                      q[
                                                        "terminal" +
                                                          ti +
                                                          "OwnOpt"
                                                      ],
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        q,
                                                        "terminal" +
                                                          ti +
                                                          "OwnOpt",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "q['terminal'+ti+'OwnOpt']"
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c("span", [_vm._v("Qty:")]),
                                                _vm._v(" "),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        q[
                                                          "terminal" +
                                                            ti +
                                                            "Own"
                                                        ],
                                                      expression:
                                                        "q['terminal'+ti+'Own']"
                                                    }
                                                  ],
                                                  attrs: {
                                                    type: "text",
                                                    disabled: !q[
                                                      "terminal" + ti + "OwnOpt"
                                                    ]
                                                  },
                                                  domProps: {
                                                    value:
                                                      q["terminal" + ti + "Own"]
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        q,
                                                        "terminal" + ti + "Own",
                                                        $event.target.value
                                                      )
                                                    }
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    false
                                      ? _c(
                                          "li",
                                          {
                                            staticStyle: {
                                              width: "calc(12% - 5px)"
                                            }
                                          },
                                          [
                                            _c("label", [_vm._v("Financing")]),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "field conditional"
                                              },
                                              [
                                                _c("checkbox-plain2", {
                                                  model: {
                                                    value:
                                                      q[
                                                        "terminal" +
                                                          ti +
                                                          "FinancingOpt"
                                                      ],
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        q,
                                                        "terminal" +
                                                          ti +
                                                          "FinancingOpt",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "q['terminal'+ti+'FinancingOpt']"
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c("span", [_vm._v("Qty:")]),
                                                _vm._v(" "),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        q[
                                                          "terminal" +
                                                            ti +
                                                            "Financing"
                                                        ],
                                                      expression:
                                                        "q['terminal'+ti+'Financing']"
                                                    }
                                                  ],
                                                  attrs: {
                                                    type: "text",
                                                    disabled: !q[
                                                      "terminal" +
                                                        ti +
                                                        "FinancingOpt"
                                                    ]
                                                  },
                                                  domProps: {
                                                    value:
                                                      q[
                                                        "terminal" +
                                                          ti +
                                                          "Financing"
                                                      ]
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        q,
                                                        "terminal" +
                                                          ti +
                                                          "Financing",
                                                        $event.target.value
                                                      )
                                                    }
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    false
                                      ? _c(
                                          "li",
                                          {
                                            staticStyle: {
                                              width: "calc(12% - 5px)"
                                            }
                                          },
                                          [
                                            _c("label", [_vm._v("Insurance")]),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "field conditional"
                                              },
                                              [
                                                _c("checkbox-plain2", {
                                                  model: {
                                                    value:
                                                      q[
                                                        "terminal" +
                                                          ti +
                                                          "InsuranceOpt"
                                                      ],
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        q,
                                                        "terminal" +
                                                          ti +
                                                          "InsuranceOpt",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "q['terminal'+ti+'InsuranceOpt']"
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c("span", [_vm._v("Qty:")]),
                                                _vm._v(" "),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        q[
                                                          "terminal" +
                                                            ti +
                                                            "Insurance"
                                                        ],
                                                      expression:
                                                        "q['terminal'+ti+'Insurance']"
                                                    }
                                                  ],
                                                  attrs: {
                                                    type: "text",
                                                    disabled: !q[
                                                      "terminal" +
                                                        ti +
                                                        "InsuranceOpt"
                                                    ]
                                                  },
                                                  domProps: {
                                                    value:
                                                      q[
                                                        "terminal" +
                                                          ti +
                                                          "Insurance"
                                                      ]
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        q,
                                                        "terminal" +
                                                          ti +
                                                          "Insurance",
                                                        $event.target.value
                                                      )
                                                    }
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                }),
                                _vm._v(" "),
                                false
                                  ? _c(
                                      "li",
                                      { staticStyle: { width: "100%" } },
                                      [
                                        _vm.terminalCount > 1
                                          ? _c(
                                              "a",
                                              {
                                                staticClass: "terminals-del",
                                                attrs: { href: "" },
                                                on: {
                                                  click: function($event) {
                                                    $event.preventDefault()
                                                    _vm.terminalCount--
                                                  }
                                                }
                                              },
                                              [_vm._v("‒")]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "a",
                                          {
                                            staticClass: "terminals-add",
                                            attrs: { href: "" },
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                _vm.terminalCount++
                                              }
                                            }
                                          },
                                          [_vm._v("+")]
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("li", { staticClass: "fwidth fwx" }, [
                                  _c("label", [_vm._v("Communication")]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "field" },
                                    [
                                      _c(
                                        "validator",
                                        {
                                          attrs: {
                                            value:
                                              q.communicationMethod &&
                                              q.communicationMethod.length,
                                            reqs: ""
                                          }
                                        },
                                        [
                                          _c("multiselect", {
                                            attrs: {
                                              options: [
                                                "IP",
                                                "Wireless",
                                                "SIM Card Required",
                                                "Batch Auto Close Time!time"
                                              ],
                                              time: q.communicationTime
                                            },
                                            on: {
                                              "update:time": function($event) {
                                                return _vm.$set(
                                                  q,
                                                  "communicationTime",
                                                  $event
                                                )
                                              }
                                            },
                                            model: {
                                              value: q.communicationMethod,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  q,
                                                  "communicationMethod",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "q.communicationMethod"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]),
                                _vm._v(" "),
                                _c(
                                  "h2",
                                  { staticStyle: { "font-size": "1.2em" } },
                                  [_vm._v("Shipping")]
                                ),
                                _vm._v(" "),
                                _c("li", { staticClass: "fwidth fwx" }, [
                                  _c("label", [_vm._v("Ship to")]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "field" },
                                    [
                                      _c(
                                        "validator",
                                        {
                                          attrs: {
                                            value:
                                              q.shipTo && q.shipTo.length
                                                ? q.shipTo
                                                : q.shipToOther,
                                            reqs: ""
                                          }
                                        },
                                        [
                                          _c("multiselect", {
                                            attrs: {
                                              other: q.shipToOther,
                                              options: [
                                                "Company",
                                                "Location",
                                                "Other"
                                              ],
                                              onlyone: true
                                            },
                                            on: {
                                              "update:other": function($event) {
                                                return _vm.$set(
                                                  q,
                                                  "shipToOther",
                                                  $event
                                                )
                                              }
                                            },
                                            model: {
                                              value: q.shipTo,
                                              callback: function($$v) {
                                                _vm.$set(q, "shipTo", $$v)
                                              },
                                              expression: "q.shipTo"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]),
                                _vm._v(" "),
                                _c("li", { staticClass: "fwidth fwx" }, [
                                  _c("label", [_vm._v("Shipping Method")]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "field" },
                                    [
                                      _c(
                                        "validator",
                                        {
                                          attrs: {
                                            value:
                                              q.shippingMethod &&
                                              q.shippingMethod.length,
                                            reqs: ""
                                          }
                                        },
                                        [
                                          _c("multiselect", {
                                            attrs: {
                                              options: [
                                                "Regular",
                                                "2 Day",
                                                "Next Day",
                                                "Overnight"
                                              ],
                                              onlyone: true
                                            },
                                            model: {
                                              value: q.shippingMethod,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  q,
                                                  "shippingMethod",
                                                  $$v
                                                )
                                              },
                                              expression: "q.shippingMethod"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]),
                                _vm._v(" "),
                                _c("li", {}, [
                                  _c("label", [
                                    _vm._v("Ship to Other Address")
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "field complex" },
                                    [
                                      _c(
                                        "validator",
                                        {
                                          attrs: {
                                            value: q.shipOtherAddress,
                                            reqs:
                                              q.shipTo &&
                                              q.shipTo.indexOf("Other") != -1
                                                ? "required"
                                                : ""
                                          }
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: q.shipOtherAddress,
                                                expression: "q.shipOtherAddress"
                                              }
                                            ],
                                            attrs: {
                                              type: "text",
                                              disabled:
                                                !q.shipTo ||
                                                q.shipTo.indexOf("Other") == -1
                                            },
                                            domProps: {
                                              value: q.shipOtherAddress
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  q,
                                                  "shipOtherAddress",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]),
                                _vm._v(" "),
                                _c("li", {}, [
                                  _c("label", [_vm._v("City")]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "field complex" },
                                    [
                                      _c(
                                        "validator",
                                        {
                                          attrs: {
                                            value: q.shipOtherCity,
                                            reqs:
                                              q.shipTo &&
                                              q.shipTo.indexOf("Other") != -1
                                                ? "required"
                                                : ""
                                          }
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: q.shipOtherCity,
                                                expression: "q.shipOtherCity"
                                              }
                                            ],
                                            attrs: {
                                              type: "text",
                                              disabled:
                                                !q.shipTo ||
                                                q.shipTo.indexOf("Other") == -1
                                            },
                                            domProps: {
                                              value: q.shipOtherCity
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  q,
                                                  "shipOtherCity",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]),
                                _vm._v(" "),
                                _c("li", {}, [
                                  _c("label", [_vm._v("Province/State")]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "field complex" },
                                    [
                                      _c(
                                        "validator",
                                        {
                                          attrs: {
                                            value: q.shipOtherState,
                                            reqs:
                                              q.shipTo &&
                                              q.shipTo.indexOf("Other") != -1
                                                ? "required"
                                                : ""
                                          }
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: q.shipOtherState,
                                                expression: "q.shipOtherState"
                                              }
                                            ],
                                            attrs: {
                                              type: "text",
                                              disabled:
                                                !q.shipTo ||
                                                q.shipTo.indexOf("Other") == -1
                                            },
                                            domProps: {
                                              value: q.shipOtherState
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  q,
                                                  "shipOtherState",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]),
                                _vm._v(" "),
                                _c("li", {}, [
                                  _c("label", [_vm._v("Postal Code/Zip")]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "field complex" },
                                    [
                                      _c(
                                        "validator",
                                        {
                                          attrs: {
                                            value: q.shipOtherPostal,
                                            reqs:
                                              q.shipTo &&
                                              q.shipTo.indexOf("Other") != -1
                                                ? "required"
                                                : ""
                                          }
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: q.shipOtherPostal,
                                                expression: "q.shipOtherPostal"
                                              }
                                            ],
                                            attrs: {
                                              type: "text",
                                              disabled:
                                                !q.shipTo ||
                                                q.shipTo.indexOf("Other") == -1
                                            },
                                            domProps: {
                                              value: q.shipOtherPostal
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  q,
                                                  "shipOtherPostal",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]),
                                _vm._v(" "),
                                _c("li", { staticStyle: { width: "100%" } }, [
                                  _c("label", [_vm._v("Instructions")]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "field complex" },
                                    [
                                      _c(
                                        "validator",
                                        {
                                          attrs: {
                                            value: q.shipOtherInstructions,
                                            reqs: ""
                                          }
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: q.shipOtherInstructions,
                                                expression:
                                                  "q.shipOtherInstructions"
                                              }
                                            ],
                                            attrs: { type: "text" },
                                            domProps: {
                                              value: q.shipOtherInstructions
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  q,
                                                  "shipOtherInstructions",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ])
                              ],
                              2
                            )
                          ])
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "buttons" }, [
                          _c(
                            "a",
                            {
                              staticClass: "btn btn-large btn-plain btn-back",
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.prevStep.apply(null, arguments)
                                }
                              }
                            },
                            [_vm._v("Back")]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "btn btn-large btn-success btn-next",
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.nextStep.apply(null, arguments)
                                }
                              }
                            },
                            [_vm._v("Next")]
                          )
                        ])
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.step == 5
                  ? _c("form", { key: "step6", staticClass: "form-step" }, [
                      _c("section", [
                        _c("h2", [_vm._v("Access Information")]),
                        _vm._v(" "),
                        _c(
                          "ul",
                          { staticClass: "column-form column-form-compact" },
                          [
                            _c("li", [
                              _c("label", [_vm._v("Portal")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field" },
                                [
                                  _c(
                                    "validator",
                                    { attrs: { value: 0, reqs: "" } },
                                    [
                                      _c("dyn-select", {
                                        attrs: { options: _vm.portals },
                                        model: {
                                          value: _vm.item.setupData.portal,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.item.setupData,
                                              "portal",
                                              $$v
                                            )
                                          },
                                          expression: "item.setupData.portal"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _c("label", [_vm._v("Username")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field" },
                                [
                                  _c(
                                    "validator",
                                    { attrs: { value: 0, reqs: "" } },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.item.setupData.username,
                                            expression:
                                              "item.setupData.username"
                                          }
                                        ],
                                        attrs: { type: "text" },
                                        domProps: {
                                          value: _vm.item.setupData.username
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.item.setupData,
                                              "username",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _c("label", [_vm._v("Send welcome email")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field" },
                                [
                                  _c(
                                    "validator",
                                    {
                                      attrs: {
                                        value: _vm.item.setupData.sendEmail,
                                        reqs: "required"
                                      }
                                    },
                                    [
                                      _c("dyn-select", {
                                        attrs: { options: _vm.toggleYesNo },
                                        model: {
                                          value: _vm.item.setupData.sendEmail,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.item.setupData,
                                              "sendEmail",
                                              $$v
                                            )
                                          },
                                          expression: "item.setupData.sendEmail"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _c("label", [_vm._v("PIN")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field" },
                                [
                                  _c(
                                    "validator",
                                    {
                                      attrs: {
                                        value: _vm.item.setupData.pin,
                                        reqs: "required"
                                      }
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.item.setupData.pin,
                                            expression: "item.setupData.pin"
                                          }
                                        ],
                                        attrs: { type: "text", readonly: "" },
                                        domProps: {
                                          value: _vm.item.setupData.pin
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.item.setupData,
                                              "pin",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "button",
                                        {
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              return _vm.generatePin.apply(
                                                null,
                                                arguments
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("Generate PIN")]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _vm.internalNotes.agentAgrBanking
                          ? _c("div", { staticClass: "notes important" }, [
                              _vm._v(
                                "Note: " +
                                  _vm._s(_vm.internalNotes.agentAgrBanking) +
                                  " "
                              )
                            ])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "buttons" }, [
                        _c(
                          "a",
                          {
                            staticClass: "btn btn-large btn-plain btn-back",
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.prevStep.apply(null, arguments)
                              }
                            }
                          },
                          [_vm._v("Back")]
                        ),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "btn btn-large btn-success btn-next",
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.nextStep.apply(null, arguments)
                              }
                            }
                          },
                          [_vm._v("Next")]
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.step == 6
                  ? _c("form", { key: "step7", staticClass: "form-step" }, [
                      _c("section", [
                        _c("h2", [_vm._v("Advanced Information")]),
                        _vm._v(" "),
                        _c(
                          "ul",
                          { staticClass: "column-form column-form-compact" },
                          [
                            _c("li", [
                              _c("label", [_vm._v("Plugg & Co. Registration")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field" },
                                [
                                  _c(
                                    "validator",
                                    {
                                      attrs: {
                                        value: _vm.item.setupData.pluggReg,
                                        reqs: "required"
                                      }
                                    },
                                    [
                                      _c("dyn-select", {
                                        attrs: { options: _vm.toggleYesNo },
                                        model: {
                                          value: _vm.item.setupData.pluggReg,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.item.setupData,
                                              "pluggReg",
                                              $$v
                                            )
                                          },
                                          expression: "item.setupData.pluggReg"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _c("label", [_vm._v("Alerts Enroll")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field" },
                                [
                                  _c(
                                    "validator",
                                    {
                                      attrs: {
                                        value: _vm.item.setupData.alerts,
                                        reqs: "required"
                                      }
                                    },
                                    [
                                      _c("dyn-select", {
                                        attrs: { options: _vm.toggleYesNo },
                                        model: {
                                          value: _vm.item.setupData.alerts,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.item.setupData,
                                              "alerts",
                                              $$v
                                            )
                                          },
                                          expression: "item.setupData.alerts"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _c("label", [_vm._v("OnSite Service Requested")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field" },
                                [
                                  _c(
                                    "validator",
                                    {
                                      attrs: {
                                        value: _vm.item.setupData.onsite,
                                        reqs: "required"
                                      }
                                    },
                                    [
                                      _c("dyn-select", {
                                        attrs: { options: _vm.toggleYesNo },
                                        model: {
                                          value: _vm.item.setupData.onsite,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.item.setupData,
                                              "onsite",
                                              $$v
                                            )
                                          },
                                          expression: "item.setupData.onsite"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _c("label", [_vm._v("G2 Monitoring")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field" },
                                [
                                  _c(
                                    "validator",
                                    {
                                      attrs: {
                                        value: _vm.item.setupData.g2mon,
                                        reqs: "required"
                                      }
                                    },
                                    [
                                      _c("dyn-select", {
                                        attrs: { options: _vm.toggleYesNo },
                                        model: {
                                          value: _vm.item.setupData.g2mon,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.item.setupData,
                                              "g2mon",
                                              $$v
                                            )
                                          },
                                          expression: "item.setupData.g2mon"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ])
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "buttons" }, [
                        _c(
                          "a",
                          {
                            staticClass: "btn btn-large btn-plain btn-back",
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.prevStep.apply(null, arguments)
                              }
                            }
                          },
                          [_vm._v("Back")]
                        ),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "btn btn-large btn-success btn-next",
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.nextStep.apply(null, arguments)
                              }
                            }
                          },
                          [_vm._v("Next")]
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.step == 7
                  ? _c("form", { key: "step8", staticClass: "form-step" }, [
                      _c("section", [
                        _c("h2", [_vm._v("New Merchant build")]),
                        _vm._v(" "),
                        _c("ul", { staticClass: "setup-steps" }, [
                          _vm.item.setupData.pluggReg == "1"
                            ? _c("li", [
                                _c("h5", [
                                  _vm._v("Plugg and Co. Registration")
                                ]),
                                _vm._v(" "),
                                _c("p", [
                                  _vm._v(
                                    "Result" +
                                      _vm._s(
                                        _vm.buildSteps.pluggReg
                                          ? ": " + _vm.pluggResult.Result
                                          : ": Pending"
                                      )
                                  )
                                ]),
                                _vm._v(" "),
                                _vm.pluggResult["Registration ID"]
                                  ? _c("p", [
                                      _vm._v(
                                        "Plugg ID: " +
                                          _vm._s(
                                            _vm.pluggResult["Registration ID"]
                                          )
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.buildSteps.pluggReg
                                  ? _c(
                                      "button",
                                      {
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.setBuildStep("pluggReg")
                                          }
                                        }
                                      },
                                      [_vm._v("Start")]
                                    )
                                  : _vm._e()
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.item.setupData.g2mon == "1"
                            ? _c("li", [
                                _c("h5", [_vm._v("G2 Monitoring")]),
                                _vm._v(" "),
                                _c("p", [
                                  _vm._v(
                                    "Result" +
                                      _vm._s(
                                        _vm.buildSteps.g2mon
                                          ? _vm.g2mdmResult.id
                                            ? ": Done"
                                            : _vm.item.app.website
                                            ? ": Fail"
                                            : ": Fail (no website specified)"
                                          : ": Pending"
                                      )
                                  )
                                ]),
                                _vm._v(" "),
                                _vm.g2mdmResult.id
                                  ? _c("p", [
                                      _vm._v(
                                        "Merchant ID: " +
                                          _vm._s(_vm.g2mdmResult.id)
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.g2mdmResult.attributes
                                  ? _c("p", [
                                      _vm._v(
                                        "Merchant Name: " +
                                          _vm._s(
                                            _vm.g2mdmResult.attributes
                                              .merchant_name
                                          )
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.g2mdmResult.attributes
                                  ? _c("p", [
                                      _vm._v(
                                        "Merchant URL: " +
                                          _vm._s(_vm.g2mdmResult.attributes.url)
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.buildSteps.g2mon
                                  ? _c(
                                      "button",
                                      {
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.setBuildStep("g2mon")
                                          }
                                        }
                                      },
                                      [_vm._v("Start")]
                                    )
                                  : _vm._e()
                              ])
                            : _vm._e()
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "buttons" }, [
                        _c(
                          "a",
                          {
                            staticClass: "btn btn-large btn-plain btn-back",
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.prevStep.apply(null, arguments)
                              }
                            }
                          },
                          [_vm._v("Back")]
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.step == 8
                  ? _c("form", { key: "step9", staticClass: "form-step" }, [
                      _c("section", [
                        _c("h2", [_vm._v("New Merchant build")]),
                        _vm._v(" "),
                        _c("ul", { staticClass: "setup-steps" }, [
                          _vm.item.setupData.pluggReg == "1"
                            ? _c("li", [
                                _c("h5", [
                                  _vm._v("Plugg and Co. Registration")
                                ]),
                                _vm._v(" "),
                                _c("p", [
                                  _vm._v(
                                    "Result" +
                                      _vm._s(
                                        _vm.buildSteps.pluggReg
                                          ? ": " + _vm.pluggResult.Result
                                          : ": Pending"
                                      )
                                  )
                                ]),
                                _vm._v(" "),
                                _vm.pluggResult["Registration ID"]
                                  ? _c("p", [
                                      _vm._v(
                                        "Plugg ID: " +
                                          _vm._s(
                                            _vm.pluggResult["Registration ID"]
                                          )
                                      )
                                    ])
                                  : _vm._e()
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.item.setupData.g2mon == "1"
                            ? _c("li", [
                                _c("h5", [_vm._v("G2 Monitoring")]),
                                _vm._v(" "),
                                _c("p", [
                                  _vm._v(
                                    "Result" +
                                      _vm._s(
                                        _vm.buildSteps.g2mon
                                          ? _vm.g2mdmResult.id
                                            ? ": Done"
                                            : _vm.item.app.website
                                            ? ": Fail"
                                            : ": Fail (no website specified)"
                                          : ": Pending"
                                      )
                                  )
                                ]),
                                _vm._v(" "),
                                _vm.g2mdmResult.id
                                  ? _c("p", [
                                      _vm._v(
                                        "Merchant ID: " +
                                          _vm._s(_vm.g2mdmResult.id)
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.g2mdmResult.attributes
                                  ? _c("p", [
                                      _vm._v(
                                        "Merchant Name: " +
                                          _vm._s(
                                            _vm.g2mdmResult.attributes
                                              .merchant_name
                                          )
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.g2mdmResult.attributes
                                  ? _c("p", [
                                      _vm._v(
                                        "Merchant URL: " +
                                          _vm._s(_vm.g2mdmResult.attributes.url)
                                      )
                                    ])
                                  : _vm._e()
                              ])
                            : _vm._e()
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "buttons" }, [
                        _c(
                          "a",
                          {
                            staticClass: "btn btn-large btn-success btn-next",
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.save.apply(null, arguments)
                              }
                            }
                          },
                          [_vm._v("Finalize")]
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                false && _vm.step == 7
                  ? _c("form", { key: "step8", staticClass: "form-step" }, [
                      _c("section", [
                        _c("h2", [_vm._v("Access Information")]),
                        _vm._v(" "),
                        _c(
                          "ul",
                          { staticClass: "column-form column-form-compact" },
                          [
                            _c("li", [
                              _c("label", [_vm._v("Additional Build Notes")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field" },
                                [
                                  _c(
                                    "validator",
                                    { attrs: { value: 0, reqs: "" } },
                                    [
                                      _c("textarea", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.item.notes,
                                            expression: "item.notes"
                                          }
                                        ],
                                        domProps: { value: _vm.item.notes },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.item,
                                              "notes",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            ])
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "buttons" }, [
                        _c(
                          "a",
                          {
                            staticClass: "btn btn-large btn-plain btn-back",
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.prevStep.apply(null, arguments)
                              }
                            }
                          },
                          [_vm._v("Back")]
                        ),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "btn btn-large btn-success btn-next",
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.nextStep.apply(null, arguments)
                              }
                            }
                          },
                          [_vm._v("Next")]
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                false && _vm.step == 8
                  ? _c("form", { key: "step9", staticClass: "form-step" }, [
                      _c("section", [
                        _c("h2", [_vm._v("Merchant Information")]),
                        _vm._v(" "),
                        _c("ul", { staticClass: "column-form readonly" }, [
                          _c("li", [
                            _c("label", { staticStyle: { border: "none" } }, [
                              _vm._v("Merchant ID:")
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field" },
                              [
                                _c(
                                  "validator",
                                  {
                                    staticStyle: { border: "none" },
                                    attrs: { value: 0, reqs: "" }
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: sdata.agentId,
                                          expression: "sdata.agentId"
                                        }
                                      ],
                                      attrs: { type: "text", readonly: "" },
                                      domProps: { value: sdata.agentId },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            sdata,
                                            "agentId",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("h2", [_vm._v("Access Information")]),
                        _vm._v(" "),
                        _c("ul", { staticClass: "column-form readonly" }, [
                          _c("li", [
                            _c("label", { staticStyle: { border: "none" } }, [
                              _vm._v("Platform:")
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field" },
                              [
                                _c(
                                  "validator",
                                  {
                                    staticStyle: {
                                      border: "none",
                                      background: "none"
                                    },
                                    attrs: { value: 0, reqs: "" }
                                  },
                                  [
                                    _c("input", {
                                      attrs: { type: "text", readonly: "" },
                                      domProps: { value: _vm.item.platform }
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c(
                              "label",
                              {
                                staticStyle: {
                                  border: "none",
                                  background: "none"
                                }
                              },
                              [_vm._v("Platform ID:")]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field" },
                              [
                                _c(
                                  "validator",
                                  {
                                    staticStyle: {
                                      border: "none",
                                      background: "none"
                                    },
                                    attrs: { value: 0, reqs: "" }
                                  },
                                  [
                                    _c("input", {
                                      attrs: { type: "text", readonly: "" },
                                      domProps: { value: "" }
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c(
                              "label",
                              {
                                staticStyle: {
                                  border: "none",
                                  background: "none"
                                }
                              },
                              [_vm._v("Portal:")]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field" },
                              [
                                _c(
                                  "validator",
                                  {
                                    staticStyle: {
                                      border: "none",
                                      background: "none"
                                    },
                                    attrs: { value: 0, reqs: "" }
                                  },
                                  [
                                    _c("input", {
                                      attrs: { type: "text", readonly: "" },
                                      domProps: { value: _vm.item.portal }
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c(
                              "label",
                              {
                                staticStyle: {
                                  border: "none",
                                  background: "none"
                                }
                              },
                              [_vm._v("Username:")]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field" },
                              [
                                _c(
                                  "validator",
                                  {
                                    staticStyle: {
                                      border: "none",
                                      background: "none"
                                    },
                                    attrs: { value: 0, reqs: "" }
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.item.username,
                                          expression: "item.username"
                                        }
                                      ],
                                      attrs: { type: "text" },
                                      domProps: { value: _vm.item.username },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.item,
                                            "username",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c(
                              "label",
                              {
                                staticStyle: {
                                  border: "none",
                                  background: "none"
                                }
                              },
                              [_vm._v("Portal Activation Code:")]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field" },
                              [
                                _c(
                                  "validator",
                                  {
                                    staticStyle: {
                                      border: "none",
                                      background: "none"
                                    },
                                    attrs: { value: 0, reqs: "" }
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.item.password,
                                          expression: "item.password"
                                        }
                                      ],
                                      attrs: { type: "text" },
                                      domProps: { value: _vm.item.password },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.item,
                                            "password",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c(
                              "label",
                              {
                                staticStyle: {
                                  border: "none",
                                  background: "none"
                                }
                              },
                              [_vm._v("Send welcome email:")]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field" },
                              [
                                _c(
                                  "validator",
                                  {
                                    staticStyle: {
                                      border: "none",
                                      background: "none"
                                    },
                                    attrs: {
                                      value: _vm.item.sendEmail,
                                      reqs: "required"
                                    }
                                  },
                                  [
                                    _c("input", {
                                      attrs: { type: "text", readonly: "" },
                                      domProps: {
                                        value:
                                          _vm.item.sendEmail == 1 ? "Yes" : "No"
                                      }
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("h2", [_vm._v("Advanced Information")]),
                        _vm._v(" "),
                        _c("ul", { staticClass: "column-form readonly" }, [
                          _c("li", [
                            _c("label", { staticStyle: { border: "none" } }, [
                              _vm._v("Plugg & Co. Registration:")
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field" },
                              [
                                _c(
                                  "validator",
                                  {
                                    staticStyle: { border: "none" },
                                    attrs: { value: 0, reqs: "" }
                                  },
                                  [
                                    _c("input", {
                                      attrs: { type: "text", readonly: "" },
                                      domProps: {
                                        value:
                                          _vm.item.pluggReg == 1 ? "Yes" : "No"
                                      }
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c(
                              "label",
                              {
                                staticStyle: {
                                  border: "none",
                                  background: "none"
                                }
                              },
                              [_vm._v("Plugg & Co. Username:")]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field" },
                              [
                                _c(
                                  "validator",
                                  {
                                    staticStyle: {
                                      border: "none",
                                      background: "none"
                                    },
                                    attrs: { value: 0, reqs: "" }
                                  },
                                  [
                                    _c("input", {
                                      attrs: { type: "text", readonly: "" },
                                      domProps: {
                                        value: _vm.item.pluggUsername
                                      }
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", { staticStyle: { border: "none" } }, [
                              _vm._v("Plugg & Co. Activation Code:")
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field" },
                              [
                                _c(
                                  "validator",
                                  {
                                    staticStyle: { border: "none" },
                                    attrs: { value: 0, reqs: "" }
                                  },
                                  [
                                    _c("input", {
                                      attrs: { type: "text", readonly: "" },
                                      domProps: { value: _vm.item.pluggActCode }
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c(
                              "label",
                              {
                                staticStyle: {
                                  border: "none",
                                  background: "none"
                                }
                              },
                              [_vm._v("Plugg & Co. Registration ID:")]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field" },
                              [
                                _c(
                                  "validator",
                                  {
                                    staticStyle: {
                                      border: "none",
                                      background: "none"
                                    },
                                    attrs: { value: 0, reqs: "" }
                                  },
                                  [
                                    _c("input", {
                                      attrs: { type: "text", readonly: "" },
                                      domProps: { value: _vm.item.pluggRegId }
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("h2", [_vm._v("Account Security Information")]),
                        _vm._v(" "),
                        _c("ul", { staticClass: "column-form readonly" }, [
                          _c("li", [
                            _c("label", { staticStyle: { border: "none" } }, [
                              _vm._v("PIN:")
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field" },
                              [
                                _c(
                                  "validator",
                                  {
                                    staticStyle: { border: "none" },
                                    attrs: {
                                      value: _vm.item.pin,
                                      reqs: "required"
                                    }
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.item.pin,
                                          expression: "item.pin"
                                        }
                                      ],
                                      attrs: { type: "text", readonly: "" },
                                      domProps: { value: _vm.item.pin },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.item,
                                            "pin",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("h2", [_vm._v("Additional Build Notes")]),
                        _vm._v(" "),
                        _c("ul", { staticClass: "column-form readonly" }, [
                          _c("li", [
                            _c("label", { staticStyle: { border: "none" } }, [
                              _vm._v("Build Notes:")
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field" },
                              [
                                _c(
                                  "validator",
                                  {
                                    staticStyle: { border: "none" },
                                    attrs: { value: 0, reqs: "" }
                                  },
                                  [
                                    _c("textarea", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.item.notes,
                                          expression: "item.notes"
                                        }
                                      ],
                                      staticStyle: { resize: "none" },
                                      attrs: { readonly: "" },
                                      domProps: { value: _vm.item.notes },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.item,
                                            "notes",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      !_vm.buildStarted
                        ? _c("div", { staticClass: "buttons" }, [
                            _c(
                              "a",
                              {
                                staticClass: "btn btn-large btn-success",
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.startBuild.apply(null, arguments)
                                  }
                                }
                              },
                              [_vm._v("Start Build")]
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.buildStarted
                        ? _c(
                            "div",
                            {
                              staticClass: "start-build",
                              class: {
                                visible: _vm.buildStarted && !_vm.buildFinalized
                              }
                            },
                            [
                              _c("div"),
                              _vm._v(" "),
                              _c("ul", [
                                _c(
                                  "li",
                                  { class: { visible: _vm.buildStep >= 1 } },
                                  [
                                    _c("label", [
                                      _vm._v(
                                        "Generating MerchantID... " +
                                          _vm._s(_vm.progress(1, 2))
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { class: { done: _vm.buildStep >= 2 } },
                                      [_vm._v("✔")]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "li",
                                  { class: { visible: _vm.buildStep >= 2 } },
                                  [
                                    _c("label", [
                                      _vm._v(
                                        "Portal... " +
                                          _vm._s(_vm.progress(2, 3))
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { class: { done: _vm.buildStep >= 3 } },
                                      [_vm._v("✔")]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "li",
                                  { class: { visible: _vm.buildStep >= 3 } },
                                  [
                                    _c("label", [
                                      _vm._v(
                                        "Username... " +
                                          _vm._s(_vm.progress(3, 4))
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { class: { done: _vm.buildStep >= 4 } },
                                      [_vm._v("✔")]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "li",
                                  { class: { visible: _vm.buildStep >= 4 } },
                                  [
                                    _c("label", [
                                      _vm._v(
                                        "Generating Activation Code.... " +
                                          _vm._s(_vm.progress(4, 5))
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { class: { done: _vm.buildStep >= 5 } },
                                      [_vm._v("✔")]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "li",
                                  { class: { visible: _vm.buildStep >= 5 } },
                                  [
                                    _c("label", [
                                      _vm._v(
                                        "Account Security PIN... " +
                                          _vm._s(_vm.progress(5, 6))
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { class: { done: _vm.buildStep >= 6 } },
                                      [_vm._v("✔")]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "li",
                                  { class: { visible: _vm.buildStep >= 6 } },
                                  [
                                    _c("label", [
                                      _vm._v(
                                        "Creating Plugg & Co registration... " +
                                          _vm._s(_vm.progress(6, 7))
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { class: { done: _vm.buildStep >= 7 } },
                                      [_vm._v("✔")]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "li",
                                  { class: { visible: _vm.buildStep >= 7 } },
                                  [
                                    _c("label", [
                                      _vm._v(
                                        "Sending data... " +
                                          _vm._s(_vm.progress(7, 9))
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { class: { done: _vm.buildStep >= 9 } },
                                      [_vm._v("✔")]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "li",
                                  { class: { visible: _vm.buildStep >= 9 } },
                                  [
                                    _c("label", [
                                      _c("strong", [
                                        _vm._v(
                                          "Receiving data (Registration ID, username, Activation Code)... " +
                                            _vm._s(_vm.progress(9, 11))
                                        )
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { class: { done: _vm.buildStep >= 11 } },
                                      [_vm._v("✔")]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "li",
                                  { class: { visible: _vm.buildStep >= 11 } },
                                  [
                                    _c("label", [
                                      _vm._v(
                                        "Note... " +
                                          _vm._s(_vm.progress(11, 12))
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { class: { done: _vm.buildStep >= 12 } },
                                      [_vm._v("✔")]
                                    )
                                  ]
                                )
                              ])
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "buttons" }, [
                        _c(
                          "a",
                          {
                            staticClass: "btn btn-large btn-plain btn-back",
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.prevStep.apply(null, arguments)
                              }
                            }
                          },
                          [_vm._v("Back")]
                        ),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "btn btn-large btn-success",
                            class: { "btn-disabled": !_vm.buildFinalized },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.save.apply(null, arguments)
                              }
                            }
                          },
                          [_vm._v("Finalize Build")]
                        )
                      ])
                    ])
                  : _vm._e()
              ]
            })
          ],
          2
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }