var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ph-prev" }, [
    _c("div", { staticClass: "ph-pr-in" }, [
      _vm.value && !_vm.changed
        ? _c("div", { staticClass: "org" }, [
            _c("img", { attrs: { src: _vm.basepath + "/" + _vm.value } })
          ])
        : _vm.value && _vm.changed
        ? _c("div", { staticClass: "chg" }, [
            _c("img", { attrs: { src: _vm.bloburl } })
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "file-obj" }, [
      !_vm.value
        ? _c("a", [_vm._v("(no file)")])
        : _vm.changed
        ? _c("a", [_vm._v(_vm._s(_vm.fileName))])
        : _c(
            "a",
            {
              attrs: { target: "_blank", href: _vm.basepath + "/" + _vm.value }
            },
            [_vm._v(_vm._s(_vm.fileName))]
          ),
      _vm._v(" "),
      _c("a", { staticClass: "btn-upload" }, [
        _vm._v("\n      +\n      "),
        _c("input", { attrs: { type: "file" }, on: { change: _vm.setFile } })
      ]),
      _vm._v(" "),
      _vm.value
        ? _c(
            "a",
            { staticClass: "btn-remove", on: { click: _vm.removeFile } },
            [_vm._v("×")]
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }