var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "content-table-container file-list-container" }, [
      _c("table", { staticClass: "file-list content-table" }, [
        _c("tbody", [
          _c("tr", [
            _c("td", [_vm._v("Website Check")]),
            _vm._v(" "),
            _c("td", { staticClass: "partner-boarding-doc-actions" }, [
              _c(
                "button",
                {
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.startWebsite.apply(null, arguments)
                    }
                  }
                },
                [_vm._v("Start")]
              )
            ]),
            _vm._v(" "),
            _vm.websiteStatus
              ? _c("td", [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.websiteStatus) +
                      "\n          "
                  )
                ])
              : _c("td", [_vm._v("Pending")])
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("td", [_vm._v("KYC Boarding Report")]),
            _vm._v(" "),
            _c("td", { staticClass: "partner-boarding-doc-actions" }, [
              _c(
                "button",
                {
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.startG2.apply(null, arguments)
                    }
                  }
                },
                [_vm._v("Start")]
              )
            ]),
            _vm._v(" "),
            _vm.g2Status
              ? _c("td", [
                  _vm._v(
                    "\n            " + _vm._s(_vm.g2Status) + "\n          "
                  )
                ])
              : _c("td", [_vm._v("Pending")])
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("td", [_vm._v("Boarding Scans")]),
            _vm._v(" "),
            _c("td", { staticClass: "partner-boarding-doc-actions" }, [
              _c(
                "button",
                {
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.startAcuant.apply(null, arguments)
                    }
                  }
                },
                [_vm._v("Start")]
              )
            ]),
            _vm._v(" "),
            _vm.acuantStatus
              ? _c("td", [
                  _vm._v(
                    "\n            " + _vm._s(_vm.acuantStatus) + "\n          "
                  )
                ])
              : _c("td", [_vm._v("Pending")])
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("td", [_vm._v("Risk Exposure Calculator")]),
            _vm._v(" "),
            _c("td", { staticClass: "partner-boarding-doc-actions" }, [
              _c(
                "button",
                {
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.startRisk.apply(null, arguments)
                    }
                  }
                },
                [_vm._v("Start")]
              )
            ]),
            _vm._v(" "),
            _vm.riskStatus
              ? _c("td", [
                  _vm._v(
                    "\n            " + _vm._s(_vm.riskStatus) + "\n          "
                  )
                ])
              : _c("td", [_vm._v("Pending")])
          ]),
          _vm._v(" "),
          _vm.boardingMessage
            ? _c("tr", [
                _c("td", { attrs: { colspan: "3" } }, [
                  _vm._v("\n            Message:"),
                  _c("br"),
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.boardingMessage) +
                      "\n          "
                  )
                ])
              ])
            : _vm._e()
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "actions" }, [
      _c(
        "button",
        {
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.goBack.apply(null, arguments)
            }
          }
        },
        [_vm._v("Back")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.doNext.apply(null, arguments)
            }
          }
        },
        [_vm._v("Next")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }