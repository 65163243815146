var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.languages, function(item) {
        return _c("div", { staticClass: "provider-form pad20" }, [
          _c("div", { staticClass: "input-group" }, [
            _c("label", [_vm._v("Name")]),
            _vm._v("\n          " + _vm._s(item.name) + "\n      ")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "input-group" }, [
            _c("label", [_vm._v("Short Name")]),
            _vm._v("\n          " + _vm._s(item.shortName) + "\n      ")
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "input-group" },
            [
              _c("label", [_vm._v("Enabled")]),
              _vm._v(" "),
              _c("mnb-switch", {
                attrs: { disabled: true },
                model: {
                  value: item.enabled,
                  callback: function($$v) {
                    _vm.$set(item, "enabled", $$v)
                  },
                  expression: "item.enabled"
                }
              })
            ],
            1
          )
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }