var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.customer
    ? _c(
        "view-profile",
        {
          attrs: { header: ["Customer Detail"] },
          scopedSlots: _vm._u(
            [
              {
                key: "tabsheader",
                fn: function() {
                  return [
                    _vm.customer.emailConfirmation
                      ? _c("p", [_vm._v("Email confirmed")])
                      : _c("p", [
                          _vm._v("Email is not confirmed. Boarding is disabled")
                        ])
                  ]
                },
                proxy: true
              },
              {
                key: "tabsmain",
                fn: function() {
                  return [
                    _c(
                      "ul",
                      [
                        _vm._l(_vm.tabs, function(tabData, tabId) {
                          return [
                            tabData.disabled
                              ? [
                                  _vm.customer.emailConfirmation
                                    ? [
                                        _c(
                                          "li",
                                          {
                                            class: { active: _vm.tab == tabId }
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: { href: "" },
                                                on: {
                                                  click: function($event) {
                                                    $event.preventDefault()
                                                    _vm.tab = tabId
                                                  }
                                                }
                                              },
                                              [_vm._v(_vm._s(tabData.label))]
                                            )
                                          ]
                                        )
                                      ]
                                    : [
                                        _c("li", { staticClass: "disabled" }, [
                                          _c(
                                            "a",
                                            {
                                              attrs: { href: "" },
                                              on: {
                                                click: function($event) {
                                                  $event.preventDefault()
                                                }
                                              }
                                            },
                                            [_vm._v(_vm._s(tabData.label))]
                                          )
                                        ])
                                      ]
                                ]
                              : [
                                  _c(
                                    "li",
                                    { class: { active: _vm.tab == tabId } },
                                    [
                                      _c(
                                        "a",
                                        {
                                          attrs: { href: "" },
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              _vm.tab = tabId
                                            }
                                          }
                                        },
                                        [_vm._v(_vm._s(tabData.label))]
                                      )
                                    ]
                                  )
                                ]
                          ]
                        })
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _vm.customer
                      ? _c(
                          "div",
                          { staticClass: "status" },
                          [
                            _vm.customer.accountType == "Personal"
                              ? [
                                  _c("label", [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.customer.personal.profileNumber
                                        )
                                      )
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _c("label", [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.customer.personal.firstName
                                        ) +
                                          " " +
                                          _vm._s(_vm.customer.personal.lastName)
                                      )
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _c("label", [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(_vm.customer.personal.status) +
                                          " - " +
                                          _vm._s(_vm.statusDescription)
                                      )
                                    ])
                                  ])
                                ]
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.customer.accountType == "Business"
                              ? [
                                  _c("label", [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.customer.business.profileNumber
                                        )
                                      )
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _c("label", [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.customer.business.companyName
                                        )
                                      )
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _c("label", [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(_vm.customer.business.status) +
                                          " - " +
                                          _vm._s(_vm.statusDescription)
                                      )
                                    ])
                                  ])
                                ]
                              : _vm._e()
                          ],
                          2
                        )
                      : _vm._e()
                  ]
                },
                proxy: true
              },
              _vm.customer
                ? _vm._l([_vm.sTabs[_vm.tab]], function(subTabs) {
                    return {
                      key: "tabssub",
                      fn: function() {
                        return [
                          _vm.customer.accountType == "Personal"
                            ? _c(
                                "ul",
                                _vm._l(subTabs, function(tabData, tabId) {
                                  return tabId != "company"
                                    ? _c(
                                        "li",
                                        {
                                          class: { active: _vm.subTab == tabId }
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              attrs: { href: "" },
                                              on: {
                                                click: function($event) {
                                                  $event.preventDefault()
                                                  ;(_vm.subTab = tabId),
                                                    (_vm.editMode = false)
                                                }
                                              }
                                            },
                                            [_vm._v(_vm._s(tabData.label))]
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                }),
                                0
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.customer.accountType == "Business"
                            ? _c(
                                "ul",
                                _vm._l(subTabs, function(tabData, tabId) {
                                  return _c(
                                    "li",
                                    { class: { active: _vm.subTab == tabId } },
                                    [
                                      _c(
                                        "a",
                                        {
                                          attrs: { href: "" },
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              ;(_vm.subTab = tabId),
                                                (_vm.editMode = false)
                                            }
                                          }
                                        },
                                        [_vm._v(_vm._s(tabData.label))]
                                      )
                                    ]
                                  )
                                }),
                                0
                              )
                            : _vm._e()
                        ]
                      },
                      proxy: true
                    }
                  })
                : null,
              {
                key: "actions",
                fn: function() {
                  return [_c("PortalTarget", { attrs: { name: "actions" } })]
                },
                proxy: true
              }
            ],
            null,
            true
          )
        },
        [
          _vm._v(" "),
          _vm._v(" "),
          _vm._v(" "),
          _vm._v(" "),
          _c("div", { staticClass: "merchant-panel" }, [
            _vm.customer
              ? _c(
                  "div",
                  { staticClass: "data-content" },
                  [
                    _vm.subTab === "notes"
                      ? _c("treasury-notes", {
                          ref: "notes",
                          attrs: {
                            notes: _vm.notes,
                            type: "Customer",
                            id: _vm.id
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.formTabs[_vm.subTab] !== undefined
                      ? _c("formview-treasury-main", {
                          attrs: {
                            customer: _vm.customer,
                            tab: _vm.formTabs[_vm.subTab],
                            notes: _vm.notes,
                            editMode: _vm.editMode,
                            edit: _vm.sTabs[_vm.tab][_vm.subTab].edit
                          },
                          on: {
                            "update:editMode": function($event) {
                              _vm.editMode = $event
                            },
                            "update:edit-mode": function($event) {
                              _vm.editMode = $event
                            }
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.subTab == "documents"
                      ? _c("treasury-documents", {
                          ref: "documents",
                          attrs: { item: _vm.customer, merchant: _vm.customer },
                          on: { reloadnotes: _vm.loadNotes }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.subTab == "emails"
                      ? _c("treasury-emails", {
                          ref: "emails",
                          attrs: { item: _vm.customer }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.subTab == "scans"
                      ? _c("treasury-scans", {
                          ref: "scans",
                          attrs: { item: _vm.customer }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.subTab == "boarding"
                      ? _c("treasury-boarding", {
                          attrs: { item: _vm.customer },
                          on: { reloadnotes: _vm.loadNotes }
                        })
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e()
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }