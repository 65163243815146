var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "multiselect wd" },
    [
      _vm._l(_vm.options || [], function(option, oi) {
        return _c("li", [
          _c(
            "label",
            { class: { checked: _vm.actualValue.includes(option) } },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.actualValue,
                    expression: "actualValue"
                  }
                ],
                attrs: { disabled: _vm.readonly, type: "checkbox" },
                domProps: {
                  value: option,
                  checked: Array.isArray(_vm.actualValue)
                    ? _vm._i(_vm.actualValue, option) > -1
                    : _vm.actualValue
                },
                on: {
                  change: [
                    function($event) {
                      var $$a = _vm.actualValue,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = option,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.actualValue = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.actualValue = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.actualValue = $$c
                      }
                    },
                    _vm.update
                  ]
                }
              }),
              _vm._v(" "),
              _c("span"),
              _vm._v(" "),
              _c("i", {
                staticClass: "bn-con",
                domProps: {
                  innerHTML: _vm._s((_vm.labels || [])[oi] || option)
                }
              })
            ]
          )
        ])
      }),
      _vm._v(" "),
      _vm.hasOther
        ? _c(
            "li",
            { staticClass: "other" },
            [
              _c("label", { class: { checked: _vm.isOther } }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.isOther,
                      expression: "isOther"
                    }
                  ],
                  attrs: {
                    disabled: _vm.readonly,
                    type: "checkbox",
                    value: "Other"
                  },
                  domProps: {
                    checked: Array.isArray(_vm.isOther)
                      ? _vm._i(_vm.isOther, "Other") > -1
                      : _vm.isOther
                  },
                  on: {
                    change: [
                      function($event) {
                        var $$a = _vm.isOther,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = "Other",
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.isOther = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.isOther = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.isOther = $$c
                        }
                      },
                      _vm.updateOther
                    ]
                  }
                }),
                _c("span"),
                _vm._v("\n      Other\n    ")
              ]),
              _vm._v(" "),
              _c(
                "validator",
                {
                  attrs: {
                    value: _vm.other,
                    reqs: _vm.isOther ? "required" : ""
                  }
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.actualOther,
                        expression: "actualOther"
                      }
                    ],
                    attrs: { disabled: !_vm.isOther },
                    domProps: { value: _vm.actualOther },
                    on: {
                      input: [
                        function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.actualOther = $event.target.value
                        },
                        _vm.updateOther
                      ]
                    }
                  })
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "desc" }, [_vm._t("default")], 2)
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }