var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pricing-template" }, [
    _c("h2", [_vm._v("MONTHLY PLAN")]),
    _vm._v(" "),
    _c("ul", { staticClass: "pricing-list" }, [
      _c(
        "li",
        [
          _vm._m(0),
          _vm._v(" "),
          _c("multiselect-dropdown", {
            attrs: {
              options: _vm.methodOptions.allMethods.map(function(a) {
                return { label: a, value: a }
              }),
              isMulti: true,
              isExclusive: true
            },
            model: {
              value: _vm.methodOptions.enabledMethods,
              callback: function($$v) {
                _vm.$set(_vm.methodOptions, "enabledMethods", $$v)
              },
              expression: "methodOptions.enabledMethods"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.methodOptions.enabledMethods.find(function(a) {
        return _vm.methodOptions.allMethods.find(function(b) {
          return a == b
        })
      })
        ? _c("li", [
            _vm._m(1),
            _vm._v(" "),
            _c("ul", [
              _c(
                "li",
                [
                  _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
                  _vm._v(" "),
                  _c("number-edit", {
                    attrs: { step: "0.01" },
                    model: {
                      value: _vm.currentTemplate.liteAccessMonthly,
                      callback: function($$v) {
                        _vm.$set(_vm.currentTemplate, "liteAccessMonthly", $$v)
                      },
                      expression: "currentTemplate.liteAccessMonthly"
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            false
              ? _c("ul", [
                  _vm.hasM("Monthly Simple")
                    ? _c(
                        "li",
                        [
                          _c("label", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.currentTemplate.liteAccessMonthlyToggle,
                                  expression:
                                    "currentTemplate.liteAccessMonthlyToggle"
                                }
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.currentTemplate.liteAccessMonthlyToggle
                                )
                                  ? _vm._i(
                                      _vm.currentTemplate
                                        .liteAccessMonthlyToggle,
                                      null
                                    ) > -1
                                  : _vm.currentTemplate.liteAccessMonthlyToggle
                              },
                              on: {
                                input: function($event) {
                                  _vm.currentTemplate.fullAccessMonthlyToggle = false
                                },
                                change: function($event) {
                                  var $$a =
                                      _vm.currentTemplate
                                        .liteAccessMonthlyToggle,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.currentTemplate,
                                          "liteAccessMonthlyToggle",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.currentTemplate,
                                          "liteAccessMonthlyToggle",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.currentTemplate,
                                      "liteAccessMonthlyToggle",
                                      $$c
                                    )
                                  }
                                }
                              }
                            }),
                            _c("em"),
                            _vm._v("Simple")
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
                          _vm._v(" "),
                          _c("number-edit", {
                            attrs: {
                              step: "0.01",
                              disabled: !_vm.currentTemplate
                                .liteAccessMonthlyToggle
                            },
                            model: {
                              value: _vm.currentTemplate.liteAccessMonthly,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.currentTemplate,
                                  "liteAccessMonthly",
                                  $$v
                                )
                              },
                              expression: "currentTemplate.liteAccessMonthly"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasM("Monthly Prime")
                    ? _c(
                        "li",
                        [
                          _c("label", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.currentTemplate.fullAccessMonthlyToggle,
                                  expression:
                                    "currentTemplate.fullAccessMonthlyToggle"
                                }
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.currentTemplate.fullAccessMonthlyToggle
                                )
                                  ? _vm._i(
                                      _vm.currentTemplate
                                        .fullAccessMonthlyToggle,
                                      null
                                    ) > -1
                                  : _vm.currentTemplate.fullAccessMonthlyToggle
                              },
                              on: {
                                input: function($event) {
                                  _vm.currentTemplate.liteAccessMonthlyToggle = false
                                },
                                change: function($event) {
                                  var $$a =
                                      _vm.currentTemplate
                                        .fullAccessMonthlyToggle,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.currentTemplate,
                                          "fullAccessMonthlyToggle",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.currentTemplate,
                                          "fullAccessMonthlyToggle",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.currentTemplate,
                                      "fullAccessMonthlyToggle",
                                      $$c
                                    )
                                  }
                                }
                              }
                            }),
                            _c("em"),
                            _vm._v("Prime")
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
                          _vm._v(" "),
                          _c("number-edit", {
                            attrs: {
                              step: "0.01",
                              disabled: !_vm.currentTemplate
                                .fullAccessMonthlyToggle
                            },
                            model: {
                              value: _vm.currentTemplate.fullAccessMonthly,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.currentTemplate,
                                  "fullAccessMonthly",
                                  $$v
                                )
                              },
                              expression: "currentTemplate.fullAccessMonthly"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ])
              : _vm._e()
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("h2", [_vm._v("PRICING MODEL")]),
    _vm._v(" "),
    _c("ul", { staticClass: "pricing-list" }, [
      _c(
        "li",
        {
          attrs: { "xv-if": "hasM('Billing Daily') || hasM('Billing Monthly')" }
        },
        [
          _c("label", [_vm._v("Billing Option")]),
          _vm._v(" "),
          _c("multiselect-dropdown", {
            attrs: {
              options: [
                { label: "Billing Daily", value: "dailyPricing" },
                { label: "Billing Monthly", value: "monthlyPricing" }
              ],
              isMulti: true,
              isExclusive: true
            },
            model: {
              value: _vm.ctCheckboxes,
              callback: function($$v) {
                _vm.ctCheckboxes = $$v
              },
              expression: "ctCheckboxes"
            }
          }),
          _vm._v(" "),
          false
            ? _c("ul", [
                _vm.hasM("Billing Daily")
                  ? _c("li", [
                      _c("label", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.currentTemplate.dailyPricing,
                              expression: "currentTemplate.dailyPricing"
                            }
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(
                              _vm.currentTemplate.dailyPricing
                            )
                              ? _vm._i(_vm.currentTemplate.dailyPricing, null) >
                                -1
                              : _vm.currentTemplate.dailyPricing
                          },
                          on: {
                            input: function($event) {
                              _vm.currentTemplate.monthlyPricing = false
                            },
                            change: function($event) {
                              var $$a = _vm.currentTemplate.dailyPricing,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.currentTemplate,
                                      "dailyPricing",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.currentTemplate,
                                      "dailyPricing",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.currentTemplate,
                                  "dailyPricing",
                                  $$c
                                )
                              }
                            }
                          }
                        }),
                        _c("em"),
                        _vm._v("Daily")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.hasM("Billing Monthly")
                  ? _c("li", [
                      _c("label", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.currentTemplate.monthlyPricing,
                              expression: "currentTemplate.monthlyPricing"
                            }
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(
                              _vm.currentTemplate.monthlyPricing
                            )
                              ? _vm._i(
                                  _vm.currentTemplate.monthlyPricing,
                                  null
                                ) > -1
                              : _vm.currentTemplate.monthlyPricing
                          },
                          on: {
                            input: function($event) {
                              _vm.currentTemplate.dailyPricing = false
                            },
                            change: function($event) {
                              var $$a = _vm.currentTemplate.monthlyPricing,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.currentTemplate,
                                      "monthlyPricing",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.currentTemplate,
                                      "monthlyPricing",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.currentTemplate,
                                  "monthlyPricing",
                                  $$c
                                )
                              }
                            }
                          }
                        }),
                        _c("em"),
                        _vm._v("Monthly")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("li")
              ])
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "li",
        [
          _c("label", [_vm._v("Billing Model")]),
          _vm._v(" "),
          _c("multiselect-dropdown", {
            attrs: {
              options: [
                { label: "Billing Flat Rate", value: "flatRatePricing" },
                {
                  label: "Billing Interchange Plus",
                  value: "interchangePricing"
                },
                { label: "Billing Tiered", value: "tieredPricing" }
              ],
              isMulti: true,
              isExclusive: true
            },
            model: {
              value: _vm.ctCheckboxes,
              callback: function($$v) {
                _vm.ctCheckboxes = $$v
              },
              expression: "ctCheckboxes"
            }
          }),
          _vm._v(" "),
          false
            ? _c("ul", [
                _vm.hasM("Billing Flat Rate")
                  ? _c("li", [
                      _c("label", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.currentTemplate.flatRatePricing,
                              expression: "currentTemplate.flatRatePricing"
                            }
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(
                              _vm.currentTemplate.flatRatePricing
                            )
                              ? _vm._i(
                                  _vm.currentTemplate.flatRatePricing,
                                  null
                                ) > -1
                              : _vm.currentTemplate.flatRatePricing
                          },
                          on: {
                            input: function($event) {
                              _vm.currentTemplate.interchangePricing = false
                              _vm.currentTemplate.tieredPricing = false
                            },
                            change: function($event) {
                              var $$a = _vm.currentTemplate.flatRatePricing,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.currentTemplate,
                                      "flatRatePricing",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.currentTemplate,
                                      "flatRatePricing",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.currentTemplate,
                                  "flatRatePricing",
                                  $$c
                                )
                              }
                            }
                          }
                        }),
                        _c("em"),
                        _vm._v("Flat Rate")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.hasM("Billing Interchange Plus")
                  ? _c("li", [
                      _c("label", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.currentTemplate.interchangePricing,
                              expression: "currentTemplate.interchangePricing"
                            }
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(
                              _vm.currentTemplate.interchangePricing
                            )
                              ? _vm._i(
                                  _vm.currentTemplate.interchangePricing,
                                  null
                                ) > -1
                              : _vm.currentTemplate.interchangePricing
                          },
                          on: {
                            input: function($event) {
                              _vm.currentTemplate.flatRatePricing = false
                              _vm.currentTemplate.tieredPricing = false
                            },
                            change: function($event) {
                              var $$a = _vm.currentTemplate.interchangePricing,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.currentTemplate,
                                      "interchangePricing",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.currentTemplate,
                                      "interchangePricing",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.currentTemplate,
                                  "interchangePricing",
                                  $$c
                                )
                              }
                            }
                          }
                        }),
                        _c("em"),
                        _vm._v("Interchange Plus Pricing")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.hasM("Billing Tiered")
                  ? _c("li", [
                      _c("label", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.currentTemplate.tieredPricing,
                              expression: "currentTemplate.tieredPricing"
                            }
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(
                              _vm.currentTemplate.tieredPricing
                            )
                              ? _vm._i(
                                  _vm.currentTemplate.tieredPricing,
                                  null
                                ) > -1
                              : _vm.currentTemplate.tieredPricing
                          },
                          on: {
                            input: function($event) {
                              _vm.currentTemplate.flatRatePricing = false
                              _vm.currentTemplate.interchangePricing = false
                            },
                            change: function($event) {
                              var $$a = _vm.currentTemplate.tieredPricing,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.currentTemplate,
                                      "tieredPricing",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.currentTemplate,
                                      "tieredPricing",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.currentTemplate,
                                  "tieredPricing",
                                  $$c
                                )
                              }
                            }
                          }
                        }),
                        _c("em"),
                        _vm._v("Tiered Pricing")
                      ])
                    ])
                  : _vm._e()
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.hasM("Billing Tiered") && _vm.currentTemplate.tieredPricing
            ? _c("div", { staticClass: "details details-tiered" }, [
                _c("ul", [
                  _c("li", [
                    _c("label", [_vm._v("T1 - Qualified")]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.currentTemplate.tier1,
                          expression: "currentTemplate.tier1"
                        }
                      ],
                      attrs: {
                        disabled: !_vm.currentTemplate.tieredPricing,
                        type: "text"
                      },
                      domProps: { value: _vm.currentTemplate.tier1 },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.currentTemplate,
                            "tier1",
                            $event.target.value
                          )
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "suffix" }, [_vm._v("%")])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("label", [_vm._v("T2 - Mid Qualified")]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.currentTemplate.tier2,
                          expression: "currentTemplate.tier2"
                        }
                      ],
                      attrs: {
                        disabled: !_vm.currentTemplate.tieredPricing,
                        type: "text"
                      },
                      domProps: { value: _vm.currentTemplate.tier2 },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.currentTemplate,
                            "tier2",
                            $event.target.value
                          )
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "suffix" }, [_vm._v("%")])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("label", [_vm._v("T3 - Non Qualified")]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.currentTemplate.tier3,
                          expression: "currentTemplate.tier3"
                        }
                      ],
                      attrs: {
                        disabled: !_vm.currentTemplate.tieredPricing,
                        type: "text"
                      },
                      domProps: { value: _vm.currentTemplate.tier3 },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.currentTemplate,
                            "tier3",
                            $event.target.value
                          )
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "suffix" }, [_vm._v("%")])
                  ])
                ])
              ])
            : _vm._e()
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("h2", [_vm._v("PAYMENT METHODS")]),
    _vm._v(" "),
    _c("ul", { staticClass: "pricing-list" }, [
      _vm.hasM("Visa") ||
      _vm.hasM("Mastercard") ||
      _vm.hasM("Discover") ||
      _vm.hasM("AMEX") ||
      _vm.hasM("JCB") ||
      _vm.hasM("Dinners") ||
      _vm.hasM("Debit")
        ? _c(
            "li",
            [
              _c("label", [_vm._v("Types")]),
              _vm._v(" "),
              _c("multiselect-dropdown", {
                attrs: { options: _vm.cardOpts, isMulti: true },
                model: {
                  value: _vm.currentTemplate.methods,
                  callback: function($$v) {
                    _vm.$set(_vm.currentTemplate, "methods", $$v)
                  },
                  expression: "currentTemplate.methods"
                }
              }),
              _vm._v(" "),
              false
                ? _c("ul", [
                    _vm.hasM("Visa")
                      ? _c("li", [
                          _c("label", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.currentTemplate.methods,
                                  expression: "currentTemplate.methods"
                                }
                              ],
                              attrs: { type: "checkbox", value: "Visa" },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.currentTemplate.methods
                                )
                                  ? _vm._i(
                                      _vm.currentTemplate.methods,
                                      "Visa"
                                    ) > -1
                                  : _vm.currentTemplate.methods
                              },
                              on: {
                                change: function($event) {
                                  var $$a = _vm.currentTemplate.methods,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = "Visa",
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.currentTemplate,
                                          "methods",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.currentTemplate,
                                          "methods",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.currentTemplate,
                                      "methods",
                                      $$c
                                    )
                                  }
                                }
                              }
                            }),
                            _c("em"),
                            _vm._v("\n              Visa\n            ")
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasM("Mastercard")
                      ? _c("li", [
                          _c("label", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.currentTemplate.methods,
                                  expression: "currentTemplate.methods"
                                }
                              ],
                              attrs: { type: "checkbox", value: "Mastercard" },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.currentTemplate.methods
                                )
                                  ? _vm._i(
                                      _vm.currentTemplate.methods,
                                      "Mastercard"
                                    ) > -1
                                  : _vm.currentTemplate.methods
                              },
                              on: {
                                change: function($event) {
                                  var $$a = _vm.currentTemplate.methods,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = "Mastercard",
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.currentTemplate,
                                          "methods",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.currentTemplate,
                                          "methods",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.currentTemplate,
                                      "methods",
                                      $$c
                                    )
                                  }
                                }
                              }
                            }),
                            _c("em"),
                            _vm._v("\n              Mastercard\n            ")
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasM("Discover")
                      ? _c("li", [
                          _c("label", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.currentTemplate.methods,
                                  expression: "currentTemplate.methods"
                                }
                              ],
                              attrs: { type: "checkbox", value: "Discover" },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.currentTemplate.methods
                                )
                                  ? _vm._i(
                                      _vm.currentTemplate.methods,
                                      "Discover"
                                    ) > -1
                                  : _vm.currentTemplate.methods
                              },
                              on: {
                                change: function($event) {
                                  var $$a = _vm.currentTemplate.methods,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = "Discover",
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.currentTemplate,
                                          "methods",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.currentTemplate,
                                          "methods",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.currentTemplate,
                                      "methods",
                                      $$c
                                    )
                                  }
                                }
                              }
                            }),
                            _c("em"),
                            _vm._v("\n              Discover\n            ")
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasM("AMEX")
                      ? _c("li", [
                          _c("label", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.currentTemplate.methods,
                                  expression: "currentTemplate.methods"
                                }
                              ],
                              attrs: { type: "checkbox", value: "AMEX" },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.currentTemplate.methods
                                )
                                  ? _vm._i(
                                      _vm.currentTemplate.methods,
                                      "AMEX"
                                    ) > -1
                                  : _vm.currentTemplate.methods
                              },
                              on: {
                                change: function($event) {
                                  var $$a = _vm.currentTemplate.methods,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = "AMEX",
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.currentTemplate,
                                          "methods",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.currentTemplate,
                                          "methods",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.currentTemplate,
                                      "methods",
                                      $$c
                                    )
                                  }
                                }
                              }
                            }),
                            _c("em"),
                            _vm._v("\n              AMEX\n            ")
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasM("JCB")
                      ? _c("li", [
                          _c("label", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.currentTemplate.methods,
                                  expression: "currentTemplate.methods"
                                }
                              ],
                              attrs: { type: "checkbox", value: "JCB" },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.currentTemplate.methods
                                )
                                  ? _vm._i(_vm.currentTemplate.methods, "JCB") >
                                    -1
                                  : _vm.currentTemplate.methods
                              },
                              on: {
                                change: function($event) {
                                  var $$a = _vm.currentTemplate.methods,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = "JCB",
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.currentTemplate,
                                          "methods",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.currentTemplate,
                                          "methods",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.currentTemplate,
                                      "methods",
                                      $$c
                                    )
                                  }
                                }
                              }
                            }),
                            _c("em"),
                            _vm._v("\n              JCB\n            ")
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasM("Dinners")
                      ? _c("li", [
                          _c("label", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.currentTemplate.methods,
                                  expression: "currentTemplate.methods"
                                }
                              ],
                              attrs: { type: "checkbox", value: "Dinners" },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.currentTemplate.methods
                                )
                                  ? _vm._i(
                                      _vm.currentTemplate.methods,
                                      "Dinners"
                                    ) > -1
                                  : _vm.currentTemplate.methods
                              },
                              on: {
                                change: function($event) {
                                  var $$a = _vm.currentTemplate.methods,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = "Dinners",
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.currentTemplate,
                                          "methods",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.currentTemplate,
                                          "methods",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.currentTemplate,
                                      "methods",
                                      $$c
                                    )
                                  }
                                }
                              }
                            }),
                            _c("em"),
                            _vm._v("\n              Dinners\n            ")
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasM("Debit")
                      ? _c("li", [
                          _c("label", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.currentTemplate.methods,
                                  expression: "currentTemplate.methods"
                                }
                              ],
                              attrs: { type: "checkbox", value: "Debit" },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.currentTemplate.methods
                                )
                                  ? _vm._i(
                                      _vm.currentTemplate.methods,
                                      "Debit"
                                    ) > -1
                                  : _vm.currentTemplate.methods
                              },
                              on: {
                                change: function($event) {
                                  var $$a = _vm.currentTemplate.methods,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = "Debit",
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.currentTemplate,
                                          "methods",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.currentTemplate,
                                          "methods",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.currentTemplate,
                                      "methods",
                                      $$c
                                    )
                                  }
                                }
                              }
                            }),
                            _c("em"),
                            _vm._v("\n              Debit\n            ")
                          ])
                        ])
                      : _vm._e()
                  ])
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      (_vm.hasM("Visa") ||
        _vm.hasM("Mastercard") ||
        _vm.hasM("Discover") ||
        _vm.hasM("AMEX") ||
        _vm.hasM("JCB") ||
        _vm.hasM("Dinners") ||
        _vm.hasM("Debit")) &&
      _vm.cardSel.ccard
        ? _c("li", [
            _c("label", [_vm._v("Swiped/Tapped/Inserted")]),
            _vm._v(" "),
            _c("ul", [
              _c(
                "li",
                [
                  _c("label", [_vm._v("Rate")]),
                  _vm._v(" "),
                  _c("number-edit", {
                    attrs: { step: "0.01", disabled: !_vm.cardSel.ccard },
                    model: {
                      value: _vm.currentTemplate.swipedTappedInserted,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.currentTemplate,
                          "swipedTappedInserted",
                          $$v
                        )
                      },
                      expression: "currentTemplate.swipedTappedInserted"
                    }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "suffix" }, [_vm._v("%")])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                [
                  _c("label", [_vm._v("Trans")]),
                  _vm._v(" "),
                  _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
                  _vm._v(" "),
                  _c("number-edit", {
                    attrs: { step: "0.01", disabled: !_vm.cardSel.ccard },
                    model: {
                      value: _vm.currentTemplate.swipedTappedInsertedTx,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.currentTemplate,
                          "swipedTappedInsertedTx",
                          $$v
                        )
                      },
                      expression: "currentTemplate.swipedTappedInsertedTx"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                [
                  _c("label", [_vm._v("Intl")]),
                  _vm._v(" "),
                  _c("number-edit", {
                    attrs: { step: "0.01", disabled: !_vm.cardSel.ccard },
                    model: {
                      value: _vm.currentTemplate.swipedTappedInsertedInt,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.currentTemplate,
                          "swipedTappedInsertedInt",
                          $$v
                        )
                      },
                      expression: "currentTemplate.swipedTappedInsertedInt"
                    }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "suffix" }, [_vm._v("%")])
                ],
                1
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      (_vm.hasM("Visa") ||
        _vm.hasM("Mastercard") ||
        _vm.hasM("Discover") ||
        _vm.hasM("AMEX") ||
        _vm.hasM("JCB") ||
        _vm.hasM("Dinners") ||
        _vm.hasM("Debit")) &&
      _vm.cardSel.ccard
        ? _c("li", [
            _c("label", [_vm._v("E-Commerce/Keyed")]),
            _vm._v(" "),
            _c("ul", [
              _c(
                "li",
                [
                  _c("label", [_vm._v("Rate")]),
                  _vm._v(" "),
                  _c("number-edit", {
                    attrs: { step: "0.01", disabled: !_vm.cardSel.ccard },
                    model: {
                      value: _vm.currentTemplate.eCommerceKeyed,
                      callback: function($$v) {
                        _vm.$set(_vm.currentTemplate, "eCommerceKeyed", $$v)
                      },
                      expression: "currentTemplate.eCommerceKeyed"
                    }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "suffix" }, [_vm._v("%")])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                [
                  _c("label", [_vm._v("Trans")]),
                  _vm._v(" "),
                  _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
                  _vm._v(" "),
                  _c("number-edit", {
                    attrs: { step: "0.01", disabled: !_vm.cardSel.ccard },
                    model: {
                      value: _vm.currentTemplate.eCommerceKeyedTx,
                      callback: function($$v) {
                        _vm.$set(_vm.currentTemplate, "eCommerceKeyedTx", $$v)
                      },
                      expression: "currentTemplate.eCommerceKeyedTx"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                [
                  _c("label", [_vm._v("Intl")]),
                  _vm._v(" "),
                  _c("number-edit", {
                    attrs: { step: "0.01", disabled: !_vm.cardSel.ccard },
                    model: {
                      value: _vm.currentTemplate.eCommerceKeyedInt,
                      callback: function($$v) {
                        _vm.$set(_vm.currentTemplate, "eCommerceKeyedInt", $$v)
                      },
                      expression: "currentTemplate.eCommerceKeyedInt"
                    }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "suffix" }, [_vm._v("%")])
                ],
                1
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      (_vm.hasM("Visa") ||
        _vm.hasM("Mastercard") ||
        _vm.hasM("Discover") ||
        _vm.hasM("AMEX") ||
        _vm.hasM("JCB") ||
        _vm.hasM("Dinners") ||
        _vm.hasM("Debit")) &&
      _vm.cardSel.ccard
        ? _c("li", [
            _c("label", [_vm._v("B2B")]),
            _vm._v(" "),
            _c("ul", [
              _c(
                "li",
                [
                  _c("label", [_vm._v("Level2 Trans")]),
                  _vm._v(" "),
                  _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
                  _vm._v(" "),
                  _c("number-edit", {
                    attrs: { step: "0.01", disabled: !_vm.cardSel.ccard },
                    model: {
                      value: _vm.currentTemplate.cbAsessment2,
                      callback: function($$v) {
                        _vm.$set(_vm.currentTemplate, "cbAsessment2", $$v)
                      },
                      expression: "currentTemplate.cbAsessment2"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                [
                  _c("label", [_vm._v("Level3 Trans")]),
                  _vm._v(" "),
                  _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
                  _vm._v(" "),
                  _c("number-edit", {
                    attrs: { step: "0.01", disabled: !_vm.cardSel.ccard },
                    model: {
                      value: _vm.currentTemplate.cbAsessment3,
                      callback: function($$v) {
                        _vm.$set(_vm.currentTemplate, "cbAsessment3", $$v)
                      },
                      expression: "currentTemplate.cbAsessment3"
                    }
                  })
                ],
                1
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.hasM("Debit") && _vm.cardSel.debit
        ? _c("li", [
            _c("label", [_vm._v("Debit: Swiped/Inserted")]),
            _vm._v(" "),
            _c("ul", [
              _c(
                "li",
                [
                  _c("label", [_vm._v("Trans")]),
                  _vm._v(" "),
                  _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
                  _vm._v(" "),
                  _c("number-edit", {
                    attrs: { step: "0.01", disabled: !_vm.cardSel.debit },
                    model: {
                      value: _vm.currentTemplate.debitTx,
                      callback: function($$v) {
                        _vm.$set(_vm.currentTemplate, "debitTx", $$v)
                      },
                      expression: "currentTemplate.debitTx"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                [
                  _c("label", [_vm._v("Tapped/Flash + Tx")]),
                  _vm._v(" "),
                  _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
                  _vm._v(" "),
                  _c("number-edit", {
                    attrs: { step: "0.01", disabled: !_vm.cardSel.debit },
                    model: {
                      value: _vm.currentTemplate.debitTx1,
                      callback: function($$v) {
                        _vm.$set(_vm.currentTemplate, "debitTx1", $$v)
                      },
                      expression: "currentTemplate.debitTx1"
                    }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "suffix-label" }, [_vm._v("(t1)")])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                [
                  _c("label", [_vm._v("+ Tx")]),
                  _vm._v(" "),
                  _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
                  _vm._v(" "),
                  _c("number-edit", {
                    attrs: { step: "0.01", disabled: !_vm.cardSel.debit },
                    model: {
                      value: _vm.currentTemplate.debitTx2,
                      callback: function($$v) {
                        _vm.$set(_vm.currentTemplate, "debitTx2", $$v)
                      },
                      expression: "currentTemplate.debitTx2"
                    }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "suffix-label" }, [_vm._v("(t2)")])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                [
                  _c("label", [_vm._v("+ Tx")]),
                  _vm._v(" "),
                  _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
                  _vm._v(" "),
                  _c("number-edit", {
                    attrs: { step: "0.01", disabled: !_vm.cardSel.debit },
                    model: {
                      value: _vm.currentTemplate.debitTx3,
                      callback: function($$v) {
                        _vm.$set(_vm.currentTemplate, "debitTx3", $$v)
                      },
                      expression: "currentTemplate.debitTx3"
                    }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "suffix-label" }, [_vm._v("(t3)")])
                ],
                1
              )
            ])
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("h2", [
      _c("label", [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.currentTemplate.cbPlusToggle,
              expression: "currentTemplate.cbPlusToggle"
            }
          ],
          attrs: { type: "checkbox" },
          domProps: {
            checked: Array.isArray(_vm.currentTemplate.cbPlusToggle)
              ? _vm._i(_vm.currentTemplate.cbPlusToggle, null) > -1
              : _vm.currentTemplate.cbPlusToggle
          },
          on: {
            change: function($event) {
              var $$a = _vm.currentTemplate.cbPlusToggle,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 &&
                    _vm.$set(
                      _vm.currentTemplate,
                      "cbPlusToggle",
                      $$a.concat([$$v])
                    )
                } else {
                  $$i > -1 &&
                    _vm.$set(
                      _vm.currentTemplate,
                      "cbPlusToggle",
                      $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                    )
                }
              } else {
                _vm.$set(_vm.currentTemplate, "cbPlusToggle", $$c)
              }
            }
          }
        }),
        _c("em"),
        _vm._v("ADVANCED PAYMENT METHODS")
      ])
    ]),
    _vm._v(" "),
    _c("ul", { staticClass: "pricing-list" }, [
      _vm.hasM("Apple Pay") ||
      _vm.hasM("Google Pay") ||
      _vm.hasM("Samsung Pay") ||
      _vm.hasM("Interac Online")
        ? _c(
            "li",
            [
              _c("label", [_vm._v("Types")]),
              _vm._v(" "),
              _c("multiselect-dropdown", {
                attrs: {
                  options: _vm.advPayOpts,
                  isMulti: true,
                  disabled: !_vm.currentTemplate.cbPlusToggle
                },
                model: {
                  value: _vm.currentTemplate.methods,
                  callback: function($$v) {
                    _vm.$set(_vm.currentTemplate, "methods", $$v)
                  },
                  expression: "currentTemplate.methods"
                }
              }),
              _vm._v(" "),
              false
                ? _c("ul", [
                    _vm.hasM("Apple Pay")
                      ? _c("li", [
                          _c("label", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.currentTemplate.methods,
                                  expression: "currentTemplate.methods"
                                }
                              ],
                              attrs: {
                                type: "checkbox",
                                value: "Apple Pay",
                                disabled: !_vm.currentTemplate.cbPlusToggle
                              },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.currentTemplate.methods
                                )
                                  ? _vm._i(
                                      _vm.currentTemplate.methods,
                                      "Apple Pay"
                                    ) > -1
                                  : _vm.currentTemplate.methods
                              },
                              on: {
                                change: function($event) {
                                  var $$a = _vm.currentTemplate.methods,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = "Apple Pay",
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.currentTemplate,
                                          "methods",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.currentTemplate,
                                          "methods",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.currentTemplate,
                                      "methods",
                                      $$c
                                    )
                                  }
                                }
                              }
                            }),
                            _c("em"),
                            _vm._v("\n              Apple Pay\n            ")
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasM("Google Pay")
                      ? _c("li", [
                          _c("label", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.currentTemplate.methods,
                                  expression: "currentTemplate.methods"
                                }
                              ],
                              attrs: {
                                type: "checkbox",
                                value: "Google Pay",
                                disabled: !_vm.currentTemplate.cbPlusToggle
                              },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.currentTemplate.methods
                                )
                                  ? _vm._i(
                                      _vm.currentTemplate.methods,
                                      "Google Pay"
                                    ) > -1
                                  : _vm.currentTemplate.methods
                              },
                              on: {
                                change: function($event) {
                                  var $$a = _vm.currentTemplate.methods,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = "Google Pay",
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.currentTemplate,
                                          "methods",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.currentTemplate,
                                          "methods",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.currentTemplate,
                                      "methods",
                                      $$c
                                    )
                                  }
                                }
                              }
                            }),
                            _c("em"),
                            _vm._v("\n              Google Pay\n            ")
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasM("Samsung Pay")
                      ? _c("li", [
                          _c("label", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.currentTemplate.methods,
                                  expression: "currentTemplate.methods"
                                }
                              ],
                              attrs: {
                                type: "checkbox",
                                value: "Samsung Pay",
                                disabled: !_vm.currentTemplate.cbPlusToggle
                              },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.currentTemplate.methods
                                )
                                  ? _vm._i(
                                      _vm.currentTemplate.methods,
                                      "Samsung Pay"
                                    ) > -1
                                  : _vm.currentTemplate.methods
                              },
                              on: {
                                change: function($event) {
                                  var $$a = _vm.currentTemplate.methods,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = "Samsung Pay",
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.currentTemplate,
                                          "methods",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.currentTemplate,
                                          "methods",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.currentTemplate,
                                      "methods",
                                      $$c
                                    )
                                  }
                                }
                              }
                            }),
                            _c("em"),
                            _vm._v("\n              Samsung Pay\n            ")
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasM("Interac Online")
                      ? _c("li", [
                          _c("label", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.currentTemplate.methods,
                                  expression: "currentTemplate.methods"
                                }
                              ],
                              attrs: {
                                type: "checkbox",
                                value: "Interac Online",
                                disabled: !_vm.currentTemplate.cbPlusToggle
                              },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.currentTemplate.methods
                                )
                                  ? _vm._i(
                                      _vm.currentTemplate.methods,
                                      "Interac Online"
                                    ) > -1
                                  : _vm.currentTemplate.methods
                              },
                              on: {
                                change: function($event) {
                                  var $$a = _vm.currentTemplate.methods,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = "Interac Online",
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.currentTemplate,
                                          "methods",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.currentTemplate,
                                          "methods",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.currentTemplate,
                                      "methods",
                                      $$c
                                    )
                                  }
                                }
                              }
                            }),
                            _c("em"),
                            _vm._v(
                              "\n              Interac Online\n            "
                            )
                          ])
                        ])
                      : _vm._e()
                  ])
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      (_vm.hasM("Apple Pay") ||
        _vm.hasM("Google Pay") ||
        _vm.hasM("Samsung Pay") ||
        _vm.hasM("Interac Online")) &&
      _vm.currentTemplate.cbPlusToggle
        ? _c("li", [
            _c("label", [_vm._v("All Types")]),
            _vm._v(" "),
            _c("ul", [
              _c(
                "li",
                [
                  _c("label", [_vm._v("Monthly")]),
                  _vm._v(" "),
                  _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
                  _vm._v(" "),
                  _c("number-edit", {
                    attrs: {
                      step: "0.01",
                      disabled: !_vm.currentTemplate.cbPlusToggle
                    },
                    model: {
                      value: _vm.currentTemplate.cbPlusMontly,
                      callback: function($$v) {
                        _vm.$set(_vm.currentTemplate, "cbPlusMontly", $$v)
                      },
                      expression: "currentTemplate.cbPlusMontly"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                [
                  _c("label", [_vm._v("Rate")]),
                  _vm._v(" "),
                  _c("number-edit", {
                    attrs: {
                      step: "0.01",
                      disabled: !_vm.currentTemplate.cbPlusToggle
                    },
                    model: {
                      value: _vm.currentTemplate.cbPlus,
                      callback: function($$v) {
                        _vm.$set(_vm.currentTemplate, "cbPlus", $$v)
                      },
                      expression: "currentTemplate.cbPlus"
                    }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "suffix" }, [_vm._v("%")])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                [
                  _c("label", [_vm._v("Trans")]),
                  _vm._v(" "),
                  _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
                  _vm._v(" "),
                  _c("number-edit", {
                    attrs: {
                      step: "0.01",
                      disabled: !_vm.currentTemplate.cbPlusToggle
                    },
                    model: {
                      value: _vm.currentTemplate.cbPlusTx,
                      callback: function($$v) {
                        _vm.$set(_vm.currentTemplate, "cbPlusTx", $$v)
                      },
                      expression: "currentTemplate.cbPlusTx"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                [
                  _c("label", [_vm._v("Intl")]),
                  _vm._v(" "),
                  _c("number-edit", {
                    attrs: {
                      step: "0.01",
                      disabled: !_vm.currentTemplate.cbPlusToggle
                    },
                    model: {
                      value: _vm.currentTemplate.cbPlusInt,
                      callback: function($$v) {
                        _vm.$set(_vm.currentTemplate, "cbPlusInt", $$v)
                      },
                      expression: "currentTemplate.cbPlusInt"
                    }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "suffix" }, [_vm._v("%")])
                ],
                1
              )
            ])
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("h2", [
      _c("label", [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.currentTemplate.bbToggle,
              expression: "currentTemplate.bbToggle"
            }
          ],
          attrs: { type: "checkbox" },
          domProps: {
            checked: Array.isArray(_vm.currentTemplate.bbToggle)
              ? _vm._i(_vm.currentTemplate.bbToggle, null) > -1
              : _vm.currentTemplate.bbToggle
          },
          on: {
            change: function($event) {
              var $$a = _vm.currentTemplate.bbToggle,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 &&
                    _vm.$set(_vm.currentTemplate, "bbToggle", $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    _vm.$set(
                      _vm.currentTemplate,
                      "bbToggle",
                      $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                    )
                }
              } else {
                _vm.$set(_vm.currentTemplate, "bbToggle", $$c)
              }
            }
          }
        }),
        _c("em"),
        _vm._v("BANK CLEARING")
      ])
    ]),
    _vm._v(" "),
    _c("ul", { staticClass: "pricing-list" }, [
      _vm.hasM("EFT") ||
      _vm.hasM("EFT Credits") ||
      _vm.hasM("ACH") ||
      _vm.hasM("ACH Credits")
        ? _c(
            "li",
            [
              _c("label", [_vm._v("Types")]),
              _vm._v(" "),
              _c("multiselect-dropdown", {
                attrs: {
                  options: _vm.bankClearingOpts,
                  isMulti: true,
                  disabled: !_vm.currentTemplate.bbToggle
                },
                model: {
                  value: _vm.currentTemplate.methods,
                  callback: function($$v) {
                    _vm.$set(_vm.currentTemplate, "methods", $$v)
                  },
                  expression: "currentTemplate.methods"
                }
              }),
              _vm._v(" "),
              false
                ? _c("ul", [
                    _vm.hasM("EFT")
                      ? _c("li", [
                          _c("label", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.currentTemplate.methods,
                                  expression: "currentTemplate.methods"
                                }
                              ],
                              attrs: {
                                type: "checkbox",
                                value: "EFT",
                                disabled: !_vm.currentTemplate.bbToggle
                              },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.currentTemplate.methods
                                )
                                  ? _vm._i(_vm.currentTemplate.methods, "EFT") >
                                    -1
                                  : _vm.currentTemplate.methods
                              },
                              on: {
                                change: function($event) {
                                  var $$a = _vm.currentTemplate.methods,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = "EFT",
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.currentTemplate,
                                          "methods",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.currentTemplate,
                                          "methods",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.currentTemplate,
                                      "methods",
                                      $$c
                                    )
                                  }
                                }
                              }
                            }),
                            _c("em"),
                            _vm._v("\n              EFT\n            ")
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasM("EFT Credits")
                      ? _c("li", [
                          _c("label", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.currentTemplate.methods,
                                  expression: "currentTemplate.methods"
                                }
                              ],
                              attrs: {
                                type: "checkbox",
                                value: "EFT Credits",
                                disabled: !_vm.currentTemplate.bbToggle
                              },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.currentTemplate.methods
                                )
                                  ? _vm._i(
                                      _vm.currentTemplate.methods,
                                      "EFT Credits"
                                    ) > -1
                                  : _vm.currentTemplate.methods
                              },
                              on: {
                                change: function($event) {
                                  var $$a = _vm.currentTemplate.methods,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = "EFT Credits",
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.currentTemplate,
                                          "methods",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.currentTemplate,
                                          "methods",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.currentTemplate,
                                      "methods",
                                      $$c
                                    )
                                  }
                                }
                              }
                            }),
                            _c("em"),
                            _vm._v("\n              EFT Credits\n            ")
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasM("ACH")
                      ? _c("li", [
                          _c("label", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.currentTemplate.methods,
                                  expression: "currentTemplate.methods"
                                }
                              ],
                              attrs: {
                                type: "checkbox",
                                value: "ACH",
                                disabled: !_vm.currentTemplate.bbToggle
                              },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.currentTemplate.methods
                                )
                                  ? _vm._i(_vm.currentTemplate.methods, "ACH") >
                                    -1
                                  : _vm.currentTemplate.methods
                              },
                              on: {
                                change: function($event) {
                                  var $$a = _vm.currentTemplate.methods,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = "ACH",
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.currentTemplate,
                                          "methods",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.currentTemplate,
                                          "methods",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.currentTemplate,
                                      "methods",
                                      $$c
                                    )
                                  }
                                }
                              }
                            }),
                            _c("em"),
                            _vm._v("\n              ACH\n            ")
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasM("ACH Credits")
                      ? _c("li", [
                          _c("label", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.currentTemplate.methods,
                                  expression: "currentTemplate.methods"
                                }
                              ],
                              attrs: {
                                type: "checkbox",
                                value: "ACH Credits",
                                disabled: !_vm.currentTemplate.bbToggle
                              },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.currentTemplate.methods
                                )
                                  ? _vm._i(
                                      _vm.currentTemplate.methods,
                                      "ACH Credits"
                                    ) > -1
                                  : _vm.currentTemplate.methods
                              },
                              on: {
                                change: function($event) {
                                  var $$a = _vm.currentTemplate.methods,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = "ACH Credits",
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.currentTemplate,
                                          "methods",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.currentTemplate,
                                          "methods",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.currentTemplate,
                                      "methods",
                                      $$c
                                    )
                                  }
                                }
                              }
                            }),
                            _c("em"),
                            _vm._v("\n              ACH Credits\n            ")
                          ])
                        ])
                      : _vm._e()
                  ])
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      (_vm.hasM("EFT") ||
        _vm.hasM("EFT Credits") ||
        _vm.hasM("ACH") ||
        _vm.hasM("ACH Credits")) &&
      _vm.currentTemplate.bbToggle
        ? _c("li", [
            _c("label", [_vm._v("Initial")]),
            _vm._v(" "),
            _c("ul", [
              _c(
                "li",
                [
                  _c("label", [_vm._v("Setup")]),
                  _vm._v(" "),
                  _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
                  _vm._v(" "),
                  _c("number-edit", {
                    attrs: {
                      step: "0.01",
                      disabled: !_vm.currentTemplate.bbToggle
                    },
                    model: {
                      value: _vm.currentTemplate.bbSetup,
                      callback: function($$v) {
                        _vm.$set(_vm.currentTemplate, "bbSetup", $$v)
                      },
                      expression: "currentTemplate.bbSetup"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                [
                  _c("label", [_vm._v("Monthly")]),
                  _vm._v(" "),
                  _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
                  _vm._v(" "),
                  _c("number-edit", {
                    attrs: {
                      step: "0.01",
                      disabled: !_vm.currentTemplate.bbToggle
                    },
                    model: {
                      value: _vm.currentTemplate.bbMontly,
                      callback: function($$v) {
                        _vm.$set(_vm.currentTemplate, "bbMontly", $$v)
                      },
                      expression: "currentTemplate.bbMontly"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                [
                  _c("label", [_vm._v("Non-Compliance")]),
                  _vm._v(" "),
                  _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
                  _vm._v(" "),
                  _c("number-edit", {
                    attrs: {
                      step: "0.01",
                      disabled: !_vm.currentTemplate.bbToggle
                    },
                    model: {
                      value: _vm.currentTemplate.bbNonComp,
                      callback: function($$v) {
                        _vm.$set(_vm.currentTemplate, "bbNonComp", $$v)
                      },
                      expression: "currentTemplate.bbNonComp"
                    }
                  })
                ],
                1
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      (_vm.hasM("EFT") ||
        _vm.hasM("EFT Credits") ||
        _vm.hasM("ACH") ||
        _vm.hasM("ACH Credits")) &&
      _vm.currentTemplate.bbToggle
        ? _c("li", [
            _c("label", [_vm._v("Processing")]),
            _vm._v(" "),
            _c("ul", [
              _c(
                "li",
                [
                  _c("label", [_vm._v("Rate")]),
                  _vm._v(" "),
                  _c("number-edit", {
                    attrs: {
                      step: "0.01",
                      disabled: !_vm.currentTemplate.bbToggle
                    },
                    model: {
                      value: _vm.currentTemplate.bbRate,
                      callback: function($$v) {
                        _vm.$set(_vm.currentTemplate, "bbRate", $$v)
                      },
                      expression: "currentTemplate.bbRate"
                    }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "suffix" }, [_vm._v("%")])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                [
                  _c("label", [_vm._v("Trans")]),
                  _vm._v(" "),
                  _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
                  _vm._v(" "),
                  _c("number-edit", {
                    attrs: {
                      step: "0.01",
                      disabled: !_vm.currentTemplate.bbToggle
                    },
                    model: {
                      value: _vm.currentTemplate.bbTx,
                      callback: function($$v) {
                        _vm.$set(_vm.currentTemplate, "bbTx", $$v)
                      },
                      expression: "currentTemplate.bbTx"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                [
                  _c("label", [_vm._v("Batch Fee")]),
                  _vm._v(" "),
                  _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
                  _vm._v(" "),
                  _c("number-edit", {
                    attrs: {
                      step: "0.01",
                      disabled: !_vm.currentTemplate.bbToggle
                    },
                    model: {
                      value: _vm.currentTemplate.bbBatch,
                      callback: function($$v) {
                        _vm.$set(_vm.currentTemplate, "bbBatch", $$v)
                      },
                      expression: "currentTemplate.bbBatch"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("li")
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      (_vm.hasM("EFT") ||
        _vm.hasM("EFT Credits") ||
        _vm.hasM("ACH") ||
        _vm.hasM("ACH Credits")) &&
      _vm.currentTemplate.bbToggle
        ? _c("li", [
            _c("label"),
            _vm._v(" "),
            _c("ul", [
              _c(
                "li",
                [
                  _c("label", [_vm._v("Rejects")]),
                  _vm._v(" "),
                  _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
                  _vm._v(" "),
                  _c("number-edit", {
                    attrs: {
                      step: "0.01",
                      disabled: !_vm.currentTemplate.bbToggle
                    },
                    model: {
                      value: _vm.currentTemplate.bbRejects,
                      callback: function($$v) {
                        _vm.$set(_vm.currentTemplate, "bbRejects", $$v)
                      },
                      expression: "currentTemplate.bbRejects"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                [
                  _c("label", [_vm._v("Returns")]),
                  _vm._v(" "),
                  _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
                  _vm._v(" "),
                  _c("number-edit", {
                    attrs: {
                      step: "0.01",
                      disabled: !_vm.currentTemplate.bbToggle
                    },
                    model: {
                      value: _vm.currentTemplate.bbReturns,
                      callback: function($$v) {
                        _vm.$set(_vm.currentTemplate, "bbReturns", $$v)
                      },
                      expression: "currentTemplate.bbReturns"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                [
                  _c("label", [_vm._v("Unauthorized")]),
                  _vm._v(" "),
                  _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
                  _vm._v(" "),
                  _c("number-edit", {
                    attrs: {
                      step: "0.01",
                      disabled: !_vm.currentTemplate.bbToggle
                    },
                    model: {
                      value: _vm.currentTemplate.bbUnauthorized,
                      callback: function($$v) {
                        _vm.$set(_vm.currentTemplate, "bbUnauthorized", $$v)
                      },
                      expression: "currentTemplate.bbUnauthorized"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                [
                  _c("label", [_vm._v("Late Returns")]),
                  _vm._v(" "),
                  _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
                  _vm._v(" "),
                  _c("number-edit", {
                    attrs: {
                      step: "0.01",
                      disabled: !_vm.currentTemplate.bbToggle
                    },
                    model: {
                      value: _vm.currentTemplate.bbLate,
                      callback: function($$v) {
                        _vm.$set(_vm.currentTemplate, "bbLate", $$v)
                      },
                      expression: "currentTemplate.bbLate"
                    }
                  })
                ],
                1
              )
            ])
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("h2", [_vm._v("MODULES")]),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "pricing-list pl2 madon" },
      [
        _c(
          "li",
          { staticStyle: { width: "100%" } },
          [
            _c("label", { staticStyle: { "min-width": "16.66%" } }, [
              _vm._v("Types")
            ]),
            _vm._v(" "),
            _c("multiselect-dropdown", {
              attrs: {
                options: _vm.methodOptions.allModules.map(function(a) {
                  return { label: a, value: a }
                }),
                isMulti: true
              },
              model: {
                value: _vm.ctCheckboxes,
                callback: function($$v) {
                  _vm.ctCheckboxes = $$v
                },
                expression: "ctCheckboxes"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _vm._l(_vm.methodOptions.allModules, function(moduleName) {
          return _vm.ctCheckboxes.indexOf(moduleName) != -1
            ? _c("li", [
                _c("label", [_c("em"), _vm._v(_vm._s(moduleName))]),
                _vm._v(" "),
                _c("ul", [
                  _c(
                    "li",
                    [
                      _c("label", [_vm._v("Setup")]),
                      _vm._v(" "),
                      _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
                      _vm._v(" "),
                      _c("number-edit", {
                        attrs: { step: "0.01" },
                        model: {
                          value: _vm.currentTemplate.payAxisSetup,
                          callback: function($$v) {
                            _vm.$set(_vm.currentTemplate, "payAxisSetup", $$v)
                          },
                          expression: "currentTemplate.payAxisSetup"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    [
                      _c("label", [_vm._v("Monthly")]),
                      _vm._v(" "),
                      _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
                      _vm._v(" "),
                      _c("number-edit", {
                        attrs: { step: "0.01" },
                        model: {
                          value: _vm.currentTemplate.payAxisMonthly,
                          callback: function($$v) {
                            _vm.$set(_vm.currentTemplate, "payAxisMonthly", $$v)
                          },
                          expression: "currentTemplate.payAxisMonthly"
                        }
                      })
                    ],
                    1
                  )
                ])
              ])
            : _vm._e()
        })
      ],
      2
    ),
    _vm._v(" "),
    _c("h2", [_vm._v("ACCOUNT OPTIONS")]),
    _vm._v(" "),
    _c("ul", { staticClass: "pricing-list pl3 madon" }, [
      _c(
        "li",
        { staticStyle: { width: "100%" } },
        [
          _c("label", { staticStyle: { "min-width": "16.66%" } }, [
            _vm._v("Types")
          ]),
          _vm._v(" "),
          _c("multiselect-dropdown", {
            attrs: { options: _vm.accountOpts, isMulti: true },
            model: {
              value: _vm.ctCheckboxes,
              callback: function($$v) {
                _vm.ctCheckboxes = $$v
              },
              expression: "ctCheckboxes"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.hasM("Multi-Currency") && _vm.currentTemplate.multiCurrencyToggle
        ? _c("li", [
            _c("label", [
              false
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.currentTemplate.multiCurrencyToggle,
                        expression: "currentTemplate.multiCurrencyToggle"
                      }
                    ],
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(
                        _vm.currentTemplate.multiCurrencyToggle
                      )
                        ? _vm._i(
                            _vm.currentTemplate.multiCurrencyToggle,
                            null
                          ) > -1
                        : _vm.currentTemplate.multiCurrencyToggle
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.currentTemplate.multiCurrencyToggle,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.currentTemplate,
                                "multiCurrencyToggle",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.currentTemplate,
                                "multiCurrencyToggle",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(
                            _vm.currentTemplate,
                            "multiCurrencyToggle",
                            $$c
                          )
                        }
                      }
                    }
                  })
                : _vm._e(),
              _c("em"),
              _vm._v("Multi-Currency")
            ]),
            _vm._v(" "),
            _c("ul", [
              _c(
                "li",
                [
                  _c("label", [_vm._v("Monthly")]),
                  _vm._v(" "),
                  _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
                  _vm._v(" "),
                  _c("number-edit", {
                    attrs: {
                      step: "0.01",
                      disabled: !_vm.currentTemplate.multiCurrencyToggle
                    },
                    model: {
                      value: _vm.currentTemplate.multiCurrencyMonthly,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.currentTemplate,
                          "multiCurrencyMonthly",
                          $$v
                        )
                      },
                      expression: "currentTemplate.multiCurrencyMonthly"
                    }
                  })
                ],
                1
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.hasM("Subscription Billing") &&
      _vm.currentTemplate.virtualTerminalToggle
        ? _c("li", [
            _c("label", [
              false
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.currentTemplate.virtualTerminalToggle,
                        expression: "currentTemplate.virtualTerminalToggle"
                      }
                    ],
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(
                        _vm.currentTemplate.virtualTerminalToggle
                      )
                        ? _vm._i(
                            _vm.currentTemplate.virtualTerminalToggle,
                            null
                          ) > -1
                        : _vm.currentTemplate.virtualTerminalToggle
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.currentTemplate.virtualTerminalToggle,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.currentTemplate,
                                "virtualTerminalToggle",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.currentTemplate,
                                "virtualTerminalToggle",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(
                            _vm.currentTemplate,
                            "virtualTerminalToggle",
                            $$c
                          )
                        }
                      }
                    }
                  })
                : _vm._e(),
              _c("em"),
              _vm._v("Subscription Billing")
            ]),
            _vm._v(" "),
            _c("ul", [
              _c(
                "li",
                [
                  _c("label", [_vm._v("Monthly")]),
                  _vm._v(" "),
                  _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
                  _vm._v(" "),
                  _c("number-edit", {
                    attrs: {
                      step: "0.01",
                      disabled: !_vm.currentTemplate.virtualTerminalToggle
                    },
                    model: {
                      value: _vm.currentTemplate.virtualTerminalMonhly,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.currentTemplate,
                          "virtualTerminalMonhly",
                          $$v
                        )
                      },
                      expression: "currentTemplate.virtualTerminalMonhly"
                    }
                  })
                ],
                1
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.hasM("Mailed Statements") && _vm.currentTemplate.chammpCheckout
        ? _c("li", [
            _c("label", [
              false
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.currentTemplate.chammpCheckout,
                        expression: "currentTemplate.chammpCheckout"
                      }
                    ],
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.currentTemplate.chammpCheckout)
                        ? _vm._i(_vm.currentTemplate.chammpCheckout, null) > -1
                        : _vm.currentTemplate.chammpCheckout
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.currentTemplate.chammpCheckout,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.currentTemplate,
                                "chammpCheckout",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.currentTemplate,
                                "chammpCheckout",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.currentTemplate, "chammpCheckout", $$c)
                        }
                      }
                    }
                  })
                : _vm._e(),
              _c("em"),
              _vm._v("Mailed Statements")
            ]),
            _vm._v(" "),
            _c("ul", [
              _c(
                "li",
                [
                  _c("label", [_vm._v("Monthly")]),
                  _vm._v(" "),
                  _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
                  _vm._v(" "),
                  _c("number-edit", {
                    attrs: {
                      step: "0.01",
                      disabled: !_vm.currentTemplate.chammpCheckout
                    },
                    model: {
                      value: _vm.currentTemplate.chammpCheckoutMonthly,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.currentTemplate,
                          "chammpCheckoutMonthly",
                          $$v
                        )
                      },
                      expression: "currentTemplate.chammpCheckoutMonthly"
                    }
                  })
                ],
                1
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.hasM("Auto Updater") && _vm.currentTemplate.chammpUpdater
        ? _c("li", [
            _c("label", [
              false
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.currentTemplate.chammpUpdater,
                        expression: "currentTemplate.chammpUpdater"
                      }
                    ],
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.currentTemplate.chammpUpdater)
                        ? _vm._i(_vm.currentTemplate.chammpUpdater, null) > -1
                        : _vm.currentTemplate.chammpUpdater
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.currentTemplate.chammpUpdater,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.currentTemplate,
                                "chammpUpdater",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.currentTemplate,
                                "chammpUpdater",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.currentTemplate, "chammpUpdater", $$c)
                        }
                      }
                    }
                  })
                : _vm._e(),
              _c("em"),
              _vm._v("Auto Account Updater")
            ]),
            _vm._v(" "),
            _c("ul", [
              _c(
                "li",
                [
                  _c("label", [_vm._v("Monthly")]),
                  _vm._v(" "),
                  _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
                  _vm._v(" "),
                  _c("number-edit", {
                    attrs: {
                      step: "0.01",
                      disabled: !_vm.currentTemplate.chammpUpdater
                    },
                    model: {
                      value: _vm.currentTemplate.chammpUpdaterMonthly,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.currentTemplate,
                          "chammpUpdaterMonthly",
                          $$v
                        )
                      },
                      expression: "currentTemplate.chammpUpdaterMonthly"
                    }
                  })
                ],
                1
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.hasM("Titan") && _vm.currentTemplate.chammpTitan
        ? _c("li", [
            _c("label", [
              false
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.currentTemplate.chammpTitan,
                        expression: "currentTemplate.chammpTitan"
                      }
                    ],
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.currentTemplate.chammpTitan)
                        ? _vm._i(_vm.currentTemplate.chammpTitan, null) > -1
                        : _vm.currentTemplate.chammpTitan
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.currentTemplate.chammpTitan,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.currentTemplate,
                                "chammpTitan",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.currentTemplate,
                                "chammpTitan",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.currentTemplate, "chammpTitan", $$c)
                        }
                      }
                    }
                  })
                : _vm._e(),
              _c("em"),
              _vm._v("Titan")
            ]),
            _vm._v(" "),
            _c("ul", [
              _c(
                "li",
                [
                  _c("label", [_vm._v("Monthly")]),
                  _vm._v(" "),
                  _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
                  _vm._v(" "),
                  _c("number-edit", {
                    attrs: {
                      step: "0.01",
                      disabled: !_vm.currentTemplate.chammpTitan
                    },
                    model: {
                      value: _vm.currentTemplate.chammpTitanMonthly,
                      callback: function($$v) {
                        _vm.$set(_vm.currentTemplate, "chammpTitanMonthly", $$v)
                      },
                      expression: "currentTemplate.chammpTitanMonthly"
                    }
                  })
                ],
                1
              )
            ])
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    false ? _c("h2", [_vm._v("CONNECTIVITY")]) : _vm._e(),
    _vm._v(" "),
    false
      ? _c("ul", { staticClass: "pricing-list pl2 madon" }, [
          _c("li", [
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.currentTemplate.tids,
                    expression: "currentTemplate.tids"
                  }
                ],
                attrs: { type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.currentTemplate.tids)
                    ? _vm._i(_vm.currentTemplate.tids, null) > -1
                    : _vm.currentTemplate.tids
                },
                on: {
                  change: function($event) {
                    var $$a = _vm.currentTemplate.tids,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.currentTemplate,
                            "tids",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.currentTemplate,
                            "tids",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.currentTemplate, "tids", $$c)
                    }
                  }
                }
              }),
              _c("em"),
              _vm._v("TIDs")
            ]),
            _vm._v(" "),
            _c("ul", [
              _c(
                "li",
                [
                  _c("label", [_vm._v("Included")]),
                  _vm._v(" "),
                  _c("span", { staticClass: "prefix" }, [_vm._v("#")]),
                  _vm._v(" "),
                  _c("number-edit", {
                    attrs: {
                      step: "0.01",
                      disabled: !_vm.currentTemplate.tids
                    },
                    model: {
                      value: _vm.currentTemplate.tidsIncluded,
                      callback: function($$v) {
                        _vm.$set(_vm.currentTemplate, "tidsIncluded", $$v)
                      },
                      expression: "currentTemplate.tidsIncluded"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                [
                  _c("label", [_vm._v("Additional")]),
                  _vm._v(" "),
                  _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
                  _vm._v(" "),
                  _c("number-edit", {
                    attrs: {
                      step: "0.01",
                      disabled: !_vm.currentTemplate.tids
                    },
                    model: {
                      value: _vm.currentTemplate.tidsFee,
                      callback: function($$v) {
                        _vm.$set(_vm.currentTemplate, "tidsFee", $$v)
                      },
                      expression: "currentTemplate.tidsFee"
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c("li", [
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.currentTemplate.keys,
                    expression: "currentTemplate.keys"
                  }
                ],
                attrs: { type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.currentTemplate.keys)
                    ? _vm._i(_vm.currentTemplate.keys, null) > -1
                    : _vm.currentTemplate.keys
                },
                on: {
                  change: function($event) {
                    var $$a = _vm.currentTemplate.keys,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.currentTemplate,
                            "keys",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.currentTemplate,
                            "keys",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.currentTemplate, "keys", $$c)
                    }
                  }
                }
              }),
              _c("em"),
              _vm._v("API Keys")
            ]),
            _vm._v(" "),
            _c("ul", [
              _c(
                "li",
                [
                  _c("label", [_vm._v("Included")]),
                  _vm._v(" "),
                  _c("span", { staticClass: "prefix" }, [_vm._v("#")]),
                  _vm._v(" "),
                  _c("number-edit", {
                    attrs: {
                      step: "0.01",
                      disabled: !_vm.currentTemplate.keys
                    },
                    model: {
                      value: _vm.currentTemplate.keysIncluded,
                      callback: function($$v) {
                        _vm.$set(_vm.currentTemplate, "keysIncluded", $$v)
                      },
                      expression: "currentTemplate.keysIncluded"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                [
                  _c("label", [_vm._v("Additional")]),
                  _vm._v(" "),
                  _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
                  _vm._v(" "),
                  _c("number-edit", {
                    attrs: {
                      step: "0.01",
                      disabled: !_vm.currentTemplate.keys
                    },
                    model: {
                      value: _vm.currentTemplate.keysFee,
                      callback: function($$v) {
                        _vm.$set(_vm.currentTemplate, "keysFee", $$v)
                      },
                      expression: "currentTemplate.keysFee"
                    }
                  })
                ],
                1
              )
            ])
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("h2", [_vm._v("SYSTEM FEES")]),
    _vm._v(" "),
    _c("div", { staticClass: "fee-types" }, [
      _c("div", [
        _c("h3", [_vm._v("Per Occurrence")]),
        _vm._v(" "),
        _c("ul", { staticClass: "fee-list" }, [
          _c(
            "li",
            [
              _c("label", [_vm._v("Chargebacks")]),
              _vm._v(" "),
              _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
              _vm._v(" "),
              _c("number-edit", {
                attrs: { step: "0.01" },
                model: {
                  value: _vm.currentTemplate.chargebacks,
                  callback: function($$v) {
                    _vm.$set(_vm.currentTemplate, "chargebacks", $$v)
                  },
                  expression: "currentTemplate.chargebacks"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("label", [_vm._v("Retrievals")]),
              _vm._v(" "),
              _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
              _vm._v(" "),
              _c("number-edit", {
                attrs: { step: "0.01" },
                model: {
                  value: _vm.currentTemplate.retrievals,
                  callback: function($$v) {
                    _vm.$set(_vm.currentTemplate, "retrievals", $$v)
                  },
                  expression: "currentTemplate.retrievals"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("label", [_vm._v("Reversals")]),
              _vm._v(" "),
              _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
              _vm._v(" "),
              _c("number-edit", {
                attrs: { step: "0.01" },
                model: {
                  value: _vm.currentTemplate.reversals,
                  callback: function($$v) {
                    _vm.$set(_vm.currentTemplate, "reversals", $$v)
                  },
                  expression: "currentTemplate.reversals"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("label", [_vm._v("Alerts")]),
              _vm._v(" "),
              _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
              _vm._v(" "),
              _c("number-edit", {
                attrs: { step: "0.01" },
                model: {
                  value: _vm.currentTemplate.chammpAlerts,
                  callback: function($$v) {
                    _vm.$set(_vm.currentTemplate, "chammpAlerts", $$v)
                  },
                  expression: "currentTemplate.chammpAlerts"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("label", [_vm._v("IVR Authorization")]),
              _vm._v(" "),
              _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
              _vm._v(" "),
              _c("number-edit", {
                attrs: { step: "0.01" },
                model: {
                  value: _vm.currentTemplate.ivrAuthorizations,
                  callback: function($$v) {
                    _vm.$set(_vm.currentTemplate, "ivrAuthorizations", $$v)
                  },
                  expression: "currentTemplate.ivrAuthorizations"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("label", [_vm._v("Voice Authorization")]),
              _vm._v(" "),
              _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
              _vm._v(" "),
              _c("number-edit", {
                attrs: { step: "0.01" },
                model: {
                  value: _vm.currentTemplate.voiceAuthorizations,
                  callback: function($$v) {
                    _vm.$set(_vm.currentTemplate, "voiceAuthorizations", $$v)
                  },
                  expression: "currentTemplate.voiceAuthorizations"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("label", [_vm._v("Referral Authorizations")]),
              _vm._v(" "),
              _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
              _vm._v(" "),
              _c("number-edit", {
                attrs: { step: "0.01" },
                model: {
                  value: _vm.currentTemplate.referralAuthorization,
                  callback: function($$v) {
                    _vm.$set(_vm.currentTemplate, "referralAuthorization", $$v)
                  },
                  expression: "currentTemplate.referralAuthorization"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("label", [_vm._v("Wireless Activation")]),
              _vm._v(" "),
              _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
              _vm._v(" "),
              _c("number-edit", {
                attrs: { step: "0.01" },
                model: {
                  value: _vm.currentTemplate.wirelessActivation,
                  callback: function($$v) {
                    _vm.$set(_vm.currentTemplate, "wirelessActivation", $$v)
                  },
                  expression: "currentTemplate.wirelessActivation"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("label", [_vm._v("Wireless Additional Data")]),
              _vm._v(" "),
              _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
              _vm._v(" "),
              _c("number-edit", {
                attrs: { step: "0.01" },
                model: {
                  value: _vm.currentTemplate.wirelessData,
                  callback: function($$v) {
                    _vm.$set(_vm.currentTemplate, "wirelessData", $$v)
                  },
                  expression: "currentTemplate.wirelessData"
                }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "suffix" }, [_vm._v("/MB")])
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", [
        _c("h3", [_vm._v("Per Transaction")]),
        _vm._v(" "),
        _c("ul", { staticClass: "fee-list" }, [
          _c(
            "li",
            [
              _c("label", [_vm._v("Titan")]),
              _vm._v(" "),
              _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
              _vm._v(" "),
              _c("number-edit", {
                attrs: { step: "0.01" },
                model: {
                  value: _vm.currentTemplate.titan,
                  callback: function($$v) {
                    _vm.$set(_vm.currentTemplate, "titan", $$v)
                  },
                  expression: "currentTemplate.titan"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("label", [_vm._v("Titan Plus")]),
              _vm._v(" "),
              _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
              _vm._v(" "),
              _c("number-edit", {
                attrs: { step: "0.01" },
                model: {
                  value: _vm.currentTemplate.titanPlus,
                  callback: function($$v) {
                    _vm.$set(_vm.currentTemplate, "titanPlus", $$v)
                  },
                  expression: "currentTemplate.titanPlus"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("label", [_vm._v("Batch Fee")]),
              _vm._v(" "),
              _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
              _vm._v(" "),
              _c("number-edit", {
                attrs: { step: "0.01" },
                model: {
                  value: _vm.currentTemplate.batchFee,
                  callback: function($$v) {
                    _vm.$set(_vm.currentTemplate, "batchFee", $$v)
                  },
                  expression: "currentTemplate.batchFee"
                }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "suffix" }, [_vm._v("/Batch")])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("label", [_vm._v("Tokenization")]),
              _vm._v(" "),
              _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
              _vm._v(" "),
              _c("number-edit", {
                attrs: { step: "0.01" },
                model: {
                  value: _vm.currentTemplate.tokenization,
                  callback: function($$v) {
                    _vm.$set(_vm.currentTemplate, "tokenization", $$v)
                  },
                  expression: "currentTemplate.tokenization"
                }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "suffix" }, [_vm._v("/Stored")])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("label", [_vm._v("Auto Account Updater")]),
              _vm._v(" "),
              _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
              _vm._v(" "),
              _c("number-edit", {
                attrs: { step: "0.01" },
                model: {
                  value: _vm.currentTemplate.autoAccountUpdater,
                  callback: function($$v) {
                    _vm.$set(_vm.currentTemplate, "autoAccountUpdater", $$v)
                  },
                  expression: "currentTemplate.autoAccountUpdater"
                }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "suffix" }, [_vm._v("/Update")])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("label", [_vm._v("AVS")]),
              _vm._v(" "),
              _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
              _vm._v(" "),
              _c("number-edit", {
                attrs: { step: "0.01" },
                model: {
                  value: _vm.currentTemplate.avs,
                  callback: function($$v) {
                    _vm.$set(_vm.currentTemplate, "avs", $$v)
                  },
                  expression: "currentTemplate.avs"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("label", [_vm._v("Wireless Transaction Fee")]),
              _vm._v(" "),
              _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
              _vm._v(" "),
              _c("number-edit", {
                attrs: { step: "0.01" },
                model: {
                  value: _vm.currentTemplate.wirelessFee,
                  callback: function($$v) {
                    _vm.$set(_vm.currentTemplate, "wirelessFee", $$v)
                  },
                  expression: "currentTemplate.wirelessFee"
                }
              })
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", [
        _c("h3", [_vm._v("Monthly")]),
        _vm._v(" "),
        _c("ul", { staticClass: "fee-list" }, [
          _c(
            "li",
            [
              _c("label", [_vm._v("Cellular Wireless Data Fee")]),
              _vm._v(" "),
              _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
              _vm._v(" "),
              _c("number-edit", {
                attrs: { step: "0.01" },
                model: {
                  value: _vm.currentTemplate.cellularFee,
                  callback: function($$v) {
                    _vm.$set(_vm.currentTemplate, "cellularFee", $$v)
                  },
                  expression: "currentTemplate.cellularFee"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("label", [_vm._v("PCI Non-Compliance")]),
              _vm._v(" "),
              _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
              _vm._v(" "),
              _c("number-edit", {
                attrs: { step: "0.01" },
                model: {
                  value: _vm.currentTemplate.pciNonCompliance,
                  callback: function($$v) {
                    _vm.$set(_vm.currentTemplate, "pciNonCompliance", $$v)
                  },
                  expression: "currentTemplate.pciNonCompliance"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("label", [_vm._v("Data Security Fee")]),
              _vm._v(" "),
              _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
              _vm._v(" "),
              _c("number-edit", {
                attrs: { step: "0.01" },
                model: {
                  value: _vm.currentTemplate.dataSecurityFee,
                  callback: function($$v) {
                    _vm.$set(_vm.currentTemplate, "dataSecurityFee", $$v)
                  },
                  expression: "currentTemplate.dataSecurityFee"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("label", [_vm._v("Wireless CDMA")]),
              _vm._v(" "),
              _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
              _vm._v(" "),
              _c("number-edit", {
                attrs: { step: "0.01" },
                model: {
                  value: _vm.currentTemplate.wirelessCDMA,
                  callback: function($$v) {
                    _vm.$set(_vm.currentTemplate, "wirelessCDMA", $$v)
                  },
                  expression: "currentTemplate.wirelessCDMA"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("label", [_vm._v("Wireless GPRS")]),
              _vm._v(" "),
              _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
              _vm._v(" "),
              _c("number-edit", {
                attrs: { step: "0.01" },
                model: {
                  value: _vm.currentTemplate.wirelessGPRS,
                  callback: function($$v) {
                    _vm.$set(_vm.currentTemplate, "wirelessGPRS", $$v)
                  },
                  expression: "currentTemplate.wirelessGPRS"
                }
              })
            ],
            1
          )
        ])
      ])
    ]),
    _vm._v(" "),
    _c("h2", [_vm._v("OTHER FEES")]),
    _vm._v(" "),
    _vm._m(2),
    _vm._v(" "),
    _c("ul", { staticClass: "fee-list fl3" }, [
      _c(
        "li",
        [
          _c("label", [_vm._v("Application Fee")]),
          _vm._v(" "),
          _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
          _vm._v(" "),
          _c("number-edit", {
            attrs: { step: "0.01" },
            model: {
              value: _vm.currentTemplate.applicationFee,
              callback: function($$v) {
                _vm.$set(_vm.currentTemplate, "applicationFee", $$v)
              },
              expression: "currentTemplate.applicationFee"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "li",
        [
          _c("label", [_vm._v("Account Updates")]),
          _vm._v(" "),
          _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
          _vm._v(" "),
          _c("number-edit", {
            attrs: { step: "0.01" },
            model: {
              value: _vm.currentTemplate.accountChanges,
              callback: function($$v) {
                _vm.$set(_vm.currentTemplate, "accountChanges", $$v)
              },
              expression: "currentTemplate.accountChanges"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "li",
        [
          _c("label", [_vm._v("Terminal Support")]),
          _vm._v(" "),
          _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
          _vm._v(" "),
          _c("number-edit", {
            attrs: { step: "0.01" },
            model: {
              value: _vm.currentTemplate.terminalSupport,
              callback: function($$v) {
                _vm.$set(_vm.currentTemplate, "terminalSupport", $$v)
              },
              expression: "currentTemplate.terminalSupport"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "li",
        [
          _c("label", [_vm._v("Account Setup")]),
          _vm._v(" "),
          _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
          _vm._v(" "),
          _c("number-edit", {
            attrs: { step: "0.01" },
            model: {
              value: _vm.currentTemplate.accountSetup,
              callback: function($$v) {
                _vm.$set(_vm.currentTemplate, "accountSetup", $$v)
              },
              expression: "currentTemplate.accountSetup"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "li",
        [
          _c("label", [_vm._v("EFT/ACH Fee")]),
          _vm._v(" "),
          _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
          _vm._v(" "),
          _c("number-edit", {
            attrs: { step: "0.01" },
            model: {
              value: _vm.currentTemplate.eftAchFee,
              callback: function($$v) {
                _vm.$set(_vm.currentTemplate, "eftAchFee", $$v)
              },
              expression: "currentTemplate.eftAchFee"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "li",
        [
          _c("label", [_vm._v("Equipment Upgrade Fee")]),
          _vm._v(" "),
          _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
          _vm._v(" "),
          _c("number-edit", {
            attrs: { step: "0.01" },
            model: {
              value: _vm.currentTemplate.equipmentUpgradeFee,
              callback: function($$v) {
                _vm.$set(_vm.currentTemplate, "equipmentUpgradeFee", $$v)
              },
              expression: "currentTemplate.equipmentUpgradeFee"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "li",
        [
          _c("label", [_vm._v("Annual Fee")]),
          _vm._v(" "),
          _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
          _vm._v(" "),
          _c("number-edit", {
            attrs: { step: "0.01" },
            model: {
              value: _vm.currentTemplate.annualFee,
              callback: function($$v) {
                _vm.$set(_vm.currentTemplate, "annualFee", $$v)
              },
              expression: "currentTemplate.annualFee"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "li",
        [
          _c("label", [_vm._v("Wire Fee")]),
          _vm._v(" "),
          _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
          _vm._v(" "),
          _c("number-edit", {
            attrs: { step: "0.01" },
            model: {
              value: _vm.currentTemplate.wireFee,
              callback: function($$v) {
                _vm.$set(_vm.currentTemplate, "wireFee", $$v)
              },
              expression: "currentTemplate.wireFee"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "li",
        [
          _c("label", [_vm._v("Equipment Trade")]),
          _vm._v(" "),
          _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
          _vm._v(" "),
          _c("number-edit", {
            attrs: { step: "0.01" },
            model: {
              value: _vm.currentTemplate.equipmentTradeBuyBack,
              callback: function($$v) {
                _vm.$set(_vm.currentTemplate, "equipmentTradeBuyBack", $$v)
              },
              expression: "currentTemplate.equipmentTradeBuyBack"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("li"),
      _vm._v(" "),
      _c(
        "li",
        [
          _c("label", [_vm._v("International Wire Fee")]),
          _vm._v(" "),
          _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
          _vm._v(" "),
          _c("number-edit", {
            attrs: { step: "0.01" },
            model: {
              value: _vm.currentTemplate.internationalWireFee,
              callback: function($$v) {
                _vm.$set(_vm.currentTemplate, "internationalWireFee", $$v)
              },
              expression: "currentTemplate.internationalWireFee"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "li",
        [
          _c("label", [_vm._v("Finance Fee")]),
          _vm._v(" "),
          _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
          _vm._v(" "),
          _c("number-edit", {
            attrs: { step: "0.01" },
            model: {
              value: _vm.currentTemplate.financeFee,
              callback: function($$v) {
                _vm.$set(_vm.currentTemplate, "financeFee", $$v)
              },
              expression: "currentTemplate.financeFee"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("li"),
      _vm._v(" "),
      _c(
        "li",
        [
          _c("label", [_vm._v("Returned Item/NSF")]),
          _vm._v(" "),
          _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
          _vm._v(" "),
          _c("number-edit", {
            attrs: { step: "0.01" },
            model: {
              value: _vm.currentTemplate.returnItemNsf,
              callback: function($$v) {
                _vm.$set(_vm.currentTemplate, "returnItemNsf", $$v)
              },
              expression: "currentTemplate.returnItemNsf"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "li",
        [
          _c("label", [_vm._v("Equipment Swap Fee")]),
          _vm._v(" "),
          _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
          _vm._v(" "),
          _c("number-edit", {
            attrs: { step: "0.01" },
            model: {
              value: _vm.currentTemplate.equipmentSwapFee,
              callback: function($$v) {
                _vm.$set(_vm.currentTemplate, "equipmentSwapFee", $$v)
              },
              expression: "currentTemplate.equipmentSwapFee"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("li"),
      _vm._v(" "),
      _c("li"),
      _vm._v(" "),
      _c(
        "li",
        [
          _c("label", [_vm._v("Protection Plan")]),
          _vm._v(" "),
          _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
          _vm._v(" "),
          _c("number-edit", {
            attrs: { step: "0.01" },
            model: {
              value: _vm.currentTemplate.protectionPlan,
              callback: function($$v) {
                _vm.$set(_vm.currentTemplate, "protectionPlan", $$v)
              },
              expression: "currentTemplate.protectionPlan"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("li"),
      _vm._v(" "),
      _c("li"),
      _vm._v(" "),
      _c(
        "li",
        [
          _c("label", [_vm._v("Platinum Protection Plan")]),
          _vm._v(" "),
          _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
          _vm._v(" "),
          _c("number-edit", {
            attrs: { step: "0.01" },
            model: {
              value: _vm.currentTemplate.protectionPlanPlatinum,
              callback: function($$v) {
                _vm.$set(_vm.currentTemplate, "protectionPlanPlatinum", $$v)
              },
              expression: "currentTemplate.protectionPlanPlatinum"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("li"),
      _vm._v(" "),
      _c("li"),
      _vm._v(" "),
      _c(
        "li",
        [
          _c("label", [_vm._v("CB OnSite")]),
          _vm._v(" "),
          _c("span", { staticClass: "prefix" }, [_vm._v("$")]),
          _vm._v(" "),
          _c("number-edit", {
            attrs: { step: "0.01" },
            model: {
              value: _vm.currentTemplate.cbOnSite,
              callback: function($$v) {
                _vm.$set(_vm.currentTemplate, "cbOnSite", $$v)
              },
              expression: "currentTemplate.cbOnSite"
            }
          }),
          _vm._v(" "),
          _c("span", { staticClass: "suffix" }, [_vm._v("/HR")])
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("h2", [_vm._v("SPECIAL OFFERS")]),
    _vm._v(" "),
    _c("ul", { staticClass: "pricing-list" }, [
      _c("li", [
        _c("label", [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.currentTemplate.freeTerminal,
                expression: "currentTemplate.freeTerminal"
              }
            ],
            attrs: { type: "checkbox" },
            domProps: {
              checked: Array.isArray(_vm.currentTemplate.freeTerminal)
                ? _vm._i(_vm.currentTemplate.freeTerminal, null) > -1
                : _vm.currentTemplate.freeTerminal
            },
            on: {
              change: function($event) {
                var $$a = _vm.currentTemplate.freeTerminal,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 &&
                      _vm.$set(
                        _vm.currentTemplate,
                        "freeTerminal",
                        $$a.concat([$$v])
                      )
                  } else {
                    $$i > -1 &&
                      _vm.$set(
                        _vm.currentTemplate,
                        "freeTerminal",
                        $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                      )
                  }
                } else {
                  _vm.$set(_vm.currentTemplate, "freeTerminal", $$c)
                }
              }
            }
          }),
          _c("em"),
          _vm._v("Free Terminal Offer")
        ]),
        _vm._v(" "),
        _vm.currentTemplate.freeTerminal
          ? _c("ul", [
              _c(
                "li",
                [
                  _c("span", { staticClass: "prefix" }, [_vm._v("#")]),
                  _vm._v(" "),
                  _c("number-edit", {
                    attrs: {
                      step: "0.01",
                      disabled: !_vm.currentTemplate.freeTerminal
                    },
                    model: {
                      value: _vm.currentTemplate.freeTerminalIncluded,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.currentTemplate,
                          "freeTerminalIncluded",
                          $$v
                        )
                      },
                      expression: "currentTemplate.freeTerminalIncluded"
                    }
                  })
                ],
                1
              )
            ])
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("em"), _vm._v("Access Plan")])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("em"), _vm._v("Price")])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "fee-types" }, [
      _c("div", [_c("h3", [_vm._v("Per Occurrence")])])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }