var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "preform-outer" }, [
    _c("div", { staticClass: "preformbg agent" }),
    _vm._v(" "),
    _c("div", { staticClass: "appf preform ncontent pfx" }, [
      _c(
        "div",
        { staticClass: "wcontent" },
        [
          _c(
            "div",
            {
              staticClass: "cb-logo intake",
              style: {
                "background-image":
                  'url("/files/company/' + _vm.companydata.intakeHeadLogo + '")'
              }
            },
            [_vm._v("CardBiller")]
          ),
          _vm._v(" "),
          _c("h1", [_vm._v("Merchant Profile Form")]),
          _vm._v(" "),
          _c("div", { staticClass: "form-info" }, [
            _c("p", [_vm._v(_vm._s(_vm.companydata.intakeHeadText))]),
            _vm._v(" "),
            _c("p", [
              _c("strong", [_vm._v(_vm._s(_vm.companydata.intakeHeadEmail))])
            ]),
            _vm._v(" "),
            _c("p", [
              _c("strong", [_vm._v(_vm._s(_vm.companydata.intakeHeadPhone))])
            ])
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "form-intro" }, [
            _vm._v("\n    " + _vm._s(_vm.companydata.intakeHeadDesc) + "\n  ")
          ]),
          _vm._v(" "),
          _vm._l([_vm.item.formData], function(q) {
            return _vm.step < 4
              ? _c("form", { staticClass: "form-step h3lg quest intake" }, [
                  _c(
                    "section",
                    [
                      _c("h2", [
                        _vm._v(
                          "Required to be completed by Partner prior to underwriting"
                        )
                      ]),
                      _vm._v(" "),
                      _c("h3", [_vm._v("General")]),
                      _vm._v(" "),
                      _c("ul", { staticClass: "column-form type2" }, [
                        _c("li", [
                          _c("label", [_vm._v("Solution")]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "field" },
                            [
                              _c(
                                "validator",
                                {
                                  attrs: {
                                    value: _vm.item.solution,
                                    reqs: "required"
                                  }
                                },
                                [
                                  _c("dyn-select", {
                                    attrs: {
                                      disabled: "",
                                      options: _vm.solutions
                                    },
                                    model: {
                                      value: _vm.item.solution,
                                      callback: function($$v) {
                                        _vm.$set(_vm.item, "solution", $$v)
                                      },
                                      expression: "item.solution"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c("label", [_vm._v("Submit Date")]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "field" },
                            [
                              _c(
                                "validator",
                                {
                                  attrs: {
                                    value: _vm.item.submitted,
                                    reqs: "required"
                                  }
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.item.submitted,
                                        expression: "item.submitted"
                                      }
                                    ],
                                    attrs: { type: "text", disabled: "" },
                                    domProps: { value: _vm.item.submitted },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.item,
                                          "submitted",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c("label", [_vm._v("MCC Code")]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "field" },
                            [
                              _c(
                                "validator",
                                { attrs: { value: q.mcc, reqs: "required" } },
                                [
                                  _c("mcc-edit", {
                                    attrs: { short: true },
                                    model: {
                                      value: q.mcc,
                                      callback: function($$v) {
                                        _vm.$set(q, "mcc", $$v)
                                      },
                                      expression: "q.mcc"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c("li")
                      ]),
                      _vm._v(" "),
                      _c("h3", [_vm._v("Partner Information")]),
                      _vm._v(" "),
                      _c("ul", { staticClass: "column-form type2" }, [
                        _c("li", [
                          _c("label", [_vm._v("Partner ID")]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "field" },
                            [
                              _c(
                                "validator",
                                { attrs: { value: "", reqs: "" } },
                                [
                                  _c("input", {
                                    attrs: { readonly: "", type: "text" },
                                    domProps: {
                                      value: q.source && q.source.agentId
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c("label", [_vm._v("Partner Company")]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "field" },
                            [
                              _c(
                                "validator",
                                { attrs: { value: "", reqs: "" } },
                                [
                                  _c("input", {
                                    attrs: { readonly: "", type: "text" },
                                    domProps: {
                                      value:
                                        q.source &&
                                        q.source.agentDb &&
                                        q.source.agentDb.name
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c("label", [_vm._v("Rep Name")]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "field" },
                            [
                              _c(
                                "validator",
                                { attrs: { value: "", reqs: "" } },
                                [
                                  _c("input", {
                                    attrs: { readonly: "", type: "text" },
                                    domProps: {
                                      value:
                                        q.source &&
                                        q.source.contacts &&
                                        q.source.contacts[0] &&
                                        q.source.contacts[0].firstName +
                                          " " +
                                          q.source.contacts[0].lastName
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c("li")
                      ]),
                      _vm._v(" "),
                      _c("h3", [
                        _vm._v("Merchant Information"),
                        _c("span", [
                          _vm._v("ACCOUNT:"),
                          _c("em", [_vm._v(_vm._s(q.accountId))])
                        ])
                      ]),
                      _vm._v(" "),
                      _c("ul", { staticClass: "column-form type2" }, [
                        _c("li", [
                          _c("label", [_vm._v("Business Name")]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "field" },
                            [
                              _c(
                                "validator",
                                { attrs: { value: q.companyName, reqs: "" } },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: q.companyName,
                                        expression: "q.companyName"
                                      }
                                    ],
                                    attrs: { readonly: "", type: "text" },
                                    domProps: { value: q.companyName },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          q,
                                          "companyName",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c("label", [_vm._v("Business DBA Name")]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "field" },
                            [
                              _c(
                                "validator",
                                {
                                  attrs: { value: q.companyNameDba, reqs: "" }
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: q.companyNameTrade,
                                        expression: "q.companyNameTrade"
                                      }
                                    ],
                                    attrs: { readonly: "", type: "text" },
                                    domProps: { value: q.companyNameTrade },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          q,
                                          "companyNameTrade",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c("label", [_vm._v("Country")]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "field" },
                            [
                              _c(
                                "validator",
                                {
                                  attrs: {
                                    value: q.companyAddressCountry,
                                    reqs: ""
                                  }
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: q.companyAddressCountry,
                                        expression: "q.companyAddressCountry"
                                      }
                                    ],
                                    attrs: { readonly: "", type: "text" },
                                    domProps: {
                                      value: q.companyAddressCountry
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          q,
                                          "companyAddressCountry",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c("label", [_vm._v("Contact")]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "field" },
                            [
                              _c(
                                "validator",
                                {
                                  attrs: { value: q.companyNameDba, reqs: "" }
                                },
                                [
                                  _c("input", {
                                    attrs: { readonly: "", type: "text" },
                                    domProps: {
                                      value:
                                        q.contacts &&
                                        q.contacts[0].firstName +
                                          " " +
                                          q.contacts[0].lastName
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c("label", [_vm._v("Email")]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "field" },
                            [
                              _c(
                                "validator",
                                { attrs: { value: q.companyEmail, reqs: "" } },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: q.companyEmail,
                                        expression: "q.companyEmail"
                                      }
                                    ],
                                    attrs: { readonly: "", type: "text" },
                                    domProps: { value: q.companyEmail },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          q,
                                          "companyEmail",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c("label", [_vm._v("Phone")]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "field" },
                            [
                              _c(
                                "validator",
                                { attrs: { value: q.companyPhone, reqs: "" } },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: q.companyPhone,
                                        expression: "q.companyPhone"
                                      }
                                    ],
                                    attrs: { readonly: "", type: "text" },
                                    domProps: { value: q.companyPhone },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          q,
                                          "companyPhone",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c("li"),
                        _vm._v(" "),
                        _c("li")
                      ]),
                      _vm._v(" "),
                      _c("h3", [_vm._v("Site Survey")]),
                      _vm._v(" "),
                      _c("ul", { staticClass: "column-form type2" }, [
                        _c("li", { staticClass: "fwidth fwx" }, [
                          _c("label", [_vm._v("Zoning")]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "field" },
                            [
                              _c(
                                "validator",
                                {
                                  attrs: {
                                    value: q.zoning && q.zoning.length,
                                    reqs: "required"
                                  }
                                },
                                [
                                  _c("multiselect", {
                                    attrs: {
                                      options: [
                                        "Business",
                                        "Industrial",
                                        "Residential"
                                      ],
                                      onlyone: true
                                    },
                                    model: {
                                      value: q.zoning,
                                      callback: function($$v) {
                                        _vm.$set(q, "zoning", $$v)
                                      },
                                      expression: "q.zoning"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", { staticClass: "fwidth fwx" }, [
                          _c("label", [_vm._v("Location")]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "field" },
                            [
                              _c(
                                "validator",
                                {
                                  attrs: {
                                    value: q.location && q.location.length,
                                    reqs: "required"
                                  }
                                },
                                [
                                  _c("multiselect", {
                                    attrs: {
                                      options: [
                                        "Mall",
                                        "Office",
                                        "Shopping Area",
                                        "Mix",
                                        "Home",
                                        "Apt",
                                        "Isolated"
                                      ],
                                      onlyone: true
                                    },
                                    model: {
                                      value: q.location,
                                      callback: function($$v) {
                                        _vm.$set(q, "location", $$v)
                                      },
                                      expression: "q.location"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", { staticClass: "fwidth fwx" }, [
                          _c("label", [_vm._v("Sq Ft")]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "field" },
                            [
                              _c(
                                "validator",
                                {
                                  attrs: {
                                    value: q.sqft && q.sqft.length,
                                    reqs: "required"
                                  }
                                },
                                [
                                  _c("multiselect", {
                                    attrs: {
                                      options: [
                                        "0-250",
                                        "251-500",
                                        "501-2000",
                                        "2001+"
                                      ],
                                      onlyone: true
                                    },
                                    model: {
                                      value: q.sqft,
                                      callback: function($$v) {
                                        _vm.$set(q, "sqft", $$v)
                                      },
                                      expression: "q.sqft"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("h3", [_vm._v("Pricing template")]),
                      _vm._v(" "),
                      _c("ul", { staticClass: "column-form type2" }, [
                        _c("li", { staticClass: "fwidth fwx" }, [
                          _c(
                            "div",
                            { staticClass: "field" },
                            [
                              _c("dyn-select", {
                                staticStyle: {
                                  width: "100%",
                                  "margin-bottom": "20px"
                                },
                                attrs: { options: _vm.currentTemplates },
                                model: {
                                  value: _vm.templateId,
                                  callback: function($$v) {
                                    _vm.templateId = $$v
                                  },
                                  expression: "templateId"
                                }
                              })
                            ],
                            1
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("pricing-details", {
                        staticClass: "intake-pricing",
                        attrs: { currentTemplate: q }
                      }),
                      _vm._v(" "),
                      false ? _c("h3", [_vm._v("Payment Methods")]) : _vm._e(),
                      _vm._v(" "),
                      false
                        ? _c("ul", { staticClass: "column-form type2" }, [
                            _c("li", { staticClass: "fwidth fwx" }, [
                              _c("label", [_vm._v("CardBiller")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field" },
                                [
                                  _c(
                                    "validator",
                                    {
                                      attrs: {
                                        value:
                                          (q.cardbiller &&
                                            q.cardbiller.length) ||
                                          (q.cardbillerPlus &&
                                            q.cardbillerPlus.length) ||
                                          (q.bankbiller &&
                                            q.bankbiller.length) ||
                                          (q.coinbiller && q.coinbiller.length),
                                        reqs: "boolean"
                                      }
                                    },
                                    [
                                      _c("multiselect", {
                                        attrs: {
                                          options: [
                                            "Visa",
                                            "MasterCard",
                                            "Discover",
                                            "AMEX",
                                            "China UnionPay",
                                            "Interac Debit"
                                          ]
                                        },
                                        model: {
                                          value: q.cardbiller,
                                          callback: function($$v) {
                                            _vm.$set(q, "cardbiller", $$v)
                                          },
                                          expression: "q.cardbiller"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", { staticClass: "fwidth fwx" }, [
                              _c("label", [_vm._v("CardBiller Plus")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field" },
                                [
                                  _c(
                                    "validator",
                                    {
                                      attrs: {
                                        value:
                                          (q.cardbiller &&
                                            q.cardbiller.length) ||
                                          (q.cardbillerPlus &&
                                            q.cardbillerPlus.length) ||
                                          (q.bankbiller &&
                                            q.bankbiller.length) ||
                                          (q.coinbiller && q.coinbiller.length),
                                        reqs: "boolean"
                                      }
                                    },
                                    [
                                      _c("multiselect", {
                                        attrs: {
                                          options: [
                                            "Apple Pay",
                                            "Google Pay",
                                            "Interac Online"
                                          ]
                                        },
                                        model: {
                                          value: q.cardbillerPlus,
                                          callback: function($$v) {
                                            _vm.$set(q, "cardbillerPlus", $$v)
                                          },
                                          expression: "q.cardbillerPlus"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", { staticClass: "fwidth fwx" }, [
                              _c("label", [_vm._v("BankBiller")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field" },
                                [
                                  _c(
                                    "validator",
                                    {
                                      attrs: {
                                        value:
                                          (q.cardbiller &&
                                            q.cardbiller.length) ||
                                          (q.cardbillerPlus &&
                                            q.cardbillerPlus.length) ||
                                          (q.bankbiller &&
                                            q.bankbiller.length) ||
                                          (q.coinbiller && q.coinbiller.length),
                                        reqs: "boolean"
                                      }
                                    },
                                    [
                                      _c("multiselect", {
                                        attrs: { options: ["EFT", "ACH"] },
                                        model: {
                                          value: q.bankbiller,
                                          callback: function($$v) {
                                            _vm.$set(q, "bankbiller", $$v)
                                          },
                                          expression: "q.bankbiller"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            false
                              ? _c("li", { staticClass: "fwidth fwx" }, [
                                  _c("label", [_vm._v("CoinBiller")]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "field" },
                                    [
                                      _c(
                                        "validator",
                                        {
                                          attrs: {
                                            value:
                                              (q.cardbiller &&
                                                q.cardbiller.length) ||
                                              (q.cardbillerPlus &&
                                                q.cardbillerPlus.length) ||
                                              (q.bankbiller &&
                                                q.bankbiller.length) ||
                                              (q.coinbiller &&
                                                q.coinbiller.length),
                                            reqs: "boolean"
                                          }
                                        },
                                        [
                                          _c("multiselect", {
                                            attrs: { options: ["BitCoin"] },
                                            model: {
                                              value: q.coinbiller,
                                              callback: function($$v) {
                                                _vm.$set(q, "coinbiller", $$v)
                                              },
                                              expression: "q.coinbiller"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("li", { staticClass: "fwidth fwx" }, [
                              _c("label", [_vm._v("Other")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field" },
                                [
                                  _c(
                                    "validator",
                                    {
                                      attrs: {
                                        value: q.cardTypesOther,
                                        reqs: ""
                                      }
                                    },
                                    [
                                      _c(
                                        "multiselect-desc",
                                        {
                                          attrs: { options: [""] },
                                          model: {
                                            value: q.cardTypesOtherFlag,
                                            callback: function($$v) {
                                              _vm.$set(
                                                q,
                                                "cardTypesOtherFlag",
                                                $$v
                                              )
                                            },
                                            expression: "q.cardTypesOtherFlag"
                                          }
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: q.cardTypesOther,
                                                expression: "q.cardTypesOther"
                                              }
                                            ],
                                            attrs: {
                                              type: "text",
                                              disabled:
                                                !q.cardTypesOtherFlag ||
                                                q.cardTypesOtherFlag.length == 0
                                            },
                                            domProps: {
                                              value: q.cardTypesOther
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  q,
                                                  "cardTypesOther",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      false
                        ? _c("h3", [_vm._v("Pricing & Options")])
                        : _vm._e(),
                      _vm._v(" "),
                      false
                        ? _c("ul", { staticClass: "column-form type2" }, [
                            _c("li", { staticClass: "fwidth fwx" }, [
                              _c("label", [_vm._v("Monthly Plan")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field" },
                                [
                                  _c(
                                    "validator",
                                    {
                                      attrs: {
                                        value:
                                          q.marketingMethod &&
                                          q.marketingMethod.length
                                            ? q.marketingMethod
                                            : q.marketingMethodOther,
                                        reqs: "required"
                                      }
                                    },
                                    [
                                      _c("multiselect", {
                                        attrs: {
                                          other: q.marketingMethodOther,
                                          options: [
                                            "Simple",
                                            "Prime",
                                            "Axis (E-Commerce)"
                                          ],
                                          onlyone: true
                                        },
                                        on: {
                                          "update:other": function($event) {
                                            return _vm.$set(
                                              q,
                                              "marketingMethodOther",
                                              $event
                                            )
                                          }
                                        },
                                        model: {
                                          value: q.marketingMethod,
                                          callback: function($$v) {
                                            _vm.$set(q, "marketingMethod", $$v)
                                          },
                                          expression: "q.marketingMethod"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", { staticClass: "intake-title" }, [
                              _vm._v("Service Fees")
                            ]),
                            _vm._v(" "),
                            _c("li", {}, [
                              _c("label", [_vm._v("Setup Fee")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field complex" },
                                [
                                  _c(
                                    "validator",
                                    { attrs: { value: q.setupFee, reqs: "" } },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "intake-field" },
                                        [
                                          _c("formel-money-amount", {
                                            attrs: {
                                              type: "text",
                                              prefix: "$"
                                            },
                                            model: {
                                              value: q.setupFee,
                                              callback: function($$v) {
                                                _vm.$set(q, "setupFee", $$v)
                                              },
                                              expression: "q.setupFee"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", {}, [
                              _c("label", [_vm._v("Annual Fee")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field complex" },
                                [
                                  _c(
                                    "validator",
                                    {
                                      attrs: {
                                        value: q.depositRequired,
                                        reqs: ""
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "intake-field" },
                                        [
                                          _c("formel-money-amount", {
                                            attrs: {
                                              type: "text",
                                              prefix: "$"
                                            },
                                            model: {
                                              value: q.annualFee,
                                              callback: function($$v) {
                                                _vm.$set(q, "annualFee", $$v)
                                              },
                                              expression: "q.annualFee"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li"),
                            _c("li"),
                            _vm._v(" "),
                            _c("li", { staticClass: "intake-title" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "checkbox",
                                  class: { checked: q.pricingOption == "flat" }
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: q.pricingOption,
                                        expression: "q.pricingOption"
                                      }
                                    ],
                                    attrs: { type: "radio", value: "flat" },
                                    domProps: {
                                      checked: _vm._q(q.pricingOption, "flat")
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.deselectSelected(
                                          "pricingOption",
                                          "flat"
                                        )
                                      },
                                      change: function($event) {
                                        return _vm.$set(
                                          q,
                                          "pricingOption",
                                          "flat"
                                        )
                                      }
                                    }
                                  }),
                                  _c("span"),
                                  _vm._v("Flat Rate")
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", {}, [
                              _c("label", [
                                _vm._v("Per Swiped / Tapped / Inserted")
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field complex" },
                                [
                                  _c(
                                    "validator",
                                    {
                                      attrs: {
                                        value: q.depositRequired,
                                        reqs: ""
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "intake-field split" },
                                        [
                                          _c("formel-money-amount", {
                                            attrs: {
                                              disabled:
                                                q.pricingOption != "flat",
                                              type: "text",
                                              suffix: "%"
                                            },
                                            model: {
                                              value: q.flatInserted,
                                              callback: function($$v) {
                                                _vm.$set(q, "flatInserted", $$v)
                                              },
                                              expression: "q.flatInserted"
                                            }
                                          }),
                                          _vm._v(" + "),
                                          _c("formel-money-amount", {
                                            attrs: {
                                              disabled:
                                                q.pricingOption != "flat",
                                              type: "text",
                                              prefix: "$"
                                            },
                                            model: {
                                              value: q.flatInsertedFee,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  q,
                                                  "flatInsertedFee",
                                                  $$v
                                                )
                                              },
                                              expression: "q.flatInsertedFee"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", {}, [
                              _c("label", [_vm._v("E-Commerce / Keyed")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field complex" },
                                [
                                  _c(
                                    "validator",
                                    {
                                      attrs: {
                                        value: q.depositRequired,
                                        reqs: ""
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "intake-field split" },
                                        [
                                          _c("formel-money-amount", {
                                            attrs: {
                                              disabled:
                                                q.pricingOption != "flat",
                                              type: "text",
                                              suffix: "%"
                                            },
                                            model: {
                                              value: q.flatKeyed,
                                              callback: function($$v) {
                                                _vm.$set(q, "flatKeyed", $$v)
                                              },
                                              expression: "q.flatKeyed"
                                            }
                                          }),
                                          _vm._v(" + "),
                                          _c("formel-money-amount", {
                                            attrs: {
                                              disabled:
                                                q.pricingOption != "flat",
                                              type: "text",
                                              prefix: "$"
                                            },
                                            model: {
                                              value: q.flatKeyedFee,
                                              callback: function($$v) {
                                                _vm.$set(q, "flatKeyedFee", $$v)
                                              },
                                              expression: "q.flatKeyedFee"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li"),
                            _c("li"),
                            _vm._v(" "),
                            _c("li", { staticClass: "intake-title" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "checkbox",
                                  class: { checked: q.pricingOption == "iplus" }
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: q.pricingOption,
                                        expression: "q.pricingOption"
                                      }
                                    ],
                                    attrs: { type: "radio", value: "iplus" },
                                    domProps: {
                                      checked: _vm._q(q.pricingOption, "iplus")
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.deselectSelected(
                                          "pricingOption",
                                          "iplus"
                                        )
                                      },
                                      change: function($event) {
                                        return _vm.$set(
                                          q,
                                          "pricingOption",
                                          "iplus"
                                        )
                                      }
                                    }
                                  }),
                                  _c("span"),
                                  _vm._v("Interchange Plus")
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", {}, [
                              _c("label", [
                                _vm._v("Per Swiped / Tapped / Inserted")
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field complex" },
                                [
                                  _c(
                                    "validator",
                                    {
                                      attrs: {
                                        value: q.depositRequired,
                                        reqs: ""
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "intake-field split" },
                                        [
                                          _c("formel-money-amount", {
                                            attrs: {
                                              disabled:
                                                q.pricingOption != "iplus",
                                              type: "text",
                                              suffix: "%"
                                            },
                                            model: {
                                              value: q.iplusInserted,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  q,
                                                  "iplusInserted",
                                                  $$v
                                                )
                                              },
                                              expression: "q.iplusInserted"
                                            }
                                          }),
                                          _vm._v(" + "),
                                          _c("formel-money-amount", {
                                            attrs: {
                                              disabled:
                                                q.pricingOption != "iplus",
                                              type: "text",
                                              prefix: "$"
                                            },
                                            model: {
                                              value: q.iplusInsertedFee,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  q,
                                                  "iplusInsertedFee",
                                                  $$v
                                                )
                                              },
                                              expression: "q.iplusInsertedFee"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", {}, [
                              _c("label", [_vm._v("E-Commerce / Keyed")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field complex" },
                                [
                                  _c(
                                    "validator",
                                    {
                                      attrs: {
                                        value: q.depositRequired,
                                        reqs: ""
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "intake-field split" },
                                        [
                                          _c("formel-money-amount", {
                                            attrs: {
                                              disabled:
                                                q.pricingOption != "iplus",
                                              type: "text",
                                              suffix: "%"
                                            },
                                            model: {
                                              value: q.iplusKeyed,
                                              callback: function($$v) {
                                                _vm.$set(q, "iplusKeyed", $$v)
                                              },
                                              expression: "q.iplusKeyed"
                                            }
                                          }),
                                          _vm._v(" + "),
                                          _c("formel-money-amount", {
                                            attrs: {
                                              disabled:
                                                q.pricingOption != "iplus",
                                              type: "text",
                                              prefix: "$"
                                            },
                                            model: {
                                              value: q.iplusKeyedFee,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  q,
                                                  "iplusKeyedFee",
                                                  $$v
                                                )
                                              },
                                              expression: "q.iplusKeyedFee"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li"),
                            _c("li"),
                            _vm._v(" "),
                            _c("li", { staticClass: "intake-title" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "checkbox",
                                  class: {
                                    checked: q.pricingOption == "tiered"
                                  }
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: q.pricingOption,
                                        expression: "q.pricingOption"
                                      }
                                    ],
                                    attrs: { type: "radio", value: "tiered" },
                                    domProps: {
                                      checked: _vm._q(q.pricingOption, "tiered")
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.deselectSelected(
                                          "pricingOption",
                                          "tiered"
                                        )
                                      },
                                      change: function($event) {
                                        return _vm.$set(
                                          q,
                                          "pricingOption",
                                          "tiered"
                                        )
                                      }
                                    }
                                  }),
                                  _c("span"),
                                  _vm._v("Tiered")
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", {}, [
                              _c("label", [_vm._v("Qualified")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field complex" },
                                [
                                  _c(
                                    "validator",
                                    {
                                      attrs: {
                                        value: q.depositRequired,
                                        reqs: ""
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "intake-field" },
                                        [
                                          _c("formel-money-amount", {
                                            attrs: {
                                              disabled:
                                                q.pricingOption != "tiered",
                                              type: "text",
                                              suffix: "%"
                                            },
                                            model: {
                                              value: q.tieredQualified,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  q,
                                                  "tieredQualified",
                                                  $$v
                                                )
                                              },
                                              expression: "q.tieredQualified"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", {}, [
                              _c("label", [_vm._v("Mid-Qualified")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field complex" },
                                [
                                  _c(
                                    "validator",
                                    {
                                      attrs: {
                                        value: q.depositRequired,
                                        reqs: ""
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "intake-field" },
                                        [
                                          _c("formel-money-amount", {
                                            attrs: {
                                              disabled:
                                                q.pricingOption != "tiered",
                                              type: "text",
                                              suffix: "%"
                                            },
                                            model: {
                                              value: q.tieredMidQualified,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  q,
                                                  "tieredMidQualified",
                                                  $$v
                                                )
                                              },
                                              expression: "q.tieredMidQualified"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", {}, [
                              _c("label", [_vm._v("Non-Qualified")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field complex" },
                                [
                                  _c(
                                    "validator",
                                    {
                                      attrs: {
                                        value: q.depositRequired,
                                        reqs: ""
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "intake-field" },
                                        [
                                          _c("formel-money-amount", {
                                            attrs: {
                                              disabled:
                                                q.pricingOption != "tiered",
                                              type: "text",
                                              suffix: "%"
                                            },
                                            model: {
                                              value: q.tieredNonQualified,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  q,
                                                  "tieredNonQualified",
                                                  $$v
                                                )
                                              },
                                              expression: "q.tieredNonQualified"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", {}, [
                              _c("label", [_vm._v("Auth Fee")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field complex" },
                                [
                                  _c(
                                    "validator",
                                    {
                                      attrs: {
                                        value: q.depositRequired,
                                        reqs: ""
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "intake-field" },
                                        [
                                          _c("formel-money-amount", {
                                            attrs: {
                                              disabled:
                                                q.pricingOption != "tiered",
                                              type: "text",
                                              prefix: "$"
                                            },
                                            model: {
                                              value: q.tieredAuthFee,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  q,
                                                  "tieredAuthFee",
                                                  $$v
                                                )
                                              },
                                              expression: "q.tieredAuthFee"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", { staticClass: "intake-title" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "checkbox",
                                  class: { checked: q.optInteracDebit }
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: q.optInteracDebit,
                                        expression: "q.optInteracDebit"
                                      }
                                    ],
                                    attrs: { type: "checkbox" },
                                    domProps: {
                                      checked: Array.isArray(q.optInteracDebit)
                                        ? _vm._i(q.optInteracDebit, null) > -1
                                        : q.optInteracDebit
                                    },
                                    on: {
                                      change: function($event) {
                                        var $$a = q.optInteracDebit,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                q,
                                                "optInteracDebit",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                q,
                                                "optInteracDebit",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(q, "optInteracDebit", $$c)
                                        }
                                      }
                                    }
                                  }),
                                  _c("span"),
                                  _vm._v("Interac Debit")
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", {}, [
                              _c("label", [_vm._v("Per Swiped / Inserted")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field complex" },
                                [
                                  _c(
                                    "validator",
                                    {
                                      attrs: {
                                        value: q.depositRequired,
                                        reqs: ""
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "intake-field" },
                                        [
                                          _c("formel-money-amount", {
                                            attrs: {
                                              disabled: !q.optInteracDebit,
                                              type: "text",
                                              prefix: "$",
                                              digits: 3
                                            },
                                            model: {
                                              value: q.interacInserted,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  q,
                                                  "interacInserted",
                                                  $$v
                                                )
                                              },
                                              expression: "q.interacInserted"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", {}, [
                              _c("label", [_vm._v("Tapped/Flash")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field complex" },
                                [
                                  _c(
                                    "validator",
                                    {
                                      attrs: {
                                        value: q.depositRequired,
                                        reqs: ""
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "intake-field" },
                                        [
                                          _c("formel-money-amount", {
                                            attrs: {
                                              disabled: !q.optInteracDebit,
                                              type: "text",
                                              prefix: "$",
                                              digits: 3
                                            },
                                            model: {
                                              value: q.interacTapped,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  q,
                                                  "interacTapped",
                                                  $$v
                                                )
                                              },
                                              expression: "q.interacTapped"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li"),
                            _c("li"),
                            _vm._v(" "),
                            _c("li", { staticClass: "intake-title" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "checkbox",
                                  class: { checked: q.optCardbillerPlus }
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: q.optCardbillerPlus,
                                        expression: "q.optCardbillerPlus"
                                      }
                                    ],
                                    attrs: { type: "checkbox" },
                                    domProps: {
                                      checked: Array.isArray(
                                        q.optCardbillerPlus
                                      )
                                        ? _vm._i(q.optCardbillerPlus, null) > -1
                                        : q.optCardbillerPlus
                                    },
                                    on: {
                                      change: function($event) {
                                        var $$a = q.optCardbillerPlus,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                q,
                                                "optCardbillerPlus",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                q,
                                                "optCardbillerPlus",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(q, "optCardbillerPlus", $$c)
                                        }
                                      }
                                    }
                                  }),
                                  _c("span"),
                                  _vm._v("CardBiller Plus")
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", {}, [
                              _c("label", [_vm._v("Apple Pay / Google Pay")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field complex" },
                                [
                                  _c(
                                    "validator",
                                    {
                                      attrs: {
                                        value: q.depositRequired,
                                        reqs: ""
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "intake-field split" },
                                        [
                                          _c("formel-money-amount", {
                                            attrs: {
                                              disabled: !q.optCardbillerPlus,
                                              type: "text",
                                              suffix: "%"
                                            },
                                            model: {
                                              value: q.cbPlusPay,
                                              callback: function($$v) {
                                                _vm.$set(q, "cbPlusPay", $$v)
                                              },
                                              expression: "q.cbPlusPay"
                                            }
                                          }),
                                          _vm._v(" + "),
                                          _c("formel-money-amount", {
                                            attrs: {
                                              disabled: !q.optCardbillerPlus,
                                              type: "text",
                                              prefix: "$"
                                            },
                                            model: {
                                              value: q.cbPlusPayFee,
                                              callback: function($$v) {
                                                _vm.$set(q, "cbPlusPayFee", $$v)
                                              },
                                              expression: "q.cbPlusPayFee"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", {}, [
                              _c("label", [_vm._v("Interac Online")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field complex" },
                                [
                                  _c(
                                    "validator",
                                    {
                                      attrs: {
                                        value: q.depositRequired,
                                        reqs: ""
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "intake-field" },
                                        [
                                          _c("formel-money-amount", {
                                            attrs: {
                                              disabled: !q.optCardbillerPlus,
                                              type: "text",
                                              prefix: "$"
                                            },
                                            model: {
                                              value: q.cbPlusInterac,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  q,
                                                  "cbPlusInterac",
                                                  $$v
                                                )
                                              },
                                              expression: "q.cbPlusInterac"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li"),
                            _c("li"),
                            _vm._v(" "),
                            _c("li", { staticClass: "intake-title" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "checkbox",
                                  class: { checked: q.optBankbiller }
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: q.optBankbiller,
                                        expression: "q.optBankbiller"
                                      }
                                    ],
                                    attrs: { type: "checkbox" },
                                    domProps: {
                                      checked: Array.isArray(q.optBankbiller)
                                        ? _vm._i(q.optBankbiller, null) > -1
                                        : q.optBankbiller
                                    },
                                    on: {
                                      change: function($event) {
                                        var $$a = q.optBankbiller,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                q,
                                                "optBankbiller",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                q,
                                                "optBankbiller",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(q, "optBankbiller", $$c)
                                        }
                                      }
                                    }
                                  }),
                                  _c("span"),
                                  _vm._v("BankBiller")
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", {}, [
                              _c("label", [_vm._v("Monthly Fee")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field complex" },
                                [
                                  _c(
                                    "validator",
                                    {
                                      attrs: {
                                        value: q.depositRequired,
                                        reqs: ""
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "intake-field" },
                                        [
                                          _c("formel-money-amount", {
                                            attrs: {
                                              disabled: !q.optBankbiller,
                                              type: "text",
                                              prefix: "$"
                                            },
                                            model: {
                                              value: q.bbMonthly,
                                              callback: function($$v) {
                                                _vm.$set(q, "bbMonthly", $$v)
                                              },
                                              expression: "q.bbMonthly"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", {}, [
                              _c("label", [_vm._v("Discount Rate")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field complex" },
                                [
                                  _c(
                                    "validator",
                                    {
                                      attrs: {
                                        value: q.depositRequired,
                                        reqs: ""
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "intake-field" },
                                        [
                                          _c("formel-money-amount", {
                                            attrs: {
                                              disabled: !q.optBankbiller,
                                              type: "text",
                                              suffix: "%"
                                            },
                                            model: {
                                              value: q.bbDiscount,
                                              callback: function($$v) {
                                                _vm.$set(q, "bbDiscount", $$v)
                                              },
                                              expression: "q.bbDiscount"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", {}, [
                              _c("label", [_vm._v("Trx Fee")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field complex" },
                                [
                                  _c(
                                    "validator",
                                    {
                                      attrs: {
                                        value: q.depositRequired,
                                        reqs: ""
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "intake-field" },
                                        [
                                          _c("formel-money-amount", {
                                            attrs: {
                                              disabled: !q.optBankbiller,
                                              type: "text",
                                              prefix: "$"
                                            },
                                            model: {
                                              value: q.bbTrx,
                                              callback: function($$v) {
                                                _vm.$set(q, "bbTrx", $$v)
                                              },
                                              expression: "q.bbTrx"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", {}, [
                              _c("label", [_vm._v("Rejects Fee")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field complex" },
                                [
                                  _c(
                                    "validator",
                                    {
                                      attrs: {
                                        value: q.depositRequired,
                                        reqs: ""
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "intake-field" },
                                        [
                                          _c("formel-money-amount", {
                                            attrs: {
                                              disabled: !q.optBankbiller,
                                              type: "text",
                                              prefix: "$"
                                            },
                                            model: {
                                              value: q.bbRejects,
                                              callback: function($$v) {
                                                _vm.$set(q, "bbRejects", $$v)
                                              },
                                              expression: "q.bbRejects"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", {}, [
                              _c("label", [_vm._v("Returns Fee")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field complex" },
                                [
                                  _c(
                                    "validator",
                                    {
                                      attrs: {
                                        value: q.depositRequired,
                                        reqs: ""
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "intake-field" },
                                        [
                                          _c("formel-money-amount", {
                                            attrs: {
                                              disabled: !q.optBankbiller,
                                              type: "text",
                                              prefix: "$"
                                            },
                                            model: {
                                              value: q.bbReturns,
                                              callback: function($$v) {
                                                _vm.$set(q, "bbReturns", $$v)
                                              },
                                              expression: "q.bbReturns"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", {}, [
                              _c("label", [_vm._v("Unauth Fee")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field complex" },
                                [
                                  _c(
                                    "validator",
                                    {
                                      attrs: {
                                        value: q.depositRequired,
                                        reqs: ""
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "intake-field" },
                                        [
                                          _c("formel-money-amount", {
                                            attrs: {
                                              disabled: !q.optBankbiller,
                                              type: "text",
                                              prefix: "$"
                                            },
                                            model: {
                                              value: q.bbUnauth,
                                              callback: function($$v) {
                                                _vm.$set(q, "bbUnauth", $$v)
                                              },
                                              expression: "q.bbUnauth"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", {}, [
                              _c("label", [_vm._v("Chargeback Fee")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field complex" },
                                [
                                  _c(
                                    "validator",
                                    {
                                      attrs: {
                                        value: q.depositRequired,
                                        reqs: ""
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "intake-field" },
                                        [
                                          _c("formel-money-amount", {
                                            attrs: {
                                              disabled: !q.optBankbiller,
                                              type: "text",
                                              prefix: "$"
                                            },
                                            model: {
                                              value: q.bbLate,
                                              callback: function($$v) {
                                                _vm.$set(q, "bbLate", $$v)
                                              },
                                              expression: "q.bbLate"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", {}, [
                              _c("label", [_vm._v("Non-Compliance")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field complex" },
                                [
                                  _c(
                                    "validator",
                                    {
                                      attrs: {
                                        value: q.depositRequired,
                                        reqs: ""
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "intake-field" },
                                        [
                                          _c("formel-money-amount", {
                                            attrs: {
                                              disabled: !q.optBankbiller,
                                              type: "text",
                                              prefix: "$"
                                            },
                                            model: {
                                              value: q.bbNonCompliance,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  q,
                                                  "bbNonCompliance",
                                                  $$v
                                                )
                                              },
                                              expression: "q.bbNonCompliance"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            false
                              ? _c("li", { staticClass: "intake-title" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "checkbox",
                                      class: { checked: q.optCoinbiller }
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: q.optCoinbiller,
                                            expression: "q.optCoinbiller"
                                          }
                                        ],
                                        attrs: { type: "checkbox" },
                                        domProps: {
                                          checked: Array.isArray(
                                            q.optCoinbiller
                                          )
                                            ? _vm._i(q.optCoinbiller, null) > -1
                                            : q.optCoinbiller
                                        },
                                        on: {
                                          change: function($event) {
                                            var $$a = q.optCoinbiller,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    q,
                                                    "optCoinbiller",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    q,
                                                    "optCoinbiller",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(q, "optCoinbiller", $$c)
                                            }
                                          }
                                        }
                                      }),
                                      _c("span"),
                                      _vm._v("CoinBiller")
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("li", {}, [
                              _c("label", [_vm._v("Monthly Fee")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field complex" },
                                [
                                  _c(
                                    "validator",
                                    {
                                      attrs: {
                                        value: q.depositRequired,
                                        reqs: ""
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "intake-field" },
                                        [
                                          _c("formel-money-amount", {
                                            attrs: {
                                              disabled: !q.optCoinbiller,
                                              type: "text",
                                              prefix: "$"
                                            },
                                            model: {
                                              value: q.cbMonthly,
                                              callback: function($$v) {
                                                _vm.$set(q, "cbMonthly", $$v)
                                              },
                                              expression: "q.cbMonthly"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", {}, [
                              _c("label", [_vm._v("Discount Rate")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field complex" },
                                [
                                  _c(
                                    "validator",
                                    {
                                      attrs: {
                                        value: q.depositRequired,
                                        reqs: ""
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "intake-field" },
                                        [
                                          _c("formel-money-amount", {
                                            attrs: {
                                              disabled: !q.optCoinbiller,
                                              type: "text",
                                              suffix: "%"
                                            },
                                            model: {
                                              value: q.cbDiscount,
                                              callback: function($$v) {
                                                _vm.$set(q, "cbDiscount", $$v)
                                              },
                                              expression: "q.cbDiscount"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", {}, [
                              _c("label", [_vm._v("Trx Fee")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field complex" },
                                [
                                  _c(
                                    "validator",
                                    {
                                      attrs: {
                                        value: q.depositRequired,
                                        reqs: ""
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "intake-field" },
                                        [
                                          _c("formel-money-amount", {
                                            attrs: {
                                              disabled: !q.optCoinbiller,
                                              type: "text",
                                              prefix: "$"
                                            },
                                            model: {
                                              value: q.cbTrx,
                                              callback: function($$v) {
                                                _vm.$set(q, "cbTrx", $$v)
                                              },
                                              expression: "q.cbTrx"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li")
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      false ? _c("h3", [_vm._v("ACCOUNT SETTINGS")]) : _vm._e(),
                      _vm._v(" "),
                      false
                        ? _c("ul", { staticClass: "column-form type2" }, [
                            _c("li", { staticClass: "fwidth fwx" }, [
                              _c("label", [_vm._v("Modules")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field" },
                                [
                                  _c(
                                    "validator",
                                    {
                                      attrs: {
                                        value: q.accSet1 && q.accSet1.length,
                                        reqs: ""
                                      }
                                    },
                                    [
                                      _c(
                                        "multiselect-desc",
                                        {
                                          attrs: {
                                            options: ["Chammp Billing"]
                                          },
                                          model: {
                                            value: q.accSet1,
                                            callback: function($$v) {
                                              _vm.$set(q, "accSet1", $$v)
                                            },
                                            expression: "q.accSet1"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n              $15.00 Monthly - Included with Prime and Axis Plan\n            "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", { staticClass: "fwidth fwx" }, [
                              _c("label", [_vm._v("Settings")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field" },
                                [
                                  _c(
                                    "validator",
                                    {
                                      attrs: {
                                        value: q.accSet2 && q.accSet2.length,
                                        reqs: ""
                                      }
                                    },
                                    [
                                      _c(
                                        "multiselect-desc",
                                        {
                                          attrs: {
                                            options: ["PayAxis Gateway"]
                                          },
                                          model: {
                                            value: q.accSet2,
                                            callback: function($$v) {
                                              _vm.$set(q, "accSet2", $$v)
                                            },
                                            expression: "q.accSet2"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n              $25.00 Monthly – Included with Prime and Axis Plan\n            "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", { staticClass: "fwidth fwx" }, [
                              _c("label", [_vm._v("Settings")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field" },
                                [
                                  _c(
                                    "validator",
                                    {
                                      attrs: {
                                        value: q.accSet3 && q.accSet3.length,
                                        reqs: ""
                                      }
                                    },
                                    [
                                      _c(
                                        "multiselect-desc",
                                        {
                                          attrs: {
                                            options: ["Virtual Terminal"]
                                          },
                                          model: {
                                            value: q.accSet3,
                                            callback: function($$v) {
                                              _vm.$set(q, "accSet3", $$v)
                                            },
                                            expression: "q.accSet3"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n              $15.00 Monthly - Included with Prime and Axis Plan\n            "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", { staticClass: "fwidth fwx" }, [
                              _c("label", [_vm._v("Settings")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field" },
                                [
                                  _c(
                                    "validator",
                                    {
                                      attrs: {
                                        value: q.accSet4 && q.accSet4.length,
                                        reqs: ""
                                      }
                                    },
                                    [
                                      _c(
                                        "multiselect-desc",
                                        {
                                          attrs: {
                                            options: ["Chammp Checkout"]
                                          },
                                          model: {
                                            value: q.accSet4,
                                            callback: function($$v) {
                                              _vm.$set(q, "accSet4", $$v)
                                            },
                                            expression: "q.accSet4"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n              $15.00 Monthly - Included with Prime and Axis Plan\n            "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", { staticClass: "fwidth fwx" }, [
                              _c("label", [_vm._v("Settings")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field" },
                                [
                                  _c(
                                    "validator",
                                    {
                                      attrs: {
                                        value: q.accSet5 && q.accSet5.length,
                                        reqs: ""
                                      }
                                    },
                                    [
                                      _c(
                                        "multiselect-desc",
                                        {
                                          attrs: {
                                            options: ["Multi-Currency"]
                                          },
                                          model: {
                                            value: q.accSet5,
                                            callback: function($$v) {
                                              _vm.$set(q, "accSet5", $$v)
                                            },
                                            expression: "q.accSet5"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n              $20.00 Monthly – Cost is Per TID\n            "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", { staticClass: "fwidth fwx" }, [
                              _c("label", [_vm._v("Settings")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field" },
                                [
                                  _c(
                                    "validator",
                                    {
                                      attrs: {
                                        value: q.accSet6 && q.accSet6.length,
                                        reqs: ""
                                      }
                                    },
                                    [
                                      _c(
                                        "multiselect-desc",
                                        {
                                          attrs: {
                                            options: [
                                              "Recurring & Subscription"
                                            ]
                                          },
                                          model: {
                                            value: q.accSet6,
                                            callback: function($$v) {
                                              _vm.$set(q, "accSet6", $$v)
                                            },
                                            expression: "q.accSet6"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n              Only available with Prime and Axis Plan\n            "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", { staticClass: "fwidth fwx" }, [
                              _c("label", [_vm._v("Settings")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field" },
                                [
                                  _c(
                                    "validator",
                                    {
                                      attrs: {
                                        value: q.accSet7 && q.accSet7.length,
                                        reqs: ""
                                      }
                                    },
                                    [
                                      _c(
                                        "multiselect-desc",
                                        {
                                          attrs: {
                                            options: ["Card Tokenization"]
                                          },
                                          model: {
                                            value: q.accSet7,
                                            callback: function($$v) {
                                              _vm.$set(q, "accSet7", $$v)
                                            },
                                            expression: "q.accSet7"
                                          }
                                        },
                                        [
                                          _c(
                                            "validator",
                                            {
                                              attrs: {
                                                value: q.cardTokenization,
                                                reqs:
                                                  q.accSet7 && q.accSet7.length
                                                    ? "required"
                                                    : ""
                                              }
                                            },
                                            [
                                              _c("formel-money-amount", {
                                                staticStyle: {
                                                  width: "70px",
                                                  padding: "0 2px"
                                                },
                                                attrs: {
                                                  disabled: !(
                                                    q.accSet7 &&
                                                    q.accSet7.length
                                                  ),
                                                  type: "text",
                                                  prefix: "$"
                                                },
                                                model: {
                                                  value: q.cardTokenization,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      q,
                                                      "cardTokenization",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "q.cardTokenization"
                                                }
                                              }),
                                              _vm._v(
                                                " Per Card - Only available with Prime and Axis Plan; Includes Account Updater\n              "
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", { staticClass: "fwidth fwx" }, [
                              _c("label", [_vm._v("TID Activations")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field" },
                                [
                                  _c(
                                    "validator",
                                    {
                                      attrs: {
                                        value: q.accSetTid,
                                        reqs: "required"
                                      }
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: q.accSetTid,
                                            expression: "q.accSetTid"
                                          }
                                        ],
                                        staticStyle: { width: "60px" },
                                        attrs: { type: "text" },
                                        domProps: { value: q.accSetTid },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              q,
                                              "accSetTid",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", { staticClass: "fwidth fwx" }, [
                              _c("label", [_vm._v("Account Add-Ons")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field" },
                                [
                                  _c(
                                    "validator",
                                    {
                                      attrs: {
                                        value: q.accSet8 && q.accSet8.length,
                                        reqs: ""
                                      }
                                    },
                                    [
                                      _c(
                                        "multiselect-desc",
                                        {
                                          attrs: { options: ["Vivpos"] },
                                          model: {
                                            value: q.accSet8,
                                            callback: function($$v) {
                                              _vm.$set(q, "accSet8", $$v)
                                            },
                                            expression: "q.accSet8"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n              $69.00 Monthly - Per License\n            "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", { staticClass: "fwidth fwx" }, [
                              _c("label", [_vm._v("Gift & Loyalty")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field" },
                                [
                                  _c(
                                    "validator",
                                    {
                                      attrs: {
                                        value: q.accSet9 && q.accSet9.length,
                                        reqs: ""
                                      }
                                    },
                                    [
                                      _c(
                                        "multiselect-desc",
                                        {
                                          attrs: {
                                            options: ["CHAMMP Rewards"]
                                          },
                                          model: {
                                            value: q.accSet9,
                                            callback: function($$v) {
                                              _vm.$set(q, "accSet9", $$v)
                                            },
                                            expression: "q.accSet9"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n              $59.00 Monthly – Cost is for program access only.\n            "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", { staticClass: "fwidth fwx" }, [
                              _c("label", [_vm._v("Statements")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "field" },
                                [
                                  _c(
                                    "validator",
                                    {
                                      attrs: {
                                        value: q.accSet10 && q.accSet10.length,
                                        reqs: ""
                                      }
                                    },
                                    [
                                      _c("multiselect", {
                                        attrs: {
                                          options: ["Mailed", "Portal"],
                                          onlyone: true
                                        },
                                        model: {
                                          value: q.accSet10,
                                          callback: function($$v) {
                                            _vm.$set(q, "accSet10", $$v)
                                          },
                                          expression: "q.accSet10"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("h3", [_vm._v("EQUIPMENT")]),
                      _vm._v(" "),
                      _c(
                        "ul",
                        { staticClass: "column-form type2" },
                        [
                          false
                            ? _c("li", { staticClass: "fwidth fwx" }, [
                                _c("label", [_vm._v("Special Offers")]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "field" },
                                  [
                                    _c(
                                      "validator",
                                      {
                                        attrs: {
                                          value:
                                            q.specialOffer &&
                                            q.specialOffer.length,
                                          reqs: ""
                                        }
                                      },
                                      [
                                        _c(
                                          "multiselect-desc",
                                          {
                                            attrs: {
                                              options: ["Free Terminal Program"]
                                            },
                                            model: {
                                              value: q.specialOffer,
                                              callback: function($$v) {
                                                _vm.$set(q, "specialOffer", $$v)
                                              },
                                              expression: "q.specialOffer"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n              Must ensure that merchant meets program criteria.\n            "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm._l(_vm.terminals, function(ti) {
                            return [
                              _c("li", { staticClass: "intake-title" }, [
                                _vm._v("Terminals Type #" + _vm._s(ti))
                              ]),
                              _vm._v(" "),
                              _c(
                                "li",
                                {
                                  staticStyle: { width: "calc(19.75% - 5px)" }
                                },
                                [
                                  _c("label", [_vm._v("Model")]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "field complex" },
                                    [
                                      _c(
                                        "validator",
                                        {
                                          attrs: {
                                            value: q["terminal" + ti + "Type"],
                                            reqs: ""
                                          }
                                        },
                                        [
                                          _c("dyn-select", {
                                            attrs: {
                                              options: _vm.equipmentOpt
                                            },
                                            model: {
                                              value:
                                                q["terminal" + ti + "Type"],
                                              callback: function($$v) {
                                                _vm.$set(
                                                  q,
                                                  "terminal" + ti + "Type",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "q['terminal'+ti+'Type']"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("br"),
                                          _vm._v(
                                            "\n            $" +
                                              _vm._s(
                                                (
                                                  _vm.equi(ti)[
                                                    q[
                                                      "terminal" +
                                                        ti +
                                                        "PurchaseOpt"
                                                    ]
                                                      ? "purchase"
                                                      : q[
                                                          "terminal" +
                                                            ti +
                                                            "RentalOpt"
                                                        ]
                                                      ? "rental"
                                                      : "reprogram"
                                                  ] || 0
                                                ).toFixed(2)
                                              ) +
                                              "\n"
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "li",
                                { staticStyle: { width: "calc(6.25% - 5px)" } },
                                [
                                  _c("label", [_vm._v("QTY")]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "field complex" },
                                    [
                                      _c(
                                        "validator",
                                        {
                                          attrs: {
                                            value: q["terminal" + ti + "Qty"],
                                            reqs: ""
                                          }
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  q["terminal" + ti + "Qty"],
                                                expression:
                                                  "q['terminal'+ti+'Qty']"
                                              }
                                            ],
                                            attrs: { type: "text" },
                                            domProps: {
                                              value: q["terminal" + ti + "Qty"]
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  q,
                                                  "terminal" + ti + "Qty",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "li",
                                { staticStyle: { width: "calc(12% - 5px)" } },
                                [
                                  _c("label", [_vm._v("Purchase")]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "field conditional" },
                                    [
                                      _c("checkbox-plain2", {
                                        attrs: {
                                          disabled: !_vm.equi(ti).purchase
                                        },
                                        on: {
                                          input: function($event) {
                                            _vm.$set(
                                              q,
                                              "terminal" + ti + "RentalOpt",
                                              false
                                            )
                                            _vm.$set(
                                              q,
                                              "terminal" + ti + "LeasingOpt",
                                              false
                                            )
                                            _vm.$set(
                                              q,
                                              "terminal" + ti + "ReprogramOpt",
                                              false
                                            )
                                          }
                                        },
                                        model: {
                                          value:
                                            q["terminal" + ti + "PurchaseOpt"],
                                          callback: function($$v) {
                                            _vm.$set(
                                              q,
                                              "terminal" + ti + "PurchaseOpt",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "q['terminal'+ti+'PurchaseOpt']"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "li",
                                { staticStyle: { width: "calc(12% - 5px)" } },
                                [
                                  _c("label", [_vm._v("Rental")]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "field conditional" },
                                    [
                                      _c("checkbox-plain2", {
                                        attrs: {
                                          disabled: !_vm.equi(ti).rental
                                        },
                                        on: {
                                          input: function($event) {
                                            _vm.$set(
                                              q,
                                              "terminal" + ti + "PurchaseOpt",
                                              false
                                            )
                                            _vm.$set(
                                              q,
                                              "terminal" + ti + "LeasingOpt",
                                              false
                                            )
                                            _vm.$set(
                                              q,
                                              "terminal" + ti + "ReprogramOpt",
                                              false
                                            )
                                          }
                                        },
                                        model: {
                                          value:
                                            q["terminal" + ti + "RentalOpt"],
                                          callback: function($$v) {
                                            _vm.$set(
                                              q,
                                              "terminal" + ti + "RentalOpt",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "q['terminal'+ti+'RentalOpt']"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "li",
                                { staticStyle: { width: "calc(12% - 5px)" } },
                                [
                                  _c("label", [_vm._v("Reprogram")]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "field conditional" },
                                    [
                                      _c("checkbox-plain2", {
                                        on: {
                                          input: function($event) {
                                            _vm.$set(
                                              q,
                                              "terminal" + ti + "PurchaseOpt",
                                              false
                                            )
                                            _vm.$set(
                                              q,
                                              "terminal" + ti + "RentalOpt",
                                              false
                                            )
                                            _vm.$set(
                                              q,
                                              "terminal" + ti + "LeasingOpt",
                                              false
                                            )
                                          }
                                        },
                                        model: {
                                          value:
                                            q["terminal" + ti + "ReprogramOpt"],
                                          callback: function($$v) {
                                            _vm.$set(
                                              q,
                                              "terminal" + ti + "ReprogramOpt",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "q['terminal'+ti+'ReprogramOpt']"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "li",
                                { staticStyle: { width: "calc(12% - 5px)" } },
                                [
                                  _c("label", [_vm._v("Leasing")]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "field conditional" },
                                    [
                                      _c("checkbox-plain2", {
                                        attrs: { disabled: "" },
                                        on: {
                                          input: function($event) {
                                            _vm.$set(
                                              q,
                                              "terminal" + ti + "PurchaseOpt",
                                              false
                                            )
                                            _vm.$set(
                                              q,
                                              "terminal" + ti + "RentalOpt",
                                              false
                                            )
                                            _vm.$set(
                                              q,
                                              "terminal" + ti + "ReprogramOpt",
                                              false
                                            )
                                          }
                                        },
                                        model: {
                                          value:
                                            q["terminal" + ti + "LeasingOpt"],
                                          callback: function($$v) {
                                            _vm.$set(
                                              q,
                                              "terminal" + ti + "LeasingOpt",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "q['terminal'+ti+'LeasingOpt']"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              false
                                ? _c(
                                    "li",
                                    {
                                      staticStyle: { width: "calc(12% - 5px)" }
                                    },
                                    [
                                      _c("label", [_vm._v("Merchant Own")]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "field conditional" },
                                        [
                                          _c("checkbox-plain2", {
                                            model: {
                                              value:
                                                q["terminal" + ti + "OwnOpt"],
                                              callback: function($$v) {
                                                _vm.$set(
                                                  q,
                                                  "terminal" + ti + "OwnOpt",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "q['terminal'+ti+'OwnOpt']"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("span", [_vm._v("Qty:")]),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  q["terminal" + ti + "Own"],
                                                expression:
                                                  "q['terminal'+ti+'Own']"
                                              }
                                            ],
                                            attrs: {
                                              type: "text",
                                              disabled: !q[
                                                "terminal" + ti + "OwnOpt"
                                              ]
                                            },
                                            domProps: {
                                              value: q["terminal" + ti + "Own"]
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  q,
                                                  "terminal" + ti + "Own",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              false
                                ? _c(
                                    "li",
                                    {
                                      staticStyle: { width: "calc(12% - 5px)" }
                                    },
                                    [
                                      _c("label", [_vm._v("Financing")]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "field conditional" },
                                        [
                                          _c("checkbox-plain2", {
                                            model: {
                                              value:
                                                q[
                                                  "terminal" +
                                                    ti +
                                                    "FinancingOpt"
                                                ],
                                              callback: function($$v) {
                                                _vm.$set(
                                                  q,
                                                  "terminal" +
                                                    ti +
                                                    "FinancingOpt",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "q['terminal'+ti+'FinancingOpt']"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("span", [_vm._v("Qty:")]),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  q[
                                                    "terminal" +
                                                      ti +
                                                      "Financing"
                                                  ],
                                                expression:
                                                  "q['terminal'+ti+'Financing']"
                                              }
                                            ],
                                            attrs: {
                                              type: "text",
                                              disabled: !q[
                                                "terminal" + ti + "FinancingOpt"
                                              ]
                                            },
                                            domProps: {
                                              value:
                                                q["terminal" + ti + "Financing"]
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  q,
                                                  "terminal" + ti + "Financing",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              false
                                ? _c(
                                    "li",
                                    {
                                      staticStyle: { width: "calc(12% - 5px)" }
                                    },
                                    [
                                      _c("label", [_vm._v("Insurance")]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "field conditional" },
                                        [
                                          _c("checkbox-plain2", {
                                            model: {
                                              value:
                                                q[
                                                  "terminal" +
                                                    ti +
                                                    "InsuranceOpt"
                                                ],
                                              callback: function($$v) {
                                                _vm.$set(
                                                  q,
                                                  "terminal" +
                                                    ti +
                                                    "InsuranceOpt",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "q['terminal'+ti+'InsuranceOpt']"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("span", [_vm._v("Qty:")]),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  q[
                                                    "terminal" +
                                                      ti +
                                                      "Insurance"
                                                  ],
                                                expression:
                                                  "q['terminal'+ti+'Insurance']"
                                              }
                                            ],
                                            attrs: {
                                              type: "text",
                                              disabled: !q[
                                                "terminal" + ti + "InsuranceOpt"
                                              ]
                                            },
                                            domProps: {
                                              value:
                                                q["terminal" + ti + "Insurance"]
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  q,
                                                  "terminal" + ti + "Insurance",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          }),
                          _vm._v(" "),
                          _c("li", { staticStyle: { width: "100%" } }, [
                            _vm.terminalCount > 1
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "terminals-del",
                                    attrs: { href: "" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        _vm.terminalCount--
                                      }
                                    }
                                  },
                                  [_vm._v("‒")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass: "terminals-add",
                                attrs: { href: "" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    _vm.terminalCount++
                                  }
                                }
                              },
                              [_vm._v("+")]
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", { staticClass: "fwidth fwx" }, [
                            _c("label", [_vm._v("Communication")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field" },
                              [
                                _c(
                                  "validator",
                                  {
                                    attrs: {
                                      value:
                                        q.communicationMethod &&
                                        q.communicationMethod.length,
                                      reqs: ""
                                    }
                                  },
                                  [
                                    _c("multiselect", {
                                      attrs: {
                                        options: [
                                          "IP",
                                          "Wireless",
                                          "SIM Card Required",
                                          "Batch Auto Close Time!time",
                                          "OnSite Service Requested"
                                        ],
                                        time: q.communicationTime
                                      },
                                      on: {
                                        "update:time": function($event) {
                                          return _vm.$set(
                                            q,
                                            "communicationTime",
                                            $event
                                          )
                                        }
                                      },
                                      model: {
                                        value: q.communicationMethod,
                                        callback: function($$v) {
                                          _vm.$set(
                                            q,
                                            "communicationMethod",
                                            $$v
                                          )
                                        },
                                        expression: "q.communicationMethod"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", { staticClass: "intake-title" }, [
                            _vm._v("Shipping")
                          ]),
                          _vm._v(" "),
                          _c("li", { staticClass: "fwidth fwx" }, [
                            _c("label", [_vm._v("Ship to")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field" },
                              [
                                _c(
                                  "validator",
                                  {
                                    attrs: {
                                      value:
                                        q.shipTo && q.shipTo.length
                                          ? q.shipTo
                                          : q.shipToOther,
                                      reqs: ""
                                    }
                                  },
                                  [
                                    _c("multiselect", {
                                      attrs: {
                                        other: q.shipToOther,
                                        options: [
                                          "Company",
                                          "Location",
                                          "Other"
                                        ],
                                        onlyone: true
                                      },
                                      on: {
                                        "update:other": function($event) {
                                          return _vm.$set(
                                            q,
                                            "shipToOther",
                                            $event
                                          )
                                        }
                                      },
                                      model: {
                                        value: q.shipTo,
                                        callback: function($$v) {
                                          _vm.$set(q, "shipTo", $$v)
                                        },
                                        expression: "q.shipTo"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", { staticClass: "fwidth fwx" }, [
                            _c("label", [_vm._v("Shipping Method")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field" },
                              [
                                _c(
                                  "validator",
                                  {
                                    attrs: {
                                      value:
                                        q.shippingMethod &&
                                        q.shippingMethod.length,
                                      reqs: ""
                                    }
                                  },
                                  [
                                    _c("multiselect", {
                                      attrs: {
                                        options: [
                                          "Regular",
                                          "2 Day",
                                          "Next Day",
                                          "Overnight"
                                        ],
                                        onlyone: true
                                      },
                                      model: {
                                        value: q.shippingMethod,
                                        callback: function($$v) {
                                          _vm.$set(q, "shippingMethod", $$v)
                                        },
                                        expression: "q.shippingMethod"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", {}, [
                            _c("label", [_vm._v("Ship to Other Address")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field complex" },
                              [
                                _c(
                                  "validator",
                                  {
                                    attrs: {
                                      value: q.shipOtherAddress,
                                      reqs:
                                        q.shipTo &&
                                        q.shipTo.indexOf("Other") != -1
                                          ? "required"
                                          : ""
                                    }
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: q.shipOtherAddress,
                                          expression: "q.shipOtherAddress"
                                        }
                                      ],
                                      attrs: {
                                        type: "text",
                                        disabled:
                                          !q.shipTo ||
                                          q.shipTo.indexOf("Other") == -1
                                      },
                                      domProps: { value: q.shipOtherAddress },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            q,
                                            "shipOtherAddress",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", {}, [
                            _c("label", [_vm._v("City")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field complex" },
                              [
                                _c(
                                  "validator",
                                  {
                                    attrs: {
                                      value: q.shipOtherCity,
                                      reqs:
                                        q.shipTo &&
                                        q.shipTo.indexOf("Other") != -1
                                          ? "required"
                                          : ""
                                    }
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: q.shipOtherCity,
                                          expression: "q.shipOtherCity"
                                        }
                                      ],
                                      attrs: {
                                        type: "text",
                                        disabled:
                                          !q.shipTo ||
                                          q.shipTo.indexOf("Other") == -1
                                      },
                                      domProps: { value: q.shipOtherCity },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            q,
                                            "shipOtherCity",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", {}, [
                            _c("label", [_vm._v("Province/State")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field complex" },
                              [
                                _c(
                                  "validator",
                                  {
                                    attrs: {
                                      value: q.shipOtherState,
                                      reqs:
                                        q.shipTo &&
                                        q.shipTo.indexOf("Other") != -1
                                          ? "required"
                                          : ""
                                    }
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: q.shipOtherState,
                                          expression: "q.shipOtherState"
                                        }
                                      ],
                                      attrs: {
                                        type: "text",
                                        disabled:
                                          !q.shipTo ||
                                          q.shipTo.indexOf("Other") == -1
                                      },
                                      domProps: { value: q.shipOtherState },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            q,
                                            "shipOtherState",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", {}, [
                            _c("label", [_vm._v("Postal Code/Zip")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field complex" },
                              [
                                _c(
                                  "validator",
                                  {
                                    attrs: {
                                      value: q.shipOtherPostal,
                                      reqs:
                                        q.shipTo &&
                                        q.shipTo.indexOf("Other") != -1
                                          ? "required"
                                          : ""
                                    }
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: q.shipOtherPostal,
                                          expression: "q.shipOtherPostal"
                                        }
                                      ],
                                      attrs: {
                                        type: "text",
                                        disabled:
                                          !q.shipTo ||
                                          q.shipTo.indexOf("Other") == -1
                                      },
                                      domProps: { value: q.shipOtherPostal },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            q,
                                            "shipOtherPostal",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", { staticStyle: { width: "100%" } }, [
                            _c("label", [_vm._v("Instructions")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field complex" },
                              [
                                _c(
                                  "validator",
                                  {
                                    attrs: {
                                      value: q.shipOtherInstructions,
                                      reqs: ""
                                    }
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: q.shipOtherInstructions,
                                          expression: "q.shipOtherInstructions"
                                        }
                                      ],
                                      attrs: { type: "text" },
                                      domProps: {
                                        value: q.shipOtherInstructions
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            q,
                                            "shipOtherInstructions",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ])
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("section", [
                    _vm._m(0, true),
                    _vm._v(" "),
                    _c("ul", { staticClass: "disc-stat" }, [
                      _c(
                        "li",
                        [
                          _c(
                            "validator",
                            { attrs: { value: q.terms, reqs: "boolean" } },
                            [
                              _c(
                                "div",
                                { staticClass: "element-toggle slin" },
                                [
                                  _c("label", { class: { checked: q.terms } }, [
                                    _c("span"),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: q.terms,
                                          expression: "q.terms"
                                        }
                                      ],
                                      attrs: { type: "checkbox" },
                                      domProps: {
                                        checked: Array.isArray(q.terms)
                                          ? _vm._i(q.terms, null) > -1
                                          : q.terms
                                      },
                                      on: {
                                        change: function($event) {
                                          var $$a = q.terms,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  q,
                                                  "terms",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  q,
                                                  "terms",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(q, "terms", $$c)
                                          }
                                        }
                                      }
                                    }),
                                    _c("span", [
                                      _vm._v(
                                        "I acknowledge that all terms and conditions of service were clearly explained to merchant."
                                      )
                                    ])
                                  ])
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        [
                          _c(
                            "validator",
                            { attrs: { value: q.conduct, reqs: "boolean" } },
                            [
                              _c(
                                "div",
                                { staticClass: "element-toggle slin" },
                                [
                                  _c(
                                    "label",
                                    { class: { checked: q.conduct } },
                                    [
                                      _c("span"),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: q.conduct,
                                            expression: "q.conduct"
                                          }
                                        ],
                                        attrs: { type: "checkbox" },
                                        domProps: {
                                          checked: Array.isArray(q.conduct)
                                            ? _vm._i(q.conduct, null) > -1
                                            : q.conduct
                                        },
                                        on: {
                                          change: function($event) {
                                            var $$a = q.conduct,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    q,
                                                    "conduct",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    q,
                                                    "conduct",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(q, "conduct", $$c)
                                            }
                                          }
                                        }
                                      }),
                                      _c("span", [
                                        _vm._v(
                                          "I acknowledge that I followed the Code of Conduct Policy when offering these products and/or services."
                                        )
                                      ])
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ])
                  ])
                ])
              : _vm.step == 4
              ? _c("div", { staticClass: "pre-done" }, [
                  _c("h1", [
                    _vm._v(
                      "Thank you for submitting the merchant profile form!"
                    )
                  ]),
                  _vm._v(" "),
                  _c("p"),
                  _vm._v(" "),
                  !_vm.guest
                    ? _c(
                        "a",
                        {
                          staticClass: "btn btn-large btn-primary btn-black",
                          attrs: { href: "/" }
                        },
                        [_vm._v("Back to Dashboard")]
                      )
                    : _vm._e()
                ])
              : _vm._e()
          }),
          _vm._v(" "),
          _vm.step != 4
            ? _c("div", { staticClass: "buttons" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-large btn-primary",
                    attrs: { disabled: _vm.sending },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.send.apply(null, arguments)
                      }
                    }
                  },
                  [_vm._v("Submit")]
                )
              ])
            : _vm._e()
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", [_c("em", [_vm._v("DISCLOSURE STATEMENTS")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }