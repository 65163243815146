var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("company-select", {
        attrs: { isNew: _vm.isNew, type: _vm.type },
        on: {
          "update:type": function($event) {
            _vm.type = $event
          }
        },
        model: {
          value: _vm.itemId,
          callback: function($$v) {
            _vm.itemId = $$v
          },
          expression: "itemId"
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "ncontent" }, [
        _c(
          "div",
          { staticClass: "header" },
          [
            _c("company-edit", {
              attrs: { itemId: _vm.itemId, isNew: _vm.isNew, type: _vm.type }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }