import { render, staticRenderFns } from "./pricing-template.vue?vue&type=template&id=74b44509&"
import script from "./pricing-template.vue?vue&type=script&lang=js&"
export * from "./pricing-template.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/c6cbhq/assets/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('74b44509')) {
      api.createRecord('74b44509', component.options)
    } else {
      api.reload('74b44509', component.options)
    }
    module.hot.accept("./pricing-template.vue?vue&type=template&id=74b44509&", function () {
      api.rerender('74b44509', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "js/pricing-template.vue"
export default component.exports