var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.shown,
          expression: "shown"
        }
      ],
      staticClass: "form-popup"
    },
    [
      _c("div", [
        _c("div", { staticClass: "ncontent appf appnat" }, [
          _c(
            "a",
            {
              staticClass: "close",
              attrs: { href: "" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.dismiss.apply(null, arguments)
                }
              }
            },
            [_vm._v("×")]
          ),
          _vm._v(" "),
          _c("h3", [_vm._v("Set partner settings")]),
          _vm._v(" "),
          _c("form", { staticClass: "form-step" }, [
            _c("section", [
              _c("h2", [_vm._v("Company Information")]),
              _vm._v(" "),
              _c("ul", { staticClass: "column-form column-form-compact" }, [
                _c("li", [
                  _c("label", [_vm._v("Relationship Manager")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "field" },
                    [
                      _c(
                        "validator",
                        {
                          attrs: { value: _vm.appl.manager, reqs: "required" }
                        },
                        [
                          _c("dyn-select", {
                            attrs: { options: _vm.sourcesToShow },
                            model: {
                              value: _vm.appl.manager,
                              callback: function($$v) {
                                _vm.$set(_vm.appl, "manager", $$v)
                              },
                              expression: "appl.manager"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Partner Tier")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "field" },
                    [
                      _c(
                        "validator",
                        {
                          attrs: {
                            value: _vm.appl.agentDb.agentClass,
                            reqs: "required"
                          }
                        },
                        [
                          _c("dyn-select", {
                            attrs: { options: _vm.accountClassesList },
                            model: {
                              value: _vm.appl.agentDb.agentClass,
                              callback: function($$v) {
                                _vm.$set(_vm.appl.agentDb, "agentClass", $$v)
                              },
                              expression: "appl.agentDb.agentClass"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Referrer")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "field" },
                    [
                      _c(
                        "validator",
                        {
                          attrs: {
                            value: _vm.appl.agentDb.referrer,
                            reqs: "required"
                          }
                        },
                        [
                          _c("dyn-select", {
                            attrs: { options: _vm.usersToShow },
                            model: {
                              value: _vm.appl.agentDb.referrer,
                              callback: function($$v) {
                                _vm.$set(_vm.appl.agentDb, "referrer", $$v)
                              },
                              expression: "appl.agentDb.referrer"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Language")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "field" },
                    [
                      _c(
                        "validator",
                        {
                          attrs: {
                            value: _vm.appl.agentDb.language,
                            reqs: "required"
                          }
                        },
                        [
                          _c("dyn-select", {
                            attrs: { options: _vm.languages },
                            model: {
                              value: _vm.appl.agentDb.language,
                              callback: function($$v) {
                                _vm.$set(_vm.appl.agentDb, "language", $$v)
                              },
                              expression: "appl.agentDb.language"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "buttons" }, [
              _c(
                "a",
                {
                  staticClass: "btn btn-large btn-success",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.save.apply(null, arguments)
                    }
                  }
                },
                [_vm._v("Set")]
              )
            ])
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }