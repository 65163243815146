var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "content-table-container" }, [
      _c("table", { staticClass: "content-table" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "tbody",
          _vm._l(_vm.items, function(item, index) {
            return _c("tr", [
              _c("td", { staticClass: "text-center main-col" }, [
                _vm._v("\n            " + _vm._s(item.status) + "\n          ")
              ]),
              _vm._v(" "),
              _c("td", { staticClass: "text-center" }, [
                _vm._v(
                  "\n            " + _vm._s(item.category) + "\n          "
                )
              ]),
              _vm._v(" "),
              _c("td", { staticClass: "text-center" }, [
                _vm._v(
                  "\n            " + _vm._s(item.description) + "\n          "
                )
              ])
            ])
          }),
          0
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticStyle: { width: "10%" } }, [_vm._v("Status code")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "25%" } }, [_vm._v("Category")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "64%" } }, [_vm._v("Description")])
      ]),
      _vm._v(" "),
      _c("tr", [_c("th", { staticClass: "spacer", attrs: { colspan: "4" } })])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }