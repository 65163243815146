var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form", { staticClass: "form-step" }, [
    _c(
      "section",
      { staticClass: "last-step" },
      [
        _vm._m(0),
        _vm._v(" "),
        _vm._m(1),
        _vm._v(" "),
        _c("div", { staticClass: "clearfx" }),
        _vm._v(" "),
        false
          ? _c(
              "validator",
              {
                attrs: {
                  value: _vm.files.application,
                  reqs: _vm.app.requirements.merchantApplication
                    ? "required"
                    : ""
                }
              },
              [
                _c(
                  "checkbox-file",
                  {
                    attrs: { basepath: _vm.basepath },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "more",
                          fn: function() {
                            return [
                              _vm._v(
                                "Incomplete, handwritten or unsigned applications will not be accepted"
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      2022671038
                    ),
                    model: {
                      value: _vm.files.application,
                      callback: function($$v) {
                        _vm.$set(_vm.files, "application", $$v)
                      },
                      expression: "files.application"
                    }
                  },
                  [
                    _vm._v(
                      "\r\n        Completed & Signed Merchant Application\r\n        "
                    )
                  ]
                )
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.app.hasParent === "1"
          ? _c(
              "validator",
              { attrs: { value: _vm.files.pParent, reqs: "required" } },
              [
                _c(
                  "checkbox-file",
                  {
                    attrs: { basepath: _vm.basepath },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "more",
                          fn: function() {
                            return [_vm._v("Please combine into 1 file")]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      2717140044
                    ),
                    model: {
                      value: _vm.files.pParent,
                      callback: function($$v) {
                        _vm.$set(_vm.files, "pParent", $$v)
                      },
                      expression: "files.pParent"
                    }
                  },
                  [
                    _vm._v(
                      "\r\n        Parent company incorporation documents \r\n        "
                    )
                  ]
                )
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "validator",
          {
            attrs: {
              value: _vm.files.incor,
              reqs: _vm.app.requirements.incorporationDocuments
                ? "required"
                : ""
            }
          },
          [
            _c(
              "checkbox-file",
              {
                attrs: { basepath: _vm.basepath },
                scopedSlots: _vm._u([
                  {
                    key: "more",
                    fn: function() {
                      return [
                        _vm._v(
                          "Please combine into 1 file, must include certificate of incorporation, memorandum and articles of association, certificates listing all current beneficial owners, directors and shareholders"
                        )
                      ]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.files.incor,
                  callback: function($$v) {
                    _vm.$set(_vm.files, "incor", $$v)
                  },
                  expression: "files.incor"
                }
              },
              [
                _vm._v(
                  "\r\n        " +
                    _vm._s(_vm.app.companyName) +
                    " Incorporation Documents \r\n        "
                )
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _vm.app.ubos.length > 1
          ? [
              _vm._l(_vm.app.ubos, function(ubo, i) {
                return [
                  _c(
                    "validator",
                    {
                      attrs: {
                        value: "files.passport" + (i + 1),
                        reqs: _vm.app.requirements.passportCopy
                          ? "required"
                          : ""
                      }
                    },
                    [
                      _c(
                        "checkbox-file",
                        {
                          attrs: { basepath: _vm.basepath },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "more",
                                fn: function() {
                                  return [
                                    _vm._v(
                                      "Passport copy for all beneficial owners, directors and authorized signatories"
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          ),
                          model: {
                            value: _vm.files["passport" + (i + 1)],
                            callback: function($$v) {
                              _vm.$set(_vm.files, "passport" + (i + 1), $$v)
                            },
                            expression: "files[`passport${i+1}`]"
                          }
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.app.ubos[i].firstName) +
                                " " +
                                _vm._s(_vm.app.ubos[i].lastName) +
                                " \r\n            "
                            ),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.app.extraData["passport" + (i + 1)],
                                    expression:
                                      "app.extraData['passport'+(i+1)]"
                                  }
                                ],
                                attrs: { type: "plain" },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                  },
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.app.extraData,
                                      "passport" + (i + 1),
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  }
                                }
                              },
                              [
                                _c("option", { attrs: { value: "Passport" } }, [
                                  _vm._v("Passport")
                                ]),
                                _vm._v(" "),
                                _c(
                                  "option",
                                  { attrs: { value: "Driver's license" } },
                                  [_vm._v("Driver's license")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "option",
                                  { attrs: { value: "Personal ID" } },
                                  [_vm._v("Personal ID")]
                                )
                              ]
                            ),
                            _vm._v("\r\n             Copy")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ]
              })
            ]
          : [
              _c(
                "validator",
                {
                  attrs: {
                    value: _vm.files.passport,
                    reqs: _vm.app.requirements.passportCopy ? "required" : ""
                  }
                },
                [
                  _c(
                    "checkbox-file",
                    {
                      attrs: { basepath: _vm.basepath },
                      scopedSlots: _vm._u([
                        {
                          key: "more",
                          fn: function() {
                            return [
                              _vm._v(
                                "Passport copy for all beneficial owners, directors and authorized signatories"
                              )
                            ]
                          },
                          proxy: true
                        }
                      ]),
                      model: {
                        value: _vm.files.passport,
                        callback: function($$v) {
                          _vm.$set(_vm.files, "passport", $$v)
                        },
                        expression: "files.passport"
                      }
                    },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.app.ubos[0].firstName) +
                            " " +
                            _vm._s(_vm.app.ubos[0].lastName) +
                            " \r\n          "
                        ),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.app.extraData["passport"],
                                expression: "app.extraData['passport']"
                              }
                            ],
                            attrs: { type: "plain" },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                              },
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.app.extraData,
                                  "passport",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              }
                            }
                          },
                          [
                            _c("option", { attrs: { value: "Passport" } }, [
                              _vm._v("Passport")
                            ]),
                            _vm._v(" "),
                            _c(
                              "option",
                              { attrs: { value: "Driver's license" } },
                              [_vm._v("Driver's license")]
                            ),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "Personal ID" } }, [
                              _vm._v("Personal ID")
                            ])
                          ]
                        ),
                        _vm._v("\r\n           Copy")
                      ])
                    ]
                  )
                ],
                1
              )
            ],
        _vm._v(" "),
        _c(
          "validator",
          {
            attrs: {
              value: _vm.files.utility,
              reqs: _vm.app.requirements.utilityBill ? "required" : ""
            }
          },
          [
            _c(
              "checkbox-file",
              {
                attrs: { basepath: _vm.basepath },
                scopedSlots: _vm._u([
                  {
                    key: "more",
                    fn: function() {
                      return [
                        _vm._v(
                          "Utility bill for all beneficial owners, directors and authorized signatories.  No older than 60 days."
                        )
                      ]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.files.utility,
                  callback: function($$v) {
                    _vm.$set(_vm.files, "utility", $$v)
                  },
                  expression: "files.utility"
                }
              },
              [
                _vm._v(
                  "\r\n        " +
                    _vm._s(_vm.app.companyName) +
                    " Recent Utility Bill\r\n        "
                )
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "validator",
          {
            attrs: {
              value: _vm.files.statement,
              reqs: _vm.app.requirements.bankStatement ? "required" : ""
            }
          },
          [
            _c(
              "checkbox-file",
              {
                attrs: { basepath: _vm.basepath },
                scopedSlots: _vm._u([
                  {
                    key: "more",
                    fn: function() {
                      return [
                        _vm._v(
                          "3 Months company bank statement clearly showing company name and address"
                        )
                      ]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.files.statement,
                  callback: function($$v) {
                    _vm.$set(_vm.files, "statement", $$v)
                  },
                  expression: "files.statement"
                }
              },
              [
                _vm._v(
                  "\r\n        " +
                    _vm._s(_vm.app.companyName) +
                    " 3 Months Company Bank Statement\r\n        "
                )
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "validator",
          {
            attrs: {
              value: _vm.files.voidCheck,
              reqs: _vm.app.requirements.voidCheck ? "required" : ""
            }
          },
          [
            _c(
              "checkbox-file",
              {
                attrs: { basepath: _vm.basepath },
                scopedSlots: _vm._u([
                  {
                    key: "more",
                    fn: function() {
                      return [_vm._v("Must be in company name")]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.files.voidCheck,
                  callback: function($$v) {
                    _vm.$set(_vm.files, "voidCheck", $$v)
                  },
                  expression: "files.voidCheck"
                }
              },
              [
                _vm._v(
                  "\r\n        " +
                    _vm._s(_vm.app.companyName) +
                    " Void Check\r\n        "
                )
              ]
            )
          ],
          1
        )
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "last-step" },
      [
        _c("h2", [_vm._v("Conditional Documents")]),
        _vm._v(" "),
        _c(
          "validator",
          {
            attrs: {
              value: _vm.files.processing,
              reqs: _vm.app.requirements.processingStatement ? "required" : ""
            }
          },
          [
            _c(
              "checkbox-file",
              {
                attrs: { basepath: _vm.basepath },
                scopedSlots: _vm._u([
                  {
                    key: "more",
                    fn: function() {
                      return [
                        _vm._v(
                          "6 most recent processing statements for company"
                        )
                      ]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.files.processing,
                  callback: function($$v) {
                    _vm.$set(_vm.files, "processing", $$v)
                  },
                  expression: "files.processing"
                }
              },
              [_vm._v("\r\n        6 Months Processing Statements\r\n        ")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "validator",
          {
            attrs: {
              value: _vm.files.govId,
              reqs: _vm.app.requirements.govId ? "required" : ""
            }
          },
          [
            _c(
              "checkbox-file",
              {
                attrs: { basepath: _vm.basepath },
                scopedSlots: _vm._u([
                  {
                    key: "more",
                    fn: function() {
                      return undefined
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.files.govId,
                  callback: function($$v) {
                    _vm.$set(_vm.files, "govId", $$v)
                  },
                  expression: "files.govId"
                }
              },
              [_vm._v("\r\n        Government Issued ID\r\n        ")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "validator",
          {
            attrs: {
              value: _vm.files.structure,
              reqs: _vm.app.requirements.corporateStructure ? "required" : ""
            }
          },
          [
            _c(
              "checkbox-file",
              {
                attrs: { basepath: _vm.basepath },
                scopedSlots: _vm._u([
                  {
                    key: "more",
                    fn: function() {
                      return [
                        _vm._v(
                          "Show parent and related companies and beneficial owners, directors and shareholders"
                        )
                      ]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.files.structure,
                  callback: function($$v) {
                    _vm.$set(_vm.files, "structure", $$v)
                  },
                  expression: "files.structure"
                }
              },
              [_vm._v("\r\n        Corporate Structure Diagram\r\n        ")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "validator",
          {
            attrs: {
              value: _vm.files.financial,
              reqs: _vm.app.requirements.financialStatements ? "required" : ""
            }
          },
          [
            _c(
              "checkbox-file",
              {
                attrs: { basepath: _vm.basepath },
                scopedSlots: _vm._u([
                  {
                    key: "more",
                    fn: function() {
                      return [
                        _vm._v("Most recent financial statements for company")
                      ]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.files.financial,
                  callback: function($$v) {
                    _vm.$set(_vm.files, "financial", $$v)
                  },
                  expression: "files.financial"
                }
              },
              [_vm._v("\r\n        Financial Statements\r\n        ")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "validator",
          {
            attrs: {
              value: _vm.files.licenses,
              reqs: _vm.app.requirements.copyOfLicense ? "required" : ""
            }
          },
          [
            _c(
              "checkbox-file",
              {
                attrs: { basepath: _vm.basepath },
                scopedSlots: _vm._u([
                  {
                    key: "more",
                    fn: function() {
                      return [_vm._v("Provide if is a regulated business type")]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.files.licenses,
                  callback: function($$v) {
                    _vm.$set(_vm.files, "licenses", $$v)
                  },
                  expression: "files.licenses"
                }
              },
              [_vm._v("\r\n        Copy of License(s)\r\n        ")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "validator",
          {
            attrs: {
              value: _vm.files.pciDss,
              reqs: _vm.app.requirements.pciDssCert ? "required" : ""
            }
          },
          [
            _c(
              "checkbox-file",
              {
                attrs: { basepath: _vm.basepath },
                scopedSlots: _vm._u([
                  {
                    key: "more",
                    fn: function() {
                      return [_vm._v("PCI DSS compliance certification")]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.files.pciDss,
                  callback: function($$v) {
                    _vm.$set(_vm.files, "pciDss", $$v)
                  },
                  expression: "files.pciDss"
                }
              },
              [
                _vm._v(
                  "\r\n        PCI DSS Certificate or Adherence\r\n        "
                )
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "validator",
          {
            attrs: {
              value: _vm.files.padAuth,
              reqs: _vm.app.requirements.padAuth ? "required" : ""
            }
          },
          [
            _c(
              "checkbox-file",
              {
                attrs: { basepath: _vm.basepath },
                scopedSlots: _vm._u([
                  {
                    key: "more",
                    fn: function() {
                      return undefined
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.files.padAuth,
                  callback: function($$v) {
                    _vm.$set(_vm.files, "padAuth", $$v)
                  },
                  expression: "files.padAuth"
                }
              },
              [_vm._v("\r\n        Sample PAD Authorization\r\n        ")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "validator",
          {
            attrs: {
              value: _vm.files.marketingMaterials,
              reqs: _vm.app.requirements.marketingMaterials ? "required" : ""
            }
          },
          [
            _c(
              "checkbox-file",
              {
                attrs: { basepath: _vm.basepath },
                scopedSlots: _vm._u([
                  {
                    key: "more",
                    fn: function() {
                      return undefined
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.files.marketingMaterials,
                  callback: function($$v) {
                    _vm.$set(_vm.files, "marketingMaterials", $$v)
                  },
                  expression: "files.marketingMaterials"
                }
              },
              [
                _vm._v(
                  "\r\n        Marketing Materials if no Website\r\n        "
                )
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "validator",
          {
            attrs: {
              value: _vm.files.businessSummary,
              reqs: _vm.app.requirements.businessSummary ? "required" : ""
            }
          },
          [
            _c(
              "checkbox-file",
              {
                attrs: { basepath: _vm.basepath },
                scopedSlots: _vm._u([
                  {
                    key: "more",
                    fn: function() {
                      return undefined
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.files.businessSummary,
                  callback: function($$v) {
                    _vm.$set(_vm.files, "businessSummary", $$v)
                  },
                  expression: "files.businessSummary"
                }
              },
              [_vm._v("\r\n        Complete Business Summary\r\n        ")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "validator",
          {
            attrs: {
              value: _vm.files.personalGuarantee,
              reqs: _vm.app.requirements.personalGuarantee ? "required" : ""
            }
          },
          [
            _c(
              "checkbox-file",
              {
                attrs: { basepath: _vm.basepath },
                scopedSlots: _vm._u([
                  {
                    key: "more",
                    fn: function() {
                      return undefined
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.files.personalGuarantee,
                  callback: function($$v) {
                    _vm.$set(_vm.files, "personalGuarantee", $$v)
                  },
                  expression: "files.personalGuarantee"
                }
              },
              [_vm._v("\r\n        Personal Guarantee\r\n        ")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("validator", { attrs: { reqs: "" } }, [
          _c("div", { staticClass: "element-checkbox-line" }, [
            _c("label", { class: { checked: _vm.questionnaireCompleted } }, [
              _c("span")
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "title",
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    _vm.more = !_vm.more
                  }
                }
              },
              [
                _vm._v(
                  "\r\n          MOTO/Internet/Future Services Questionnaire\r\n          "
                ),
                _c("div", { staticClass: "file-obj" }, [
                  _c("a", [
                    _vm._v(
                      _vm._s(
                        _vm.questionnaireCompleted
                          ? "Questionnaire.pdf"
                          : "(no file)"
                      )
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.more,
                      expression: "more"
                    }
                  ],
                  staticClass: "more"
                })
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "toggleMore",
                on: {
                  click: function($event) {
                    $event.preventDefault()
                  }
                }
              },
              [_c("a", { attrs: { href: "" } })]
            )
          ])
        ])
      ],
      1
    ),
    _vm._v(" "),
    _vm.app.companyNew
      ? _c(
          "section",
          { staticClass: "last-step" },
          [
            _c("h2", [_vm._v("New Business")]),
            _vm._v(" "),
            _c(
              "validator",
              {
                attrs: {
                  value: _vm.files.plan,
                  reqs: _vm.app.requirements.businessPlan ? "required" : ""
                }
              },
              [
                _c(
                  "checkbox-file",
                  {
                    attrs: { basepath: _vm.basepath },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "more",
                          fn: function() {
                            return [
                              _vm._v("Provide business plan with projections ")
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      2699953954
                    ),
                    model: {
                      value: _vm.files.plan,
                      callback: function($$v) {
                        _vm.$set(_vm.files, "plan", $$v)
                      },
                      expression: "files.plan"
                    }
                  },
                  [
                    _vm._v(
                      "\r\n        Business Plan with Projections\r\n        "
                    )
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "validator",
              {
                attrs: {
                  value: _vm.files.bankStatement,
                  reqs: _vm.app.requirements.bankStatement2 ? "required" : ""
                }
              },
              [
                _c(
                  "checkbox-file",
                  {
                    attrs: { basepath: _vm.basepath },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "more",
                          fn: function() {
                            return [
                              _vm._v(
                                "Provide bank statement for all beneficial owners, directors and shareholders"
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      3616815798
                    ),
                    model: {
                      value: _vm.files.bankStatement,
                      callback: function($$v) {
                        _vm.$set(_vm.files, "bankStatement", $$v)
                      },
                      expression: "files.bankStatement"
                    }
                  },
                  [
                    _vm._v(
                      "\r\n        Bank Statement or Signed Bank Letter\r\n        "
                    )
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "validator",
              {
                attrs: {
                  value: _vm.files.businessSummary2,
                  reqs: _vm.app.requirements.businessSummary2 ? "required" : ""
                }
              },
              [
                _c(
                  "checkbox-file",
                  {
                    attrs: { basepath: _vm.basepath },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "more",
                          fn: function() {
                            return undefined
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      3165073180
                    ),
                    model: {
                      value: _vm.files.businessSummary2,
                      callback: function($$v) {
                        _vm.$set(_vm.files, "businessSummary2", $$v)
                      },
                      expression: "files.businessSummary2"
                    }
                  },
                  [_vm._v("\r\n        Complete Business Summary\r\n        ")]
                )
              ],
              1
            )
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm._m(2),
    _vm._v(" "),
    _c("ul", { staticClass: "notes" }, [
      _c("li", [
        _vm._v(
          "For assistance, please contact " +
            _vm._s(_vm.companydata.applicationEmail) +
            " or call " +
            _vm._s(_vm.companydata.servicesTel)
        )
      ]),
      _vm._v(" "),
      _c("li", [_vm._v("Additional requirements may apply")]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "Ensure that all above requested items are complete when submitted"
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "Please submit/upload all requested items on the last page of application."
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "By completing this form, you agree that your personal data may be shared with third parties for on boarding and Know-Your-Customer purposes."
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "By submitting this form you confirm that all information provided is accurate, complete and truthful and you consent to credit and information verification checks being performed."
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "buttons" }, [
      !_vm.simulation
        ? _c(
            "a",
            {
              staticClass: "btn btn-large btn-plain btn-back",
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.$emit("prev")
                }
              }
            },
            [_vm._v("Back")]
          )
        : _c(
            "a",
            {
              staticClass: "btn btn-large btn-plain btn-back",
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.$emit("reset")
                }
              }
            },
            [_vm._v("Reset")]
          ),
      _vm._v(" "),
      !_vm.simulation
        ? _c(
            "a",
            {
              staticClass: "btn btn-large btn-warn",
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.$emit("save")
                }
              }
            },
            [_vm._v("Save")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.simulation ? _c("div") : _vm._e(),
      _vm._v(" "),
      !_vm.simulation
        ? _c(
            "a",
            {
              staticClass: "btn btn-large btn-success",
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.nextStep.apply(null, arguments)
                }
              }
            },
            [_vm._v("Submit")]
          )
        : _c(
            "a",
            {
              staticClass: "btn btn-large btn-success",
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.$emit("next")
                }
              }
            },
            [_vm._v("Submit")]
          )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("figure", { staticClass: "last-step-head" }, [
      _c("h2", [_vm._v("Uploads")]),
      _vm._v(" "),
      _c("div", { staticClass: "note" }, [
        _vm._v("Please use the section below to upload documents:")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-7-header" }, [
      _c("p", { staticClass: "page-7-right-text" }, [
        _vm._v("Only the following file types are accepted:"),
        _c("br"),
        _vm._v("jpg, jpeg, gif, tif, tiff, pdf")
      ]),
      _vm._v(" "),
      _c("h2", [_vm._v("Required Documents")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "note-title" }, [
      _c("h2", [_vm._v("Notes")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }