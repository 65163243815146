var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "form",
        { staticClass: "edit-system search-form" },
        [
          [_vm._v("Type")],
          _vm._v(" "),
          _c("dyn-select", {
            staticStyle: { width: "100%", "margin-bottom": "20px" },
            attrs: { options: _vm.types },
            model: {
              value: _vm.typeId,
              callback: function($$v) {
                _vm.typeId = $$v
              },
              expression: "typeId"
            }
          }),
          _vm._v(" "),
          _vm.typeId ? [_vm._v("Solution")] : _vm._e(),
          _vm._v(" "),
          _vm.typeId == "Payment"
            ? _c("dyn-select", {
                staticStyle: { width: "100%", "margin-bottom": "20px" },
                attrs: { options: _vm.solutions },
                model: {
                  value: _vm.solutionId,
                  callback: function($$v) {
                    _vm.solutionId = $$v
                  },
                  expression: "solutionId"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.typeId == "Treasury"
            ? _c("dyn-select", {
                staticStyle: { width: "100%", "margin-bottom": "20px" },
                attrs: { options: _vm.treasuries },
                model: {
                  value: _vm.solutionId,
                  callback: function($$v) {
                    _vm.solutionId = $$v
                  },
                  expression: "solutionId"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.solutionId ? [_vm._v("Template")] : _vm._e(),
          _vm._v(" "),
          _vm.solutionId
            ? _c("dyn-select", {
                staticStyle: { width: "100%", "margin-bottom": "20px" },
                attrs: { options: _vm.currentTemplates },
                model: {
                  value: _vm.templateId,
                  callback: function($$v) {
                    _vm.templateId = $$v
                  },
                  expression: "templateId"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.solutionId ? [_vm._v("Name")] : _vm._e(),
          _vm._v(" "),
          _vm.solutionId
            ? _c(
                "validator",
                {
                  attrs: { value: _vm.currentTemplate.name, reqs: "required" }
                },
                [
                  _vm.solutionId
                    ? _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.currentTemplate.name,
                            expression: "currentTemplate.name"
                          }
                        ],
                        staticStyle: { width: "100%", "margin-bottom": "20px" },
                        attrs: {
                          disabled: !_vm.editMode,
                          type: "text",
                          placeholder: "Template name"
                        },
                        domProps: { value: _vm.currentTemplate.name },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.currentTemplate,
                              "name",
                              $event.target.value
                            )
                          }
                        }
                      })
                    : _vm._e()
                ]
              )
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _vm.solutionId && _vm.typeId == "Payment"
        ? _c("pricing-details", {
            attrs: {
              currentTemplate: _vm.currentTemplate,
              solution: _vm.solutionId
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.solutionId && _vm.typeId == "Treasury"
        ? _c("pricing-details-treasury", {
            attrs: {
              currentTemplate: _vm.currentTemplate,
              solution: _vm.solutionId,
              editMode: _vm.editMode
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.solutionId
        ? _c(
            "div",
            { staticClass: "buttons" },
            [
              !_vm.editMode
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-large btn-primary",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          _vm.editMode = true
                        }
                      }
                    },
                    [_vm._v("Update")]
                  )
                : [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-large btn-primary",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.updateTemplate.apply(null, arguments)
                          }
                        }
                      },
                      [_vm._v("Submit")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-large btn-danger",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.deleteTemplate.apply(null, arguments)
                          }
                        }
                      },
                      [_vm._v("Delete")]
                    )
                  ]
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }