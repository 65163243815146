var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    { staticClass: "form-step h3lg quest" },
    [
      _vm._l([_vm.app.questionnaire], function(q) {
        return _c("section", { class: { shaded: !_vm.isRequired } }, [
          _c("h2", [_vm._v("Moto / Internet / Future Services Questionnaire")]),
          _vm._v(" "),
          _c("h3", [_vm._v("General")]),
          _vm._v(" "),
          _c("ul", { staticClass: "column-form type2" }, [
            _c("li", [
              _c("label", [_vm._v("Date")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                [
                  _c(
                    "validator",
                    {
                      attrs: {
                        value: q.date,
                        reqs: _vm.isRequired ? "required" : ""
                      }
                    },
                    [
                      _c("datepicker", {
                        attrs: { future: "today" },
                        model: {
                          value: q.date,
                          callback: function($$v) {
                            _vm.$set(q, "date", $$v)
                          },
                          expression: "q.date"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("li", [
              _c("label", [_vm._v("Legal Business Name")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                [
                  _c("validator", [
                    _c("input", {
                      attrs: { type: "text", readonly: "" },
                      domProps: { value: q.companyName }
                    })
                  ])
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("li", [
              _c("label", [_vm._v("Business DBA Name")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                [
                  _c("validator", [
                    _c("input", {
                      attrs: { type: "text", readonly: "" },
                      domProps: { value: q.companyNameDba }
                    })
                  ])
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c("ul", { staticClass: "column-form type2" }, [
            _c("li", { staticClass: "fwidth fwx" }, [
              _c("label", [
                _c("em", [
                  _vm._v(
                    _vm._s(
                      (q.percentBusiness * 1 || 0) +
                        (q.percentConsumers * 1 || 0)
                    ) + "%"
                  )
                ]),
                _vm._v(" Your percentage of sales "),
                _c("span", [_vm._v("(total should equal 100%)")])
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                [
                  _c(
                    "validator",
                    {
                      staticClass: "multipercent",
                      attrs: {
                        value: [q.percentBusiness, q.percentConsumers],
                        reqs: "sum100"
                      }
                    },
                    [
                      _c("div", [
                        _c("label", [_vm._v("Business")]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: q.percentBusiness,
                              expression: "q.percentBusiness"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: q.percentBusiness },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                q,
                                "percentBusiness",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _c("span", [_vm._v("%")])
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c("label", [_vm._v("Consumers")]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: q.percentConsumers,
                              expression: "q.percentConsumers"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: q.percentConsumers },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                q,
                                "percentConsumers",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _c("span", [_vm._v("%")])
                      ])
                    ]
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "fwidth fwx" }, [
              _vm._m(0, true),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                [
                  _c(
                    "validator",
                    {
                      attrs: {
                        value:
                          q.marketingMethod && q.marketingMethod.length
                            ? q.marketingMethod
                            : q.marketingMethodOther,
                        reqs: _vm.isRequired ? "required" : ""
                      }
                    },
                    [
                      _c("multiselect", {
                        attrs: {
                          other: q.marketingMethodOther,
                          hasOther: "1",
                          options: [
                            "Direct Mail/Brochure/Catalog",
                            "Newspaper / Magazine",
                            "Social Media",
                            "Television / Radio",
                            "Internet",
                            "Inbound / Outbound Telemarketing",
                            "Trade Shows"
                          ]
                        },
                        on: {
                          "update:other": function($event) {
                            return _vm.$set(q, "marketingMethodOther", $event)
                          }
                        },
                        model: {
                          value: q.marketingMethod,
                          callback: function($$v) {
                            _vm.$set(q, "marketingMethod", $$v)
                          },
                          expression: "q.marketingMethod"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "fwidth fwx" }, [
              _c("label", [
                _c("em", [
                  _vm._v(
                    _vm._s(
                      (q.originDomestic * 1 || 0) +
                        (q.originEurope * 1 || 0) +
                        (q.originUSA * 1 || 0) +
                        (q.originOther * 1 || 0)
                    ) + "%"
                  )
                ]),
                _vm._v(" % of Customer Base"),
                _c("br"),
                _vm._v("(E-Commerce) "),
                _c("span", [_vm._v("(total should equal 100%)")])
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                [
                  _c(
                    "validator",
                    {
                      staticClass: "multipercent",
                      attrs: {
                        value: [
                          q.originDomestic,
                          q.originEurope,
                          q.originUSA,
                          q.originOther
                        ],
                        reqs: "sum100"
                      }
                    },
                    [
                      _c("div", [
                        _c("label", [_vm._v("Canada")]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: q.originDomestic,
                              expression: "q.originDomestic"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: q.originDomestic },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(q, "originDomestic", $event.target.value)
                            }
                          }
                        }),
                        _c("span", [_vm._v("%")])
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c("label", [_vm._v("USA")]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: q.originUSA,
                              expression: "q.originUSA"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: q.originUSA },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(q, "originUSA", $event.target.value)
                            }
                          }
                        }),
                        _c("span", [_vm._v("%")])
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c("label", [_vm._v("Europe")]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: q.originEurope,
                              expression: "q.originEurope"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: q.originEurope },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(q, "originEurope", $event.target.value)
                            }
                          }
                        }),
                        _c("span", [_vm._v("%")])
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c("label", [_vm._v("Rest")]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: q.originOther,
                              expression: "q.originOther"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: q.originOther },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(q, "originOther", $event.target.value)
                            }
                          }
                        }),
                        _c("span", [_vm._v("%")]),
                        _vm._v(" "),
                        _c("span", { staticClass: "pfx-info" }, [
                          _vm._v("(Rest of the world)")
                        ])
                      ])
                    ]
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "fwidth fwx" }, [
              _c("label", [_vm._v("When do you charge the card?")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                [
                  _c(
                    "validator",
                    {
                      attrs: {
                        value:
                          q.chargePoint && q.chargePoint.length
                            ? q.chargePoint
                            : q.chargePointOther,
                        reqs: _vm.isRequired ? "required" : ""
                      }
                    },
                    [
                      _c("multiselect", {
                        attrs: {
                          other: q.chargePointOther,
                          hasOther: "1",
                          options: ["Day of Order", "Day of Shipment"],
                          onlyone: true
                        },
                        on: {
                          "update:other": function($event) {
                            return _vm.$set(q, "chargePointOther", $event)
                          }
                        },
                        model: {
                          value: q.chargePoint,
                          callback: function($$v) {
                            _vm.$set(q, "chargePoint", $$v)
                          },
                          expression: "q.chargePoint"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "fwidth fwx" }, [
              _c("label", [
                _vm._v("If future delivery, do you require a deposit?")
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field complex" },
                [
                  _c(
                    "validator",
                    {
                      attrs: {
                        value: q.depositRequired,
                        reqs: _vm.isRequired ? "required" : ""
                      }
                    },
                    [
                      _c(
                        "formel-toggle-slot",
                        {
                          staticClass: "horiz",
                          attrs: { hideslot: true },
                          model: {
                            value: q.depositRequired,
                            callback: function($$v) {
                              _vm.$set(q, "depositRequired", $$v)
                            },
                            expression: "q.depositRequired"
                          }
                        },
                        [
                          _c(
                            "div",
                            {},
                            [
                              _vm._v(
                                "\n                If yes, percent of sale required "
                              ),
                              _c("formel-money-amount", {
                                attrs: { type: "text", suffix: "%" },
                                model: {
                                  value: q.depositPercent,
                                  callback: function($$v) {
                                    _vm.$set(q, "depositPercent", $$v)
                                  },
                                  expression: "q.depositPercent"
                                }
                              }),
                              _vm._v(" or flat fee "),
                              _c("formel-money-amount", {
                                attrs: { type: "text", prefix: "$" },
                                model: {
                                  value: q.depositFee,
                                  callback: function($$v) {
                                    _vm.$set(q, "depositFee", $$v)
                                  },
                                  expression: "q.depositFee"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "fwidth fwx" }, [
              _c("label", [_vm._v("Is final payment due before fulfillment?")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field complex" },
                [
                  _c(
                    "validator",
                    {
                      attrs: {
                        value: q.advancePayment,
                        reqs: _vm.isRequired ? "required" : ""
                      }
                    },
                    [
                      _c(
                        "formel-toggle-slot",
                        {
                          staticClass: "horiz",
                          model: {
                            value: q.advancePayment,
                            callback: function($$v) {
                              _vm.$set(q, "advancePayment", $$v)
                            },
                            expression: "q.advancePayment"
                          }
                        },
                        [
                          _c("div", {}, [
                            _vm._v("\n                If yes, number of days "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: q.depositDays,
                                  expression: "q.depositDays"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: q.depositDays },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    q,
                                    "depositDays",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "fwidth fwx" }, [
              _c("label", [
                _vm._v(
                  "Do your sales involve automatic or negative option billing?"
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                [
                  _c(
                    "validator",
                    {
                      attrs: {
                        value: q.automaticBilling,
                        reqs: _vm.isRequired ? "required" : ""
                      }
                    },
                    [
                      _c("formel-toggle", {
                        model: {
                          value: q.automaticBilling,
                          callback: function($$v) {
                            _vm.$set(q, "automaticBilling", $$v)
                          },
                          expression: "q.automaticBilling"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "fwidth fwx" }, [
              _c("label", [
                _vm._v("Do you offer a product guarantee or warranty?")
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field complex" },
                [
                  _c(
                    "validator",
                    {
                      attrs: {
                        value: q.warranty,
                        reqs: _vm.isRequired ? "required" : ""
                      }
                    },
                    [
                      _c(
                        "formel-toggle-slot",
                        {
                          attrs: { hideslot: true },
                          model: {
                            value: q.warranty,
                            callback: function($$v) {
                              _vm.$set(q, "warranty", $$v)
                            },
                            expression: "q.warranty"
                          }
                        },
                        [
                          _c(
                            "div",
                            {},
                            [
                              _vm._v("\n                If yes, is it "),
                              _c("multiselect", {
                                attrs: {
                                  onlyone: true,
                                  options: [
                                    "Replacement",
                                    "Refund",
                                    "Partial Refund"
                                  ]
                                },
                                model: {
                                  value: q.warrantyMethod,
                                  callback: function($$v) {
                                    _vm.$set(q, "warrantyMethod", $$v)
                                  },
                                  expression: "q.warrantyMethod"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "fwidth fwx" }, [
              _c("label", [_vm._v("Refund policy is within how many days")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                [
                  _c(
                    "validator",
                    {
                      attrs: {
                        value: q.refundDays && q.refundDays.length,
                        reqs: _vm.isRequired ? "required" : ""
                      }
                    },
                    [
                      _c("multiselect", {
                        attrs: {
                          onlyone: true,
                          options: [
                            "30 Days",
                            "31-90 Days",
                            "Greater than 90 days",
                            "No Refunds"
                          ]
                        },
                        model: {
                          value: q.refundDays,
                          callback: function($$v) {
                            _vm.$set(q, "refundDays", $$v)
                          },
                          expression: "q.refundDays"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "fwidth fwx" }, [
              _c("label", [
                _vm._v("Do you offer recurring or subscription billing?")
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field complex" },
                [
                  _c(
                    "validator",
                    {
                      attrs: {
                        value:
                          q.recurring === "0" ||
                          (q.recurringType && q.recurringType.length
                            ? q.recurringType
                            : q.recurringTypeOther),
                        reqs: _vm.isRequired ? "required" : ""
                      }
                    },
                    [
                      _c(
                        "formel-toggle-slot",
                        {
                          attrs: { hideslot: true },
                          model: {
                            value: q.recurring,
                            callback: function($$v) {
                              _vm.$set(q, "recurring", $$v)
                            },
                            expression: "q.recurring"
                          }
                        },
                        [
                          _c("multiselect", {
                            attrs: {
                              onlyone: true,
                              other: q.recurringTypeOther,
                              hasOther: "1",
                              options: [
                                "Weekly",
                                "Monthly",
                                "Quarterly",
                                "Annually"
                              ]
                            },
                            on: {
                              "update:other": function($event) {
                                return _vm.$set(q, "recurringTypeOther", $event)
                              }
                            },
                            model: {
                              value: q.recurringType,
                              callback: function($$v) {
                                _vm.$set(q, "recurringType", $$v)
                              },
                              expression: "q.recurringType"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "fwidth fwx" }, [
              _c("label", [
                _vm._v(
                  "How is the card payment information entered into the system?"
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                [
                  _c(
                    "validator",
                    {
                      attrs: {
                        value:
                          q.cardEntry && q.cardEntry.length
                            ? q.cardEntry
                            : q.cardEntryOther,
                        reqs: _vm.isRequired ? "required" : ""
                      }
                    },
                    [
                      _c("multiselect", {
                        attrs: {
                          onlyone: true,
                          other: q.cardEntryOther,
                          hasOther: "1",
                          options: ["Merchant", "Consumer", "Both"]
                        },
                        on: {
                          "update:other": function($event) {
                            return _vm.$set(q, "cardEntryOther", $event)
                          }
                        },
                        model: {
                          value: q.cardEntry,
                          callback: function($$v) {
                            _vm.$set(q, "cardEntry", $$v)
                          },
                          expression: "q.cardEntry"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "fwidth fwx" }, [
              _c("label", [
                _vm._v("Is card payment information entered via the Internet?")
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                [
                  _c(
                    "validator",
                    {
                      attrs: {
                        value: q.viaInternet,
                        reqs: _vm.isRequired ? "required" : ""
                      }
                    },
                    [
                      _c("formel-toggle", {
                        model: {
                          value: q.viaInternet,
                          callback: function($$v) {
                            _vm.$set(q, "viaInternet", $$v)
                          },
                          expression: "q.viaInternet"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "fwidth fwx" }, [
              _c("label", [
                _vm._v("Is the payment channel encrypted by SSL or better?")
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                [
                  _c(
                    "validator",
                    {
                      attrs: {
                        value: q.encrypted,
                        reqs: _vm.isRequired ? "required" : ""
                      }
                    },
                    [
                      _c("formel-toggle", {
                        model: {
                          value: q.encrypted,
                          callback: function($$v) {
                            _vm.$set(q, "encrypted", $$v)
                          },
                          expression: "q.encrypted"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c("h3", [
            _vm._v(
              "Not Applicable For Services, Virtual or Downloadable Products"
            )
          ]),
          _vm._v(" "),
          _c("ul", { staticClass: "column-form type2" }, [
            _c("li", { staticClass: "fwidth fwx" }, [
              _c("label", [_vm._v("Do you own the product/inventory?")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field complex" },
                [
                  _c(
                    "validator",
                    {
                      attrs: {
                        value: q.ownsProduct,
                        reqs: _vm.isRequired ? "required" : ""
                      }
                    },
                    [
                      _c(
                        "formel-toggle-slot",
                        {
                          attrs: { hideslot: "inverse" },
                          model: {
                            value: q.ownsProduct,
                            callback: function($$v) {
                              _vm.$set(q, "ownsProduct", $$v)
                            },
                            expression: "q.ownsProduct"
                          }
                        },
                        [
                          _vm._v(
                            "\n              If no, who owns the product/inventory?\n              "
                          ),
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: q.ownsProductOther,
                                expression: "q.ownsProductOther"
                              }
                            ],
                            staticStyle: { resize: "none" },
                            domProps: { value: q.ownsProductOther },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  q,
                                  "ownsProductOther",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "fwidth fwx" }, [
              _c("label", [
                _vm._v("Where is the product stored/shipped from?")
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                [
                  _c(
                    "validator",
                    { attrs: { value: q.shippedFrom, reqs: "" } },
                    [
                      _c("ul", { staticClass: "multiselect" }, [
                        _c("li", [
                          _c(
                            "label",
                            {
                              class: {
                                checked:
                                  q.shippedFrom.indexOf("Business Location") !=
                                  -1
                              }
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: q.shippedFrom,
                                    expression: "q.shippedFrom"
                                  }
                                ],
                                attrs: {
                                  type: "checkbox",
                                  value: "Business Location"
                                },
                                domProps: {
                                  checked: Array.isArray(q.shippedFrom)
                                    ? _vm._i(
                                        q.shippedFrom,
                                        "Business Location"
                                      ) > -1
                                    : q.shippedFrom
                                },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    q.shippedFrom = ["Business Location"]
                                  },
                                  change: function($event) {
                                    var $$a = q.shippedFrom,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = "Business Location",
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            q,
                                            "shippedFrom",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            q,
                                            "shippedFrom",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(q, "shippedFrom", $$c)
                                    }
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("span"),
                              _vm._v(" "),
                              _c("i", { staticClass: "bn-con" }, [
                                _vm._v("Business Location")
                              ])
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c(
                            "label",
                            {
                              class: {
                                checked:
                                  q.shippedFrom.indexOf("Own Warehouse") != -1
                              }
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: q.shippedFrom,
                                    expression: "q.shippedFrom"
                                  }
                                ],
                                attrs: {
                                  type: "checkbox",
                                  value: "Own Warehouse"
                                },
                                domProps: {
                                  checked: Array.isArray(q.shippedFrom)
                                    ? _vm._i(q.shippedFrom, "Own Warehouse") >
                                      -1
                                    : q.shippedFrom
                                },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    q.shippedFrom = ["Own Warehouse"]
                                  },
                                  change: function($event) {
                                    var $$a = q.shippedFrom,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = "Own Warehouse",
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            q,
                                            "shippedFrom",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            q,
                                            "shippedFrom",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(q, "shippedFrom", $$c)
                                    }
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("span"),
                              _vm._v(" "),
                              _c("i", { staticClass: "bn-con" }, [
                                _vm._v("Own Warehouse")
                              ])
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", { staticClass: "other" }, [
                          _c(
                            "label",
                            {
                              class: {
                                checked:
                                  q.shippedFrom.indexOf("Fulfillment Center") !=
                                  -1
                              }
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: q.shippedFrom,
                                    expression: "q.shippedFrom"
                                  }
                                ],
                                attrs: {
                                  type: "checkbox",
                                  value: "Fulfillment Center"
                                },
                                domProps: {
                                  checked: Array.isArray(q.shippedFrom)
                                    ? _vm._i(
                                        q.shippedFrom,
                                        "Fulfillment Center"
                                      ) > -1
                                    : q.shippedFrom
                                },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    q.shippedFrom = ["Fulfillment Center"]
                                  },
                                  change: function($event) {
                                    var $$a = q.shippedFrom,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = "Fulfillment Center",
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            q,
                                            "shippedFrom",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            q,
                                            "shippedFrom",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(q, "shippedFrom", $$c)
                                    }
                                  }
                                }
                              }),
                              _c("span"),
                              _vm._v("Fulfillment Center")
                            ]
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "validator",
                          staticStyle: {
                            "font-weight": "bold",
                            "font-size": "12px"
                          }
                        },
                        [
                          _vm._v("\n              Company "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: q.fcCompany,
                                expression: "q.fcCompany"
                              }
                            ],
                            staticStyle: {
                              width: "140px",
                              "margin-right": "20px"
                            },
                            style: {
                              background:
                                q.shippedFrom.indexOf("Fulfillment Center") ==
                                -1
                                  ? "#e8e8e7"
                                  : ""
                            },
                            attrs: {
                              disabled:
                                q.shippedFrom.indexOf("Fulfillment Center") ==
                                -1,
                              type: "text"
                            },
                            domProps: { value: q.fcCompany },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(q, "fcCompany", $event.target.value)
                              }
                            }
                          }),
                          _vm._v("\n              Contact name "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: q.fcContactName,
                                expression: "q.fcContactName"
                              }
                            ],
                            staticStyle: {
                              width: "140px",
                              "margin-right": "20px"
                            },
                            style: {
                              background:
                                q.shippedFrom.indexOf("Fulfillment Center") ==
                                -1
                                  ? "#e8e8e7"
                                  : ""
                            },
                            attrs: {
                              disabled:
                                q.shippedFrom.indexOf("Fulfillment Center") ==
                                -1,
                              type: "text"
                            },
                            domProps: { value: q.fcContactName },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  q,
                                  "fcContactName",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _vm._v("\n              Contact phone "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: q.fcContactPhone,
                                expression: "q.fcContactPhone"
                              }
                            ],
                            staticStyle: { width: "140px" },
                            style: {
                              background:
                                q.shippedFrom.indexOf("Fulfillment Center") ==
                                -1
                                  ? "#e8e8e7"
                                  : ""
                            },
                            attrs: {
                              disabled:
                                q.shippedFrom.indexOf("Fulfillment Center") ==
                                -1,
                              type: "text"
                            },
                            domProps: { value: q.fcContactPhone },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  q,
                                  "fcContactPhone",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ]
                      )
                    ]
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "fwidth fwx" }, [
              _c("label", [_vm._v("Method of Delivery?")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                [
                  _c(
                    "validator",
                    {
                      attrs: {
                        value:
                          q.deliveryMethod && q.deliveryMethod.length
                            ? q.deliveryMethod
                            : q.deliveryMethodOther,
                        reqs: _vm.isRequired ? "" : ""
                      }
                    },
                    [
                      _c("multiselect", {
                        attrs: {
                          onlyone: true,
                          other: q.deliveryMethodOther,
                          hasOther: "1",
                          options: [
                            "Canada Post",
                            "USPS",
                            "FedEx",
                            "UPS",
                            "Courier",
                            "DHL"
                          ]
                        },
                        on: {
                          "update:other": function($event) {
                            return _vm.$set(q, "deliveryMethodOther", $event)
                          }
                        },
                        model: {
                          value: q.deliveryMethod,
                          callback: function($$v) {
                            _vm.$set(q, "deliveryMethod", $$v)
                          },
                          expression: "q.deliveryMethod"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "fwidth fwx" }, [
              _c("label", [_vm._v("Is a tracking number provided?")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                [
                  _c(
                    "validator",
                    {
                      attrs: {
                        value: q.trackingProvided,
                        reqs:
                          _vm.isRequired &&
                          q.deliveryMethod &&
                          q.deliveryMethod.length
                            ? "required"
                            : ""
                      }
                    },
                    [
                      _c("formel-toggle", {
                        model: {
                          value: q.trackingProvided,
                          callback: function($$v) {
                            _vm.$set(q, "trackingProvided", $$v)
                          },
                          expression: "q.trackingProvided"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c("h3", [_vm._v("TRADE REFERENCE")]),
          _vm._v(" "),
          _c("ul", { staticClass: "column-form type2" }, [
            _c("li", [
              _c("label", [_vm._v("Contact Name")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                [
                  _c(
                    "validator",
                    {
                      attrs: {
                        value: q.refName,
                        reqs: _vm.isRequired ? "" : ""
                      }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: q.refName,
                            expression: "q.refName"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: q.refName },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(q, "refName", $event.target.value)
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("li", [
              _c("label", [_vm._v("Contact Title")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                [
                  _c(
                    "validator",
                    {
                      attrs: {
                        value: q.refTitle,
                        reqs: _vm.isRequired ? "" : ""
                      }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: q.refTitle,
                            expression: "q.refTitle"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: q.refTitle },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(q, "refTitle", $event.target.value)
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("li", [
              _c("label", [_vm._v("Contact Phone")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                [
                  _c(
                    "validator",
                    {
                      attrs: {
                        value: q.refPhone,
                        reqs: _vm.isRequired ? "phone" : ""
                      }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: q.refPhone,
                            expression: "q.refPhone"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: q.refPhone },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(q, "refPhone", $event.target.value)
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("li", [
              _c("label", [_vm._v("Contact Email")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                [
                  _c(
                    "validator",
                    {
                      attrs: {
                        value: q.refEmail,
                        reqs: _vm.isRequired ? "" : ""
                      }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: q.refEmail,
                            expression: "q.refEmail"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: q.refEmail },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(q, "refEmail", $event.target.value)
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("li", [
              _c("label", [_vm._v("Company Name")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                [
                  _c(
                    "validator",
                    {
                      attrs: {
                        value: q.refCompanyName,
                        reqs: _vm.isRequired ? "" : ""
                      }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: q.refCompanyName,
                            expression: "q.refCompanyName"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: q.refCompanyName },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(q, "refCompanyName", $event.target.value)
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("li", [
              _c("label", [_vm._v("Company Address")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                [
                  _c(
                    "validator",
                    {
                      attrs: {
                        value: q.refCompanyAddress,
                        reqs: _vm.isRequired ? "" : ""
                      }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: q.refCompanyAddress,
                            expression: "q.refCompanyAddress"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: q.refCompanyAddress },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              q,
                              "refCompanyAddress",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "extw" }, [
              _c("label", [_vm._v("Company City, Prov/State, Postal/Zip")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                [
                  _c(
                    "validator",
                    {
                      attrs: {
                        value: q.refCompanyAddress2,
                        reqs: _vm.isRequired ? "" : ""
                      }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: q.refCompanyAddress2,
                            expression: "q.refCompanyAddress2"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: q.refCompanyAddress2 },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              q,
                              "refCompanyAddress2",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("li", [_vm._v("\n         \n      ")])
          ]),
          _vm._v(" "),
          !_vm.isRequired ? _c("div", { staticClass: "shade" }) : _vm._e(),
          _vm._v(" "),
          !_vm.isRequired
            ? _c("div", { staticClass: "shade-message shade-high" }, [
                _c("p", [
                  _vm._v(
                    "This form is not required to be completed for your type of business"
                  )
                ])
              ])
            : _vm._e()
        ])
      }),
      _vm._v(" "),
      !_vm.onlyStep
        ? _c("div", { staticClass: "buttons" }, [
            _c(
              "a",
              {
                staticClass: "btn btn-large btn-plain btn-back",
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.$emit("prev")
                  }
                }
              },
              [_vm._v("Back")]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "btn btn-large btn-warn",
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.$emit("save")
                  }
                }
              },
              [_vm._v("Save")]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "btn btn-large btn-success btn-next",
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.nextStep.apply(null, arguments)
                  }
                }
              },
              [_vm._v("Next Step")]
            )
          ])
        : _c("div", { staticClass: "buttons" }, [
            _c(
              "a",
              {
                staticClass: "btn btn-large btn-success btn-next",
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.$emit("generate")
                  }
                }
              },
              [_vm._v("Submit")]
            )
          ])
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [
      _vm._v("Method of Marketing "),
      _c("br"),
      _vm._v("(check all that apply)")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }