var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "upload-input" },
    [
      _c("form-field-text", {
        class: _vm.classes,
        attrs: {
          value: _vm.value ? _vm.value.name : null,
          label: _vm.label,
          validator: _vm.validator,
          required: _vm.required,
          disabled: ""
        }
      }),
      _vm._v(" "),
      _c(
        "button",
        {
          class: _vm.btnClasses,
          attrs: { disabled: _vm.disabled },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.$refs.file.click()
            }
          }
        },
        [_vm._v(_vm._s(_vm.$t("register.choose_file")))]
      ),
      _vm._v(" "),
      _c("input", {
        directives: [
          { name: "show", rawName: "v-show", value: false, expression: "false" }
        ],
        ref: "file",
        attrs: { type: "file" },
        on: {
          change: function($event) {
            return _vm.uploadFile($event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }