var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "message-history" },
    _vm._l(_vm.messages, function(message, mi) {
      return _c("li", [
        _c("p", { staticClass: "head" }, [
          _vm._v("\n      Message posted by "),
          _c("a", [_vm._v(_vm._s(message.author))]),
          _vm._v(" on "),
          _c("time", [
            _vm._v(_vm._s(_vm.formatDate(message.date, "YYYY/MM/DD")))
          ])
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "message" }, [
          _vm._v("\n      " + _vm._s(message.message) + "\n    ")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "dashboard-flag" }, [
          _c("label", [_vm._v("DASHBOARD ONLY MESSAGE:")]),
          _vm._v(" "),
          _c(
            "span",
            {
              class: {
                danger: message.dashboard == 0,
                success: message.dashboard == 1
              }
            },
            [_vm._v(_vm._s(message.dashboard == 1 ? "YES" : "NO"))]
          )
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "message-delete btn btn-danger",
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.deleteMessage(mi)
              }
            }
          },
          [_vm._v("Delete message")]
        )
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }