var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return (_vm.isNew || _vm.itemId) && !_vm.newComplete
    ? _c(
        "form",
        { staticClass: "edit-system search-form" },
        [
          _c("div", { staticClass: "twocol" }, [
            _c("div", [
              _c("div", { staticClass: "field" }, [
                _c("label", [_vm._v("Solution Prefix:")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.item.solutionAccountPrefix,
                      expression: "item.solutionAccountPrefix"
                    }
                  ],
                  staticClass: "pin-code",
                  attrs: {
                    oninput:
                      "javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);",
                    type: "number",
                    maxlength: "6",
                    minlength: "1",
                    required: "",
                    disabled: !_vm.isNew && !_vm.editable
                  },
                  domProps: { value: _vm.item.solutionAccountPrefix },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.item,
                        "solutionAccountPrefix",
                        $event.target.value
                      )
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "field" }, [
                _c("label", [_vm._v("Solution Nickname:")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.item.nameSolution,
                      expression: "item.nameSolution"
                    }
                  ],
                  attrs: { disabled: !_vm.isNew && !_vm.editable },
                  domProps: { value: _vm.item.nameSolution },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.item, "nameSolution", $event.target.value)
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "field" }, [
                _c("label", [_vm._v("Solution Name:")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.item.name,
                      expression: "item.name"
                    }
                  ],
                  attrs: { disabled: !_vm.isNew && !_vm.editable },
                  domProps: { value: _vm.item.name },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.item, "name", $event.target.value)
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "field" }, [
                _c("label", [_vm._v("Solution DBA Name:")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.item.nameDba,
                      expression: "item.nameDba"
                    }
                  ],
                  attrs: { disabled: !_vm.isNew && !_vm.editable },
                  domProps: { value: _vm.item.nameDba },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.item, "nameDba", $event.target.value)
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "field" }, [
                _c("label", [_vm._v("Solution Address:")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.item.address,
                      expression: "item.address"
                    }
                  ],
                  attrs: { disabled: !_vm.isNew && !_vm.editable },
                  domProps: { value: _vm.item.address },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.item, "address", $event.target.value)
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "field" }, [
                _c("label", [_vm._v("Solution City:")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.item.city,
                      expression: "item.city"
                    }
                  ],
                  attrs: { disabled: !_vm.isNew && !_vm.editable },
                  domProps: { value: _vm.item.city },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.item, "city", $event.target.value)
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "field" }, [
                _c("label", [_vm._v("Solution State:")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.item.state,
                      expression: "item.state"
                    }
                  ],
                  attrs: { disabled: !_vm.isNew && !_vm.editable },
                  domProps: { value: _vm.item.state },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.item, "state", $event.target.value)
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "field" }, [
                _c("label", [_vm._v("Solution Zip/Postal Code:")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.item.postcode,
                      expression: "item.postcode"
                    }
                  ],
                  attrs: { disabled: !_vm.isNew && !_vm.editable },
                  domProps: { value: _vm.item.postcode },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.item, "postcode", $event.target.value)
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "field" }, [
                _c("label", [_vm._v("Send-From Email:")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.item.sendFrom,
                      expression: "item.sendFrom"
                    }
                  ],
                  attrs: { disabled: !_vm.isNew && !_vm.editable },
                  domProps: { value: _vm.item.sendFrom },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.item, "sendFrom", $event.target.value)
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _vm.type == "Employees"
                ? _c("div", { staticClass: "field" }, [
                    _c("label", [_vm._v("Email Suffix:")]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.item.emailSuffix,
                          expression: "item.emailSuffix"
                        }
                      ],
                      attrs: { disabled: !_vm.isNew && !_vm.editable },
                      domProps: { value: _vm.item.emailSuffix },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.item, "emailSuffix", $event.target.value)
                        }
                      }
                    })
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "field" },
                [
                  _c("label", [_vm._v("Logo:")]),
                  _vm._v(" "),
                  _c("photo-obj", {
                    attrs: {
                      basepath: _vm.basePath,
                      disabled: !_vm.isNew && !_vm.editable
                    },
                    model: {
                      value: _vm.logoFile,
                      callback: function($$v) {
                        _vm.logoFile = $$v
                      },
                      expression: "logoFile"
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              [
                _c("div", { staticClass: "field" }, [
                  _c("label", [_vm._v("Solution Support Telephone?:")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.item.supportTel,
                        expression: "item.supportTel"
                      }
                    ],
                    attrs: { disabled: !_vm.isNew && !_vm.editable },
                    domProps: { value: _vm.item.supportTel },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.item, "supportTel", $event.target.value)
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "field" }, [
                  _c("label", [_vm._v("Solution Email:")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.item.email,
                        expression: "item.email"
                      }
                    ],
                    attrs: { disabled: !_vm.isNew && !_vm.editable },
                    domProps: { value: _vm.item.email },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.item, "email", $event.target.value)
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "field" }, [
                  _c("label", [_vm._v("Solution Support Email:")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.item.supportEmail,
                        expression: "item.supportEmail"
                      }
                    ],
                    attrs: { disabled: !_vm.isNew && !_vm.editable },
                    domProps: { value: _vm.item.supportEmail },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.item, "supportEmail", $event.target.value)
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "field" }, [
                  _c("label", [_vm._v("Solution Website:")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.item.website,
                        expression: "item.website"
                      }
                    ],
                    attrs: { disabled: !_vm.isNew && !_vm.editable },
                    domProps: { value: _vm.item.website },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.item, "website", $event.target.value)
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "field" }, [
                  _c("label", [_vm._v("Solution Slogan:")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.item.slogan,
                        expression: "item.slogan"
                      }
                    ],
                    attrs: { disabled: !_vm.isNew && !_vm.editable },
                    domProps: { value: _vm.item.slogan },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.item, "slogan", $event.target.value)
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _vm.type == "Merchants"
                  ? [
                      _c("div", { staticClass: "field" }, [
                        _c("label", [_vm._v("Qualification Link:")]),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            attrs: {
                              target: "_blank",
                              href: _vm.pubLink + "-q"
                            }
                          },
                          [_vm._v(_vm._s(_vm.pubLink + "-q"))]
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "field" }, [
                        _c("label", [_vm._v("Application Link:")]),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            attrs: {
                              target: "_blank",
                              href: _vm.pubLink + "-m"
                            }
                          },
                          [_vm._v(_vm._s(_vm.pubLink + "-m"))]
                        )
                      ])
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.type == "Banking"
                  ? [
                      _c("div", { staticClass: "field" }, [
                        _c("label", [_vm._v("Application Link:")]),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            attrs: {
                              target: "_blank",
                              href: _vm.pubLink + "-t"
                            }
                          },
                          [_vm._v(_vm._s(_vm.pubLink + "-t"))]
                        )
                      ])
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.type == "Partners"
                  ? [
                      _c("div", { staticClass: "field" }, [
                        _c("label", [_vm._v("Application Link:")]),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            attrs: {
                              target: "_blank",
                              href: _vm.pubLink + "-p"
                            }
                          },
                          [_vm._v(_vm._s(_vm.pubLink + "-p"))]
                        )
                      ])
                    ]
                  : _vm._e()
              ],
              2
            )
          ]),
          _vm._v(" "),
          _vm.type == "Merchants"
            ? _c("edit-payment-opts2", {
                key: _vm.item.id || "new",
                ref: "payOpts",
                attrs: {
                  item: _vm.item,
                  isNew: _vm.isNew,
                  editable: _vm.editable
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.type == "Banking"
            ? _c("edit-payment-opts3", {
                key: _vm.item.id || "new",
                ref: "payOpts",
                attrs: {
                  item: _vm.item,
                  isNew: _vm.isNew,
                  editable: _vm.editable
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.type == "Partners"
            ? _c("h2", [_vm._v("Agent signup form")])
            : _vm._e(),
          _vm._v(" "),
          _vm.type == "Partners"
            ? _c("div", { staticClass: "twocol" }, [
                _c("div", [
                  _c("div", { staticClass: "field" }, [
                    _c("label", [_vm._v("Header Assistance Text:")]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.item.agentHeadText,
                          expression: "item.agentHeadText"
                        }
                      ],
                      attrs: { disabled: !_vm.isNew && !_vm.editable },
                      domProps: { value: _vm.item.agentHeadText },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.item,
                            "agentHeadText",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "field" }, [
                    _c("label", [_vm._v("Header Assistance Email:")]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.item.agentHeadEmail,
                          expression: "item.agentHeadEmail"
                        }
                      ],
                      attrs: { disabled: !_vm.isNew && !_vm.editable },
                      domProps: { value: _vm.item.agentHeadEmail },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.item,
                            "agentHeadEmail",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "field" }, [
                    _c("label", [_vm._v("Header Assistance Phone:")]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.item.agentHeadPhone,
                          expression: "item.agentHeadPhone"
                        }
                      ],
                      attrs: { disabled: !_vm.isNew && !_vm.editable },
                      domProps: { value: _vm.item.agentHeadPhone },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.item,
                            "agentHeadPhone",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ])
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "div",
                    { staticClass: "field" },
                    [
                      _c("label", [_vm._v("Header Logo:")]),
                      _vm._v(" "),
                      _c("photo-obj", {
                        attrs: {
                          basepath: _vm.basePath,
                          disabled: !_vm.isNew && !_vm.editable
                        },
                        model: {
                          value: _vm.agentHeadLogo,
                          callback: function($$v) {
                            _vm.agentHeadLogo = $$v
                          },
                          expression: "agentHeadLogo"
                        }
                      })
                    ],
                    1
                  )
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.type == "Partners"
            ? _c("div", { staticClass: "field" }, [
                _c("label", { staticStyle: { width: "calc(16% - 10px)" } }, [
                  _vm._v("Header Description:")
                ]),
                _vm._v(" "),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.item.agentHeadDesc,
                      expression: "item.agentHeadDesc"
                    }
                  ],
                  staticStyle: { width: "84%" },
                  attrs: { disabled: !_vm.isNew && !_vm.editable },
                  domProps: { value: _vm.item.agentHeadDesc },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.item, "agentHeadDesc", $event.target.value)
                    }
                  }
                })
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.type == "Merchants"
            ? [
                _c("h2", [_vm._v("Merchant application form")]),
                _vm._v(" "),
                _c("div", { staticClass: "twocol" }, [
                  _c("div", [
                    _c("div", { staticClass: "field" }, [
                      _c("label", [_vm._v("Header Assistance Text:")]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.item.appHeadText,
                            expression: "item.appHeadText"
                          }
                        ],
                        attrs: { disabled: !_vm.isNew && !_vm.editable },
                        domProps: { value: _vm.item.appHeadText },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.item,
                              "appHeadText",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "field" }, [
                      _c("label", [_vm._v("Header Assistance Email:")]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.item.appHeadEmail,
                            expression: "item.appHeadEmail"
                          }
                        ],
                        attrs: { disabled: !_vm.isNew && !_vm.editable },
                        domProps: { value: _vm.item.appHeadEmail },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.item,
                              "appHeadEmail",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "field" }, [
                      _c("label", [_vm._v("Header Assistance Phone:")]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.item.appHeadPhone,
                            expression: "item.appHeadPhone"
                          }
                        ],
                        attrs: { disabled: !_vm.isNew && !_vm.editable },
                        domProps: { value: _vm.item.appHeadPhone },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.item,
                              "appHeadPhone",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "div",
                      { staticClass: "field" },
                      [
                        _c("label", [_vm._v("Header Logo:")]),
                        _vm._v(" "),
                        _c("photo-obj", {
                          attrs: {
                            basepath: _vm.basePath,
                            disabled: !_vm.isNew && !_vm.editable
                          },
                          model: {
                            value: _vm.appHeadLogo,
                            callback: function($$v) {
                              _vm.appHeadLogo = $$v
                            },
                            expression: "appHeadLogo"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "field" }, [
                  _c("label", { staticStyle: { width: "calc(16% - 10px)" } }, [
                    _vm._v("Header Description:")
                  ]),
                  _vm._v(" "),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.item.appHeadDesc,
                        expression: "item.appHeadDesc"
                      }
                    ],
                    staticStyle: { width: "84%" },
                    attrs: { disabled: !_vm.isNew && !_vm.editable },
                    domProps: { value: _vm.item.appHeadDesc },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.item, "appHeadDesc", $event.target.value)
                      }
                    }
                  })
                ])
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.type == "Banking"
            ? [
                _c("h2", [_vm._v("Treasury application form")]),
                _vm._v(" "),
                _c("div", { staticClass: "twocol" }, [
                  _c("div", [
                    _c("div", { staticClass: "field" }, [
                      _c("label", [_vm._v("Header Assistance Text:")]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.item.appHeadText,
                            expression: "item.appHeadText"
                          }
                        ],
                        attrs: { disabled: !_vm.isNew && !_vm.editable },
                        domProps: { value: _vm.item.appHeadText },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.item,
                              "appHeadText",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "field" }, [
                      _c("label", [_vm._v("Header Assistance Email:")]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.item.appHeadEmail,
                            expression: "item.appHeadEmail"
                          }
                        ],
                        attrs: { disabled: !_vm.isNew && !_vm.editable },
                        domProps: { value: _vm.item.appHeadEmail },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.item,
                              "appHeadEmail",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "field" }, [
                      _c("label", [_vm._v("Header Assistance Phone:")]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.item.appHeadPhone,
                            expression: "item.appHeadPhone"
                          }
                        ],
                        attrs: { disabled: !_vm.isNew && !_vm.editable },
                        domProps: { value: _vm.item.appHeadPhone },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.item,
                              "appHeadPhone",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "div",
                      { staticClass: "field" },
                      [
                        _c("label", [_vm._v("Header Logo:")]),
                        _vm._v(" "),
                        _c("photo-obj", {
                          attrs: {
                            basepath: _vm.basePath,
                            disabled: !_vm.isNew && !_vm.editable
                          },
                          model: {
                            value: _vm.appHeadLogo,
                            callback: function($$v) {
                              _vm.appHeadLogo = $$v
                            },
                            expression: "appHeadLogo"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "field" }, [
                  _c("label", { staticStyle: { width: "calc(16% - 10px)" } }, [
                    _vm._v("Header Description:")
                  ]),
                  _vm._v(" "),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.item.appHeadDesc,
                        expression: "item.appHeadDesc"
                      }
                    ],
                    staticStyle: { width: "84%" },
                    attrs: { disabled: !_vm.isNew && !_vm.editable },
                    domProps: { value: _vm.item.appHeadDesc },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.item, "appHeadDesc", $event.target.value)
                      }
                    }
                  })
                ])
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.type == "Merchants"
            ? [
                _c("h2", [_vm._v("Merchant prequalification form")]),
                _vm._v(" "),
                _c("div", { staticClass: "twocol" }, [
                  _c("div", [
                    _c("div", { staticClass: "field" }, [
                      _c("label", [_vm._v("Header Assistance Text:")]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.item.preqHeadText,
                            expression: "item.preqHeadText"
                          }
                        ],
                        attrs: { disabled: !_vm.isNew && !_vm.editable },
                        domProps: { value: _vm.item.preqHeadText },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.item,
                              "preqHeadText",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "field" }, [
                      _c("label", [_vm._v("Header Assistance Email:")]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.item.preqHeadEmail,
                            expression: "item.preqHeadEmail"
                          }
                        ],
                        attrs: { disabled: !_vm.isNew && !_vm.editable },
                        domProps: { value: _vm.item.preqHeadEmail },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.item,
                              "preqHeadEmail",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "field" }, [
                      _c("label", [_vm._v("Header Assistance Phone:")]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.item.preqHeadPhone,
                            expression: "item.preqHeadPhone"
                          }
                        ],
                        attrs: { disabled: !_vm.isNew && !_vm.editable },
                        domProps: { value: _vm.item.preqHeadPhone },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.item,
                              "preqHeadPhone",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "div",
                      { staticClass: "field" },
                      [
                        _c("label", [_vm._v("Header Logo:")]),
                        _vm._v(" "),
                        _c("photo-obj", {
                          attrs: {
                            basepath: _vm.basePath,
                            disabled: !_vm.isNew && !_vm.editable
                          },
                          model: {
                            value: _vm.preqHeadLogo,
                            callback: function($$v) {
                              _vm.preqHeadLogo = $$v
                            },
                            expression: "preqHeadLogo"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "field" }, [
                  _c("label", { staticStyle: { width: "calc(16% - 10px)" } }, [
                    _vm._v("Header Description:")
                  ]),
                  _vm._v(" "),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.item.preqHeadDesc,
                        expression: "item.preqHeadDesc"
                      }
                    ],
                    staticStyle: { width: "84%" },
                    attrs: { disabled: !_vm.isNew && !_vm.editable },
                    domProps: { value: _vm.item.preqHeadDesc },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.item, "preqHeadDesc", $event.target.value)
                      }
                    }
                  })
                ])
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.type == "Merchants"
            ? [
                _c("h2", [_vm._v("Merchant Profile")]),
                _vm._v(" "),
                _c("div", { staticClass: "twocol" }, [
                  _c("div", [
                    _c("div", { staticClass: "field" }, [
                      _c("label", [_vm._v("Header Assistance Text:")]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.item.intakeHeadText,
                            expression: "item.intakeHeadText"
                          }
                        ],
                        attrs: { disabled: !_vm.isNew && !_vm.editable },
                        domProps: { value: _vm.item.intakeHeadText },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.item,
                              "intakeHeadText",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "field" }, [
                      _c("label", [_vm._v("Header Assistance Email:")]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.item.intakeHeadEmail,
                            expression: "item.intakeHeadEmail"
                          }
                        ],
                        attrs: { disabled: !_vm.isNew && !_vm.editable },
                        domProps: { value: _vm.item.intakeHeadEmail },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.item,
                              "intakeHeadEmail",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "field" }, [
                      _c("label", [_vm._v("Header Assistance Phone:")]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.item.intakeHeadPhone,
                            expression: "item.intakeHeadPhone"
                          }
                        ],
                        attrs: { disabled: !_vm.isNew && !_vm.editable },
                        domProps: { value: _vm.item.intakeHeadPhone },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.item,
                              "intakeHeadPhone",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "div",
                      { staticClass: "field" },
                      [
                        _c("label", [_vm._v("Header Logo:")]),
                        _vm._v(" "),
                        _c("photo-obj", {
                          attrs: {
                            basepath: _vm.basePath,
                            disabled: !_vm.isNew && !_vm.editable
                          },
                          model: {
                            value: _vm.intakeHeadLogo,
                            callback: function($$v) {
                              _vm.intakeHeadLogo = $$v
                            },
                            expression: "intakeHeadLogo"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "field" }, [
                  _c("label", { staticStyle: { width: "calc(16% - 10px)" } }, [
                    _vm._v("Header Description:")
                  ]),
                  _vm._v(" "),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.item.intakeHeadDesc,
                        expression: "item.intakeHeadDesc"
                      }
                    ],
                    staticStyle: { width: "84%" },
                    attrs: { disabled: !_vm.isNew && !_vm.editable },
                    domProps: { value: _vm.item.intakeHeadDesc },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.item,
                          "intakeHeadDesc",
                          $event.target.value
                        )
                      }
                    }
                  })
                ])
              ]
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "buttons" },
            [
              _vm.isNew
                ? [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-large btn-primary",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.updateSettings.apply(null, arguments)
                          }
                        }
                      },
                      [_vm._v("Save")]
                    )
                  ]
                : [
                    !_vm.editable
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-large btn-primary",
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                _vm.editable = true
                              }
                            }
                          },
                          [_vm._v("Update")]
                        )
                      : _c(
                          "button",
                          {
                            staticClass: "btn btn-large btn-primary",
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.updateSettings.apply(null, arguments)
                              }
                            }
                          },
                          [_vm._v("Save")]
                        )
                  ]
            ],
            2
          )
        ],
        2
      )
    : _vm.newComplete
    ? _c("div", { staticStyle: { "text-align": "center" } }, [
        _vm._v("\n    (refreshing)\n  ")
      ])
    : _c("div", { staticStyle: { "text-align": "center" } }, [
        _vm._v("\n    (no solution selected)\n  ")
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }