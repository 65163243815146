var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", [
        !_vm.newCheck && !_vm.uwShown
          ? _c("div", { staticClass: "action-sub-buttons" }, [
              _c("a", { staticClass: "btn", on: { click: _vm.newCalc } }, [
                _vm._v("New")
              ]),
              _vm._v(" "),
              _vm.uwSelected
                ? _c(
                    "a",
                    {
                      staticClass: "btn",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.doShowItems(_vm.uwSelected)
                        }
                      }
                    },
                    [_vm._v("View")]
                  )
                : _vm._e()
            ])
          : _c("div", { staticClass: "action-sub-buttons" }, [
              _c(
                "a",
                {
                  staticClass: "btn",
                  on: {
                    click: function($event) {
                      _vm.uwShown = null
                      _vm.newCheck = null
                    }
                  }
                },
                [_vm._v("Back")]
              ),
              _vm._v(" "),
              _vm.newCheck
                ? _c("a", { staticClass: "btn", on: { click: _vm.saveNew } }, [
                    _vm._v("Save")
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.uwShown
                ? _c(
                    "a",
                    {
                      staticClass: "btn",
                      attrs: { href: _vm.printUrl, target: "_blank" }
                    },
                    [_vm._v("Print")]
                  )
                : _vm._e()
            ])
      ]),
      _vm._v(" "),
      !_vm.newCheck && !_vm.uwShown
        ? _c("table", { staticClass: "file-list content-table" }, [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "tbody",
              _vm._l(_vm.uwList, function(uwItem, uwI) {
                return _c("tr", [
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.uwSelected,
                          expression: "uwSelected"
                        }
                      ],
                      attrs: { type: "radio" },
                      domProps: {
                        value: uwItem.id,
                        checked: _vm._q(_vm.uwSelected, uwItem.id)
                      },
                      on: {
                        change: function($event) {
                          _vm.uwSelected = uwItem.id
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(_vm.uwList.length - uwI))]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(_vm.item2.companyName))]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        _vm.formatDate(uwItem.createdDate, "YYYY-MM-DD HH:mm")
                      )
                    )
                  ])
                ])
              }),
              0
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.newCheck
        ? _c("div", { staticClass: "risk-calculator" }, [
            _c("h3", [_vm._v("Processing Exposure Calculator")]),
            _vm._v(" "),
            _c("p", [_vm._v("DBA: " + _vm._s(_vm.item2.companyNameTrade))]),
            _vm._v(" "),
            _c("ul", [
              _c("li", [
                _c("h4", [_vm._v("Credit and Chargeback Risk Exposure")]),
                _vm._v(" "),
                _c("ul", [
                  _c(
                    "li",
                    [
                      _c("label", [_vm._v("Refund Ratio")]),
                      _c("formel-money-amount", {
                        attrs: { suffix: "%" },
                        model: {
                          value: _vm.item.riskData.refundRatio,
                          callback: function($$v) {
                            _vm.$set(_vm.item.riskData, "refundRatio", $$v)
                          },
                          expression: "item.riskData.refundRatio"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    [
                      _c("label", [_vm._v("Chargeback Ratio")]),
                      _c("formel-money-amount", {
                        attrs: { suffix: "%" },
                        model: {
                          value: _vm.item.riskData.cbRatio,
                          callback: function($$v) {
                            _vm.$set(_vm.item.riskData, "cbRatio", $$v)
                          },
                          expression: "item.riskData.cbRatio"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    [
                      _c("label", [
                        _vm._v("Estimated average daily Visa/MasterCard Volume")
                      ]),
                      _c("formel-money-amount", {
                        attrs: { prefix: "$" },
                        model: {
                          value: _vm.item.riskData.cardVolume,
                          callback: function($$v) {
                            _vm.$set(_vm.item.riskData, "cardVolume", $$v)
                          },
                          expression: "item.riskData.cardVolume"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("li", [
                    _c("label", [_vm._v("Exposure Timeframe (days)")]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.item.riskData.expTime,
                          expression: "item.riskData.expTime"
                        }
                      ],
                      domProps: { value: _vm.item.riskData.expTime },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.item.riskData,
                            "expTime",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("label", [
                      _vm._v("(Refund Ratio+CB Ratio)*(Avg Daily*Days)")
                    ]),
                    _c("input", {
                      attrs: { readonly: "", tabindex: "-1" },
                      domProps: { value: _vm.calc1 }
                    })
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("li", [
                _c("h4", [_vm._v("Delayed Delivery Risk Exposure")]),
                _vm._v(" "),
                _c("ul", [
                  _c("li", [
                    _c("label", [_vm._v("Number of Delayed Delivery Days")]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.item.riskData.delayDays,
                          expression: "item.riskData.delayDays"
                        }
                      ],
                      domProps: { value: _vm.item.riskData.delayDays },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.item.riskData,
                            "delayDays",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c(
                    "li",
                    [
                      _c("label", [
                        _vm._v("Estimated average daily Visa/MasterCard Volume")
                      ]),
                      _c("formel-money-amount", {
                        attrs: { prefix: "$" },
                        model: {
                          value: _vm.item.riskData.cardVolume2,
                          callback: function($$v) {
                            _vm.$set(_vm.item.riskData, "cardVolume2", $$v)
                          },
                          expression: "item.riskData.cardVolume2"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    [
                      _c("label", [
                        _vm._v("Delayed delivery percent of sales")
                      ]),
                      _c("formel-money-amount", {
                        attrs: { suffix: "%" },
                        model: {
                          value: _vm.item.riskData.delayPercent,
                          callback: function($$v) {
                            _vm.$set(_vm.item.riskData, "delayPercent", $$v)
                          },
                          expression: "item.riskData.delayPercent"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("li", [
                    _c("label", [
                      _vm._v(
                        "(Delayed Delivery Days*Avg Daily*Delayed Percent)"
                      )
                    ]),
                    _c("input", {
                      attrs: { readonly: "", tabindex: "-1" },
                      domProps: { value: _vm.calc2 }
                    })
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("li", [
                _c("h4", [_vm._v("Discount and Fee Exposure")]),
                _vm._v(" "),
                _c("ul", [
                  _c(
                    "li",
                    [
                      _c("label", [
                        _vm._v("Estimated average daily Visa/MasterCard Volume")
                      ]),
                      _c("formel-money-amount", {
                        attrs: { prefix: "$" },
                        model: {
                          value: _vm.item.riskData.cardVolume3,
                          callback: function($$v) {
                            _vm.$set(_vm.item.riskData, "cardVolume3", $$v)
                          },
                          expression: "item.riskData.cardVolume3"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("li", [
                    _c("label", [
                      _vm._v(
                        "N = Average Days Before Discount or Fees Collected"
                      )
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.item.riskData.discountDays,
                          expression: "item.riskData.discountDays"
                        }
                      ],
                      domProps: { value: _vm.item.riskData.discountDays },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.item.riskData,
                            "discountDays",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c(
                    "li",
                    [
                      _c("label", [_vm._v("Discount Rate")]),
                      _c("formel-money-amount", {
                        attrs: { suffix: "%" },
                        model: {
                          value: _vm.item.riskData.discountRate,
                          callback: function($$v) {
                            _vm.$set(_vm.item.riskData, "discountRate", $$v)
                          },
                          expression: "item.riskData.discountRate"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("li", [
                    _c("label", [_vm._v("Avg Daily*N*Discount Rate")]),
                    _c("input", {
                      attrs: { readonly: "", tabindex: "-1" },
                      domProps: { value: _vm.calc3 }
                    })
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("li", [
                _c("h4", [_vm._v("Total Exposure")]),
                _vm._v(" "),
                _c("ul", [
                  _c("li", [
                    _c("label", [
                      _vm._v(
                        "(Credit exposure + Delayed Delivery Exposure + Fee Exposure)"
                      )
                    ]),
                    _c("input", {
                      attrs: { readonly: "", tabindex: "-1" },
                      domProps: { value: _vm.calc4 }
                    })
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("li", [
                _c("ul", [
                  _c(
                    "li",
                    [
                      _c("label", [_vm._v("Estimated Annual Volume")]),
                      _c("formel-money-amount", {
                        attrs: { prefix: "$" },
                        model: {
                          value: _vm.item.riskData.annualVolume,
                          callback: function($$v) {
                            _vm.$set(_vm.item.riskData, "annualVolume", $$v)
                          },
                          expression: "item.riskData.annualVolume"
                        }
                      })
                    ],
                    1
                  )
                ])
              ])
            ])
          ])
        : _vm.uwShown
        ? _c("div", { staticClass: "risk-calculator" }, [
            _c("h3", [_vm._v("Processing Exposure Calculator")]),
            _vm._v(" "),
            _c("p", [_vm._v("DBA: " + _vm._s(_vm.item2.companyNameTrade))]),
            _vm._v(" "),
            _c("ul", [
              _c("li", [
                _c("h4", [_vm._v("Credit and Chargeback Risk Exposure")]),
                _vm._v(" "),
                _c("ul", [
                  _c(
                    "li",
                    [
                      _c("label", [_vm._v("Refund Ratio")]),
                      _c("formel-money-amount", {
                        attrs: { suffix: "%", readonly: "" },
                        model: {
                          value: _vm.uwShown.riskData.refundRatio,
                          callback: function($$v) {
                            _vm.$set(_vm.uwShown.riskData, "refundRatio", $$v)
                          },
                          expression: "uwShown.riskData.refundRatio"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    [
                      _c("label", [_vm._v("Chargeback Ratio")]),
                      _c("formel-money-amount", {
                        attrs: { suffix: "%", readonly: "" },
                        model: {
                          value: _vm.uwShown.riskData.cbRatio,
                          callback: function($$v) {
                            _vm.$set(_vm.uwShown.riskData, "cbRatio", $$v)
                          },
                          expression: "uwShown.riskData.cbRatio"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    [
                      _c("label", [
                        _vm._v("Estimated average daily Visa/MasterCard Volume")
                      ]),
                      _c("formel-money-amount", {
                        attrs: { prefix: "$", readonly: "" },
                        model: {
                          value: _vm.uwShown.riskData.cardVolume,
                          callback: function($$v) {
                            _vm.$set(_vm.uwShown.riskData, "cardVolume", $$v)
                          },
                          expression: "uwShown.riskData.cardVolume"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("li", [
                    _c("label", [_vm._v("Exposure Timeframe (days)")]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.uwShown.riskData.expTime,
                          expression: "uwShown.riskData.expTime"
                        }
                      ],
                      attrs: { readonly: "" },
                      domProps: { value: _vm.uwShown.riskData.expTime },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.uwShown.riskData,
                            "expTime",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("label", [
                      _vm._v("(Refund Ratio+CB Ratio)*(Avg Daily*Days)")
                    ]),
                    _c("input", {
                      attrs: { readonly: "", tabindex: "-1" },
                      domProps: { value: _vm.calc1 }
                    })
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("li", [
                _c("h4", [_vm._v("Delayed Delivery Risk Exposure")]),
                _vm._v(" "),
                _c("ul", [
                  _c("li", [
                    _c("label", [_vm._v("Number of Delayed Delivery Days")]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.uwShown.riskData.delayDays,
                          expression: "uwShown.riskData.delayDays"
                        }
                      ],
                      attrs: { readonly: "" },
                      domProps: { value: _vm.uwShown.riskData.delayDays },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.uwShown.riskData,
                            "delayDays",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c(
                    "li",
                    [
                      _c("label", [
                        _vm._v("Estimated average daily Visa/MasterCard Volume")
                      ]),
                      _c("formel-money-amount", {
                        attrs: { prefix: "$", readonly: "" },
                        model: {
                          value: _vm.uwShown.riskData.cardVolume2,
                          callback: function($$v) {
                            _vm.$set(_vm.uwShown.riskData, "cardVolume2", $$v)
                          },
                          expression: "uwShown.riskData.cardVolume2"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    [
                      _c("label", [
                        _vm._v("Delayed delivery percent of sales")
                      ]),
                      _c("formel-money-amount", {
                        attrs: { suffix: "%", readonly: "" },
                        model: {
                          value: _vm.uwShown.riskData.delayPercent,
                          callback: function($$v) {
                            _vm.$set(_vm.uwShown.riskData, "delayPercent", $$v)
                          },
                          expression: "uwShown.riskData.delayPercent"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("li", [
                    _c("label", [
                      _vm._v(
                        "(Delayed Delivery Days*Avg Daily*Delayed Percent)"
                      )
                    ]),
                    _c("input", {
                      attrs: { readonly: "", tabindex: "-1" },
                      domProps: { value: _vm.calc2 }
                    })
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("li", [
                _c("h4", [_vm._v("Discount and Fee Exposure")]),
                _vm._v(" "),
                _c("ul", [
                  _c(
                    "li",
                    [
                      _c("label", [
                        _vm._v("Estimated average daily Visa/MasterCard Volume")
                      ]),
                      _c("formel-money-amount", {
                        attrs: { prefix: "$", readonly: "" },
                        model: {
                          value: _vm.uwShown.riskData.cardVolume3,
                          callback: function($$v) {
                            _vm.$set(_vm.uwShown.riskData, "cardVolume3", $$v)
                          },
                          expression: "uwShown.riskData.cardVolume3"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("li", [
                    _c("label", [
                      _vm._v(
                        "N = Average Days Before Discount or Fees Collected"
                      )
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.uwShown.riskData.discountDays,
                          expression: "uwShown.riskData.discountDays"
                        }
                      ],
                      attrs: { readonly: "" },
                      domProps: { value: _vm.uwShown.riskData.discountDays },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.uwShown.riskData,
                            "discountDays",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c(
                    "li",
                    [
                      _c("label", [_vm._v("Discount Rate")]),
                      _c("formel-money-amount", {
                        attrs: { suffix: "%", readonly: "" },
                        model: {
                          value: _vm.uwShown.riskData.discountRate,
                          callback: function($$v) {
                            _vm.$set(_vm.uwShown.riskData, "discountRate", $$v)
                          },
                          expression: "uwShown.riskData.discountRate"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("li", [
                    _c("label", [_vm._v("Avg Daily*N*Discount Rate")]),
                    _c("input", {
                      attrs: { readonly: "", tabindex: "-1" },
                      domProps: { value: _vm.calc3 }
                    })
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("li", [
                _c("h4", [_vm._v("Total Exposure")]),
                _vm._v(" "),
                _c("ul", [
                  _c("li", [
                    _c("label", [
                      _vm._v(
                        "(Credit exposure + Delayed Delivery Exposure + Fee Exposure)"
                      )
                    ]),
                    _c("input", {
                      attrs: { readonly: "", tabindex: "-1" },
                      domProps: { value: _vm.calc4 }
                    })
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("li", [
                _c("ul", [
                  _c(
                    "li",
                    [
                      _c("label", [_vm._v("Estimated Annual Volume")]),
                      _c("formel-money-amount", {
                        attrs: { prefix: "$", readonly: "" },
                        model: {
                          value: _vm.uwShown.riskData.annualVolume,
                          callback: function($$v) {
                            _vm.$set(_vm.uwShown.riskData, "annualVolume", $$v)
                          },
                          expression: "uwShown.riskData.annualVolume"
                        }
                      })
                    ],
                    1
                  )
                ])
              ])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("portal", { attrs: { to: "actions" } }, [
        _c(
          "ul",
          [
            _vm.newCheck
              ? [
                  _c("li", [
                    _c(
                      "a",
                      {
                        attrs: { href: "" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            _vm.newCheck = false
                          }
                        }
                      },
                      [_vm._v("Back")]
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c(
                      "a",
                      {
                        attrs: { href: "" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.saveNew()
                          }
                        }
                      },
                      [_vm._v("Save")]
                    )
                  ])
                ]
              : [
                  _c("li", [
                    !_vm.uwShown
                      ? _c(
                          "a",
                          {
                            attrs: { href: "" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.newCalc()
                              }
                            }
                          },
                          [_vm._v("New")]
                        )
                      : _c(
                          "a",
                          {
                            attrs: { href: "" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                _vm.uwShown = null
                              }
                            }
                          },
                          [_vm._v("Back")]
                        )
                  ]),
                  _vm._v(" "),
                  !_vm.uwShown && _vm.uwSelected
                    ? _c("li", [
                        _c(
                          "a",
                          {
                            attrs: { href: "" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.doShowItems(_vm.uwSelected)
                              }
                            }
                          },
                          [_vm._v("View")]
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.uwShown
                    ? _c("li", [
                        _c(
                          "a",
                          { attrs: { href: _vm.printUrl, target: "_blank" } },
                          [_vm._v("Print")]
                        )
                      ])
                    : _vm._e()
                ]
          ],
          2
        )
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticStyle: { width: "1%" } }),
        _vm._v(" "),
        _c("th", [_vm._v("ID")]),
        _vm._v(" "),
        _c("th", [_vm._v("Legal Name")]),
        _vm._v(" "),
        _c("th", [_vm._v("Date")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }