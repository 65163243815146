var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "combo-select" }, [
    _c(
      "select",
      { domProps: { value: _vm.value }, on: { input: _vm.updateValue } },
      _vm._l(_vm.options, function(opt) {
        return _c("option", { domProps: { value: opt } }, [
          _vm._v(_vm._s(opt || "other"))
        ])
      }),
      0
    ),
    _vm._v(" "),
    !_vm.value || _vm.options.indexOf(_vm.value) == -1
      ? _c("input", {
          attrs: { type: "text" },
          domProps: { value: _vm.value },
          on: { input: _vm.updateValue }
        })
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }