var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "drawer",
      on: {
        click: function($event) {
          return _vm.close()
        }
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "drawer-panel",
          style: { width: _vm.size + "px" },
          on: {
            click: function($event) {
              $event.stopPropagation()
            }
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "close",
              on: {
                click: function($event) {
                  return _vm.close()
                }
              }
            },
            [_c("img", { attrs: { src: "/assets/img/close.png" } })]
          ),
          _vm._v(" "),
          _c("div", [
            _c("div", { staticClass: "drawer-title" }, [
              _vm._v("\n        " + _vm._s(_vm.title) + "\n      ")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "drawer-content" }, [_vm._t("default")], 2)
          ]),
          _vm._v(" "),
          _vm.$slots.footer
            ? _c("div", { staticClass: "drawer-footer" }, [_vm._t("footer")], 2)
            : _vm._e()
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }