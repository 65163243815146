var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "autocomplete", staticStyle: { display: "inline-block" } },
    [
      _c("input", {
        class: _vm.vclass,
        attrs: { type: "text" },
        domProps: { value: _vm.shownValue },
        on: { input: _vm.update, keypress: _vm.handleKeypress }
      }),
      _vm._v(" "),
      _vm.value && _vm.shownList
        ? _c(
            "div",
            { staticClass: "options" },
            _vm._l(_vm.optionValues, function(option, optindex) {
              return option
                .toLowerCase()
                .indexOf((_vm.value || "").toLowerCase()) != -1
                ? _c(
                    "div",
                    {
                      staticClass: "option",
                      class: { current: _vm.index === optindex },
                      on: {
                        mouseover: function($event) {
                          return _vm.hoverItem(optindex)
                        },
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.selectItem(optindex)
                        }
                      }
                    },
                    [_vm._v("\n      " + _vm._s(option) + "\n    ")]
                  )
                : _vm._e()
            }),
            0
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }