var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "multiselect" },
    _vm._l(_vm.options, function(option) {
      return _c("li", [
        _c("label", [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.val,
                expression: "val"
              }
            ],
            attrs: { disabled: _vm.readonly, type: "checkbox" },
            domProps: {
              value: option.value,
              checked: Array.isArray(_vm.val)
                ? _vm._i(_vm.val, option.value) > -1
                : _vm.val
            },
            on: {
              change: [
                function($event) {
                  var $$a = _vm.val,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = option.value,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.val = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.val = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.val = $$c
                  }
                },
                _vm.updateValue
              ]
            }
          }),
          _vm._v("\n      " + _vm._s(option.label) + "\n    ")
        ])
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }