var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "ncontent nc-flat custom-width" }, [
      _c("div", { staticClass: "ncontent touch-it" }, [
        _c("div", { staticClass: "merchant-panel" }, [
          _c("div", { staticClass: "data-content" }, [
            _c(
              "ul",
              { staticClass: "tabs" },
              _vm._l(_vm.tabs, function(tab, idx) {
                return _c("li", { class: { active: _vm.activeTab == idx } }, [
                  _vm._v(_vm._s(tab))
                ])
              }),
              0
            )
          ])
        ]),
        _vm._v(" "),
        _vm.activeTab === 0
          ? _c("div", { staticClass: "custom-form pad20" }, [
              _c(
                "div",
                { staticClass: "input-group" },
                [
                  _c("label", [_vm._v("First Name")]),
                  _vm._v(" "),
                  _c(
                    "validator",
                    { attrs: { value: _vm.form.first_name, reqs: "required" } },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.first_name,
                            expression: "form.first_name"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.form.first_name },
                        on: {
                          keypress: function($event) {
                            return _vm.allowLetterNumber($event, {
                              numbers: false
                            })
                          },
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.form,
                              "first_name",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "input-group" },
                [
                  _c("label", [_vm._v("Last Name")]),
                  _vm._v(" "),
                  _c(
                    "validator",
                    { attrs: { value: _vm.form.last_name, reqs: "required" } },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.last_name,
                            expression: "form.last_name"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.form.last_name },
                        on: {
                          keypress: function($event) {
                            return _vm.allowLetterNumber($event, {
                              numbers: false
                            })
                          },
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.form, "last_name", $event.target.value)
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "input-group" },
                [
                  _c("label", [_vm._v("Email")]),
                  _vm._v(" "),
                  _c(
                    "validator",
                    {
                      attrs: { value: _vm.form.email, reqs: "required email" }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.email,
                            expression: "form.email"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.form.email },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.form, "email", $event.target.value)
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "input-group" },
                [
                  _c("label", [_vm._v("Phone")]),
                  _vm._v(" "),
                  _c(
                    "validator",
                    { attrs: { value: _vm.form.phone, reqs: "required" } },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.phone,
                            expression: "form.phone"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.form.phone },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.form, "phone", $event.target.value)
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "input-group" },
                [
                  _c("label", [_vm._v("Username")]),
                  _vm._v(" "),
                  _c(
                    "validator",
                    { attrs: { value: _vm.form.username, reqs: "required" } },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.username,
                            expression: "form.username"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.form.username },
                        on: {
                          keypress: function($event) {
                            return _vm.allowLetterNumber($event, {
                              numbers: true
                            })
                          },
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.form, "username", $event.target.value)
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "input-group" }, [
                _vm._v("\n           \n        ")
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.activeTab === 0
          ? _c("div", { staticClass: "custom-form pad20" })
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "buttons clear-after" }, [
          _vm.activeTab === 0
            ? _c(
                "button",
                {
                  staticClass: "btn btn-large btn-primary floatr no-margin",
                  on: {
                    click: function($event) {
                      return _vm.save()
                    }
                  }
                },
                [_vm._v("FINALIZE")]
              )
            : _vm._e()
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }