var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "popup usermgmt", class: { shown: _vm.visible } },
    [
      _c("div", { staticClass: "pop-med" }, [
        _c("div", { staticClass: "pop-inner" }, [
          _c("a", {
            staticClass: "close",
            attrs: { href: "" },
            on: {
              click: function($event) {
                $event.preventDefault()
                _vm.visible = false
              }
            }
          }),
          _vm._v(" "),
          _c("h2", [
            _vm._v("Set new password for: " + _vm._s(_vm.user.username))
          ]),
          _vm._v(" "),
          _c("form", { staticClass: "search-form" }, [
            false
              ? _c(
                  "div",
                  [
                    _c(
                      "validator",
                      {
                        staticClass: "field",
                        attrs: { value: _vm.agentId, reqs: "" }
                      },
                      [
                        _c("label", [_vm._v("Partner ID")]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.agentId,
                              expression: "agentId"
                            }
                          ],
                          attrs: { tabindex: "2", type: "text" },
                          domProps: { value: _vm.agentId },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.agentId = $event.target.value
                            }
                          }
                        })
                      ]
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              [
                _c(
                  "validator",
                  {
                    staticClass: "field",
                    attrs: { value: _vm.username, reqs: "" }
                  },
                  [
                    _c("label", [_vm._v("Username")]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.username,
                          expression: "username"
                        }
                      ],
                      attrs: { tabindex: "2", type: "text" },
                      domProps: { value: _vm.username },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.username = $event.target.value
                        }
                      }
                    })
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              [
                _c(
                  "validator",
                  {
                    staticClass: "field",
                    attrs: { value: _vm.password, reqs: "password" }
                  },
                  [
                    _c("label", [_vm._v("New Password")]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.password,
                          expression: "password"
                        }
                      ],
                      attrs: { tabindex: "2", type: "password" },
                      domProps: { value: _vm.password },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.password = $event.target.value
                        }
                      }
                    })
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              [
                _c(
                  "validator",
                  {
                    staticClass: "field",
                    attrs: { value: _vm.password2, reqs: "password" }
                  },
                  [
                    _c("label", [_vm._v("Confirm Password")]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.password2,
                          expression: "password2"
                        }
                      ],
                      attrs: { tabindex: "2", type: "password" },
                      domProps: { value: _vm.password2 },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.password2 = $event.target.value
                        }
                      }
                    })
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _vm.user.initial
              ? _c("div", { staticClass: "consent" }, [
                  _c("p", [
                    _vm._v(
                      "By accessing the system, you accept and agree to our:"
                    )
                  ]),
                  _vm._v(" "),
                  _c("ul", [
                    _c("li", [
                      _c("label", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.consent.accessControl,
                              expression: "consent.accessControl"
                            }
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.consent.accessControl)
                              ? _vm._i(_vm.consent.accessControl, null) > -1
                              : _vm.consent.accessControl
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.consent.accessControl,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.consent,
                                      "accessControl",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.consent,
                                      "accessControl",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.consent, "accessControl", $$c)
                              }
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("a", { attrs: { href: "", target: "_blank" } }, [
                          _vm._v("Access Control Policy")
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.consent.cyberSecurity,
                              expression: "consent.cyberSecurity"
                            }
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.consent.cyberSecurity)
                              ? _vm._i(_vm.consent.cyberSecurity, null) > -1
                              : _vm.consent.cyberSecurity
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.consent.cyberSecurity,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.consent,
                                      "cyberSecurity",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.consent,
                                      "cyberSecurity",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.consent, "cyberSecurity", $$c)
                              }
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("a", { attrs: { href: "", target: "_blank" } }, [
                          _vm._v("Cyber Security policy")
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.consent.acceptableUse,
                              expression: "consent.acceptableUse"
                            }
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.consent.acceptableUse)
                              ? _vm._i(_vm.consent.acceptableUse, null) > -1
                              : _vm.consent.acceptableUse
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.consent.acceptableUse,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.consent,
                                      "acceptableUse",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.consent,
                                      "acceptableUse",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.consent, "acceptableUse", $$c)
                              }
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("a", { attrs: { href: "", target: "_blank" } }, [
                          _vm._v("Acceptable Use Policy")
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.consent.terms,
                              expression: "consent.terms"
                            }
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.consent.terms)
                              ? _vm._i(_vm.consent.terms, null) > -1
                              : _vm.consent.terms
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.consent.terms,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.consent,
                                      "terms",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.consent,
                                      "terms",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.consent, "terms", $$c)
                              }
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("a", { attrs: { href: "", target: "_blank" } }, [
                          _vm._v("Terms of Use")
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.consent.privacy,
                              expression: "consent.privacy"
                            }
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.consent.privacy)
                              ? _vm._i(_vm.consent.privacy, null) > -1
                              : _vm.consent.privacy
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.consent.privacy,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.consent,
                                      "privacy",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.consent,
                                      "privacy",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.consent, "privacy", $$c)
                              }
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("a", { attrs: { href: "", target: "_blank" } }, [
                          _vm._v("Privacy Policy")
                        ])
                      ])
                    ])
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "buttons" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-large btn-primary",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.send.apply(null, arguments)
                    }
                  }
                },
                [_vm._v("Update")]
              )
            ])
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }