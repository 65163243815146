var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("p", [_vm._v("Partner Onboarding")]),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.buildOpen,
            expression: "buildOpen"
          }
        ]
      },
      [
        _c("agent-setup-popup", {
          ref: "agentSetupPop",
          attrs: { popup: true }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "actions" }, [
      _c(
        "button",
        {
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.goBack.apply(null, arguments)
            }
          }
        },
        [_vm._v("Back")]
      ),
      _vm._v(" "),
      _vm.item.agentDb.status == "A04"
        ? _c(
            "button",
            {
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.doNext.apply(null, arguments)
                }
              }
            },
            [_vm._v("Complete")]
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }