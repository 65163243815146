var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "content-table-container file-list-container" }, [
      _c("table", { staticClass: "file-list content-table" }, [
        _c(
          "tbody",
          [
            false
              ? _c("tr", [
                  _c("td", [_vm._v("Completed Signup Form")]),
                  _vm._v(" "),
                  _c("td"),
                  _vm._v(" "),
                  _c("td", [
                    _c(
                      "a",
                      { attrs: { href: _vm.appPdfUrl, target: "_blank" } },
                      [
                        _vm._v(
                          _vm._s(_vm.item.appId) + " - " + _vm._s(_vm.item.name)
                        )
                      ]
                    )
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.signedApplications, function(signedDoc, si) {
              return si == _vm.signedApplications.length - 1
                ? _c("tr", [
                    _c("td", [_vm._v("Signed Signup Form")]),
                    _vm._v(" "),
                    _c("td", { staticClass: "partner-boarding-doc-actions" }, [
                      _c(
                        "button",
                        {
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.uploadSignup.apply(null, arguments)
                            }
                          }
                        },
                        [_vm._v("Browse")]
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _c(
                        "a",
                        {
                          staticStyle: { display: "block" },
                          attrs: {
                            target: "_blank",
                            href: _vm.fileLink(signedDoc.filename, signedDoc.id)
                          }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.item.name) + " signed signup form"
                          )
                        ]
                      )
                    ])
                  ])
                : _vm._e()
            }),
            _vm._v(" "),
            !_vm.signedApplications.length
              ? _c("tr", [
                  _c("td", [_vm._v("Signed Signup Form")]),
                  _vm._v(" "),
                  _c("td", [
                    _c(
                      "button",
                      {
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.uploadSignup.apply(null, arguments)
                          }
                        }
                      },
                      [_vm._v("Browse")]
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", [_vm._v("No file chosen")])
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("tr", [
              _c("td", [_vm._v("Create Agreement")]),
              _vm._v(" "),
              _c("td", { staticClass: "partner-boarding-doc-actions" }, [
                _c(
                  "button",
                  {
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.startAgreement.apply(null, arguments)
                      }
                    }
                  },
                  [_vm._v("Start")]
                )
              ]),
              _vm._v(" "),
              _vm.agreements.length
                ? _c("td", [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: _vm.agreementPath(_vm.agreements[0]),
                          target: "_blank"
                        }
                      },
                      [_vm._v(_vm._s(_vm.item.name) + " agreement")]
                    )
                  ])
                : _c("td", [_vm._v("No agreement created")])
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", [_vm._v("Signed Agreement")]),
              _vm._v(" "),
              _c("td", { staticClass: "partner-boarding-doc-actions" }, [
                _c(
                  "button",
                  {
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.uploadAgreement.apply(null, arguments)
                      }
                    }
                  },
                  [_vm._v("Browse")]
                )
              ]),
              _vm._v(" "),
              _vm.agreements.length && _vm.agreements[0].filename
                ? _c("td", [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: _vm.fileLink(_vm.agreements[0].filename),
                          target: "_blank"
                        }
                      },
                      [_vm._v(_vm._s(_vm.item.name) + " signed agreement")]
                    )
                  ])
                : _c("td", [_vm._v("No file chosen")])
            ])
          ],
          2
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "actions" }, [
      _c(
        "button",
        {
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.goBack.apply(null, arguments)
            }
          }
        },
        [_vm._v("Back")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.doNext.apply(null, arguments)
            }
          }
        },
        [_vm._v("Next")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }