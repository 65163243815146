var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "zip-code" }, [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.zip1,
          expression: "zip1"
        }
      ],
      attrs: { type: "text" },
      domProps: { value: _vm.zip1 },
      on: {
        input: [
          function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.zip1 = $event.target.value
          },
          _vm.updateValue
        ]
      }
    }),
    _vm._v(" "),
    _c("span", [_vm._v("-")]),
    _vm._v(" "),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.zip2,
          expression: "zip2"
        }
      ],
      attrs: { type: "text" },
      domProps: { value: _vm.zip2 },
      on: {
        input: [
          function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.zip2 = $event.target.value
          },
          _vm.updateValue
        ]
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }