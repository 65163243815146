var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("p", { staticClass: "result-count" }, [
      _vm._v("\n    Your search returned "),
      _c("span", [_vm._v(_vm._s(_vm.results))]),
      _vm._v(" result(s).\n  ")
    ]),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "acc-results" },
      _vm._l(_vm.perStatus, function(status, si) {
        return _c("li", { class: { active: _vm.currentIndex == si } }, [
          _c(
            "h4",
            {
              on: {
                click: function($event) {
                  return _vm.selectIndex(si)
                }
              }
            },
            [
              _c("span", { staticClass: "label" }, [
                _vm._v(_vm._s(status.description))
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "count" }, [
                _vm._v(_vm._s(status.items.length) + " Application(s)")
              ])
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "acc-list" }, [
            _c("table", [
              _c("thead", [
                _c(
                  "tr",
                  [
                    _vm._l(_vm.columnsToShow[status.status], function(
                      columnLabel,
                      columnName
                    ) {
                      return _c("th", [
                        _vm._v(
                          "\n                " +
                            _vm._s(columnLabel) +
                            "\n              "
                        )
                      ])
                    }),
                    _vm._v(" "),
                    !_vm.noActions &&
                    !_vm.followup &&
                    !_vm.negative &&
                    (status.status == "A02" ||
                      status.status == "A03" ||
                      status.status == "A04" ||
                      status.status == "M00")
                      ? _c("th", [
                          _vm._v("\n                Timer\n              ")
                        ])
                      : !_vm.followup &&
                        !_vm.negative &&
                        status.status.indexOf("M") == 0
                      ? _c("th", [
                          _vm._v("\n                Services\n              ")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.noActions ? _c("th", [_vm._v("Options")]) : _vm._e()
                  ],
                  2
                )
              ]),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(status.items, function(item) {
                  return _c(
                    "tr",
                    [
                      _vm._l(_vm.columnsToShow[status.status], function(
                        columnLabel,
                        columnName
                      ) {
                        return _c(
                          "td",
                          [
                            _vm.fieldHref(item, columnName)
                              ? _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: _vm.fieldHref(item, columnName),
                                      title: _vm.showTitle(item, columnName)
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.showField(item, columnName)
                                        ) +
                                        "\n                "
                                    )
                                  ]
                                )
                              : [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(_vm.showField(item, columnName)) +
                                      "\n                "
                                  )
                                ]
                          ],
                          2
                        )
                      }),
                      _vm._v(" "),
                      !_vm.followup &&
                      !_vm.negative &&
                      (status.status == "A02" || status.status == "A03")
                        ? _c(
                            "td",
                            {
                              style: {
                                "background-color": _vm.agoColor(
                                  item.submitDate,
                                  _vm.now
                                )
                              }
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.agoTimer(item.submitDate, _vm.now)
                                  ) +
                                  "\n              "
                              )
                            ]
                          )
                        : !_vm.noActions &&
                          !_vm.followup &&
                          !_vm.negative &&
                          (status.status == "A04" || status.status == "M00")
                        ? _c(
                            "td",
                            {
                              style: {
                                "background-color": _vm.agoColor(
                                  item.approveDate || item.submitDate,
                                  _vm.now
                                )
                              }
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.agoTimer(
                                      item.approveDate || item.submitDate,
                                      _vm.now
                                    )
                                  ) +
                                  "\n              "
                              )
                            ]
                          )
                        : !_vm.followup &&
                          !_vm.negative &&
                          status.status.indexOf("M") == 0
                        ? _c("td", [
                            _vm._v("\n                 \n              ")
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.noActions
                        ? _c(
                            "td",
                            [
                              _vm.mode != "intake"
                                ? [
                                    _vm.followup && item.followup
                                      ? _c(
                                          "a",
                                          {
                                            attrs: { href: "" },
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                return _vm.doAction(
                                                  item,
                                                  "removeFup"
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("Remove")]
                                        )
                                      : !_vm.negative && !item.followup
                                      ? _c(
                                          "a",
                                          {
                                            attrs: { href: "" },
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                return _vm.doAction(
                                                  item,
                                                  "followUp"
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("Follow-up")]
                                        )
                                      : _vm.negative
                                      ? _c(
                                          "a",
                                          {
                                            attrs: { href: "" },
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                return _vm.doAction(
                                                  item,
                                                  "removeNeg"
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("Remove")]
                                        )
                                      : _vm._e()
                                  ]
                                : _vm._e()
                            ],
                            2
                          )
                        : _vm._e()
                    ],
                    2
                  )
                }),
                0
              )
            ])
          ])
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }