var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: { filled: _vm.focused || _vm.actualValue, disabled: _vm.disabled }
    },
    [
      _c("label", [
        _vm._v(_vm._s(_vm.label) + " "),
        _vm.required ? _c("em", [_vm._v("*")]) : _vm._e()
      ]),
      _vm._v(" "),
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.actualValue,
            expression: "actualValue"
          }
        ],
        ref: "textarea",
        class: { error: _vm.hasErrors },
        attrs: {
          type: _vm.type,
          required: _vm.required,
          disabled: _vm.disabled,
          maxlength: _vm.maxlength || null
        },
        domProps: { value: _vm.actualValue },
        on: {
          focus: function($event) {
            _vm.focused = true
          },
          blur: function($event) {
            _vm.focused = false
          },
          input: [
            function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.actualValue = $event.target.value
            },
            function($event) {
              return _vm.$emit("input", $event.target.value)
            }
          ]
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }