var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "action-buttons" }, [
      !_vm.viewApp
        ? _c(
            "a",
            {
              attrs: { href: "" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  _vm.viewApp = true
                }
              }
            },
            [_vm._v("View")]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.viewApp
        ? _c(
            "a",
            {
              attrs: { href: "" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.uploadFile(_vm.signedApplicationField)
                }
              }
            },
            [_vm._v("Upload signed")]
          )
        : _c(
            "a",
            {
              attrs: { href: "" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  _vm.viewApp = false
                }
              }
            },
            [_vm._v("Back")]
          )
    ]),
    _vm._v(" "),
    !_vm.viewApp
      ? _c(
          "div",
          { staticClass: "content-table-container file-list-container" },
          [
            _c("table", { staticClass: "file-list content-table" }, [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "tbody",
                [
                  _c("tr", [
                    _vm._m(1),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(_vm.item.agentId))]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          _vm.formatDate(
                            _vm.item.agentDb.createdAt,
                            "YYYY-MM-DD"
                          )
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(_vm.item.agentDb.status))]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(_vm.item.agentDb.name))])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", { attrs: { colspan: "3" } }, [
                      _c(
                        "a",
                        {
                          staticStyle: { display: "block" },
                          attrs: { target: "_blank", href: _vm.agentPdfUrl }
                        },
                        [_vm._v("Completed Agent Application")]
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", { attrs: { colspan: "3" } }, [
                      _c(
                        "a",
                        {
                          staticStyle: { display: "block" },
                          attrs: { target: "_blank", href: _vm.agentPdfUrl }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.item.applicationId) +
                              " " +
                              _vm._s(_vm.item.agentDb.name) +
                              " " +
                              _vm._s(
                                _vm.formatDate(
                                  _vm.item.agentDb.createdAt,
                                  "YYYY-MM-DD hh:mm"
                                )
                              )
                          )
                        ]
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.signedApplications, function(signedDoc) {
                    return _c("tr", [
                      _c("td", { attrs: { colspan: "3" } }, [
                        _c(
                          "a",
                          {
                            staticStyle: { display: "block" },
                            attrs: {
                              target: "_blank",
                              href: _vm.fileLink(
                                signedDoc.filename,
                                signedDoc.id
                              )
                            }
                          },
                          [_vm._v("Signed Agent Application")]
                        )
                      ]),
                      _vm._v(" "),
                      _c("td", { attrs: { colspan: "3" } }, [
                        _c(
                          "a",
                          {
                            staticStyle: { display: "block" },
                            attrs: {
                              target: "_blank",
                              href: _vm.fileLink(
                                signedDoc.filename,
                                signedDoc.id
                              )
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.item.applicationId) +
                                " " +
                                _vm._s(_vm.item.agentDb.name) +
                                " " +
                                _vm._s(
                                  _vm.formatDate(
                                    signedDoc.created,
                                    "YYYY-MM-DD hh:mm"
                                  )
                                )
                            )
                          ]
                        )
                      ])
                    ])
                  })
                ],
                2
              )
            ])
          ]
        )
      : _c("div", { staticClass: "app-details" }, [
          _c("p", [
            _c("label", [_vm._v("Agent ID:")]),
            _vm._v(" " + _vm._s(_vm.item.agentId))
          ]),
          _vm._v(" "),
          _c("p", [
            _c("label", [_vm._v("Submitted Date:")]),
            _vm._v(
              " " +
                _vm._s(_vm.formatDate(_vm.item.agentDb.createdAt, "YYYY-MM-DD"))
            )
          ]),
          _vm._v(" "),
          _c("p", [
            _c("label", [_vm._v("Status:")]),
            _vm._v(" " + _vm._s(_vm.item.agentDb.status))
          ]),
          _vm._v(" "),
          _c("p", [
            _c("label", [_vm._v("Legal Name:")]),
            _vm._v(" " + _vm._s(_vm.item.agentDb.name))
          ])
        ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th"),
        _vm._v(" "),
        _c("th", [_vm._v("AgentID")]),
        _vm._v(" "),
        _c("th", [_vm._v("Submitted")]),
        _vm._v(" "),
        _c("th", [_vm._v("Status")]),
        _vm._v(" "),
        _c("th", [_vm._v("Legal Name")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("input", { attrs: { type: "radio", checked: "" } })])
  }
]
render._withStripped = true

export { render, staticRenderFns }