var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "account-dropdown" }, [
    _c("div", { staticClass: "text" }, [
      _c("span", { staticClass: "welcome-user" }, [
        _vm._v("Welcome back, " + _vm._s(_vm.user.username))
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "label" }, [_vm._v(_vm._s(_vm.label))]),
      _vm._v(" "),
      _c("span", { staticClass: "label" }, [
        _vm._v(
          "Last login: " + _vm._s(_vm.formatDate(_vm.user2.lastLogin, "LLL"))
        )
      ])
    ]),
    _vm._v(" "),
    _c("ul", [
      _c("li", [
        _c("a", { attrs: { href: "/your-profile/" + _vm.user.username } }, [
          _vm._v(" My Profile ")
        ])
      ]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _vm._m(1)
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("a", { attrs: { href: "/sys-options" } }, [_vm._v(" Options ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("a", { attrs: { href: "/login?logout=1" } }, [_vm._v(" Logout ")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }