var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "combo-select" }, [
    _c(
      "select",
      {
        attrs: { tabindex: "-1" },
        domProps: { value: _vm.value },
        on: { input: _vm.updateValue }
      },
      _vm._l(_vm.options, function(opt) {
        return _c("option", { domProps: { value: opt } }, [_vm._v(_vm._s(opt))])
      }),
      0
    ),
    _vm._v(" "),
    _c("input", {
      domProps: { value: _vm.value },
      on: { input: _vm.updateValue }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }