var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("p", { staticClass: "result-count" }, [
      _vm._v("Your search returned "),
      _c("span", [_vm._v(_vm._s(_vm.results))]),
      _vm._v(" result(s).")
    ]),
    _vm._v(" "),
    _c("ul", { staticClass: "acc-results" }, [
      _c("li", [
        _c(
          "h4",
          {
            on: {
              click: function($event) {
                _vm.show = !_vm.show
              }
            }
          },
          [
            _c("span", { staticClass: "label" }, [_vm._v("Profiles")]),
            _vm._v(" "),
            _c("span", { staticClass: "count" }, [
              _vm._v(_vm._s(_vm.sortItems.length) + " Application(s)")
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.show,
                expression: "show"
              }
            ],
            ref: "",
            staticClass: "acc-list",
            staticStyle: { display: "block" }
          },
          [
            _c("table", [
              _c("thead", [
                _c(
                  "tr",
                  [
                    _vm._l(_vm.columns, function(columnLabel, columnName) {
                      return _c("th", [
                        _vm._v(
                          "\n                " +
                            _vm._s(columnLabel) +
                            "\n              "
                        )
                      ])
                    }),
                    _vm._v(" "),
                    !_vm.noActions ? _c("th", [_vm._v("Options")]) : _vm._e()
                  ],
                  2
                )
              ]),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.sortItems, function(item) {
                  return _c(
                    "tr",
                    [
                      _vm._l(_vm.columns, function(columnLabel, columnName) {
                        return _c(
                          "td",
                          [
                            _vm.fieldHref(item, columnName)
                              ? _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: _vm.fieldHref(item, columnName)
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.showField(item, columnName)
                                        ) +
                                        "\n                "
                                    )
                                  ]
                                )
                              : [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(_vm.showField(item, columnName)) +
                                      "\n                "
                                  )
                                ]
                          ],
                          2
                        )
                      }),
                      _vm._v(" "),
                      !_vm.noActions ? _c("td") : _vm._e()
                    ],
                    2
                  )
                }),
                0
              )
            ])
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }