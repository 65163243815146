var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "date" },
    [
      _vm.label && !_vm.day && !_vm.month && !_vm.year
        ? _c("span", { staticClass: "label" }, [_vm._v(_vm._s(_vm.label))])
        : _vm._e(),
      _vm._v(" "),
      _c("form-field-select", {
        staticClass: "date-item sm",
        attrs: {
          label: "DD",
          validator: _vm.$v.day,
          required: _vm.required,
          options: _vm.dayOptions,
          disabled: _vm.disabled
        },
        model: {
          value: _vm.day,
          callback: function($$v) {
            _vm.day = $$v
          },
          expression: "day"
        }
      }),
      _vm._v(" "),
      _c("form-field-select", {
        staticClass: "date-item lg",
        attrs: {
          label: "MM",
          validator: _vm.$v.month,
          required: _vm.required,
          options: _vm.monthOptions,
          disabled: _vm.disabled
        },
        model: {
          value: _vm.month,
          callback: function($$v) {
            _vm.month = $$v
          },
          expression: "month"
        }
      }),
      _vm._v(" "),
      _c("form-field-select", {
        staticClass: "date-item",
        attrs: {
          label: _vm.$t("register.year_format"),
          validator: _vm.$v.year,
          required: _vm.required,
          options: _vm.yearOptions,
          disabled: _vm.disabled
        },
        model: {
          value: _vm.year,
          callback: function($$v) {
            _vm.year = $$v
          },
          expression: "year"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }