var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "popup usermgmt", class: { shown: _vm.visible } },
    [
      _c("div", { staticClass: "pop-med" }, [
        _c("div", { staticClass: "pop-inner" }, [
          _c("a", {
            staticClass: "close",
            attrs: { href: "" },
            on: {
              click: function($event) {
                $event.preventDefault()
                _vm.visible = false
              }
            }
          }),
          _vm._v(" "),
          _c("h2", [
            _vm._v(
              _vm._s(
                _vm.user.id
                  ? "Editing user: " +
                      _vm.user.username +
                      " " +
                      (_vm.user.agentId ? "(" + _vm.user.agentId + ")" : "")
                  : "New user"
              )
            )
          ]),
          _vm._v(" "),
          _c(
            "form",
            { staticClass: "search-form" },
            [
              _c(
                "validator",
                {
                  staticClass: "field shortlab",
                  attrs: { value: _vm.user.services, reqs: "" }
                },
                [
                  _c("label", [_vm._v("Services")]),
                  _vm._v(" "),
                  _c("dyn-multiselect", {
                    attrs: { readonly: _vm.readonly, options: _vm.services },
                    model: {
                      value: _vm.user.services,
                      callback: function($$v) {
                        _vm.$set(_vm.user, "services", $$v)
                      },
                      expression: "user.services"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "validator",
                {
                  staticClass: "field shortlab",
                  attrs: { value: _vm.user.groups, reqs: "required" }
                },
                [
                  _c("label", [_vm._v("Role")]),
                  _vm._v(" "),
                  _c("dyn-select", {
                    attrs: { disabled: _vm.readonly, options: _vm.groups },
                    model: {
                      value: _vm.user.groups,
                      callback: function($$v) {
                        _vm.$set(_vm.user, "groups", $$v)
                      },
                      expression: "user.groups"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "f2split" }, [
                !_vm.user.id && _vm.user.groups == "merchant"
                  ? _c(
                      "div",
                      [
                        _c(
                          "validator",
                          {
                            staticClass: "field",
                            attrs: {
                              value: _vm.user.username,
                              reqs: "required"
                            }
                          },
                          [
                            _c("label", [_vm._v("Username")]),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.user.applicationId,
                                    expression: "user.applicationId"
                                  }
                                ],
                                attrs: {
                                  disabled: _vm.readonly,
                                  tabindex: "1"
                                },
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.user,
                                      "applicationId",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  }
                                }
                              },
                              _vm._l(_vm.merchantList, function(merchant) {
                                return _c(
                                  "option",
                                  { domProps: { value: merchant.id } },
                                  [_vm._v(_vm._s(merchant.username))]
                                )
                              }),
                              0
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _c(
                      "div",
                      [
                        _c(
                          "validator",
                          {
                            staticClass: "field",
                            attrs: {
                              value: _vm.user.username,
                              reqs: "required"
                            }
                          },
                          [
                            _c("label", [_vm._v("Username")]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.user.username,
                                  expression: "user.username"
                                }
                              ],
                              attrs: {
                                tabindex: "1",
                                type: "text",
                                readonly:
                                  (!_vm.user.id &&
                                    _vm.user.groups != "admin-super") ||
                                  _vm.readonly
                              },
                              domProps: { value: _vm.user.username },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.user,
                                    "username",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]
                        )
                      ],
                      1
                    ),
                _vm._v(" "),
                false
                  ? _c(
                      "div",
                      [
                        _c(
                          "validator",
                          {
                            staticClass: "field",
                            attrs: {
                              value: _vm.user.password,
                              reqs: "password"
                            }
                          },
                          [
                            _c("label", [_vm._v("Password")]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.user.password,
                                  expression: "user.password"
                                }
                              ],
                              attrs: {
                                readonly: _vm.readonly,
                                tabindex: "2",
                                type: "password"
                              },
                              domProps: { value: _vm.user.password },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.user,
                                    "password",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]
                        )
                      ],
                      1
                    )
                  : _vm.isAgent
                  ? _c(
                      "div",
                      [
                        _c(
                          "validator",
                          {
                            staticClass: "field",
                            attrs: { value: _vm.user.manager, reqs: "" }
                          },
                          [
                            _c("label", [_vm._v("Relationship Manager")]),
                            _vm._v(" "),
                            _c("dyn-select", {
                              attrs: {
                                disabled: _vm.readonly,
                                tabindex: "2",
                                options: _vm.managers
                              },
                              model: {
                                value: _vm.user.manager,
                                callback: function($$v) {
                                  _vm.$set(_vm.user, "manager", $$v)
                                },
                                expression: "user.manager"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm.user.groups == "merchant"
                  ? _c(
                      "div",
                      [
                        _c(
                          "validator",
                          {
                            staticClass: "field",
                            attrs: { value: _vm.user.manager, reqs: "" }
                          },
                          [
                            _c("label", [_vm._v("Account Manager")]),
                            _vm._v(" "),
                            _c("dyn-select", {
                              attrs: {
                                disabled: _vm.readonly,
                                tabindex: "2",
                                options: _vm.amanagers
                              },
                              model: {
                                value: _vm.user.manager,
                                callback: function($$v) {
                                  _vm.$set(_vm.user, "manager", $$v)
                                },
                                expression: "user.manager"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "f2split" }, [
                _c(
                  "div",
                  [
                    _c(
                      "validator",
                      {
                        staticClass: "field",
                        attrs: { value: _vm.user.fullName, reqs: "required" }
                      },
                      [
                        _c("label", [_vm._v("First Name")]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.user.fullName,
                              expression: "user.fullName"
                            }
                          ],
                          attrs: {
                            readonly: _vm.readonly,
                            tabindex: "3",
                            type: "text"
                          },
                          domProps: { value: _vm.user.fullName },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.user,
                                "fullName",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c(
                      "validator",
                      {
                        staticClass: "field",
                        attrs: { value: _vm.user.lastName, reqs: "required" }
                      },
                      [
                        _c("label", [_vm._v("Last Name")]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.user.lastName,
                              expression: "user.lastName"
                            }
                          ],
                          attrs: {
                            readonly: _vm.readonly,
                            tabindex: "3",
                            type: "text"
                          },
                          domProps: { value: _vm.user.lastName },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.user,
                                "lastName",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ]
                    )
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              !_vm.isAgent
                ? _c("div", { staticClass: "f2split" }, [
                    _c(
                      "div",
                      [
                        _c(
                          "validator",
                          {
                            staticClass: "field",
                            attrs: { value: _vm.user.title, reqs: "" }
                          },
                          [
                            _c("label", [_vm._v("Title")]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.user.title,
                                  expression: "user.title"
                                }
                              ],
                              attrs: {
                                readonly: _vm.readonly,
                                tabindex: "3",
                                type: "text"
                              },
                              domProps: { value: _vm.user.title },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.user,
                                    "title",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _vm.hasPhone
                          ? _c(
                              "validator",
                              {
                                staticClass: "field",
                                attrs: {
                                  value: _vm.user.phone,
                                  reqs: "required"
                                }
                              },
                              [
                                _c("label", [_vm._v("Phone")]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.user.phone,
                                      expression: "user.phone"
                                    }
                                  ],
                                  attrs: {
                                    readonly: _vm.readonly,
                                    tabindex: "5",
                                    type: "text"
                                  },
                                  domProps: { value: _vm.user.phone },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.user,
                                        "phone",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c(
                          "validator",
                          {
                            staticClass: "field",
                            attrs: {
                              value: _vm.user.email,
                              reqs: "required email"
                            }
                          },
                          [
                            _c("label", [_vm._v("E-mail")]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.user.email,
                                  expression: "user.email"
                                }
                              ],
                              attrs: {
                                readonly: _vm.readonly,
                                tabindex: "4",
                                type: "text"
                              },
                              domProps: { value: _vm.user.email },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.user,
                                    "email",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _vm.hasPhone
                          ? _c(
                              "validator",
                              {
                                staticClass: "field",
                                attrs: { value: _vm.user.phoneExt, reqs: "" }
                              },
                              [
                                _c("label", [_vm._v("Phone Ext")]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.user.phoneExt,
                                      expression: "user.phoneExt"
                                    }
                                  ],
                                  attrs: {
                                    readonly: _vm.readonly,
                                    tabindex: "5",
                                    type: "text"
                                  },
                                  domProps: { value: _vm.user.phoneExt },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.user,
                                        "phoneExt",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ])
                : _vm.user.groups == "merchant"
                ? _c("div", { staticClass: "f2split" }, [
                    _c(
                      "div",
                      [
                        _c(
                          "validator",
                          {
                            staticClass: "field",
                            attrs: { value: _vm.user.supportPin, reqs: "" }
                          },
                          [
                            _c("label", [_vm._v("Support Pin")]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.user.supportPin,
                                  expression: "user.supportPin"
                                }
                              ],
                              attrs: {
                                readonly: "",
                                tabindex: "4",
                                type: "text"
                              },
                              domProps: { value: _vm.user.supportPin },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.user,
                                    "supportPin",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]
                        )
                      ],
                      1
                    )
                  ])
                : _c("div", { staticClass: "f2split" }, [
                    _c(
                      "div",
                      [
                        _c(
                          "validator",
                          {
                            staticClass: "field",
                            attrs: {
                              value: _vm.user.companyName,
                              reqs: "required"
                            }
                          },
                          [
                            _c("label", [_vm._v("Company Name")]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.user.companyName,
                                  expression: "user.companyName"
                                }
                              ],
                              attrs: {
                                readonly: _vm.readonly,
                                tabindex: "5",
                                type: "text"
                              },
                              domProps: { value: _vm.user.companyName },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.user,
                                    "companyName",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "validator",
                          {
                            staticClass: "field",
                            attrs: {
                              value: _vm.user.companyCity,
                              reqs: "required"
                            }
                          },
                          [
                            _c("label", [_vm._v("Company City")]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.user.companyCity,
                                  expression: "user.companyCity"
                                }
                              ],
                              attrs: {
                                readonly: _vm.readonly,
                                tabindex: "7",
                                type: "text"
                              },
                              domProps: { value: _vm.user.companyCity },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.user,
                                    "companyCity",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "validator",
                          {
                            staticClass: "field",
                            attrs: {
                              value: _vm.user.companyState,
                              reqs: "required"
                            }
                          },
                          [
                            _c("label", [_vm._v("Company State")]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.user.companyState,
                                  expression: "user.companyState"
                                }
                              ],
                              attrs: {
                                readonly: _vm.readonly,
                                tabindex: "9",
                                type: "text"
                              },
                              domProps: { value: _vm.user.companyState },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.user,
                                    "companyState",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "validator",
                          {
                            staticClass: "field",
                            attrs: {
                              value: _vm.user.companyEmail,
                              reqs: "required email"
                            }
                          },
                          [
                            _c("label", [_vm._v("Company Email")]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.user.companyEmail,
                                  expression: "user.companyEmail"
                                }
                              ],
                              attrs: {
                                readonly: _vm.readonly,
                                tabindex: "11",
                                type: "text"
                              },
                              domProps: { value: _vm.user.companyEmail },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.user,
                                    "companyEmail",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c(
                          "validator",
                          {
                            staticClass: "field",
                            attrs: {
                              value: _vm.user.companyAddress,
                              reqs: "required"
                            }
                          },
                          [
                            _c("label", [_vm._v("Company Address")]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.user.companyAddress,
                                  expression: "user.companyAddress"
                                }
                              ],
                              attrs: {
                                readonly: _vm.readonly,
                                tabindex: "6",
                                type: "text"
                              },
                              domProps: { value: _vm.user.companyAddress },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.user,
                                    "companyAddress",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "validator",
                          {
                            staticClass: "field",
                            attrs: {
                              value: _vm.user.companyZip,
                              reqs: "required"
                            }
                          },
                          [
                            _c("label", [_vm._v("Company Zip")]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.user.companyZip,
                                  expression: "user.companyZip"
                                }
                              ],
                              attrs: {
                                readonly: _vm.readonly,
                                tabindex: "8",
                                type: "text"
                              },
                              domProps: { value: _vm.user.companyZip },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.user,
                                    "companyZip",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "validator",
                          {
                            staticClass: "field",
                            attrs: {
                              value: _vm.user.companyCountry,
                              reqs: "required"
                            }
                          },
                          [
                            _c("label", [_vm._v("Company Country")]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.user.companyCountry,
                                  expression: "user.companyCountry"
                                }
                              ],
                              attrs: {
                                readonly: _vm.readonly,
                                tabindex: "10",
                                type: "text"
                              },
                              domProps: { value: _vm.user.companyCountry },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.user,
                                    "companyCountry",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "validator",
                          {
                            staticClass: "field",
                            attrs: {
                              value: _vm.user.companyPhone,
                              reqs: "required"
                            }
                          },
                          [
                            _c("label", [_vm._v("Company Phone")]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.user.companyPhone,
                                  expression: "user.companyPhone"
                                }
                              ],
                              attrs: {
                                readonly: _vm.readonly,
                                tabindex: "12",
                                type: "text"
                              },
                              domProps: { value: _vm.user.companyPhone },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.user,
                                    "companyPhone",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]
                        )
                      ],
                      1
                    )
                  ]),
              _vm._v(" "),
              _vm.isAgent
                ? _c(
                    "ul",
                    { staticClass: "user-contacts" },
                    [
                      _vm._l(_vm.user.contacts, function(contact, ci) {
                        return _c("li", [
                          _vm.user.contacts.length > 1
                            ? _c(
                                "a",
                                {
                                  staticClass: "remcon",
                                  attrs: { href: "" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.removeContact(ci)
                                    }
                                  }
                                },
                                [_vm._v("Remove Contact")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticClass: "f2split" }, [
                            _c(
                              "div",
                              [
                                _c(
                                  "validator",
                                  {
                                    staticClass: "field",
                                    attrs: {
                                      value: contact.firstName,
                                      reqs: "required"
                                    }
                                  },
                                  [
                                    _c("label", [_vm._v("Contact First Name")]),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: contact.firstName,
                                          expression: "contact.firstName"
                                        }
                                      ],
                                      attrs: {
                                        readonly: _vm.readonly,
                                        tabindex: 13 + ci * 4,
                                        type: "text"
                                      },
                                      domProps: { value: contact.firstName },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            contact,
                                            "firstName",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "validator",
                                  {
                                    staticClass: "field",
                                    attrs: {
                                      value: contact.phone,
                                      reqs: "required"
                                    }
                                  },
                                  [
                                    _c("label", [_vm._v("Contact Phone")]),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: contact.phone,
                                          expression: "contact.phone"
                                        }
                                      ],
                                      attrs: {
                                        readonly: _vm.readonly,
                                        tabindex: 15 + ci * 4,
                                        type: "text"
                                      },
                                      domProps: { value: contact.phone },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            contact,
                                            "phone",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ]
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c(
                                  "validator",
                                  {
                                    staticClass: "field",
                                    attrs: {
                                      value: contact.lastName,
                                      reqs: "required"
                                    }
                                  },
                                  [
                                    _c("label", [_vm._v("Contact Last Name")]),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: contact.lastName,
                                          expression: "contact.lastName"
                                        }
                                      ],
                                      attrs: {
                                        readonly: _vm.readonly,
                                        tabindex: 14 + ci * 4,
                                        type: "text"
                                      },
                                      domProps: { value: contact.lastName },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            contact,
                                            "lastName",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "validator",
                                  {
                                    staticClass: "field",
                                    attrs: {
                                      value: contact.email,
                                      reqs: "required email"
                                    }
                                  },
                                  [
                                    _c("label", [_vm._v("Contact Email")]),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: contact.email,
                                          expression: "contact.email"
                                        }
                                      ],
                                      attrs: {
                                        readonly: _vm.readonly,
                                        tabindex: 16 + ci * 4,
                                        type: "text"
                                      },
                                      domProps: { value: contact.email },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            contact,
                                            "email",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ])
                        ])
                      }),
                      _vm._v(" "),
                      !_vm.readonly
                        ? _c("li", [
                            _c(
                              "a",
                              {
                                staticClass: "newcon",
                                attrs: { href: "" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.addContact.apply(null, arguments)
                                  }
                                }
                              },
                              [_vm._v("Add additional contact")]
                            ),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass: "newcon",
                                attrs: { href: "" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.resetPassword.apply(
                                      null,
                                      arguments
                                    )
                                  }
                                }
                              },
                              [_vm._v("Reset Password")]
                            ),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass: "newcon",
                                attrs: { href: "" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.welcomeEmail.apply(
                                      null,
                                      arguments
                                    )
                                  }
                                }
                              },
                              [_vm._v("Welcome Email")]
                            )
                          ])
                        : _vm._e()
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.isAgent && !_vm.readonly
                ? _c(
                    "a",
                    {
                      staticClass: "newcon",
                      attrs: { href: "" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.resetPassword.apply(null, arguments)
                        }
                      }
                    },
                    [_vm._v("Reset Password")]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.readonly
                ? _c("div", { staticClass: "buttons" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-large btn-primary",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.send.apply(null, arguments)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.user.id ? "Update" : "Create"))]
                    )
                  ])
                : _vm._e()
            ],
            1
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }