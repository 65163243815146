var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "content-table-container" }, [
      _vm.loaded
        ? _c("table", { staticClass: "content-table" }, [
            _c("tbody", [
              _vm._m(0),
              _vm._v(" "),
              _c("tr", [
                _c(
                  "td",
                  {
                    staticClass: "services auto-height",
                    staticStyle: { "text-align": "left" }
                  },
                  _vm._l(_vm.providers, function(provider) {
                    return _c(
                      "div",
                      { staticClass: "input-group" },
                      [
                        _c("label", [_vm._v(_vm._s(provider.providerName))]),
                        _vm._v(" "),
                        _c("mnb-switch", {
                          attrs: { disabled: !_vm.isNew && !_vm.editable },
                          on: {
                            input: function($event) {
                              return _vm.handleExclusiveProvider(
                                provider,
                                $event
                              )
                            }
                          },
                          model: {
                            value: provider.switch,
                            callback: function($$v) {
                              _vm.$set(provider, "switch", $$v)
                            },
                            expression: "provider.switch"
                          }
                        })
                      ],
                      1
                    )
                  }),
                  0
                )
              ]),
              _vm._v(" "),
              _vm._m(1),
              _vm._v(" "),
              _c("tr", [
                _c(
                  "td",
                  {
                    staticClass: "services auto-height",
                    staticStyle: { "text-align": "left" }
                  },
                  [
                    _c("div", { staticClass: "input-group countries" }, [
                      _c(
                        "div",
                        [
                          _vm._v(
                            "\n                Business\n                "
                          ),
                          _c("multiselect-dropdown", {
                            attrs: {
                              options: _vm.duplicateCheckerBusinessOptions.map(
                                function(a) {
                                  return { label: a, value: a }
                                }
                              ),
                              isMulti: true,
                              disabled: !_vm.isNew && !_vm.editable
                            },
                            model: {
                              value: _vm.item.duplicateCheckerBusiness,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.item,
                                  "duplicateCheckerBusiness",
                                  $$v
                                )
                              },
                              expression: "item.duplicateCheckerBusiness"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _vm._v(
                            "\n                Contacts\n                "
                          ),
                          _c("multiselect-dropdown", {
                            attrs: {
                              options: _vm.duplicateCheckerContactsOptions.map(
                                function(a) {
                                  return { label: a, value: a }
                                }
                              ),
                              isMulti: true,
                              disabled: !_vm.isNew && !_vm.editable
                            },
                            model: {
                              value: _vm.item.duplicateCheckerContacts,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.item,
                                  "duplicateCheckerContacts",
                                  $$v
                                )
                              },
                              expression: "item.duplicateCheckerContacts"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div")
                    ])
                  ]
                )
              ]),
              _vm._v(" "),
              _vm._m(2),
              _vm._v(" "),
              _c("tr", [
                _c(
                  "td",
                  {
                    staticClass: "services auto-height",
                    staticStyle: { "text-align": "left" }
                  },
                  [
                    _c("div", { staticClass: "input-group countries" }, [
                      _c(
                        "div",
                        [
                          _vm._v(
                            "\n                Business Formation\n                "
                          ),
                          _c("multiselect-dropdown", {
                            attrs: {
                              options: _vm.fullCountries.map(function(a) {
                                return { label: a.text, value: a.id }
                              }),
                              isMulti: true,
                              disabled: !_vm.isNew && !_vm.editable
                            },
                            model: {
                              value: _vm.item.businessFormation,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "businessFormation", $$v)
                              },
                              expression: "item.businessFormation"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _vm._v(
                            "\n                Contacts\n                "
                          ),
                          _c("multiselect-dropdown", {
                            attrs: {
                              options: _vm.fullCountries.map(function(a) {
                                return { label: a.text, value: a.id }
                              }),
                              isMulti: true,
                              disabled: !_vm.isNew && !_vm.editable
                            },
                            model: {
                              value: _vm.item.contacts,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "contacts", $$v)
                              },
                              expression: "item.contacts"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div")
                    ])
                  ]
                )
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("th", [
                  _c("label", [_vm._v("Monthly plan")]),
                  _vm._v(" "),
                  !_vm.monthlyPlanForm
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-small",
                          attrs: { disabled: !_vm.isNew && !_vm.editable },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              _vm.monthlyPlanForm = true
                            }
                          }
                        },
                        [_vm._v("Add")]
                      )
                    : _c("form", { on: { submit: _vm.addMonthlyPlan } }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.addMPlanName,
                              expression: "addMPlanName"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: _vm.addMPlanName },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.addMPlanName = $event.target.value
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("button", { staticClass: "btn btn-small" }, [
                          _vm._v("Save")
                        ]),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-small",
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                _vm.monthlyPlanForm = false
                                _vm.addMPlanName = ""
                              }
                            }
                          },
                          [_vm._v("Cancel")]
                        )
                      ])
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", { staticStyle: { "text-align": "left" } }, [
                  _c(
                    "div",
                    { staticClass: "services" },
                    _vm._l(_vm.item.allMethods, function(method) {
                      return _c("label", { staticClass: "input-group" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(method) +
                            "\n                "
                        ),
                        _c("span", { staticClass: "mn-switch" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: false,
                                expression: "false"
                              },
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.item.enabledMethods,
                                expression: "item.enabledMethods"
                              }
                            ],
                            attrs: {
                              type: "checkbox",
                              disabled: !_vm.isNew && !_vm.editable
                            },
                            domProps: {
                              value: method,
                              checked: Array.isArray(_vm.item.enabledMethods)
                                ? _vm._i(_vm.item.enabledMethods, method) > -1
                                : _vm.item.enabledMethods
                            },
                            on: {
                              change: function($event) {
                                var $$a = _vm.item.enabledMethods,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = method,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.item,
                                        "enabledMethods",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.item,
                                        "enabledMethods",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(_vm.item, "enabledMethods", $$c)
                                }
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "mn-slider" })
                        ])
                      ])
                    }),
                    0
                  )
                ])
              ]),
              _vm._v(" "),
              _vm._m(3),
              _vm._v(" "),
              _c("tr", [
                _c("td", { staticStyle: { "text-align": "left" } }, [
                  _c("div", { staticClass: "services auto-height" }, [
                    _vm._m(4),
                    _vm._v(" "),
                    _c("label", { staticClass: "input-group" }, [
                      _vm._v("\n                PrePay\n                "),
                      _vm.settings.modules.banking.prepay
                        ? _c("span", { staticClass: "mn-switch" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: false,
                                  expression: "false"
                                },
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.item.enabledMethods,
                                  expression: "item.enabledMethods"
                                }
                              ],
                              attrs: {
                                type: "checkbox",
                                value: "PrePay",
                                disabled: !_vm.isNew && !_vm.editable
                              },
                              domProps: {
                                checked: Array.isArray(_vm.item.enabledMethods)
                                  ? _vm._i(_vm.item.enabledMethods, "PrePay") >
                                    -1
                                  : _vm.item.enabledMethods
                              },
                              on: {
                                change: function($event) {
                                  var $$a = _vm.item.enabledMethods,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = "PrePay",
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.item,
                                          "enabledMethods",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.item,
                                          "enabledMethods",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(_vm.item, "enabledMethods", $$c)
                                  }
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "mn-slider" })
                          ])
                        : _c("span", { staticClass: "mn-switch" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: false,
                                  expression: "false"
                                },
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.item.enabledMethods,
                                  expression: "item.enabledMethods"
                                }
                              ],
                              attrs: {
                                type: "checkbox",
                                value: "",
                                disabled: true
                              },
                              domProps: {
                                checked: Array.isArray(_vm.item.enabledMethods)
                                  ? _vm._i(_vm.item.enabledMethods, "") > -1
                                  : _vm.item.enabledMethods
                              },
                              on: {
                                change: function($event) {
                                  var $$a = _vm.item.enabledMethods,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = "",
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.item,
                                          "enabledMethods",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.item,
                                          "enabledMethods",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(_vm.item, "enabledMethods", $$c)
                                  }
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "mn-slider" })
                          ])
                    ]),
                    _vm._v(" "),
                    _c("label", { staticClass: "input-group" }, [
                      _vm._v("\n                PostPay\n                "),
                      _vm.settings.modules.banking.postpay
                        ? _c("span", { staticClass: "mn-switch" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: false,
                                  expression: "false"
                                },
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.item.enabledMethods,
                                  expression: "item.enabledMethods"
                                }
                              ],
                              attrs: {
                                type: "checkbox",
                                value: "PostPay",
                                disabled: !_vm.isNew && !_vm.editable
                              },
                              domProps: {
                                checked: Array.isArray(_vm.item.enabledMethods)
                                  ? _vm._i(_vm.item.enabledMethods, "PostPay") >
                                    -1
                                  : _vm.item.enabledMethods
                              },
                              on: {
                                change: function($event) {
                                  var $$a = _vm.item.enabledMethods,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = "PostPay",
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.item,
                                          "enabledMethods",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.item,
                                          "enabledMethods",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(_vm.item, "enabledMethods", $$c)
                                  }
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "mn-slider" })
                          ])
                        : _c("span", { staticClass: "mn-switch" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: false,
                                  expression: "false"
                                },
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.item.enabledMethods,
                                  expression: "item.enabledMethods"
                                }
                              ],
                              attrs: {
                                type: "checkbox",
                                value: "",
                                disabled: true
                              },
                              domProps: {
                                checked: Array.isArray(_vm.item.enabledMethods)
                                  ? _vm._i(_vm.item.enabledMethods, "") > -1
                                  : _vm.item.enabledMethods
                              },
                              on: {
                                change: function($event) {
                                  var $$a = _vm.item.enabledMethods,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = "",
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.item,
                                          "enabledMethods",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.item,
                                          "enabledMethods",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(_vm.item, "enabledMethods", $$c)
                                  }
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "mn-slider" })
                          ])
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _vm._m(5),
              _vm._v(" "),
              _c("tr", [
                _c(
                  "td",
                  {
                    staticClass: "services auto-height",
                    staticStyle: { "text-align": "left" }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "input-group" },
                      [
                        _c("label", [_vm._v("KYC")]),
                        _vm._v(" "),
                        _vm.providerChecked
                          ? [
                              _vm.checkSwitch("accounts", "kyc") == true
                                ? [
                                    _c("mnb-switch", {
                                      attrs: {
                                        disabled: !_vm.isNew && !_vm.editable
                                      },
                                      model: {
                                        value: _vm.item.service.accounts.kyc,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.item.service.accounts,
                                            "kyc",
                                            $$v
                                          )
                                        },
                                        expression: "item.service.accounts.kyc"
                                      }
                                    })
                                  ]
                                : [
                                    _c("mnb-switch", {
                                      attrs: {
                                        disabled: true,
                                        set: (_vm.item.service.accounts.kyc = false)
                                      }
                                    })
                                  ]
                            ]
                          : [
                              _c("mnb-switch", {
                                attrs: {
                                  disabled: true,
                                  set: (_vm.item.service.accounts.kyc = false)
                                }
                              })
                            ]
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "input-group" },
                      [
                        _c("label", [_vm._v("AML")]),
                        _vm._v(" "),
                        _vm.providerChecked
                          ? [
                              _vm.checkSwitch("accounts", "aml") == true
                                ? [
                                    _c("mnb-switch", {
                                      attrs: {
                                        disabled: !_vm.isNew && !_vm.editable
                                      },
                                      model: {
                                        value: _vm.item.service.accounts.aml,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.item.service.accounts,
                                            "aml",
                                            $$v
                                          )
                                        },
                                        expression: "item.service.accounts.aml"
                                      }
                                    })
                                  ]
                                : [
                                    _c("mnb-switch", {
                                      attrs: {
                                        disabled: true,
                                        set: (_vm.item.service.accounts.aml = false)
                                      }
                                    })
                                  ]
                            ]
                          : [
                              _c("mnb-switch", {
                                attrs: {
                                  disabled: true,
                                  set: (_vm.item.service.accounts.aml = false)
                                }
                              })
                            ]
                      ],
                      2
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _vm._m(6),
              _vm._v(" "),
              _c("tr", [
                _c(
                  "td",
                  {
                    staticClass: "services auto-height",
                    staticStyle: { "text-align": "left" }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "input-group" },
                      [
                        _c("label", [_vm._v("ACH")]),
                        _vm._v(" "),
                        _vm.providerChecked
                          ? [
                              _vm.checkSwitch("methods", "ach") == true
                                ? [
                                    _c("mnb-switch", {
                                      attrs: {
                                        disabled: !_vm.isNew && !_vm.editable
                                      },
                                      model: {
                                        value: _vm.item.service.methods.ach,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.item.service.methods,
                                            "ach",
                                            $$v
                                          )
                                        },
                                        expression: "item.service.methods.ach"
                                      }
                                    })
                                  ]
                                : [
                                    _c("mnb-switch", {
                                      attrs: {
                                        disabled: true,
                                        set: (_vm.item.service.methods.ach = false)
                                      }
                                    })
                                  ]
                            ]
                          : [
                              _c("mnb-switch", {
                                attrs: {
                                  disabled: true,
                                  set: (_vm.item.service.methods.ach = false)
                                }
                              })
                            ]
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "input-group" },
                      [
                        _c("label", [_vm._v("Account Transfer")]),
                        _vm._v(" "),
                        _vm.providerChecked
                          ? [
                              _vm.checkSwitch("methods", "accountTransfer") ==
                              true
                                ? [
                                    _c("mnb-switch", {
                                      attrs: {
                                        disabled: !_vm.isNew && !_vm.editable
                                      },
                                      model: {
                                        value:
                                          _vm.item.service.methods
                                            .accountTransfer,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.item.service.methods,
                                            "accountTransfer",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "item.service.methods.accountTransfer"
                                      }
                                    })
                                  ]
                                : [
                                    _c("mnb-switch", {
                                      attrs: {
                                        disabled: true,
                                        set: (_vm.item.service.methods.accountTransfer = false)
                                      }
                                    })
                                  ]
                            ]
                          : [
                              _c("mnb-switch", {
                                attrs: {
                                  disabled: true,
                                  set: (_vm.item.service.methods.accountTransfer = false)
                                }
                              })
                            ]
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "input-group" },
                      [
                        _c("label", [_vm._v("Checks")]),
                        _vm._v(" "),
                        _vm.providerChecked
                          ? [
                              _vm.checkSwitch("methods", "checks") == true
                                ? [
                                    _c("mnb-switch", {
                                      attrs: {
                                        disabled: !_vm.isNew && !_vm.editable
                                      },
                                      model: {
                                        value: _vm.item.service.methods.checks,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.item.service.methods,
                                            "checks",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "item.service.methods.checks"
                                      }
                                    })
                                  ]
                                : [
                                    _c("mnb-switch", {
                                      attrs: {
                                        disabled: true,
                                        set: (_vm.item.service.methods.checks = false)
                                      }
                                    })
                                  ]
                            ]
                          : [
                              _c("mnb-switch", {
                                attrs: {
                                  disabled: true,
                                  set: (_vm.item.service.methods.checks = false)
                                }
                              })
                            ]
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "input-group" },
                      [
                        _c("label", [_vm._v("Remote Deposit")]),
                        _vm._v(" "),
                        _vm.providerChecked
                          ? [
                              _vm.checkSwitch("methods", "remoteDeposit") ==
                              true
                                ? [
                                    _c("mnb-switch", {
                                      attrs: {
                                        disabled: !_vm.isNew && !_vm.editable
                                      },
                                      model: {
                                        value:
                                          _vm.item.service.methods
                                            .remoteDeposit,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.item.service.methods,
                                            "remoteDeposit",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "item.service.methods.remoteDeposit"
                                      }
                                    })
                                  ]
                                : [
                                    _c("mnb-switch", {
                                      attrs: {
                                        disabled: true,
                                        set: (_vm.item.service.methods.remoteDeposit = false)
                                      }
                                    })
                                  ]
                            ]
                          : [
                              _c("mnb-switch", {
                                attrs: {
                                  disabled: true,
                                  set: (_vm.item.service.methods.remoteDeposit = false)
                                }
                              })
                            ]
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "input-group" },
                      [
                        _c("label", [_vm._v("Domestic Wires")]),
                        _vm._v(" "),
                        _vm.providerChecked
                          ? [
                              _vm.checkSwitch("methods", "domesticWires") ==
                              true
                                ? [
                                    _c("mnb-switch", {
                                      attrs: {
                                        disabled: !_vm.isNew && !_vm.editable
                                      },
                                      model: {
                                        value:
                                          _vm.item.service.methods
                                            .domesticWires,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.item.service.methods,
                                            "domesticWires",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "item.service.methods.domesticWires"
                                      }
                                    })
                                  ]
                                : [
                                    _c("mnb-switch", {
                                      attrs: {
                                        disabled: true,
                                        set: (_vm.item.service.methods.domesticWires = false)
                                      }
                                    })
                                  ]
                            ]
                          : [
                              _c("mnb-switch", {
                                attrs: {
                                  disabled: true,
                                  set: (_vm.item.service.methods.domesticWires = false)
                                }
                              })
                            ]
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "input-group" },
                      [
                        _c("label", [_vm._v("International Wires")]),
                        _vm._v(" "),
                        _vm.providerChecked
                          ? [
                              _vm.checkSwitch(
                                "methods",
                                "internationalWires"
                              ) == true
                                ? [
                                    _c("mnb-switch", {
                                      attrs: {
                                        disabled: !_vm.isNew && !_vm.editable
                                      },
                                      model: {
                                        value:
                                          _vm.item.service.methods
                                            .internationalWires,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.item.service.methods,
                                            "internationalWires",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "item.service.methods.internationalWires"
                                      }
                                    })
                                  ]
                                : [
                                    _c("mnb-switch", {
                                      attrs: {
                                        disabled: true,
                                        set: (_vm.item.service.methods.internationalWires = false)
                                      }
                                    })
                                  ]
                            ]
                          : [
                              _c("mnb-switch", {
                                attrs: {
                                  disabled: true,
                                  set: (_vm.item.service.methods.internationalWires = false)
                                }
                              })
                            ]
                      ],
                      2
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _vm._m(7),
              _vm._v(" "),
              _c("tr", [
                _c(
                  "td",
                  {
                    staticClass: "services auto-height",
                    staticStyle: { "text-align": "left" }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "input-group" },
                      [
                        _c("label", [_vm._v("Debit Cards")]),
                        _vm._v(" "),
                        _vm.settings.modules.banking.cardIssuing
                          ? [
                              _vm.providerChecked
                                ? [
                                    _vm.checkSwitch("options", "debitCards") ==
                                    true
                                      ? [
                                          _c("mnb-switch", {
                                            attrs: {
                                              disabled:
                                                !_vm.isNew && !_vm.editable
                                            },
                                            model: {
                                              value:
                                                _vm.item.service.options
                                                  .debitCards,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.item.service.options,
                                                  "debitCards",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "item.service.options.debitCards"
                                            }
                                          })
                                        ]
                                      : [
                                          _c("mnb-switch", {
                                            attrs: {
                                              disabled: true,
                                              set: (_vm.item.service.options.debitCards = false)
                                            }
                                          })
                                        ]
                                  ]
                                : [
                                    _c("mnb-switch", {
                                      attrs: {
                                        disabled: true,
                                        set: (_vm.item.service.options.debitCards = false)
                                      }
                                    })
                                  ]
                            ]
                          : [
                              _c("mnb-switch", {
                                attrs: {
                                  disabled: true,
                                  set: (_vm.item.service.options.debitCards = false)
                                }
                              })
                            ]
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "input-group" },
                      [
                        _c("label", [_vm._v("Digital Assets")]),
                        _vm._v(" "),
                        _vm.settings.modules.banking.digitalAssets
                          ? [
                              _vm.providerChecked
                                ? [
                                    _vm.checkSwitch(
                                      "advancedMethods",
                                      "digitalAssets"
                                    ) == true
                                      ? [
                                          _c("mnb-switch", {
                                            attrs: {
                                              disabled:
                                                !_vm.isNew && !_vm.editable
                                            },
                                            model: {
                                              value:
                                                _vm.item.service.advancedMethods
                                                  .digitalAssets,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.item.service
                                                    .advancedMethods,
                                                  "digitalAssets",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "item.service.advancedMethods.digitalAssets"
                                            }
                                          })
                                        ]
                                      : [
                                          _c("mnb-switch", {
                                            attrs: {
                                              disabled: true,
                                              set: (_vm.item.service.advancedMethods.digitalAssets = false)
                                            }
                                          })
                                        ]
                                  ]
                                : [
                                    _c("mnb-switch", {
                                      attrs: {
                                        disabled: true,
                                        set: (_vm.item.service.advancedMethods.digitalAssets = false)
                                      }
                                    })
                                  ]
                            ]
                          : [
                              _c("mnb-switch", {
                                attrs: {
                                  disabled: true,
                                  set: (_vm.item.service.advancedMethods.digitalAssets = false)
                                }
                              })
                            ]
                      ],
                      2
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _vm._m(8),
              _vm._v(" "),
              _c("tr", [
                _c(
                  "td",
                  {
                    staticClass: "services auto-height",
                    staticStyle: { "text-align": "left" }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "input-group" },
                      [
                        _c("label", [_vm._v("Exchange Desk")]),
                        _vm._v(" "),
                        _vm.settings.modules.banking.exchangeDesk
                          ? [
                              _c("mnb-switch", {
                                attrs: {
                                  disabled: !_vm.isNew && !_vm.editable
                                },
                                model: {
                                  value:
                                    _vm.item.service.advancedMethods
                                      .exchangeDesk,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.item.service.advancedMethods,
                                      "exchangeDesk",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "item.service.advancedMethods.exchangeDesk"
                                }
                              })
                            ]
                          : [
                              _c("mnb-switch", {
                                attrs: {
                                  set: (_vm.item.service.advancedMethods.exchangeDesk = false),
                                  disabled: true
                                }
                              })
                            ]
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "input-group" },
                      [
                        _c("label", [_vm._v("Trade Desk")]),
                        _vm._v(" "),
                        _vm.settings.modules.banking.tradeDesk
                          ? [
                              _vm.item.service.advancedMethods.digitalAssets
                                ? [
                                    _c("mnb-switch", {
                                      attrs: {
                                        disabled: !_vm.isNew && !_vm.editable
                                      },
                                      model: {
                                        value:
                                          _vm.item.service.advancedMethods
                                            .tradeDesk,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.item.service.advancedMethods,
                                            "tradeDesk",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "item.service.advancedMethods.tradeDesk"
                                      }
                                    })
                                  ]
                                : [
                                    _c("mnb-switch", {
                                      attrs: { disabled: true },
                                      model: {
                                        value:
                                          _vm.item.service.advancedMethods
                                            .tradeDesk,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.item.service.advancedMethods,
                                            "tradeDesk",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "item.service.advancedMethods.tradeDesk"
                                      }
                                    })
                                  ]
                            ]
                          : [
                              _c("mnb-switch", {
                                attrs: { disabled: true },
                                model: {
                                  value:
                                    _vm.item.service.advancedMethods.tradeDesk,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.item.service.advancedMethods,
                                      "tradeDesk",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "item.service.advancedMethods.tradeDesk"
                                }
                              })
                            ]
                      ],
                      2
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _vm._m(9),
              _vm._v(" "),
              _c("tr", [
                _c(
                  "td",
                  {
                    staticClass: "services auto-height",
                    staticStyle: { "text-align": "left" }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "input-group" },
                      [
                        _c("label", [_vm._v("Multi-currency")]),
                        _vm._v(" "),
                        _vm.settings.modules.banking.multiCurrency
                          ? [
                              _vm.providerChecked
                                ? [
                                    _vm.checkSwitch(
                                      "options",
                                      "multiCurrency"
                                    ) == true
                                      ? [
                                          _c("mnb-switch", {
                                            attrs: {
                                              disabled:
                                                !_vm.isNew && !_vm.editable
                                            },
                                            model: {
                                              value:
                                                _vm.item.service.options
                                                  .multiCurrency,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.item.service.options,
                                                  "multiCurrency",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "item.service.options.multiCurrency"
                                            }
                                          })
                                        ]
                                      : [
                                          _c("mnb-switch", {
                                            attrs: {
                                              disabled: true,
                                              value: false,
                                              set: (_vm.item.service.options.multiCurrency = false)
                                            }
                                          })
                                        ]
                                  ]
                                : [
                                    _c("mnb-switch", {
                                      attrs: {
                                        disabled: true,
                                        value: false,
                                        set: (_vm.item.service.options.multiCurrency = false)
                                      }
                                    })
                                  ]
                            ]
                          : [
                              _c("mnb-switch", {
                                attrs: {
                                  disabled: true,
                                  value: false,
                                  set: (_vm.item.service.options.multiCurrency = false)
                                }
                              })
                            ]
                      ],
                      2
                    )
                  ]
                )
              ])
            ])
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_c("label", [_vm._v("\n            Providers\n          ")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [
        _c("label", [_vm._v("\n            Duplicate Checker\n          ")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_c("label", [_vm._v("\n            Countries\n          ")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [_c("th", [_c("label", [_vm._v("Pricing Model")])])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group" }, [
      _c("label", [_vm._v("Billing Option")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [_c("th", [_c("label", [_vm._v("Accounts")])])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [_c("th", [_c("label", [_vm._v("Methods")])])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [_c("th", [_c("label", [_vm._v("Advanced Methods")])])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [_c("th", [_c("label", [_vm._v("Modules")])])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [_c("th", [_c("label", [_vm._v("Options")])])])
  }
]
render._withStripped = true

export { render, staticRenderFns }