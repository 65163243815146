var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    { staticClass: "edit-system search-form company-branding" },
    [
      _c("div", { staticClass: "twocol" }, [
        _c("div", [
          _c("h1", [_vm._v("Partner Portal")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field" },
            [
              _c("label", [_vm._v("Login Logo:")]),
              _vm._v(" "),
              _c("photo-obj", {
                attrs: { basepath: _vm.basePath },
                model: {
                  value: _vm.files.partnerLoginLogo,
                  callback: function($$v) {
                    _vm.$set(_vm.files, "partnerLoginLogo", $$v)
                  },
                  expression: "files.partnerLoginLogo"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "field" }, [
            _c("label", [_vm._v("Login Text:")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.partnerLoginText,
                  expression: "form.partnerLoginText"
                }
              ],
              domProps: { value: _vm.form.partnerLoginText },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "partnerLoginText", $event.target.value)
                }
              }
            })
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field" },
            [
              _c("label", [_vm._v("Main Logo:")]),
              _vm._v(" "),
              _c("photo-obj", {
                attrs: { basepath: _vm.basePath },
                model: {
                  value: _vm.files.partnerLogo,
                  callback: function($$v) {
                    _vm.$set(_vm.files, "partnerLogo", $$v)
                  },
                  expression: "files.partnerLogo"
                }
              })
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "buttons" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-large btn-primary",
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.updateSettings.apply(null, arguments)
              }
            }
          },
          [_vm._v("Submit")]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }