var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { grbackground: _vm.tab > 0 } },
    [
      _vm.tab == 0
        ? _c("div", { staticClass: "status" }, [
            _c("em", [
              _c("i", [_vm._v("Account Reviews")]),
              _vm._v(
                _vm._s(_vm.accountReview) +
                  " " +
                  _vm._s(_vm.accountReview != 1 ? "Days" : "Day")
              )
            ]),
            _vm._v(" "),
            _c("label", [
              _vm._v("Current Status: "),
              _c("strong", [
                _vm._v(
                  _vm._s(_vm.computedStatus) +
                    " (" +
                    _vm._s(_vm.statusDesc(_vm.computedStatus)) +
                    ")"
                )
              ])
            ]),
            _vm._v(" "),
            _vm.item.status == "A02" || _vm.item.status == "A03"
              ? _c(
                  "span",
                  { class: _vm.agoClass(_vm.item.submitDate, _vm.now) },
                  [
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.agoTimer(_vm.item.submitDate, _vm.now)) +
                        "\n    "
                    )
                  ]
                )
              : _vm.item.status == "A04"
              ? _c(
                  "span",
                  {
                    class: _vm.agoClass(
                      _vm.item.submitDate,
                      _vm.item.approveDate
                    )
                  },
                  [
                    _vm._v(
                      "\n      " +
                        _vm._s(
                          _vm.agoTimer(
                            _vm.item.submitDate,
                            _vm.item.approveDate
                          )
                        ) +
                        "\n    "
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.item.status == "A04" &&
            (!_vm.merchant || _vm.merchant.status == "M00")
              ? _c(
                  "span",
                  {
                    class: _vm.agoClass(
                      _vm.item.approveDate || _vm.item.submitDate,
                      _vm.now
                    )
                  },
                  [
                    _vm._v(
                      "\n      " +
                        _vm._s(
                          _vm.agoTimer(
                            _vm.item.approveDate || _vm.item.submitDate,
                            _vm.now
                          )
                        ) +
                        "\n    "
                    )
                  ]
                )
              : _c("span", [_vm._v("\n      00:00:00\n    ")])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.tab == 0
        ? _c("form", { staticClass: "preq-form preq-view" }, [
            _c("section", [
              _c("ul", { staticClass: "appview-list" }, [
                _c("li", [
                  _c("label", [_vm._v("Created")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.formatDate(_vm.item.createdDate, "YYYY-MM-DD")
                        )
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Solution")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "validator" },
                    [
                      _vm.editMode
                        ? _c("dyn-select", {
                            attrs: { options: _vm.solutions },
                            model: {
                              value: _vm.item.solution,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "solution", $$v)
                              },
                              expression: "item.solution"
                            }
                          })
                        : _c("span", [
                            _vm._v(_vm._s(_vm.solution(_vm.item.solution)))
                          ])
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Profile")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "validator" },
                    [
                      _vm.editMode
                        ? _c("dyn-select", {
                            attrs: { options: _vm.managersToShow },
                            model: {
                              value: _vm.item.managerId,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "managerId", $$v)
                              },
                              expression: "item.managerId"
                            }
                          })
                        : _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.item.manager &&
                                  (_vm.item.manager.username ||
                                    _vm.item.manager.id)
                              )
                            )
                          ])
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Merchant Type")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "validator" },
                    [
                      _vm.editMode
                        ? _c("dyn-select", {
                            attrs: { options: _vm.merchantTypes },
                            model: {
                              value: _vm.item.merchantType,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "merchantType", $$v)
                              },
                              expression: "item.merchantType"
                            }
                          })
                        : _c("span", [
                            _vm._v(
                              _vm._s(_vm.merchantType(_vm.item.merchantType))
                            )
                          ])
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Industry")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "validator" },
                    [
                      _vm.editMode
                        ? _c("dyn-select", {
                            attrs: { options: _vm.industries },
                            model: {
                              value: _vm.item.industry,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "industry", $$v)
                              },
                              expression: "item.industry"
                            }
                          })
                        : _c("span", [_vm._v(_vm._s(_vm.item.industry))])
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Partner")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "validator" },
                    [
                      _vm.editMode
                        ? _c("dyn-select", {
                            attrs: { options: _vm.agentsToShow },
                            model: {
                              value: _vm.item.sourceId,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "sourceId", $$v)
                              },
                              expression: "item.sourceId"
                            }
                          })
                        : _c("span", [
                            _vm._v(
                              _vm._s(
                                (_vm.item.source &&
                                  (_vm.item.source.agentId || "internal")) ||
                                  "internal"
                              )
                            )
                          ])
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Referrer")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "validator" },
                    [
                      _vm.editMode
                        ? _c("dyn-select", {
                            attrs: { options: _vm.referrersToShow },
                            model: {
                              value: _vm.item.referrerId,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "referrerId", $$v)
                              },
                              expression: "item.referrerId"
                            }
                          })
                        : _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.item.referrer &&
                                  (_vm.item.source.referrerId ||
                                    _vm.item.referrer.id)
                              )
                            )
                          ])
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Approval Date")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.formatDate(_vm.item.approveDate, "YYYY-MM-DD")
                        )
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Closed Date")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.formatDate(_vm.item.closedDate, "YYYY-MM-DD")
                        )
                      )
                    ])
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _vm.editMode
              ? _c("div", { staticClass: "form-actions" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-large",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.saveItem.apply(null, arguments)
                        }
                      }
                    },
                    [_vm._v("Save")]
                  )
                ])
              : _vm._e()
          ])
        : _vm.tab == 1
        ? _c("form", { staticClass: "preq-form" }, [
            _c("section", { staticClass: "border" }, [
              _c("div", { staticClass: "light-grey" }, [
                _c("div", { staticClass: "leftrightcontent" }, [
                  _c("label", [_vm._v("New Business")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "validator" },
                    [
                      !_vm.editMode
                        ? _c("span", [
                            _vm._v(
                              _vm._s(_vm.item.companyNew == 1 ? "Yes" : "No")
                            )
                          ])
                        : _c("formel-toggle", {
                            model: {
                              value: _vm.item.companyNew,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "companyNew", $$v)
                              },
                              expression: "item.companyNew"
                            }
                          })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "leftrightcontent" }, [
                  _c("label", [_vm._v("Has parent")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "validator" },
                    [
                      !_vm.editMode
                        ? _c("span", [
                            _vm._v(
                              _vm._s(_vm.item.hasParent == 1 ? "Yes" : "No")
                            )
                          ])
                        : _c("formel-toggle", {
                            model: {
                              value: _vm.item.hasParent,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "hasParent", $$v)
                              },
                              expression: "item.hasParent"
                            }
                          })
                    ],
                    1
                  )
                ])
              ]),
              _vm._v(" "),
              _vm._m(0),
              _vm._v(" "),
              _c("ul", { staticClass: "appview-list column6" }, [
                _c("li", [
                  _c("label", [_vm._v("Legal name")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [_vm._v(_vm._s(_vm.item.companyName))])
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.companyName,
                              expression: "item.companyName"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: _vm.item.companyName },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.item,
                                "companyName",
                                $event.target.value
                              )
                            }
                          }
                        })
                  ]),
                  _vm._v(" "),
                  false
                    ? _c("div", { staticClass: "validator" }, [
                        !_vm.editMode
                          ? _c("span", [_vm._v(_vm._s(_vm.item.parentName))])
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.item.parentName,
                                  expression: "item.parentName"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: _vm.item.parentName },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.item,
                                    "parentName",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("DBA")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [_vm._v(_vm._s(_vm.item.companyNameTrade))])
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.companyNameTrade,
                              expression: "item.companyNameTrade"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: _vm.item.companyNameTrade },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.item,
                                "companyNameTrade",
                                $event.target.value
                              )
                            }
                          }
                        })
                  ]),
                  _vm._v(" "),
                  false
                    ? _c("div", { staticClass: "validator" }, [
                        !_vm.editMode
                          ? _c("span", [
                              _vm._v(_vm._s(_vm.item.parentNameTrade))
                            ])
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.item.parentNameTrade,
                                  expression: "item.parentNameTrade"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: _vm.item.parentNameTrade },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.item,
                                    "parentNameTrade",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Type")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [_vm._v(_vm._s(_vm.item.companyType))])
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.companyType,
                              expression: "item.companyType"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: _vm.item.companyType },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.item,
                                "companyType",
                                $event.target.value
                              )
                            }
                          }
                        })
                  ]),
                  _vm._v(" "),
                  false
                    ? _c("div", { staticClass: "validator" }, [
                        !_vm.editMode
                          ? _c("span", [_vm._v(_vm._s(_vm.item.parentType))])
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.item.parentType,
                                  expression: "item.parentType"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: _vm.item.parentType },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.item,
                                    "parentType",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Registration number")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [_vm._v(_vm._s(_vm.item.companyRegnum))])
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.companyRegnum,
                              expression: "item.companyRegnum"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: _vm.item.companyRegnum },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.item,
                                "companyRegnum",
                                $event.target.value
                              )
                            }
                          }
                        })
                  ]),
                  _vm._v(" "),
                  false
                    ? _c("div", { staticClass: "validator" }, [
                        !_vm.editMode
                          ? _c("span", [_vm._v(_vm._s(_vm.item.parentRegnum))])
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.item.parentRegnum,
                                  expression: "item.parentRegnum"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: _vm.item.parentRegnum },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.item,
                                    "parentRegnum",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Tax ID")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [_vm._v(_vm._s(_vm.item.companyTaxId))])
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.companyTaxId,
                              expression: "item.companyTaxId"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: _vm.item.companyTaxId },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.item,
                                "companyTaxId",
                                $event.target.value
                              )
                            }
                          }
                        })
                  ]),
                  _vm._v(" "),
                  false
                    ? _c("div", { staticClass: "validator" }, [
                        !_vm.editMode
                          ? _c("span", [_vm._v(_vm._s(_vm.item.parentTaxId))])
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.item.parentTaxId,
                                  expression: "item.parentTaxId"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: _vm.item.parentTaxId },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.item,
                                    "parentTaxId",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Incorporation date")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "validator" },
                    [
                      !_vm.editMode
                        ? _c("span", [_vm._v(_vm._s(_vm.item.companyDate))])
                        : _c("datepicker", {
                            model: {
                              value: _vm.item.companyDate,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "companyDate", $$v)
                              },
                              expression: "item.companyDate"
                            }
                          })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  false
                    ? _c(
                        "div",
                        { staticClass: "validator" },
                        [
                          !_vm.editMode
                            ? _c("span", [_vm._v(_vm._s(_vm.item.parentDate))])
                            : _c("datepicker", {
                                model: {
                                  value: _vm.item.parentDate,
                                  callback: function($$v) {
                                    _vm.$set(_vm.item, "parentDate", $$v)
                                  },
                                  expression: "item.parentDate"
                                }
                              })
                        ],
                        1
                      )
                    : _vm._e()
                ])
              ]),
              _vm._v(" "),
              _c("ul", { staticClass: "appview-list" }, [
                _c("li", [
                  _c("label", [_vm._v("ADDRESS")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [_vm._v(_vm._s(_vm.item.companyAddress))])
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.companyAddress,
                              expression: "item.companyAddress"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: _vm.item.companyAddress },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.item,
                                "companyAddress",
                                $event.target.value
                              )
                            }
                          }
                        })
                  ]),
                  _vm._v(" "),
                  false
                    ? _c("div", { staticClass: "validator" }, [
                        !_vm.editMode
                          ? _c("span", [_vm._v(_vm._s(_vm.item.parentAddress))])
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.item.parentAddress,
                                  expression: "item.parentAddress"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: _vm.item.parentAddress },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.item,
                                    "parentAddress",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("City")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [
                          _vm._v(_vm._s(_vm.item.companyAddressCity))
                        ])
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.companyAddressCity,
                              expression: "item.companyAddressCity"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: _vm.item.companyAddressCity },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.item,
                                "companyAddressCity",
                                $event.target.value
                              )
                            }
                          }
                        })
                  ]),
                  _vm._v(" "),
                  false
                    ? _c("div", { staticClass: "validator" }, [
                        !_vm.editMode
                          ? _c("span", [
                              _vm._v(_vm._s(_vm.item.parentAddressCity))
                            ])
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.item.parentAddressCity,
                                  expression: "item.parentAddressCity"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: _vm.item.parentAddressCity },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.item,
                                    "parentAddressCity",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("PROVINCE/STATE")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "validator" },
                    [
                      _vm.editMode
                        ? [
                            _vm.item.companyAddressCountry == "CA" ||
                            _vm.item.companyAddressCountry == "US"
                              ? _c("province-select", {
                                  attrs: {
                                    value: _vm.item.companyAddressState,
                                    country: _vm.item.companyAddressCountry
                                  },
                                  model: {
                                    value: _vm.item.companyAddressState,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.item,
                                        "companyAddressState",
                                        $$v
                                      )
                                    },
                                    expression: "item.companyAddressState"
                                  }
                                })
                              : _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.item.companyAddressState,
                                      expression: "item.companyAddressState"
                                    }
                                  ],
                                  attrs: { type: "text" },
                                  domProps: {
                                    value: _vm.item.companyAddressState
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.item,
                                        "companyAddressState",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                          ]
                        : _c("span", [
                            _vm._v(_vm._s(_vm.item.companyAddressState))
                          ])
                    ],
                    2
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("POSTAL/ZIP CODE")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [
                          _vm._v(_vm._s(_vm.item.companyAddressPostcode))
                        ])
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.companyAddressPostcode,
                              expression: "item.companyAddressPostcode"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: _vm.item.companyAddressPostcode },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.item,
                                "companyAddressPostcode",
                                $event.target.value
                              )
                            }
                          }
                        })
                  ]),
                  _vm._v(" "),
                  false
                    ? _c("div", { staticClass: "validator" }, [
                        !_vm.editMode
                          ? _c("span", [
                              _vm._v(_vm._s(_vm.item.parentAddressPostcode))
                            ])
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.item.parentAddressPostcode,
                                  expression: "item.parentAddressPostcode"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: {
                                value: _vm.item.parentAddressPostcode
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.item,
                                    "parentAddressPostcode",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Country")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "validator" },
                    [
                      _vm.editMode
                        ? _c("dyn-select", {
                            attrs: { options: _vm.validCountries },
                            model: {
                              value: _vm.item.companyAddressCountry,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "companyAddressCountry", $$v)
                              },
                              expression: "item.companyAddressCountry"
                            }
                          })
                        : _c("span", [
                            _vm._v(_vm._s(_vm.item.companyAddressCountry))
                          ])
                    ],
                    1
                  )
                ])
              ]),
              _vm._v(" "),
              _c("ul", { staticClass: "appview-list" }, [
                _c("li", [
                  _c("label", [_vm._v("Company Email")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [_vm._v(_vm._s(_vm.item.companyEmail))])
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.companyEmail,
                              expression: "item.companyEmail"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: _vm.item.companyEmail },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.item,
                                "companyEmail",
                                $event.target.value
                              )
                            }
                          }
                        })
                  ]),
                  _vm._v(" "),
                  false
                    ? _c("div", { staticClass: "validator" }, [
                        !_vm.editMode
                          ? _c("span", [_vm._v(_vm._s(_vm.item.parentEmail))])
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.item.parentEmail,
                                  expression: "item.parentEmail"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: _vm.item.parentEmail },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.item,
                                    "parentEmail",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Phone")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [_vm._v(_vm._s(_vm.item.companyPhone))])
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.companyPhone,
                              expression: "item.companyPhone"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: _vm.item.companyPhone },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.item,
                                "companyPhone",
                                $event.target.value
                              )
                            }
                          }
                        })
                  ]),
                  _vm._v(" "),
                  false
                    ? _c("div", { staticClass: "validator" }, [
                        !_vm.editMode
                          ? _c("span", [_vm._v(_vm._s(_vm.item.parentPhone))])
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.item.parentPhone,
                                  expression: "item.parentPhone"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: _vm.item.parentPhone },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.item,
                                    "parentPhone",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("WEBSITE")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [_vm._v(_vm._s(_vm.item.companyFax))])
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.companyFax,
                              expression: "item.companyFax"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: _vm.item.companyFax },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.item,
                                "companyFax",
                                $event.target.value
                              )
                            }
                          }
                        })
                  ]),
                  _vm._v(" "),
                  false
                    ? _c("div", { staticClass: "validator" }, [
                        !_vm.editMode
                          ? _c("span", [_vm._v(_vm._s(_vm.item.parentFax))])
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.item.parentFax,
                                  expression: "item.parentFax"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: _vm.item.parentFax },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.item,
                                    "parentFax",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                      ])
                    : _vm._e()
                ])
              ]),
              _vm._v(" "),
              _c("ul", { staticClass: "appview-list column6" }, [
                _c("li", [
                  _c("label", [_vm._v("Turnover")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "validator" },
                    [
                      !_vm.editMode
                        ? _c("span", [
                            _vm._v(
                              _vm._s(_vm.item.companyTurnover) +
                                " " +
                                _vm._s(
                                  _vm.currency(_vm.item.companyTurnoverCurrency)
                                )
                            )
                          ])
                        : _c("money", {
                            attrs: {
                              amount: _vm.item.companyTurnover,
                              currency: _vm.item.companyTurnoverCurrency
                            },
                            on: {
                              "update:amount": function($event) {
                                return _vm.$set(
                                  _vm.item,
                                  "companyTurnover",
                                  $event
                                )
                              },
                              "update:currency": function($event) {
                                return _vm.$set(
                                  _vm.item,
                                  "companyTurnoverCurrency",
                                  $event
                                )
                              }
                            }
                          })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  false
                    ? _c(
                        "div",
                        { staticClass: "validator" },
                        [
                          !_vm.editMode
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(_vm.item.parentTurnover) +
                                    " " +
                                    _vm._s(
                                      _vm.currency(
                                        _vm.item.parentTurnoverCurrency
                                      )
                                    )
                                )
                              ])
                            : _c("money", {
                                attrs: {
                                  amount: _vm.item.parentTurnover,
                                  currency: _vm.item.parentTurnoverCurrency
                                },
                                on: {
                                  "update:amount": function($event) {
                                    return _vm.$set(
                                      _vm.item,
                                      "parentTurnover",
                                      $event
                                    )
                                  },
                                  "update:currency": function($event) {
                                    return _vm.$set(
                                      _vm.item,
                                      "parentTurnoverCurrency",
                                      $event
                                    )
                                  }
                                }
                              })
                        ],
                        1
                      )
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Is public")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "validator" },
                    [
                      _c("mnb-switch", {
                        attrs: { disabled: !_vm.editMode },
                        model: {
                          value: _vm.item.companyIsPublic,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "companyIsPublic", $$v)
                          },
                          expression: "item.companyIsPublic"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Is non-profit")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "validator" },
                    [
                      _c("mnb-switch", {
                        attrs: { disabled: !_vm.editMode },
                        model: {
                          value: _vm.item.companyIsNonProfit,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "companyIsNonProfit", $$v)
                          },
                          expression: "item.companyIsNonProfit"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Lawsuits")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "validator" },
                    [
                      _c("mnb-switch", {
                        attrs: { disabled: !_vm.editMode },
                        model: {
                          value: _vm.item.companyLawsuit,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "companyLawsuit", $$v)
                          },
                          expression: "item.companyLawsuit"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Declined for processing")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "validator" },
                    [
                      _c("mnb-switch", {
                        attrs: { disabled: !_vm.editMode },
                        model: {
                          value: _vm.item.companyDeclined,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "companyDeclined", $$v)
                          },
                          expression: "item.companyDeclined"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Terminated for processing")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "validator" },
                    [
                      _c("mnb-switch", {
                        attrs: { disabled: !_vm.editMode },
                        model: {
                          value: _vm.item.companyTerminated,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "companyTerminated", $$v)
                          },
                          expression: "item.companyTerminated"
                        }
                      })
                    ],
                    1
                  )
                ])
              ]),
              _vm._v(" "),
              _vm.item.hasParent == 1
                ? _c("ul", { staticClass: "appview-list" }, [
                    _vm._m(1),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Registration number")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "validator" }, [
                        !_vm.editMode
                          ? _c("span", [_vm._v(_vm._s(_vm.item.parentRegnum))])
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.item.parentRegnum,
                                  expression: "item.parentRegnum"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: _vm.item.parentRegnum },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.item,
                                    "parentRegnum",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Legal name")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "validator" }, [
                        !_vm.editMode
                          ? _c("span", [_vm._v(_vm._s(_vm.item.parentName))])
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.item.parentName,
                                  expression: "item.parentName"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: _vm.item.parentName },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.item,
                                    "parentName",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Trade name")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "validator" }, [
                        !_vm.editMode
                          ? _c("span", [
                              _vm._v(_vm._s(_vm.item.parentNameTrade))
                            ])
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.item.parentNameTrade,
                                  expression: "item.parentNameTrade"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: _vm.item.parentNameTrade },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.item,
                                    "parentNameTrade",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Type")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "validator" }, [
                        !_vm.editMode
                          ? _c("span", [_vm._v(_vm._s(_vm.item.parentType))])
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.item.parentType,
                                  expression: "item.parentType"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: _vm.item.parentType },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.item,
                                    "parentType",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Street")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "validator" }, [
                        !_vm.editMode
                          ? _c("span", [_vm._v(_vm._s(_vm.item.parentAddress))])
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.item.parentAddress,
                                  expression: "item.parentAddress"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: _vm.item.parentAddress },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.item,
                                    "parentAddress",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Number")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "validator" }, [
                        !_vm.editMode
                          ? _c("span", [
                              _vm._v(_vm._s(_vm.item.parentAddressNum))
                            ])
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.item.parentAddressNum,
                                  expression: "item.parentAddressNum"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: _vm.item.parentAddressNum },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.item,
                                    "parentAddressNum",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Postcode")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "validator" }, [
                        !_vm.editMode
                          ? _c("span", [
                              _vm._v(_vm._s(_vm.item.parentAddressPostcode))
                            ])
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.item.parentAddressPostcode,
                                  expression: "item.parentAddressPostcode"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: {
                                value: _vm.item.parentAddressPostcode
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.item,
                                    "parentAddressPostcode",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("City")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "validator" }, [
                        !_vm.editMode
                          ? _c("span", [
                              _vm._v(_vm._s(_vm.item.parentAddressCity))
                            ])
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.item.parentAddressCity,
                                  expression: "item.parentAddressCity"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: _vm.item.parentAddressCity },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.item,
                                    "parentAddressCity",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("State")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "validator" }, [
                        !_vm.editMode
                          ? _c("span", [
                              _vm._v(_vm._s(_vm.item.parentAddressState))
                            ])
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.item.parentAddressState,
                                  expression: "item.parentAddressState"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: _vm.item.parentAddressState },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.item,
                                    "parentAddressState",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Country")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "validator" }, [
                        !_vm.editMode
                          ? _c("span", [
                              _vm._v(_vm._s(_vm.item.parentAddressCountry))
                            ])
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.item.parentAddressCountry,
                                  expression: "item.parentAddressCountry"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: {
                                value: _vm.item.parentAddressCountry
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.item,
                                    "parentAddressCountry",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Phone")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "validator" }, [
                        !_vm.editMode
                          ? _c("span", [_vm._v(_vm._s(_vm.item.parentPhone))])
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.item.parentPhone,
                                  expression: "item.parentPhone"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: _vm.item.parentPhone },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.item,
                                    "parentPhone",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Fax")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "validator" }, [
                        !_vm.editMode
                          ? _c("span", [_vm._v(_vm._s(_vm.item.parentFax))])
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.item.parentFax,
                                  expression: "item.parentFax"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: _vm.item.parentFax },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.item,
                                    "parentFax",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Company Email")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "validator" }, [
                        !_vm.editMode
                          ? _c("span", [_vm._v(_vm._s(_vm.item.parentEmail))])
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.item.parentEmail,
                                  expression: "item.parentEmail"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: _vm.item.parentEmail },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.item,
                                    "parentEmail",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Turnover")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "validator" },
                        [
                          !_vm.editMode
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(_vm.item.parentTurnover) +
                                    " " +
                                    _vm._s(
                                      _vm.currency(
                                        _vm.item.parentTurnoverCurrency
                                      )
                                    )
                                )
                              ])
                            : _c("money", {
                                attrs: {
                                  amount: _vm.item.parentTurnover,
                                  currency: _vm.item.parentTurnoverCurrency
                                },
                                on: {
                                  "update:amount": function($event) {
                                    return _vm.$set(
                                      _vm.item,
                                      "parentTurnover",
                                      $event
                                    )
                                  },
                                  "update:currency": function($event) {
                                    return _vm.$set(
                                      _vm.item,
                                      "parentTurnoverCurrency",
                                      $event
                                    )
                                  }
                                }
                              })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Incorporation date")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "validator" },
                        [
                          !_vm.editMode
                            ? _c("span", [_vm._v(_vm._s(_vm.item.parentDate))])
                            : _c("datepicker", {
                                model: {
                                  value: _vm.item.parentDate,
                                  callback: function($$v) {
                                    _vm.$set(_vm.item, "parentDate", $$v)
                                  },
                                  expression: "item.parentDate"
                                }
                              })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Tax ID")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "validator" }, [
                        !_vm.editMode
                          ? _c("span", [_vm._v(_vm._s(_vm.item.parentTaxId))])
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.item.parentTaxId,
                                  expression: "item.parentTaxId"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: _vm.item.parentTaxId },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.item,
                                    "parentTaxId",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Is public")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "validator" },
                        [
                          !_vm.editMode
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.item.parentIsPublic == 1 ? "Yes" : "No"
                                  )
                                )
                              ])
                            : _c("formel-toggle", {
                                model: {
                                  value: _vm.item.parentIsPublic,
                                  callback: function($$v) {
                                    _vm.$set(_vm.item, "parentIsPublic", $$v)
                                  },
                                  expression: "item.parentIsPublic"
                                }
                              })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Is non-profit")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "validator" },
                        [
                          !_vm.editMode
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.item.parentIsNonProfit == 1
                                      ? "Yes"
                                      : "No"
                                  )
                                )
                              ])
                            : _c("formel-toggle", {
                                model: {
                                  value: _vm.item.parentIsNonProfit,
                                  callback: function($$v) {
                                    _vm.$set(_vm.item, "parentIsNonProfit", $$v)
                                  },
                                  expression: "item.parentIsNonProfit"
                                }
                              })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Lawsuits")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "validator" },
                        [
                          !_vm.editMode
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.item.parentLawsuit == 1 ? "Yes" : "No"
                                  )
                                )
                              ])
                            : _c("formel-toggle", {
                                model: {
                                  value: _vm.item.parentLawsuit,
                                  callback: function($$v) {
                                    _vm.$set(_vm.item, "parentLawsuit", $$v)
                                  },
                                  expression: "item.parentLawsuit"
                                }
                              })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Declined for processing")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "validator" },
                        [
                          !_vm.editMode
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.item.parentDeclined == 1 ? "Yes" : "No"
                                  )
                                )
                              ])
                            : _c("formel-toggle", {
                                model: {
                                  value: _vm.item.parentDeclined,
                                  callback: function($$v) {
                                    _vm.$set(_vm.item, "parentDeclined", $$v)
                                  },
                                  expression: "item.parentDeclined"
                                }
                              })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Terminated for processing")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "validator" },
                        [
                          !_vm.editMode
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.item.parentTerminated == 1
                                      ? "Yes"
                                      : "No"
                                  )
                                )
                              ])
                            : _c("formel-toggle", {
                                model: {
                                  value: _vm.item.parentTerminated,
                                  callback: function($$v) {
                                    _vm.$set(_vm.item, "parentTerminated", $$v)
                                  },
                                  expression: "item.parentTerminated"
                                }
                              })
                        ],
                        1
                      )
                    ])
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _vm.editMode
              ? _c("div", { staticClass: "form-actions" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-large",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.saveItem.apply(null, arguments)
                        }
                      }
                    },
                    [_vm._v("Save")]
                  )
                ])
              : _vm._e()
          ])
        : _vm.tab == 2
        ? _c("form", { staticClass: "preq-form" }, [
            _c(
              "section",
              [
                _vm._l(_vm.item.directors, function(ubo, ui) {
                  return _c("ul", { staticClass: "appview-list" }, [
                    _c("li", { staticClass: "title" }, [
                      _vm._v("Director #" + _vm._s(ui * 1 + 1) + " "),
                      _vm.editMode
                        ? _c(
                            "a",
                            {
                              staticClass: "remove",
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.item.directors.splice(ui, 1)
                                }
                              }
                            },
                            [_vm._v("×")]
                          )
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("First Name")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "validator" }, [
                        !_vm.editMode
                          ? _c("span", [_vm._v(_vm._s(ubo.firstName))])
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: ubo.firstName,
                                  expression: "ubo.firstName"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: ubo.firstName },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    ubo,
                                    "firstName",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Last Name")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "validator" }, [
                        !_vm.editMode
                          ? _c("span", [_vm._v(_vm._s(ubo.lastName))])
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: ubo.lastName,
                                  expression: "ubo.lastName"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: ubo.lastName },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(ubo, "lastName", $event.target.value)
                                }
                              }
                            })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("DOB")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "validator" },
                        [
                          !_vm.editMode
                            ? _c("span", [_vm._v(_vm._s(ubo.dob))])
                            : _c("datepicker", {
                                model: {
                                  value: ubo.dob,
                                  callback: function($$v) {
                                    _vm.$set(ubo, "dob", $$v)
                                  },
                                  expression: "ubo.dob"
                                }
                              })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("SIN/SSN")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "validator" }, [
                        !_vm.editMode
                          ? _c("span", [_vm._v(_vm._s(ubo.ssn))])
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: ubo.ssn,
                                  expression: "ubo.ssn"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: ubo.ssn },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(ubo, "ssn", $event.target.value)
                                }
                              }
                            })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("ID NUMBER")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "validator" }, [
                        !_vm.editMode
                          ? _c("span", [_vm._v(_vm._s(ubo.passportNum))])
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: ubo.passportNum,
                                  expression: "ubo.passportNum"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: ubo.passportNum },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    ubo,
                                    "passportNum",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("ADDRESS")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "validator" }, [
                        !_vm.editMode
                          ? _c("span", [_vm._v(_vm._s(ubo.address))])
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: ubo.address,
                                  expression: "ubo.address"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: ubo.address },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(ubo, "address", $event.target.value)
                                }
                              }
                            })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("City")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "validator" }, [
                        !_vm.editMode
                          ? _c("span", [_vm._v(_vm._s(ubo.addressCity))])
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: ubo.addressCity,
                                  expression: "ubo.addressCity"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: ubo.addressCity },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    ubo,
                                    "addressCity",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("State/Province")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "validator" },
                        [
                          _vm.editMode
                            ? [
                                ubo.addressCountry == "CA" ||
                                ubo.addressCountry == "US"
                                  ? _c("province-select", {
                                      attrs: {
                                        value: ubo.addressState,
                                        country: ubo.addressCountry
                                      },
                                      model: {
                                        value: ubo.addressState,
                                        callback: function($$v) {
                                          _vm.$set(ubo, "addressState", $$v)
                                        },
                                        expression: "ubo.addressState"
                                      }
                                    })
                                  : _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: ubo.addressState,
                                          expression: "ubo.addressState"
                                        }
                                      ],
                                      attrs: { type: "text" },
                                      domProps: { value: ubo.addressState },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            ubo,
                                            "addressState",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                              ]
                            : _c("span", [_vm._v(_vm._s(ubo.addressState))])
                        ],
                        2
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("POSTAL/ZIP CODE")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "validator" }, [
                        !_vm.editMode
                          ? _c("span", [_vm._v(_vm._s(ubo.addressPostcode))])
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: ubo.addressPostcode,
                                  expression: "ubo.addressPostcode"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: ubo.addressPostcode },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    ubo,
                                    "addressPostcode",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Country")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "validator" },
                        [
                          _vm.editMode
                            ? _c("dyn-select", {
                                attrs: { options: _vm.validContactCountries },
                                model: {
                                  value: ubo.addressCountry,
                                  callback: function($$v) {
                                    _vm.$set(ubo, "addressCountry", $$v)
                                  },
                                  expression: "ubo.addressCountry"
                                }
                              })
                            : _c("span", [_vm._v(_vm._s(ubo.addressCountry))])
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Phone")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "validator" }, [
                        !_vm.editMode
                          ? _c("span", [_vm._v(_vm._s(ubo.phone))])
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: ubo.phone,
                                  expression: "ubo.phone"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: ubo.phone },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(ubo, "phone", $event.target.value)
                                }
                              }
                            })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Email")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "validator" }, [
                        !_vm.editMode
                          ? _c("span", [_vm._v(_vm._s(ubo.email))])
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: ubo.email,
                                  expression: "ubo.email"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: ubo.email },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(ubo, "email", $event.target.value)
                                }
                              }
                            })
                      ])
                    ])
                  ])
                }),
                _vm._v(" "),
                _vm.editMode && _vm.item.directors.length < 5
                  ? _c(
                      "a",
                      {
                        staticClass: "new",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            _vm.item.directors.length < 5 &&
                              _vm.item.directors.push({})
                          }
                        }
                      },
                      [_vm._v("+")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm._l(_vm.item.ubos, function(ubo, ui) {
                  return _c("ul", { staticClass: "appview-list" }, [
                    _c("li", { staticClass: "title" }, [
                      _vm._v("UBO #" + _vm._s(ui * 1 + 1) + " "),
                      _vm.editMode
                        ? _c(
                            "a",
                            {
                              staticClass: "remove",
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.item.directors.splice(ui, 1)
                                }
                              }
                            },
                            [_vm._v("×")]
                          )
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("First Name")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "validator" }, [
                        !_vm.editMode
                          ? _c("span", [_vm._v(_vm._s(ubo.firstName))])
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: ubo.firstName,
                                  expression: "ubo.firstName"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: ubo.firstName },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    ubo,
                                    "firstName",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Last Name")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "validator" }, [
                        !_vm.editMode
                          ? _c("span", [_vm._v(_vm._s(ubo.lastName))])
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: ubo.lastName,
                                  expression: "ubo.lastName"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: ubo.lastName },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(ubo, "lastName", $event.target.value)
                                }
                              }
                            })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("DOB")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "validator" },
                        [
                          !_vm.editMode
                            ? _c("span", [_vm._v(_vm._s(ubo.dob))])
                            : _c("datepicker", {
                                model: {
                                  value: ubo.dob,
                                  callback: function($$v) {
                                    _vm.$set(ubo, "dob", $$v)
                                  },
                                  expression: "ubo.dob"
                                }
                              })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("SIN/SSN")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "validator" }, [
                        !_vm.editMode
                          ? _c("span", [_vm._v(_vm._s(ubo.ssn))])
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: ubo.ssn,
                                  expression: "ubo.ssn"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: ubo.ssn },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(ubo, "ssn", $event.target.value)
                                }
                              }
                            })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("ID NUMBER")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "validator" }, [
                        !_vm.editMode
                          ? _c("span", [_vm._v(_vm._s(ubo.passportNum))])
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: ubo.passportNum,
                                  expression: "ubo.passportNum"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: ubo.passportNum },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    ubo,
                                    "passportNum",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("ADDRESS")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "validator" }, [
                        !_vm.editMode
                          ? _c("span", [_vm._v(_vm._s(ubo.address))])
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: ubo.address,
                                  expression: "ubo.address"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: ubo.address },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(ubo, "address", $event.target.value)
                                }
                              }
                            })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("City")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "validator" }, [
                        !_vm.editMode
                          ? _c("span", [_vm._v(_vm._s(ubo.addressCity))])
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: ubo.addressCity,
                                  expression: "ubo.addressCity"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: ubo.addressCity },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    ubo,
                                    "addressCity",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("State/Province")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "validator" },
                        [
                          _vm.editMode
                            ? [
                                ubo.addressCountry == "CA" ||
                                ubo.addressCountry == "US"
                                  ? _c("province-select", {
                                      attrs: {
                                        value: ubo.addressState,
                                        country: ubo.addressCountry
                                      },
                                      model: {
                                        value: ubo.addressState,
                                        callback: function($$v) {
                                          _vm.$set(ubo, "addressState", $$v)
                                        },
                                        expression: "ubo.addressState"
                                      }
                                    })
                                  : _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: ubo.addressState,
                                          expression: "ubo.addressState"
                                        }
                                      ],
                                      attrs: { type: "text" },
                                      domProps: { value: ubo.addressState },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            ubo,
                                            "addressState",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                              ]
                            : _c("span", [_vm._v(_vm._s(ubo.addressState))])
                        ],
                        2
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("POSTAL/ZIP CODE")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "validator" }, [
                        !_vm.editMode
                          ? _c("span", [_vm._v(_vm._s(ubo.addressPostcode))])
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: ubo.addressPostcode,
                                  expression: "ubo.addressPostcode"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: ubo.addressPostcode },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    ubo,
                                    "addressPostcode",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Country")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "validator" },
                        [
                          _vm.editMode
                            ? _c("dyn-select", {
                                attrs: { options: _vm.validContactCountries },
                                model: {
                                  value: ubo.addressCountry,
                                  callback: function($$v) {
                                    _vm.$set(ubo, "addressCountry", $$v)
                                  },
                                  expression: "ubo.addressCountry"
                                }
                              })
                            : _c("span", [_vm._v(_vm._s(ubo.addressCountry))])
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Phone")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "validator" }, [
                        !_vm.editMode
                          ? _c("span", [_vm._v(_vm._s(ubo.phone))])
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: ubo.phone,
                                  expression: "ubo.phone"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: ubo.phone },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(ubo, "phone", $event.target.value)
                                }
                              }
                            })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Email")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "validator" }, [
                        !_vm.editMode
                          ? _c("span", [_vm._v(_vm._s(ubo.email))])
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: ubo.email,
                                  expression: "ubo.email"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: ubo.email },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(ubo, "email", $event.target.value)
                                }
                              }
                            })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Ownership % ")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "validator" }, [
                        !_vm.editMode
                          ? _c("span", [_vm._v(_vm._s(ubo.ownership))])
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: ubo.ownership,
                                  expression: "ubo.ownership"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: ubo.ownership },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    ubo,
                                    "ownership",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                      ])
                    ])
                  ])
                }),
                _vm._v(" "),
                _vm.editMode && _vm.item.ubos.length < 5
                  ? _c(
                      "a",
                      {
                        staticClass: "new",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            _vm.item.ubos.length < 5 && _vm.item.ubos.push({})
                          }
                        }
                      },
                      [_vm._v("+")]
                    )
                  : _vm._e()
              ],
              2
            ),
            _vm._v(" "),
            _vm.editMode
              ? _c("div", { staticClass: "form-actions" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-large",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.saveItem.apply(null, arguments)
                        }
                      }
                    },
                    [_vm._v("Save")]
                  )
                ])
              : _vm._e()
          ])
        : _vm.tab == 3
        ? _c("form", { staticClass: "preq-form" }, [
            _c("section", [
              _c("ul", { staticClass: "column-form appview-list" }, [
                _c("li", [
                  _c("label", [_vm._v("Description of products")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [
                          _vm._v(_vm._s(_vm.item.businessDescription))
                        ])
                      : _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.businessDescription,
                              expression: "item.businessDescription"
                            }
                          ],
                          domProps: { value: _vm.item.businessDescription },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.item,
                                "businessDescription",
                                $event.target.value
                              )
                            }
                          }
                        })
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Time in business")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "validator" },
                    [
                      !_vm.editMode
                        ? _c("span", [
                            _vm._v(
                              _vm._s(Math.floor(_vm.item.businessLength / 12)) +
                                " years " +
                                _vm._s(_vm.item.businessLength % 12) +
                                " months"
                            )
                          ])
                        : _c("field-businesslength", {
                            model: {
                              value: _vm.item.businessLength,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "businessLength", $$v)
                              },
                              expression: "item.businessLength"
                            }
                          })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Website(s)")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.item.hasWebsite && !_vm.item.website
                                ? "No website"
                                : _vm.item.website
                            )
                          )
                        ])
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.website,
                              expression: "item.website"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: _vm.item.website },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.item, "website", $event.target.value)
                            }
                          }
                        })
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Login details")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [
                          _vm._v(
                            _vm._s(_vm.item.websiteUsername) +
                              " / " +
                              _vm._s(_vm.item.websitePassword)
                          )
                        ])
                      : _c("div", { staticStyle: { display: "flex" } }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.item.websiteUsername,
                                expression: "item.websiteUsername"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: { value: _vm.item.websiteUsername },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.item,
                                  "websiteUsername",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.item.websitePassword,
                                expression: "item.websitePassword"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: { value: _vm.item.websitePassword },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.item,
                                  "websitePassword",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ])
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Beta website(s)")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.item.hasWebsite
                                ? "No website"
                                : _vm.item.websiteBeta
                            )
                          )
                        ])
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.websiteBeta,
                              expression: "item.websiteBeta"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: _vm.item.websiteBeta },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.item,
                                "websiteBeta",
                                $event.target.value
                              )
                            }
                          }
                        })
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Descriptor")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [_vm._v(_vm._s(_vm.item.descriptor1))])
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.descriptor1,
                              expression: "item.descriptor1"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: _vm.item.descriptor1 },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.item,
                                "descriptor1",
                                $event.target.value
                              )
                            }
                          }
                        })
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("DBA/City")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [_vm._v(_vm._s(_vm.item.descriptor2))])
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.descriptor2,
                              expression: "item.descriptor2"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: _vm.item.descriptor2 },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.item,
                                "descriptor2",
                                $event.target.value
                              )
                            }
                          }
                        })
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Dynamic descriptors")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "validator" },
                    [
                      !_vm.editMode
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.item.dynamicDescriptors == 1 ? "Yes" : "No"
                              )
                            )
                          ])
                        : _c("formel-toggle", {
                            model: {
                              value: _vm.item.dynamicDescriptors,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "dynamicDescriptors", $$v)
                              },
                              expression: "item.dynamicDescriptors"
                            }
                          })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Bankruptcy")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "validator" },
                    [
                      !_vm.editMode
                        ? _c("span", [
                            _vm._v(
                              _vm._s(_vm.item.bankruptcy == 1 ? "Yes" : "No")
                            )
                          ])
                        : _c("formel-toggle", {
                            model: {
                              value: _vm.item.bankruptcy,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "bankruptcy", $$v)
                              },
                              expression: "item.bankruptcy"
                            }
                          })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Bankruptcy date")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "validator" },
                    [
                      !_vm.editMode
                        ? _c("span", [_vm._v(_vm._s(_vm.item.bankruptcyDate))])
                        : _c("datepicker", {
                            model: {
                              value: _vm.item.bankruptcyDate,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "bankruptcyDate", $$v)
                              },
                              expression: "item.bankruptcyDate"
                            }
                          })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Current/previous Payment Provider")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [_vm._v(_vm._s(_vm.item.lastProvider))])
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.lastProvider,
                              expression: "item.lastProvider"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: _vm.item.lastProvider },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.item,
                                "lastProvider",
                                $event.target.value
                              )
                            }
                          }
                        })
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Reason for leaving")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [_vm._v(_vm._s(_vm.item.lastReason))])
                      : _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.lastReason,
                              expression: "item.lastReason"
                            }
                          ],
                          domProps: { value: _vm.item.lastReason },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.item,
                                "lastReason",
                                $event.target.value
                              )
                            }
                          }
                        })
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Accepted cards")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "validator" },
                    [
                      !_vm.editMode
                        ? _c("span", [
                            _vm._v(
                              _vm._s(_vm.item.acceptedCards == 1 ? "Yes" : "No")
                            )
                          ])
                        : _c("formel-toggle", {
                            model: {
                              value: _vm.item.acceptedCards,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "acceptedCards", $$v)
                              },
                              expression: "item.acceptedCards"
                            }
                          })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Accepted cards")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [
                          _vm._v(
                            _vm._s(_vm.item.acceptedCardsMonths || 0) +
                              " months"
                          )
                        ])
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.acceptedCardsMonths,
                              expression: "item.acceptedCardsMonths"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: _vm.item.acceptedCardsMonths },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.item,
                                "acceptedCardsMonths",
                                $event.target.value
                              )
                            }
                          }
                        })
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Violation")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "validator" },
                    [
                      !_vm.editMode
                        ? _c("span", [
                            _vm._v(
                              _vm._s(_vm.item.violation == 1 ? "Yes" : "No")
                            )
                          ])
                        : _c("formel-toggle", {
                            model: {
                              value: _vm.item.violation,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "violation", $$v)
                              },
                              expression: "item.violation"
                            }
                          })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Violation details")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [_vm._v(_vm._s(_vm.item.violationDetails))])
                      : _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.violationDetails,
                              expression: "item.violationDetails"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: _vm.item.violationDetails },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.item,
                                "violationDetails",
                                $event.target.value
                              )
                            }
                          }
                        })
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("History currency")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "validator" },
                    [
                      !_vm.editMode
                        ? _c("span", [
                            _vm._v(
                              _vm._s(_vm.currency(_vm.item.historyCurrency))
                            )
                          ])
                        : _c("dyn-select", {
                            attrs: { options: _vm.currencies },
                            model: {
                              value: _vm.item.historyCurrency,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "historyCurrency", $$v)
                              },
                              expression: "item.historyCurrency"
                            }
                          })
                    ],
                    1
                  )
                ])
              ]),
              _vm._v(" "),
              _c("ul", { staticClass: "column-form ext-cform" }, [
                _vm._m(2),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Number of transactions")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "field cols" },
                    _vm._l(_vm.item.history, function(ago, i) {
                      return _c(
                        "div",
                        { key: "ccph1x" + i, staticClass: "validator" },
                        [
                          !_vm.editMode
                            ? _c("span", [_vm._v(_vm._s(ago.tsCount))])
                            : _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: ago.tsCount,
                                    expression: "ago.tsCount"
                                  }
                                ],
                                attrs: { type: "text" },
                                domProps: { value: ago.tsCount },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      ago,
                                      "tsCount",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                        ]
                      )
                    }),
                    0
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Transaction volume")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "field cols" },
                    _vm._l(_vm.item.history, function(ago, i) {
                      return _c(
                        "div",
                        { key: "ccph1x" + i, staticClass: "validator" },
                        [
                          !_vm.editMode
                            ? _c("span", [_vm._v(_vm._s(ago.tsVolume))])
                            : _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: ago.tsVolume,
                                    expression: "ago.tsVolume"
                                  }
                                ],
                                attrs: { type: "text" },
                                domProps: { value: ago.tsVolume },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      ago,
                                      "tsVolume",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                        ]
                      )
                    }),
                    0
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Number of chargebacks")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "field cols" },
                    _vm._l(_vm.item.history, function(ago, i) {
                      return _c(
                        "div",
                        { key: "ccph1x" + i, staticClass: "validator" },
                        [
                          !_vm.editMode
                            ? _c("span", [_vm._v(_vm._s(ago.cbCount))])
                            : _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: ago.cbCount,
                                    expression: "ago.cbCount"
                                  }
                                ],
                                attrs: { type: "text" },
                                domProps: { value: ago.cbCount },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      ago,
                                      "cbCount",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                        ]
                      )
                    }),
                    0
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Chargeback volume")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "field cols" },
                    _vm._l(_vm.item.history, function(ago, i) {
                      return _c(
                        "div",
                        { key: "ccph1x" + i, staticClass: "validator" },
                        [
                          !_vm.editMode
                            ? _c("span", [_vm._v(_vm._s(ago.cbVolume))])
                            : _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: ago.cbVolume,
                                    expression: "ago.cbVolume"
                                  }
                                ],
                                attrs: { type: "text" },
                                domProps: { value: ago.cbVolume },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      ago,
                                      "cbVolume",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                        ]
                      )
                    }),
                    0
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Number of refunds")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "field cols" },
                    _vm._l(_vm.item.history, function(ago, i) {
                      return _c(
                        "div",
                        { key: "ccph1x" + i, staticClass: "validator" },
                        [
                          !_vm.editMode
                            ? _c("span", [_vm._v(_vm._s(ago.refundCount))])
                            : _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: ago.refundCount,
                                    expression: "ago.refundCount"
                                  }
                                ],
                                attrs: { type: "text" },
                                domProps: { value: ago.refundCount },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      ago,
                                      "refundCount",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                        ]
                      )
                    }),
                    0
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Refund volume")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "field cols" },
                    _vm._l(_vm.item.history, function(ago, i) {
                      return _c(
                        "div",
                        { key: "ccph1x" + i, staticClass: "validator" },
                        [
                          !_vm.editMode
                            ? _c("span", [_vm._v(_vm._s(ago.refundVolume))])
                            : _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: ago.refundVolume,
                                    expression: "ago.refundVolume"
                                  }
                                ],
                                attrs: { type: "text" },
                                domProps: { value: ago.refundVolume },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      ago,
                                      "refundVolume",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                        ]
                      )
                    }),
                    0
                  )
                ])
              ]),
              _vm._v(" "),
              _c("h2", { staticClass: "list-heading" }, [_vm._v("Finance")]),
              _vm._v(" "),
              _c("ul", { staticClass: "column-form appview-list" }, [
                _c("li", [
                  _c("label", [_vm._v("Est monthly sales")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "validator" },
                    [
                      !_vm.editMode
                        ? _c("span", [
                            _vm._v(
                              _vm._s(_vm.item.volume) +
                                " " +
                                _vm._s(_vm.currency(_vm.item.volumeCurrency))
                            )
                          ])
                        : _c("money", {
                            attrs: {
                              amount: _vm.item.volume,
                              currency: _vm.item.volumeCurrency
                            },
                            on: {
                              "update:amount": function($event) {
                                return _vm.$set(_vm.item, "volume", $event)
                              },
                              "update:currency": function($event) {
                                return _vm.$set(
                                  _vm.item,
                                  "volumeCurrency",
                                  $event
                                )
                              }
                            }
                          })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Avg transaction")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "validator" },
                    [
                      !_vm.editMode
                        ? _c("span", [
                            _vm._v(
                              _vm._s(_vm.item.avgTransaction) +
                                " " +
                                _vm._s(
                                  _vm.currency(_vm.item.avgTransactionCurrency)
                                )
                            )
                          ])
                        : _c("money", {
                            attrs: {
                              amount: _vm.item.avgTransaction,
                              currency: _vm.item.avgTransactionCurrency
                            },
                            on: {
                              "update:amount": function($event) {
                                return _vm.$set(
                                  _vm.item,
                                  "avgTransaction",
                                  $event
                                )
                              },
                              "update:currency": function($event) {
                                return _vm.$set(
                                  _vm.item,
                                  "avgTransactionCurrency",
                                  $event
                                )
                              }
                            }
                          })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Max transaction")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "validator" },
                    [
                      !_vm.editMode
                        ? _c("span", [
                            _vm._v(
                              _vm._s(_vm.item.maxTransaction) +
                                " " +
                                _vm._s(
                                  _vm.currency(_vm.item.maxTransactionCurrency)
                                )
                            )
                          ])
                        : _c("money", {
                            attrs: {
                              amount: _vm.item.maxTransaction,
                              currency: _vm.item.maxTransactionCurrency
                            },
                            on: {
                              "update:amount": function($event) {
                                return _vm.$set(
                                  _vm.item,
                                  "maxTransaction",
                                  $event
                                )
                              },
                              "update:currency": function($event) {
                                return _vm.$set(
                                  _vm.item,
                                  "maxTransactionCurrency",
                                  $event
                                )
                              }
                            }
                          })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Transaction currencies")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [
                          _vm._v(_vm._s(_vm.item.transactionCurrencies))
                        ])
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.transactionCurrencies,
                              expression: "item.transactionCurrencies"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: _vm.item.transactionCurrencies },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.item,
                                "transactionCurrencies",
                                $event.target.value
                              )
                            }
                          }
                        })
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Payment Types:")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "validator" },
                    [
                      !_vm.editMode
                        ? _c("span", [
                            _vm._v(
                              _vm._s((_vm.item.cardTypes || []).join(", "))
                            )
                          ])
                        : _c("multiselect", {
                            attrs: {
                              other: _vm.item.cardTypesOther,
                              hasOther: "1",
                              options: [
                                "Visa",
                                "MasterCard",
                                "Discover",
                                "AMEX",
                                "China Union Pay",
                                "Apple Pay",
                                "Google Pay",
                                "Interac Online",
                                "ACH/EFT",
                                "Cryptocurrency"
                              ]
                            },
                            on: {
                              "update:other": function($event) {
                                return _vm.$set(
                                  _vm.item,
                                  "cardTypesOther",
                                  $event
                                )
                              }
                            },
                            model: {
                              value: _vm.item.cardTypes,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "cardTypes", $$v)
                              },
                              expression: "item.cardTypes"
                            }
                          })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Origin: Domestic")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [
                          _vm._v(_vm._s(_vm.item.originDomestic) + " %")
                        ])
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.originDomestic,
                              expression: "item.originDomestic"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: _vm.item.originDomestic },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.item,
                                "originDomestic",
                                $event.target.value
                              )
                            }
                          }
                        })
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Origin: Europe")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [
                          _vm._v(_vm._s(_vm.item.originEurope) + " %")
                        ])
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.originEurope,
                              expression: "item.originEurope"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: _vm.item.originEurope },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.item,
                                "originEurope",
                                $event.target.value
                              )
                            }
                          }
                        })
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Origin: USA")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [_vm._v(_vm._s(_vm.item.originUSA) + " %")])
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.originUSA,
                              expression: "item.originUSA"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: _vm.item.originUSA },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.item,
                                "originUSA",
                                $event.target.value
                              )
                            }
                          }
                        })
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Origin: Asia")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [_vm._v(_vm._s(_vm.item.originAsia) + " %")])
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.originAsia,
                              expression: "item.originAsia"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: _vm.item.originAsia },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.item,
                                "originAsia",
                                $event.target.value
                              )
                            }
                          }
                        })
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Origin: Rest of world")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [
                          _vm._v(_vm._s(_vm.item.originOther) + " %")
                        ])
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.originOther,
                              expression: "item.originOther"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: _vm.item.originOther },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.item,
                                "originOther",
                                $event.target.value
                              )
                            }
                          }
                        })
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Method: E-Commerce")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [
                          _vm._v(_vm._s(_vm.item.methodECommerce) + " %")
                        ])
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.methodECommerce,
                              expression: "item.methodECommerce"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: _vm.item.methodECommerce },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.item,
                                "methodECommerce",
                                $event.target.value
                              )
                            }
                          }
                        })
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Method: POS")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [_vm._v(_vm._s(_vm.item.methodPOS) + " %")])
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.methodPOS,
                              expression: "item.methodPOS"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: _vm.item.methodPOS },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.item,
                                "methodPOS",
                                $event.target.value
                              )
                            }
                          }
                        })
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Method: M-POS")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [_vm._v(_vm._s(_vm.item.methodMPOS) + " %")])
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.methodMPOS,
                              expression: "item.methodMPOS"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: _vm.item.methodMPOS },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.item,
                                "methodMPOS",
                                $event.target.value
                              )
                            }
                          }
                        })
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Method: M-Commerce")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [
                          _vm._v(_vm._s(_vm.item.methodMCommerce) + " %")
                        ])
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.methodMCommerce,
                              expression: "item.methodMCommerce"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: _vm.item.methodMCommerce },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.item,
                                "methodMCommerce",
                                $event.target.value
                              )
                            }
                          }
                        })
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Method: MOTO")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [_vm._v(_vm._s(_vm.item.methodMOTO) + " %")])
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.methodMOTO,
                              expression: "item.methodMOTO"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: _vm.item.methodMOTO },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.item,
                                "methodMOTO",
                                $event.target.value
                              )
                            }
                          }
                        })
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Method: In-App")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [_vm._v(_vm._s(_vm.item.methodApp) + " %")])
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.methodApp,
                              expression: "item.methodApp"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: _vm.item.methodApp },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.item,
                                "methodApp",
                                $event.target.value
                              )
                            }
                          }
                        })
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Payment time")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "validator" },
                    [
                      !_vm.editMode
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.item.paymentMoment.join(", ")))
                          ])
                        : _c("multiselect", {
                            attrs: {
                              other: _vm.item.paymentMomentOther,
                              hasOther: "1",
                              options: [
                                "Upon purchase",
                                "With download",
                                "On delivery"
                              ]
                            },
                            on: {
                              "update:other": function($event) {
                                return _vm.$set(
                                  _vm.item,
                                  "paymentMomentOther",
                                  $event
                                )
                              }
                            },
                            model: {
                              value: _vm.item.paymentMoment,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "paymentMoment", $$v)
                              },
                              expression: "item.paymentMoment"
                            }
                          })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Payment frequency")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "validator" },
                    [
                      !_vm.editMode
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.item.frequency.join(", ")))
                          ])
                        : _c("multiselect", {
                            attrs: {
                              hasOther: false,
                              options: [
                                "One-time payment",
                                "Recurring payment (subscription)"
                              ]
                            },
                            model: {
                              value: _vm.item.frequency,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "frequency", $$v)
                              },
                              expression: "item.frequency"
                            }
                          })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Delivery: Immediately")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [
                          _vm._v(_vm._s(_vm.item.deliveryTime1) + " %")
                        ])
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.deliveryTime1,
                              expression: "item.deliveryTime1"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: _vm.item.deliveryTime1 },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.item,
                                "deliveryTime1",
                                $event.target.value
                              )
                            }
                          }
                        })
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Delivery: <= 4 weeks")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [
                          _vm._v(_vm._s(_vm.item.deliveryTime2) + " %")
                        ])
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.deliveryTime2,
                              expression: "item.deliveryTime2"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: _vm.item.deliveryTime2 },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.item,
                                "deliveryTime2",
                                $event.target.value
                              )
                            }
                          }
                        })
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Delivery: 5-14 weeks")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [
                          _vm._v(_vm._s(_vm.item.deliveryTime3) + " %")
                        ])
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.deliveryTime3,
                              expression: "item.deliveryTime3"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: _vm.item.deliveryTime3 },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.item,
                                "deliveryTime3",
                                $event.target.value
                              )
                            }
                          }
                        })
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Delivery: > 14 weeks")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [
                          _vm._v(_vm._s(_vm.item.deliveryTime4) + " %")
                        ])
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.deliveryTime4,
                              expression: "item.deliveryTime4"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: _vm.item.deliveryTime4 },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.item,
                                "deliveryTime4",
                                $event.target.value
                              )
                            }
                          }
                        })
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Affiliate program")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "validator" },
                    [
                      !_vm.editMode
                        ? _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.item.affiliate == 1 ? "Yes" : "No")
                            )
                          ])
                        : _c("formel-toggle", {
                            model: {
                              value: _vm.item.affiliate,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "affiliate", $$v)
                              },
                              expression: "item.affiliate"
                            }
                          })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Affiliate details")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [
                          _vm._v(" " + _vm._s(_vm.item.affiliateDetails))
                        ])
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.affiliateDetails,
                              expression: "item.affiliateDetails"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: _vm.item.affiliateDetails },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.item,
                                "affiliateDetails",
                                $event.target.value
                              )
                            }
                          }
                        })
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Customer service email")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [_vm._v(_vm._s(_vm.item.emailService))])
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.emailService,
                              expression: "item.emailService"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: _vm.item.emailService },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.item,
                                "emailService",
                                $event.target.value
                              )
                            }
                          }
                        })
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Customer service phone")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [_vm._v(_vm._s(_vm.item.phoneService))])
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.phoneService,
                              expression: "item.phoneService"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: _vm.item.phoneService },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.item,
                                "phoneService",
                                $event.target.value
                              )
                            }
                          }
                        })
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Chargeback notification email")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [_vm._v(_vm._s(_vm.item.emailChargeback))])
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.emailChargeback,
                              expression: "item.emailChargeback"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: _vm.item.emailChargeback },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.item,
                                "emailChargeback",
                                $event.target.value
                              )
                            }
                          }
                        })
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("h2", { staticClass: "list-heading" }, [
                _vm._v("Security measures")
              ]),
              _vm._v(" "),
              _c("ul", { staticClass: "column-form appview-list" }, [
                _c("li", [
                  _c("label", [_vm._v("Security measures")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [_vm._v(_vm._s(_vm.item.securityMeasures))])
                      : _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.securityMeasures,
                              expression: "item.securityMeasures"
                            }
                          ],
                          domProps: { value: _vm.item.securityMeasures },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.item,
                                "securityMeasures",
                                $event.target.value
                              )
                            }
                          }
                        })
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("3D Secure")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [
                          _vm._v(
                            _vm._s(_vm.item.use3dSecure == 1 ? "Yes" : "No") +
                              " " +
                              _vm._s(
                                _vm.item.use3dSecureMPI
                                  ? "MPI: " + _vm.item.use3dSecureMPI
                                  : ""
                              )
                          )
                        ])
                      : _c(
                          "div",
                          [
                            _c("formel-toggle", {
                              model: {
                                value: _vm.item.use3dSecure,
                                callback: function($$v) {
                                  _vm.$set(_vm.item, "use3dSecure", $$v)
                                },
                                expression: "item.use3dSecure"
                              }
                            }),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.item.use3dSecureMPI,
                                  expression: "item.use3dSecureMPI"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: _vm.item.use3dSecureMPI },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.item,
                                    "use3dSecureMPI",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ],
                          1
                        )
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("CVC")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "validator" },
                    [
                      !_vm.editMode
                        ? _c("span", [
                            _vm._v(
                              " " + _vm._s(_vm.item.useCVC == 1 ? "Yes" : "No")
                            )
                          ])
                        : _c("formel-toggle", {
                            model: {
                              value: _vm.item.useCVC,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "useCVC", $$v)
                              },
                              expression: "item.useCVC"
                            }
                          })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("AVS")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "validator" },
                    [
                      !_vm.editMode
                        ? _c("span", [
                            _vm._v(
                              " " + _vm._s(_vm.item.useAVS == 1 ? "Yes" : "No")
                            )
                          ])
                        : _c("formel-toggle", {
                            model: {
                              value: _vm.item.useAVS,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "useAVS", $$v)
                              },
                              expression: "item.useAVS"
                            }
                          })
                    ],
                    1
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            _vm.editMode
              ? _c("div", { staticClass: "form-actions" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-large",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.saveItem.apply(null, arguments)
                        }
                      }
                    },
                    [_vm._v("Save")]
                  )
                ])
              : _vm._e()
          ])
        : _vm.tab == 4
        ? _c("form", { staticClass: "preq-form" }, [
            _c(
              "section",
              [
                _vm._l(_vm.item.accounts, function(account, i) {
                  return _c("div", [
                    _c("ul", { staticClass: "column-form appview-list" }, [
                      _c("li", { staticClass: "title" }, [
                        _vm._v("Account #" + _vm._s(i + 1) + " "),
                        _vm.editMode
                          ? _c(
                              "a",
                              {
                                staticClass: "remove",
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.item.accounts.splice(i, 1)
                                  }
                                }
                              },
                              [_vm._v("×")]
                            )
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("label", [_vm._v("CURRENCY")]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "validator" },
                          [
                            !_vm.editMode
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.currency(account.accountCurrency)
                                    )
                                  )
                                ])
                              : _c("dyn-select", {
                                  attrs: { options: _vm.currencies },
                                  model: {
                                    value: account.accountCurrency,
                                    callback: function($$v) {
                                      _vm.$set(account, "accountCurrency", $$v)
                                    },
                                    expression: "account.accountCurrency"
                                  }
                                })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("label", [_vm._v("ACCOUNT NAME")]),
                        _vm._v(" "),
                        _c("div", { staticClass: "validator" }, [
                          !_vm.editMode
                            ? _c("span", [
                                _vm._v(_vm._s(account.accountHolder))
                              ])
                            : _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: account.accountHolder,
                                    expression: "account.accountHolder"
                                  }
                                ],
                                attrs: { type: "text" },
                                domProps: { value: account.accountHolder },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      account,
                                      "accountHolder",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                        ])
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("label", [_vm._v("ACCOUNT NUMBER")]),
                        _vm._v(" "),
                        _c("div", { staticClass: "validator" }, [
                          !_vm.editMode
                            ? _c("span", [
                                _vm._v(_vm._s(account.accountNumber))
                              ])
                            : _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: account.accountNumber,
                                    expression: "account.accountNumber"
                                  }
                                ],
                                attrs: { type: "text" },
                                domProps: { value: account.accountNumber },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      account,
                                      "accountNumber",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                        ])
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("label", [_vm._v("Institution Number")]),
                        _vm._v(" "),
                        _c("div", { staticClass: "validator" }, [
                          !_vm.editMode
                            ? _c("span", [_vm._v(_vm._s(account.accountSwift))])
                            : _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: account.accountSwift,
                                    expression: "account.accountSwift"
                                  }
                                ],
                                attrs: { type: "text" },
                                domProps: { value: account.accountSwift },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      account,
                                      "accountSwift",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                        ])
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("label", [_vm._v("Branch/Transit Number")]),
                        _vm._v(" "),
                        _c("div", { staticClass: "validator" }, [
                          !_vm.editMode
                            ? _c("span", [_vm._v(_vm._s(account.accountIBAN))])
                            : _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: account.accountIBAN,
                                    expression: "account.accountIBAN"
                                  }
                                ],
                                attrs: { type: "text" },
                                domProps: { value: account.accountIBAN },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      account,
                                      "accountIBAN",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                        ])
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("label", [_vm._v("ROUTING NUMBER")]),
                        _vm._v(" "),
                        _c("div", { staticClass: "validator" }, [
                          !_vm.editMode
                            ? _c("span", [
                                _vm._v(_vm._s(account.accountRouting))
                              ])
                            : _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: account.accountRouting,
                                    expression: "account.accountRouting"
                                  }
                                ],
                                attrs: { type: "text" },
                                domProps: { value: account.accountRouting },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      account,
                                      "accountRouting",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                        ])
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("label", [_vm._v("Bank name")]),
                        _vm._v(" "),
                        _c("div", { staticClass: "validator" }, [
                          !_vm.editMode
                            ? _c("span", [_vm._v(_vm._s(account.bankName))])
                            : _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: account.bankName,
                                    expression: "account.bankName"
                                  }
                                ],
                                attrs: { type: "text" },
                                domProps: { value: account.bankName },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      account,
                                      "bankName",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                        ])
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("label", [_vm._v("Bank address")]),
                        _vm._v(" "),
                        _c("div", { staticClass: "validator" }, [
                          !_vm.editMode
                            ? _c("span", [_vm._v(_vm._s(account.bankAddress))])
                            : _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: account.bankAddress,
                                    expression: "account.bankAddress"
                                  }
                                ],
                                attrs: { type: "text" },
                                domProps: { value: account.bankAddress },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      account,
                                      "bankAddress",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                        ])
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("label", [_vm._v("POSTAL/ZIP CODE")]),
                        _vm._v(" "),
                        _c("div", { staticClass: "validator" }, [
                          !_vm.editMode
                            ? _c("span", [
                                _vm._v(_vm._s(account.bankAddressPostCode))
                              ])
                            : _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: account.bankAddressPostCode,
                                    expression: "account.bankAddressPostCode"
                                  }
                                ],
                                attrs: { type: "text" },
                                domProps: {
                                  value: account.bankAddressPostCode
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      account,
                                      "bankAddressPostCode",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                        ])
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("label", [_vm._v("City")]),
                        _vm._v(" "),
                        _c("div", { staticClass: "validator" }, [
                          !_vm.editMode
                            ? _c("span", [
                                _vm._v(_vm._s(account.bankAddressCity))
                              ])
                            : _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: account.bankAddressCity,
                                    expression: "account.bankAddressCity"
                                  }
                                ],
                                attrs: { type: "text" },
                                domProps: { value: account.bankAddressCity },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      account,
                                      "bankAddressCity",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                        ])
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("label", [_vm._v("PROVINCE/STATE")]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "validator" },
                          [
                            _vm.editMode
                              ? [
                                  account.bankAddressCountry == "CA" ||
                                  account.bankAddressCountry == "US"
                                    ? _c("province-select", {
                                        attrs: {
                                          value: account.bankAddressState,
                                          country: account.bankAddressCountry
                                        },
                                        model: {
                                          value: account.bankAddressState,
                                          callback: function($$v) {
                                            _vm.$set(
                                              account,
                                              "bankAddressState",
                                              $$v
                                            )
                                          },
                                          expression: "account.bankAddressState"
                                        }
                                      })
                                    : _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: account.bankAddressState,
                                            expression:
                                              "account.bankAddressState"
                                          }
                                        ],
                                        attrs: { type: "text" },
                                        domProps: {
                                          value: account.bankAddressState
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              account,
                                              "bankAddressState",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                ]
                              : _c("span", [
                                  _vm._v(_vm._s(account.bankAddressState))
                                ])
                          ],
                          2
                        )
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("label", [_vm._v("Country")]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "validator" },
                          [
                            _vm.editMode
                              ? _c("dyn-select", {
                                  attrs: { options: _vm.validCountries },
                                  model: {
                                    value: account.bankAddressCountry,
                                    callback: function($$v) {
                                      _vm.$set(
                                        account,
                                        "bankAddressCountry",
                                        $$v
                                      )
                                    },
                                    expression: "account.bankAddressCountry"
                                  }
                                })
                              : _c("span", [
                                  _vm._v(_vm._s(account.bankAddressCountry))
                                ])
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("label", [_vm._v("Bank telephone number")]),
                        _vm._v(" "),
                        _c("div", { staticClass: "validator" }, [
                          !_vm.editMode
                            ? _c("span", [_vm._v(_vm._s(account.bankPhone))])
                            : _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: account.bankPhone,
                                    expression: "account.bankPhone"
                                  }
                                ],
                                attrs: { type: "text" },
                                domProps: { value: account.bankPhone },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      account,
                                      "bankPhone",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                        ])
                      ])
                    ])
                  ])
                }),
                _vm._v(" "),
                _vm.editMode && _vm.item.accounts.length < 5
                  ? _c(
                      "a",
                      {
                        staticClass: "new",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            _vm.item.accounts.length < 5 &&
                              _vm.item.accounts.push({})
                          }
                        }
                      },
                      [_vm._v("+")]
                    )
                  : _vm._e()
              ],
              2
            ),
            _vm._v(" "),
            _vm.editMode
              ? _c("div", { staticClass: "form-actions" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-large",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.saveItem.apply(null, arguments)
                        }
                      }
                    },
                    [_vm._v("Save")]
                  )
                ])
              : _vm._e()
          ])
        : _vm.tab == 5
        ? _c("form", { staticClass: "preq-form" }, [
            _c(
              "section",
              [
                _vm._l(_vm.typesWithContact, function(xtype) {
                  return [
                    _c("h2", {
                      key: "chead" + xtype.title,
                      staticClass: "list-heading",
                      class: { emph: xtype.emph },
                      domProps: { innerHTML: _vm._s(xtype.title) }
                    }),
                    _vm._v(" "),
                    _c(
                      "ul",
                      {
                        key: "clist" + xtype.title,
                        staticClass: "column-form appview-list"
                      },
                      [
                        _c("li", [
                          _c("label", [_vm._v("First name")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "validator" }, [
                            !_vm.editMode
                              ? _c("span", [
                                  _vm._v(_vm._s(xtype.contact.firstName))
                                ])
                              : _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: xtype.contact.firstName,
                                      expression: "xtype.contact.firstName"
                                    }
                                  ],
                                  attrs: { type: "text" },
                                  domProps: { value: xtype.contact.firstName },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        xtype.contact,
                                        "firstName",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                          ])
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c("label", [_vm._v("Last name")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "validator" }, [
                            !_vm.editMode
                              ? _c("span", [
                                  _vm._v(_vm._s(xtype.contact.lastName))
                                ])
                              : _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: xtype.contact.lastName,
                                      expression: "xtype.contact.lastName"
                                    }
                                  ],
                                  attrs: { type: "text" },
                                  domProps: { value: xtype.contact.lastName },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        xtype.contact,
                                        "lastName",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                          ])
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c("label", [_vm._v("E-mail address")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "validator" }, [
                            !_vm.editMode
                              ? _c("span", [
                                  _vm._v(_vm._s(xtype.contact.email))
                                ])
                              : _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: xtype.contact.email,
                                      expression: "xtype.contact.email"
                                    }
                                  ],
                                  attrs: { type: "text" },
                                  domProps: { value: xtype.contact.email },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        xtype.contact,
                                        "email",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                          ])
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c("label", [_vm._v("Telephone number")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "validator" }, [
                            !_vm.editMode
                              ? _c("span", [
                                  _vm._v(_vm._s(xtype.contact.phone))
                                ])
                              : _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: xtype.contact.phone,
                                      expression: "xtype.contact.phone"
                                    }
                                  ],
                                  attrs: { type: "text" },
                                  domProps: { value: xtype.contact.phone },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        xtype.contact,
                                        "phone",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                          ])
                        ]),
                        _vm._v(" "),
                        xtype.emph
                          ? _c("li", [
                              _c("label", [_vm._v("Fax number")]),
                              _vm._v(" "),
                              _c("div", { staticClass: "validator" }, [
                                !_vm.editMode
                                  ? _c("span", [
                                      _vm._v(_vm._s(xtype.contact.fax))
                                    ])
                                  : _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: xtype.contact.fax,
                                          expression: "xtype.contact.fax"
                                        }
                                      ],
                                      attrs: { type: "text" },
                                      domProps: { value: xtype.contact.fax },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            xtype.contact,
                                            "fax",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                              ])
                            ])
                          : _vm._e()
                      ]
                    )
                  ]
                })
              ],
              2
            ),
            _vm._v(" "),
            _vm.editMode
              ? _c("div", { staticClass: "form-actions" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-large",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.saveItem.apply(null, arguments)
                        }
                      }
                    },
                    [_vm._v("Save")]
                  )
                ])
              : _vm._e()
          ])
        : _vm.tab == 6
        ? _c(
            "form",
            { staticClass: "preq-form" },
            [
              _c("section", [
                _c("div", { staticClass: "appl-notes" }, [
                  _c("h4", [_vm._v("Application Notes")]),
                  _vm._v(" "),
                  !_vm.editMode
                    ? _c("div", {
                        staticClass: "note-text",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.multilineText(_vm.item.additionalComments)
                          )
                        }
                      })
                    : _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.item.additionalComments,
                            expression: "item.additionalComments"
                          }
                        ],
                        domProps: { value: _vm.item.additionalComments },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.item,
                              "additionalComments",
                              $event.target.value
                            )
                          }
                        }
                      })
                ])
              ]),
              _vm._v(" "),
              _vm.editMode
                ? _c("div", { staticClass: "form-actions" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-large",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.saveItem.apply(null, arguments)
                          }
                        }
                      },
                      [_vm._v("Save")]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.item.flyid
                ? _c("messages-api", {
                    staticClass: "appl-notes",
                    attrs: {
                      notes: _vm.messagesApi,
                      type: "Application",
                      id: _vm.item.id,
                      application: _vm.item
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("notes", {
                staticClass: "appl-notes",
                attrs: {
                  notes: _vm.notes,
                  type: "Application",
                  id: _vm.item.id
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.tab == 7
        ? _c("form", { staticClass: "preq-form preq-view" }, [
            _c("section", [
              _c("ul", { staticClass: "appview-list" }, [
                _c("li", [
                  _c("label", [_vm._v("Account Class")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "validator" },
                    [
                      _vm.editMode
                        ? _c("dyn-select", {
                            attrs: { options: _vm.classesToShow },
                            model: {
                              value: _vm.item.classId,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "classId", $$v)
                              },
                              expression: "item.classId"
                            }
                          })
                        : _c("span", [
                            _vm._v(
                              _vm._s(_vm.item.class && _vm.item.class.name)
                            )
                          ])
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Industry")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "validator" },
                    [
                      _vm.editMode
                        ? _c("dyn-select", {
                            attrs: { options: _vm.industries },
                            model: {
                              value: _vm.item.industry,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "industry", $$v)
                              },
                              expression: "item.industry"
                            }
                          })
                        : _c("span", [_vm._v(_vm._s(_vm.item.industry))])
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("MCC Code")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "validator" },
                    [
                      !_vm.editMode
                        ? _c("span", [_vm._v(_vm._s(_vm.item.mcc))])
                        : _c("mcc-edit", {
                            model: {
                              value: _vm.item.mcc,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "mcc", $$v)
                              },
                              expression: "item.mcc"
                            }
                          })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Merchant Type")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "validator" },
                    [
                      _vm.editMode
                        ? _c("dyn-select", {
                            attrs: { options: _vm.merchantTypes },
                            model: {
                              value: _vm.item.merchantType,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "merchantType", $$v)
                              },
                              expression: "item.merchantType"
                            }
                          })
                        : _c("span", [_vm._v(_vm._s(_vm.item.merchantType))])
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Batch Close Time")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [_vm._v("a")])
                      : _c("input", { attrs: { type: "text" } })
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Security PIN")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [_vm._v("a")])
                      : _c("input", { attrs: { type: "text" } })
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Cash Advance (Select Company)")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [_vm._v("a")])
                      : _c("input", { attrs: { type: "text" } })
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Platform")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [_vm._v("a")])
                      : _c("input", { attrs: { type: "text" } })
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Platform Login/Username")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [_vm._v("a")])
                      : _c("input", { attrs: { type: "text" } })
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("TID & KEY Assign & Generate")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [_vm._v("a")])
                      : _c("input", { attrs: { type: "text" } })
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Alerts Email")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [_vm._v("a")])
                      : _c("input", { attrs: { type: "text" } })
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("CB Notification Email")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [_vm._v("a")])
                      : _c("input", { attrs: { type: "text" } })
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("CS Email")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [_vm._v(_vm._s(_vm.item.emailService))])
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.emailService,
                              expression: "item.emailService"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: _vm.item.emailService },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.item,
                                "emailService",
                                $event.target.value
                              )
                            }
                          }
                        })
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("CS Number")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [_vm._v(_vm._s(_vm.item.phoneService))])
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.phoneService,
                              expression: "item.phoneService"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: _vm.item.phoneService },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.item,
                                "phoneService",
                                $event.target.value
                              )
                            }
                          }
                        })
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Descriptor")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [_vm._v(_vm._s(_vm.item.descriptor1))])
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.descriptor1,
                              expression: "item.descriptor1"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: _vm.item.descriptor1 },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.item,
                                "descriptor1",
                                $event.target.value
                              )
                            }
                          }
                        })
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Website")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [_vm._v(_vm._s(_vm.item.website))])
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.website,
                              expression: "item.website"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: _vm.item.website },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.item, "website", $event.target.value)
                            }
                          }
                        })
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _vm.editMode
              ? _c("div", { staticClass: "form-actions" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-large",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.saveItem.apply(null, arguments)
                        }
                      }
                    },
                    [_vm._v("Save")]
                  )
                ])
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.tab == 8
        ? _c("form", { staticClass: "preq-form preq-view" }, [
            _c("section", [
              _c("ul", { staticClass: "appview-list" }, [
                _c("li", [
                  _c("label", [_vm._v("Payment Methods & Types")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.payTypes["CardBiller"]
                                .filter(function(a) {
                                  return (
                                    (_vm.merchant.cardTypes || []).indexOf(a) !=
                                    -1
                                  )
                                })
                                .join(", ")
                            )
                          )
                        ])
                      : _c("input", { attrs: { type: "text" } })
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Advanced Payment Methods & Types")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.payTypes["CardBiller Plus"]
                                .filter(function(a) {
                                  return (
                                    (_vm.merchant.cardTypes || []).indexOf(a) !=
                                    -1
                                  )
                                })
                                .join(", ")
                            )
                          )
                        ])
                      : _c("input", { attrs: { type: "text" } })
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Bank Clearing & Types")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.payTypes["BankBiller"]
                                .filter(function(a) {
                                  return (
                                    (_vm.merchant.cardTypes || []).indexOf(a) !=
                                    -1
                                  )
                                })
                                .join(", ")
                            )
                          )
                        ])
                      : _c("input", { attrs: { type: "text" } })
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("CoinBiller & Types")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.payTypes["CoinBiller"]
                                .filter(function(a) {
                                  return (
                                    (_vm.merchant.cardTypes || []).indexOf(a) !=
                                    -1
                                  )
                                })
                                .join(", ")
                            )
                          )
                        ])
                      : _c("input", { attrs: { type: "text" } })
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _vm.editMode
              ? _c("div", { staticClass: "form-actions" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-large",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.saveItem.apply(null, arguments)
                        }
                      }
                    },
                    [_vm._v("Save")]
                  )
                ])
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.tab == 9
        ? _c("form", { staticClass: "preq-form preq-view" }, [
            _c("section", [
              _c("ul", { staticClass: "appview-list" }, [
                _c("li", [
                  _c("label", [_vm._v("InvoBiller")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [
                          _vm._v(_vm._s(_vm.lastAgreement.invoBiller))
                        ])
                      : _c("input", { attrs: { type: "text" } })
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _vm.editMode
              ? _c("div", { staticClass: "form-actions" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-large",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.saveItem.apply(null, arguments)
                        }
                      }
                    },
                    [_vm._v("Save")]
                  )
                ])
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.tab == 10
        ? _c("form", { staticClass: "preq-form preq-view" }, [
            _c("section", [
              _c(
                "ul",
                { staticClass: "appview-list" },
                [
                  _vm._l(_vm.merchantOptions, function(option) {
                    return _c("li", [
                      _c("label", [_vm._v(_vm._s(option.name))]),
                      _vm._v(" "),
                      _c("div", { staticClass: "validator" }, [
                        !_vm.editMode
                          ? _c("span", [
                              _vm._v(_vm._s(option.count ? "Yes" : "No"))
                            ])
                          : _c("input", { attrs: { type: "checkbox" } })
                      ])
                    ])
                  }),
                  _vm._v(" "),
                  _c("li", [
                    _c("label", [
                      _vm._v(
                        "Chammp KEY # Included and can be changed and can generate new"
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "validator" }, [
                      !_vm.editMode
                        ? _c("span", [_vm._v("a")])
                        : _c("input", { attrs: { type: "text" } })
                    ])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("label", [
                      _vm._v(
                        "Chammp TID # Included and can be changed and can generate new"
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "validator" }, [
                      !_vm.editMode
                        ? _c("span", [_vm._v("a")])
                        : _c("input", { attrs: { type: "text" } })
                    ])
                  ])
                ],
                2
              )
            ]),
            _vm._v(" "),
            _vm.editMode
              ? _c("div", { staticClass: "form-actions" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-large",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.saveItem.apply(null, arguments)
                        }
                      }
                    },
                    [_vm._v("Save")]
                  )
                ])
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.tab == 11
        ? _c(
            "form",
            { staticClass: "appf pfx" },
            [
              _vm._l([_vm.intake.formData], function(q) {
                return _vm.intake
                  ? _c("section", { staticClass: "form-step" }, [
                      _c("h2", [_vm._v("EQUIPMENT")]),
                      _vm._v(" "),
                      _c(
                        "ul",
                        { staticClass: "column-form" },
                        [
                          false
                            ? _c("li", { staticClass: "fwidth fwx" }, [
                                _c("label", [_vm._v("Special Offers")]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "field" },
                                  [
                                    _c(
                                      "validator",
                                      {
                                        attrs: {
                                          value:
                                            q.specialOffer &&
                                            q.specialOffer.length,
                                          reqs: ""
                                        }
                                      },
                                      [
                                        _c(
                                          "multiselect-desc",
                                          {
                                            attrs: {
                                              options: ["Free Terminal Program"]
                                            },
                                            model: {
                                              value: q.specialOffer,
                                              callback: function($$v) {
                                                _vm.$set(q, "specialOffer", $$v)
                                              },
                                              expression: "q.specialOffer"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n              Must ensure that merchant meets program criteria.\n            "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm._l(_vm.terminals, function(ti) {
                            return [
                              _c(
                                "h2",
                                { staticStyle: { "font-size": "1.2em" } },
                                [_vm._v("Terminals Type #" + _vm._s(ti))]
                              ),
                              _vm._v(" "),
                              _c("li", {}, [
                                _c("label", [_vm._v("Model")]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "field complex" },
                                  [
                                    _c(
                                      "validator",
                                      {
                                        attrs: {
                                          value: q["terminal" + ti + "Type"],
                                          reqs: ""
                                        }
                                      },
                                      [
                                        _c("dyn-select", {
                                          attrs: { options: _vm.equipmentOpt },
                                          model: {
                                            value: q["terminal" + ti + "Type"],
                                            callback: function($$v) {
                                              _vm.$set(
                                                q,
                                                "terminal" + ti + "Type",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "q['terminal'+ti+'Type']"
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("br"),
                                        _vm._v(
                                          "\n            $" +
                                            _vm._s(
                                              (
                                                _vm.equi(ti)[
                                                  q[
                                                    "terminal" +
                                                      ti +
                                                      "PurchaseOpt"
                                                  ]
                                                    ? "purchase"
                                                    : q[
                                                        "terminal" +
                                                          ti +
                                                          "RentalOpt"
                                                      ]
                                                    ? "rental"
                                                    : "reprogram"
                                                ] || 0
                                              ).toFixed(2)
                                            ) +
                                            "\n"
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]),
                              _vm._v(" "),
                              _c("li", {}, [
                                _c("label", [_vm._v("QTY")]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "field complex" },
                                  [
                                    _c(
                                      "validator",
                                      {
                                        attrs: {
                                          value: q["terminal" + ti + "Qty"],
                                          reqs: ""
                                        }
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: q["terminal" + ti + "Qty"],
                                              expression:
                                                "q['terminal'+ti+'Qty']"
                                            }
                                          ],
                                          attrs: { type: "text" },
                                          domProps: {
                                            value: q["terminal" + ti + "Qty"]
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                q,
                                                "terminal" + ti + "Qty",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]),
                              _vm._v(" "),
                              _c("li", {}, [
                                _c("label", [_vm._v("Purchase")]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "field conditional" },
                                  [
                                    _c("checkbox-plain2", {
                                      attrs: {
                                        disabled: !_vm.equi(ti).purchase
                                      },
                                      on: {
                                        input: function($event) {
                                          _vm.$set(
                                            q,
                                            "terminal" + ti + "RentalOpt",
                                            false
                                          )
                                          _vm.$set(
                                            q,
                                            "terminal" + ti + "LeasingOpt",
                                            false
                                          )
                                          _vm.$set(
                                            q,
                                            "terminal" + ti + "ReprogramOpt",
                                            false
                                          )
                                        }
                                      },
                                      model: {
                                        value:
                                          q["terminal" + ti + "PurchaseOpt"],
                                        callback: function($$v) {
                                          _vm.$set(
                                            q,
                                            "terminal" + ti + "PurchaseOpt",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "q['terminal'+ti+'PurchaseOpt']"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]),
                              _vm._v(" "),
                              _c("li", {}, [
                                _c("label", [_vm._v("Rental")]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "field conditional" },
                                  [
                                    _c("checkbox-plain2", {
                                      attrs: { disabled: !_vm.equi(ti).rental },
                                      on: {
                                        input: function($event) {
                                          _vm.$set(
                                            q,
                                            "terminal" + ti + "PurchaseOpt",
                                            false
                                          )
                                          _vm.$set(
                                            q,
                                            "terminal" + ti + "LeasingOpt",
                                            false
                                          )
                                          _vm.$set(
                                            q,
                                            "terminal" + ti + "ReprogramOpt",
                                            false
                                          )
                                        }
                                      },
                                      model: {
                                        value: q["terminal" + ti + "RentalOpt"],
                                        callback: function($$v) {
                                          _vm.$set(
                                            q,
                                            "terminal" + ti + "RentalOpt",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "q['terminal'+ti+'RentalOpt']"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]),
                              _vm._v(" "),
                              _c("li", {}, [
                                _c("label", [_vm._v("Reprogram")]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "field conditional" },
                                  [
                                    _c("checkbox-plain2", {
                                      on: {
                                        input: function($event) {
                                          _vm.$set(
                                            q,
                                            "terminal" + ti + "PurchaseOpt",
                                            false
                                          )
                                          _vm.$set(
                                            q,
                                            "terminal" + ti + "RentalOpt",
                                            false
                                          )
                                          _vm.$set(
                                            q,
                                            "terminal" + ti + "LeasingOpt",
                                            false
                                          )
                                        }
                                      },
                                      model: {
                                        value:
                                          q["terminal" + ti + "ReprogramOpt"],
                                        callback: function($$v) {
                                          _vm.$set(
                                            q,
                                            "terminal" + ti + "ReprogramOpt",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "q['terminal'+ti+'ReprogramOpt']"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]),
                              _vm._v(" "),
                              _c("li", {}, [
                                _c("label", [_vm._v("Leasing")]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "field conditional" },
                                  [
                                    _c("checkbox-plain2", {
                                      attrs: { disabled: "" },
                                      on: {
                                        input: function($event) {
                                          _vm.$set(
                                            q,
                                            "terminal" + ti + "PurchaseOpt",
                                            false
                                          )
                                          _vm.$set(
                                            q,
                                            "terminal" + ti + "RentalOpt",
                                            false
                                          )
                                          _vm.$set(
                                            q,
                                            "terminal" + ti + "ReprogramOpt",
                                            false
                                          )
                                        }
                                      },
                                      model: {
                                        value:
                                          q["terminal" + ti + "LeasingOpt"],
                                        callback: function($$v) {
                                          _vm.$set(
                                            q,
                                            "terminal" + ti + "LeasingOpt",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "q['terminal'+ti+'LeasingOpt']"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]),
                              _vm._v(" "),
                              false
                                ? _c(
                                    "li",
                                    {
                                      staticStyle: { width: "calc(12% - 5px)" }
                                    },
                                    [
                                      _c("label", [_vm._v("Merchant Own")]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "field conditional" },
                                        [
                                          _c("checkbox-plain2", {
                                            model: {
                                              value:
                                                q["terminal" + ti + "OwnOpt"],
                                              callback: function($$v) {
                                                _vm.$set(
                                                  q,
                                                  "terminal" + ti + "OwnOpt",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "q['terminal'+ti+'OwnOpt']"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("span", [_vm._v("Qty:")]),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  q["terminal" + ti + "Own"],
                                                expression:
                                                  "q['terminal'+ti+'Own']"
                                              }
                                            ],
                                            attrs: {
                                              type: "text",
                                              disabled: !q[
                                                "terminal" + ti + "OwnOpt"
                                              ]
                                            },
                                            domProps: {
                                              value: q["terminal" + ti + "Own"]
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  q,
                                                  "terminal" + ti + "Own",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              false
                                ? _c(
                                    "li",
                                    {
                                      staticStyle: { width: "calc(12% - 5px)" }
                                    },
                                    [
                                      _c("label", [_vm._v("Financing")]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "field conditional" },
                                        [
                                          _c("checkbox-plain2", {
                                            model: {
                                              value:
                                                q[
                                                  "terminal" +
                                                    ti +
                                                    "FinancingOpt"
                                                ],
                                              callback: function($$v) {
                                                _vm.$set(
                                                  q,
                                                  "terminal" +
                                                    ti +
                                                    "FinancingOpt",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "q['terminal'+ti+'FinancingOpt']"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("span", [_vm._v("Qty:")]),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  q[
                                                    "terminal" +
                                                      ti +
                                                      "Financing"
                                                  ],
                                                expression:
                                                  "q['terminal'+ti+'Financing']"
                                              }
                                            ],
                                            attrs: {
                                              type: "text",
                                              disabled: !q[
                                                "terminal" + ti + "FinancingOpt"
                                              ]
                                            },
                                            domProps: {
                                              value:
                                                q["terminal" + ti + "Financing"]
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  q,
                                                  "terminal" + ti + "Financing",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              false
                                ? _c(
                                    "li",
                                    {
                                      staticStyle: { width: "calc(12% - 5px)" }
                                    },
                                    [
                                      _c("label", [_vm._v("Insurance")]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "field conditional" },
                                        [
                                          _c("checkbox-plain2", {
                                            model: {
                                              value:
                                                q[
                                                  "terminal" +
                                                    ti +
                                                    "InsuranceOpt"
                                                ],
                                              callback: function($$v) {
                                                _vm.$set(
                                                  q,
                                                  "terminal" +
                                                    ti +
                                                    "InsuranceOpt",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "q['terminal'+ti+'InsuranceOpt']"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("span", [_vm._v("Qty:")]),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  q[
                                                    "terminal" +
                                                      ti +
                                                      "Insurance"
                                                  ],
                                                expression:
                                                  "q['terminal'+ti+'Insurance']"
                                              }
                                            ],
                                            attrs: {
                                              type: "text",
                                              disabled: !q[
                                                "terminal" + ti + "InsuranceOpt"
                                              ]
                                            },
                                            domProps: {
                                              value:
                                                q["terminal" + ti + "Insurance"]
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  q,
                                                  "terminal" + ti + "Insurance",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          }),
                          _vm._v(" "),
                          false
                            ? _c("li", { staticStyle: { width: "100%" } }, [
                                _vm.terminalCount > 1
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "terminals-del",
                                        attrs: { href: "" },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            _vm.terminalCount--
                                          }
                                        }
                                      },
                                      [_vm._v("‒")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "a",
                                  {
                                    staticClass: "terminals-add",
                                    attrs: { href: "" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        _vm.terminalCount++
                                      }
                                    }
                                  },
                                  [_vm._v("+")]
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("li", { staticClass: "fwidth fwx" }, [
                            _c("label", [_vm._v("Communication")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field" },
                              [
                                _c(
                                  "validator",
                                  {
                                    attrs: {
                                      value:
                                        q.communicationMethod &&
                                        q.communicationMethod.length,
                                      reqs: ""
                                    }
                                  },
                                  [
                                    _c("multiselect", {
                                      attrs: {
                                        options: [
                                          "IP",
                                          "Wireless",
                                          "SIM Card Required",
                                          "Batch Auto Close Time!time"
                                        ],
                                        time: q.communicationTime
                                      },
                                      on: {
                                        "update:time": function($event) {
                                          return _vm.$set(
                                            q,
                                            "communicationTime",
                                            $event
                                          )
                                        }
                                      },
                                      model: {
                                        value: q.communicationMethod,
                                        callback: function($$v) {
                                          _vm.$set(
                                            q,
                                            "communicationMethod",
                                            $$v
                                          )
                                        },
                                        expression: "q.communicationMethod"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ])
                        ],
                        2
                      )
                    ])
                  : _vm._e()
              }),
              _vm._v(" "),
              _vm.editMode
                ? _c("div", { staticClass: "form-actions" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-large",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.saveItem.apply(null, arguments)
                          }
                        }
                      },
                      [_vm._v("Save")]
                    )
                  ])
                : _vm._e()
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.tab == 12 && _vm.intake
        ? _c("form", {}, [
            _c(
              "section",
              [
                _c("pricing-details", {
                  attrs: {
                    currentTemplate: _vm.intake.formData,
                    solution: _vm.item.solution
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _vm.editMode
              ? _c("div", { staticClass: "form-actions" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-large",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.saveItem.apply(null, arguments)
                        }
                      }
                    },
                    [_vm._v("Save")]
                  )
                ])
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.tab == 16 && _vm.intake
        ? _c(
            "form",
            { staticClass: "appf pfx" },
            [
              _vm._l([_vm.setup], function(item) {
                return _vm.setup
                  ? _c("section", { staticClass: "form-step" }, [
                      _c("h2", [_vm._v("Risk & Compliance Settings")]),
                      _vm._v(" "),
                      _c(
                        "ul",
                        { staticClass: "column-form column-form-compact" },
                        [
                          _c("li", [
                            _c("label", [_vm._v("Compliance Review Days")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field" },
                              [
                                _c(
                                  "validator",
                                  { attrs: { value: 0, reqs: "" } },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: item.merchant.reviewDays,
                                          expression: "item.merchant.reviewDays"
                                        }
                                      ],
                                      attrs: { type: "text", readonly: "" },
                                      domProps: {
                                        value: item.merchant.reviewDays
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            item.merchant,
                                            "reviewDays",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v("Account Reserve (%)")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field" },
                              [
                                _c(
                                  "validator",
                                  { attrs: { value: 0, reqs: "" } },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: item.merchant.reserve,
                                          expression: "item.merchant.reserve"
                                        }
                                      ],
                                      attrs: { type: "text", readonly: "" },
                                      domProps: {
                                        value: item.merchant.reserve
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            item.merchant,
                                            "reserve",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v("Account Reserve (Days)")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field" },
                              [
                                _c(
                                  "validator",
                                  { attrs: { value: 0, reqs: "" } },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: item.merchant.reserveHold,
                                          expression:
                                            "item.merchant.reserveHold"
                                        }
                                      ],
                                      attrs: { type: "text", readonly: "" },
                                      domProps: {
                                        value: item.merchant.reserveHold
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            item.merchant,
                                            "reserveHold",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v("Settlement Delay (Days)")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field" },
                              [
                                _c(
                                  "validator",
                                  { attrs: { value: 0, reqs: "" } },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: item.merchant.settlementDays,
                                          expression:
                                            "item.merchant.settlementDays"
                                        }
                                      ],
                                      attrs: { type: "text", readonly: "" },
                                      domProps: {
                                        value: item.merchant.settlementDays
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            item.merchant,
                                            "settlementDays",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v("Fraud Prevention")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field" },
                              [
                                _c(
                                  "validator",
                                  { attrs: { value: 0, reqs: "" } },
                                  [
                                    _c("dyn-select", {
                                      attrs: { options: [] },
                                      model: {
                                        value: item.setupData.fraudPrevention,
                                        callback: function($$v) {
                                          _vm.$set(
                                            item.setupData,
                                            "fraudPrevention",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "item.setupData.fraudPrevention"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", { staticClass: "double" }, [
                            _c("label", [_vm._v("Billing Descriptor")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field" },
                              [
                                _c(
                                  "validator",
                                  { attrs: { value: 0, reqs: "" } },
                                  [
                                    _c("input", {
                                      attrs: { readonly: "", type: "text" },
                                      domProps: { value: item.app.descriptor1 }
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", { staticClass: "triple" }, [
                            _c("label", [_vm._v("Account Class")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field" },
                              [
                                _c(
                                  "validator",
                                  { attrs: { value: 0, reqs: "" } },
                                  [
                                    _c("input", {
                                      attrs: { readonly: "", type: "text" },
                                      domProps: {
                                        value:
                                          item.app.class.name +
                                          " " +
                                          item.app.class.description
                                      }
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", { staticClass: "quintuple" }, [
                            _c("label", [_vm._v("MCC")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field" },
                              [
                                _c(
                                  "validator",
                                  { attrs: { value: 0, reqs: "" } },
                                  [
                                    _c("input", {
                                      attrs: { readonly: "", type: "text" },
                                      domProps: { value: item.app.mcc }
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ])
                        ]
                      )
                    ])
                  : _vm._e()
              }),
              _vm._v(" "),
              _vm.editMode
                ? _c("div", { staticClass: "form-actions" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-large",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.saveItem.apply(null, arguments)
                          }
                        }
                      },
                      [_vm._v("Save")]
                    )
                  ])
                : _vm._e()
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.tab == 17
        ? _c(
            "form",
            { staticClass: "appf pfx" },
            [
              _vm._l([_vm.setup], function(item) {
                return _vm.setup
                  ? _c("section", { staticClass: "form-step" }, [
                      _c("h2", [_vm._v("Access Information")]),
                      _vm._v(" "),
                      _c(
                        "ul",
                        { staticClass: "column-form column-form-compact" },
                        [
                          _c("li", [
                            _c("label", [_vm._v("Portal")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field" },
                              [
                                _c(
                                  "validator",
                                  { attrs: { value: 0, reqs: "" } },
                                  [
                                    _c("dyn-select", {
                                      attrs: { options: _vm.portals },
                                      model: {
                                        value: item.setupData.portal,
                                        callback: function($$v) {
                                          _vm.$set(
                                            item.setupData,
                                            "portal",
                                            $$v
                                          )
                                        },
                                        expression: "item.setupData.portal"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v("Username")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field" },
                              [
                                _c(
                                  "validator",
                                  { attrs: { value: 0, reqs: "" } },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: item.setupData.username,
                                          expression: "item.setupData.username"
                                        }
                                      ],
                                      attrs: { type: "text" },
                                      domProps: {
                                        value: item.setupData.username
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            item.setupData,
                                            "username",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v("Send welcome email")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field" },
                              [
                                _c(
                                  "validator",
                                  {
                                    attrs: {
                                      value: item.setupData.sendEmail,
                                      reqs: "required"
                                    }
                                  },
                                  [
                                    _c("dyn-select", {
                                      attrs: { options: _vm.toggleYesNo },
                                      model: {
                                        value: item.setupData.sendEmail,
                                        callback: function($$v) {
                                          _vm.$set(
                                            item.setupData,
                                            "sendEmail",
                                            $$v
                                          )
                                        },
                                        expression: "item.setupData.sendEmail"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v("PIN")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field" },
                              [
                                _c(
                                  "validator",
                                  {
                                    attrs: {
                                      value: item.setupData.pin,
                                      reqs: "required"
                                    }
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: item.setupData.pin,
                                          expression: "item.setupData.pin"
                                        }
                                      ],
                                      attrs: { type: "text", readonly: "" },
                                      domProps: { value: item.setupData.pin },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            item.setupData,
                                            "pin",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.generatePin.apply(
                                              null,
                                              arguments
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v("Generate PIN")]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ])
                        ]
                      )
                    ])
                  : _vm._e()
              }),
              _vm._v(" "),
              _vm.editMode
                ? _c("div", { staticClass: "form-actions" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-large",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.saveItem.apply(null, arguments)
                          }
                        }
                      },
                      [_vm._v("Save")]
                    )
                  ])
                : _vm._e()
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.tab == 13
        ? _c("form", { staticClass: "preq-form preq-view" }, [
            _c("section"),
            _vm._v(" "),
            _vm.editMode
              ? _c("div", { staticClass: "form-actions" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-large",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.saveItem.apply(null, arguments)
                        }
                      }
                    },
                    [_vm._v("Save")]
                  )
                ])
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.tab == 14
        ? _c(
            "form",
            { staticClass: "appf pfx" },
            [
              _vm._l([_vm.setup], function(item) {
                return _vm.setup
                  ? _c("section", { staticClass: "form-step" }, [
                      _c("h2", [_vm._v("Connectivity")]),
                      _vm._v(" "),
                      _c(
                        "ul",
                        { staticClass: "column-form column-form-compact" },
                        [
                          _c("li", [
                            _c("label", [_vm._v("Gateway")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field" },
                              [
                                _c(
                                  "validator",
                                  { attrs: { value: 0, reqs: "" } },
                                  [
                                    _c("dyn-select", {
                                      attrs: { options: _vm.gatewaysList },
                                      model: {
                                        value: item.app.gateway,
                                        callback: function($$v) {
                                          _vm.$set(item.app, "gateway", $$v)
                                        },
                                        expression: "item.app.gateway"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v("Processor")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field" },
                              [
                                _c(
                                  "validator",
                                  { attrs: { value: 0, reqs: "" } },
                                  [
                                    _c("dyn-select", {
                                      attrs: { options: _vm.processorsList },
                                      model: {
                                        value: item.app.processor,
                                        callback: function($$v) {
                                          _vm.$set(item.app, "processor", $$v)
                                        },
                                        expression: "item.app.processor"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c("h2", [_vm._v("Payment Methods")]),
                      _vm._v(" "),
                      _c(
                        "ul",
                        { staticClass: "column-form column-form-compact" },
                        [
                          _c("li", [
                            _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: item.setupData.cardPresent,
                                    expression: "item.setupData.cardPresent"
                                  }
                                ],
                                staticStyle: {
                                  "margin-right": "4px",
                                  "vertical-align": "middle"
                                },
                                attrs: { type: "checkbox" },
                                domProps: {
                                  checked: Array.isArray(
                                    item.setupData.cardPresent
                                  )
                                    ? _vm._i(item.setupData.cardPresent, null) >
                                      -1
                                    : item.setupData.cardPresent
                                },
                                on: {
                                  change: function($event) {
                                    var $$a = item.setupData.cardPresent,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            item.setupData,
                                            "cardPresent",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            item.setupData,
                                            "cardPresent",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        item.setupData,
                                        "cardPresent",
                                        $$c
                                      )
                                    }
                                  }
                                }
                              }),
                              _vm._v("\n        Card Present / POS")
                            ])
                          ]),
                          _vm._v(" "),
                          _c("li", { staticStyle: { width: "25%" } }, [
                            _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: item.setupData.cardNotPresent,
                                    expression: "item.setupData.cardNotPresent"
                                  }
                                ],
                                staticStyle: {
                                  "margin-right": "4px",
                                  "vertical-align": "middle"
                                },
                                attrs: { type: "checkbox" },
                                domProps: {
                                  checked: Array.isArray(
                                    item.setupData.cardNotPresent
                                  )
                                    ? _vm._i(
                                        item.setupData.cardNotPresent,
                                        null
                                      ) > -1
                                    : item.setupData.cardNotPresent
                                },
                                on: {
                                  change: function($event) {
                                    var $$a = item.setupData.cardNotPresent,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            item.setupData,
                                            "cardNotPresent",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            item.setupData,
                                            "cardNotPresent",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        item.setupData,
                                        "cardNotPresent",
                                        $$c
                                      )
                                    }
                                  }
                                }
                              }),
                              _vm._v("\n        Card not Present / E-Commerce")
                            ])
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c("h2", [_vm._v("Payment Settings")]),
                      _vm._v(" "),
                      _c(
                        "ul",
                        {
                          staticClass:
                            "column-form column-form-compact column-form-compact-3"
                        },
                        [
                          _c("li", [
                            _c("label", [_vm._v("Transaction Currencies")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field" },
                              [
                                _c(
                                  "validator",
                                  { attrs: { value: 0, reqs: "" } },
                                  [
                                    _c("input", {
                                      attrs: { readonly: "", type: "text" },
                                      domProps: {
                                        value: item.app.transactionCurrencies
                                      }
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [
                              _vm._v("Monthly Transaction Volume Limit")
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field" },
                              [
                                _c(
                                  "validator",
                                  { attrs: { value: 0, reqs: "" } },
                                  [
                                    _c("input", {
                                      attrs: { type: "text", readonly: "" },
                                      domProps: {
                                        value: item.merchant.monthlyLimit
                                      }
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [
                              _vm._v("Annual Transaction Volume Limit")
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field" },
                              [
                                _c(
                                  "validator",
                                  { attrs: { value: 0, reqs: "" } },
                                  [
                                    _c("input", {
                                      attrs: { type: "text" },
                                      domProps: {
                                        value: item.merchant.annualLimit
                                      }
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "ul",
                        {
                          staticClass:
                            "column-form column-form-compact column-form-compact-3"
                        },
                        [
                          _c("li", [
                            _c("label", [_vm._v("Minimum Transaction Amount")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field" },
                              [
                                _c(
                                  "validator",
                                  { attrs: { value: 0, reqs: "" } },
                                  [
                                    _c("input", {
                                      attrs: { type: "text" },
                                      domProps: {
                                        value: item.setupData.minTransaction
                                      }
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v("Average Transaction Amount")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field" },
                              [
                                _c(
                                  "validator",
                                  { attrs: { value: 0, reqs: "" } },
                                  [
                                    _c("input", {
                                      attrs: { readonly: "", type: "text" },
                                      domProps: {
                                        value: item.app.avgTransaction
                                      }
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v("Maximum Transaction Amount")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field" },
                              [
                                _c(
                                  "validator",
                                  { attrs: { value: 0, reqs: "" } },
                                  [
                                    _c("input", {
                                      attrs: { readonly: "", type: "text" },
                                      domProps: {
                                        value: item.app.maxTransaction
                                      }
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c("h2", { staticStyle: { "font-size": "1.3em" } }, [
                        _vm._v("EFT / ACH")
                      ]),
                      _vm._v(" "),
                      _c(
                        "ul",
                        {
                          staticClass:
                            "column-form column-form-compact column-form-compact-3"
                        },
                        [
                          _c("li", [
                            _c("label", [
                              _vm._v("Monthly Transaction Volume Limit")
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field" },
                              [
                                _c(
                                  "validator",
                                  { attrs: { value: 0, reqs: "" } },
                                  [
                                    _c("input", {
                                      attrs: { readonly: "", type: "text" },
                                      domProps: {
                                        value: item.setupData.eftMonthlyLimit
                                      }
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [
                              _vm._v("Annual Transaction Volume Limit")
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field" },
                              [
                                _c(
                                  "validator",
                                  { attrs: { value: 0, reqs: "" } },
                                  [
                                    _c("input", {
                                      attrs: { readonly: "", type: "text" },
                                      domProps: {
                                        value: item.setupData.eftAnnualLimit
                                      }
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "ul",
                        {
                          staticClass:
                            "column-form column-form-compact column-form-compact-3"
                        },
                        [
                          _c("li", [
                            _c("label", [_vm._v("Minimum Transaction Amount")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field" },
                              [
                                _c(
                                  "validator",
                                  { attrs: { value: 0, reqs: "" } },
                                  [
                                    _c("input", {
                                      attrs: { readonly: "", type: "text" },
                                      domProps: {
                                        value: item.setupData.eftMinTransaction
                                      }
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v("Average Transaction Amount")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field" },
                              [
                                _c(
                                  "validator",
                                  { attrs: { value: 0, reqs: "" } },
                                  [
                                    _c("input", {
                                      attrs: { readonly: "", type: "text" },
                                      domProps: {
                                        value: item.setupData.eftAvgTransaction
                                      }
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("label", [_vm._v("Maximum Transaction Amount")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field" },
                              [
                                _c(
                                  "validator",
                                  { attrs: { value: 0, reqs: "" } },
                                  [
                                    _c("input", {
                                      attrs: { readonly: "", type: "text" },
                                      domProps: {
                                        value: item.setupData.eftMaxTransaction
                                      }
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c("h2", [_vm._v("TIDS & KEYS Settings")]),
                      _vm._v(" "),
                      _c("ul", {
                        staticClass: "column-form column-form-compact"
                      }),
                      _vm._v(" "),
                      false
                        ? _c("ul", { staticClass: "appview-list" }, [
                            _c("li", [
                              _c("label", [_vm._v("Acquirer")]),
                              _vm._v(" "),
                              _c("div", { staticClass: "validator" }, [
                                !_vm.editMode
                                  ? _c("span", [_vm._v("a")])
                                  : _c("input", { attrs: { type: "text" } })
                              ])
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _c("label", [_vm._v("Acquirer Merchant ID")]),
                              _vm._v(" "),
                              _c("div", { staticClass: "validator" }, [
                                !_vm.editMode
                                  ? _c("span", [_vm._v("a")])
                                  : _c("input", { attrs: { type: "text" } })
                              ])
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _c("label", [_vm._v("Gateway")]),
                              _vm._v(" "),
                              _c("div", { staticClass: "validator" }, [
                                !_vm.editMode
                                  ? _c("span", [_vm._v("a")])
                                  : _c("input", { attrs: { type: "text" } })
                              ])
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _c("label", [_vm._v("Gateway Merchant ID")]),
                              _vm._v(" "),
                              _c("div", { staticClass: "validator" }, [
                                !_vm.editMode
                                  ? _c("span", [_vm._v("a")])
                                  : _c("input", { attrs: { type: "text" } })
                              ])
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _c("label", [_vm._v("Transaction Currencies")]),
                              _vm._v(" "),
                              _c("div", { staticClass: "validator" }, [
                                !_vm.editMode
                                  ? _c("span", [_vm._v("a")])
                                  : _c("input", { attrs: { type: "text" } })
                              ])
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _c("label", [
                                _vm._v(
                                  "Payment Types Allowed (POS, Mobile, E-Commerce, In-App)"
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "validator" }, [
                                !_vm.editMode
                                  ? _c("span", [_vm._v("a")])
                                  : _c("input", { attrs: { type: "text" } })
                              ])
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _c("label", [
                                _vm._v("Minimum Transaction Amount")
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "validator" }, [
                                !_vm.editMode
                                  ? _c("span", [_vm._v("a")])
                                  : _c("input", { attrs: { type: "text" } })
                              ])
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _c("label", [
                                _vm._v("Maximum Transaction Amount")
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "validator" }, [
                                !_vm.editMode
                                  ? _c("span", [_vm._v("a")])
                                  : _c("input", { attrs: { type: "text" } })
                              ])
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _c("label", [_vm._v("Monthly Processing Limit")]),
                              _vm._v(" "),
                              _c("div", { staticClass: "validator" }, [
                                !_vm.editMode
                                  ? _c("span", [_vm._v("a")])
                                  : _c("input", { attrs: { type: "text" } })
                              ])
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _c("label", [_vm._v("Recurring (Yes/No)")]),
                              _vm._v(" "),
                              _c("div", { staticClass: "validator" }, [
                                !_vm.editMode
                                  ? _c("span", [_vm._v("a")])
                                  : _c("input", { attrs: { type: "text" } })
                              ])
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _c("label", [_vm._v("Multi-Currency (Yes/No)")]),
                              _vm._v(" "),
                              _c("div", { staticClass: "validator" }, [
                                !_vm.editMode
                                  ? _c("span", [_vm._v("a")])
                                  : _c("input", { attrs: { type: "text" } })
                              ])
                            ])
                          ])
                        : _vm._e()
                    ])
                  : _vm._e()
              }),
              _vm._v(" "),
              _vm.editMode
                ? _c("div", { staticClass: "form-actions" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-large",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.saveItem.apply(null, arguments)
                          }
                        }
                      },
                      [_vm._v("Save")]
                    )
                  ])
                : _vm._e()
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.tab == 15
        ? _c("form", { staticClass: "preq-form preq-view" }, [
            _c("section", [
              _c("ul", { staticClass: "appview-list" }, [
                _c("li", [
                  _c("label", [_vm._v("Settlements")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [_vm._v("a")])
                      : _c("input", { attrs: { type: "text" } })
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Statements")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [_vm._v("a")])
                      : _c("input", { attrs: { type: "text" } })
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Invoices")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [_vm._v("a")])
                      : _c("input", { attrs: { type: "text" } })
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("label", [_vm._v("Payment History")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "validator" }, [
                    !_vm.editMode
                      ? _c("span", [_vm._v("a")])
                      : _c("input", { attrs: { type: "text" } })
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _vm.editMode
              ? _c("div", { staticClass: "form-actions" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-large",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.saveItem.apply(null, arguments)
                        }
                      }
                    },
                    [_vm._v("Save")]
                  )
                ])
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("portal", { attrs: { to: "actions" } }, [
        _c(
          "ul",
          [
            _vm.edit
              ? _c("li", [
                  _c(
                    "a",
                    {
                      attrs: { href: "" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.$emit("update:editMode", true)
                        }
                      }
                    },
                    [_vm._v("Edit")]
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.statusActions, function(optLabel, optMethod) {
              return _vm.tab == 999
                ? _c(
                    "li",
                    {
                      class: {
                        disabled: _vm.activeStatuses.includes(_vm.item.status)
                      }
                    },
                    [
                      _c(
                        "a",
                        {
                          attrs: { href: "", title: optLabel },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              !_vm.$parent.$parent.doAction(optMethod)
                            }
                          }
                        },
                        [_vm._v(_vm._s(optMethod))]
                      )
                    ]
                  )
                : _vm._e()
            })
          ],
          2
        )
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "appview-list" }, [
      _c("li", { staticClass: "title" }, [
        _c("div", { staticClass: "validator" }, [
          _c("span", [_vm._v("Applying Company")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "title" }, [
      _c("div", { staticClass: "validator" }, [
        _c("span", [_vm._v("Parent Company")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "head" }, [
      _c("label", [_vm._v("History")]),
      _vm._v(" "),
      _c("div", [
        _c("div", [_vm._v("Last month")]),
        _vm._v(" "),
        _c("div", [_vm._v("2 months ago")]),
        _vm._v(" "),
        _c("div", [_vm._v("3 months ago")]),
        _vm._v(" "),
        _c("div", [_vm._v("4 months ago")]),
        _vm._v(" "),
        _c("div", [_vm._v("5 months ago")]),
        _vm._v(" "),
        _c("div", [_vm._v("6 months ago")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }