var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form", { staticClass: "edit-system search-form" }, [
    _c("div", {}, [
      _c("div", [
        _c("div", { staticClass: "field" }, [
          _c("label", [_vm._v("Agreement General:")]),
          _vm._v(" "),
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.item.agrGeneral,
                expression: "item.agrGeneral"
              }
            ],
            domProps: { value: _vm.item.agrGeneral },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.item, "agrGeneral", $event.target.value)
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "field" }, [
          _c("label", [_vm._v("Agreement Company Info:")]),
          _vm._v(" "),
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.item.argCompany,
                expression: "item.argCompany"
              }
            ],
            domProps: { value: _vm.item.argCompany },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.item, "argCompany", $event.target.value)
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "field" }, [
          _c("label", [_vm._v("Agreement Banking:")]),
          _vm._v(" "),
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.item.agrBanking,
                expression: "item.agrBanking"
              }
            ],
            domProps: { value: _vm.item.agrBanking },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.item, "agrBanking", $event.target.value)
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "field" }, [
          _c("label", [_vm._v("Agreement Modules:")]),
          _vm._v(" "),
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.item.agrModules,
                expression: "item.agrModules"
              }
            ],
            domProps: { value: _vm.item.agrModules },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.item, "agrModules", $event.target.value)
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "field" }, [
          _c("label", [_vm._v("Agreement Equipment:")]),
          _vm._v(" "),
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.item.agrEquipment,
                expression: "item.agrEquipment"
              }
            ],
            domProps: { value: _vm.item.agrEquipment },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.item, "agrEquipment", $event.target.value)
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "field" }, [
          _c("label", [_vm._v("Agreement Options:")]),
          _vm._v(" "),
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.item.agrOptions,
                expression: "item.agrOptions"
              }
            ],
            domProps: { value: _vm.item.agrOptions },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.item, "agrOptions", $event.target.value)
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "field" }, [
          _c("label", [_vm._v("Agreement Pricing:")]),
          _vm._v(" "),
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.item.agrPricing,
                expression: "item.agrPricing"
              }
            ],
            domProps: { value: _vm.item.agrPricing },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.item, "agrPricing", $event.target.value)
              }
            }
          })
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "buttons" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-large btn-primary",
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.updateSettings.apply(null, arguments)
            }
          }
        },
        [_vm._v("Submit")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }