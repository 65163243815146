var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "content-filters" },
      [
        _c(
          "basic-form",
          [
            _c("h4", [_vm._v("Languages")]),
            _vm._v(" "),
            _c("multiselect-dropdown", {
              attrs: {
                options: _vm.languageOptions.map(function(a) {
                  return { label: a, value: a }
                }),
                isMulti: true,
                disabled: !_vm.updateInformations
              },
              model: {
                value: _vm.settings.language,
                callback: function($$v) {
                  _vm.$set(_vm.settings, "language", $$v)
                },
                expression: "settings.language"
              }
            }),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("h4", [_vm._v("Timezones")]),
            _vm._v(" "),
            _c("multiselect-dropdown", {
              attrs: {
                options: _vm.timezoneOptions.map(function(a) {
                  return { label: a, value: a }
                }),
                isMulti: true,
                disabled: !_vm.updateInformations
              },
              model: {
                value: _vm.settings.timezone,
                callback: function($$v) {
                  _vm.$set(_vm.settings, "timezone", $$v)
                },
                expression: "settings.timezone"
              }
            }),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "buttons" },
              [
                _vm.updateInformations
                  ? [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-large btn-primary",
                          on: {
                            click: function($event) {
                              return _vm.save()
                            }
                          }
                        },
                        [_vm._v("Submit")]
                      )
                    ]
                  : [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-large btn-primary",
                          on: {
                            click: function($event) {
                              _vm.updateInformations = true
                            }
                          }
                        },
                        [_vm._v("Update")]
                      )
                    ]
              ],
              2
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }