var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.uwShown
      ? _c("div", { staticClass: "action-sub-buttons" }, [
          _c("a", { staticClass: "btn", on: { click: _vm.newCheck } }, [
            _vm._v("Run new")
          ]),
          _vm._v(" "),
          _vm.uwSelected &&
          _vm.uwSelectedItem.response &&
          _vm.uwSelectedItem.response.complete
            ? _c(
                "a",
                {
                  staticClass: "btn",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.doShowItems(_vm.uwSelected)
                    }
                  }
                },
                [_vm._v("View")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.uwSelected &&
          _vm.uwSelectedItem.response &&
          !_vm.uwSelectedItem.response.complete
            ? _c(
                "a",
                {
                  staticClass: "btn",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.refresh(_vm.uwSelected)
                    }
                  }
                },
                [_vm._v("Refresh")]
              )
            : _vm._e()
        ])
      : _c("div", { staticClass: "action-sub-buttons" }, [
          _c(
            "a",
            {
              staticClass: "btn",
              on: {
                click: function($event) {
                  _vm.uwShown = null
                }
              }
            },
            [_vm._v("Back")]
          ),
          _vm._v(" "),
          _vm.printUrl
            ? _c(
                "a",
                {
                  staticClass: "btn",
                  attrs: { href: _vm.printUrl, target: "_blank" }
                },
                [_vm._v("View Report")]
              )
            : _vm._e()
        ]),
    _vm._v(" "),
    !_vm.uwShown
      ? _c("table", { staticClass: "file-list content-table" }, [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "tbody",
            _vm._l(_vm.uwList, function(uwItem, uwI) {
              return _c("tr", [
                _c("td", [_vm._v(_vm._s(_vm.uwList.length - uwI))]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    _vm._s(
                      _vm.formatDate(uwItem.createdDate, "YYYY-MM-DD HH:mm")
                    )
                  )
                ]),
                _vm._v(" "),
                _c("td", [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: _vm.printUrlForItem(uwItem),
                        target: "_blank"
                      }
                    },
                    [_vm._v(_vm._s(_vm.docNameForItem(uwItem)))]
                  )
                ]),
                _vm._v(" "),
                uwItem.type == "g2"
                  ? _c("td", [
                      _vm._v(
                        _vm._s(
                          uwItem.response.complete ? "Completed" : "Pending"
                        )
                      )
                    ])
                  : _c("td", [
                      _vm._v(
                        _vm._s(
                          (uwItem.response && uwItem.response.res) || "ERROR"
                        )
                      )
                    ])
              ])
            }),
            0
          )
        ])
      : _vm.uwShown
      ? _c("div", { staticClass: "pre-check-details" }, [
          _c(
            "div",
            { staticClass: "t2col toAnimate" },
            _vm._l([_vm.uwShownItem.response || {}], function(r) {
              return _c(
                "div",
                { staticClass: "kycg2", staticStyle: { width: "100%" } },
                [
                  _c("h4", [_vm._v("Messages")]),
                  _vm._v(" "),
                  _c(
                    "ul",
                    { staticClass: "messagesx" },
                    [
                      _vm._l(r.messages.info, function(item) {
                        return _c("li", { staticClass: "info" }, [
                          _vm._v(_vm._s(item))
                        ])
                      }),
                      _vm._v(" "),
                      _vm._l(r.messages.warn, function(item) {
                        return _c("li", { staticClass: "warn" }, [
                          _vm._v(_vm._s(item))
                        ])
                      }),
                      _vm._v(" "),
                      _vm._l(r.messages.ok, function(item) {
                        return _c("li", { staticClass: "ok" }, [
                          _vm._v(_vm._s(item))
                        ])
                      }),
                      _vm._v(" "),
                      _vm._l(r.messages.crit, function(item) {
                        return _c("li", { staticClass: "crit" }, [
                          _vm._v(_vm._s(item))
                        ])
                      })
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("h4", [_vm._v("Invalid Addresses")]),
                  _vm._v(" "),
                  _c(
                    "ul",
                    { staticClass: "addresses" },
                    [
                      !r.invalid_addresses.length
                        ? _c("li", { staticClass: "info" }, [_vm._v("(none)")])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._l(r.invalid_addresses, function(addr) {
                        return _c("li", [
                          _vm._v(
                            "\n                  " +
                              _vm._s(
                                addr.street +
                                  " " +
                                  addr.city +
                                  " " +
                                  addr.postcode +
                                  " " +
                                  addr.state +
                                  " " +
                                  addr.country
                              ) +
                              "\n                "
                          )
                        ])
                      })
                    ],
                    2
                  ),
                  _vm._v(" "),
                  r.alexa ? _c("h4", [_vm._v("Alexa")]) : _vm._e(),
                  _vm._v(" "),
                  r.alexa
                    ? _c("ul", [
                        _c("li", [
                          _vm._v(
                            "Adult: " + _vm._s(r.alexa.adult ? "Yes" : "No")
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _vm._v("Rank: " + _vm._s(r.alexa.rank || "N/A"))
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  r.bbb_results.exists ? _c("h4", [_vm._v("BBB")]) : _vm._e(),
                  _vm._v(" "),
                  r.bbb_results.exists
                    ? _c("ul", [
                        _c("li", [
                          _vm._v(
                            "Exists: " +
                              _vm._s(r.bbb_results.exists ? "Yes" : "No")
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _vm._v("Grade: " + _vm._s(r.bbb_results.grade))
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _vm._v("Report: "),
                          _c(
                            "a",
                            {
                              attrs: {
                                target: "_blank",
                                href: r.bbb_results.bbb_api.ReportUrl
                              }
                            },
                            [_vm._v("View")]
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _vm._v(
                            "Email: " +
                              _vm._s(
                                r.bbb_results.bbb_api.ContactEmailAddress[0]
                              )
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _vm._v(
                            "Url: " +
                              _vm._s(r.bbb_results.bbb_api.BusinessURLs[0])
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _vm._v(
                            "Phone: " + _vm._s(r.bbb_results.bbb_api.Phones[0])
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _vm._v(
                            "Start date: " +
                              _vm._s(r.bbb_results.bbb_api.DateBusinessStarted)
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _vm._v(
                            "Category: " +
                              _vm._s(r.bbb_results.bbb_api.PrimaryCategory)
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  r.google_places_results
                    ? [
                        _c("h4", [_vm._v("Google Places")]),
                        _vm._v(" "),
                        _vm._l(r.google_places_results, function(place) {
                          return _c(
                            "ul",
                            [
                              _vm._l([place.results[0]], function(p) {
                                return [
                                  _c("li", [
                                    _vm._v(
                                      "Search Query: " + _vm._s(place.query)
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("li", [_vm._v("Name: " + _vm._s(p.name))]),
                                  _vm._v(" "),
                                  _c("li", [
                                    _vm._v("Rating: " + _vm._s(p.rating))
                                  ]),
                                  _vm._v(" "),
                                  _c("li", [
                                    _vm._v(
                                      "Price level: " + _vm._s(p.price_level)
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("li", [
                                    _vm._v(
                                      "Phone: " +
                                        _vm._s(p.formatted_phone_number)
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("li", [
                                    _vm._v(
                                      "Address: " + _vm._s(p.formatted_address)
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("li", [
                                    _vm._v("Url: " + _vm._s(p.website))
                                  ])
                                ]
                              })
                            ],
                            2
                          )
                        })
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  r.ms_entities_results != "disabled"
                    ? [
                        _c("h4", [_vm._v("Microsoft Entities")]),
                        _vm._v(" "),
                        _vm._l(r.ms_entities_results, function(place) {
                          return _c(
                            "ul",
                            [
                              _vm._l([place.places.value[0]], function(p) {
                                return [
                                  _c("li", [_vm._v("Name: " + _vm._s(p.name))]),
                                  _vm._v(" "),
                                  _c("li", [_vm._v("Url: " + _vm._s(p.url))]),
                                  _vm._v(" "),
                                  _c("li", [
                                    _vm._v("Phone: " + _vm._s(p.telephone))
                                  ]),
                                  _vm._v(" "),
                                  _c("li", [
                                    _vm._v(
                                      "Address: " +
                                        _vm._s(
                                          p.address.neighborhood +
                                            " " +
                                            p.address.postalCode +
                                            " " +
                                            p.address.addressLocality +
                                            " " +
                                            p.address.addressRegion +
                                            " " +
                                            p.address.addressCountry
                                        )
                                    )
                                  ])
                                ]
                              })
                            ],
                            2
                          )
                        })
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  r.malware_detections
                    ? _c("h4", [_vm._v("Malware")])
                    : _vm._e(),
                  _vm._v(" "),
                  r.malware_detections
                    ? _c("ul", [
                        _c("li", [
                          _vm._v(
                            "Phishings: " +
                              _vm._s(r.malware_detections.phishings)
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _vm._v(
                            "Malwares: " + _vm._s(r.malware_detections.malwares)
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  r.ofac_results ? _c("h4", [_vm._v("OFAC")]) : _vm._e(),
                  _vm._v(" "),
                  r.ofac_results
                    ? _c(
                        "ul",
                        [
                          _vm._l(r.ofac_results.name, function(ofac) {
                            return _c(
                              "li",
                              [
                                _vm._l(ofac, function(n, name) {
                                  return [_vm._v(_vm._s(name))]
                                })
                              ],
                              2
                            )
                          }),
                          _vm._v(" "),
                          _vm._l(r.ofac_results.location, function(ofac) {
                            return _c(
                              "li",
                              [
                                _vm._l(ofac, function(n, name) {
                                  return [_vm._v(_vm._s(name))]
                                })
                              ],
                              2
                            )
                          })
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  r.pipl_results
                    ? [
                        _c("h4", [_vm._v("PIPL")]),
                        _vm._v(" "),
                        _vm._l(
                          r.pipl_results.length
                            ? r.pipl_results[0].possible_persons
                            : [],
                          function(person) {
                            return _c("ul", [
                              _c("li", [
                                _vm._v(
                                  _vm._s(
                                    person.names
                                      .map(function(a) {
                                        return a.display
                                      })
                                      .join(", ")
                                  )
                                )
                              ])
                            ])
                          }
                        )
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  r.whois_results ? _c("h4", [_vm._v("Whois")]) : _vm._e(),
                  _vm._v(" "),
                  r.whois_results
                    ? _c(
                        "ul",
                        [
                          _c("li", [
                            _vm._v(
                              "Created: " +
                                _vm._s(r.whois_results.creation_date.join(" "))
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _vm._v(
                              "Expires: " +
                                _vm._s(
                                  r.whois_results.expiration_date.join(" ")
                                )
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _vm._v(
                              "Updated: " +
                                _vm._s(r.whois_results.updated_date.join(" "))
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _vm._v(
                              "Registrar: " +
                                _vm._s(r.whois_results.registrar.join(" "))
                            )
                          ]),
                          _vm._v(" "),
                          _vm._l(r.whois_results.nameservers, function(ns) {
                            return _c("li", [
                              _vm._v("Nameserver: " + _vm._s(ns))
                            ])
                          }),
                          _vm._v(" "),
                          _vm._l(r.whois_results.nameservers, function(ns) {
                            return _c("li", [
                              _vm._v("Nameserver: " + _vm._s(ns))
                            ])
                          })
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("h4", [_vm._v("Yellow pages")]),
                  _vm._v(" "),
                  _vm._l(r.yellowpages_result, function(y) {
                    return _c("ul", [
                      _c("li", [
                        _vm._v("Search term: " + _vm._s(y.search_term))
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _vm._v("Business name: " + _vm._s(y.business_name))
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _vm._v("Category: " + _vm._s(y.primary_category))
                      ]),
                      _vm._v(" "),
                      _c("li", [_vm._v("Open hours: " + _vm._s(y.open_hours))]),
                      _vm._v(" "),
                      _c("li", [
                        _vm._v("Payment methods: " + _vm._s(y.payment_methods))
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _vm._v(
                          "Address: " +
                            _vm._s(
                              y.street +
                                " " +
                                y.zip +
                                " " +
                                y.city +
                                ", " +
                                y.state
                            )
                        )
                      ]),
                      _vm._v(" "),
                      _c("li", [_vm._v("Phone: " + _vm._s(y.phone))]),
                      _vm._v(" "),
                      _c("li", [_vm._v("Rating: " + _vm._s(y.averageRating))]),
                      _vm._v(" "),
                      _c("li", [_vm._v("Url: " + _vm._s(y.websiteURL))]),
                      _vm._v(" "),
                      _c("li", [_vm._v("Slogan: " + _vm._s(y.slogan))])
                    ])
                  }),
                  _vm._v(" "),
                  _c("h4", [_vm._v("Yelp pages")]),
                  _vm._v(" "),
                  _vm._l(r.yelp_results, function(y) {
                    return _c("ul", [
                      _c("li", [_vm._v("Name: " + _vm._s(y.name))]),
                      _vm._v(" "),
                      _c("li", [_vm._v("Rating: " + _vm._s(y.rating))]),
                      _vm._v(" "),
                      _c("li", [
                        _vm._v(
                          "Categories: " +
                            _vm._s(
                              y.categories
                                .map(function(a) {
                                  return a.title
                                })
                                .join(",")
                            )
                        )
                      ]),
                      _vm._v(" "),
                      _c("li", [_vm._v("Price level: " + _vm._s(y.price))]),
                      _vm._v(" "),
                      _c("li", [_vm._v("Phone: " + _vm._s(y.display_phone))]),
                      _vm._v(" "),
                      _c("li", [
                        _vm._v(
                          "Address: " +
                            _vm._s(
                              y.location.address1 +
                                " " +
                                y.location.address2 +
                                " " +
                                y.location.address3 +
                                " " +
                                y.location.postal_code +
                                " " +
                                y.location.city +
                                " " +
                                y.location.state +
                                " " +
                                y.location.country
                            )
                        )
                      ])
                    ])
                  })
                ],
                2
              )
            }),
            0
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("ID")]),
        _vm._v(" "),
        _c("th", [_vm._v("Date")]),
        _vm._v(" "),
        _c("th", [_vm._v("Filename")]),
        _vm._v(" "),
        _c("th", [_vm._v("Status")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }