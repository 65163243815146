var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("ul", { staticClass: "acc-results" }, [
      _c("li", {}, [
        _c(
          "div",
          { staticClass: "acc-list", staticStyle: { display: "block" } },
          [
            _c("table", [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.connexes, function(item) {
                  return _c("tr", [
                    _c("td", { staticStyle: { "text-align": "left" } }, [
                      !item.editing
                        ? _c("span", [_vm._v(_vm._s(item.version))])
                        : _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: item.version,
                                expression: "item.version"
                              }
                            ],
                            domProps: { value: item.version },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(item, "version", $event.target.value)
                              }
                            }
                          })
                    ]),
                    _vm._v(" "),
                    _c("td", { staticStyle: { "text-align": "left" } }, [
                      !item.editing
                        ? _c("span", [_vm._v(_vm._s(item.username))])
                        : _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: item.username,
                                expression: "item.username"
                              }
                            ],
                            domProps: { value: item.username },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(item, "username", $event.target.value)
                              }
                            }
                          })
                    ]),
                    _vm._v(" "),
                    _c("td", { staticStyle: { "text-align": "left" } }, [
                      !item.editing
                        ? _c("span", [_vm._v(_vm._s(item.connexKey))])
                        : _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: item.connexKey,
                                expression: "item.connexKey"
                              }
                            ],
                            domProps: { value: item.connexKey },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(item, "connexKey", $event.target.value)
                              }
                            }
                          })
                    ]),
                    _vm._v(" "),
                    _c(
                      "td",
                      { staticStyle: { "text-align": "left" } },
                      [
                        !item.editing
                          ? _c("span", [_vm._v(_vm._s(item.status))])
                          : _c("dyn-select", {
                              attrs: { options: _vm.statuses },
                              model: {
                                value: item.status,
                                callback: function($$v) {
                                  _vm.$set(item, "status", $$v)
                                },
                                expression: "item.status"
                              }
                            })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("td", { staticStyle: { "text-align": "left" } }, [
                      !item.editing
                        ? _c("span", [_vm._v(_vm._s(item.notes))])
                        : _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: item.notes,
                                expression: "item.notes"
                              }
                            ],
                            domProps: { value: item.notes },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(item, "notes", $event.target.value)
                              }
                            }
                          })
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      !item.editing
                        ? _c(
                            "a",
                            {
                              attrs: { href: "" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  item.editing = true
                                }
                              }
                            },
                            [_vm._v("Edit")]
                          )
                        : _c(
                            "a",
                            {
                              attrs: { href: "" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.saveItem(item)
                                }
                              }
                            },
                            [_vm._v("Save")]
                          )
                    ])
                  ])
                }),
                0
              )
            ])
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticStyle: { "text-align": "left" } }, [
          _vm._v("Version")
        ]),
        _vm._v(" "),
        _c("th", { staticStyle: { "text-align": "left" } }, [
          _vm._v("Username")
        ]),
        _vm._v(" "),
        _c("th", { staticStyle: { "text-align": "left" } }, [_vm._v("Key")]),
        _vm._v(" "),
        _c("th", { staticStyle: { "text-align": "left" } }, [_vm._v("Status")]),
        _vm._v(" "),
        _c("th", { staticStyle: { "text-align": "left" } }, [_vm._v("Notes")]),
        _vm._v(" "),
        _c("th", [_vm._v("Options")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }