var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("page-header", {
        attrs: { title: "Implementation", breadcrumbs: _vm.breadcrumbs }
      }),
      _vm._v(" "),
      _c("sub-menu", { attrs: { back: true, menu: _vm.menu } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }